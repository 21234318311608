// Customizable Area Start
import React from "react";
import {
    Box,
    Button,
    Grid,
    Tooltip,
    TextField,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import { styled } from "@material-ui/styles";
import SearchIcon from "@material-ui/icons/Search";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import Select from "react-select";
import OnlineToolsController, { Props } from "./OnlineToolsController.web";
import AppSidebar from "../../../../components/src/AppSidebar.web";
import OnlineToolsDialog from "./OnlineToolsDialog.web";
const LanguageData = localStorage.getItem("language") === "ar";
import Spinner from "../../../../components/src/Spinner.web";

const editIcon = require("../../assets/edit.png");
const copyIcon = require("../../assets/copy.png");
const deleteIcon = require("../../assets/delete.png");

class OnlineTools extends OnlineToolsController {
    constructor(props: Props) {
        super(props);
    }

    TableBodyRedner = () => {
        return (
            this.state.data.length > 0 ?
                <>
                    {this.state.data.map((item: any, index: number) => (
                        <TableRow key={`${index + 1}`}>
                            <TableCell style={{ fontFamily: "ProximaNova-Regular", fontSize: "18px", color: "#000" }}>{item.question}</TableCell>
                            <TableCell align='center' style={{ fontFamily: "ProximaNova-Regular", fontSize: "18px", color: "#000", whiteSpace: "nowrap" }}>
                                <Box display="flex" justifyContent="center">
                                    <span>{item.correctOption.map((option: any) => (option + 10).toString(36).toUpperCase()).join(", ")}</span>
                                    <CustomTooltip
                                        arrow
                                        placement="right"
                                        title={
                                            <Box display="flex" alignItems="center">
                                                {item.correctAnswer?.map((ans: any, index2: number) => ans.includes("https://") ? <><img key={`${index2 + 1}`} src={ans} alt="not found!" style={{ height: "50px", width: "50px", marginRight: "10px" }} /></> : <span key={`${index2 + 1}`}>{index2 < item.correctAnswer.length - 1 ? ans + ", " : ans}</span>)}
                                            </Box>
                                        }
                                    >
                                        <InfoOutlinedIcon style={{ width: "18px", height: "18px", cursor: "pointer", marginTop: "2px", marginLeft: "4px", color: "#333333" }} />
                                    </CustomTooltip>
                                </Box>
                            </TableCell>
                            <TableCell align='center' style={{ fontFamily: "ProximaNova-Regular", fontSize: "18px", color: "#000", whiteSpace: "nowrap" }}>{item.industry}</TableCell>
                            <TableCell align='center' style={{ fontFamily: "ProximaNova-Regular", fontSize: "18px", color: "#000", whiteSpace: "nowrap" }}>{item.competency}</TableCell>
                            <TableCell align='center' style={{ fontFamily: "ProximaNova-Regular", fontSize: "18px", color: "#000", whiteSpace: "nowrap" }}>{item.level}</TableCell>
                            <TableCell align='center' style={{ fontFamily: "ProximaNova-Regular", fontSize: "18px", color: "#000" }}>
                                <img src={editIcon} id="edit" style={{ cursor: "pointer" }} onClick={() => this.setState({ item, openDialogName: "Edit" })} />
                                <img src={copyIcon} id="clone" style={{ margin: "0px 15px", cursor: "pointer" }} onClick={() => this.cloneQuestion(item.id)} />
                                <img src={deleteIcon} id="delete" style={{ cursor: "pointer" }} onClick={() => this.setState({ item, openDialogName: "Delete" })} />
                            </TableCell>
                        </TableRow>
                    ))}
                </>
                : <TableRow>
                    <TableCell colSpan={6}
                        align="center"
                        style={{ fontFamily: "ProximaNova-Regular", fontSize: "18px", color: "#000", whiteSpace: "nowrap" }}>{this.t("onlineToolDialog.label.notFound")}</TableCell>
                </TableRow>

        )
    }

    render() {
        return (
            <StyleOnlineTools style={{ fontFamily: "ProximaNova-Regular" }} data-testid="OnlineToolsHeading">
                {this.state.loading && <Spinner spinnerModal={this.state.loading} />}
                <AppSidebar type="admin">
                    <Box margin="35px 0px" mr="30px">
                        <Box display={{ xs: "block", md: "flex" }} justifyContent="space-between" mb="40px">
                            <Box>
                                <Box
                                    mb="5px"
                                    fontFamily="ProximaNova-Bold"
                                    fontSize={{ xs: "24px", sm: "30px", md: "44px" }}
                                    color="#1b3c69">{this.t("onlineTool.title")}</Box>
                                <Box color="#1b3c69" fontSize={{ xs: "14px", sm: "20px" }}>{this.t("onlineTool.description")}</Box>
                            </Box>
                            <Box mt={{ xs: "20px" }}>
                                <CustomButton style={{ marginLeft: LanguageData ? '10px' : "unset" }}
                                    onClick={() => this.setState({ openDialogName: "AddEditTool" })}>{this.t("onlineTool.button.addEdit")}</CustomButton>
                                <RedButton
                                    onClick={() => this.setState({ openDialogName: "AddQuestion" })} style={{ backgroundColor: "#f26a42" }}>{this.t("onlineTool.button.add")}</RedButton>
                            </Box>
                        </Box>
                        <Box style={{
                            borderRadius: "10px",
                            backgroundColor: "#eff6ff"
                        }}>
                            <Grid container spacing={2} style={{
                                alignItems: "end",
                                padding: "20px 15px 10px 15px"
                            }}>
                                <Grid item xs={12} sm={6} md={5}>
                                    <Box
                                        style={{ color: "#1b3c69" }}>
                                        <div data-testid="OnlineToolsHeading1" style={{ marginBottom: "5px", fontSize: "18px" }}>{this.t("onlineTool.label.sortBy")}</div>
                                        <Select
                                            autofocus={true}
                                            classNamePrefix="react-select"
                                            placeholder={this.t("onlineTool.placeholder.sortBy")}
                                            openOnFocus={true}
                                            isClearable={true}
                                            isSearchable={false}
                                            value={
                                                sortByList.filter(
                                                    (option: any) =>
                                                        option.value === this.state.sortBy
                                                )[0]
                                            }
                                            options={sortByList}
                                            onChange={(event: any) => {
                                                this.onsortByValueChange(event);
                                            }}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={5}>
                                    <Box style={{ color: "#1b3c69" }}>
                                        <div style={{ marginBottom: "5px", fontSize: "18px" }}>{this.t("onlineTool.label.selectOption")}</div>
                                        <Select
                                            classNamePrefix="react-select"
                                            placeholder={this.t("onlineTool.placeholder.selectOption")}
                                            isSearchable={false}
                                            isClearable={true}
                                            options={this.state.optionList}
                                            value={
                                                this.state.optionList.filter(
                                                    (option: any) =>
                                                        option?.label === this.state.option
                                                )[0] ?? []
                                            }
                                            onChange={(event: any) => {
                                                this.setState({ option: event.label });
                                            }}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={2}>
                                    <Box>
                                        <RedButton onClick={this.onApplyButtonClick}>{this.t("onlineTool.button.apply")}</RedButton>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box mt="60px">
                            <Box display="flex" justifyContent="end" mb="25px">
                                <Box fontSize="22px" color="#f26a42" fontFamily="ProximaNova-Semibold" style={{ cursor: "pointer" }} onClick={this.redirectToAccessRepository}>{this.t("onlineTool.link.accessRepository")}</Box>
                            </Box>
                            <Box>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Box mb="25px"
                                            display="flex" justifyContent="end"
                                            alignItems="center" className={LanguageData ? "SearchIconRightSide" : ""}>
                                            <TextField
                                                InputLabelProps={{
                                                    style: {
                                                        fontFamily: "ProximaNova-Regular",
                                                        zIndex: 0
                                                    }
                                                }}
                                                onChange={(event: any) => {
                                                    this.onChangeHandler(event.target.value, 0);
                                                }}
                                                id="search"
                                                label={this.t("onlineTool.label.search")}
                                                variant="outlined"
                                                InputProps={{
                                                    endAdornment: <SearchIcon />
                                                }}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} style={{ overflow: "auto" }}>
                                        <Table aria-label="simple table" style={{ border: "1px solid #E0E0E0", borderRadius: "10px", borderCollapse: "separate" }} className="custom-table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell style={{ fontFamily: "ProximaNova-Bold", fontSize: "16px" }} align="center">{this.t("onlineTool.text.question")}</TableCell>
                                                    <TableCell style={{ fontFamily: "ProximaNova-Bold", fontSize: "16px" }} align="center">{this.t("onlineTool.text.option")}</TableCell>
                                                    <TableCell style={{ fontFamily: "ProximaNova-Bold", fontSize: "16px" }} align="center">{this.t("onlineTool.text.industry")}</TableCell>
                                                    <TableCell style={{ fontFamily: "ProximaNova-Bold", fontSize: "16px" }} align="center">{this.t("onlineTool.text.competency")}</TableCell>
                                                    <TableCell style={{ fontFamily: "ProximaNova-Bold", fontSize: "16px" }} align="center">{this.t("onlineTool.text.level")}</TableCell>
                                                    <TableCell style={{ fontFamily: "ProximaNova-Bold", fontSize: "16px" }} align="center">{this.t("onlineTool.text.action")}</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {this.TableBodyRedner()}
                                            </TableBody>
                                        </Table>
                                        {this.state.data.length > 0 &&
                                            <div className="custom-pagination" data-testid="OnlineToolsHeading3">
                                                <Pagination
                                                    count={Math.ceil(this.state.dataLength / this.state.rowsPerPage)}
                                                    page={this.state.page + 1}
                                                    onChange={(event: unknown, newPage: number) => {
                                                        this.onChangeHandler(this.state.search, newPage - 1);
                                                    }}
                                                    dir="ltr"
                                                    shape="rounded"
                                                    showFirstButton
                                                    showLastButton
                                                    variant="outlined"
                                                    siblingCount={0}
                                                    boundaryCount={1}
                                                />
                                            </div>
                                        }
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </Box>
                </AppSidebar>
                {this.state.openDialogName &&
                    <OnlineToolsDialog
                        t={this.t}
                        item={this.state.item}
                        onlineToolList={this.state.onlineToolList}
                        competencyList={this.state.competencyList}
                        industryList={this.state.industryList}
                        openDialogName={this.state.openDialogName}
                        changeDialog={this.onChangeDialog}
                    />
                }
            </StyleOnlineTools>
        );
    }
}

export default withRouter(withTranslation()(OnlineTools));

const CustomTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    "& .MuiTooltip-tooltip": {
        margin: "0px 8px",
        fontSize: "13px",
        color: "black",
        backgroundColor: "white",
        border: "1px solid black",
    },
    "& .MuiTooltip-arrow": {
        color: "white",
    },
    "& .MuiTooltip-arrow::before": {
        border: "1px solid black",
    }
}));

const CustomButton = styled(Button)({
    padding: "8px 25px",
    backgroundColor: "white",
    fontFamily: "ProximaNova-Semibold",
    fontSize: "18px",
    color: "#f26a42",
    marginRight: "15px",
    borderRadius: "8px",
    textTransform: "none",
    border: "1px solid #f26a42",
    "&:hover": {
        color: "#f26a42",
        backgroundColor: "white",
    },
    "@media(max-width:600px)": {
        width: "100%",
    },
    "@media(max-width:992px)": {
        padding: "7px 15px",
    }
});

const StyleOnlineTools = styled('div')({
    '& .SearchIconRightSide': {
        '& .MuiInputBase-root': {
            display: "flex",
            flexDirection: "row-reverse"
        }
    }
})

const RedButton = styled(Button)({
    color: "white",
    fontSize: "18px",
    borderRadius: "10px",
    backgroundColor: "#f94b4b",
    fontFamily: "ProximaNova-Semibold",
    textTransform: "none",
    padding: "9px 25px",
    "&:hover": {
        color: "white",
        backgroundColor: "#f94b4b",
    },
    "@media(max-width:600px)": {
        width: "100%",
        marginTop: "27px",
    },
    "@media(max-width:992px)": {
        padding: "8px 15px",
    }
});

const sortByList = [
    { label: "All", value: "all" },
    { label: "Industry", value: "industry" },
    { label: "Competency", value: "competency" },
    { label: "Level", value: "level" },
];
// Customizable Area End