import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { toast } from "react-toastify";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    id?: string;
    navigation?: any;
    location: any;
    history: any;
    match: any;
    // Customizable Area Start
    t: any;
    // Customizable Area End
}

export interface Attributes {
    di: string;
    title: string;
    content: string;
    evaluation_type: string;
    duration: string;
    progress: number;
    complete_by: string;
    slot_booked_on: string;
    status: string;
    total_question: string;
    logo_url: string;
    updated_at: string;
}

export interface Data {
    id: string;
    type: string;
    attributes: Attributes;
}

export interface S {
    // Customizable Area Start
    loading: boolean;
    data: any[];
    count: number | null;
    tools: any[];
    onlineTools: any;
    assessorTools: any;
    idDatePast: boolean;
    open: boolean;
    url: any;
    redirectId: any,
    modalOpen: boolean,
    isTestTaken: boolean,
    PsychometricTool:any
    tool_id:any;
    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export interface DataArr {
    isDisabled: boolean;
    interval: any;
}

export let dataArr: DataArr[] = [];

export default class DashboardController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    dashboardApiCallID: string = "";
    ZoomLinkApiCallId: string = "";
    toolStatusApiCallID: string = "";
    updateAttemptApiCallID: string = "";
    updateProgressApiCallID: string = "";
    t: any = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage),
        ];
        this.receive = this.receive.bind(this);
        runEngine.attachBuildingBlock(this, this.subScribedMessages);

        this.state = {
            // Customizable Area Start
            loading: false,
            data: [],
            count: null,
            tools: [],
            onlineTools: null,
            assessorTools: null,
            idDatePast: false,
            open: false,
            url: null,
            redirectId: "",
            modalOpen: false,
            isTestTaken: false,
            PsychometricTool:"false",
            tool_id:0
            // Customizable Area End
        };
        // Customizable Area Start
        this.t = this.props.t;
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            this.setFilterData(apiRequestCallId, responseJson)

        }
        // Customizable Area End
    }

    // Customizable Area Start

    setFilterData = (apiRequestCallId: any, responseJson: any) => {
        if (apiRequestCallId && responseJson) {
            if (apiRequestCallId === this.dashboardApiCallID) {
                if (!responseJson.error) {
                    this.setTools(responseJson)
                    let temp = this.state.tools.filter((val: any) => val.name === "Psychometric")
                    this.tempprogressIfFuncation(temp)
                    this.setState({ loading: false });
                }else{
                    toast.error(this.t(responseJson.error));
                }
            } else if (apiRequestCallId === this.ZoomLinkApiCallId) {
                let data = responseJson.data.filter((val:any)=> val.attributes.assessor_lead_tool_id == this.state.tool_id)
                let urls: any = data?.[0].attributes?.join_url
                window.open(urls, "_blank")
            } else if (apiRequestCallId === this.updateAttemptApiCallID) {
                this.getDashboardDetails()
            }
            else if (apiRequestCallId === this.updateProgressApiCallID) {
                this.getDashboardDetails()
            }
        }
    }

    setTools = (responseJson:any) =>{
        this.setState({
            onlineTools: responseJson?.online_tools ? responseJson?.online_tools : [],
            assessorTools: responseJson?.assessor_tools ? responseJson?.assessor_tools : []
        }, () => {
            let temp = [...this.state.onlineTools, ...this.state.assessorTools]
            this.setState({ tools: temp })
        });
    }

    tempprogressIfFuncation = (temp: any) => {
        if (temp[0]?.progress === 0 || null && temp[0]?.name === "Psychometric") {
            this.setState({ modalOpen: true })
        }
    }

    async componentDidMount() {
        let status = localStorage.getItem("reloadDashboard")
        if(status === "true"){
        window.location.reload()
        this.getDashboardDetails()
        localStorage.setItem("reloadDashboard","false")
        }
        window.scrollTo(0, 0);
        this.getDashboardDetails();
      }

      componentDidUpdate() {
        window.onpopstate = () => this.AreyouSureFun();
      }
    
      AreyouSureFun = () => {
        this.props.navigation.navigate('Dashboard')
      };

    getDashboardDetails = () => {
        this.setState({ loading: true });

        const header = {
            "Content-Type": configJSON.dashboardApiContentType,
            token: localStorage.getItem("accesToken")
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.dashboardApiCallID = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.dashboardApiEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.dashboardApiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }

    redirectData = (val: any) => {
        this.setState(
            {
                tool_id : val?.id,
                redirectId: val?.slots?.data?.id,

            },
            () => {
                this.ZoomLinkApiCall();
                this.updateToolStatus(val);
            }
        );
    }


    apiCall = async (data: any) => {
        const token = localStorage.getItem("accesToken") as string
        const { contentType, method, endPoint, body, type } = data
        const header = {
            'Content-Type': contentType, token: token
        }
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        )
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        )
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        )
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        )
        body && type != 'formData' ?
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                JSON.stringify(body))

            : requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                body
            );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return requestMessage.messageId;
    }

    updateProgress = async () => {
        this.updateProgressApiCallID = await this.apiCall({
            contentType: "application/json",
            method: "POST",
            endPoint: `bx_block_profile/psychometric_progress?name=Psychometric`,
        });
        this.setState({ modalOpen: false })
    }

    updateToolStatus = async (val: any) => {
        this.toolStatusApiCallID = await this.apiCall({
            contentType: "application/json",
            method: "POST",
            endPoint: `bx_block_profile/participant_meeting_status?assessor_lead_tool_id=${val?.id}&status=Ongoing&id=${val?.slots?.data?.attributes?.participant_id}`,
        });
    }
    showAttempt = async () => {
        this.updateAttemptApiCallID = await this.apiCall({
            contentType: "application/json",
            method: "POST",
            endPoint: `account_block/update_status_count?name=Psychometric&status_count=1`,
        });
    }

    ZoomLinkApiCall = () => {
        const header = {
            "Content-Type": configJSON.dashboardApiContentType,
            token: localStorage.getItem("accesToken")
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.ZoomLinkApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            '/account_block/participants/participant_meeting_calenders'
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "GET"
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }
    checkProgress = (val: any) => {
        return Number(val?.progress) <= 100 ? Number(val?.progress) : 100
    }
    handleStartNow = (val: any) => {
        if (val?.name === "Psychometric") {
            let url = val?.url
            this.setState({ open: true, url: url,PsychometricTool : val  })
            this.showAttempt()
        }
        else {
            localStorage.setItem("tool", JSON.stringify(val))
            localStorage.removeItem("disableNext")
            this.props.navigation.navigate('TestInstruction')
        }
    }
    // handleDisable = (type : any,val : any) =>{
    //     moment(val?.complete_by).isBefore(val?.date, 'year') ? true : false
    //     return true
    // }
    // Customizable Area End
}
