// Customizable Area Start
import React from "react";
import {
  Box,
  Button,
  Grid,
  Tooltip,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  InputBase,
  DialogContent,
  Dialog,
} from "@material-ui/core";
import { styled } from "@material-ui/styles";
import moment from "moment";
//@ts-ignore
import { withRouter } from "react-router";
import CloseIcon from "@material-ui/icons/Close";
import Select from "react-select";
import { Formik } from "formik";
import * as Yup from "yup";
import Pagination from "@material-ui/lab/Pagination";
import Spinner from "../../../components/src/Spinner.web";
import AppSidebar from "../../../components/src/AppSidebar.web";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import { toast } from "react-toastify";
import ProjectsController, { Props } from "./ProjectsController.web";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import BorderColorOutlinedIcon from "@material-ui/icons/BorderColorOutlined";
import InsertDriveFileOutlined from '@material-ui/icons/InsertDriveFileOutlined';
import EditIconForSection from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import ReactQuill from "react-quill";
import "./Projects.web.css";
export const uploadIcon = require("../assets/upload.png");
const LanguageData = localStorage.getItem("language") === "ar";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
export const calender = require("../assets/calender.png");
import { withTranslation } from "react-i18next";

class EditProject extends ProjectsController {
  constructor(props: Props) {
    super(props);
  }

  sortByList = [
    { label: "Status", value: "status" },
    { label: "Project", value: "name" },
  ];

  negativeMarkingList = [
    {
      label: "0.5",
      value: "0.5",
    },
    {
      label: "1",
      value: "1",
    },
    {
      label: "1.5",
      value: "1.5",
    },
    {
      label: "2",
      value: "2",
    },
  ];

  projectTypeList = [
    {
      label: "Hybrid",
      value: "hybrid",
    },
    {
      label: "Virtual",
      value: "virtual",
    },
    {
      label: "Assessor",
      value: "assessor",
    },
  ];

  editProjectSchema = () => {
    return Yup.object().shape({
      project_name: Yup.string().required(
        this.t("EditProject.createNewProjectSchema.Projectname")
      ),
      project_type: Yup.string().required(
        this.t("EditProject.createNewProjectSchema.Projecttype")
      ),
      assessor_id: Yup.string().when(
        "project_type",
        (project_type: any, schema: any) => {
          if (project_type !== "virtual") return schema.required(
            this.t("EditProject.createNewProjectSchema.Assessor")
          );

          return schema;
        }
      ),
      client_id: Yup.string()
        .nullable()
        .required(this.t("EditProject.createNewProjectSchema.Client")),
      manager_id: Yup.string().required(
        this.t("EditProject.createNewProjectSchema.Manager")
      ),
      coManager_id: Yup.string().required(
        this.t("EditProject.createNewProjectSchema.Comanager")
      ),
      start_date: Yup.string().required(
        this.t("EditProject.createNewProjectSchema.Startdate")
      ),
      end_date: Yup.string().required(
        this.t("EditProject.createNewProjectSchema.EndDateRequired")
      ),
      negative_marks: Yup.string().required(
        this.t("EditProject.createNewProjectSchema.NegativeMarkRequired")
      ),
      competency_id: Yup.string().when(
        "project_type",
        (project_type: any, schema: any) => {
          if (project_type !== "virtual")
            return schema.required(
              this.t("EditProject.createNewProjectSchema.Competency")
            );
          return schema;
        }
      ),
    });
  };
  uploadHandleChange = async (e: any, props: any) => {
    if (e.target.files[0].size > 50 * 1024 * 1024) {
      toast.warning("Upload video with size less than 50MB")
    } else {
      if (e.target.files[0].type.split('/')[0] !== "image") {
        toast.warn(this.t("EditProject.AddCompanysection.toast.UploadValid", { value: "image" }))
      } else {
        props.setFieldValue('uploadFile', [await this.getBase64(e.target.files[0])])
      }
    }
  }
  editProjectHandleChange = async (e: any, props: any) => {
    if (e.target.files[0].size > 50 * 1024 * 1024) { toast.warn(this.t("ProjectsController.Uploadvideowithsizelessthan50MB")) } else {
      if (e.target.files[0].type.split('/')[0] !== "image") {
        toast.warn(this.t("EditProject.AddCompanysection.toast.UploadValid", { value: "image" }))
      } else {
        props.setFieldValue('uploadFile', [await this.getBase64(e.target.files[0])])
      }
    }
  }
  videoHandleChange = async (e: any, props: any) => {
    if (e.target.files[0].size > 50 * 1024 * 1024) {
      toast.warning("Upload video with size less than 50MB")
    } else {
      if (e.target.files[0].type.split('/')[0] !== "video") {
        toast.warn(this.t("EditProject.AddCompanysection.toast.UploadValid", { value: 'video' }))
      } else {
        props.setFieldValue('uploadFile', [await this.getBase64(e.target.files[0])])
      }
    }
  }
  addCompanyHandleClick = (index: number) => {
    this.setState({
      EditSectionIndex: index,
      CompanyModel: !this.state.CompanyModel,
      SectionNumber: index + 1
    })
  }
  handleSbmits = (values: any) => {
    this.setState({
      ParentContentData: [values],
      AddParentContentModel: ""
    })
    if (this.state.CompanySection.length !== 0) {
      this.setState({
        CompanyModel: false
      })
    }
  }
  parentHandleClick = () => {
    this.setState({
      CompanyModel: !this.state.CompanyModel,
      AddParentContentModel: ""
    })
  }
  videosHandleChange = async (e: any, props: any) => {
    if (e.target.files[0].size > 50 * 1024 * 1024) { toast.warn(this.t("ProjectsController.Uploadvideowithsizelessthan50MB")) } else {
      if (e.target.files[0].type.split('/')[0] !== "video") { toast.warn(this.t("EditProject.AddCompanysection.toast.UploadValid", { value: 'video' })) } else {
        props.setFieldValue('uploadFile', [await this.getBase64(e.target.files[0])])
      }
    }
  }
  imagesHandleChange = async (e: any, props: any) => {
    if (e.target.files[0].size > 50 * 1024 * 1024) { toast.warn(this.t("ProjectsController.Uploadvideowithsizelessthan50MB")) } else {
      if (e.target.files[0].type.split('/')[0] !== "image") {
        toast.warn(this.t("EditProject.AddCompanysection.toast.UploadValid", { value: "image" }))
      } else {
        props.setFieldValue('uploadFile', [await this.getBase64(e.target.files[0])])
      }
    }
  }
  quetionPartStart = (props: any, toolsList: any) => {
    return (
      <Box style={{ marginTop: "3rem" }}>
        <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                cursor: "pointer",
                alignItems: "center",
                marginTop: "20px",
              }}
              data-testid="EditSelectTool"
            >
              <Typography style={{
                fontFamily: "ProximaNova-Bold",
                display: 'flex'
              }}>
                {this.t("EditProject.TextField.SelectTool")}{' '}<span style={{ color: "red" }}>*</span>
              </Typography>
              {(this.state.projectType === "assessor") === false && (
                <div
                  className="SetTimerContainer"
                  data-testid="SetTimerContainer"
                  onClick={this.TimerModelOpenClose}
                >
                  <BorderColorOutlinedIcon className="EditTimePen" />{" "}
                  <p>{this.t("EditProject.TextField.SetTimer")}</p>
                </div>
              )}
            </div>
            {this.renderButtonContainer()}
          </Grid>
          {this.state.tools.length && this.state.selectedTools.length > 0 && (
            <Box
              style={{
                marginTop: "3rem",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box>
                <Typography>
                  {this.t(
                    "EditProject.TextField.QuestionairesFromTheRepository"
                  )}
                </Typography>
              </Box>
            </Box>
          )}
          <Grid item xs={12} sm={12} md={12} lg={12}>
            {this.state.selectedTools.length > 0 &&
              this.state.selectedQuestions
                ?.slice(
                  this.state.page * this.state.rowsPerPage,
                  this.state.page * this.state.rowsPerPage +
                  this.state.rowsPerPage
                )
                .map((items: any, index: any) => {
                  return (
                    <div className="ContainerQuetion" key={items?.id}>
                      <Accordion
                        style={{
                          backgroundColor: "#eff6ff",
                          marginBottom: "10px",
                        }}
                        expanded={this.state.isExpendQue === items?.id}
                      >
                        <AccordionSummary
                          expandIcon={false}
                          aria-controls="panel1bh-content"
                          id="panel1bh-header"
                          className="panel1bh-header"
                        >
                          <div className="AcorenHeading">
                            <div className="labelAcordin" style={{ backgroundColor: `${items?.attributes?.online_tool?.tool_color === null ? "#ffd9d8" : items?.attributes?.online_tool?.tool_color}` }}>
                              <h5>{items?.attributes?.online_tool?.name}</h5>
                            </div>
                            <div className="checkBoxAndQueHeading">
                              <div className="InputContainer">
                                <Checkbox
                                  inputProps={{
                                    "aria-label": "Checkbox demo",
                                  }}
                                  checked={this.state.finalQuetionForApi
                                    .map((item: any) => Number(item.id))
                                    .includes(Number(items.id))}
                                  onChange={(event: any) =>
                                    this.handleCheck(event, items)
                                  }
                                />
                              </div>
                              {this.state.isExpendQue === items?.id ? (
                                <Grid container>
                                  <Grid
                                    item
                                    xs={12}
                                    style={{ marginTop: "20px" }}
                                  >
                                    <div className="MarksContainer">
                                      <h5>
                                        {this.t(
                                          "EditProject.TextField.Question"
                                        )}
                                      </h5>
                                      {items?.attributes?.mark !== null && (
                                        <h5 data-testid={items?.id}>
                                          {this.t("EditProject.TextField.Mark")}{" "}
                                          {items?.attributes?.mark}
                                        </h5>
                                      )}
                                    </div>
                                    <TextField
                                      className="QuetionTextField"
                                      fullWidth
                                      style={{ width: "100%" }}
                                      variant="outlined"
                                      onChange={(e: any) =>
                                        this.ChnageQueText(e)
                                      }
                                      value={this.state.QuestionText}
                                    />
                                  </Grid>
                                </Grid>
                              ) : (
                                <div className="TextandButton" data-testid={`${items?.id}new`}>
                                  <div className="QuetionRender">
                                    <div data-testid={`${items?.id}`}>
                                      <h3
                                        style={{
                                          verticalAlign: "middle",
                                          alignSelf: "center",
                                        }}
                                      >
                                        {this.t(
                                          "EditProject.TextField.Question"
                                        )}{" "}
                                        {20 * (this.state.page + 1 - 1) + index + 1}
                                      </h3>
                                    </div>
                                    <div
                                      className="quetions"
                                      data-testid={items?.attributes?.name}
                                    >
                                      <h4>{items?.attributes?.name}</h4>
                                    </div>
                                  </div>
                                  <div style={{display:"grid",placeItems:"center"}}>
                                    <Button
                                      style={{
                                        marginRight: "15px",
                                        minWidth: "130px",
                                        borderRadius: "10px",
                                        border: "1px solid #f94b4c",
                                        color: "#f94b4c",
                                        textTransform: "capitalize"
                                      }}
                                      data-testid={`View${index}`}
                                      onClick={() => this.AcordinOpen(items)}
                                    >
                                      {this.t("EditProject.button.View")}
                                    </Button>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid container>
                            <Grid item style={{ margin: "20px 0" }} xs={6}>
                              <label>
                                {this.t(
                                  "EditProject.TextField.SelectOnlineTool"
                                )}
                              </label>
                              <Select
                                onChange={(e) => {
                                  this.toolIdchnage(e);
                                }}
                                options={toolsList}
                                classNamePrefix="react-select"
                                value={
                                  toolsList.filter(
                                    (item: any) =>
                                      item.value ===
                                      this.state.CurrentEditQuetion?.attributes?.online_tool_id.toString()
                                  )[0]
                                }
                                isSearchable={false}
                                placeholder={this.t(
                                  "EditProject.TextField.SelectOnlineTool"
                                )}
                              />
                            </Grid>
                            {this.renderGrid4(props)}
                            <Grid container>
                              <Grid
                                style={{
                                  margin: "20px 0",
                                  justifyContent: "flex-end",
                                  display: "flex",
                                }}
                                item
                                xs={12}
                              >
                                <Button
                                  style={{
                                    color: "white",
                                    backgroundColor: "#f94b4c",
                                    textTransform: "capitalize",
                                    marginRight: "20px"
                                  }}
                                  onClick={this.editQuestion}
                                >
                                  {this.t("EditProject.button.Edit")}
                                </Button>
                                <Button
                                  data-testid="cancel-button"
                                  onClick={() => this.AcordinOpen(items)}
                                  style={{
                                    marginRight: "20px",
                                    backgroundColor: "#bebebe",
                                    color: "white",
                                    textTransform: "capitalize"
                                  }}
                                >
                                  {this.t("EditProject.button.Cancel")}
                                </Button>
                              </Grid>
                            </Grid>
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  );
                })}
          </Grid>
          {this.state.tools.length > 0 && this.state.selectedTools.length > 0 && (
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <div className="table-Pagination-Data">
                  <Pagination
                    variant="outlined"
                    showFirstButton
                    count={Math.ceil(this.state.selectedQuestions?.length / this.state.rowsPerPage)}
                    onChange={(event: unknown, newPage: number) =>
                      this.setState({
                        ...this.state,
                        page: newPage - 1,
                      })
                    }
                    dir="ltr"
                    page={this.state.page === 0 ? 1 : this.state.page + 1}
                    showLastButton
                    shape="rounded"
                    siblingCount={0}
                    boundaryCount={1}
                  />
                </div>
              </div>
            </Grid>
          )}
          <Grid
            item
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "10px",
            }}
            xs={12}
          >
            <Button
              type="submit"
              style={{
                backgroundColor: "#f94b4c",
                color: "white",
                textTransform: "capitalize",
                marginRight: !LanguageData ? "12px" : "",
                marginLeft: LanguageData ? "12px" : "",
                fontFamily: "ProximaNova-Regular !important"
              }}
              data-testid="createProjectBtn"
            >
              {this.t("EditProject.button.SAVE")}
            </Button>
            <Button
              onClick={this.handleCancel}
              style={{
                backgroundColor: "#bebebe",
                color: "white",
                fontFamily: "ProximaNova-Regular !important",
                textTransform: "capitalize",
                marginLeft: LanguageData ? "0px" : "12px",
              }}
            >
              {this.t("EditProject.button.Cancel")}
            </Button>
          </Grid>
        </Grid>
      </Box>
    );
  };

  async componentDidMount(): Promise<void> {
    const id = this.props.navigation.getParam("id");
    this.handleEditProject(id);
    this.getAssessorData();
    this.getCompetencyData(
      `?project_id=${window.location.pathname.split("/")[2]}`
    );
    this.getClientsData();
    this.getCoManagersData();
    this.getManagersData();
    this.getQuestionsData();
    const locationDataEditProjects = this.props?.history?.location?.state?.attributes?.online_tools.map(
      (item: any) => {
        return {
          id: item.id.toString(),
          attributes: item,
          type: "online_tool",
        };
      }
    );
    const locationDataEditProjectsassessor = this.props?.history?.location?.state?.attributes?.assessor_tools.map(
      (item: any) => {
        return {
          id: item.id.toString(),
          attributes: item,
          type: "assessor_lead_tools",
        };
      }
    );
    const QuestionForLocationEditProject = this.props?.history?.location?.state?.attributes?.questions.map(
      (item: any) => {
        return {
          ...item,
          attributes: { online_tool_id: item.online_tool_id },
        };
      }
    );
    const ProjectType = this.props?.history?.location?.state?.attributes
      ?.project_type;
    this.toolsApicall(ProjectType);
    this.setState({
      selectedTools: [
        ...locationDataEditProjects,
        ...locationDataEditProjectsassessor,
      ],
      finalQuetionForApi: this.state.finalQuetionForApi.concat(
        QuestionForLocationEditProject
      ),
      projectType: ProjectType,
    });
  }

  imagechangeTrueEdit = (ans: any) => {
    return ans.attributes.MediaType.includes("video") ? (
      <div className="videoRender">
        <video controls>
          <source src={ans.attributes.image_url.url} type="video/mp4" />
        </video>
      </div>
    ) : (
      <img
        width="100%"
        height="200px"
        alt="true"
        src={ans.attributes.image_url.url}
      />
    );
  };

  imagechangefalseEdit = (ans: any) => {
    return ans.attributes.image_url?.url !== null &&
      ans.attributes.image_url?.url !== "undefined" &&
      typeof ans.attributes.image_url === "object" &&
      ans.attributes.image_url.type?.includes("video") ? (
      <video width="100%" height="200px" controls>
        <source src={ans.attributes.image_url?.url} type="video/mp4" />
      </video>
    ) : (
      ans.attributes.image_url.url !== null && (
        <img
          width="100%"
          height="200px"
          alt="flase"
          src={ans.attributes.image_url?.url}
        />
      )
    );
  };
  cancelBtnClick = () => {
    this.setState({ CompanyModel: false, AddParentContentModel: "" })
  }
  closeHandleClick = () => {
    this.setState({ ViewFileModel: !this.state.ViewFileModel, AddParentContentModel: "" })
  }
  renderGrid4 = (props: any) => {
    return (
      <Grid container spacing={2}>
        {this.state.EditedAnswers?.map((ans: any, ansIndex: number) => {
          let color = ans?.attributes?.correct_answer ? "#dff8d1" : "";
          return (
            <Grid item key={ans?.id} style={{ margin: "20px 0" }} xs={6}>
              <div
                style={{
                  justifyContent: "space-between",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <label
                  data-testid={ans?.id + "EditProjectAddOption"}
                  style={{
                    marginBottom: "10px",
                  }}
                  className="AddOption"
                >
                  {this.t("EditProject.TextField.AddOption")}{" "}
                  {String.fromCharCode(65 + ansIndex)}
                </label>
                {ans.attributes.image_url?.url !== null && (
                  <div>
                    {color &&
                      <Checkbox
                        disabled
                        style={{ color: "#057605" }}
                        checked={true} />
                    }
                    <Button
                      variant="outlined"
                      size="small"
                      className="redButton"
                      component="label"
                    >
                      {this.t("EditProject.button.Edit")}{" "}
                      {String.fromCharCode(65 + ansIndex)}
                      <input
                        type="file"
                        data-testid={
                          String.fromCharCode(65 + ansIndex) + "Option"
                        }
                        onChange={(e: any) =>
                          this.HandelImageChange(e, ansIndex, ans)
                        }
                        accept={
                          ans.attributes.image_url.type?.includes("video")
                            ? "video/*"
                            : "image/*"
                        }
                        style={{ display: "none" }}
                        id={`${ans?.id}`}
                      />
                    </Button>
                  </div>
                )}
              </div>
              {ans.attributes.imageChange
                ? this.imagechangeTrueEdit(ans)
                : this.imagechangefalseEdit(ans)}
              {ans.attributes.image_url.url === null && (
                <TextField
                  fullWidth
                  onChange={(e) => this.handleAnswer(e, ansIndex)}
                  style={{ backgroundColor: color }}
                  value={ans?.attributes?.answer}
                  variant="outlined"
                  className="QuetionTextField"
                />
              )}
            </Grid>
          );
        })}
      </Grid>
    );
  };

  renderButtonContainer = () => {
    return (
      <Box className="ButtonContainer">
        {this.state.tools.sort((a: any, b: any) => { return a.attributes.name.toLowerCase().localeCompare(b.attributes.name.toLowerCase()) })?.map((tool: any, index: any) => {
          return (
            <Box key={tool.id}>
              <Button
                style={{ textTransform: "capitalize" }}
                onClick={() => this.handleTool(tool)}
                data-testid={`toolNameBtn${index}`}
                className={
                  this.state.selectedTools
                    .map((item: any) => item.id)
                    .includes(tool.id)
                    ? "SelectBtn"
                    : "UnSelectBtn"
                }
              >
                {tool?.attributes?.name}
              </Button>
            </Box>
          );
        })}
      </Box>
    );
  };

  renderProjectname = (props: any) => {
    return (
      <Grid item xs={6}>
        <Typography
          className="Projectlabel"
          style={{ marginBottom: "5px" }}
          variant="body2"
        >
          {this.t("EditProject.TextField.ProjectName")}
          <span style={{ color: "red" }}>*</span>
        </Typography>
        <Box className="textfield-parent">
          <TextField
            name="project_name"
            id="project_name"
            value={props.values.project_name}
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            className="client-textfields"
            placeholder={this.t("EditProject.TextField.EnterProjectName")}
            variant="outlined"
            fullWidth
            disabled
          />
          {this.getErrorMessage(props.touched, props.errors, "project_name")}
        </Box>
      </Grid>
    );
  };

  ProjectTypeRedner = (props: any, editProject: any) => {
    return (
      <Grid item xs={6}>
        <Box>
          <Typography
            className="Projectlabel"
            variant="body2"
            style={{ marginBottom: "5px" }}
          >
            {this.t("EditProject.TextField.ProjectType")}
            <span data-testid="Pagination" style={{ color: "red" }}>
              *
            </span>
          </Typography>
          <Select
            name="project_type"
            classNamePrefix="react-select"
            placeholder={this.t("EditProject.TextField.SelectProjectType")}
            isSearchable={false}
            options={this.projectTypeList}
            onChange={(event: any) => {
              props.setFieldValue("project_type", event.value);
              this.setState({ projectType: event.value });
              this.toolsApicall(event.value);
            }}
            value={
              this.projectTypeList.filter(
                (option: any) => option.value === editProject.project_type
              )[0]
            }
            isDisabled
          />
          {this.getErrorMessage(props.touched, props.errors, "project_type")}
        </Box>
      </Grid>
    );
  };

  DcMangerRedner = (props: any) => {
    return (
      <Grid item xs={6}>
        <Typography
          className="Projectlabel"
          variant="body2"
          style={{ marginBottom: "5px" }}
        >
          {this.t("EditProject.TextField.DCManager")}
          <span style={{ color: "red" }}>*</span>
        </Typography>
        <Select
          classNamePrefix="react-select"
          isSearchable
          options={this.state.managerList}
          value={
            this.state.managerList.filter(
              (option: any) => option.value == this.state.manager_id
            )[0]
          }
          placeholder={this.t("EditProject.TextField.DCManager")}
          onChange={(event: any) => {
            this.setState({ manager_id: event.value });
            props.setFieldValue("manager_id", event.value);
          }}
        />
        {this.getErrorMessage(props.touched, props.errors, "manager_id")}
      </Grid>
    );
  };

  renderCoManager = (props: any, editProject: any) => {
    return (
      <Grid item xs={12} sm={6}>
        <Typography
          className="Projectlabel"
          style={{ marginBottom: "5px" }}
          variant="body2"
        >
          {this.t("EditProject.TextField.CoManager")}
          <span style={{ color: "red" }}>*</span>
        </Typography>
        <Select
          placeholder={this.t("EditProject.TextField.CoManager")}
          value={
            this.state.coManagerList.filter(
              (option: any) => option.value == this.state.coManager_id
            )[0]
          }
          classNamePrefix="react-select"
          isSearchable
          options={this.state.coManagerList}
          onChange={(event: any) => {
            props.setFieldValue("coManager_id", event.value);
            this.setState({ coManager_id: event.value });
          }}
        />
        {this.getErrorMessage(props.touched, props.errors, "coManager_id")}
        {this.state.coManagerList.filter((option: any) => {
          return option.value === editProject.co_manager_id;
        })}
      </Grid>
    );
  };

  SelectAssessorRender = (props: any, assessor_id: any) => {
    return (
      <Grid item xs={12} sm={6}>
        <Typography
          className="Projectlabel"
          variant="body2"
          style={{ marginBottom: "5px" }}
        >
          {this.t("EditProject.TextField.SelectAssessor")}
          <span style={{ color: "red" }}>*</span>
        </Typography>
        <Select
          placeholder={this.t("EditProject.TextField.SelectAssessor")}
          classNamePrefix="react-select"
          isMulti={true}
          isDisabled={this.state.projectType === "virtual" ? true : false}
          isSearchable={true}
          options={this.state.assessorList}
          value={this.state.assessorList.filter(
            (option: any) =>
              !!assessor_id.find((ele: string) => ele === option.value)
          )}
          onChange={(event: any) => {
            let assessorIds = [];
            if (event) {
              assessorIds = event.map((obj: any) => {
                return obj.value;
              });
            }
            props.setFieldValue("assessor_id", assessorIds);
            this.setState({ assessor_id: assessorIds });
          }}
        />
        {this.getErrorMessage(props.touched, props.errors, "assessor_id")}
      </Grid>
    );
  };

  SelectClientRedner = (props: any) => {
    return (
      <Grid item xs={12} sm={6}>
        <Typography
          className="Projectlabel"
          variant="body2"
          style={{ marginBottom: "5px" }}
        >
          {this.t("EditProject.TextField.SelectClient")}
          <span style={{ color: "red" }}>*</span>
        </Typography>
        <Select
          placeholder={this.t("EditProject.TextField.SelectClient")}
          classNamePrefix="react-select"
          options={this.state.clientsList}
          isSearchable
          value={
            this.state.clientsList.filter(
              (option: any) => option.value == this.state.client_id
            )[0]
          }
          onChange={(event: any) => {
            this.setState({ client_id: event.value });
            props.setFieldValue("client_id", event.value);
          }}
          isDisabled
        />
        {this.getErrorMessage(props.touched, props.errors, "client_id")}
      </Grid>
    );
  };

  StartDateRender = (props: any) => {
    return (
      <Grid item xs={12} sm={6}>
        <Typography
          className="Projectlabel"
          style={{ marginBottom: "5px" }}
          variant="body2"
        >
          {this.t("EditProject.TextField.StartDate")}
          <span style={{ color: "red" }}>*</span>
        </Typography>
        <DatePicker
          id="start_date"
          name="start_date"
          value={moment(props.values.start_date).format("DD/MM/YYYY HH:MM a")}
          onChange={(date: any) => {
            props.setFieldValue("start_date", date);
          }}
          onBlur={props.handleBlur}
          peekNextMonth
          customInput={<CustomInput />}
          showMonthDropdown
          showYearDropdown
          minDate={new Date()}
          dropdownMode="select"
          placeholderText="DD/MM/YYYY HH:MM a"
          data-testid="startDate"
          timeInputLabel="Time:"
          dateFormat="DD/MM/YYYY HH:MM a"
          showTimeInput
          readOnly={true}
        />
        {this.getErrorMessage(props.touched, props.errors, "start_date")}
      </Grid>
    );
  };

  EndDateRender = (props: any) => {
    return (
      <Grid item xs={6}>
        <Box>
          <Typography
            className="Projectlabel"
            variant="body2"
            style={{ marginBottom: "5px" }}
          >
            {this.t("EditProject.TextField.EndDate")}
            <span style={{ color: "red" }}>*</span>
          </Typography>
          <DatePicker
            id="end_date"
            name="end_date"
            value={moment(props.values.end_date).format("DD/MM/YYYY HH:MM a")}
            onChange={(date: any) => {
              props.setFieldValue("end_date", date);
            }}
            onBlur={props.handleBlur}
            peekNextMonth
            customInput={<CustomInput />}
            showMonthDropdown
            showYearDropdown
            minDate={new Date()}
            dropdownMode="select"
            placeholderText="DD/MM/YYYY HH:MM a"
            data-testid="endDate"
            timeInputLabel="Time:"
            dateFormat="DD/MM/YYYY HH:MM a"
            showTimeInput
          />
          {this.getErrorMessage(props.touched, props.errors, "end_date")}
        </Box>
      </Grid>
    );
  };

  UploadDocument = (props: any) => {
    return (
      <Grid item xs={6}>
        <Box>
          <Typography
            className="Projectlabel"
            variant="body2"
            style={{ marginBottom: "5px" }}
          >
            {this.t("EditProject.TextField.UploadDocument")}
          </Typography>
          <Grid alignItems="flex-start" container spacing={1}>
            {this.state.pdfFile.map((fileObj: any, index: any) => {
              return (
                <div key={`${index + 1}`} className="viewUploadedFiles">
                  <a
                    href={fileObj.file}
                    target="_blank"
                    style={{ cursor: "pointer" }}
                  >
                    {fileObj.name}
                  </a>
                </div>
              );
            })}
          </Grid>
        </Box>
      </Grid>
    );
  };

  NegativeMarkingRender = (props: any, competencyId: any) => {
    return (
      <Grid item xs={6}>
        <Box>
          <Typography
            variant="body2"
            style={{
              marginBottom: "5px",
              justifyContent: "space-between",
              display: "flex",
            }}
          >
            <span className="Projectlabel">
              {this.t("EditProject.TextField.NegativeMarking")}?
              <span style={{ color: "red" }}>*</span>
            </span>
            <RadioGroup
              aria-label="isNegative"
              value={this.state.isNegative}
              name="isNegative"
              style={{ flexDirection: "row" }}
              onChange={(event: any) => {
                props.setFieldValue("isNegative", event.target.value);
                this.setState({
                  isNegative: (event.target as HTMLInputElement).value,
                });
                if (this.state.isNegative === "No") {
                  props.setFieldValue("negative_marks", 0);
                  this.setState({ negative_marks: 0 });
                }
              }}
            >
              <FormControlLabel
                value="Yes"
                disabled
                control={<Radio />}
                label="Yes"
                className="radio"
              />
              <FormControlLabel
                value="No"
                disabled
                control={<Radio />}
                label="No"
                className="radio"
              />
            </RadioGroup>
          </Typography>
          <Select
            name="negative_marks"
            classNamePrefix="react-select"
            placeholder="0"
            onChange={(event: any) => {
              props.setFieldValue("negative_marks", event.value);
              this.setState({ negative_marks: event.value });
            }}
            isDisabled={true}
            isSearchable={false}
            options={this.negativeMarkingList}
            value={
              this.state.isNegative === "No"
                ? { label: "0", value: "0" }
                : {
                  label: props.values.negative_marks,
                  value: props.values.negative_marks,
                }
            }
          />
          {this.getErrorMessage(props.touched, props.errors, "negative_marks")}
        </Box>
        <Box mt="20px">
          <Typography
            className="Projectlabel"
            variant="body2"
            style={{ marginBottom: "5px" }}
          >
            {this.t("EditProject.TextField.SelectCompetency")}
            <span style={{ color: "red" }} data-testid="SelectCompetency">
              *
            </span>
          </Typography>
          <Select
            classNamePrefix="react-select"
            className="competency"
            menuIsOpen={this.state.menuOpen}
            options={this.state.competencyIds}
            isMulti={true}
            allowSelectAll={true}
            placeholder={this.t("EditProject.TextField.Competency")}
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            value={this.state.competencyIds.filter(
              (option: any) =>
                !!competencyId.find(
                  (ele: string) => ele.toString() === option.value
                )
            )}
            isDisabled={true}
            openMenuOnClick={true}
            onBlur={(event: any) => {
              this.setState({ menuOpen: false });
            }}
            onFocus={(event: any) => {
              this.setState({ menuOpen: true });
            }}
            onEditOptionClicked={(obj: any) => {
              this.handleCloneCompetency(obj.value);
            }}
            onChange={(event: any) => {
              let ids = [];
              if (event) {
                ids = event.map((obj: any) => {
                  return obj.value;
                });
              }
              this.setState({ competencyId: ids });
              props.setFieldValue("competency_id", ids);
            }}
          />
          {this.getErrorMessage(props.touched, props.errors, "competency_id")}
        </Box>
        {(this.state.projectType === "assessor" ||
          this.state.projectType === "hybrid") && (
            <Box>
              <div
                className="SetRatingContainer"
                onClick={() =>
                  this.handleScoreModal("edit", this.state.setIncrement)
                }
              >
                <BorderColorOutlinedIcon className="EditTimePen" />{" "}
                <p>{this.t("EditProject.TextField.SetScoreRating")}</p>
              </div>
            </Box>
          )}
      </Grid>
    );
  };

  RednerDeleteDialog = () => {
    return (
      <DeleteDialog
        aria-labelledby="scroll-dialog-title"
        fullWidth={false}
        scroll="body"
        onClose={this.TimerModelOpenClose}
        open={this.state.SetTimeModel}
        aria-describedby="scroll-dialog-description"
      >
        <DialogContent style={{ padding: "0px", overflow: "hidden" }}>
          <div className="firstDiv" data-testid="EditSelectToolmodel">
            <Box
              style={{
                paddingTop: "0px !important",
                textAlign: "right",
              }}
            >
              <CloseIcon
                onClick={this.TimerModelOpenClose}
                style={{
                  cursor: "pointer",
                  fontSize: "35px",
                  color: "#787878",
                }}
              />
            </Box>
            <Box padding={{ xs: "15px 25px" }}>
              <Box
                id="modal-title"
                fontWeight="bolder"
                fontSize={{ xs: "20px", sm: "30px" }}
                fontFamily="ProximaNova-Bold"
              >
                {this.t("EditProject.TextField.SetTimer")}
              </Box>
            </Box>
            <Box padding={{ xs: "15px 25px" }}>
              <Grid container>
                <Grid item xs={12}>
                  <label className="labels" data-testid="EditSelectToollabels">
                    {this.t("EditProject.TextField.SelectToolName")}
                    <span
                      data-testid="EditSelectToollabelsSelectToolName"
                      style={{ color: "#f94b4b" }}
                    >
                      *
                    </span>
                  </label>
                  <Box className="textfield-parent">
                    <Select
                      classNamePrefix="react-select"
                      isSearchable={false}
                      options={this.state.ChangeTimeOfTools}
                      className="SelectToolName"
                      value={this.state.CurrentToolName === "" ? this.state.ChangeTimeOfTools[0] : this.state.tools.filter((option: any) => option.value === this.state.CurrentToolName)[0]}
                      onChange={(event: any, index: any) => {
                        this.setState({
                          CurrentToolName: event.value,
                          CurrentToolIndex: this.state.ChangeTimeOfTools.map(
                            (item: any) => item.online_tool_id
                          ).indexOf(event.value),
                        });
                      }}
                      placeholder={this.t(
                        "EditProject.TextField.SelectToolName"
                      )}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box padding={{ xs: "15px 25px" }}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <label className="labels" placeholder="SetTimerEdit">
                    {this.t("EditProject.TextField.SetTimer")}{" "}<span style={{ fontSize: "10px", fontFamily: "ProximaNova-Regular", color: "red" }}>{`(${this.t("ProjectsController.Max60minutesallowed")})`}</span>
                  </label>
                </Grid>
                <Grid item xs={12}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                    className="ModelMainDiv"
                    data-testid="EditSelectToollabelsfirst"
                  >
                    <div data-testid="EditSelectToollabelshours">
                      <TextField
                        variant="outlined"
                        type="number"
                        InputProps={{ inputProps: { min: 0, max: 60 } }}
                        InputLabelProps={{
                          style: {
                            fontFamily: "ProximaNova-Regular",
                            zIndex: 0,
                          },
                        }}
                        onChange={(e: any) => this.ChangeToolsTime(e, "min")}
                        value={
                          this.state?.ChangeTimeOfTools[
                            this.state.CurrentToolIndex
                          ]?.min
                        }
                        error={
                          this.state?.ChangeTimeOfTools[
                            this.state.CurrentToolIndex
                          ]?.errorMin ||
                          this.state?.ChangeTimeOfTools[
                            this.state.CurrentToolIndex
                          ]?.min === ""
                        }
                        label={this.t("EditProject.TextField.Min")}
                      />
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div
                    data-testid="EditSelectToollabelsButons"
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <Button
                      onClick={this.TimerModelOpenClose}
                      className="cancelBtn"
                      size="large"
                      style={{ textTransform: "capitalize", fontFamily: "ProximaNova-Regular !important", }}
                      id="EditProject.button.Canceledit"
                    >
                      {this.t("EditProject.button.Cancel")}
                    </Button>
                    <Button
                      style={{
                        backgroundColor: "#f94b4c",
                        color: "white",
                        textTransform: "capitalize",
                        fontFamily: "ProximaNova-Regular",
                      }}
                      onClick={this.TimerSave}
                      type="submit"
                      data-testid="createProjectBtn"
                      id="EditProject.button.Canceleditsdsds"
                    >
                      {this.t("EditProject.button.SAVE")}
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </Box>
          </div>
        </DialogContent>
      </DeleteDialog>
    );
  };

  RenderRatingDilog = () => {
    return (
      <Dialog
        open={this.state.openDialogName === "scoreRating"}
        fullWidth={false}
        className="setScoreRating"
        aria-labelledby="scroll-dialog-title"
        scroll="body"
        aria-describedby="scroll-dialog-description"
        transitionDuration={0}
        onClose={() => {
          this.setState({ openDialogName: "" });
        }}
        maxWidth={"sm"}
        PaperProps={{
          style: {
            width: "600px",
          },
        }}
      >
        <DialogContent style={{ padding: "0px", width: "100%" }}>
          <Box
            style={{
              margin: "10px 10px 0px 0px",
              textAlign: "right",
            }}
          >
            <CloseIcon
              onClick={this.handleCancelProjectRating}
              style={{
                cursor: "pointer",
                color: "#787878",
                fontSize: "35px",
              }}
            />
          </Box>
          <Box padding={{ xs: "15px 25px 25px", sm: "15px 40px 40px" }}>
            <Box
              id="modal-title"
              fontWeight="bolder"
              fontSize={{ xs: "20px", sm: "30px" }}
              mb="20px"
              fontFamily="ProximaNova-Bold"
              color="#111"
            >
              {this.t("EditProject.TextField.SetScoreRating")}
            </Box>
            <form
              id="my-form"
              noValidate
              onSubmit={this.onSubmitRating}
              autoComplete="off"
            >
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <Box>
                    <Typography variant="body2" style={{ marginBottom: "5px" }}>
                      {this.t("EditProject.TextField.SetIncrement")}
                      <span style={{ color: "red" }}>*</span>
                    </Typography>
                    <Select
                      name="setIncrement"
                      classNamePrefix="react-select"
                      value={
                        this.incrementList.filter(
                          (option: any) =>
                            option.value == this.state.setIncrement
                        )[0]
                      }
                      onChange={(event: any) => {
                        this.setState({
                          setIncrement: event.value,
                          rating:
                            event.value === "0.5"
                              ? this.state.rangeHalfData[0]?.value
                              : this.state.rangeOneData[0]?.value,
                        });
                      }}
                      isDisabled
                      options={this.incrementList}
                      isSearchable={false}
                    />
                  </Box>
                </Grid>
              </Grid>
              {this.state.setIncrement === "1" && (
                <>
                  {this.state.rangeOneData.map((item: any) => {
                    return (
                      <Grid
                        style={{
                          color: "#8a8888",
                          margin: "20px 0",
                          fontSize: "12px",
                        }}
                        key={item.label}
                        id={item.label}
                      >
                        {item.label}
                        {" - "}
                        {item.description}
                      </Grid>
                    );
                  })}
                </>
              )}
              {this.state.setIncrement === "0.5" && (
                <>
                  {this.state.rangeHalfData.map((item: any) => {
                    return (
                      <Grid
                        id={item.label}
                        key={item.label} style={{
                          color: "#8a8888",
                          fontSize: "12px",
                          margin: "20px 0",
                        }}
                      >
                        {item.label}
                        {" - "}
                        {item.description}
                      </Grid>
                    );
                  })}
                </>
              )}
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <Box>
                    <Typography variant="body2" style={{ marginBottom: "5px" }}>
                      {this.t("EditProject.TextField.Rating")}
                      <span style={{ color: "red" }}>*</span>
                    </Typography>
                    <Select
                      classNamePrefix="react-select"
                      isSearchable={false}
                      id="EditProject.TextField.Ratingkdsdsddddsdsds"
                      value={
                        this.state.setIncrement === "0.5"
                          ? this.state.rangeHalfData.filter(
                            (option: any) => option.value == this.state.rating
                          )[0]
                          : this.state.rangeOneData.filter(
                            (option: any) => option.value == this.state.rating
                          )[0]
                      }
                      onChange={(event: any) => {
                        this.setState({ rating: event.value });
                      }}
                      className="rating-dropdown"
                      options={
                        this.state.setIncrement === "0.5"
                          ? this.state.rangeHalfData
                          : this.state.rangeOneData
                      }
                      name="rating"
                    />
                  </Box>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid xs={12} item>
                  <Typography style={{ marginBottom: "5px" }} variant="body2">
                    {this.t("EditProject.TextField.Description")}
                    <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <CustomTwoInputBase
                    placeholder={this.t("EditProject.TextField.WriteDescription")}
                    fullWidth
                    minRows={4}
                    onChange={this.handleProjectRatings}
                    multiline
                    readOnly
                    value={this.handleRatingsDescription()}
                  />
                </Grid>
              </Grid>
              <Box mt="30px" display="flex" justifyContent="end">
                <CancelButton onClick={this.handleCancelProjectRating} className="cancelBtn" >{this.t("EditProject.button.Cancel")} </CancelButton>
                <SaveButton style={{ marginRight: LanguageData ? "15px" : "unset" }} form="my-form" type="submit"> {this.t("EditProject.button.SAVE")} </SaveButton>
              </Box>
            </form>
          </Box>
        </DialogContent>
      </Dialog>
    );
  };

  sectionuploadSchema = () => {
    return Yup.object().shape({
      heading: Yup.string().required(
        this.t("EditProject.sectionuploadSchema.Headingisrequired")
      ),
      title: Yup.string().required(
        this.t("EditProject.sectionuploadSchema.Titleisrequired")
      ),
      content: Yup.string().required(
        this.t("EditProject.sectionuploadSchema.Contentisrequired")
      ),
      uploadFile: Yup.array().required(
        this.t("EditProject.sectionuploadSchema.UploadFileisrequired")
      ),
    });
  };

  AddParentsectionuploadSchema = () => {
    return Yup.object().shape({
      heading: Yup.string().required(this.t("EditProject.sectionuploadSchema.Headingisrequired")),
      content: Yup.string().required(this.t("EditProject.sectionuploadSchema.Contentisrequired")),
    });
  };

  AddCompanySection = () => {
    return (
      <Grid container>
        {this.state.CompanySection.length === 0 && this.state.ParentContentData.length === 0 ?
          <Grid item xs={12}>
            <div className="CompanysectionMainDiv" onClick={() => this.setState({
              CompanyModel: !this.state.CompanyModel,
              AddParentContentModel: "EditParent",
              EditSectionIndex: this.state.CompanySection.length
            })}>
              <AddIcon /><Typography style={{ fontWeight: "bold" }}>{this.t("EditProject.AddCompanysection.AddCompanysectionText")}</Typography>
            </div>
          </Grid> :
          <Grid item xs={12}>
            <div className="SectionContainer" data-testid="SectionContainer1">
              <div className="SectionHeading" data-testid="SectionContainer2">
                <div style={{ fontWeight: "bolder" }}>
                  <Typography>{this.t("EditProject.AddCompanysection.CompanyDetails")}</Typography>
                </div>
                <div style={{ display: 'flex' }} data-testid="SectionContainer34">
                  <Button className="addnewsection" style={{ marginRight: '10px' }} onClick={() => this.setState({
                    AddParentContentModel: "EditParent",
                    CompanyModel: !this.state.CompanyModel,
                  })}>
                    <EditIconForSection />
                    <Typography>
                      {this.t("EditProject.AddCompanysection.EditParentDetails")}
                    </Typography>
                  </Button>
                  <Button className="addnewsection" onClick={() => this.setState({
                    SectionNumber: this.state.CompanySection.length + 1,
                    CompanyModel: true
                  })}>
                    <Typography>{this.t("EditProject.AddCompanysection.AddCompanySection")}</Typography>
                  </Button>
                </div>
              </div>
              {this.state.ParentContentData.map((item: any, index: any) => {
                return (
                  <div className="AllSection" key={`${item.heading + index + 1}`} data-testid="SectionContainerde1">
                    <div className="SectionHeading">
                      <div>
                        <Typography variant="h4">
                          {item.heading}
                        </Typography>
                      </div>
                    </div>
                    <div style={{ marginLeft: "30px" }} data-testid="SectionContainer1dsssd">
                      <Typography variant="subtitle1" dangerouslySetInnerHTML={this.HtmlConverter(
                        item.content
                      )} />
                    </div>
                  </div>
                )
              })}
              {this.state.CompanySection.map((item: any, index: any) => {
                return (
                  this.CompanySectionMap(item, index)
                )
              })}
            </div>
          </Grid>
        }
        {this.ViewFileModel()}
      </Grid>
    )
  }

  CompanySectionMap = (item: any, index: any) => {
    return (
      <div className="AllSection" key={`${item.heading + index + 1}`}>
        <div className="SectionHeading">
          <div>
            <Typography variant="h4">
              {item.heading}
            </Typography>
          </div>
          <Button className="addnewsection" style={{ textDecoration: "none" }} onClick={() =>
            this.addCompanyHandleClick(index)}>
            <EditIconForSection />
            <Typography>
              {this.t("EditProject.AddCompanysection.EditDetails")}
            </Typography>
          </Button>
        </div>
        <div>
          <Typography variant="h5">
            {item.title}
          </Typography>
        </div>
        <div style={{ marginLeft: "30px" }}>
          <Typography variant="subtitle1" dangerouslySetInnerHTML={this.HtmlConverter(
            item.content
          )} />
        </div>
        <div className="MediaContainer">
          <div className="AllMediaSection">
            {item.uploadFile.map((fileRender1: any, index: any) => {
              let Type;
              if (fileRender1?.url !== undefined) {
                Type = fileRender1?.url.includes("video") ? "video" : "Image"
              } else {
                Type = fileRender1?.includes("video") ? "video" : "Image"
              }
              return (
                <Tooltip id='videoSelectors' data-test-id='videoSelectors' className='videoSelectors' title={this.t('ProjectsController.ViewFile')} key={fileRender1} placement="bottom" onClick={() => this.OpenSectionFiles(fileRender1)}>
                  {Type.includes("video") ? <PlayCircleOutlineIcon style={{ width: "60px", height: "60px" }} /> :
                    <img style={{ width: "60px", height: "60px" }} src={fileRender1.url !== undefined ? fileRender1.url : fileRender1} alt={`${fileRender1 + index + 1}`} />
                  }
                </Tooltip>
              )
            }
            )}
          </div>
          <div style={{ display: 'flex', alignItems: 'flex-end' }}>
            <Button data-testid="editaddnewsection" className="addnewsection" style={{ color: "#fa6c7d", paddingBottom: '0px' }}
              onClick={() => this.RemoveSection(item)} ><Typography>
                {this.t("EditProject.AddCompanysection.RemoveSection")}
              </Typography>
            </Button>
          </div>
        </div>
      </div>)
  }
  AddCompanyModel = () => {
    return (
      <Dialog
        open={this.state.CompanyModel}
        fullWidth={false}
        className="setScoreRating"
        transitionDuration={0}
        onClose={() => { }}
        scroll="body"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        maxWidth={"lg"}
        PaperProps={{
          style: {
            width: "100%",
          },
        }}
      >
        <DialogContent style={{ padding: "0px", width: "100%" }}>
          {this.state.AddParentContentModel === "EditParent" &&
            this.Parentformik()
          }
          {this.state.ParentContentData.length !== 0 && this.state.AddParentContentModel !== "EditParent" &&
            this.subSectionFormik()
          }
        </DialogContent>
      </Dialog>
    )
  }

  Parentformik = () => {
    return (
      <StyleNewPRoject>
        <Box
          style={{
            textAlign: "right",
            margin: "10px 10px 0px 0px",
          }}
        >
          <CloseIcon
            onClick={() =>
              this.parentHandleClick()

            }
            style={{
              cursor: "pointer",
              fontSize: "35px",
              color: "#787878",
            }}
          />
        </Box>
        <Box padding={{ xs: "15px 25px 25px", sm: "15px 40px 40px" }}>
          <Box
            mb="20px"
            style={{ fontFamily: "ProximaNova-Regular" }}
            id="modal-title"
            color="#111"
            fontSize={{ xs: "20px", sm: "30px" }}
            fontFamily="ProximaNova-Bold"
            fontWeight="bolder"
            data-testid="SectionContainer1dssfd"
          >
            {this.t("EditProject.AddCompanysection.ParentSection")}
          </Box>
          <Formik
            onSubmit={(values, { setSubmitting }) => {
              this.handleSbmits(values)

            }}
            initialValues={{
              heading: this.state.ParentContentData[0]?.heading || "",
              content: this.state.ParentContentData[0]?.content || ""
            }}
            validationSchema={this.AddParentsectionuploadSchema}
            validateOnChange={true}
            validateOnBlur={true}
          >
            {(props) => (
              <form
                id="my-form"
                onSubmit={props.handleSubmit}
                noValidate
                autoComplete="off"
                data-testid="SectionContainer1sdsdfd"
              >
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12}>
                    <Box>
                      <Typography
                        variant="body2"
                        style={{ marginBottom: "5px", fontFamily: "ProximaNova-Regular" }}
                      >
                        {this.t("EditProject.AddCompanysection.Heading")}
                      </Typography>
                      <TextField
                        placeholder={this.t("EditProject.AddCompanysection.PlaceholderHeading")}
                        className="client-textfields"
                        name="heading"
                        id="heading"
                        value={props.values.heading}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        variant="outlined"
                        fullWidth
                      />
                    </Box>
                    {this.getErrorMessage(
                      props.touched,
                      props.errors,
                      "heading"
                    )}
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Typography
                      style={{ marginBottom: "5px", fontFamily: "ProximaNova-Regular" }}
                      variant="body2"
                    >
                      {this.t("EditProject.AddCompanysection.HTML")}
                      <span data-testid="AddCompanysection.HTML23" style={{ color: "red" }}>*</span>
                    </Typography>
                    <div data-testid="AddCompanysection.HTML23sdfkjdsfdfh">
                      <ReactQuill
                        placeholder={this.t("EditProject.AddCompanysection.PlaceholderHTML")}
                        theme="snow"
                        id="content"
                        value={props.values.content}
                        onChange={(e: any) => props.setFieldValue('content', e)}
                        formats={[
                          "header",
                          "bold",
                          "italic",
                          "underline",
                          "strike",
                          "blockquote",
                          "list",
                          "bullet",
                          "indent",
                          "link",
                          "image",
                        ]}
                        modules={{
                          toolbar: [
                            [{ header: [1, 2, 3, 4, 5, 6, 7] }],
                            [
                              "bold",
                              "italic",
                              "underline",
                              "strike",
                              "blockquote",
                            ],
                            [
                              { list: "ordered" },
                              { list: "bullet" },
                              { indent: "-1" },
                              { indent: "+1" },
                            ],
                            ["link"],
                            ["clean"],
                          ],
                        }}
                      />
                    </div>
                    {this.getErrorMessage(
                      props.touched,
                      props.errors,
                      "content"
                    )}
                  </Grid>
                </Grid>
                <Box mt="30px" display="flex" justifyContent="end">
                  <CancelButton
                    style={{
                      marginLeft: LanguageData ? "10px" : ""
                    }}
                    onClick={() => this.cancelBtnClick()}
                    className="cancelBtn"
                  >
                    {this.t("EditProject.button.Cancel")}
                  </CancelButton>
                  <SaveButton form="my-form" type="submit">{this.t("EditProject.button.SAVE")}</SaveButton>
                </Box>
              </form>
            )}
          </Formik>
        </Box>
      </StyleNewPRoject>
    )
  }

  subSectionFormik = () => {
    return (
      <StyleNewPRoject>
        <Box
          style={{
            textAlign: "right",
            margin: "10px 10px 0px 0px",
          }}
        >
          <CloseIcon
            onClick={() =>
              this.setState({
                CompanyModel: !this.state.CompanyModel,
              })
            }
            style={{
              cursor: "pointer",
              fontSize: "35px",
              color: "#787878",
            }}
          />
        </Box>
        <Box
          padding={{ xs: "15px 25px 25px", sm: "15px 40px 40px" }}
        >
          <Box
            id="modal-title"
            color="#111"
            fontSize={{ xs: "20px", sm: "30px" }}
            fontFamily="ProximaNova-Bold"
            mb="20px"
            fontWeight="bolder"
            style={{ fontFamily: "ProximaNova-Regular" }}
          >
            {this.t("EditProject.AddCompanysection.Section")} {this.state.CompanySection.length === 0 ? 1 : this.state.SectionNumber}
          </Box>
          <Formik
            validationSchema={this.sectionuploadSchema}
            validateOnChange={true}
            onSubmit={(values, { setSubmitting }) => {
              this.AddDatainSections(values)
            }}
            initialValues={{
              heading: this.state.CompanySection[this.state.EditSectionIndex]?.heading || "",
              title: this.state.CompanySection[this.state.EditSectionIndex]?.title || "",
              content: this.state.CompanySection[this.state.EditSectionIndex]?.content || "",
              uploadFile: this.state.CompanySection[this.state.EditSectionIndex]?.uploadFile || [],
            }}
            validateOnBlur={true}
          >
            {(props) => (
              <form
                id="my-form"
                onSubmit={props.handleSubmit}
                noValidate
                autoComplete="off"
                onDragEnter={this.handleDrag} onDragOver={this.handleDrag}
              >
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <Box>
                      <Typography
                        variant="body2"
                        style={{ marginBottom: "5px", fontFamily: "ProximaNova-Regular" }}
                      >
                        {this.t("EditProject.AddCompanysection.Heading")}
                      </Typography>
                      <TextField
                        name="heading"
                        id="heading"
                        className="client-textfields"
                        variant="outlined"
                        fullWidth
                        value={props.values.heading}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        placeholder={this.t("EditProject.AddCompanysection.PlaceholderHeading")}
                      />
                    </Box>
                    {this.getErrorMessage(
                      props.touched,
                      props.errors,
                      "heading"
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Box>
                      <Typography
                        variant="body2"
                        style={{ marginBottom: "5px", fontFamily: "ProximaNova-Regular" }}
                      >
                        {this.t("EditProject.AddCompanysection.Title")}
                      </Typography>
                      <TextField
                        name="title"
                        onBlur={props.handleBlur}
                        placeholder={this.t("EditProject.AddCompanysection.PlaceholderTitle")}
                        className="client-textfields"
                        variant="outlined"
                        fullWidth
                        id="title"
                        value={props.values.title}
                        onChange={props.handleChange}
                      />
                    </Box>
                    {this.getErrorMessage(
                      props.touched,
                      props.errors,
                      "title"
                    )}
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Typography variant="body2" style={{ marginBottom: "5px", fontFamily: "ProximaNova-Regular" }}>{this.t("EditProject.AddCompanysection.HTML")}<span style={{ color: "red" }}>*</span></Typography>
                    <div data-testid="AddCompanysectionplaceholder">
                      <ReactQuill
                        onChange={(e: any) => props.setFieldValue('content', e)}
                        placeholder={this.t("EditProject.AddCompanysection.PlaceholderHTML")}
                        theme="snow"
                        modules={{
                          toolbar: [
                            [{ header: [1, 2, 3, 4, 5, 6, 7] }],
                            [
                              "bold",
                              "italic",
                              "underline",
                              "strike",
                              "blockquote",
                            ],
                            [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" },], ["link"], ["clean"],],
                        }}
                        formats={[
                          "header",
                          "bold",
                          "italic", "underline", "strike", "blockquote", "list",
                          "bullet",
                          "indent",
                          "link",
                          "image",
                        ]}
                        id="content"
                        value={props.values.content}
                      />
                    </div>
                    {this.getErrorMessage(
                      props.touched,
                      props.errors,
                      "content"
                    )}
                  </Grid>
                </Grid>
                {this.UploadImageEdit(props)}
                <Box mt="30px" display="flex" justifyContent="end">
                  <CancelButton
                    className="cancelBtn"
                    onClick={() =>
                      this.setState({
                        CompanyModel: false,
                      })
                    }
                    style={{
                      marginLeft: LanguageData ? "10px" : ""
                    }}
                  >
                    {this.t("EditProject.button.Cancel")}
                  </CancelButton>
                  <SaveButton form="my-form" type="submit">
                    {this.t("EditProject.button.SAVE")}
                  </SaveButton>
                </Box>
              </form>
            )}
          </Formik>
        </Box>
      </StyleNewPRoject>
    )
  }

  UploadImageEdit = (props: any) => {
    return (
      <Grid container spacing={3}>
        {this.ImageSectionUploadRender(props)}
        <Grid item xs={2} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          <Typography variant="body2" style={{ display: "flex", alignItems: "center", justifyContent: "center" }} data-testid="AddCompanySectidsdsdsonCreates">
            {this.t("ProjectsController.OR")}</Typography>
        </Grid>
        <Grid item xs={5}>
          <Typography variant="body2" style={{ marginBottom: "5px", fontFamily: "ProximaNova-Bold", fontWeight: "bold" }}
          >{this.t("EditProject.AddCompanysection.UploadVideo")} <span style={{ color: "red" }}>*</span></Typography>
          <Button className="dragElement" disabled={props.values.uploadFile.length !== 0} style={{ width: '100%' }}>
            <input onBlur={props.handleBlur} type="file" name="VideoUpload" id="VideoUpload" data-testid="creaetedfileuploadcreaetedfileuploadcompany" accept="video/*" style={{ display: "none" }}
              onChange={(e: any) => {
                this.videosHandleChange(e, props)
              }}
            />
            <label htmlFor="VideoUpload" style={{ width: "100%" }} className={this.state.dragActive ? "drag-active label-file-upload" : "label-file-upload"}><Box textAlign="center" className='customUploadAreaCompany'><div style={{ display: 'flex', alignItems: 'center', textTransform: "capitalize" }}><InsertDriveFileOutlined /><Box style={{ fontWeight: "bold" }}>{this.t("EditProject.TextField.DropOrBrowseYourFileHere")}</Box></div></Box></label>
            {this.state.dragActive && <div className="drag-file-element" onDragEnter={(event) => this.handleDrag(event)} onDragLeave={(event) => this.handleDrag(event)} onDragOver={(event) => this.handleDrag(event)} onDrop={(event) => this.handleDrop(event, props, "video")}></div>}
          </Button>
          {this.getErrorMessage(
            props.touched,
            props.errors,
            "uploadFile"
          )}
        </Grid>
        <Grid item xs={12}>
          {props.values.uploadFile.length !== 0 &&
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <SaveButton onClick={() => this.OpenSectionFiles(props.values.uploadFile[0])}>
                {this.t("EditProject.AddCompanysection.ViewUploadedFile")}
              </SaveButton>
              <SaveButton onClick={() => props.setFieldValue('uploadFile', [])} >
                {this.t("EditProject.AddCompanysection.DeleteUploadedFile")}
              </SaveButton>
            </div>
          }
        </Grid>
      </Grid>
    )
  }

  ImageSectionUploadRender = (props: any) => {
    return (
      <Grid item xs={5}>
        <Typography
          variant="body2"
          style={{ marginBottom: "5px", fontFamily: "ProximaNova-Bold", fontWeight: "bold" }}
        >
          {this.t("EditProject.AddCompanysection.UploadImage")}
          <span style={{ color: "red" }}>*</span>
        </Typography>
        <Button className="dragElement" disabled={props.values.uploadFile.length !== 0} style={{ width: '100%' }}>
          <input
            data-testid="creaetedfileuploadcompanyfdfdfd"
            type="file"
            accept="image/*"
            name="ImageUpload"
            id="ImageUpload"
            style={{ display: "none" }}
            onChange={(e: any) => {
              this.imagesHandleChange(e, props)
            }}
            onBlur={props.handleBlur}
          />
          <label htmlFor="ImageUpload" style={{ width: "100%" }} className={this.state.dragActive ? "drag-active label-file-upload" : "label-file-upload"}>
            <Box textAlign="center" className='customUploadAreaCompany'>
              <div style={{ display: 'flex', alignItems: 'center', textTransform: "capitalize" }}>
                <InsertDriveFileOutlined />
                <Box style={{ fontWeight: "bold" }}>
                  {this.t("EditProject.TextField.DropOrBrowseYourFileHere")}
                </Box>
              </div>
            </Box>
          </label>
          {this.state.dragActive && <div className="drag-file-element" onDragEnter={(event) => this.handleDrag(event)} onDragLeave={(event) => this.handleDrag(event)} onDragOver={(event) => this.handleDrag(event)} onDrop={(event) => this.handleDrop(event, props, "image")}></div>}
        </Button>
        {this.getErrorMessage(
          props.touched,
          props.errors,
          "uploadFile"
        )}
      </Grid>
    )
  }

  ViewFileModel = () => {
    return (
      <Dialog
        open={this.state.ViewFileModel}
        onClose={() => this.setState({
          ViewFileModel: !this.state.ViewFileModel,
        })}
        scroll="body"
        fullWidth={false}
        className="setScoreRating"
        transitionDuration={0}
        aria-describedby="scroll-dialog-description"
        maxWidth={"lg"}
        PaperProps={{
          style: {
            width: "100%",
          },
        }}
        aria-labelledby="scroll-dialog-title"
      >
        <DialogContent style={{ padding: "0px", width: "100%" }}>
          <StyleNewPRoject>
            <Box
              style={{ textAlign: "right", margin: "10px 10px 0px 0px" }}>
              <CloseIcon onClick={() => this.closeHandleClick()} style={{ cursor: "pointer", fontSize: "35px", color: "#787878", }} />
            </Box>
            <Box padding={{ xs: "15px 25px 25px", sm: "15px 40px 40px" }}>
              {this.state.FileType === "video" ?
                <div className="FilevideoRender"><video controls><source src={this.state.ViewFileSource} type="video/mp4" /></video></div> :
                <img
                  height="500px"
                  width="100%"
                  alt="true"
                  src={this.state.ViewFileSource}
                />
              }
            </Box>
          </StyleNewPRoject>
        </DialogContent>
      </Dialog>
    )
  }

  render() {
    const { editProject, assessor_id, competencyId } = this.state;
    const toolsList = this.state?.tools
      ?.filter((item: any) => item?.type !== "assessor_lead_tools")
      ?.map((tool: any) => {
        return {
          label: tool?.attributes?.name,
          value: tool.id,
        };
      })
      .filter((item: any) => item?.label !== "Psychometric");

    return (
      <StyleNewPRoject
        style={{ fontFamily: "ProximaNova-Regular" }}
        data-testid="Heading"
      >
        {this.state.loading && <Spinner spinnerModal={this.state.loading} />}
        <AppSidebar type="admin">
          <Box
            margin="35px 0px"
            mr="30px"
            className="createNewProject"
            data-testid="deleteid"
          >
            {editProject && Object.keys(editProject).length > 0 && (
              <>
                <Box
                  display={{ xs: "block", md: "flex" }}
                  justifyContent="space-between"
                  mb="40px"
                >
                  <Box>
                    <Box
                      mb="5px"
                      fontFamily="ProximaNova-Bold"
                      fontSize={{ xs: "24px", sm: "30px", md: "44px" }}
                      color="#1b3c69"
                    >
                      {editProject.name}
                    </Box>
                    <Box color="#1b3c69" fontSize={{ xs: "14px", sm: "20px" }}>
                      {this.t("EditProject.title.SubTitile")} {editProject.name}{" "}
                      {this.t("EditProject.TextField.data")}
                    </Box>
                  </Box>
                </Box>
                <Formik
                  initialValues={{
                    project_name: editProject.name,
                    project_type: editProject.project_type,
                    assessor_id: assessor_id,
                    client_id: editProject.client_id,
                    manager_id: editProject.manager_id,
                    coManager_id: editProject.co_manager_id,
                    start_date: editProject.start_date,
                    end_date: editProject.end_date,
                    pdfFile: this.state.pdfFile,
                    negative_marks: editProject.is_negative_marking
                      ? editProject.negative_mark
                      : "0",
                    isNegative: editProject.is_negative_marking ? "Yes" : "No",
                    competency_id: competencyId,
                  }}
                  validationSchema={this.editProjectSchema}
                  onSubmit={(values, { setSubmitting }) => {
                    const id = this.props.navigation.getParam("id");
                    if (this.state.projectType === "assessor") {
                      this.handleUpdateProject(id, values);
                    } else {
                      if (this.state.finalQuetionForApi.length === 0) {
                        toast.warn(
                          this.t("ProjectsController.PleaseAddQuestions")
                        );
                      } else {
                        this.EditApiCall(id, values);
                      }
                    }
                  }}
                  validateOnChange={true}
                  validateOnBlur={true}
                >
                  {(props) => (
                    <form onSubmit={props.handleSubmit}>
                      <Grid className="label-parent" container spacing={3}>
                        {this.renderProjectname(props)}
                        {this.ProjectTypeRedner(props, editProject)}
                        {this.DcMangerRedner(props)}
                        {this.renderCoManager(props, editProject)}
                        {this.SelectAssessorRender(props, assessor_id)}
                        {this.SelectClientRedner(props)}
                        {this.StartDateRender(props)}
                        {this.EndDateRender(props)}
                        {this.UploadDocument(props)}
                        {this.NegativeMarkingRender(props, competencyId)}
                      </Grid>
                      {this.AddCompanySection()}
                      {this.state.tools.length > 0 &&
                        this.quetionPartStart(props, toolsList)}
                    </form>
                  )}
                </Formik>
              </>
            )}
          </Box>
          {this.RednerDeleteDialog()}
          {/* Rating dialog */}
          {this.RenderRatingDilog()}
          {this.AddCompanyModel()}
        </AppSidebar>
      </StyleNewPRoject>
    );
  }
}

export default withRouter(withTranslation()(EditProject));
const CustomInput = React.forwardRef<any, { value?: any; onClick?: any }>(
  ({ value, onClick }, ref) => (
    <Box position="relative" border="1px solid #d6d6d6" borderRadius="10px">
      <img
        src={calender}
        onClick={onClick}
        style={{
          zIndex: 0,
          position: "absolute",
          right: LanguageData === false ? "12px" : "",
          left: LanguageData ? "12px" : "",
          cursor: "pointer",
          width: "20px",
        }}
      />
      <DateInput
        placeholder="DD/MM/YYYY"
        onClick={onClick}
        value={value}
        className="disabled"
      />
    </Box>
  )
);
const DateInput = styled(InputBase)({
  fontFamily: "ProximaNova-Regular",
  minHeight: "50px",
  paddingLeft: !LanguageData ? "20px" : "",
  paddingRight: LanguageData ? "20px" : "",
  fontSize: "14px",
  width: "100%",
  "& .MuiInputBase-input": {
    "&::placeholder": {
      fontSize: "17px",
    },
  },
});
const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  "& .MuiTooltip-tooltip": {
    margin: "0px 8px",
    fontSize: "13px",
    color: "black",
    border: "1px solid black",
    backgroundColor: "white",
  },
  "& .MuiTooltip-arrow::before": {
    border: "1px solid black",
  },
  "& .MuiTooltip-arrow": {
    color: "white",
  },
}));

const StyleNewPRoject = styled("div")({
  fontFamily: "ProximaNova-Regular !important",
  "& .SetTimerContainer": {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "space-between",
    "& .EditTimePen": {
      marginRight: "10px",
      color: "#f94b4b",
      fontSize: "20px",
    },
    "& p": {
      color: "#f94b4b",
      fontWeight: "bold",
    },
  },
  "& .Projectlabel": {
    fontFamily: "ProximaNova-Regular",
    fontWeight: "900",
    textTransform: "capitalize",
  },
  "& .UnSelectBtn": {
    flex: "10%",
    border: "1px solid black",
    color: "black",
    margin: "5px",
    backgroundColor: "white",
    minWidth: "80px",
  },
  "& .AddOption": {
    fontFamily: "ProximaNova-Regular",
  },
  "& .SelectBtn": {
    color: "white",
    backgroundColor: "#f94b4b",
    margin: "5px",
    minWidth: "80px",
  },
  "& .ButtonContainer": {
    justifyContent: "flex-start",
    display: "flex",
    padding: "5px",
    flexWrap: "wrap",
    marginTop: "6px",
    backgroundColor: "#eff6ff",
  },
  "& .ContainerQuetion": {
    margin: "5px 0",
  },
  "& .panel1bh-header .MuiAccordionSummary-content": {
    flexGrow: "unset",
    width: "100%"
  },
  "& .AcorenHeading": {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& .checkquetion": {
      display: "flex",
      alignItems: "center",
    },
  },
  "& .TextandButton": {
    display: "flex",
    justifyContent: "space-between",
    width: "calc(100% - 68px)",
  },
  "& .InputContainer": {
    "& .MuiSvgIcon-root": {
      width: "50px",
      height: "50px",
      backgroundColor: "white",
      borderRadius: "6px",
    },
    "& .MuiCheckbox-root": {
      borderWidth: "1px",
    },
  },
  "& .quetions": {
    maxWidth: "85%",
    width: "100%",
    margin: "0",
    paddingLeft: "10px",
    boxSizing: "border-box",
    textOverflow: "ellipsis",
    "& h4": {
      display: "inline-block",
      width: "inherit",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      maxWidth: "700px",
      fontFamily: "ProximaNova-Regular",
    },
  },
  "& .CloseAcordian": {
    position: "relative",
  },
  "& .labelAcordin": {
    position: "absolute",
    left: !LanguageData ? "0" : "unset",
    right: LanguageData ? "0" : "unset",
    top: "0px",
    color: "black",
    padding: "2px 10px",
    borderTopLeftRadius: "4px",
    "& h5": {
      fontSize: "16px",
    },
  },
  "& .checkBoxAndQueHeading": {
    width: "100%",
    display: "flex",
    marginTop: "10px",
    flexWrap: "wrap",
    maxWidth: "100%" 
  },
  "& .QuetionRender": {
    display: "flex",
    alignItems: "center",
    maxWidth: "100%",
    overflow: "hidden",
    width: "calc(100% - 155px)",
    "& div": {
      "& h3": {
        fontFamily: "ProximaNova-Regular",
        fontWeight: "400",
      },
    },
  },
  "& .MarksContainer": {
    display: "flex",
    justifyContent: "space-between",
  },
  "& .QuetionTextField": {
    "& input": {
      fontFamily: "ProximaNova-Regular",
      fontWeight: "700",
    },
  },
  "&  .redButton": {
    backgroundColor: "#f94b4b",
    color: "white",
    fontFamily: "ProximaNova-Semibold",
    fontSize: "18px",
    borderRadius: "10px",
    textTransform: "none",
    padding: "5px 25px",
    "&:hover": {
      color: "white",
      backgroundColor: "#f94b4b",
    },
    "@media(max-width:600px)": {
      width: "100%",
      marginTop: "27px",
    },
    "@media(max-width:992px)": {
      padding: "8px 15px",
    },
  },
  '& .videoRender': {
    '& video': {
      width: '100%',
      height: '200px'
    }
  },
  "& .SetRatingContainer": {
    cursor: "pointer",
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "15px",
    padding: "5px",
    "& .EditTimePen": {
      marginRight: "10px",
      color: "#f94b4b",
      fontSize: "20px",
    },
    "& p": {
      color: "#f94b4b",
      fontWeight: "bold",
    },
  },
  '& .CompanysectionMainDiv': {
    marginTop: "10px",
    display: 'flex',
    justifyContent: 'center',
    padding: '10px',
    backgroundColor: '#eff6ff',
    borderRadius: '6px',
    cursor: 'pointer'
  },
  '& .SectionContainer': {
    backgroundColor: '#eff6ff',
    padding: "20px 20px",
    borderRadius: '6px',
    marginTop: "10px",
  },
  '& .SectionHeading': {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: '10px'
  },
  '& .FilevideoRender': {
    '& video': {
      width: '100%',
      height: '500px'
    }
  },
  '& .addnewsection': {
    display: 'flex',
    textDecoration: "underline",
    color: '#1b395c',
    cursor: "pointer",
    textTransform: "capitalize"
  },
  '& .MediaContainer': {
    display: "flex",
    justifyContent: "space-between"
  },
  '& .AllMediaSection': {
    display: "flex",
    '& >div': {
      marginLeft: "5px",
      marginRight: "5px",
      marginTop: "10px",
      borderRadius: "8px",
      cursor: "pointer"
    },
    '& :first-child': {
      marginLeft: '0px'
    },
    '& :last-child': {
      marginRight: "0px"
    }
  },
  '& .AllSection': {
    backgroundColor: "white",
    margin: "10px 0",
    padding: "15px",
    borderRadius: '6px'
  },
  '& .customUploadAreaCompany': {
    width: '100%',
    border: 'dashed',
    cursor: 'pointer',
    overflow: 'hidden',
    position: 'relative',
    boxSizing: 'border-box',
    minHeight: '60px',
    borderColor: 'rgba(0, 0, 0, 0.12)',
    borderRadius: '4px',
    backgroundColor: '#fff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '30px',
    '& .MuiSvgIcon-root': {
      marginBottom: '2px'
    }
  },
  '& .quill': {
    display: 'grid'
  },
  '& .ql-container': {
    minHeight: '100px'
  },
  '& .react-datepicker-wrapper': {
    width: "100%"
  },
});

const CustomTwoInputBase = styled(InputBase)({
  border: "1px solid #d6d6d6",
  backgroundColor: "hsl(0,0%,95%)",
  minHeight: "50px",
  padding: "10px",
  paddingLeft: "15px",
  fontSize: "14px",
  borderRadius: "5px",
  "& .MuiInputBase-input": {
    "&::placeholder": {
      fontSize: "14px",
    },
  },
});

const CustomInputBase = styled(InputBase)({
  border: "1px solid #d6d6d6",
  backgroundColor: "white",
  minHeight: "50px",
  borderRadius: "10px",
  padding: "10px 15px",
  fontSize: "14px",
  "& .MuiInputBase-input": {
    "&::placeholder": {
      fontSize: "14px",
    },
  },
});
const CancelButton = styled(Button)({
  fontFamily: "ProximaNova-Semibold",
  color: "#f26a42",
  borderRadius: "10px",
  marginRight: "30px",
  fontSize: "18px",
  padding: "7px 15px",
  backgroundColor: "#fff",
  border: "1px solid #f26a42",
  textTransform: "none",
});

const SaveButton = styled(Button)({
  fontFamily: "ProximaNova-Semibold",
  borderRadius: "10px",
  fontSize: "18px",
  backgroundColor: "#f26a42",
  color: "#fff",
  padding: "7px 22px",
  border: "1px solid #f26a42",
  textTransform: "none",
  "&:hover": {
    color: "#fff",
    backgroundColor: "#f26a42",
  },
  "&.MuiButton-root.Mui-disabled": {
    border: "1px solid hsl(0,0%,60%)",
    color: "hsl(0,0%,60%)",
    backgroundColor: "hsl(0,0%,95%)",
    cursor: "no-drop",
    pointerEvents: "auto",
  },
});

const DeleteDialog = styled(Dialog)({
  "& .SelectToolName": {
    "& .react-select__menu-list": {
      zindex: 99,
      maxHeight: "170px",
    },
  },
  "& .ModelMainDiv": {
    "& div": {
      width: "100%",
    },
  },
  "& .MuiDialog-paper": {
    minWidth: "500px",
  },
  "@media(max-width:576px)": {
    margin: "0px",
  },
  margin: "0px 61px",
});

// Customizable Area End
