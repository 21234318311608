// Customizable Area Start
import React from "react";
import {
  Box,
  Button,
  InputBase,
  Grid,
  TextField,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Dialog,
  DialogContent,
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import { styled } from "@material-ui/styles";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import * as Yup from "yup";
import { Formik } from "formik";
import { withTranslation } from "react-i18next";
import SectorController, { Props } from "./SectorController.web";
import AppSidebar from "../../../../../components/src/AppSidebar.web";
import Spinner from "../../../../../components/src/Spinner.web";
const LanguageData = localStorage.getItem("language") === "ar";

const editIcon = require("../../../assets/edit.png");
const deleteIcon = require("../../../assets/delete.png");

class Sector extends SectorController {
  constructor(props: Props) {
    super(props);
  }

  sectorSchema = () => {
    return Yup.object().shape({
      sectorName: Yup.string().required(
        this.t("organizationFunction.sector.errorMessages.name")
      ),
    });
  };

  TableRender = () => {
    return (
      <Table
        style={{
          border: "1px solid #E0E0E0",
          borderRadius: "10px",
          borderCollapse: "separate",
        }}
        aria-label="simple table"
        className="custom-table"
      >
        <TableHead>
          <TableRow>
            <TableCell
              style={{
                fontFamily: "ProximaNova-Bold",
                fontSize: "16px",
              }}
              align={LanguageData ? "right" : "left"}
            >
              {this.t(
                "organizationFunction.sector.label.tableName"
              )}
            </TableCell>
            <TableCell
              style={{
                fontFamily: "ProximaNova-Bold",
                fontSize: "16px",
              }}
              align={LanguageData ? "left" : "right"}
            >
              {this.t("organizationFunction.sector.label.action")}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {this.state.data.length > 0 ? (
            <>
              {this.state.data.map((item: any, index: number) => (
                <TableRow key={`${index + 1}`}>
                  <TableCell
                    style={{
                      color: "#000",
                      fontFamily: "ProximaNova-Regular",
                      fontSize: "18px",
                    }}
                    align={LanguageData ? "right" : "left"}
                  >
                    {item.sectorName}
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#000",
                      fontSize: "18px",
                      fontFamily: "ProximaNova-Regular",
                    }}
                    align={LanguageData ? "left" : "right"}
                  >
                    <img
                      src={editIcon}
                      id="edit"
                      style={{
                        marginRight: "10px",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        this.setState({
                          sectorName: item.sectorName,
                          sectorId: item.id,
                          openDialogName: "Edit",
                        })
                      }
                    />
                    <img
                      src={deleteIcon}
                      id="delete"
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        this.setState({
                          sectorId: item.id,
                          openDialogName: "Delete",
                          sectorName: item.sectorName,
                        })
                      }
                    />
                  </TableCell>
                </TableRow>
              ))}
            </>
          ) : (
            <TableRow>
              <TableCell
                align="center"
                colSpan={6}
                style={{
                  fontFamily: "ProximaNova-Regular",
                  color: "#000",
                  fontSize: "18px",
                  whiteSpace: "nowrap",
                }}
              >
                {this.t(
                  "organizationFunction.sector.label.notFound"
                )}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    )
  }

  EditAddDilogRender = () => {
    return (
      <Dialog
        open={["Add", "Edit"].includes(this.state.openDialogName ?? "")}
        scroll="body"
        maxWidth={"sm"}
        transitionDuration={0}
        onClose={() => { }}
        aria-labelledby="scroll-dialog-title"
        fullWidth={false}
        aria-describedby="scroll-dialog-description"
        PaperProps={{
          style: {
            width: "500px",
          },
        }}
      >
        <DialogContent style={{ padding: "0px", width: "100%" }}>
          <Box style={{ textAlign: "right", margin: "10px 10px 0px 0px" }}>
            <CloseIcon
              style={{
                cursor: "pointer",
                fontSize: "35px",
                color: "#787878",
              }}
              onClick={() =>
                this.setState({
                  sectorName: "",
                  openDialogName: null,
                  sectorId: null,
                })
              }
            />
          </Box>
          <Box padding={{ xs: "15px 25px 25px", sm: "15px 40px 40px" }}>
            <Box
              fontSize={{ xs: "20px", sm: "30px" }}
              fontFamily="ProximaNova-Bold"
              fontWeight="bolder"
              id="modal-title"
              mb="20px"
              color="#1b3c69"
              style={{ textAlign: LanguageData ? "right" : "left" }}
            >
              {this.t(
                `organizationFunction.sector.title.${this.state.openDialogName}`
              )}
            </Box>
            <Formik
              initialValues={{
                sectorName: this.state.sectorName,
              }}
              validationSchema={this.sectorSchema}
              onSubmit={(values) => {
                this.state.openDialogName === "Add"
                  ? this.addSector()
                  : this.editSector();
              }}
            >
              {({ errors, touched, setFieldValue, handleSubmit }) => (
                <form onSubmit={handleSubmit} noValidate autoComplete="off">
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <div style={{ marginBottom: "5px", fontSize: "18px", textAlign: LanguageData ? "right" : "left" }}>
                        {this.t("organizationFunction.sector.label.name")}
                        <span
                          style={{ color: "red" }}>*</span>
                      </div>
                      <CustomInputBase
                        placeholder={this.t(
                          "organizationFunction.sector.placeholder.name"
                        )}
                        fullWidth
                        value={this.state.sectorName}
                        onChange={(event: any) => {
                          setFieldValue("sectorName", event.target.value);
                          this.setState({ sectorName: event.target.value });
                        }}
                      />
                      {touched.sectorName && errors.sectorName && (
                        <div className="text-danger" style={{ textAlign: LanguageData ? "right" : "left" }}>{errors.sectorName}</div>
                      )}
                    </Grid>
                  </Grid>
                  <Box
                    mt="30px"
                    justifyContent="end"
                    display={{ xs: "block", sm: "flex" }}
                  >
                    <CustomButton
                      className="cancelBtn"
                      onClick={() =>
                        this.setState({
                          sectorName: "",
                          sectorId: null,
                          openDialogName: null,
                        })}>
                      {this.t("organizationFunction.sector.button.cancel")}
                    </CustomButton>
                    <RedButton
                      style={{
                        marginRight: LanguageData ? "10px" : ""
                      }}
                      type="submit">
                      {this.t(
                        `organizationFunction.sector.button.${this.state.openDialogName}`
                      )}
                    </RedButton>
                  </Box>
                </form>
              )}
            </Formik>
          </Box>
        </DialogContent>
      </Dialog>
    )
  }

  DeleteDilogRendere = () => {
    return (
      <Dialog
        fullWidth={false}
        maxWidth={"sm"}
        open={this.state.openDialogName === "Delete"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        scroll="body"
        transitionDuration={0}
        onClose={() => { }}
      >
        <DialogContent style={{ padding: "0px", maxWidth: "500px" }}>
          <Box
            style={{
              paddingTop: "0px !important",
              textAlign: "right",
              margin: "10px 10px 0px 0px",
            }}
          >
            <CloseIcon
              onClick={() =>
                this.setState({
                  sectorId: null,
                  sectorName: "",
                  openDialogName: null,
                })
              }
              style={{
                fontSize: "35px",
                cursor: "pointer",
                color: "#787878",
              }}
            />
          </Box>
          <Box
            alignItems="center"
            display="flex"
            flexDirection="column"
            padding={{ xs: "15px 25px 25px", sm: "15px 70px 40px" }}
          >
            <Box
              id="modal-title"
              mb="20px"
              fontWeight="bolder"
              fontSize={{ xs: "20px", sm: "30px" }}
              fontFamily="ProximaNova-Bold"
            >
              {this.t("organizationFunction.sector.title.delete")}
            </Box>
            <Box textAlign="center"
              fontSize="18px">
              {this.t("organizationFunction.sector.description.delete", {
                value: this.state.sectorName,
              })}
            </Box>
            <Box mt="30px" display="flex">
              <CustomButton
                onClick={() =>
                  this.setState({
                    sectorName: "",
                    sectorId: null,
                    openDialogName: null,
                  })
                }
                style={{ marginBottom: "0px", padding: "5px 10px" }}
                className="cancelBtn"
              >
                {this.t("organizationFunction.sector.button.cancel")}
              </CustomButton>
              <RedButton
                onClick={this.deleteSector}
                style={{ padding: "5px 10px", marginRight: LanguageData ? "10px" : "" }}
              >
                {this.t("organizationFunction.sector.button.delete")}
              </RedButton>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    )
  }

  render() {
    return (
      <SectorStyle style={{ fontFamily: "ProximaNova-Regular" }}>
        {this.state.loading && <Spinner spinnerModal={this.state.loading} />}
        <AppSidebar type="admin" openOrganization>
          <Box margin="35px 0px" mr="30px">
            <Box mb="40px" display={{ xs: "block", sm: "flex" }} justifyContent="space-between">
              <Box data-testid="sectoer1">
                <Box
                  fontFamily="ProximaNova-Bold"
                  fontSize={{ xs: "24px", sm: "30px", md: "44px" }}
                  mb="5px"
                  color="#1b3c69"
                >
                  {this.t("organizationFunction.sector.title.main")}
                </Box>
                <Box color="#1b3c69" fontSize={{ xs: "14px", sm: "20px" }}>
                  {this.t("organizationFunction.sector.description.main")}
                </Box>
              </Box>
              <Box mt={{ xs: "20px", sm: "0px" }}>
                <RedButton
                  style={{ backgroundColor: "#f26a42" }}
                  onClick={() => this.setState({ openDialogName: "Add" })}
                >
                  {" "}
                  {this.t("organizationFunction.sector.button.Add")}{" "}
                </RedButton>{" "}
              </Box>
            </Box>
            <Box mt="60px">
              <Box>
                <Grid container>
                  <Grid item xs={12} className={LanguageData ? "SearchIconRightSide" : ""}>
                    <Box
                      mb="25px"
                      display="flex"
                      alignItems="center"
                      justifyContent="end"
                    >
                      <TextField
                        InputLabelProps={{
                          style: {
                            fontFamily: "ProximaNova-Regular",
                            zIndex: 0,
                          },
                        }}
                        label={this.t(
                          "organizationFunction.sector.label.search"
                        )}
                        onChange={(event: any) => {
                          this.onChangeHandler(event.target.value, 0);
                        }}
                        id="search"
                        variant="outlined"
                        InputProps={{
                          endAdornment: <SearchIcon />,
                        }}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} style={{ overflow: "auto" }}>
                    {this.TableRender()}
                    {this.state.data.length > 0 && (
                      <div className="custom-pagination">
                        <Pagination
                          page={this.state.page + 1}
                          count={Math.ceil(this.state.dataLength / this.state.rowsPerPage)}
                          onChange={(event: unknown, newPage: number) => { this.onChangeHandler(this.state.search, newPage - 1); }}
                          variant="outlined"
                          showFirstButton
                          dir="ltr"
                          showLastButton
                          shape="rounded"
                          siblingCount={0}
                          boundaryCount={1}
                        />
                      </div>
                    )}
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>
        </AppSidebar>

        {/* for add/edit user */}
        {this.EditAddDilogRender()}


        {/* for delete */}
        {this.DeleteDilogRendere()}
      </SectorStyle>
    );
  }
}

export default withTranslation()(Sector);

const CustomInputBase = styled(InputBase)({
  minHeight: "45px",
  border: "1px solid #787878",
  paddingLeft: "15px",
  borderRadius: "10px",
  fontSize: "16px",
  "& .MuiInputBase-inputMultiline": {
    "&::placeholder": {
      fontSize: "17px",
    },
  },
});

const SectorStyle = styled('div')({
  '& .SearchIconRightSide': {
    '& .MuiInputBase-root': {
      display: "flex",
      flexDirection: "row-reverse"
    }
  }
})

const CustomButton = styled(Button)({
  color: "#f26a42",
  borderRadius: "8px",
  border: "1px solid #f26a42",
  textTransform: "none",
  fontFamily: "ProximaNova-Semibold",
  padding: "7px 17px",
  backgroundColor: "white",
  marginRight: "15px",
  fontSize: "18px",
  "&:hover": {
    color: "#f26a42",
    backgroundColor: "white",
  },
  "@media(max-width:600px)": {
    width: "100%",
  },
  "@media(max-width:992px)": {
    padding: "7px 15px",
  },
});

const RedButton = styled(Button)({
  fontFamily: "ProximaNova-Semibold",
  borderRadius: "10px",
  padding: "9px 30px",
  backgroundColor: "#f94b4b",
  fontSize: "18px",
  textTransform: "none",
  color: "white",
  "&:hover": {
    color: "white",
    backgroundColor: "#f94b4b",
  },
  "@media(max-width:600px)": {
    width: "100%",
    marginTop: "27px",
  },
});
// Customizable Area End
