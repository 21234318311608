

// Customizable Area Start
import React from 'react'
import {Box,Typography} from '@material-ui/core'
import {shrm} from './assets'
import './ThankyouPage.web.css'
// Customizable Area End

    
  
// Customizable Area Start

class Thankyou extends React.Component{
    render(): React.ReactNode {
        return(
            <>
           <Box className='thankyou'>
             <Box className='box-logo'>
                <img style={{color:"transparent"}} src={shrm} alt="shrm_logo" />
            </Box>
            <Box className='thanks-bold-parent'>
                <Typography className='thanks-typo'>Dear Participant,</Typography>
                <Typography className='thanks-bold'>Thank You!</Typography>
                <Typography className='thanks-typo'>You have successfully completed your assessment.</Typography>
            </Box>
            <Box className='thanks-section'>
                <Typography className="thanksss">Thanks,</Typography>
                <Typography className='thanksss'>The SHRM Team</Typography>
            </Box>
           </Box>
           <footer className='thanks-footer'>
                <Typography className='footer-text'>© 2021 SHRM. All Rights Reserved</Typography>
           </footer>
           </>
        )
    }
}

export default Thankyou

// Customizable Area End