// Customizable Area Start
//Fullcalendar, Sweetalert modules imported
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";

import interactionPlugin from "@fullcalendar/interaction"; // needed for dayClick
import Swal from "sweetalert2";
import React from "react";
import AppSidebar from "../../../components/src/AppSidebar.web";
import Typography from "@material-ui/core/Typography";
import { Snackbar, Grid } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import MuiAlert from "@material-ui/lab/Alert";
import Button from "@material-ui/core/Button";
import { styled } from "@material-ui/styles";
import Box from "@material-ui/core/Box";
import Modal from "@material-ui/core/Modal";
import timeGridPlugin from '@fullcalendar/timegrid';
import "./AssessorCalendar.web.css";
import AssessorDashboardController from "./AssessorDashboardController.web";
import moment from "moment";
import { withTranslation } from "react-i18next";

// Customizable Area End

// Customizable Area Start
const Alert = (props: any) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

class AssessorCalendar extends AssessorDashboardController {

  RenderModel = () => {
    return (
      <Modal
        open={this.state.SlotModel}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <StyleCalander>
          <Box className="modalStyle" style={{ padding: "30px" }}>
            {/* <div className="imageWrapper">
                <img src={help} className="helpModal" />
              </div> */}
            <Typography
              variant="h6"
              style={{ fontWeight: 600, fontSize: "30px", fontFamily: "ProximaNova-Regular" }}
              component="h1"
            >
              {this.t("assessorCalendar.availability")}
            </Typography>
            <Typography
              className=""
              variant="h6"
              component="h4"
              style={{ paddingBottom: "10px", fontFamily: "ProximaNova-Regular" }}
            >
              {moment(this.state.SelectedDate).format("MMMM Do, YYYY")}{" "}
              {moment(this.state.SelectedDate).format("dddd")}
            </Typography>
            <Grid
              container
              spacing={2}
              style={{ overflowX: "auto", maxHeight: "300px", fontFamily: "ProximaNova-Regular" }}
            >
              {this.state.AllSolts.length > 0 ? (
                this.state.AllSolts.map((item: any, index: any) => {
                  return (
                    <Grid key={item.id} item xs={6}>
                      <div
                        style={{ display: "flex", alignItems: "center" }}
                        className={`${item.attributes.available.includes("available")
                          ? "green"
                          : ""
                          } ${item.attributes.available.includes("booked")
                            ? "red"
                            : ""
                          }`}
                      >
                        <input
                          data-testid={`input${index}`}
                          id={`input${index}`}
                          disabled={
                            Number(
                              moment(item.attributes.start_time)
                                .utc()
                                .format("H")
                            ) < Number(moment().format("H")) &&
                            moment(this.state.SelectedDate).format(
                              "DD-MM-YYYY"
                            ) === moment().format("DD-MM-YYYY")
                          }
                          checked={this.state.AvailableSlot.includes(
                            item.attributes.id
                          )}
                          type="checkbox"
                          onClick={(e: any) => {
                            if (e.target.checked === false) {
                              this.setState({
                                ...this.state,
                                AvailableSlot: this.state.AvailableSlot.filter(
                                  (items: any) => items !== item.attributes.id
                                ),
                                RemoveSlots: this.state.RemoveSlots.concat(
                                  item.attributes.id
                                ),
                                SlotUpdateApiCall: true,
                              });
                            } else {
                              this.setState({
                                ...this.state,
                                BookSlotIds: this.state.BookSlotIds.concat(
                                  item.attributes.id
                                ),
                                RemoveSlots: this.state.RemoveSlots.filter(
                                  (items: any) => items !== item.attributes.id
                                ),
                                AvailableSlot: this.state.AvailableSlot.concat(
                                  item.attributes.id
                                ),
                                SlotUpdateApiCall: true,
                              });
                            }
                          }}
                          name={item.id}
                          style={{ margin:"0px 5px" }}
                          className="CheckBox"
                        />
                        <label htmlFor={item.id} className="SlotTime" dir="ltr">
                          <Button
                            fullWidth
                            variant="outlined"
                            component="span"
                            style={{
                              fontFamily: "ProximaNova-Regular"
                            }}
                            dir="ltr"
                          >
                            {moment(item.attributes.start_time).utc().format(
                              "hh:mm a"
                            )}{" "}
                            to{" "}
                            {moment(item.attributes.end_time).utc().format(
                              "hh:mm a"
                            )}
                          </Button>
                        </label>
                      </div>
                    </Grid>
                  );
                })
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    minHeight: "90px",
                    height: "100%",
                  }}
                >
                  <p>{this.t("assessorCalendar.noSlot")}</p>
                </div>
              )}
            </Grid>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                className="cancelBtn"
                data-testid="cancelBtn"
                onClick={() => {
                  this.setState({
                    SlotModel: false,
                    AllSolts: [],
                  });
                }}
              >
                {this.t("assessorCalendar.cancelBtn")}
              </Button>
              <Button
                className="submitBtn"
                data-testid="submitBtn"
                onClick={() => {
                  this.state.SlotUpdateApiCall
                    ? this.AssignSlotsApiCall()
                    : this.setState({
                      ...this.state,
                      SlotModel: false,
                    });
                }}
              >
                {this.t("assessorCalendar.yesBtn")}
              </Button>
            </div>
          </Box>
        </StyleCalander>
      </Modal>
    )
  }

  render(): React.ReactNode {
    let events = this.state.events.map((event: any) => {
      return {
        title: event.participant_name,
        description: event.description,
        date: moment(event.start).utc().format("YYYY-MM-DD"),
        timeStart: moment(event.start).utc().format("hh:mm A"),
        timeEnd: moment(event.end).utc().format("hh:mm A"),
        color: moment().isAfter(moment(event.start).format("YYYY-MM-DD hh:mm")) ? "green" : "#ADD8E6",
        start: `${moment(event.start).utc().format()}`,
        end: `${moment(event.end).utc().format()}`,
        oragnation: event.title,
        client_name:event.client_name
      };
    });
    return (
      <StyleCalander>
        <AppSidebar>
          <div
            data-testid="Heading"
            className="maindiv"
            style={{ marginBottom: "16px" }}
          >

            <FullCalendar
              // @ts-ignore
              plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
              //Dayclick open sweetalert
              headerToolbar={{
                left: "today",
                center: "prev title next",
                right: "dayGridMonth,timeGridWeek,timeGridDay",
              }}
              firstDay={1}
              slotLabelFormat={{
                hour: "numeric",
                minute: "2-digit",
                meridiem: true,
              }}
              eventClick={(info: any) => {
                Swal.fire({
                  title: info.event.title,
                  text:
                    info.event._def.extendedProps.timeStart +
                    " - " +
                    info.event._def.extendedProps.timeEnd +
                    "\n" +
                    info.event._def.extendedProps.description + ", " + this.t("assessorCalendar.Belongstoorganization") + " : " + info.event._def.extendedProps.client_name
                });
              }}
              timeZone=""
              initialView="dayGridMonth"
              validRange={{
                start: moment().format("YYYY-MM-DD"),
              }}
              locale={`${localStorage.getItem("language") ?? "en"}`}
              dateClick={(date: any) => this.SlotOpen(date)}
              events={events}
              eventTimeFormat={{
                hour: '2-digit',
                minute: '2-digit',
                meridiem: true,
              }}
            />
          </div>
        </AppSidebar>
        {this.RenderModel()}
        <Snackbar
          autoHideDuration={3000}
          onClose={() =>
            this.setState({
              ...this.state,
              AlertModel: false,
            })
          }
          open={this.state.AlertModel}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <Alert
            onClose={() =>
              this.setState({
                ...this.state,
                AlertModel: false,
              })
            }
            severity="success"
            style={{ height: "46px" }}
          >
            {this.state.AlertText}
          </Alert>
        </Snackbar>
      </StyleCalander>
    );
  }
}

export default withRouter(withTranslation()(AssessorCalendar));
const StyleCalander = styled("div")({
  "&  .fc-dayGridMonth-button": {
    textTransform: "capitalize",
  },
  "& .fc-dayGridWeek-button": {
    textTransform: "capitalize",
  },
  "& .SlotTime": {
    width: "100%",
    fontFamily: "ProximaNova-Regular",
    "& button": {
      fontFamily: "ProximaNova-Regular"
    }
  },
  "& .fc-scrollgrid-sync-table": {
    width: "100% !important",
    height: "100% !important",
  },
  "& .fc-daygrid-body-unbalanced": {
    width: "100% !important",
    height: "100% !important",
  },
  "& .fc-col-header": {
    width: "100% !important",
  },
});
// Customizable Area End
