// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
// Customizable Area End


// Customizable Area Start
import { toast } from "react-toastify";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    id?: string;
    navigation?: any;
    location: any;
    history: any;
    match: any;
    // Customizable Area Start
    t: any;
    // Customizable Area End
}

export interface S {
    // Customizable Area Start
    loading: boolean;
    page: number;
    rowsPerPage: number;
    dataLength: number;
    count: number | null;
    item: any;
    sortBy: string;
    option: string;
    search: string;
    sortByClients: any,
    sortByProjects: any,
    openDialogName: string | null;
    data: any;
    clients: any;
    projects: any;
    tableData: any[];
    filterData: any;
    sortByList: any[];
    optionList: any[];
    industryList: any[];
    competencyList: any[];
    onlineToolList: any[];
    isDocOpen: boolean,
    documentName: string,
    documentURL: string,
    Allprojectlist: any[],
    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class AssessorProjectDocumentController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    t: any;
    // Customizable Area End

    getProjectDocumentAPICall: string = "";
    applyFilterAPICall: string = "";

    constructor(props: Props) {super(props);
        this.subScribedMessages = [
                 getName(MessageEnum.RestAPIResponceMessage),
                 getName(MessageEnum.NavigationPayLoadMessage),
                 getName(MessageEnum.CountryCodeMessage),];
                      this.receive = this.receive.bind(this);
        
                           runEngine.attachBuildingBlock(this, this.subScribedMessages);
this.state = {
            // Customizable Area Start
            dataLength: 0,
            count: null,
            item: null,
            sortBy: "",
            option: "",
            loading: true,
            page: 0,
            rowsPerPage: 5,
            tableData: [],
            filterData: [],
            sortByList: [],
            search: "",
            openDialogName: "",
            sortByClients: "",
            sortByProjects: "",
            data: [],
            clients: [],
            projects: [],
            Allprojectlist: [],
            optionList: [],
            industryList: [],
            competencyList: [],
            onlineToolList: [],
            isDocOpen: false,
            documentName: "",
            documentURL: ""
            // Customizable Area End
        };
        // Customizable Area Start
        this.t = this.props.t;
        // Customizable Area End
    }


    // Customizable Area Start
    async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId === this.getProjectDocumentAPICall) {
                if (responseJson != null && !responseJson.errors) {
                    let arrayProjectdoc: any = []
                    responseJson.data.map((item: any) => (
                        item.attributes.documents.data.map((row: any) => {
                            let obj = {
                                id: row.id,
                                name: row.attributes.name,
                                project_name: row.attributes.project_name,
                                client_name: row.attributes.client_name,
                                modified_at: row.attributes.modified_at,
                                document_url: row.attributes.document_url,
                                project_live_on: row.attributes.project_live_on
                            }
                            arrayProjectdoc.push(obj)
                        })
                    ))
                    this.setState({ data: arrayProjectdoc, filterData: arrayProjectdoc, loading: false })
                    this.setState({ clients: responseJson.clients, projects: responseJson.projects, loading: false, Allprojectlist: responseJson.projects, })
                } else {
                    this.setState({ filterData: [], data: [], loading: false })
                }
            }

            if (apiRequestCallId === this.applyFilterAPICall) {
                if (responseJson != null && !responseJson.errors) {
                    let arrayProjectdoc: any = []
                    responseJson.data.map((item: any) => (
                        item.attributes.documents.data.map((row: any) => {
                            let obj = {
                                id: row.id,
                                project_name: row.attributes.project_name,
                                document_url: row.attributes.document_url,
                                client_name: row.attributes.client_name,
                                name: row.attributes.name,
                                modified_at: row.attributes.modified_at,
                                project_live_on: row.attributes.project_live_on
                            }
                            arrayProjectdoc.push(obj)
                        })
                    ))
                    this.setState({ data: arrayProjectdoc, filterData: arrayProjectdoc, loading: false })
                } else {
                    this.setState({ filterData: [], data: [], loading: false })
                }
            }


        }
    }

    async componentDidMount(): Promise<void> {
        this.getProjectDocument();
    }


    getProjectDocument(): boolean {
        const header = {
            token: localStorage.getItem('accesToken')
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getProjectDocumentAPICall = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getProjectDocument
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;
    }


    openDocument = (blob: any, fileName: any) => {
        const link = document.createElement('a');
        // create a blobURI pointing to our Blob
        link.href = URL.createObjectURL(blob);
        link.target = "_blank"
        // link.download = fileName;
        // some browser needs the anchor to be in the doc
        document.body.append(link);
        toast.success("assessorDocument.toast.PleaseCheckYourInvoice")
        link.click();
        link.remove();
        // in case the Blob uses a lot of memory
    };


    filterClients = (event: any) => {
        if (event === null) {
            this.getProjectDocument();
        }
        let data = this.state.Allprojectlist.filter((item: any) => {
            return item.client_id === event?.value
        })
        this.setState({ sortByClients: event !== null ? event.label : "", sortByProjects: "", projects: data })
    }

    filterProjects = (event: any) => {
        this.setState({ sortByProjects: event !== null ? event.label : "" })
    }

    redirectToDocument = (name: string, url: string) => {
        this.setState({ isDocOpen: true, documentName: name, documentURL: url })
    }


    onApplyButtonClick = () => {
        

            this.setState({ loading: true })

            const client_id = this.state.sortByClients !== "" ? this.state.clients.find((opt: any) => opt.client_name === this.state.sortByClients)?.client_id : ""
            const project_id = this.state.sortByProjects !== "" ? this.state.projects.find((opt: any) => opt.project_name === this.state.sortByProjects).project_id : ""

            let Enpoint = ""
            if(client_id !== ""){
                Enpoint = `client_id=${client_id}`
            }
            if(client_id !=="" && project_id !== ""){
                Enpoint = `project_id=${project_id}&client_id=${client_id}`
            }
            const header = {
                token: localStorage.getItem('accesToken')
            };
            const requestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );
            this.applyFilterAPICall = requestMessage.messageId;
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(header)
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.validationApiMethodType
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                Enpoint !== "" ? `${configJSON.getProjectDocument}?${Enpoint}` : `${configJSON.getProjectDocument}`
            );

            runEngine.sendMessage(requestMessage.id, requestMessage);

    }


    onChangeHandler = (search: string, page: number) => {

        let modifiedData = this.state.data.filter((item: any) => {
            if (search === "") {
                return true;
            } else if (
                item.project_name.toString().toLowerCase().includes(search.toLowerCase()) ||
                item.name.toString().toLowerCase().includes(search.toLowerCase())
            ) {
                return item;
            }
        });

        const dataLength = this.state.data.length;

        this.setState({ ...this.state, filterData: modifiedData, dataLength, page, search });
    }

    // Customizable Area End
}
