// Customizable Area Start
import React from "react";

function AnalyticsSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="20"
      height="20"
      viewBox="0 0 20 20"
    >
      <defs>
        <path id="path-1" d="M0 0H20V19.973H0z"></path>
      </defs>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(-35 -562)">
          <g transform="translate(35.002 562.014)">
            <mask id="mask-2" fill="#fff">
              <use xlinkHref="#path-1"></use>
            </mask>
            <path
              fill="#FFF"
              fillRule="nonzero"
              d="M1.4703369 16.155945L6.7629075 16.155945 6.7629075 16.660515 1.4703369 16.660515z"
              mask="url(#mask-2)"
            ></path>
            <path
              fill="#FFF"
              fillRule="nonzero"
              d="M1.4703369 14.998779L6.7629075 14.998779 6.7629075 15.50335 1.4703369 15.50335z"
              mask="url(#mask-2)"
            ></path>
            <path
              fill="#FFF"
              fillRule="nonzero"
              d="M1.4703369 17.31311L6.7629075 17.31311 6.7629075 17.81768 1.4703369 17.81768z"
              mask="url(#mask-2)"
            ></path>
            <path
              fill="#FFF"
              fillRule="nonzero"
              d="M1.3883972 4.701111L1.8929678 4.701111 1.8929678 5.331824 1.3883972 5.331824z"
              mask="url(#mask-2)"
            ></path>
            <path
              fill="#FFF"
              fillRule="nonzero"
              d="M1.3883972 5.9625244L1.8929678 5.9625244 1.8929678 6.5932374 1.3883972 6.5932374z"
              mask="url(#mask-2)"
            ></path>
            <path
              fill="#FFF"
              fillRule="nonzero"
              d="M1.9087356 4.07022531L1.9087356 3.4554443 1.3883972 3.4554443 1.3883972 4.0703895 1.89296779 4.0703895z"
              mask="url(#mask-2)"
            ></path>
            <path
              fill="#FFF"
              fillRule="nonzero"
              d="M2.5394287 3.4554443L3.1701419 3.4554443 3.1701419 3.9600148 2.5394287 3.9600148z"
              mask="url(#mask-2)"
            ></path>
            <path
              fill="#FFF"
              fillRule="nonzero"
              d="M3.8008728 3.4554443L4.4315858 3.4554443 4.4315858 3.9600148 3.8008728 3.9600148z"
              mask="url(#mask-2)"
            ></path>
            <path
              fill="#FFF"
              fillRule="nonzero"
              d="M5.0622864 3.4554443L5.6929994 3.4554443 5.6929994 3.9600148 5.0622864 3.9600148z"
              mask="url(#mask-2)"
            ></path>
            <path
              fill="#FFF"
              fillRule="nonzero"
              d="M6.3237305 3.4554443L6.9544435 3.4554443 6.9544435 3.9600148 6.3237305 3.9600148z"
              mask="url(#mask-2)"
            ></path>
            <path
              fill="#FFF"
              fillRule="nonzero"
              d="M6.5316467 10.053101L7.0362172 10.053101 7.0362172 10.6838145 6.5316467 10.6838145z"
              mask="url(#mask-2)"
            ></path>
            <path
              fill="#FFF"
              fillRule="nonzero"
              d="M1.4700317 12.686218L2.1007448 12.686218 2.1007448 13.190789 1.4700317 13.190789z"
              mask="url(#mask-2)"
            ></path>
            <path
              fill="#FFF"
              fillRule="nonzero"
              d="M2.7314453 12.686218L3.3621585 12.686218 3.3621585 13.190789 2.7314453 13.190789z"
              mask="url(#mask-2)"
            ></path>
            <path
              fill="#FFF"
              fillRule="nonzero"
              d="M6.5316467 11.314575L7.0362172 11.314575 7.0362172 11.9452885 6.5316467 11.9452885z"
              mask="url(#mask-2)"
            ></path>
            <path
              fill="#FFF"
              fillRule="nonzero"
              d="M7.03621917 12.575989L6.5157166 12.575989 6.5157166 13.1907704 7.03621917 13.1907704z"
              mask="url(#mask-2)"
            ></path>
            <path
              fill="#FFF"
              fillRule="nonzero"
              d="M5.254303 12.686218L5.885016 12.686218 5.885016 13.190789 5.254303 13.190789z"
              mask="url(#mask-2)"
            ></path>
            <path
              fill="#FFF"
              fillRule="nonzero"
              d="M3.9928894 12.686218L4.6236026 12.686218 4.6236026 13.190789 3.9928894 13.190789z"
              mask="url(#mask-2)"
            ></path>
            <path
              fill="#FFF"
              d="M10.526 15.577v.253A1.05 1.05 0 019.5 16.878a1.053 1.053 0 01-1.025-1.048V8.38H7.97v7.449a1.56 1.56 0 001.507 1.552v.001h.02a.67.67 0 00.027 0h4.982v1.75h-3.819v.505h4.323v-2.255h3.437c.856 0 1.553-.697 1.553-1.553v-.253h-9.474zm7.92 1.302h-7.824c.2-.218.338-.493.388-.797h8.455a1.05 1.05 0 01-1.018.797z"
              mask="url(#mask-2)"
            ></path>
            <path
              fill="#FFF"
              d="M.505 2.638h14V4.04H7.97v2.304h.505v-1.8h9.472v9.855h.504V4.04h-3.44V.35H0v19.288h9.835v-.504H.505V2.638zM13.454.855h1.052v1.279h-1.052V.854zm-1.524 0h1.019v1.279H11.93V.854zM.505.855h10.92v1.279H.506V.854z"
              mask="url(#mask-2)"
            ></path>
            <path
              fill="#FFF"
              d="M13.03 13.608a3.408 3.408 0 003.405-3.404v-.252h-3.152V6.8h-.253a3.408 3.408 0 00-3.403 3.404 3.408 3.408 0 003.403 3.404zm2.89-3.152a2.904 2.904 0 01-1.445 2.26l-1.05-2.26h2.494zm-1.902 2.474a2.886 2.886 0 01-2.738-.417l1.682-1.857 1.056 2.274zm-1.24-5.614v2.79l-1.872 2.068a2.887 2.887 0 01-.775-1.97 2.903 2.903 0 012.647-2.888z"
              mask="url(#mask-2)"
            ></path>
            <path
              fill="#FFF"
              d="M17.31 9.345a3.408 3.408 0 00-3.403-3.404h-.253v3.656h3.657v-.252zm-3.151-.253V6.456a2.904 2.904 0 012.636 2.636H14.16zM9.768 6.705a.827.827 0 000-1.652.827.827 0 00-.758 1.154l-1.759 1.7a.82.82 0 00-1.04.062L4.913 7.38a.827.827 0 10-1.623-.215c0 .128.03.248.082.355l-1.42 1.373a.827.827 0 10-.31 1.591.827.827 0 00.697-1.265l1.378-1.33a.82.82 0 00.918-.08l1.325.599a.827.827 0 101.635.167.825.825 0 00-.042-.26l1.79-1.73a.82.82 0 00.425.119zm0-1.148a.322.322 0 110 .644.322.322 0 010-.644zM1.641 9.98a.322.322 0 110-.643.322.322 0 010 .643zm2.476-2.492a.322.322 0 110-.643.322.322 0 010 .643zm2.652 1.41a.322.322 0 11.001-.644.322.322 0 010 .643z"
              mask="url(#mask-2)"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default AnalyticsSvg;

// Customizable Area End
