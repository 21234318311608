



// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import React from "react";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  t: any;
  i18n: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  selected: any
  userName: string;
  pass: string;
  errorMessage: string;
  formErrors: any;
  open: boolean,
  snakbarAlert: any,
  snakbarText: any,
  errorUserName: any,
  errorPassword: any,
  ErrorPasswordText:any,
  ErrorUserText:any;
  copyright: string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountLoginController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  adminApiCallId: string = "";
  t: any;

  getCopyrightApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];
    this.selectionHandler = this.selectionHandler.bind(this)

    this.state = {
      userName: "",
      pass: "",
      errorMessage: "",
      selected: localStorage.getItem("language") ?? "en",
      formErrors: { userName: '', pass: '' },
      open: false,
      snakbarAlert: false,
      snakbarText: "",
      errorUserName: false,
      errorPassword: false,
      ErrorUserText:"",
      ErrorPasswordText:"",
      copyright: `© ${new Date().getFullYear()} SHRM India. All Rights Reserved.`
    };
    this.t = this.props.t;
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start

  handleUserEmail = (e: any) => {
    this.setState({ userName: e.target.value });
    const emailregsx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (e.target.value === "") {
      this.setState((prev:any) => ({
        ...prev, 
        errorUserName: true, 
        ErrorUserText:"adminLogin.errorMessage.username"
      }))
    }
    if (emailregsx.test(e.target.value)) {
      this.setState({
        errorUserName: true,
        ErrorUserText:"adminLogin.errorMessage.username"
      });
      e.preventDefault();
    }
  };

  handleUserPass = (e: any) => {
    this.setState({ pass: e.target.value });
    if (e.target.value.trim() === "") {
      this.setState((prev:any) => ({
        ...prev, 
        errorPassword: true, 
        ErrorPasswordText:"adminLogin.errorMessage.password"
      }))
    }
    else if (e.target.value.trim().length < 8 && e.target.value.trim().length !== 0) {
      this.setState((prev:any) => ({
        ...prev, 
        errorPassword: true, 
        ErrorPasswordText:"adminLogin.errorMessage.passwordLength"
      }))
    }
    else{
      this.setState((prev:any) => ({
        ...prev, 
        errorPassword: false, 
        ErrorPasswordText:""
      }))
    }
  };

  handleErrorMessage = (e: any) => {
    this.setState({ errorMessage: "" });
  };

  handleLogin = (e: any) => {
    const emailregsx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const languageskey = localStorage.getItem("language")
    if (this.state.userName === "") {
      this.setState((prev:any) => ({
        ...prev, 
        errorUserName: true,
        ErrorUserText:"adminLogin.errorMessage.username"
      }))
      e.preventDefault();
    }
    if (this.state.pass === "") {
      this.setState((prev:any) => ({
        ...prev, errorPassword: true,
        ErrorPasswordText:"adminLogin.errorMessage.password"
      }))
    }
    if (emailregsx.test(this.state.userName) || this.state.pass.trim().length < 8) {
      if(emailregsx.test(this.state.userName)){
        this.setState({
          errorUserName: true,
          ErrorUserText:"adminLogin.errorMessage.username"
        });
      }
      e.preventDefault();
      if (this.state.pass.trim() === "") {
        this.setState((prev:any) => ({
          ...prev, 
          errorPassword: true, 
          ErrorPasswordText:"adminLogin.errorMessage.password"
        }))
      }
      else if (this.state.pass.trim().length < 8 && this.state.pass.trim().length !== 0) {
        this.setState((prev:any) => ({
          ...prev, errorPassword: true,
          ErrorPasswordText:"adminLogin.errorMessage.passwordLength"
        }))
      }
    }
    else{ e.preventDefault()
      let headers = {
               "Content-Type": "application/json",
               "locale":`${languageskey}`
      };
  let httpBody = {

  }
  httpBody = {data: {type: "admin_manager",
            attributes: {email: this.state.userName,
                        password: this.state.pass,
                      },
                    },
                  };
  const validEmailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+.[a-zA-Z]*$/;
   if (this.state.userName.match(validEmailRegex)) 
   {httpBody = {data: {type: "admin_manager",
            attributes: {email: this.state.userName, // user_name or email both can use for login
                         password: this.state.pass,
            },},}}
  
    const loginApiMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.adminApiCallId = loginApiMsg.messageId;
        loginApiMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_login/logins`);
  
      loginApiMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers));
  
loginApiMsg.addData(getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody));
  
  loginApiMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
        "POST");
        
      // Customizable Area End
      runEngine.sendMessage(loginApiMsg.id, loginApiMsg);
    }};
  // Customizable Area Start
  async componentDidMount() { 
    this.getCopyright();
  }

  selectionHandler = (e: any) => {
    console.log("changed")
    this.setState({ selected: e.target.value })
  }
  // Customizable Area End

  async receive(from: string, message: Message) {


    if (this.adminApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
          const apiResponse = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
          );
          
          if(apiResponse?.errors){
           this.checkErrors(apiResponse)
          } else {  
            localStorage.setItem("user",apiResponse.data.attributes.full_name )
            localStorage.setItem("userId",apiResponse.data.attributes.id )
            localStorage.setItem("accesToken",apiResponse.meta.token)
            localStorage.setItem("userRole", apiResponse.data.type)
            if(apiResponse.data.type === "admin" || 
              apiResponse.data.type === "co_manager" ||
              apiResponse.data.type === "manager"
              ){
              this.props.navigation.navigate('CreateProject')
            } else if(apiResponse.data.type === "assessor"){
              this.props.navigation.navigate('AssessorDashboard')
            }
            
          }  }
          else if (message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.getCopyrightApiCallId) {
            const responseJson = message.getData(
              getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if(Array.isArray(responseJson)){
              this.setState({ copyright: responseJson[0].copyright });
            }
        }
  }

  getCopyright = () => {
    const header = {
      "Content-Type": "application/json",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCopyrightApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/bx_block_admin/footer_copyrights"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  checkErrors = (apiResponse:any) => {
    apiResponse?.errors?.map((option: any) => {
      for(const i in option){
        if(option[i]=="Login Failed"){
          this.setState({
            ...this.state, snakbarText : this.t("adminLogin.snakbar.loginFailed"), snakbarAlert:true
          })
        }
        if(option[i]=="Account not found, or not activated"){
          this.setState({
            ...this.state, snakbarText : this.t("adminLogin.snakbar.accountNotFound"),snakbarAlert:true
          })
        }
        if(option[i]=="You are not authorise to perform this action."){
          this.setState({
            ...this.state, snakbarText : "You are not authorise to perform this action.",snakbarAlert:true
          })
        }
      }
    })
  }

}
// Customizable Area End
