// Customizable Area Start
import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { toast } from "react-toastify";
import { Surface, Symbols } from "recharts"

export const configJSON = require("./config");

export interface Props {
    navigation?: any;
    id?: string;
    t: any;
}

interface S {
    data: any;
    loader: boolean;
    error: boolean;
    functionData: any,
    industryData: any,
    expanded: string;
    selectedValue: string;
    anchorEl: any;
    sectorData: any,
    sortBy: string;
    optionList: any[];
    option: string;
    search: string;
    PojectListData:any[]
}

interface SS {
    id: any;
}

const genderList = [
    {
        label: "Male",
        value: "male"
    },
    {
        label: "Female",
        value: "female"
    },
    {
        label: "Other",
        value: "other"
    },
]

export default class AnalyticsController extends BlockComponent<
    Props,
    S,
    SS
> {
    getHighestEduAPICall: string = '';
    getFunctionAPICall: string = '';
    getIndustryAPICall: string = '';
    getSectorAPICall: string = '';
    getAnalyticsAPICall: string = '';
    getProjectDataAPICall: string = '';
    t: any;


    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this)
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.PostDetailDataMessage)
        ]
        this.state = {
            loader: true,
            expanded: 'all',
            selectedValue: 'all',
            anchorEl: null,
            error: false,
            functionData: null,
            data: [],
            industryData: null,
            sortBy: "",
            optionList: [],
            sectorData: null,
            search: "",
            option: "",
            PojectListData:[] 
        };
        // @ts-ignore
        this.callRef = React.createRef();
        this.t = this.props.t;
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        const { expanded } = this.state;
        this.getAnalyticsData(expanded, "all")
        this.getSectorData();
        this.getFunctionData();
        this.getClientDataOfproject()
        this.getIndustryData();}


          async receive(from: string, message: Message) {
if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (apiRequestCallId === this.getFunctionAPICall) {
                if (responseJson != null && !responseJson.message) {
                    const functionData = responseJson.data.map((item: any) => 
                    {
                        return {
                            label: item.attributes.name,
                            value: item.id,
                        }
                    }); this.setState({ functionData, loader: false, error: false })
                } else {this.setState({ error: true })
                }
            }
            if (apiRequestCallId === this.getAnalyticsAPICall) {
                if (responseJson != null && !responseJson.message) {
                    this.setState({ data: responseJson, loader: false, error: false })
                } else {
                    this.setState({ error: true })
                }
            }this.checkApiCallData(apiRequestCallId,responseJson)
            this.ProjcetDataApi(apiRequestCallId,responseJson)
        }
    }

      checkApiCallData = (apiRequestCallId:any,responseJson:any)=>{
          if (apiRequestCallId === this.getSectorAPICall) {
             if (responseJson != null && !responseJson.message) {
                const sectorData = responseJson.data.map((item: any) => {
                    return {
                       label: item.attributes.name,
                          value: item.id,}});
                                      this.setState({ sectorData, loader: false, error: false })}
                                       else {this.setState({ error: true })}}
    if (apiRequestCallId === this.getIndustryAPICall) {if (responseJson != null && !responseJson.message) 
        {const industryData = responseJson.data.map((item: any) => {return {
                    label: item.attributes.industry_name,
                    value: item.id,
                }});
this.setState({ industryData, loader: false, error: false })} else {
            this.setState({ error: true })
        }}}
    handleToastMessage() {
        toast.success('participant added succesfully', {
            position: toast.POSITION.TOP_RIGHT});
    }

    ProjcetDataApi = (apiRequestCallId: any, responseJson: any) => {
        if (apiRequestCallId === this.getProjectDataAPICall) {
            console.log(responseJson.data,"responseJson.data");
            
            const optionList = responseJson.data.map((item: any) => {
                return {
                    label: item.attributes.name,
                    value: item.id,
                }
            })
            this.setState({
                PojectListData: optionList,
                loader: false
            })

        }
    }

onApplyButtonClick = () => {
    let { sortBy, option } = this.state;
let optionId;
if(sortBy === "all"){
    this.getAnalyticsData(this.state.expanded, "all")
}else{

    if(sortBy !== "all"){
    
        if(sortBy!== "all" && option===""){
            toast.warn(this.t("ClientDashboard.Toast.Selectoption",{data: sortBy.charAt(0).toUpperCase() + sortBy.slice(1)}))
        }else{
        this.ApplyButtonClickTwo(optionId)
            }
    }
}


      
    }
    
    ApplyButtonClickTwo = (optionId:any) =>{
        let { sortBy, option, sectorData, functionData, industryData } = this.state;
        if (sortBy === "sector") {
            optionId = sectorData.filter((obj: any) => obj.label === option)[0].label}
            else if (sortBy === "function") {
                let temp = functionData.filter((obj: any) => obj.label === option)[0].label
                if(temp == "R&D"){
                    optionId = "R%26D"
                }
                else
                {optionId = functionData.filter((obj: any) => obj.label === option)[0].label}
            }
           
            else if (sortBy === "industry") {
                optionId = industryData.filter((obj: any) => obj.label === option)[0].label
            }
            else if (sortBy === "all") {
                optionId = "all"
            }
            else if (sortBy === "gender") {
                optionId = genderList.filter((obj:any) => obj.label === option)[0].label
               
            }else if (sortBy === "projects") {
                sortBy = "project"
                optionId = option
            }
            this.getAnalyticsData(sortBy, optionId);
    }

    onsortByValueChange = (sortBy: string) => {
        let optionListClient: any[] = this.state.data;
        if(sortBy === undefined){
            if(sortBy === "all" && this.state.option !== ""){
                return false
            }else{
                this.getAnalyticsData(this.state.expanded, "all")
            }
            this.setState({
                sortBy: sortBy ?? "",
                option:"",
                loader:false
            })
        }  else{
            if (sortBy === "all") {
               optionListClient = [];
           } else if (sortBy === "sector") {
               optionListClient = this.state.sectorData;
           } else if (sortBy === "function") {
               optionListClient = this.state.functionData;
           } else if (sortBy === "industry") {
               optionListClient = this.state.industryData;
           } else if (sortBy === "gender") {
               optionListClient = genderList
           } else if (sortBy === "projects") {
               optionListClient = this.state.PojectListData
           }
   
           this.setState({ sortBy: sortBy ?? "", optionList:optionListClient, option: "" })
        }
        }
        sentMessage(data: any) {
            const msg: Message = new Message(getName(MessageEnum.PostDetailDataMessage))
            msg.properties['text'] = data
            this.send(msg)
        }
   
        handleDangerToastMessage() {
            toast.error('Participant already exists', {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    handleClose = () => {
        this.setState({ anchorEl: null })
    };
    handleButtonClick = (event: React.MouseEvent<HTMLElement>) => {
        this.setState({ anchorEl: event.currentTarget })
    };

   

    


    handleDashboard = () => {
        this.props.navigation.navigate("Dashboard")
    }

    renderAssesmentLegend = (props: any ) => {
        const { payload } = props;
        return (
            <div className="newLegend">
                {
                    payload.map((entry: any,index:any) => {
                        const { payload: Py } = entry
                        const { payload } = Py
                        return (
                            <span className="legend-wrapper" key={`${entry.value+index}`}>
    <Surface width={20} height={20}  >
    <Symbols cx={10} cy={8} type="square" size={150} viewBox="0 0 15 15" fill={entry.color} />
    </Surface>
<span className="legend-item"><span >{entry.value}{"\xa0\xa0"}</span> <span>{(payload.count < 2) ? `${payload.count} Assesment` : `${payload.count} Assesments` }</span></span>
</span>)})}</div>
        );
    }
renderLegend = (props: any) => {const { payload } = props;
return (<div className="newLegend">
{payload.map((entry: any,index:any) => {const { payload: Py } = entry
                        const { payload } = Py
                        return (
                            <span className="legend-wrapper" key={`${entry.value+index}`}>
                                <Surface width={20} height={20}><Symbols cx={10} cy={8} type="square" size={150} viewBox="0 0 15 15" fill={entry.color} /></Surface>
                                <span className="legend-item"><span >{entry.value}{"\xa0\xa0"}</span> <span>{(payload.count < 2) ? `${payload.count} Participant` : `${payload.count} Participants` }</span></span>
                            </span>
                        )
                    })
                }</div>);
    }

   

    CustomizedLabel = ({ x, y, stroke, value }: any) => {
        return (
            <text x={x} y={y} dy={-4} fill={stroke} fontSize={10} textAnchor="middle">
                {value}
            </text>
        );

    }
    renderColorfulLegendText = (value: string, entry: any) => {
        return <span style={{ color: "#000" }}>{value}</span>;
    };

    getAnalyticsData=(type: any, sortBy:any) =>{
    this.setState({
        loader:true
    })
    let endpoint = type && sortBy && `/account_block/clients/client_dashboard_data?type=${type}&sort_by=${sortBy}`
const header = {token: localStorage.getItem('accesToken')
        };const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage)
        );
this.getAnalyticsAPICall = requestMessage.messageId;
requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
requestMessage.addData(
getName(MessageEnum.RestAPIRequestMethodMessage),configJSON.validationApiMethodType
        );
requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
            endpoint        );
        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;
}
getSectorData = () => {
const header = {
token: localStorage.getItem('accesToken')
};
const requestMessage = new Message(
getName(MessageEnum.RestAPIRequestMessage)
);
this.getSectorAPICall = requestMessage.messageId;
requestMessage.addData(
getName(MessageEnum.RestAPIRequestHeaderMessage),
JSON.stringify(header));
requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),configJSON.validationApiMethodType
        );
requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
            "/bx_block_admin/sectors"
);
runEngine.sendMessage(requestMessage.id, requestMessage);
return true;}

getIndustryData = () => {const header = {
token: localStorage.getItem('accesToken')};
const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage)
        );
this.getIndustryAPICall = requestMessage.messageId;
requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header)
        );
requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType);
requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
            "/bx_block_profile/industries"
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;

    }
getFunctionData = () => {
const header={token: localStorage.getItem('accesToken')};
const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage)
        );
this.getFunctionAPICall = requestMessage.messageId;
requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
requestMessage.addData(
getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
        );
        requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
"bx_block_admin/org_functions");
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }
    
    getClientDataOfproject = () => {
        this.setState({loader:true})
        const header={token: localStorage.getItem('accesToken')};
        const ClientId = localStorage.getItem("participantId")
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage)
                );
        this.getProjectDataAPICall = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
                    JSON.stringify(header)
                );
        requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.validationApiMethodType
                );
                requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
        `/account_block/clients/${ClientId}/client_projects`);
                runEngine.sendMessage(requestMessage.id, requestMessage);
                return true;
            }
}
// Customizable Area End