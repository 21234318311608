import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End


export interface Props {
  id?: string;
  navigation?: any;
  location: any;
  history: any;
  match: any;
  // Customizable Area Start
  t: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  data: any[];
  filterData: any[];
  openModal: boolean;
  openURLModal: boolean;
  EditModal: boolean;
  URLmodal: boolean;
  clientName: any;
  dynamicUrl: any;
  rederectUser: any;
  clientEmail: any;
  clientPassword: any;
  clientUserName: string;
  clientNameError: any;
  clientUserNameError: any;
  clientPasswordError: any;
  clientEmailError: any;
  loader: boolean;
  page: any;
  rowsPerPage: any;
  search: any;
  deleteModel: any;
  deleteModelText: any;
  clientDetails: any;
  questionImage: any;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class UnavailabilityOfAssessorController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  addClientCallId: string = "";
  getURLdataAPICall = "string";
  Tabledata: any = "";
  Userdelete: any = "";
  updateClientAPICall: string = "";
  t: any = "";

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.PostDetailDataMessage),
    ];

    this.state = {
      data: [],
      filterData: [],
      openModal: false,
      openURLModal: false,
      URLmodal: false,
      rederectUser: "",
      dynamicUrl: "",
      clientName: "",
      clientEmail: "",
      clientPassword: "",
      clientUserName: "",
      clientNameError: "",
      clientEmailError: "",
      clientPasswordError: "",
      clientUserNameError: "",
      loader: true,
      page: 0,
      search: "",
      rowsPerPage: 20,
      deleteModel: false,
      deleteModelText: [],
      EditModal: false,
      clientDetails: null,
      questionImage: "",
    };
    this.t = this.props.t;
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  // Customizable Area Start
  async componentDidMount(): Promise<void> {
    return this.getTable();
  }
  // Customizable Area End

  // Customizable Area Start
  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.Tabledata) {
        this.setState((e) => ({
          ...e,
          data: responseJson.data,
          filterData: responseJson.data,
          loader: false,
        }));
      }
    }
  }
  // Customizable Area End

  // Customizable Area Start
  getTable = () => {
    this.getTableData();
  };

  NavigateToCalander = (item: any) => {
    this.props.history.push("UnavailabilityCalander", item.attributes);
  };

  getTableData = () => {
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
      );
      const header = {
        "Content-Type": "application/json",
        token: localStorage.getItem("accesToken"),
      };

    this.Tabledata = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/account_block/assessors/"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area End
}
