import { Message } from "../../../../../framework/src/Message";
import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import { runEngine } from "../../../../../framework/src/RunEngine";
import MessageEnum, {
    getName,
} from "../../../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { toast } from "react-toastify";
// Customizable Area End

export const configJSON = require("../../config");

export interface Props {
    id: string;
    navigation: any;
    t: any;
}

export interface S {
    // Customizable Area Start
    loading: boolean;
    page: number;
    rowsPerPage: number;
    dataLength: number;
    count: number | null;
    designationId: any;
    designationName: string;
    search: string;
    openDialogName: string | null;
    data: any[];
    tableData: any[];
    filterData: any[];
    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class DesignationController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    getDesignationListApiCallId: string = "";
    addDesignationApiCallId: string = "";
    editDesignationApiCallId: string = "";
    deleteDesignationApiCallId: string = "";
    t: any;
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage),
        ];
        this.receive = this.receive.bind(this);
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
        this.t = this.props.t;
        this.state = {
            // Customizable Area Start
            loading: false,
            page: 0,
            rowsPerPage: 20,
            dataLength: 0,
            count: null,
            designationId: null,
            designationName: "",
            filterData: [],
            search: "",
            openDialogName: "",
            data: [],
            tableData: [],
            // Customizable Area End
        };
    }

    async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            if (apiRequestCallId && responseJson) {
                if (responseJson.status === 500) {
                    this.setState({ loading :false });
                    toast.error(this.t("organizationFunction.designation.toast.somethingWrong"));
                } else if (!Array.isArray(responseJson.errors) && responseJson.errors) {
                    this.setState({ loading: false });
                    toast.error(responseJson.errors);
                } else {
                    this.handleApiResponse(apiRequestCallId, responseJson);
                }
            }
        }
    }

    async componentDidMount() {
        window.scrollTo(0, 0);
        this.getDesignationList();
    }

    handleApiResponse = (apiRequestCallId: string, responseJson: any) => {
        if (Array.isArray(responseJson.errors)) {
            if (responseJson.errors[0].message) {
                toast.error(responseJson.errors[0].message);
            } else if (responseJson.errors[0].name) {
                toast.error(responseJson.errors[0].name);
            } else {
                toast.error(this.t("organizationFunction.designation.toast.somethingWrong"));
            }
            this.setState({ loading: false });
        } else if (apiRequestCallId === this.getDesignationListApiCallId) {
            this.handelDesignationListApiResponse(responseJson);
        } else if (apiRequestCallId === this.addDesignationApiCallId) {
            this.handelAddDesignationApiResponse(responseJson);
        } else if (apiRequestCallId === this.editDesignationApiCallId) {
            this.handelEditDesignationApiResponse(responseJson);
        } else if (apiRequestCallId === this.deleteDesignationApiCallId) {
            this.handelDeleteDesignationApiResponse();
        }
    }

    handelDesignationListApiResponse = (responseJson: any) => {
        const data = responseJson.data.map((item: any) => {
            return {
                id: item.id,
                designationName: item.attributes.name
            }
        });
        this.setState({ tableData: data, filterData: data, loading: false },
            () => { this.onChangeHandler(this.state.search, 0) }
        );
    }

    handelAddDesignationApiResponse = (responseJson: any) => {
        toast.success(this.t("organizationFunction.designation.toast.add"));
        let tableData = [...this.state.tableData];
        const obj = {
            id: responseJson.data.id,
            designationName: responseJson.data.attributes.name
        };
        tableData = [obj, ...tableData];
        this.setState({ tableData, filterData: tableData, designationId: null, designationName: "", openDialogName: null, loading: false },
            () => { this.onChangeHandler(this.state.search, 0) }
        );
    }

    handelEditDesignationApiResponse = (responseJson: any) => {
        toast.success(this.t("organizationFunction.designation.toast.edit"));
        let tableData = [...this.state.tableData];
        tableData = tableData.map((item: any) => {
            if (item.id === this.state.designationId) {
                return {
                    id: responseJson.data.id,
                    designationName: responseJson.data.attributes.name
                };
            } else {
                return item;
            }
        });

        this.setState({ tableData, filterData: tableData, designationId: null, designationName: "", openDialogName: null, loading: false },
            () => { this.onChangeHandler(this.state.search, this.state.page) }
        );
    }

    handelDeleteDesignationApiResponse = () => {
        toast.success(this.t("organizationFunction.designation.toast.delete"));
        let tableData = [...this.state.tableData];
        tableData = tableData.filter((item: any) => item.id !== this.state.designationId);
        this.setState({ tableData, filterData: tableData, designationId: null, designationName: "", openDialogName: null, loading: false },
            () => { this.onChangeHandler(this.state.search, this.state.page) }
        );
    }

    onChangeHandler = (search: string, page: number) => {
        const { filterData, rowsPerPage } = this.state;
        let data = filterData.filter((item: any) => {
            if (search === "") {
                return true;
            } else if (item.designationName.toString().toLowerCase().includes(search.toLowerCase())) {
                return item;
            }
        });

        const dataLength = data.length;
        let totalPage = Math.ceil(dataLength / this.state.rowsPerPage);
        page = totalPage === page ? page - 1 : page;
        data = data.slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage
        );
        this.setState({ data, dataLength, page, search });
    }

    getDesignationList = () => {
        this.setState({ loading: true });

        const header = {
            "Content-Type": configJSON.designationListContentType,
            token: localStorage.getItem("accesToken")
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getDesignationListApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.designationListApiEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.designationListApiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }

    addDesignation = () => {
        this.setState({ loading: true });

        const header = {
            "Content-Type": configJSON.addDesignationApiContentType,
            token: localStorage.getItem("accesToken")
        };

        const httpBody = {
            "name": this.state.designationName,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.addDesignationApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.addDesignationApiEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.addDesignationApiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }

    editDesignation = () => {
        this.setState({ loading: true });

        const header = {
            "Content-Type": configJSON.editDesignationApiContentType,
            token: localStorage.getItem("accesToken")
        };

        const httpBody = {
            "name": this.state.designationName,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.editDesignationApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.editDesignationApiEndPoint}/${this.state.designationId}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.editDesignationApiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }

    deleteDesignation = () => {
        this.setState({ loading: true });
        const header = {
            "Content-Type": configJSON.deleteDesignationApiContentType,
            token: localStorage.getItem("accesToken")
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.deleteDesignationApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.deleteDesignationApiEndPoint}/${this.state.designationId}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.deleteDesignationApiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }
}
// Customizable Area End