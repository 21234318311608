import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { toast } from "react-toastify";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  t: any;
  history: any;
  location: any;
  match: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  data: any[];
  filterData: any[];
  openModal: boolean;
  openURLModal: boolean;
  URLmodal: boolean;
  dynamicUrl: any;
  deleteModelText: any;
  EditModal: boolean;
  rederectUser: any;
  clientName: any;
  clientEmail: any;
  clientUserName: string;
  clientPassword: any;
  clientNameError: any;
  clientPasswordError: any;
  clientEmailError: any;
  clientUserNameError: any;
  loader: boolean;
  rowsPerPage: any;
  search: any;
  clientProject: any;
  deleteModel: any;
  IdForUpdate: any;
  clientDetails: any;
  questionImage: any;
  page: any;
  UpdateNameist: any;
  projectName: string;
  option: any;
  participantName: string;
  projectList: any[];
  participantList: any[];
  dataLength: number;
  tableData: any[];
  // Customizable Area End
}

interface SS {
  id: any;
}

const statusList = [
  { label: "Yet to be started", value: "yet to be started" },
  { label: "On Going", value: "on going" },
  { label: "Completed", value: "completed" },
];

export default class AssignAvailableAssessorController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  Tabledata: any = "";
  Userdelete: any = "";
  updateClientAPICall: string = "";
  UpdateName: string = "";
  t: any;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal2 = this.handleCloseModal2.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.PostDetailDataMessage),
    ];

    this.state = {
      data: [],
      openModal: false,
      openURLModal: false,
      URLmodal: false,
      dynamicUrl: "",
      rederectUser: "",
      clientName: "",
      clientEmail: "",
      clientUserName: "",
      clientPassword: "",
      clientNameError: "",
      clientEmailError: "",
      clientUserNameError: "",
      clientPasswordError: "",
      deleteModel: false,
      loader: true,
      page: 0,
      rowsPerPage: 20,
      filterData: [],
      search: "",
      deleteModelText: [],
      EditModal: false,
      projectList: [],
      clientDetails: null,
      questionImage: "",
      UpdateNameist: [],
      IdForUpdate: "",
      clientProject: "",
      projectName: "",
      participantName: "",
      participantList: [],
      dataLength: 0,
      option: "",
      tableData: []
    };
    this.t = this.props.t;
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  // Customizable Area Start
  async componentDidMount(): Promise<void> {
    return this.getTable();
  }

  // Customizable Area End

  // Customizable Area Start
  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      if (apiRequestCallId === this.Tabledata) {
        this.setState({
          data: responseJson.data, tableData: responseJson.data,
          filterData: responseJson.data, loader: false,
        }, () => {
          this.setProjectList();
          this.onChangeHandler("", 0);
        });
      } else if (apiRequestCallId === this.UpdateName) {
        this.setState({ UpdateNameist: responseJson.data });
      }
      else if (apiRequestCallId === this.updateClientAPICall) {
        this.getTableData()
        toast.success(this.t("AssignAvailableAssessor.ToastMessage.Assessordetailsupdatedsuccessfully"));
        this.setState({
          loader: false
        })
      }
    }
  }

  handleOpenModal() {
    this.setState({ openModal: true });
  }
  handleCloseModal2() {
    this.setState({ openModal: false, clientUserName: "", clientPassword: "" });
  }
  // Customizable Area End

  // Customizable Area Start

  getTable = () => {
    this.getTableData();
  };

  handleEditmodalOpen = (item: any) => {
    this.setState({
      clientName: `${item.attributes.assessor_name}`,
      clientDetails: item.attributes,
      clientProject: `${item?.attributes?.project_name}`,
      EditModal: true,
    });
    this.GetUpdateName(item.attributes.id);
  };

  handleEditmodalClose = () => {
    this.setState({ EditModal: false, UpdateNameist: [] });
  };

  handleUpdateClient(values: any) {
    this.setState({
      loader: true
    })
    const { AssessorName } = values;
    const { clientDetails } = this.state;
    let headers = {
      "Content-Type": "application/json",
      token: localStorage.getItem("accesToken"),
    };
    let httpBody = {};

    httpBody = {
      assessor_id: Number(AssessorName),
      assign_participant_slot_id: clientDetails.id,
    };
    const loginEditMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateClientAPICall = loginEditMsg.messageId;

    loginEditMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_profile/slots/change_assessor`
    );

    loginEditMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    loginEditMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    loginEditMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(loginEditMsg.id, loginEditMsg);
    this.setState({ EditModal: false });
  }

  getTableData = () => {
    this.setState({
      loader: true
    })
    const header = {
      token: localStorage.getItem("accesToken"),
      "Content-Type": "application/json",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.Tabledata = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/bx_block_profile/slots/list_participant_slots"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  GetUpdateName = (id: any) => {
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("accesToken"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.UpdateName = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_profile/slots/list_available_assessors?assign_participant_slot_id=${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  setProjectList = () => {
    let projectList = [...new Set(this.state.tableData.map((item: any) => item.attributes?.project_name))];
    projectList = projectList.map((item: any) => ({
      label: item,
      value: item
    }));
    this.setState({ projectList, participantName: "" });
  };

  onProjectValueChange = (projectName: string) => {
    if (projectName === undefined) {
      this.setState({
        filterData: this.state.tableData
      }, () => {
        this.onChangeHandler(this.state.search, 0);
      })
    }
    let tableData = this.state.tableData.filter((item: any) => item.attributes.project_name === projectName);
    tableData = [...new Set(tableData.map((item: any) => item.attributes.name))];
    let participantList = tableData.map((item: any) => ({
      label: item,
      value: item
    }));
    this.setState({ projectName, participantName: "", participantList });
  }

  onChangeHandler = (search: string, page: number) => {
    const { filterData, rowsPerPage } = this.state;
    let data = filterData.filter((item: any) => {
      if (search === "") {
        return true;
      } else if (
        item?.attributes?.project_name?.toLowerCase().includes(search.toLowerCase()) ||
        item?.attributes?.assessor_name?.toLowerCase().includes(search.toLowerCase()) ||
        item?.attributes?.name?.toLowerCase().includes(search.toLowerCase())
      ) {
        return item;
      }
    });

    const dataLength = data.length;
    data = data.slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    );

    this.setState({ dataLength, data, search, page });
  };

  onApplyButtonClick = () => {
    let { tableData, projectName, participantName } = this.state;
    let filterData = tableData;
    if (projectName && participantName) {
      filterData = tableData.filter((item: any) => {
        return item.attributes.project_name === projectName && item.attributes.name === participantName;
      });
    } else if (projectName) {
      filterData = tableData.filter((item: any) => {
        return item.attributes.project_name === projectName;
      });
    }

    this.setState({ filterData }, () => {
      this.onChangeHandler(this.state.search, 0);
    });
  };
  // Customizable Area End
}
