// Customizable Area Start
import React from "react";

function ReportsSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="20"
      height="20"
      viewBox="0 0 20 20"
    >
      <defs>
        <path id="path-1" d="M0 0H20V20H0z"></path>
      </defs>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(-35 -418)">
          <g transform="translate(35 418)">
            <mask id="mask-2" fill="#fff">
              <use xlinkHref="#path-1"></use>
            </mask>
            <path
              fill="#FFF"
              d="M12.25 6.679H9.07a.293.293 0 110-.586h3.181a.293.293 0 110 .586zM15.432 5.175H9.069a.293.293 0 110-.586h6.363a.293.293 0 110 .586zM12.25 11.588H9.07a.293.293 0 110-.586h3.181a.293.293 0 110 .586zM15.432 10.085H9.069a.293.293 0 110-.586h6.363a.293.293 0 110 .586zM12.25 16.498H9.07a.293.293 0 110-.586h3.181a.293.293 0 110 .586zM15.432 14.994H9.069a.293.293 0 110-.586h6.363a.293.293 0 110 .586zM12.533 2.76H7.467a.874.874 0 01-.874-.874V.873c0-.481.392-.873.874-.873h5.066c.482 0 .874.392.874.873v1.013a.874.874 0 01-.874.874zM7.467.586a.288.288 0 00-.288.287v1.013c0 .159.13.288.288.288h5.066a.288.288 0 00.288-.288V.873a.288.288 0 00-.288-.287H7.467zM6.364 7.071H5.062a.787.787 0 01-.787-.786V4.983c0-.434.353-.787.787-.787h1.302c.434 0 .787.353.787.787v1.302a.787.787 0 01-.787.786zM5.062 4.782a.2.2 0 00-.2.2v1.303c0 .11.09.2.2.2h1.302a.2.2 0 00.2-.2V4.983a.2.2 0 00-.2-.2H5.062zM6.364 11.981H5.062a.787.787 0 01-.787-.787V9.892c0-.433.353-.786.787-.786h1.302c.434 0 .787.353.787.786v1.302a.787.787 0 01-.787.787zm-1.302-2.29a.2.2 0 00-.2.201v1.302c0 .111.09.201.2.201h1.302a.2.2 0 00.2-.2V9.891a.2.2 0 00-.2-.2H5.062zM6.364 16.89H5.062a.787.787 0 01-.787-.786v-1.302c0-.434.353-.787.787-.787h1.302c.434 0 .787.353.787.787v1.302a.787.787 0 01-.787.787zm-1.302-2.289a.2.2 0 00-.2.201v1.302c0 .11.09.2.2.2h1.302a.2.2 0 00.2-.2v-1.302a.2.2 0 00-.2-.2H5.062z"
              mask="url(#mask-2)"
            ></path>
            <path
              fill="#FFF"
              d="M16.232 20H3.768c-.7 0-1.27-.57-1.27-1.27V2.357c0-.7.57-1.269 1.27-1.269h1.755a.293.293 0 110 .586H3.768a.684.684 0 00-.684.683v16.375c0 .377.307.683.684.683h12.464a.684.684 0 00.684-.683V2.356a.684.684 0 00-.684-.683h-1.755a.293.293 0 110-.586h1.755c.7 0 1.27.57 1.27 1.27V18.73c0 .7-.57 1.269-1.27 1.269z"
              mask="url(#mask-2)"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default ReportsSvg;

// Customizable Area End
