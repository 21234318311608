import React from "react";

import {
  StyleSheet,
  Text,
  Image,
  TouchableOpacity,
  View,
  TextInput,
  ScrollView,
  TouchableWithoutFeedback,
  Button,
  Platform,
  // Customizable Area Start
  // Customizable Area End
} from "react-native";

import EmailNotificationsController, {
  Props,
  configJSON
} from "./EmailNotificationsController";

export default class EmailNotifications extends EmailNotificationsController {
  constructor(props: Props)    {
                      super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ScrollView         keyboardShouldPersistTaps="always" style={styles.container}>
        <TouchableWithoutFeedback
                    onPress={() => {
                         this.hideKeyboard();
          }}
        >
          {/* Customizable Area Start */}
          {/* Merge Engine UI Engine Code */}
          <View>
{this.isPlatformWeb() ? (
<Text
style={styles.title} //UI Engine::From Sketch
testID="labelTitle" //Merge Engine::From BDS
>
{configJSON.labelTitleText}
</Text> //UI Engine::From Sketch
) : null}

<Text
style={styles.body}       testID="labelBody" //Merge Engine::From BDS
            >
              {" "}
              {configJSON.labelBodyText} {/* UI Engine::From Sketch */}
            </Text>

            <Text testID="txtSaved">
              This is the reviced value : {this.state.txtSavedValue}
              {" "}
              {/* Merge Engine::From BDS - {...this.testIDValue} */}
            </Text><View style={styles.bgPasswordContainer}>
<TextInput   {...this.txtInputProps} //Merge Engine::From BDS - {...this.testIDProps}
      style={styles.bgMobileInput} //UI Engine::From Sketch
        placeholder={configJSON.txtInputPlaceholder} //UI Engine::From Sketch
          testID="txtInput" //Merge Engine::From BDS
/>
<TouchableOpacity         {...this.btnShowHideProps} //Merge Engine::From BDS - {...this.testIDProps}
  testID={"btnShowHide"} //Merge Engine::From BDS
    style={styles.showHide} //UI Engine::From Sketch
><Image {...this.btnShowHideImageProps} //Merge Engine::From BDS - {...this.testIDProps}
    testID={"btnShowHideImage"} //Merge Engine::From BDS - testIDImage
      style={styles.imgShowhide} //UI Engine::From Sketch
/></TouchableOpacity></View>
<Button {...this.btnExampleProps} //Merge Engine::From BDS - {...this.testIDProps}
    testID={"btnExample"} //Merge Engine::From BDS
              title={configJSON.btnExampleTitle}  /> </View>
          </TouchableWithoutFeedback>
      </ScrollView>
      // Customizable Area End
    );}}
// Customizable Area Start
const styles = StyleSheet.create({  container: {
    flex: 1,
    backgroundColor: "red",
    padding: 18,
    marginLeft: "auto",
    marginRight: "auto",
    width: Platform.OS === "web" ? "75%" : "100%",
    maxWidth: 650,
  },
  title: {
    textAlign: "left",
    marginBottom: 32,
    fontSize: 16,
    marginVertical: 8
  },
  body: {
    textAlign: "left",
    marginBottom: 32,
    fontSize: 16,
    marginVertical: 8
  },
  bgPasswordContainer: {
    marginBottom: 16,
    flexDirection: "row",
    backgroundColor: "#00000000",
    borderBottomWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    padding: 10,
    borderWidth: Platform.OS === "web" ? 0 : 1
  },
  bgMobileInput: {
    flex: 1,
    padding:10
  },
  showHide: {
    alignItems: "center"
  },
  imgShowhide: Platform.OS === "web" ? {  width: 30 ,height: 30,} : {}
});
// Customizable Area End
