// Customizable Area Start
import React from "react";
import {
    Box,
    Button,
    Grid,
    TextField,
    Table,
    TableHead,
    TableBody,
    TableRow,
    Tooltip,
    TableCell,
} from "@material-ui/core";
import { styled } from "@material-ui/styles";
import SearchIcon from "@material-ui/icons/Search";
import AppSidebar from "../../../components/src/AppSidebar.web";
import Select, { OptionsType } from "react-select";
import AssessorProjectDocumentController, { Props } from "./AssessorProjectDocumentController";
import { pdfIcon } from "./assets";
import { withRouter } from 'react-router-dom';
import moment from "moment";
import Pagination from "@material-ui/lab/Pagination";
import './assessorProjectDocument.web.css'
import Spinner from "../../../components/src/Spinner.web";
import AssessorDocument from "./AssessorDocument.web";
import { withTranslation } from "react-i18next";
const locallanguage = localStorage.getItem("language") === "ar";
class AssessorProjectDocs extends AssessorProjectDocumentController {

    constructor(props: Props) {
        super(props);
    }
    clientsData = (): OptionsType<{ label: string; value: any }> => {
        return this.state.clients.map((item: any) => ({ label: item.client_name, value: item.client_id }));
    }
    clientsDataValue = (): { label: string; value: any } | null => {
        return this.state.clients.filter((opt: any) => opt.client_name === this.state.sortByClients).map((item: any) => ({ label: item?.client_name, value: item?.client_id }))
    }
    projectData = (): OptionsType<{ label: string; value: any }> => {
        return this.state.sortByClients !== "" ? this.state.projects.map((item: any) => ({ label: item.project_name, value: item.project_id })) : []
    }

    projectDataValue = (): { label: string; value: any } | null => {
        const temp = this.state.projects.filter((opt: any) => opt.project_name === this.state.sortByProjects);
        return temp.length > 0 ? temp.map((item: any) => ({ label: item?.project_name, value: item?.project_id }))[0] : null
    }

    TableRender = () => {
        return (<Table aria-label="simple table" style={{ border: "1px solid #E0E0E0", borderRadius: "10px", borderCollapse: "separate" }}>
            <TableHead>
                <TableRow>
                    <TableCell style={{ fontFamily: "ProximaNova-Bold", fontSize: "16px" }} align={locallanguage ? "right" : "left"}>{this.t("assessorDocument.table.documentList")}</TableCell>
                    <TableCell align={locallanguage ? "right" : "left"} style={{ fontFamily: "ProximaNova-Bold", fontSize: "16px" }}>{this.t("assessorDocument.table.projects")}</TableCell>
                    <TableCell style={{ fontFamily: "ProximaNova-Bold", fontSize: "16px" }} align={locallanguage ? "right" : "left"}>{this.t("assessorDocument.table.date")}</TableCell>
                    <TableCell align={locallanguage ? "right" : "left"} style={{ fontFamily: "ProximaNova-Bold", fontSize: "16px" }}>{this.t("assessorDocument.table.liveOn")}</TableCell>
                </TableRow>
            </TableHead>
            {this.TableBodyRender()}
        </Table>)
    }

    TableBodyRender = () => {
        return (
            <TableBody>
                {this.state.filterData.length > 0 ? (
                    <>
                        {this.state.filterData.slice(
                            this.state.page * this.state.rowsPerPage,
                            this.state.page * this.state.rowsPerPage +
                            this.state.rowsPerPage
                        ).map((item: any) => (
                            this.TableRowRender(item)
                        ))}
                    </>
                ) : (
                    this.TableRowRenderTwo()
                )}
            </TableBody>
        )
    }

    TableRowRender = (item: any) => {
        return (
            <TableRow key={item.id}>
                <TableCell style={{ fontFamily: "ProximaNova-Regular", fontWeight: "normal", fontSize: "18px", display: "flex", alignItems: "center" }} >
                    <Tooltip title={item.name}>
                        <div style={{ display:"flex", alignItems:"center"}}>
                            <img src={pdfIcon} alt="pdf" style={{ verticalAlign: "middle" }} />
                            <a href={item.document_url} target="_blank"> <span style={{ color: "#333", textDecoration: "underline", marginLeft: "5px", marginRight: locallanguage ? "5px" : "0", display: 'block', maxWidth: '100px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>  {item.name}</span> </a>
                        </div>
                    </Tooltip>
                </TableCell>
                <TableCell align={locallanguage ? "right" : "left"} style={{ fontFamily: "ProximaNova-Regular", fontWeight: "normal", fontSize: "18px", color: "#333" }}>{item.project_name}</TableCell>
                <TableCell style={{ fontFamily: "ProximaNova-Regular", fontWeight: "normal", fontSize: "18px", color: "#333" }} align={locallanguage ? "right" : "left"}>{moment(`${item.modified_at}`).format("DD-MM-YYYY")}</TableCell>
                <TableCell align={locallanguage ? "right" : "left"} style={{ fontFamily: "ProximaNova-Regular", fontWeight: "normal", fontSize: "18px", color: "#333" }}>{moment(`${item.project_live_on}`).format("DD-MM-YYYY")}</TableCell>
            </TableRow>
        )
    }

    TableRowRenderTwo = () => {
        return (
            <TableRow>
                <TableCell colSpan={6} align="center" style={{ fontFamily: "ProximaNova-Regular", fontSize: "18px", color: "#000", whiteSpace: "nowrap" }}>{this.t("assessorDocument.noData")}</TableCell>
            </TableRow>
        )
    }


    render() {

        return (
            <div>
                {this.state.loading && <Spinner spinnerModal={this.state.loading} />}
                <AppSidebar>
                    {this.state.isDocOpen ? (<AssessorDocument name={this.state.documentName} />) : (
                        <Box margin="35px 0px" mr="30px">
                            <Box display={{ xs: "block", md: "flex" }} justifyContent="space-between" mb="40px">
                                <Box>
                                    <Box mb="5px" fontFamily="ProximaNova-Bold" fontSize={{ xs: "24px", sm: "30px", md: "44px" }} color="#1b3c69">{this.t("assessorDocument.title")} </Box>
                                    <Box color="#1b3c69" fontSize={{ xs: "14px", sm: "20px" }}>{this.t("assessorDocument.description")}</Box>
                                </Box>
                            </Box>
                            <Box style={{ borderRadius: "10px", backgroundColor: "#eff6ff" }}>
                                <Grid container spacing={4} style={{ alignItems: "end", padding: "20px 15px 10px 15px" }}>
                                    <Grid item xs={12} sm={6} md={5}>
                                        <Box style={{ color: "#1b3c69" }}>
                                            <div style={{ marginBottom: "5px", fontSize: "18px" }}>{this.t("assessorDocument.filter.byClient")}</div>
                                            <Select
                                                className="assessorDocument"
                                                autofocus={true}
                                                openOnFocus={true}
                                                classNamePrefix="react-select"
                                                placeholder={this.t("assessorDocument.filter.byClient")}
                                                isSearchable={false}
                                                isClearable={true}
                                                options={this.clientsData()}
                                                value={this.clientsDataValue()}
                                                onChange={(event: any) => {
                                                    this.filterClients(event)
                                                }}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={5}>
                                        <Box style={{ color: "#1b3c69" }}>
                                            <div style={{ marginBottom: "5px", fontSize: "18px" }}>{this.t("assessorDocument.filter.byProject")}</div>
                                            <Select
                                                className="assessorDocumentprojects"
                                                classNamePrefix="react-select"
                                                placeholder={this.t("assessorDocument.filter.byProject")}
                                                isSearchable={false}
                                                options={this.projectData()}
                                                value={this.projectDataValue()}
                                                onChange={(event: any) => {
                                                    this.filterProjects(event)
                                                }}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={2}>
                                        <Box>
                                            <RedButton onClick={this.onApplyButtonClick}>{this.t("assessorDocument.filter.btn")}</RedButton>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box mt="60px">

                                <Box>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Box mb="25px" display="flex" justifyContent="space-between" alignItems="center">
                                                <Box fontSize="22px" fontWeight="bold" fontFamily="ProximaNova-Semibold"></Box>
                                                <TextField
                                                    id="search"
                                                    value={this.state.search}
                                                    onChange={(event: any) => {
                                                        this.onChangeHandler(event.target.value, 0);
                                                    }}
                                                    placeholder={this.t("assessorDocument.search")}
                                                    variant="outlined"
                                                    InputProps={{
                                                        startAdornment: <SearchIcon style={{ marginRight: "15px", alignItems: locallanguage ? "right" : "left" }} />,
                                                        style: { borderRadius: "10px" }
                                                    }}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} style={{ overflow: "auto" }} className="Project-Document">
                                            {this.TableRender()}
                                            <div className="table-Pagination-Data">
                                                {this.state.search === "" ? (
                                                    <Pagination
                                                        count={Math.ceil(this.state.data.length / this.state.rowsPerPage)}
                                                        onChange={(event: unknown, newPage: number) =>
                                                            this.setState({
                                                                ...this.state,
                                                                page: newPage - 1,
                                                            })
                                                        }
                                                        variant="outlined"
                                                        shape="rounded"
                                                        showFirstButton
                                                        dir="ltr"
                                                        showLastButton
                                                        siblingCount={0}
                                                        boundaryCount={1}
                                                    />
                                                ) : (
                                                    <Pagination
                                                        count={Math.ceil(this.state.filterData.length / this.state.rowsPerPage)}
                                                        onChange={(event: unknown, newPage: number) =>
                                                            this.setState({
                                                                ...this.state,
                                                                page: newPage - 1,
                                                            })
                                                        }
                                                        variant="outlined"
                                                        shape="rounded"
                                                        showFirstButton
                                                        dir="ltr"
                                                        showLastButton
                                                        siblingCount={0}
                                                        boundaryCount={1}
                                                    />
                                                )}
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Box>
                        </Box>)}
                </AppSidebar>
            </div>
        )
    }
}

export default withRouter(withTranslation()(AssessorProjectDocs))


const RedButton = styled(Button)({
    backgroundColor: "#f94b4b",
    color: "white",
    borderRadius: "10px",
    fontFamily: "ProximaNova-Semibold",
    alignItems: "center",
    fontSize: "18px",
    textTransform: "none",
    padding: "9px 25px",
    "&:hover": {
        color: "white",
        backgroundColor: "#f94b4b",
    },
    "@media(max-width:600px)": {
        width: "100%",
        marginTop: "27px",
    },
    "@media(max-width:992px)": {
        padding: "8px 15px",
    }
});


// Customizable Area End