import React from "react";
// Customizable Area Start
import {
    Box,
    Grid,
    Typography,
    Button,
    Dialog,
    DialogContent,
    InputBase,
    FormControlLabel,
    InputAdornment,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Link,
    Radio
} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';
import GetAppIcon from '@material-ui/icons/GetApp';
import CloseIcon from '@material-ui/icons/Close';
import { styled } from "@material-ui/styles";
import Select from "react-select";
import * as Yup from "yup";
import { Formik } from "formik";
//@ts-ignore
import { CSVLink } from 'react-csv';
import { toast } from "react-toastify";
import OnlineToolsDialogController from "./OnlineToolsDialogController.web";
import Spinner from "../../../../components/src/Spinner.web";
const LanguageData = localStorage.getItem("language") === "ar";
const uploadIcon = require("../../assets/upload.png");
const editIcon = require("../../assets/edit.png");
const deleteIcon = require("../../assets/delete.png");
const warningIcon = require("../../assets/warning.png");
// Customizable Area End
// Customizable Area Start

class OnlineToolsDialog extends OnlineToolsDialogController {

    questionSchema = () => {
        return Yup.object().shape({
            question: Yup.string().required(this.t("onlineToolDialog.errorMessages.question")),
            mark: Yup.string().required(this.t("onlineToolDialog.errorMessages.mark")),
            competency: Yup.string().required(this.t("onlineToolDialog.errorMessages.competency")),
            industry: Yup.string().required(this.t("onlineToolDialog.errorMessages.industry")),
            level: Yup.string().required(this.t("onlineToolDialog.errorMessages.level")),
            onlineToolId: Yup.string().required(this.t("onlineToolDialog.errorMessages.onlineTool")),
            noOfOption: Yup.string().required(this.t("onlineToolDialog.errorMessages.noOfQuestion")),
            questionImage: Yup.mixed()
                .test("fileSize", this.t("onlineToolDialog.errorMessages.fileSize"), (file) => {
                    return typeof file !== "string"
                        ? (!file || (file && file.size <= 10 * 1024 * 1024))
                        : true
                }),
            optionArray: Yup.array().of(
                Yup.object().shape({
                    answer: Yup.string().when(["image_url"], {
                        is: (image_url: any) => {
                            return !image_url;
                        },
                        then: Yup.string().required(this.t("onlineToolDialog.errorMessages.option"))
                    }).nullable(),
                    image_url: Yup.mixed()
                        .test("fileSize", this.t("onlineToolDialog.errorMessages.fileSize"), (file) => {
                            return typeof file !== "string"
                                ? (!file || (file && file.size <= 10 * 1024 * 1024))
                                : true
                        }),
                    bothPresent: Yup.string().when(["answer", "image_url"], {
                        is: (answer: any, image_url: any) => {
                            return answer && image_url;
                        },
                        then: Yup.string().required(this.t("onlineToolDialog.errorMessages.bothNotAccept"))
                    }),
                })),
            correctAnswer: Yup.array().when(["optionArray", "noOfOption"], {
                is: (optionArray: any[], noOfOption: any) => {
                    return optionArray.every((option: any) => (option.answer || option.image_url) && !(option.answer && option.image_url)) && noOfOption;
                },
                then: Yup.array()
                    .test('rightAnswer',
                        this.t("onlineToolDialog.errorMessages.correctAns"),
                        (obj: any) => {
                            return obj.some((a: any) => a.correct_answer);
                        }
                    ),
            }),
        });
    }

    renderAddQuestionDialog = () => {
        return (
            <Dialog
                open={this.state.openDialogName === "AddQuestion"}
                fullWidth={false}
                maxWidth={"sm"}
                scroll="body"
                onClose={() => { }}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogContent style={{ padding: "0px", maxWidth: "500px", width: "100%" }}>
                    <Box style={{ textAlign: "right", margin: "10px 10px 0px 0px" }}>
                        <CloseIcon onClick={() => this.props.changeDialog(null)} style={{ cursor: "pointer", fontSize: "35px", color: "#787878" }} />
                    </Box>
                    <Box padding={{ xs: "15px 25px 25px", sm: "15px 40px 40px" }}>
                        <Box
                            id="modal-title"
                            fontWeight="bolder"
                            fontSize={{ xs: "20px", sm: "30px" }}
                            fontFamily="ProximaNova-Bold"
                            mb="20px"
                            style={{ textAlign: LanguageData ? "right" : "unset" }}
                        >
                            {this.t("onlineToolDialog.title.addQuestion")}
                        </Box>
                        <Typography variant="body2" id="modal-description" style={{ textAlign: LanguageData ? "right" : "unset" }}>
                            {this.t("onlineToolDialog.label.selectOption")}<span style={{ color: "red" }}>*</span>
                        </Typography>
                        <Box mt="5px" display={{ xs: "block", sm: "flex" }}>
                            <CustomButton style={{ marginLeft: LanguageData ? "10px" : "unset" }} onClick={() => this.setState({ openDialogName: "Upload" })}>{this.t("onlineToolDialog.button.upload")}</CustomButton>
                            <RedButton onClick={() => this.setState({ openDialogName: "Add" })}>{this.t("onlineToolDialog.button.question")}</RedButton>
                        </Box>
                    </Box>
                </DialogContent>
            </Dialog>
        );
    }

    renderUploadQuestionDialog = () => {
        return (
            <Dialog
                open={this.state.openDialogName === "Upload"}
                fullWidth={false}
                maxWidth={"sm"}
                scroll="body"
                onClose={() => { }}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogContent style={{ padding: "0px" }}>
                    <Box style={{ textAlign: "right", margin: "10px 10px 0px 0px" }}>
                        <CloseIcon onClick={() => this.props.changeDialog(null)} style={{ fontSize: "35px", color: "#787878", cursor: "pointer" }} />
                    </Box>
                    <Box padding={{ xs: "15px 25px 25px", sm: "15px 40px 40px" }}>
                        <Box
                            id="modal-title"
                            fontWeight="bolder"
                            fontSize={{ xs: "20px", sm: "30px" }}
                            fontFamily="ProximaNova-Bold"
                            mb="20px"
                            style={{ textAlign: LanguageData ? "right" : "left" }}
                        >
                            {this.t("onlineToolDialog.title.uploadQuestion")}
                        </Box>
                        <Box display="flex" alignItems={{ xs: "start", sm: "center" }} justifyContent={LanguageData ? "flex-start" : "space-between"} flexDirection={{ xs: "column-reverse", sm: "row" }}>
                            <Typography variant="body2" id="modal-description"> </Typography>
                            <Box mb={{ xs: "10px", sm: "0px" }}>
                                <CSVLink
                                    data={SampleFileData}
                                    filename={'Upload Questions.csv'}
                                    target="_blank"
                                    style={{ borderBottom: "1px solid blue" }}
                                >
                                    {this.t("onlineToolDialog.link.download")}
                                </CSVLink>
                            </Box>
                        </Box>
                        <Box mt="15px" mb="5px">
                            <form onDragEnter={this.handleDrag} onSubmit={(e: any) => e.preventDefault()} style={{ position: "relative" }}>
                                <input
                                    type="file"
                                    accept=".csv"
                                    style={{ display: "none" }}
                                    id="questionFile"
                                    onChange={(event: any) => {
                                        this.setState({
                                            questionFile: event.target.files[0]
                                        });
                                    }}
                                />
                                <label htmlFor="questionFile" style={{ width: "100%" }}>
                                    <Box p={{ xs: "25px", sm: "25px 105px" }} textAlign="center" width="100%" border="1px dashed #bebebe" borderRadius="8px" style={{ cursor: "pointer" }}>
                                        <img src={uploadIcon} />
                                        <Box mt="15px">{this.t("onlineToolDialog.label.dropFile")}</Box>
                                    </Box>
                                </label>
                                {this.state.dragActive && <div id="drag-questions-file-element" onDragEnter={this.handleDrag} onDragLeave={this.handleDrag} onDragOver={this.handleDrag} onDrop={this.handleDrop}></div>}
                            </form>
                            <Box mt="3px">{this.state.questionFile?.name}</Box>
                            {(this.state.uploadSubmitBtn && !this.state.questionFile) && <div className="text-danger">{this.t("onlineToolDialog.errorMessages.addFile")}</div>}
                        </Box>
                        <div className="text-danger" style={{ textAlign: LanguageData ? "right" : "left" }}>{this.t("onlineToolDialog.errorMessages.only.jpg,.jpeg,.png,.mp4,URLsaresupportedforimageandvideooptions")}</div>
                        <Box mt="5px" display={{ xs: "block", sm: "flex" }}>
                            <CustomButton style={{ marginLeft: LanguageData ? "10px" : "unset" }} onClick={() => this.props.changeDialog(null)}>{this.t("onlineToolDialog.button.cancel")}</CustomButton>
                            <RedButton style={{ padding: "5px 15px" }} onClick={this.addBulkQuestion}>{this.t("onlineToolDialog.button.submit")}</RedButton>
                        </Box>
                    </Box>
                </DialogContent>
            </Dialog>
        );
    }

    renderCSVErrorDialog = () => {
        return (
            <Dialog
                open={this.state.openDialogName === "CSVError"}
                fullWidth={false}
                maxWidth={"sm"}
                scroll="body"
                onClose={() => { }}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogContent style={{ padding: "0px" }}>
                    <Box style={{ textAlign: "right", margin: "10px 10px 0px 0px" }}>
                        <CloseIcon onClick={() => this.setState({ openDialogName: "Upload", questionFile: null })} style={{ fontSize: "35px", color: "#787878", cursor: "pointer" }} />
                    </Box>
                    <Box display="flex" alignItems="center" flexDirection="column" padding={{ xs: "15px 25px 25px", sm: "15px 70px 40px" }}>
                        <img src={warningIcon} alt="warning.png" />
                        <Box
                            id="modal-title"
                            fontWeight="bolder"
                            fontSize={{ xs: "20px", sm: "30px" }}
                            fontFamily="ProximaNova-Bold"
                            mb="20px"
                            mt="15px"
                            style={{ color: "#f94b4b" }}
                        >
                            {this.t("onlineToolDialog.title.uploadError")}
                        </Box>
                        <Typography variant="body2" id="modal-description" style={{ textAlign: "center", fontSize: "18px", marginBottom: "10px" }}>
                            {this.t("onlineToolDialog.description.csvError", { total: this.state.totalQuestion, error: this.state.errorQuestion, success: this.state.successQuestion })}
                        </Typography>
                        <RedButton
                            startIcon={<GetAppIcon />}
                            onClick={this.onCSVFileDownload}
                        >
                            {this.t("onlineToolDialog.button.download")}
                        </RedButton>
                    </Box>
                </DialogContent>
            </Dialog>
        );
    }

    link = (href: any, isOptionLink: boolean, setFieldValue: any, index?: number) => {
        return (
            <Box display="flex" alignItems="center">
                <Link target="_blank" href={href} style={{ cursor: "pointer" }}>
                    {this.t("onlineToolDialog.label.viewFile")}
                </Link>
                <HighlightOffOutlinedIcon
                    titleAccess="Delete"
                    style={{ cursor: "pointer", marginLeft: "4px", height: "14px", width: "14px", color: "red" }}
                    onClick={() => {
                        if (isOptionLink) {
                            setFieldValue(`optionArray[${index}].image_url`, null);
                            this.removeFile(index ?? 0);
                        } else {
                            setFieldValue("questionImage", null);
                            this.setState({ questionImage: null, isfileEdit: true });
                        }
                    }}
                />
            </Box>
        );
    }

    viewFile = (item: any, index: number, setFieldValue: any) => {
        if (item.isEdit && item.file) {
            return this.link(URL.createObjectURL(item.file), true, setFieldValue, index);
        } else if (item.image_url) {
            return this.link(item.image_url, true, setFieldValue, index);
        }
    }

    viewAttachment = (setFieldValue: any) => {
        return (this.state.isfileEdit && this.state.questionImage)
            ? this.link(URL.createObjectURL(this.state.questionImage), false, setFieldValue)
            : this.state.questionImage && this.link(this.state.questionImage, false, setFieldValue)
    }

    getErrorMessage = (touched: any, errors: any, value: any) => {
        return touched[value] && errors[value] &&
            <div className="text-danger">{errors[value]}</div>
    }

    getErrorMessageForOption = (touched: any, errors: any, index: number) => {
        if (touched.optionArray && errors.optionArray && errors.optionArray[index]) {
            return (
                <>
                    <div className="text-danger">{errors.optionArray[index]?.answer}</div>
                    <div className="text-danger">{errors.optionArray[index]?.bothPresent}</div>
                    <div className="text-danger">{errors.optionArray[index]?.image_url}</div>
                </>
            )
        }
    }

    ViewOption = (setFieldValue: any, touched: any, errors: any) => {
        return (
            this.state.optionArray.map((item: any, index: any) => {
                const optionName = (index + 10).toString(36).toUpperCase();
                return (
                    <Grid item xs={12} md={6} key={`${index + 1}`}>
                        <Box display="flex">
                            <FormControlLabel
                                style={{ margin: "-11px 0px -5px -12px" }}
                                control={
                                    <Radio
                                        checked={item.correct_answer}
                                        onChange={() => {
                                            setFieldValue(`correctAnswer[${index}].correct_answer`, true);
                                            this.onCorrectAnswerClick(index, true);
                                        }}
                                        name={`Option ${optionName}`}
                                    />
                                }
                                label=""
                            />
                            <Typography variant="body2" style={{ marginBottom: "5px" }}>
                                {this.t("onlineToolDialog.label.option")} {optionName}<span style={{ color: "red" }}>*</span>
                            </Typography>
                        </Box>
                        <Box>
                            <CustomInputBase
                                fullWidth
                                multiline
                                placeholder={`${this.t("onlineToolDialog.placeholder.option")} ${optionName}`}
                                value={item.answer}
                                onChange={(event: any) => {
                                    setFieldValue(`optionArray[${index}].answer`, event.target.value);
                                    this.onOptionValueChange(index, event.target.value);
                                }}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <>
                                            <input
                                                type="file"
                                                onChange={(event: any) => {
                                                    let FileNames = this.state.optionArray.map((item: any) => item?.FileName)
                                                    if (event.target.files[0]) {
                                                        if (FileNames.includes(event.target.files[0].name)) {
                                                            toast.warning(this.t("onlineToolDialog.errorMessages.multipleoptionscannothavesameanswer"));
                                                        } else {
                                                            setFieldValue(`optionArray[${index}].image_url`, event.target.files[0]);
                                                            this.onImageVideoSelect(index, event);
                                                        }
                                                    }
                                                }}
                                                accept="image/*,video/*"
                                                style={{ display: "none" }}
                                                id={`option-${index}`}
                                            />
                                            <label htmlFor={`option-${index}`} style={{ cursor: "pointer" }}>
                                                <AttachFileIcon style={{ color: "#929495" }} />
                                            </label>
                                        </>
                                    </InputAdornment>
                                }
                            />
                        </Box>
                        <Box mt="3px" fontSize="14px">
                            {this.viewFile(item, index, setFieldValue)}
                        </Box>
                        {this.getErrorMessageForOption(touched, errors, index)}
                    </Grid>
                )
            })
        )
    }

    Questionrender = (setFieldValue: any, touched: any, errors: any) => {
        return (
            <Grid item xs={12}>
                <Typography variant="body2" style={{ marginBottom: "5px", textAlign: LanguageData ? "right" : "unset" }}>
                    {this.t("onlineToolDialog.label.question")}<span style={{ color: "red" }}>*</span>
                </Typography>
                <CustomInputBase
                    fullWidth
                    multiline
                    minRows={2}
                    placeholder={this.t("onlineToolDialog.placeholder.question")}
                    value={this.state.question}
                    onChange={(event: any) => {
                        setFieldValue("question", event.target.value);
                        this.setState({ question: event.target.value });
                    }}
                    style={{ padding: "15px 20px" }}
                />
                {this.getErrorMessage(touched, errors, "question")}
            </Grid>
        )
    }

    Competency = (setFieldValue: any, touched: any, errors: any) => {
        return (
            <Grid item xs={12}>
                <Typography variant="body2" style={{ marginBottom: "5px", textAlign: LanguageData ? "right" : "unset" }}>
                    {this.t("onlineToolDialog.label.competency")}<span style={{ color: "red" }}>*</span>
                </Typography>
                <Select
                    classNamePrefix="react-select"
                    placeholder={this.t("onlineToolDialog.placeholder.competency")}
                    isSearchable={false}
                    options={this.state.competencyList}
                    value={
                        this.state.competencyList.filter(
                            (option: any) =>
                                option.value === this.state.competency
                        )[0]
                    }
                    onChange={(event: any) => {
                        setFieldValue("competency", event.value);
                        this.setState({ competency: event.value });
                    }}
                />
                {this.getErrorMessage(touched, errors, "competency")}
            </Grid>
        )
    }

    UploadFileTextfield = (setFieldValue: any, touched: any, errors: any) => {
        return (
            <Grid item xs={12}>
                <Typography variant="body2" style={{ marginBottom: "5px", textAlign: LanguageData ? "right" : "unset" }}>
                    {this.t("onlineToolDialog.label.uploadFile")}
                </Typography>
                <Box>
                    <form onDragEnter={this.handleDrag} onSubmit={(e: any) => e.preventDefault()} style={{ position: "relative" }}>
                        <input
                            type="file"
                            accept="image/png, image/jpeg, image/jpg, video/mp4"
                            style={{ display: "none" }}
                            id="questionImage"
                            onChange={(event: any) => {
                                if (event.target.files[0]) {
                                    setFieldValue("questionImage", event.target.files[0]);
                                    this.setState({
                                        questionImage: event.target.files[0],
                                        isfileEdit: true
                                    });
                                }
                            }}
                        />
                        <label htmlFor="questionImage" style={{ width: "100%" }}>
                            <Box p={{ xs: "25px", sm: "25px 105px" }} textAlign="center" width="100%" border="1px dashed #bebebe" borderRadius="8px" style={{ cursor: "pointer" }}>
                                <img src={uploadIcon} />
                                <Box mt="15px">{this.t("onlineToolDialog.placeholder.dropFile")}</Box>
                            </Box>
                        </label>
                        {this.state.dragActive && <div id="drag-questions-file-element" onDragEnter={this.handleDrag} onDragLeave={this.handleDrag} onDragOver={this.handleDrag} onDrop={(e: any) => this.handleFileDrop(e, setFieldValue)}></div>}
                    </form>
                    <Box mt="3px" fontSize="14px">
                        {this.viewAttachment(setFieldValue)}
                        {this.getErrorMessage(touched, errors, "questionImage")}
                    </Box>
                </Box>
            </Grid>
        )
    }

    IndustryList = (setFieldValue: any, touched: any, errors: any) => {
        return (
            <Grid item xs={12} sm={6}>
                <Typography variant="body2" style={{ marginBottom: "5px", textAlign: LanguageData ? "right" : "unset" }}>
                    {this.t("onlineToolDialog.label.industry")}<span style={{ color: "red" }}>*</span>
                </Typography>
                <Select
                    classNamePrefix="react-select"
                    placeholder={this.t("onlineToolDialog.placeholder.industry")}
                    isSearchable={false}
                    options={this.state.industryList}
                    value={
                        this.state.industryList.filter(
                            (option: any) =>
                                option.value === this.state.industry
                        )[0]
                    }
                    onChange={(event: any) => {
                        setFieldValue("industry", event.value);
                        this.setState({ industry: event.value });
                    }}
                />
                {this.getErrorMessage(touched, errors, "industry")}
            </Grid>
        )
    }

    LevelRender = (setFieldValue: any, touched: any, errors: any) => {
        return (
            <Grid item xs={12} sm={6}>
                <Typography variant="body2" style={{ marginBottom: "5px", textAlign: LanguageData ? "right" : "unset" }}>
                    {this.t("onlineToolDialog.label.level")}<span style={{ color: "red" }}>*</span>
                </Typography>
                <Select
                    classNamePrefix="react-select"
                    placeholder={this.t("onlineToolDialog.placeholder.level")}
                    isSearchable={false}
                    options={levelList}
                    value={
                        levelList.filter(
                            (option: any) =>
                                option.value === this.state.level
                        )[0]
                    }
                    onChange={(event: any) => {
                        setFieldValue("level", event.value);
                        this.setState({ level: event.value });
                    }}
                />
                {this.getErrorMessage(touched, errors, "level")}
            </Grid>
        )
    }

    OnlineToolIdRedner = (setFieldValue: any, touched: any, errors: any) => {
        return (
            <Grid item xs={12} sm={6}>
                <Typography variant="body2" style={{ marginBottom: "5px", textAlign: LanguageData ? "right" : "unset" }}>
                    {this.t("onlineToolDialog.label.onlineTool")}<span style={{ color: "red" }}>*</span>
                </Typography>
                <Select
                    classNamePrefix="react-select"
                    placeholder={this.t("onlineToolDialog.placeholder.onlineTool")}
                    isSearchable={false}
                    options={this.state.onlineToolList}
                    value={
                        this.state.onlineToolList.filter(
                            (option: any) =>
                                option.value === this.state.onlineToolId
                        )[0]
                    }
                    onChange={(event: any) => {
                        setFieldValue("onlineToolId", event.value);
                        this.setState({ onlineToolId: event.value });
                    }}
                />
                {this.getErrorMessage(touched, errors, "onlineToolId")}
            </Grid>
        )
    }

    MarkRedner = (setFieldValue: any, touched: any, errors: any) => {
        return (
            <Grid item xs={12} sm={6}>
                <Typography variant="body2" style={{ marginBottom: "5px", textAlign: LanguageData ? "right" : "unset" }}>
                    {this.t("onlineToolDialog.label.mark")}<span style={{ color: "red" }}>*</span>
                </Typography>
                <CustomInputBase
                    fullWidth
                    placeholder={this.t("onlineToolDialog.placeholder.mark")}
                    value={this.state.mark}
                    onChange={(event: any) => {
                        this.onMarkChange(event, setFieldValue);
                    }}
                />
                {this.getErrorMessage(touched, errors, "mark")}
            </Grid>
        )
    }

    renderAddEditCloneQuestionDialog = () => {
        return (
            <Dialog
                open={["Add", "Edit", "Clone"].includes(this.state.openDialogName ?? "")}
                fullWidth={false}
                maxWidth={"sm"}
                scroll="body"
                onClose={() => { }}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogContent style={{ padding: "0px" }}>
                    <Box style={{ paddingTop: "0px !important", textAlign: "right", margin: "10px 10px 0px 0px" }}>
                        <CloseIcon onClick={() => this.onDialogClose()} style={{ cursor: "pointer", fontSize: "35px", color: "#787878" }} />
                    </Box>
                    <Box padding={{ xs: "15px 25px 25px", sm: "15px 40px 40px" }}>
                        <Box
                            id="modal-title"
                            fontWeight="bolder"
                            fontSize={{ xs: "20px", sm: "30px" }}
                            fontFamily="ProximaNova-Bold"
                            mb="20px"
                            style={{ textAlign: LanguageData ? "right" : "left" }}
                        >
                            {this.t(`onlineToolDialog.label.${this.state.openDialogName}`)}
                        </Box>
                        <Formik
                            enableReinitialize={this.state.isNoOfOptionChange}
                            initialValues={{
                                question: this.state.question,
                                mark: this.state.mark,
                                competency: this.state.competency,
                                industry: this.state.industry,
                                level: this.state.level,
                                onlineToolId: this.state.onlineToolId,
                                noOfOption: this.state.noOfOption,
                                questionImage: this.state.questionImage,
                                optionArray: this.state.optionArray,
                                correctAnswer: [...this.state.optionArray],
                            }}
                            validationSchema={this.questionSchema}
                            onSubmit={(values) => {
                                let FileAnswers = this.state.optionArray.map((item: any) => item?.answer)
                                let TypeFileUpload = this.state.optionArray.filter((data:any)=> data?.FileName !== "")
                                let sameornot = FileAnswers.filter((item: any, index: any) => FileAnswers.indexOf(item) !== index)
                                if (sameornot.length !== 0 && TypeFileUpload.length === 0) {
                                    toast.warning(this.t("onlineToolDialog.errorMessages.multipleoptionscannothavesameanswer"));
                                } else {
                                    this.state.openDialogName === "Add" ? this.addQuestion() : this.editQuestion();
                                }
                            }}
                        >
                            {({ errors, touched, setFieldValue, handleSubmit }) => (
                                <form
                                    onSubmit={handleSubmit}
                                    noValidate
                                    autoComplete="off"
                                >
                                    <Grid container spacing={3}>
                                        {this.Questionrender(setFieldValue, touched, errors)}
                                        {this.Competency(setFieldValue, touched, errors)}
                                        {this.IndustryList(setFieldValue, touched, errors)}
                                        {this.LevelRender(setFieldValue, touched, errors)}
                                        {this.OnlineToolIdRedner(setFieldValue, touched, errors)}
                                        {this.MarkRedner(setFieldValue, touched, errors)}
                                        <Grid item xs={12}>
                                            <Typography variant="body2" style={{ marginBottom: "5px", textAlign: LanguageData ? "right" : "unset" }}>
                                                {this.t("onlineToolDialog.label.noOfQuestion")}<span style={{ color: "red" }}>*</span>
                                            </Typography>
                                            <Select
                                                classNamePrefix="react-select"
                                                placeholder={this.t("onlineToolDialog.label.noOfQuestion")}
                                                isSearchable={false}
                                                options={NoOfOption}
                                                value={
                                                    NoOfOption.filter(
                                                        (option: any) =>
                                                            option.value === this.state.noOfOption
                                                    )[0]
                                                }
                                                onChange={(event: any) => {
                                                    setFieldValue("noOfOption", event.value);
                                                    this.onNoOfOptionChange(event);
                                                }}
                                            />
                                            {this.getErrorMessage(touched, errors, "noOfOption")}
                                        </Grid>
                                        {this.UploadFileTextfield(setFieldValue, touched, errors)}
                                        {this.ViewOption(setFieldValue, touched, errors)}
                                    </Grid>
                                    {this.getErrorMessage(touched, errors, "correctAnswer")}
                                    <Box mt="30px" display={{ xs: "block", sm: "flex" }}>
                                        <CustomButton style={{ marginLeft: LanguageData ? "10px" : "" }} onClick={() => this.onCancel()}>{this.t("onlineToolDialog.button.cancel")}</CustomButton>
                                        <RedButton type="submit" onClick={() => this.setState({ isSubmitBtnClick: true })}>{this.t(`onlineToolDialog.label.${this.state.openDialogName}`)}</RedButton>
                                    </Box>
                                </form>
                            )}
                        </Formik>
                    </Box>
                </DialogContent>
            </Dialog>
        );
    }

    renderDeleteQuestionDialog = () => {
        return (
            <DeleteDialog
                open={this.state.openDialogName === "Delete"}
                fullWidth={false}
                maxWidth={"sm"}
                scroll="body"
                onClose={() => { }}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogContent style={{ padding: "0px", maxWidth: "500px" }}>
                    <Box style={{ paddingTop: "0px !important", textAlign: "right", margin: "10px 10px 0px 0px" }}>
                        <CloseIcon onClick={() => this.props.changeDialog(null)} style={{ cursor: "pointer", fontSize: "35px", color: "#787878" }} />
                    </Box>
                    <Box display="flex" alignItems="center" flexDirection="column" padding={{ xs: "15px 25px 25px", sm: "15px 70px 40px" }}>
                        <Box
                            id="modal-title"
                            fontWeight="bolder"
                            fontSize={{ xs: "20px", sm: "30px" }}
                            fontFamily="ProximaNova-Bold"
                            mb="20px"
                        >
                            {this.t("onlineToolDialog.title.delete")}
                        </Box>
                        <Box textAlign="center">
                            {this.t("onlineToolDialog.description.delete")}
                        </Box>
                        <Box mt="30px" display="flex">
                            <CustomButton onClick={() => this.props.changeDialog(null)} style={{ marginBottom: "0px", padding: "5px 10px" }}>{this.t("onlineToolDialog.button.cancel")}</CustomButton>
                            <RedButton onClick={this.deleteQuestion} style={{ padding: "5px 10px", marginRight: LanguageData ? '10px' : "unset" }}>{this.t("onlineToolDialog.button.delete")}</RedButton>
                        </Box>
                    </Box>
                </DialogContent>
            </DeleteDialog>
        );
    }

    renderAddEditOnlineToolDialog = () => {
        return (
            <Dialog
                open={["AddEditTool", "DeleteTool"].includes(this.state.openDialogName ?? "")}
                fullWidth={false}
                maxWidth={"md"}
                scroll="body"
                onClose={() => { }}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogContent style={{ padding: "0px" }}>
                    <Box style={{ paddingTop: "0px !important", textAlign: "right", margin: "10px 10px 0px 0px" }}>
                        <CloseIcon onClick={() => this.props.changeDialog(null)} style={{ cursor: "pointer", fontSize: "35px", color: "#787878" }} />
                    </Box>
                    <Box padding={{ xs: "15px 25px 25px", sm: "15px 40px 40px" }}>
                        <Box mb="20px" display={{ xs: "block", sm: "flex" }} justifyContent="space-between" alignItems="center">
                            <Box
                                id="modal-title"
                                fontWeight="bolder"
                                mr="42px"
                                fontSize={{ xs: "20px", sm: "30px" }}
                                fontFamily="ProximaNova-Bold"
                            >
                                {this.t("onlineToolDialog.title.addTool")}
                            </Box>
                            <Box mt={{ xs: "10px", sm: "0px" }} style={{ color: "#f26a42" }}>
                                <span onClick={this.addToolRecord} style={{ cursor: "pointer" }}>{this.t("onlineToolDialog.link.addTool")}</span>
                            </Box>
                        </Box>
                        <Box>
                            <Grid container>
                                <Grid item xs={12} style={{ overflow: "auto" }}>
                                    {this.renderAddEditOnlineToolDialogTable()}
                                </Grid>
                            </Grid>
                        </Box>
                        <Box mt="20px" display="flex" justifyContent="end">
                            <CustomButton onClick={() => this.props.changeDialog(null)} style={{ marginRight: "0px", marginBottom: "0px", padding: "5px 10px" }}>{this.t("onlineToolDialog.button.cancel")}</CustomButton>
                        </Box>
                    </Box>
                </DialogContent>
            </Dialog>
        );
    }

    renderAddEditOnlineToolDialogTable = () => {
        return (
            <Table aria-label="simple table" style={{ border: "1px solid #E0E0E0", borderRadius: "10px", borderCollapse: "separate" }} className="custom-table">
                <TableHead>
                    <TableRow>
                        <TableCell align="left" style={{ fontFamily: "ProximaNova-Bold", fontSize: "16px" }}>{this.t("onlineToolDialog.label.logo")}</TableCell>
                        <TableCell style={{ fontFamily: "ProximaNova-Bold", fontSize: "16px" }}>{this.t("onlineToolDialog.label.tool")}</TableCell>
                        <TableCell style={{ fontFamily: "ProximaNova-Bold", fontSize: "16px" }}>{this.t("onlineToolDialog.label.shortDescription")}</TableCell>
                        <TableCell style={{ fontFamily: "ProximaNova-Bold", fontSize: "16px" }}>{this.t("onlineToolDialog.label.color")}</TableCell>
                        <TableCell style={{ fontFamily: "ProximaNova-Bold", fontSize: "16px" }}>{this.t("onlineToolDialog.label.action")}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {this.state.onlineToolList.length > 0 ?
                        <>
                            {this.state.onlineToolList.map((tool: any, index: number) => {
                                let element = <img src={tool.image} alt="not found!" style={{ height: "30px", width: "30px" }} />;
                                if (tool.value === this.state.editToolId && tool.image) {
                                    element = <Box textAlign="center" width="100%" border="1px solid #bebebe" borderRadius="8px">
                                        <Box p="7px 0" color="red" fontSize="18px" whiteSpace="nowrap">
                                            <Box pl="11px" fontSize="16px" display="flex" justifyContent="space-between" alignItems="center">
                                                <span style={{ paddingRight: "15px" }}>{`logo${index}.jpg`}</span>
                                                <CloseIcon style={{ cursor: "pointer", paddingRight: "5px" }} onClick={() => this.onToolLogoChange(null, index)} />
                                            </Box>
                                        </Box>
                                    </Box>;
                                } else if (tool.value === this.state.editToolId) {
                                    element = <Box>
                                        <input
                                            type="file"
                                            accept="image/*"
                                            style={{ display: "none" }}
                                            id={`logo-${index}`}
                                            onChange={(event: any) => {
                                                this.onToolLogoChange(event.target.files[0], index);
                                            }}
                                        />
                                        <label htmlFor={`logo-${index}`}>
                                            <Box textAlign="center" width="100%" border="1px solid #bebebe" borderRadius="8px" style={{ cursor: "pointer" }}>
                                                <Box p="6px 11px" color="red" fontSize="18px" whiteSpace="nowrap">
                                                    {this.t("onlineToolDialog.label.browseFile")}
                                                </Box>
                                            </Box>
                                        </label>
                                    </Box>
                                }

                                return this.TableBodyMap(tool, index, element)
                            })}
                        </>
                        : <TableRow>
                            <TableCell colSpan={6} align="center" style={{ fontFamily: "ProximaNova-Regular", fontSize: "18px", color: "#000", whiteSpace: "nowrap" }}>{this.t("onlineToolDialog.label.notFound")}</TableCell>
                        </TableRow>
                    }
                </TableBody>
            </Table>
        )
    }

    TableBodyMap = (tool: any, index: any, element: any) => {
        return (
            <TableRow key={`${index + 1}`}>
                <TableCell align="left" style={{ fontFamily: "ProximaNova-Regular", fontSize: "18px", color: "#000" }}>
                    {element}
                </TableCell>
                <TableCell style={{ fontFamily: "ProximaNova-Regular", fontSize: "18px", color: "#000", width: "200px" }}>
                    {tool.value === this.state.editToolId ?
                        <OnlineToolInputBase
                            autoFocus
                            value={tool.label}
                            placeholder={this.t("onlineToolDialog.placeholder.tool")}
                            inputRef={input => this.inputRef = input}
                            onChange={(event: any) => this.onToolNameChange(event, index)}
                        />
                        :
                        <span style={{
                            display: 'block',
                            width: '200px',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis'
                        }}>{tool.label}</span>
                    }
                </TableCell>
                <TableCell style={{ fontFamily: "ProximaNova-Regular", fontSize: "18px", color: "#000", width: "350px" }}>
                    {tool.value === this.state.editToolId ?
                        <OnlineToolInputBase
                            multiline
                            value={tool.description}
                            placeholder={this.t("onlineToolDialog.placeholder.description")}
                            onChange={(event: any) => this.onToolDescriptionChange(event, index)}
                        />
                        : tool.description
                    }
                </TableCell>
                <TableCell style={{ fontFamily: "ProximaNova-Regular", fontSize: "18px", color: "#000", width: "350px" }}>
                    {tool.value === this.state.editToolId ?
                        <input type="color" id="favcolor" name="favcolor" onChange={(event: any) => {
                            this.onToolLogoColor(event, index);
                        }} value={this.state.onlineToolList[index].color} />
                        : <input type="color" disabled value={tool.ToolColor} />
                    }
                </TableCell>
                <TableCell align="right" style={{ fontFamily: "ProximaNova-Regular", fontSize: "18px", color: "#000" }}>
                    {tool.value === this.state.editToolId && <SaveIcon onClick={() => this.onSaveTool(tool.value)} style={{ cursor: "pointer", color: "#736d6d" }} />}
                    {tool.value && tool.value !== this.state.editToolId && <img src={editIcon} style={{ cursor: "pointer" }} onClick={() => this.onEditButtonClick(tool, index)} />}
                    <img src={deleteIcon} style={{ marginLeft: "15px", cursor: "pointer" }} onClick={() => this.onDeleteToolButtonClick(tool)} />
                </TableCell>
            </TableRow>
        )
    }

    renderDeleteOnlineToolDialog = () => {
        return (
            <DeleteDialog
                open={this.state.openDialogName === "DeleteTool"}
                fullWidth={false}
                maxWidth={"sm"}
                scroll="body"
                onClose={() => { }}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogContent style={{ padding: "0px", maxWidth: "500px" }}>
                    <Box style={{ paddingTop: "0px !important", textAlign: "right", margin: "10px 10px 0px 0px" }}>
                        <CloseIcon onClick={() => this.setState({ openDialogName: "AddEditTool" })} style={{ cursor: "pointer", fontSize: "35px", color: "#787878" }} />
                    </Box>
                    <Box display="flex" alignItems="center" flexDirection="column" padding={{ xs: "15px 25px 25px", sm: "15px 70px 40px" }}>
                        <Box
                            id="modal-title"
                            fontWeight="bolder"
                            fontSize={{ xs: "20px", sm: "30px" }}
                            fontFamily="ProximaNova-Bold"
                            mb="20px"
                        >
                            {this.t("onlineToolDialog.title.delete")}
                        </Box>
                        <Box textAlign="center" className="centerText" fontSize="18px">
                            {this.t("onlineToolDialog.description.deleteTool", { name: this.state.deleteTool?.label })}
                        </Box>
                        <Box mt="30px" display="flex">
                            <CustomButton onClick={() => this.setState({ openDialogName: "AddEditTool" })} style={{ marginBottom: "0px", padding: "5px 10px" }}>{this.t("onlineToolDialog.button.cancel")}</CustomButton>
                            <RedButton onClick={this.deleteTool} style={{ padding: "5px 10px" }}>{this.t("onlineToolDialog.button.delete")}</RedButton>
                        </Box>
                    </Box>
                </DialogContent>
            </DeleteDialog>
        );
    }

    render() {
        return (
            <>
                <Spinner spinnerModal={this.state.loading} />
                {this.renderAddQuestionDialog()}
                {this.renderAddEditCloneQuestionDialog()}
                {this.renderDeleteQuestionDialog()}
                {this.renderUploadQuestionDialog()}
                {this.renderCSVErrorDialog()}
                {this.renderAddEditOnlineToolDialog()}
                {this.renderDeleteOnlineToolDialog()}
            </>
        )
    }
}

export default OnlineToolsDialog;

const OnlineToolInputBase = styled(InputBase)({
    fontFamily: "ProximaNova-Regular",
    border: "1px solid #bebebe",
    borderRadius: "8px",
    minHeight: "40px",
    width: "100%",
    paddingLeft: "9px",
    fontSize: "18px",
    '& .MuiInputBase-inputMultiline': {
        "&::placeholder": {
            fontSize: "16px"
        }
    }
});

const CustomInputBase = styled(InputBase)({
    fontFamily: "ProximaNova-Regular",
    border: "1px solid #d6d6d6",
    borderRadius: "10px",
    minHeight: "50px",
    paddingLeft: "20px",
    fontSize: "14px",
    '& .MuiInputBase-input': {
        "&::placeholder": {
            fontSize: "16px"
        }
    }
});

const CustomButton = styled(Button)({
    backgroundColor: "white",
    color: "#f94b4b",
    border: "1px solid #f94b4b",
    fontFamily: "ProximaNova-Semibold",
    marginRight: "15px",
    borderRadius: "8px",
    fontSize: "18px",
    textTransform: "none",
    padding: "4px 15px",
    "&:hover": {
        color: "#f94b4b",
        backgroundColor: "white",
    },
    "@media(max-width:992px)": {
        padding: "8px",
        width: "50%"
    },
    "@media(max-width:576px)": {
        width: "100%",
        marginBottom: "20px",
        padding: "3px",
    },
});

const RedButton = styled(Button)({
    backgroundColor: "#f94b4b",
    color: "white",
    borderRadius: "8px",
    fontFamily: "ProximaNova-Semibold",
    fontSize: "18px",
    textTransform: "none",
    padding: "5px 25px",
    "&:hover": {
        color: "white",
        backgroundColor: "#f94b4b",
    },
    "@media(max-width:992px)": {
        padding: "8px",
        width: "50%"
    },
    "@media(max-width:576px)": {
        width: "100%",
        padding: "3px",
    },
});

const DeleteDialog = styled(Dialog)({
    margin: "0px 61px",
    "@media(max-width:576px)": {
        margin: "0px"
    },
});

const levelList = [
    { label: "Junior", value: "junior" },
    { label: "Senior", value: "senior" },
];

const NoOfOption = [
    { label: "2", value: 2 },
    { label: "3", value: 3 },
    { label: "4", value: 4 },
    { label: "5", value: 5 },
    { label: "6", value: 6 },
];

const SampleFileData = [
    ["name", "online_tool_name", "level", "image_url", "industry_name", "competancy_name", "answers_attributes", "correct_answer", "marks"],
    ["Question 1 for testing purpose ", "MCQ", "junior", "", "Industry1", "Competency1", "modi,rahul,sonia", "modi", "2"],
    ["Question 2 for testing purpose", "SJT", "junior", "https://static.bokeh.org/logos/logo.png", "Industry1", "Competency2", "https://www.computerhope.com/cdn/big/computer.jpg,https://image.shutterstock.com/image-photo/blue-colourful-mountains-layers-sunset-600w-1062810707.jpg", "https://www.computerhope.com/cdn/big/computer.jpg", "2"],
];
// Customizable Area End
