import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { runEngine } from "../../../../framework/src/RunEngine";
import MessageEnum, {
    getName,
} from "../../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { toast } from "react-toastify";
// Customizable Area End

export const configJSON = require("../config");

export interface Props {
    id?: string;
    navigation?: any;
    location: any;
    history: any;
    match: any;
    t: any;
}

export interface S {
    // Customizable Area Start
    loading: boolean;
    page: number;
    rowsPerPage: number;
    dataLength: number;
    count: number | null;
    item: any;
    sortBy: string;
    option: string;
    search: string;
    openDialogName: string | null;
    data: any[];
    tableData: any[];
    filterData: any[];
    sortByList: any[];
    optionList: any[];
    industryList: any[];
    competencyList: any[];
    onlineToolList: any[];
    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

const levelList = [
    { label: "junior", value: "junior" },
    { label: "senior", value: "senior" },
];

export default class OnlineToolsController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    getTableDataApiCallId: string = "";
    getIndustryListApiCallId: string = "";
    getCompetencyListApiCallId: string = "";
    getOnlineToolsListApiCallId: string = "";
    cloneQuestionApiCallId: string = "";
    t: any;
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage),
        ];
        this.receive = this.receive.bind(this);
        runEngine.attachBuildingBlock(this, this.subScribedMessages);

        this.state = {
            // Customizable Area Start
            loading: false,
            page: 0,
            rowsPerPage: 20,
            dataLength: 0,
            count: null,
            item: null,
            sortBy: "",
            option: "",
            search: "",
            openDialogName: "",
            data: [],
            tableData: [],
            filterData: [],
            sortByList: [],
            optionList: [],
            industryList: [],
            competencyList: [],
            onlineToolList: [],
            // Customizable Area End
        };
        this.t = this.props.t;
    }

    async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId: any = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId && responseJson) {
                if (responseJson.status === 500) {
                    toast.error(this.t("onlineToolDialog.toast.somethingWrong"));
                    this.setState({ loading: false });
                } else if (!Array.isArray(responseJson.errors) && responseJson.errors) {
                    toast.error(responseJson.errors);
                    this.setState({ loading: false });
                } else {
                    this.handleApisResponse(apiRequestCallId, responseJson);
                }
            }
        }
    }

    async componentDidMount() {
        window.scrollTo(0, 0);
        this.getCompetencyList();
        this.getIndustryList();
        this.getOnlineToolsList();
        this.getTableDetail();
    }

    handleApisResponse = (apiRequestCallId: string, responseJson: any) => {
        if (Array.isArray(responseJson.errors)) {
            if (responseJson.errors[0].message) {
                toast.error(responseJson.errors[0].message);
            } else if (responseJson.errors[0].token) {
                toast.error(responseJson.errors[0].token);
            } else {
                toast.error(this.t("onlineToolDialog.toast.somethingWrong"));
            }
            this.setState({ loading: false });
        } else if (apiRequestCallId === this.getTableDataApiCallId) {
            this.handelTableDataApiResponse(responseJson);
        } else if (apiRequestCallId === this.getCompetencyListApiCallId) {
            this.handelCompetencyApiResponse(responseJson);
        } else if (apiRequestCallId === this.getIndustryListApiCallId) {
            this.handelIndustryApiResponse(responseJson);
        } else if (apiRequestCallId === this.getOnlineToolsListApiCallId) {
            this.handelOnlineToolsApiResponse(responseJson);
        } else if (apiRequestCallId === this.cloneQuestionApiCallId) {
            toast.success(this.t("onlineToolDialog.toast.cloneQuestion"));
            this.handleCloneQuestionApiResponse(responseJson);
        }
    }

    handleResponseJson = (responseJson: any) => {
        const data = responseJson.map((item: any) => {
            let correct: any[] = [];
            let correctAnswer: any[] = [];

            const options = item.attributes.answers.data.map((option: any, index: number) => {
                if (option.attributes.correct_answer) {
                    correct.push(index);
                    correctAnswer.push(option.attributes.answer ? option.attributes.answer : option.attributes.image_url.url);
                }
                return {
                    id: option.id,
                    answer: option.attributes.answer,
                    image_url: option.attributes.image_url?.url,
                    type: option.attributes.image_url?.type,
                    correct_answer: option.attributes.correct_answer,
                    FileName:option.attributes.image_url?.url !== null ? option.attributes.image_url?.url : ""
                }
            });

            return {
                id: item.id,
                question: item.attributes.name,
                questionImageURL: item.attributes.image_url?.url,
                type: item.attributes.image_url?.type,
                competency: item.attributes.competancy.name,
                industry: item.attributes.industry.industry_name,
                industryId: item.attributes.industry_id?.toString(),
                competencyId: item.attributes.competancy_id?.toString(),
                level: item.attributes.level,
                mark: item.attributes.mark,
                answers: options,
                onlineToolId: item.attributes.online_tool_id?.toString(),
                correctOption: correct,
                correctAnswer: correctAnswer,
            }
        });

        return data;
    }

    handelTableDataApiResponse = (responseJson: any) => {
        const data = this.handleResponseJson(responseJson.data);

        this.setState({ tableData: data, filterData: data, loading: false },
            () => { this.onChangeHandler(this.state.search, 0) }
        );
    }

    handleCloneQuestionApiResponse = (responseJson: any) => {
        const data = this.handleResponseJson([responseJson.data]);
        let tableData = [...this.state.tableData];
        const index = tableData.findIndex((item: any) => item.id === this.state.item.id);
        tableData.splice(index + 1, 0, data[0]);
        this.setState({ tableData, filterData: tableData, openDialogName: "Clone", item: data[0], loading: false },
            () => { this.onChangeHandler(this.state.search, this.state.page) }
        );
    }

    handelCompetencyApiResponse = (responseJson: any) => {
        const competencyList = responseJson.data.map((item: any) => {
            return {
                label: item.attributes.name,
                value: item.id,
            }
        });

        this.setState({ competencyList });
    }

    handelIndustryApiResponse = (responseJson: any) => {
        const industryList = responseJson.data.map((item: any) => {
            return {
                label: item.attributes.industry_name,
                value: item.id,
            }
        });

        this.setState({ industryList });
    }

    handelOnlineToolsApiResponse = (responseJson: any) => {
        const onlineToolList = responseJson.data.map((item: any) => {
            return {
                value: item.id,
                label: item.attributes.name,
                image: item.attributes.image,
                description: item.attributes.description,
                ToolColor: item.attributes.tool_color
            }
        });
        if (this.state.tableData.length > 0) {
            this.setState({ loading: false });
        }
        this.setState({ onlineToolList });
    }

    redirectToAccessRepository = () => {
        this.props.history.push({
            pathname: "/AccessRepository",
            state: { onlineToolList: this.state.onlineToolList }
        });
    }

    onChangeDialog = (name: string | null, obj: any) => {
        if (name === "AddEditTool") {
            this.setState({ openDialogName: name },
                () => this.getOnlineToolsList()
            );
        } else if (name === "Add") {
            const data = this.handleResponseJson([obj]);
            let tableData = [...data, ...this.state.tableData];
            this.setState({ tableData, filterData: tableData, item: null, openDialogName: "" },
                () => { this.onChangeHandler(this.state.search, 0) }
            );
        } else if (name === "Edit") {
            const data = this.handleResponseJson([obj]);
            let tableData = [...this.state.tableData];
            tableData = tableData.map((item: any) => {
                if (item.id === this.state.item.id) {
                    return data[0];
                } else {
                    return item;
                }
            });
            this.setState({ tableData, filterData: tableData, item: null, openDialogName: "" },
                () => { this.onChangeHandler(this.state.search, this.state.page) }
            );
        } else if (name === "Delete") {
            let tableData = [...this.state.tableData];
            tableData = tableData.filter((item: any) => item.id !== this.state.item.id);
            this.setState({ tableData, filterData: tableData, item: null, openDialogName: "" },
                () => { this.onChangeHandler(this.state.search, this.state.page); }
            );
        } else if (name !== null) {
            this.setState({ openDialogName: name, item: null },
                () => this.getTableDetail()
            );
        } else {
            this.setState({ openDialogName: name, item: null });
        }
    }

    onApplyButtonClick = () => {
        let { tableData, sortBy, option } = this.state;
        let filterData = tableData;
        if(sortBy === "all" && option === ""){
            toast.warn(this.t("onlineToolDialog.toast.Selectoption"))
        }
        else if(sortBy !== "all" && option == ""){
            toast.warn(this.t("onlineToolDialog.toast.Selectoption",
            {data: sortBy.charAt(0).toUpperCase() + sortBy.slice(1)}));
        }
        if (sortBy !== "all") {
            if (sortBy && option) {
                filterData = tableData.filter((item: any) => item[sortBy] === option);
            }
        }

        this.setState({ filterData }, () => {
            this.onChangeHandler(this.state.search, 0);
        });
    }

    onsortByValueChange = (sortBy: any) => {
        let optionList: any[] = this.state.tableData;
        if(sortBy === null){
            this.setState({
                filterData: this.state.tableData,
                sortBy:"",
                option:""
            },()=>{
                this.onChangeHandler(this.state.search, 0);
            })
        }
        if(sortBy !== null){
            if (sortBy.value === "all") {
                optionList = [];
            } else if (sortBy.value === "competency") {
                optionList = this.state.competencyList;
            } else if (sortBy.value === "industry") {
                optionList = this.state.industryList;
            } else if (sortBy.value === "level") {
                optionList = levelList;
            }
        }else{
            optionList = []
        }
        this.setState({ sortBy: sortBy !== null ? sortBy.value : "", optionList, option: "" });
    }

    onChangeHandler = (search: string, page: number) => {
        const { filterData, rowsPerPage } = this.state;

        let data = filterData.filter((item: any) => {
            if (search === "") {
                return true;
            } else if (
                item.question.toString().toLowerCase().includes(search.toLowerCase()) ||
                item.competency.toString().toLowerCase().includes(search.toLowerCase()) ||
                item.industry.toString().toLowerCase().includes(search.toLowerCase()) ||
                item.level.toString().toLowerCase().includes(search.toLowerCase())
            ) {
                return item;
            }
        });

        const dataLength = data.length;
        let totalPage = Math.ceil(dataLength / this.state.rowsPerPage);
        page = totalPage === page ? page - 1 : page;
        data = data.slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage
        );
        this.setState({ data, dataLength, page, search });
    }

    getTableDetail = () => {
        this.setState({ loading: true });

        const header = {
            "Content-Type": configJSON.tableDetailContentType,
            token: localStorage.getItem("accesToken")
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getTableDataApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.tableDetailApiEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.tableDetailApiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }

    getIndustryList = () => {
        this.setState({ loading: true });

        const header = {
            "Content-Type": configJSON.industryListApiContentType,
            token: localStorage.getItem("accesToken")
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getIndustryListApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.industryListApiEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.industryListApiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }

    getCompetencyList = () => {
        this.setState({ loading: true });

        const header = {
            "Content-Type": configJSON.competencyListApiContentType,
            token: localStorage.getItem("accesToken")
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getCompetencyListApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.competencyListApiEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.competencyListApiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }

    getOnlineToolsList = () => {
        this.setState({ loading: true });

        const header = {
            "Content-Type": configJSON.onlineToolsListApiContentType,
            token: localStorage.getItem("accesToken")
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getOnlineToolsListApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.onlineToolsListApiEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.onlineToolsListApiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }

    cloneQuestion = async (id: any) => {
        this.setState({ loading: true, item: { id } });

        const header = {
            "Content-Type": configJSON.cloneQuestionApiContentType,
            token: localStorage.getItem("accesToken")
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.cloneQuestionApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.cloneQuestionApiEndPoint}?id=${id}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.cloneQuestionApiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }
}

// Customizable Area End
