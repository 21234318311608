// Customizable Area Start
import React from "react";
import {
  Tooltip,
  IconButton,
  Button,
  Box,
  MenuItem,
  Typography,
  Menu,
  AppBar,
  Toolbar,
  Container,
  Divider,
  Modal,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Notification from "./Notification.web";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import "./style.css";
const { baseURL } = require("../../framework/src/config")
import { logo, shrm, help, close } from "./assets";
// @ts-ignore
import shrmLogo from '../assets/shrm.png'

interface ManagerDataProps {
  full_name: string
  email: string
  phone_number?: string
}

interface coManagerDataProps {
  full_name: string
  email: string
  phone_number?: string
}

export default function AppHeader({ type }: any) {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const [openMobileMenu, setOpenMobileMenu] = React.useState(false);
  const [helpModal, setOpenHelpModal] = React.useState(false);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
  const [openAccordion, setOpenAccordion] = React.useState(false);
  const [userInfo, setUserInfo] = React.useState("");
  const [logoutModal, OpenLogoutModal] = React.useState(false);
  const [clientLogo, setClientLogo] = React.useState('')
  const [coManager, setCoManager] = React.useState<coManagerDataProps>({
    full_name: "",
    email: "",
    phone_number: ""
  })
  const [manager, setManager] = React.useState<ManagerDataProps>({
    full_name: "",
    email: "",
    phone_number: ""
  });

 const { t } = useTranslation();
 const pages = [t("AppHeader.help.btn")];
 const settings = [t("AppHeader.logout.btn")];
 
  const handleLogoutModal = () => {
    OpenLogoutModal(true)
    setAnchorElUser(null);
  }

  const handleLogoutModalClose = () => {
    OpenLogoutModal(false)
  }

  const logoutUser = () => {
    Object.keys(localStorage).forEach((item: any) => {
      if (item !== "language") {
        localStorage.removeItem(item);
      }
    })
    window.location.replace('/login')
  }

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setOpenMobileMenu(!openMobileMenu);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const openHelpModal = () => {
    setOpenHelpModal(true);
    getManagerData();
    if (openMobileMenu) {
      setOpenMobileMenu(false);
    }
  };

  const getManagerData = () => {
    const token = localStorage.getItem("accesToken")

    const requestOptions = {
      method: 'GET',
      headers: {
        "token": `${token}`
      },
    };
    fetch(`${baseURL}/account_block/help_section`, requestOptions)
      .then(response => {
        if (response.status === 200) {
          return response.json()
        }
        else {
          throw new Error("Something Went Wrong")
        }
      }
      )
      .then(res => {
        if (res) {
          console.log("res", res);
          setManager(res.data.attributes.manager);
          setCoManager(res.data.attributes.co_manager);
        }
      }).catch((err) => {
        console.log(err)
      })
  };


  const handleHelpModalClose = () => {
    setOpenHelpModal(false);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleOpenUserAccordion = () => {
    setOpenAccordion(!openAccordion);
  };

  React.useEffect(() => {
    if (openMobileMenu) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [openMobileMenu]);

  React.useEffect(() => {
    // @ts-ignore
    const logoUrl = localStorage.getItem("logo");
    const userInfo = localStorage.getItem('user')
    if (userInfo) {
      setUserInfo(userInfo)
    }
    if (logoUrl) {
      setClientLogo(logoUrl)
    }
  }, [])

  const locaation = window.location.pathname
  const HandleNavigate = () =>{
    if(locaation !== '/Test' && locaation !== "/Dashboard" && locaation !== "/ClientsDashboard"){
      window.location.replace('/Dashboard')
    }
  }

  const handleLogo = () => {
    return (<img src={clientLogo ? clientLogo : logo} onClick={HandleNavigate} style={{ cursor: "pointer"}}  className="companylogoImage"/>)
  }

  const renderIconButton = () => {
    return <IconButton
      size="medium"
      aria-label="account of current user"
      aria-controls="menu-appbar"
      aria-haspopup="true"
      onClick={handleOpenNavMenu}
      color="inherit"
      className="hamburger"
    >
      {openMobileMenu ? (
        <div className="bar animate" />
      ) : (
        <div className="bar" />
      )}
    </IconButton>
  }

  return (
    <AppBar position="static" className="header">
      <Container maxWidth="xl" className="container">
        <Toolbar disableGutters>
          {handleLogo()}

          <Box
            sx={{
              flexGrow: 1,
              justifyContent: "end",
              padding: "0px",
            }}
            className="headerBox"
          >
            {renderIconButton}
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
            >
              {pages.map((page) => (
                <MenuItem key={page} onClick={handleCloseNavMenu}>
                  <Typography align="center">{page}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Box
            className={`${openMobileMenu ? "mobile-nav show" : "mobile-nav hide"
              }`}
          >
            <IconButton
              onClick={handleOpenUserAccordion}
              className="userProfile"
            >
              <span className="userName">{userInfo ? userInfo : "User"}</span>
              <KeyboardArrowDownIcon
                className="downArrowIcon"
                style={{
                  transform: `${openAccordion ? "rotate(180deg)" : "rotate(0deg)"
                    }`,
                }}
              />
            </IconButton>
            {openAccordion &&
              settings.map((setting) => (
                <MenuItem key={setting} onClick={handleCloseUserMenu}>
                  <Typography align="center" className="accordionList" onClick={handleLogoutModal}>
                    {setting}
                  </Typography>
                </MenuItem>
              ))}
            {type !== "client" &&
              <>
                <Divider className="divider" />
                <Button onClick={openHelpModal} className="helpButton">
                  <span className="helpPage">{t("AppHeader.help.btn")} </span>{" "}
                  <HelpOutlineIcon className="HelpOutlineIcon" />{" "}
                </Button>
              </>
            }

          </Box>

          <Box
            sx={{
              flexGrow: 1,
              justifyContent: "end",
            }}
            className="responsiveHeaderBox"
          >
            <Notification />
            <Box display={{ xs: "none", md: "flex" }}>
              <Divider
                light
                orientation="vertical"
                flexItem
                className="divider"
              />
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} className="userProfile">
                  <span className="helpPage">{userInfo ? userInfo : "User"}</span>
                  <KeyboardArrowDownIcon className="downArrowIcon" />{" "}
                </IconButton>
              </Tooltip>
              <Menu
                style={{ marginTop: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {settings.map((setting) => (
                  <MenuItem key={setting} onClick={handleLogoutModal}>
                    <Typography align="center">{setting}</Typography>
                  </MenuItem>
                ))}
              </Menu>
              {type !== "client" &&
                <>
                  <Divider
                    light
                    orientation="vertical"
                    flexItem
                    className="divider"
                  />
                  <Button onClick={openHelpModal} className="helpButton">
                    <HelpOutlineIcon className="HelpOutlineIcon" />{" "}
                  <span className="helpPage">{t("AppHeader.help.btn")} </span>
                  </Button>
                </>
              }
              {logoutModal && (
                <Modal
                  open={logoutModal}
                  onClose={handleLogoutModalClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box className="modalStyle logoutModal">
                    <img
                      src={close}
                      className="closeImg"
                      onClick={handleLogoutModalClose}
                    />
                    <Typography
                      className="logoutModalTitle helpModalTitle centerText"
                      variant="h6"
                      component="h2"
                    >
                  {t("AppHeader.logout.header")}
                    </Typography>
                    <Typography
                      className="centerText"
                      variant="h6"
                      component="h4"
                    >
                     {t("AppHeader.logout.text")}
                    </Typography>
                    <div className="centerText">
                    <Button className="cancelBtn" onClick={handleLogoutModalClose}>{t("AppHeader.logout.cancel")}</Button>
                    <Button className="submitBtn" onClick={logoutUser}>{t("AppHeader.logout.yes")}</Button>
                    </div>
                  </Box>
                </Modal>
              )}
              {helpModal && (
                <Modal
                  open={helpModal}
                  onClose={handleHelpModalClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box className="modalStyle">
                    <img
                      src={close}
                      className="closeImg"
                      onClick={handleHelpModalClose}
                    />
                    <div className="imageWrapper">
                      <img src={help} className="helpModal" />
                    </div>
                    <Typography
                      className="helpModalTitle"
                      variant="h6"
                      component="h2"
                    >
                   {t("AppHeader.help.header")}
                    </Typography>
                    <div className="parentAligned">
                    <div>{t("AppHeader.help.manager")}</div>
                      <div>{manager.full_name ? manager.full_name : "---"}</div>
                    </div>
                    <Divider className="divider" />
                    <div className="parentAligned">
                    <div>{t("AppHeader.help.managerPhone")} </div>
                      <div>{manager.phone_number ? manager.phone_number : '---'}</div>
                    </div>
                    <Divider className="divider" />
                    <div className="parentAligned">
                    <div>{t("AppHeader.help.managerEmail")}</div>
                      <div>{manager.email ? manager.email : '---'}</div>
                    </div>

                    {/* Co dc Manager */}
                    <div className="parentAligned">
                    <div>{t("AppHeader.help.coManager")}</div>
                      <div>{coManager.full_name ? coManager.full_name : "---"}</div>
                    </div>
                    <Divider className="divider" />
                    <div className="parentAligned">
                    <div>{t("AppHeader.help.managerPhone")}</div>
                      <div>{coManager.phone_number ? coManager.phone_number : '---'}</div>
                    </div>
                    <Divider className="divider" />
                    <div className="parentAligned">
                    <div>{t("AppHeader.help.managerEmail")}</div>
                      <div>{coManager.email ? coManager.email : '---'}</div>
                    </div>
                  </Box>
                </Modal>
              )}
              <Divider
                light
                orientation="vertical"
                flexItem
                className="divider"
              />
              <img src={shrmLogo} className={"logoImage"}  />
            </Box>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
// Customizable Area End
