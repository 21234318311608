import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  t: any;
  i18n: any;
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  fields: any;
  maxDate: string;
  allCountry: any[];
  allState: any[];
  DropdownOptions: any;
  username: string;
  message: string;
  open: boolean;
  authflag: number;
  firstName: string;
  lastName: string;
  email: string;
  isLoading: boolean;
  forgetPassword: any;
  account: any;
  errors: any;
  password: string;
  otpAuthToken: string;
  reTypePassword: string;
  data: any[];
  passwordHelperText: string;
  lang: any;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  countryCodeSelected: string;
  phone: string;
  DesignationState:any;
  TypeOfEmploymentState:any;
  current_organistion_functionState:any;
  organistion_sectorState:any;
  OrganizationIndustryState:any;
  clientLogoURL: any;
  selected: any;
  copyright: string;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountRegistrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  arrayholder: any[];
  passwordReg: RegExp;
  emailReg: RegExp;
  createAccountApiCallId: any;
  validationApiCallId: string = "";

  imgPasswordVisible: any;
  imgPasswordInVisible: any;

  labelHeader: any;
  labelFirstName: string;
  lastName: string;
  labelEmail: string;
  labelPassword: string;
  labelRePassword: string;
  labelLegalText: string;
  labelLegalTermCondition: string;
  labelLegalPrivacyPolicy: string;
  btnTextSignUp: string;
  currentCountryCode: any;
  getHeaderAPICall: string = "";
  GetSectors: any = "";
  OrgFunctions: any = "";
  OrgDesignations: any ="";
  EmploymentTypes: any ="";
  OrganizationIndustry: any = ""
  AlredyLoginApi: any ='';
  getClientLogoAPICall: any= '';
  t: any;

  getCopyrightApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
  
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);
    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);
    
    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      allCountry: [],
      allState: [],
      email: "",
      password: "",
      maxDate: "03/18/2019",
      username: "",
      message: "",
      authflag: 1,
      open: false,
      firstName: "",
      lastName: "",
      account: { username: "", password: "" },
      forgetPassword: { email: "", error: {} },
      errors: {},
      isLoading: false,
      reTypePassword: "",
      otpAuthToken: "",
      data: [],
      lang: "Eng",
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      countryCodeSelected: "",
      selected: localStorage.getItem("language") ?? "en",
      phone: "",
      fields: {
        first_name: "",
        last_name: "",
        company_name: "",
        gender: "",
        date_of_birth: "",
        country: "",
        state: "",
        city: "",
        highest_eduction: "",
        professional_certification: "",
        employment_type_id: "",
        org_designation_id: "",
        org_function_id: "",
        sector_id: "",
        industry_id: "",
        organistion_work_experience: "",
        overall_work_experience: ""
      },
      DropdownOptions: {
        Gender: [
          { name: "Male", value: "Male" },
          { name: "Female", value: "Female" },
          { name: "Other", value: "Other" },
        ],
        TypeOfEmployment: [
          { name: "Full-Time", value: "fulltime" },
          { name: "Part-Time", value: "parttime" },
          { name: "Contract", value: "contract" }
        ],
        Designation: [
          {
            name: "Senior middle entry trainee",
            value: "senior_middle_entry_trainee"
          },
          { name: "Middle", value: "middle" },
          { name: "Junior", value: "junior" },
          { name: "Trainee", value: "trainee" }
        ],
        organistion_sector: [
          { name: "Public", value: "public" },
          { name: "Private", value: "private" },
          { name: "Non-Profit", value: "non-profit" },
          { name: "Col", value: "col" },
          { name: "Global Org. MNC", value: "Globel-Org-MNC" }
        ],
        current_organistion_function: [
          { name: "Marketing", value: "marketing" },
          { name: "Finance", value: "finance" },
          { name: "Sales", value: "sales" }
        ],
        organistion_industry: [
          { name: "Technology", value: "technology" },
          { name: "Manufacturing", value: "manufacturing" },
          { name: "BFSI", value: "BFSI" },
          {
            name: "Standard classification ",
            value: "Standard classification "
          },
        ],
        OverallWorkExp: [
          { name: "1 years", value: "0-1 years" },
          { name: "3 years", value: "1-3 years" },
          { name: "5 years", value: "3-5 years" }
         ]
      },
      DesignationState:[],
      TypeOfEmploymentState:[],
      current_organistion_functionState:[],
      organistion_sectorState:[],
      OrganizationIndustryState:[],
      clientLogoURL: "",
      copyright: `© ${new Date().getFullYear()} SHRM India. All Rights Reserved.`
      // Customizable Area End
    };

    // Customizable Area Start
    this.t = this.props.t;

    this.arrayholder = [];
    this.passwordReg = /\w+/
    this.emailReg = /\w+/;

    this.imgPasswordVisible = imgPasswordVisible;
    this.imgPasswordInVisible = imgPasswordInVisible;

    this.labelHeader = configJSON.labelHeader;
    this.labelFirstName = configJSON.labelFirstName;
    this.lastName = configJSON.lastName;
    this.labelEmail = configJSON.labelEmail;
    this.labelPassword = configJSON.labelPassword;
    this.labelRePassword = configJSON.labelRePassword;
    this.labelLegalText = configJSON.labelLegalText;
    this.labelLegalTermCondition = configJSON.labelLegalTermCondition;
    this.labelLegalPrivacyPolicy = configJSON.labelLegalPrivacyPolicy;
    this.btnTextSignUp = configJSON.btnTextSignUp;
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      this.GetHeaderandAndGetCopyrightApiCall(apiRequestCallId,responseJson,errorReponse)
      
    }
    

    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const otpAuthTkn = message.getData(
        getName(MessageEnum.AuthTokenDataMessage)
      );
      if (otpAuthTkn && otpAuthTkn.length > 0) {
        this.setState({ otpAuthToken: otpAuthTkn });
        runEngine.debugLog("otpAuthTkn", this.state.otpAuthToken);
        runEngine.unSubscribeFromMessages(this as IBlock, [message.id]);
      }
    }

    if (getName(MessageEnum.CountryCodeMessage) === message.id) {
      const selectedCode = message.getData(
        getName(MessageEnum.CountyCodeDataMessage)
      );

      if (selectedCode !== undefined) {
        this.setState({
          countryCodeSelected:
            selectedCode.indexOf("+") > 0
              ? selectedCode.split("+")[1]
              : selectedCode,
        });
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    this.getClientLogo();
    this.getCopyright();
  }

  GetHeaderandAndGetCopyrightApiCall = (apiRequestCallId:any,responseJson:any,errorReponse:any) =>{
    if (apiRequestCallId === this.getHeaderAPICall) {
      if (!responseJson.error) {
        localStorage.setItem("user", responseJson.name);
        //@ts-ignore
        this.props.history.replace("Welcome");
      }
    } else if (apiRequestCallId === this.getCopyrightApiCallId) {
      if(Array.isArray(responseJson)){
        this.setState({ copyright: responseJson[0].copyright });
      }
    }
    this.checkResponse(apiRequestCallId, responseJson, errorReponse)
  }
 
  checkValidation = (responseJson:any)=>{
    this.arrayholder = responseJson.data;

        if (this.arrayholder && this.arrayholder.length !== 0) {
          let regexData = this.arrayholder[0];

          if (regexData.password_validation_regexp) {
            this.passwordReg = new RegExp(
              regexData.password_validation_regexp
            );
          }

          if (regexData.password_validation_rules) {
            this.setState({
              passwordHelperText: regexData.password_validation_rules,
            });
          }

          if (regexData.email_validation_regexp) {
            this.emailReg = new RegExp(regexData.email_validation_regexp);
          }
        }
  }

  checkResponse = (apiRequestCallId:any, responseJson:any, errorReponse:any) =>{
    if (apiRequestCallId && responseJson) {
      if (apiRequestCallId === this.validationApiCallId) {
        this.checkValidation(responseJson)
      } else if (apiRequestCallId === this.createAccountApiCallId) {
        if (!responseJson.errors) {
          const msg: Message = new Message(
            getName(MessageEnum.AccoutResgistrationSuccess)
          );

          msg.addData(
            getName(MessageEnum.NavigationPropsMessage),
            this.props
          );

          this.send(msg);
        } else {
          this.parseApiErrorResponse(responseJson);
        }

        this.parseApiCatchErrorResponse(errorReponse);
      } 
      else if(apiRequestCallId === this.GetSectors){
        this.setState({
          ...this.state, organistion_sectorState:responseJson.data
        })
      } 
      this.checkResponseReduceComplexity(apiRequestCallId,responseJson)
    }
  }

  checkResponseReduceComplexity = (apiRequestCallId:any, responseJson:any) =>{
    if(apiRequestCallId === this.OrgFunctions){
        this.setState({
          ...this.state, current_organistion_functionState:responseJson.data
        })
      } 
      else if(apiRequestCallId === this.OrgDesignations){
        this.setState({
          ...this.state, DesignationState:responseJson.data
        })
      } 
      else if(apiRequestCallId === this.EmploymentTypes){
        this.setState({
          ...this.state, TypeOfEmploymentState:responseJson.data
        });
      }
      else if(apiRequestCallId === this.OrganizationIndustry){
        console.log(responseJson,"responseJson OrganizationIndustry")
        this.setState({
          ...this.state, OrganizationIndustryState:responseJson.data
        })
      }
      else if (apiRequestCallId === this.getClientLogoAPICall){
        this.setState({ clientLogoURL: responseJson.logo_url})
      }
      else if(apiRequestCallId === this.AlredyLoginApi){
        if(responseJson.data.attributes.gender !== null){
          this.props.navigation.navigate("WelcomeParticipant")
        } else {
          this.props.navigation.navigate("BasicInformation")
        }
      }
  }

  getCopyright = () => {
    const header = {
      "Content-Type": "application/json",
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCopyrightApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/bx_block_admin/footer_copyrights"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  goToTermsAndCondition() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationTermAndConditionMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToPrivacyPolicy() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationPrivacyPolicyMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  isValidEmail(email: string) {
    return this.emailReg.test(email);
  }

  createAccount(): boolean {
    if (
      this.isStringNullOrBlank(this.state.firstName) ||
      this.isStringNullOrBlank(this.state.lastName) ||
      this.isStringNullOrBlank(this.state.email) ||
      this.isStringNullOrBlank(this.state.password) ||
      this.isStringNullOrBlank(this.state.reTypePassword)
    ) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory
      );
      return false;
    }

    let phoneNumberError = this.validateCountryCodeAndPhoneNumber(
      this.state.countryCodeSelected,
      this.state.phone
    );

    if (phoneNumberError) {
      this.showAlert(configJSON.errorTitle, phoneNumberError);
      return false;
    }

    if (!this.isValidEmail(this.state.email)) {
      this.showAlert(configJSON.errorTitle, configJSON.errorEmailNotValid);
      return false;
    }

    if (!this.passwordReg.test(this.state.password)) {
      this.showAlert(configJSON.errorTitle, configJSON.errorPasswordNotValid);
      return false;
    }

    if (this.state.password !== this.state.reTypePassword) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorBothPasswordsNotSame
      );
      return false;
    }

    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
    };

    const attrs = {
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      email: this.state.email,
      password: this.state.password,
      full_phone_number: "+" + this.state.countryCodeSelected + this.state.phone,
    };

    const data = {
      type: "email_account",
      attributes: attrs,
    };

    const httpBody = {
      data: data,
      token: this.state.otpAuthToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createAccountApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountsAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getHeaderData(): boolean {
    const header = {
      token: localStorage.getItem("accesToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getHeaderAPICall = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointApiGetHeaderData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  getClientLogo():boolean {
    let id = window.location.href.split("client_id=").pop()
    const header = {}

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getClientLogoAPICall = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointClientLogo + `${id}/client_information`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  getValidations() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  isNonNullAndEmpty(value: string) {
    return (
      value !== undefined &&
      value !== null &&
      value !== "null" &&
      value.trim().length > 0
    );
  }

  imgEnableRePasswordFieldProps = {
    source: imgPasswordVisible,
  };

  validateCountryCodeAndPhoneNumber(countryCode: string, phoneNumber: string) {
    let error = null;

    if (this.isNonNullAndEmpty(phoneNumber)) {
      if (!this.isNonNullAndEmpty(String(countryCode))) {
        error = configJSON.errorCountryCodeNotSelected;
      }
    } else if (this.isNonNullAndEmpty(countryCode)) {
      if (!this.isNonNullAndEmpty(phoneNumber)) {
        error = "Phone " + configJSON.errorBlankField;
      }
    }

    return error;
  }

  btnConfirmPasswordShowHideProps = {
    onPress: () => {
      this.setState({
        enableReTypePasswordField: !this.state.enableReTypePasswordField,
      });
      this.txtInputConfirmPasswordProps.secureTextEntry = !this.state
        .enableReTypePasswordField;
      this.imgEnableRePasswordFieldProps.source = this
        .txtInputConfirmPasswordProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  imgEnablePasswordFieldProps = {
    source: imgPasswordVisible,
  };

  btnPasswordShowHideProps = {
    onPress: () => {
      this.setState({ enablePasswordField: !this.state.enablePasswordField });
      this.txtInputPasswordProps.secureTextEntry = !this.state
        .enablePasswordField;
      this.imgEnablePasswordFieldProps.source = this.txtInputPasswordProps
        .secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnSignUpProps = {
    onPress: () => this.createAccount(),
  };

  btnLegalPrivacyPolicyProps = {
    onPress: () => this.goToPrivacyPolicy(),
  };

  btnLegalTermsAndConditionProps = {
    onPress: () => this.goToTermsAndCondition(),
  };

  txtInputEmailWebPrpos = {
    onChangeText: (text: string) => {
      this.setState({ email: text });
      //@ts-ignore
      this.txtInputEmailPrpos.value = text;
    },
  };

  txtInputEmailMobilePrpos = {
    ...this.txtInputEmailWebPrpos,
    keyboardType: "email-address",
  };

  txtInputEmailPrpos = this.isPlatformWeb()
    ? this.txtInputEmailWebPrpos
    : this.txtInputEmailMobilePrpos;

  txtPhoneNumberWebProps = {
    onChangeText: (text: string) => {
      this.setState({ phone: text });

      //@ts-ignore
      this.txtPhoneNumberProps.value = text;
    },
  };

  txtPhoneNumberMobileProps = {
    ...this.txtPhoneNumberWebProps,
    autoCompleteType: "tel",
    keyboardType: "phone-pad",
  };

  txtPhoneNumberProps = this.isPlatformWeb()
    ? this.txtPhoneNumberWebProps
    : this.txtPhoneNumberMobileProps;

  txtInputLastNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ lastName: text });

      //@ts-ignore
      this.txtInputLastNamePrpos.value = text;
    },
  };

  txtInputFirstNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ firstName: text });

      //@ts-ignore
      this.txtInputFirstNamePrpos.value = text;
    },
  };

  txtInputConfirmPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ reTypePassword: text });

      //@ts-ignore
      this.txtInputConfirmPasswordProps.value = text;
    },
    secureTextEntry: true,
  };

  txtInputPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ password: text });

      //@ts-ignore
      this.txtInputPasswordProps.value = text;
    },
    secureTextEntry: true,
  };

  GetSectorsList = () => {
    const header = {
        "Content-Type": "application/json",
        token: localStorage.getItem("accesToken")
    };

    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );

    this.GetSectors = requestMessage.messageId;
    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        '/bx_block_admin/sectors'
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
}
GetOrgFunctions = () => {
  const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("accesToken")
  };

  const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
  );

  this.OrgFunctions = requestMessage.messageId;
  requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      '/bx_block_admin/org_functions'
  );

  requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
  );

  requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
  return true;
}

GetOrgDesignations = () => {
  const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("accesToken")
  };

  const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
  );

  this.OrgDesignations = requestMessage.messageId;
  requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      '/bx_block_admin/org_designations'
  );

  requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
  );

  requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
  return true;
}
GetEmploymentTypes = () => {
  const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("accesToken")
  };

  const requestMessageNew = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
  );

  this.EmploymentTypes = requestMessageNew.messageId;
  requestMessageNew.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      '/bx_block_profile/employment_types'
  );

  requestMessageNew.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
  );

  requestMessageNew.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
  );

  runEngine.sendMessage(requestMessageNew.id, requestMessageNew);
  return true;
}

GetOrganizationIndustry = () => {
  const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("accesToken")
  };

  const NewrequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
  );

  this.OrganizationIndustry = NewrequestMessage.messageId;
  NewrequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      '/bx_block_profile/industries'
  );

  NewrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
  );

  NewrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
  );

  runEngine.sendMessage(NewrequestMessage.id, NewrequestMessage);
  return true;
}

AlredyLogin = () => {
  const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("accesToken")
  };

  const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
  );

  this.AlredyLoginApi = requestMessage.messageId;
  requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      '/account_block/accounts/profile'
  );

  requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
  );

  requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
  return true;
}
  // Customizable Area End
}
