import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { toast } from "react-toastify";
import moment from "moment";
import React from "react";
const { baseURL } = require("../../../framework/src/config");
import * as Yup from "yup";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  history: any;
  navigation?: any;
  id?: string;
  location: any;
  match: any;
  // Customizable Area Start
  t: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  data: any[];
  filterBy: any;
  option: any;
  tableData: any[];
  filterData: any[];
  projectName: any;
  startDate: any;
  endDate: any;
  files: any;
  tools: any;
  questions: any;
  checked: any;
  selectedQuestions: any;
  selectedTools: any;
  extended: any;
  page: number;
  dataLength: number;
  rowsPerPage: number;
  projectType: any;
  dcManager: any;
  coManager: any;
  assessor: any;
  client: any;
  finalQuestions: any;
  newProject: any;
  editProject: any;
  deleteModelText: any;
  id: any;
  search: any;
  deleteModel: any;
  projectTypeList: any[];
  loading: boolean;
  clientsList: any[];
  managerList: any[];
  coManagerList: any[];
  assessorList: any[];
  client_id: string;
  assessor_id: any[];
  manager_id: string;
  coManager_id: string;
  pdfFile: any;
  isNegative: string;
  negative_marks: any;
  DeteleId: any;
  clonning_id: any;
  clonnedFrom_id: any;
  QuetuionExpand: any;
  isExpendQue: any;
  QuestionText: any;
  selectedTool: any;
  EditedAnswers: any;
  finalQuetionForApi: any;
  CurrentEditQuetion: any;
  CloneProjectIten: any;
  menuOpen: any;
  competencyIds: any[];
  competencyId: any[];
  competencyName: any;
  openDialogName: any;
  SetTimeModel: boolean;
  editCompetencyId: any[];
  TimerForTools: any[];
  ChangeTimeOfTools: any[];
  CurrentToolName: string;
  CurrentToolIndex: number;
  isProjectClonnedAndUpdated: boolean;
  UnSelectTimerTools: any[];
  setIncrement: any;
  rating: any;
  description: any;
  rangeOneData: any[];
  rangeHalfData: any[];
  isCancelClicked: boolean;
  competencyIdToBeReplacedWhileEdit: any;
  duplicateCompetency: boolean;
  projectFinalData: any;
  SaverangeHalfData: any[];
  SaverangeOneData: any[];
  CompanySection: any[];
  CompanyModel: boolean,
  AddParentContentModel: string,
  ParentContentData: any[],
  SectionNumber: number,
  EditSectionIndex: number,
  ViewFileModel: boolean,
  FileType: string,
  ViewFileSource: any,
  dragActive: boolean,
  isfileEdit: boolean,
  AssosserHybridData: any[]
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ProjectsController extends BlockComponent<Props, S, SS> {
  getBase64 = (file: Blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  // Customizable Area Start

  Tabledata: any = "";
  competencyData: any = "";
  editCompetencyCallId: any = "";
  eventsData: any = "";
  toolsData: any = "";
  questionsData: any = "";
  projectData: any = "";
  showProject: any = "";
  cloneProject: any = "";
  param: any;
  deleteProjectData: any = "";
  getAssessorsAPICall: string = "";
  getClientsDataAPICall: string = "";
  getFilteredClientsDataAPICall: string = "";
  getManagersDataAPICall: string = "";
  getCoManagersDataAPICall: string = "";
  updateProjectData: string = "";
  editQuestionApiCallId: string = "";
  getOnlineToolsListApiCallId: string = "";
  GetLeadToolsData: string = "";
  CloneProjectItem: any;
  globalRatingsData: any = "";
  editGlobalRatingsData: any = "";
  cloneCompetency: any = "";
  deleteCompetencyApiCallId: string = "";
  checkDuplicityAPICallId: any = "";
  showCompetencyAPICallId: any = "";
  t: any;

  incrementList = [
    {
      label: "0.5",
      value: "0.5",
    },
    {
      label: "1",
      value: "1",
    },
  ];

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      data: [],
      filterBy: "",
      option: "",
      tableData: [],
      filterData: [],
      projectName: "",
      startDate: "",
      endDate: "",
      files: [],
      tools: [],
      questions: [],
      checked: false,
      selectedQuestions: [],
      selectedTools: [],
      extended: Boolean,
      page: 0,
      rowsPerPage: 20,
      dataLength: 0,
      projectType: "",
      dcManager: "",
      coManager: "",
      assessor: [],
      client: "",
      finalQuestions: [],
      newProject: {},
      editProject: {},
      id: "",
      deleteModelText: "",
      search: "",
      deleteModel: false,
      projectTypeList: [],
      loading: true,
      clientsList: [],
      managerList: [],
      coManagerList: [],
      assessorList: [],
      client_id: "",
      assessor_id: [],
      manager_id: "",
      coManager_id: "",
      pdfFile: [],
      isNegative: "No",
      negative_marks: "",
      clonning_id: "",
      DeteleId: "",
      clonnedFrom_id: "",
      QuetuionExpand: "",
      isExpendQue: "",
      QuestionText: "",
      selectedTool: "",
      EditedAnswers: [],
      finalQuetionForApi: [],
      CurrentEditQuetion: [],
      CloneProjectIten: [],
      editCompetencyId: [],
      menuOpen: false,
      competencyIds: [],
      competencyId: [],
      competencyName: "",
      openDialogName: "",
      SetTimeModel: false,
      TimerForTools: [],
      ChangeTimeOfTools: [],
      CurrentToolName: "",
      CurrentToolIndex: 0,
      isProjectClonnedAndUpdated: false,
      UnSelectTimerTools: [],
      setIncrement: "1",
      rating: "1",
      description: "",
      rangeOneData: [],
      rangeHalfData: [],
      isCancelClicked: false,
      competencyIdToBeReplacedWhileEdit: "",
      duplicateCompetency: false,
      projectFinalData: null,
      SaverangeHalfData: [],
      SaverangeOneData: [],
      CompanySection: [],
      CompanyModel: false,
      ParentContentData: [],
      AddParentContentModel: "",
      SectionNumber: 0,
      EditSectionIndex: -1,
      ViewFileModel: false,
      FileType: "",
      ViewFileSource: [],
      dragActive: false,
      isfileEdit: false,
      AssosserHybridData: []
    };
    this.t = this.props.t;

    this.param = this.props.navigation.getParam("id");

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  // Customizable Area Start
  async componentDidMount(): Promise<void> {
    this.getClientsData();
    this.getAssessorData();
    this.getCoManagersData();
    this.getManagersData();
  }
  // Customizable Area End

  // Customizable Area Start
  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      this.ErrorApiCall(apiRequestCallId, responseJson);
    }
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      this.ApiCalls(apiRequestCallId, responseJson);
      this.ApicallForTableData(apiRequestCallId, responseJson);
      this.CloneApiCallFuncation(apiRequestCallId, responseJson);
      this.DeleteAndShowProject(apiRequestCallId, responseJson);
      this.MyProjectAPicall(apiRequestCallId, responseJson);
      this.ApiCallsForTypeTools(apiRequestCallId, responseJson);
      this.competencyAPICall(apiRequestCallId, responseJson);
      this.editCompetencyAPICall(apiRequestCallId, responseJson);
      this.getGlobalRatingsCall(apiRequestCallId, responseJson);
      this.editGlobalRatingsCall(apiRequestCallId, responseJson);
      this.cloneCompetencyApiCall(apiRequestCallId, responseJson);
      this.checkDuplicityAPICall(apiRequestCallId, responseJson);
      this.showCompetencyAPICall(apiRequestCallId, responseJson)
    }
  }

  ApiCallsForTypeTools = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.GetLeadToolsData) {
      if (this.state?.projectType?.toLowerCase() === "hybrid") {
        this.setState({
          tools: this.state.tools.concat(responseJson.data),
          loading: false,
        });
      } else {
        this.setState({
          tools: responseJson.data,
          loading: false,
        });
      }
    }
    if (apiRequestCallId === this.getOnlineToolsListApiCallId) {
      this.setState({
        // tools: responseJson.data,
        loading: false,
      });
    }
  };

  toolsApicall = (value: any) => {
    if (value.toLowerCase() === "virtual") {
      this.getToolsData();
    } else if (value.toLowerCase() === "hybrid") {
      this.setState({
        tools: [],
      });
      this.getToolsData();
      this.GetLeadToolsDataApi();
    } else if (value.toLowerCase() === "assessor") {
      this.GetLeadToolsDataApi();
    }
  };

  ApicallForTableData = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.Tabledata) {
      this.setState({
        loading: true,
      });
      responseJson &&
        this.setState(
          {
            tableData: responseJson.data,
            filterData: responseJson.data,
            page: 0,
            loading: false,
          },
          () => this.onSearch(this.state.search, 0)
        );
    }
  };

  ErrorApiCall = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId && responseJson) {
      if (responseJson.status === 500) {
        toast.error(this.t("ProjectsController.SomethingWentWrong"));
      } else if (!Array.isArray(responseJson.errors) && responseJson.errors) {
        toast.error(responseJson.errors);
        this.setState({ loading: false });
      } else {
        this.handleApiResponse(apiRequestCallId, responseJson);
      }
    }
  };

  ApiCalls = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.toolsData) {
      if (this.state.projectType.toLowerCase() === "hybrid") {
        this.setState({
          tools: this.state.tools.concat(responseJson.data),
          loading: false,
        });
      } else {
        this.setState({
          tools: responseJson.data,
          loading: false,
        });
      }
    }
    this.QuestionsDataApiCall(apiRequestCallId, responseJson)
    this.SubSectionOfApicalls(apiRequestCallId, responseJson);
  };

  QuestionsDataApiCall = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.questionsData) {
      this.setState({ loading: true });
      let resposs = responseJson.data.sort((a: any, b: any) => {
        return a.attributes.online_tool.name.toLowerCase().localeCompare(b.attributes.online_tool.name.toLowerCase())
      });
      let responsedata = resposs;
      this.QuetiondataIf(responsedata)
      this.setState({
        questions: [...responsedata],
        selectedQuestions: [...responsedata],
        loading: false,
      });
    }
  }

  QuetiondataIf = (responsedata: any) => {
    if (this.props?.history?.location?.pathname?.split("/")[1] === "EditProject" || this.props?.history?.location?.pathname?.split("/")[1] === "CloneProject") {
      const QuestionForLocation = this.props?.history?.location?.state?.attributes?.questions.map(
        (item: any) => Number(item.id)
      );
      this.SortData(responsedata, QuestionForLocation)
    }
  }

  SortData = (responsedata: any, QuestionForLocation: any) => {
    let data = responsedata.sort((sortitem: any) => {
      if (QuestionForLocation?.includes(Number(sortitem.id))) return -1;
      return 0;
    }).sort((a: any, b: any) => {
      if (a.attributes.online_tool.name.toLowerCase() < b.attributes.online_tool.name.toLowerCase()) {
        return -1;
      }
      if (a.attributes.online_tool.name.toLowerCase() > b.attributes.online_tool.name.toLowerCase()) {
        return 1;
      }
      return 0;
    });
    return data
  }

  SubSectionOfApicalls = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.updateProjectData) {
      if (responseJson.status === 500) {
        toast.error(this.t("ProjectsController.SomethingWentWrong"));
      } else if (responseJson.errors && Array.isArray(responseJson.errors)) {
        toast.error(responseJson.errors);
        this.setState({ loading: false });
      } else {
        this.setState({
          editProject: responseJson.data,
          isProjectClonnedAndUpdated: false,
          clonning_id: "",
        });
        if (window.location.pathname.split("/")[1] === "CloneProject") {
          toast.success(this.t("ProjectsController.ProjectClonnedSuccessfully"));
        } else {
          toast.success(this.t("ProjectsController.ProjectUpdatedSuccessfully"));
        }
        localStorage.removeItem("ProjectCloneItem");
        this.props.history.push({
          pathname: "/myproject",
        });
      }
    }
    if (apiRequestCallId === this.editQuestionApiCallId) {
      this.setState({
        isExpendQue: false,
      });
      this.getQuestionsData();
    }
  };

  MyProjectAPicall = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.projectData) {
      if (responseJson.status === 500) {
        toast.error(this.t("ProjectsController.SomethingWentWrong"));
      } else if (responseJson.errors && Array.isArray(responseJson.errors)) {
        toast.error(responseJson.errors);
        this.setState({ loading: false });
      } else if (responseJson.message) {
        toast.warning(responseJson.message);
        this.setState({ loading: false });
      } else {
        this.setState({
          newProject: responseJson.data,
        });
        toast.success(this.t("ProjectsController.ProjectCreatedSuccessfully"));
        this.props.history.push({
          pathname: "/myProject",
        });
      }
    }
  };

  cloneCompetencyApiCall = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.cloneCompetency) {
      if (responseJson.status === 500) {
        toast.error(this.t("ProjectsController.SomethingWentWrong"));
      } else if (responseJson.errors && Array.isArray(responseJson.errors)) {
        toast.error(responseJson.errors);
        this.setState({ loading: false });
      } else if (responseJson.message) {
        toast.warning(responseJson.message);
        this.setState({ loading: false });
      } else {
        this.setState({
          editCompetencyId: responseJson.data.id,
          menuOpen: true,
          competencyName: responseJson.data.attributes.name,
          openDialogName: "Edit",
        });
      }
    }
  };

  editCompetencyAPICall = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.editCompetencyCallId) {
      if (responseJson.status === 500) {
        toast.error(this.t("ProjectsController.SomethingWentWrong"));
      } else if (responseJson.errors && Array.isArray(responseJson.errors)) {
        toast.error(responseJson.errors);
        this.setState({ loading: false });
      } else {
        const competencyIndex = this.state.competencyId?.findIndex(
          (item: any) => item === this.state.competencyIdToBeReplacedWhileEdit
        );
        if (competencyIndex > -1) {
          let newArray = [...this.state.competencyId];
          newArray.splice(competencyIndex, 1);
          newArray.push(this.state.editCompetencyId);
          this.setState({ competencyId: newArray });
        }
        const clonedCompetencyIds = this.state.competencyIds.map(
          (item: any) => {
            if (item.value === this.state.competencyIdToBeReplacedWhileEdit) {
              return {
                label: responseJson.data.attributes.name,
                value: responseJson.data.id,
              };
            } else {
              return item;
            }
          }
        );
        this.setState({
          competencyIdToBeReplacedWhileEdit: responseJson.data.id,
          competencyName: "",
          openDialogName: null,
          loading: false,
          editCompetencyId: [],
          competencyIds: clonedCompetencyIds,
          menuOpen: false,
        });
      }
    }
  };

  competencyAPICall = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.competencyData) {
      if (responseJson.status === 500) {
        toast.error(this.t("ProjectsController.SomethingWentWrong"));
      } else if (responseJson.errors && Array.isArray(responseJson.errors)) {
        toast.error(responseJson.errors);
        this.setState({ loading: false });
      } else {
        const competencyIds = responseJson.data.map((item: any) => {
          return {
            label: item.attributes.name,
            value: item.id,
          };
        });
        this.setState({ competencyIds: competencyIds, loading: false });
      }
    }
  };

  getGlobalRatingsCall = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.globalRatingsData) {
      if (responseJson.status === 500) {
        toast.error(this.t("ProjectsController.SomethingWentWrong"));
      } else if (responseJson.errors && Array.isArray(responseJson.errors)) {
        toast.error(responseJson.errors);
        this.setState({ loading: false });
      } else {
        const rangeOne = responseJson.data
          .filter((item: any) => item.attributes.range === "1")
          .map((data: any) => {
            return {
              label: data.attributes.rating_number,
              description: data.attributes.description,
              value: data.id,
              project_ratings: data.attributes.project_ratings ?? "",
            };
          })
          .sort((a: any, b: any) => ("" + a.label).localeCompare(b.label));
        const rangeHalf = responseJson.data
          .filter((item: any) => item.attributes.range === "0.5")
          .map((data: any) => {
            return {
              label: data.attributes.rating_number,
              value: data.id,
              project_ratings: data.attributes.project_ratings ?? "",
              description: data.attributes.description,
            };
          })
          .sort((a: any, b: any) => ("" + a.label).localeCompare(b.label));
        this.setState({
          rangeHalfData: rangeHalf,
          rangeOneData: rangeOne,
          rating: rangeOne[0]?.value,
          openDialogName: "scoreRating",
          loading: false,
        });
      }
    }
  };

  checkDuplicityAPICall = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.checkDuplicityAPICallId) {
      if (responseJson.status === 500) {
        toast.error(this.t("ProjectsController.SomethingWentWrong"));
      } else if (responseJson.errors && Array.isArray(responseJson.errors)) {
        toast.error(responseJson.errors);
        this.setState({ loading: false });
      } else if (responseJson.message) {
        toast.error(this.t("ProjectsController.PleaseGiveUniqueCompetencyNames"));
        this.setState({
          loading: false, menuOpen: true, duplicateCompetency: true
        });
        this.state.competencyId.forEach((id: any) => {
          this.showCompetency(id)
        })
      }
      else {
        this.setState({ duplicateCompetency: false, loading: false })
        let location = this.props?.history?.location.pathname.split("/")[1] === "CloneProject";
        if (location) {
          this.handleUpdateProject(this.state.clonning_id, this.state.projectFinalData)
        } else {
          this.createProject(this.state.projectFinalData)
        }
      }
    }
  }

  showCompetencyAPICall = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.showCompetencyAPICallId) {
      if (responseJson.status === 500) {
        toast.error(this.t("ProjectsController.SomethingWentWrong"));
      } else if (responseJson.errors && Array.isArray(responseJson.errors)) {
        toast.error(responseJson.errors);
        this.setState({ loading: false });
      }
      else {
        let deleteId = responseJson.data?.attributes.is_clone === true ? responseJson.data.id : null
        this.deleteCompetencyWithId(deleteId)
        this.getCompetencyData('/')
        this.setState({ loading: false, duplicateCompetency: false });
      }
    }
  }
  editGlobalRatingsCall = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.editGlobalRatingsData) {
      if (responseJson.status === 500) {
        toast.error(this.t("ProjectsController.SomethingWentWrong"));
      } else if (responseJson.errors && Array.isArray(responseJson.errors)) {
        this.setState({ loading: false });
        toast.error(responseJson.errors);
      } else {
        const rangeOne = responseJson.data
          .filter((item: any) => item.attributes.range === "1")
          .map((data: any) => {
            return {
              description: data.attributes.description,
              label: data.attributes.rating_number,
              value: data.id,
              project_ratings: data.attributes.project_ratings ?? "",
            };
          })
          .sort((a: any, b: any) => ("" + a.label).localeCompare(b.label));
        const rangeHalf = responseJson.data
          .filter((item: any) => item.attributes.range === "0.5")
          .map((data: any) => {
            return {
              description: data.attributes.description,
              value: data.id,
              label: data.attributes.rating_number,
              project_ratings: data.attributes.project_ratings ?? "",
            };
          })
          .sort((a: any, b: any) => ("" + a.label).localeCompare(b.label));
        this.setState({
          rangeHalfData: rangeHalf,
          rating: rangeOne[0]?.value,
          rangeOneData: rangeOne,
          loading: false,
          openDialogName: "scoreRating",
        });
      }
    }
  };

  handleProjectRatings = (event: any) => {
    let data =
      this.state.setIncrement === "0.5"
        ? this.state.rangeHalfData
        : this.state.rangeOneData;
    data = data.map((item: any) => {
      if (this.state.rating === item.value) {
        return { ...item, project_ratings: event.target.value };
      } else {
        return item;
      }
    });

    if (this.state.setIncrement === "0.5") {
      this.setState({ rangeHalfData: data });
    } else {
      this.setState({ rangeOneData: data });
    }
  };

  onSubmitRating = (event: any) => {
    event.preventDefault();
    let data =
      this.state.setIncrement === "0.5"
        ? this.state.rangeHalfData
        : this.state.rangeOneData;
    data = data.filter((item: any) => (item.project_ratings ? false : true));
    if (data.length > 0) {
      toast.warn(`${this.t("ProjectsController.PleaseAddDescriptionForRating")} ${data[0].label}`);
    } else {
      this.setState({
        openDialogName: "", isCancelClicked: false, SaverangeHalfData: this.state.rangeHalfData,
        SaverangeOneData: this.state.rangeOneData,
      });
    }
  };

  handleCancelProjectRating = () => {
    let rangeOneData = this.state.rangeOneData.map((item: any) => ({
      ...item,
      project_ratings: item.original_project_ratings,
    }));
    let rangeHalfData = this.state.rangeHalfData.map((item: any) => ({
      ...item,
      project_ratings: item.original_project_ratings,
    }));
    this.setState({ rangeHalfData, rangeOneData, openDialogName: "" });
  };

  handleRatingsDescription = () => {
    let data =
      this.state.setIncrement === "0.5"
        ? this.state.rangeHalfData
        : this.state.rangeOneData;
    data = data.filter((item: any) => {
      if (this.state.rating === item.value) {
        return true;
      } else {
        return false;
      }
    });
    return data[0] ? data[0].project_ratings : "";
  };

  CloneApiCallFuncation = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.cloneProject) {
      if (responseJson.status === 500) {
        toast.error(this.t("ProjectsController.SomethingWentWrong"));
      } else if (responseJson.errors && Array.isArray(responseJson.errors)) {
        toast.error(responseJson.errors);
        this.setState({ loading: false });
      } else if (responseJson.message) {
        toast.error(responseJson.message);
        this.setState({ loading: false });
      } else {
        let assessorIds = responseJson.data.attributes.assessor.map(
          (obj: any) => obj.id.toString()
        );
        this.setState({
          editProject: responseJson.data.attributes,
          loading: false,
          isNegative: responseJson.data.attributes.is_negative_marking
            ? "Yes"
            : "No",
          negative_marks: responseJson.data.attributes.negative_mark,
          coManager_id: responseJson.data.attributes.co_manager_id,
          client_id: responseJson.data.attributes.client_id,
          assessor_id: assessorIds,
          manager_id: responseJson.data.attributes.manager_id,
          clonning_id: responseJson.data.id,
          projectType: responseJson.data.attributes.project_type,
          competencyId: responseJson.data.attributes.competancy_ids,
        });
      }
    }
  };

  DeleteAndShowProject = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.showProject) {
      this.ShowProjectApicall(responseJson);
    }

    if (apiRequestCallId === this.deleteProjectData) {
      toast.success(`${this.t("ProjectsController.ProjectSuccesfully")}`);
      this.setState({
        deleteModel: false,
        isProjectClonnedAndUpdated: false,
        clonning_id: "",
      });
      this.getTable();
    }
  };

  ShowProjectApicall = async (responseJson: any) => {
    let assessorIds = responseJson.data.attributes.assessor.map((obj: any) =>
      obj.id.toString()
    );
    let timefortools = responseJson.data.attributes.set_time_to_online_tools?.data?.map(
      (item: any) => {
        return {
          online_tool_id: Number(item.attributes.online_tool_id),
          hour: Number(item.attributes.hour),
          min: Number(item.attributes.min),
          id: Number(item.id),
        };
      }
    );
    let changefortimer = responseJson.data.attributes.set_time_to_online_tools?.data?.map(
      (item: any) => {
        return {
          label: item.attributes?.tool_name,
          hours: item.attributes?.hour,
          min: item.attributes?.min,
          value: item.attributes?.online_tool_id,
          online_tool_id: item.attributes?.online_tool_id,
          tool_name: item.attributes?.tool_name,
          errorMin: false,
          errorHours: false,
          id: item.attributes.id,
        };
      }
    );
    let rangeHalfData = responseJson.data.attributes?.ratings?.data
      .filter((item: any) => item.attributes.range === "0.5")
      .map((data: any) => {
        return {
          description: data.attributes.description,
          label: data.attributes.rating_number,
          value: data.id, project_ratings:
            data.attributes.project_ratings != null ? data.attributes.project_ratings.description : data.attributes.project_ratings ?? "", ...(data.attributes.project_ratings != null && { project_ratings_id: data.attributes.project_ratings.id, }),
          original_project_ratings:
            data.attributes.project_ratings != null
              ? data.attributes.project_ratings.description
              : data.attributes.project_ratings ?? "",
        };
      })
      .sort((a: any, b: any) => ("" + a.label).localeCompare(b.label));
    let rangeOneData = responseJson.data.attributes.ratings?.data
      .filter((item: any) => item.attributes.range === "1")
      .map((data: any) => {
        return {
          label: data.attributes.rating_number,
          description: data.attributes.description,
          value: data.id,
          project_ratings: data.attributes.project_ratings != null ? data.attributes.project_ratings.description : data.attributes.project_ratings ?? "", ...(data.attributes.project_ratings != null && {
            project_ratings_id: data.attributes.project_ratings.id,
          }),
          original_project_ratings: data.attributes.project_ratings != null ? data.attributes.project_ratings.description : data.attributes.project_ratings ?? "",
        };
      })
      .sort((a: any, b: any) => ("" + a.label).localeCompare(b.label));
    this.GetEditData(responseJson)
    this.setState({
      editProject: responseJson.data.attributes,
      loading: false,
      isNegative: responseJson.data.attributes.is_negative_marking
        ? "Yes"
        : "No",
      negative_marks: responseJson.data.attributes.negative_mark,
      coManager_id: responseJson.data.attributes.co_manager_id,
      client_id: responseJson.data.attributes.client_id,
      assessor_id: assessorIds,
      manager_id: responseJson.data.attributes.manager_id,
      pdfFile: responseJson.data.attributes.documents?.data.map((item: any) => {
        return {
          file: item.attributes.document_url,
          name: item.attributes.name,
        };
      }),
      competencyId: responseJson.data.attributes.competancy_ids,
      TimerForTools: timefortools,
      ChangeTimeOfTools: changefortimer,
      rangeHalfData: rangeHalfData,
      rangeOneData: rangeOneData,
      setIncrement: responseJson.data.attributes.set_increament
    });
  };

  convertBlobToBase64 = (blob: any) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };

  GetEditData = async (responseJson: any) => {
    if (responseJson.data.attributes.project_content.data !== null) {
      this.setState({ loading: true })
      let urls = responseJson.data.attributes.project_content?.data?.attributes?.project_content_sections.data.map((item: any) => item.attributes.image_url.url)
      const responses = await Promise.all(urls.map((url: RequestInfo) => fetch(url)));
      const blobs = await Promise.all(responses.map((response: any) => {
        return response.blob()
      }));
      const base64Promises = blobs.map((blob: any) => this.convertBlobToBase64(blob));
      const convertedBase64Data = await Promise.all(base64Promises);
      let companySectionData = responseJson.data.attributes.project_content.data === null ? [] : responseJson.data.attributes.project_content?.data?.attributes?.project_content_sections.data.map((item: any, index: number) => {
        return {
          heading: item.attributes.heading,
          title: item.attributes.title,
          content: item.attributes.html,
          uploadFile: [{ url: convertedBase64Data[index], type: item.attributes.image_url.type }]
        }
      })
      this.setState({
        ParentContentData: responseJson?.data?.attributes?.project_content?.data === null ? [] : [{ heading: responseJson?.data?.attributes?.project_content?.data?.attributes.parent_heading, content: responseJson.data.attributes?.project_content?.data.attributes.parent_content }],
        CompanySection: companySectionData,
        loading: false
      })
    }
  }

  handelClientsApiResponse = (responseJson: any) => {
    const clientsList = responseJson.data.map((item: any) => {
      return {
        label: item.attributes.full_name,
        value: item.id,
      };
    });
    this.setState({ clientsList, loading: false });
  };

  getCoManagersData = () => {
    this.setState({
      loading: true,
    });
    const header = {
      // "Content-Type": "application/json",
      token: localStorage.getItem("accesToken"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCoManagersDataAPICall = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "account_block/co_managers"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleApiResponse = (apiRequestCallId: string, responseJson: any) => {
    if (Array.isArray(responseJson.errors)) {
      if (responseJson.errors[0].message) {
        toast.error(responseJson.errors[0].message);
      } else if (responseJson.errors[0].token) {
        toast.error(responseJson.errors[0].token);
      } else if (responseJson.errors[0].name) {
        toast.error(responseJson.errors[0].name);
      } else if (responseJson.errors[0].client_id) {
        toast.error(`${this.t("ProjectsController.Client")} ${responseJson.errors[0].client_id}`);
      } else {
        toast.error(this.t("ProjectsController.SomethingWentWrong"));
      }
      this.setState({ loading: false });
    } else if (apiRequestCallId === this.getAssessorsAPICall) {
      this.handleAssessorApiResponse(responseJson);
    } else if (apiRequestCallId === this.getClientsDataAPICall) {
      this.handelClientsApiResponse(responseJson);
    } else if (apiRequestCallId === this.getFilteredClientsDataAPICall) {
      this.handelClientsApiResponse(responseJson);
    } else if (apiRequestCallId === this.getManagersDataAPICall) {
      this.handleManagerApiResponse(responseJson);
    } else if (apiRequestCallId === this.getCoManagersDataAPICall) {
      this.handleCoManagerApiResponse(responseJson);
    } else if (apiRequestCallId === this.deleteCompetencyApiCallId) {
      this.handleDeleteCompetencyApiResponse();
    }
  };

  handleDeleteCompetencyApiResponse = () => {
    this.setState({
      competencyName: "",
      openDialogName: null,
      loading: false,
      competencyIdToBeReplacedWhileEdit: "",
    });
  };

  deleteCompetency = () => {
    this.setState({ loading: true, menuOpen: false });
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("accesToken"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteCompetencyApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_assessmenttest/competancies/${this.state.editCompetencyId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  deleteCompetencyWithId = (id: any) => {
    this.setState({ loading: true, menuOpen: false });
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("accesToken")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteCompetencyApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_assessmenttest/competancies/${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getManagersData = () => {
    this.setState({
      loading: true,
    });
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("accesToken"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getManagersDataAPICall = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/account_block/managers"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getClientsData = () => {
    this.setState({
      loading: true,
    });
    const header = {
      // "Content-Type": "application/json",
      token: localStorage.getItem("accesToken"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getClientsDataAPICall = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/account_block/clients"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getFilteredClientsData = () => {
    this.setState({
      loading: true,
    });
    const header = {
      token: localStorage.getItem("accesToken"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getFilteredClientsDataAPICall = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/account_block/clients?client=have"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleManagerApiResponse = (responseJson: any) => {
    const managerList = responseJson.data.map((item: any) => {
      return {
        label: item.attributes.full_name,
        value: item.id,
      };
    });

    this.setState({ managerList, loading: false });
  };

  handleCoManagerApiResponse = (responseJson: any) => {
    const coManagerList = responseJson.data.map((item: any) => {
      return {
        label: item.attributes.full_name,
        value: item.id,
      };
    });

    this.setState({ coManagerList, loading: false });
  };

  handleAssessorApiResponse = (responseJson: any) => {
    const assessorList = responseJson.data.map((item: any) => {
      return {
        label: item.attributes.full_name,
        value: item.id.toString(),
      };
    });

    this.setState({ assessorList, loading: false });
  };

  handleProjectType = (e: any) => {
    this.setState({ projectType: e.value });
  };
  handleDcManager = (e: any) => {
    this.setState({ dcManager: e.value });
  };
  handleCoManager = (e: any) => {
    this.setState({ coManager: e.value });
  };
  handleAssessor = (e: any) => {
    let assessor = this.state.assessor;
    assessor.push(e.value);
    this.setState({ assessor });
  };
  handleClient = (e: any) => {
    this.setState({ client: e.value });
  };

  handleFilterBy = (e: any) => {
    if (e?.value === undefined) {
      this.setState({
        filterData: this.state.tableData,
      })
    }
    this.setState({ filterBy: e?.value === undefined ? "" : e?.value, option: "" },
      () => this.onSearch(this.state.search, 0));
  };

  handleOption = (e: any) => {
    this.setState({ option: e ? e?.value : "" });
  };

  handleChange(files: any) {
    this.setState({
      files: files,
    });
  }

  handleTool = (tool: any) => {
    let timerTools = {
      label: tool.attributes?.name,
      hours: 0,
      min: "",
      value: tool.id,
      online_tool_id: tool.id,
      tool_name: tool.attributes?.name,
      errorMin: false,
      errorHours: false,
      isDestroy: false,
    };
    let newData = this.state.UnSelectTimerTools.map((item: any) => {
      if (Number(item.online_tool_id) === Number(tool.id)) {
        return {
          ...item,
          isDestroy: !item.isDestroy,
        };
      } else {
        return item;
      }
    });
    this.setState({
      UnSelectTimerTools: newData,
    });
    if (
      this.state.ChangeTimeOfTools.map((item: any) =>
        Number(item.online_tool_id)
      ).includes(Number(tool.id)) === false
    ) {
      if (tool.type === "online_tool" && tool?.attributes?.name.toLowerCase() !== "psychometric") {
        this.setState({
          ChangeTimeOfTools: this.state.ChangeTimeOfTools.concat(timerTools),
        });
      }
    } else {
      this.setState({
        ChangeTimeOfTools: this.state.ChangeTimeOfTools.filter(
          (item: any) => Number(item.online_tool_id) !== Number(tool.id)
        ),
        TimerForTools: this.state.TimerForTools.filter(
          (item: any) => Number(item.online_tool_id) !== Number(tool.id)
        ),
        finalQuetionForApi: this.state.finalQuetionForApi.filter(
          (item: any) => item.attributes.online_tool_id !== Number(tool.id)
        ),
        CurrentToolIndex: 0,
        CurrentToolName: ""
      });
      let data = this.state.TimerForTools.filter(
        (item: any) => Number(item.online_tool_id) === Number(tool.id)
      ).map((item: any) => {
        return {
          online_tool_id: Number(item.online_tool_id),
          hour: Number(item.hour),
          min: Number(item.min),
          id: item.id,
          isDestroy: true,
        };
      });
      let unseledtIds = this.state.UnSelectTimerTools.map((item: any) =>
        Number(item.online_tool_id)
      );
      if (unseledtIds.includes(Number(tool.id)) === false) {
        this.setState({
          UnSelectTimerTools: this.state.UnSelectTimerTools.concat(data),
        });
      }
    }

    let selectedTools = this.state.selectedTools;
    let index = selectedTools.map((item: any) => item.id).indexOf(tool.id);
    index === -1 ? selectedTools.push(tool) : selectedTools.splice(index, 1);
    this.setState({ selectedTools, page: 0 }, () => {
      this.handleQuestions();
    });
  };

  SetQuetionText = (items: any) => {
    this.setState({
      QuestionText: items.attributes.name,
    });
  };

  ChnageQueText = (e: any) => {
    this.setState({
      QuestionText: e?.target?.value,
    });
  };

  handleQuestions() {
    let selectedTools = this.state.selectedTools;
    let selectedQuestions = this.state.questions.filter((question: any) => {
      let present = false;
      selectedTools.forEach((tool: any) => {
        if (question.attributes.online_tool_id == tool.id) {
          present = true;
        }
      });
      return present;
    }).sort((a: any, b: any) => {
      return a.attributes.online_tool.name.toLowerCase().localeCompare(b.attributes.online_tool.name.toLowerCase())
    });
    if (this.state.selectedTools.length === 0) {
      selectedQuestions = this.state.questions;
    }
    this.setState({ selectedQuestions });
  }

  handleFinalQuestions = (questionId: number, flag: boolean) => {
    let finalQuestions = this.state.finalQuestions;
    flag
      ? finalQuestions.push(questionId)
      : finalQuestions.splice(finalQuestions.indexOf(questionId), 1);
    this.setState({ finalQuestions });
  };

  handleCheck = (event: any, items: any) => {
    if (event.target.checked === false) {
      this.setState({
        finalQuetionForApi: this.state.finalQuetionForApi.filter(
          (qu: any) => Number(items.id) !== Number(qu.id)
        ),
      });
    } else {
      this.setState({
        finalQuetionForApi: this.state.finalQuetionForApi.concat(items),
      });
    }
  };

  // Customizable Area End

  // Customizable Area Start

  getTable = () => {
    this.setState({
      loading: true,
    });
    this.getTableData();
  };

  getTools = () => {
    this.getToolsData();
  };

  handleCancel = () => {
    this.props.history.push({
      pathname: "/myproject",
    });
  };

  onSearch(search: string, page: number) {
    const { filterData, rowsPerPage } = this.state;

    let data = filterData?.filter((item: any) => {
      if (search === "") {
        return true;
      } else if (
        item.attributes.name.toLowerCase().includes(search.toLowerCase()) ||
        item.attributes.manager.full_name
          .toLowerCase()
          .includes(search.toLowerCase()) ||
        item.attributes.status.toLowerCase().includes(search.toLowerCase())
      ) {
        return item;
      }
    });

    const dataLength = data?.length;
    data = data?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    this.setState({ data, dataLength, page, search });
  }

  AcordinOpen = (items: any) => {
    this.SetQuetionText(items);
    this.setState({
      isExpendQue: this.state.isExpendQue === items.id ? false : items.id,
      EditedAnswers: items?.attributes?.answers?.data,
      CurrentEditQuetion: items,
      selectedTool: items?.attributes?.online_tool.name,
    });
  };

  AcordinOpenCancel = () => {
    this.setState({
      isExpendQue: false,
      EditedAnswers: [],
      CurrentEditQuetion: [],
      selectedTool: "",
    });
  };

  handleAnswer = (e: any, ansIndex: any) => {
    let arr = [...this.state.EditedAnswers];
    arr[ansIndex].attributes.answer = e.target.value;
    this.setState({
      EditedAnswers: arr,
    });
  };

  HandelImageChange = async (e: any, ansIndex: any, ans: any) => {
    const UploadFile = e.target.files[0]
    if (
      ans.attributes?.image_url?.type?.split("/")[0] ===
      e?.target?.files[0].type.split("/")[0]
    ) {
      let MediaType = ans.attributes.image_url.type?.includes("video")
        ? "video"
        : "image";
      const { EditedAnswers } = this.state
      const url = await this.getBase64(UploadFile)
      let arr = EditedAnswers.map((item: any, index: any) => {
        if (ansIndex === index) {
          return {
            ...item,
            attributes: {
              ...item.attributes,
              imageChange: true,
              MediaType: MediaType,
              image_url: {
                ...item.attributes.image_url,
                url: url
              }
            }
          }
        } else {
          return { ...item }
        }
      });
      this.setState({
        EditedAnswers: [...arr],
        loading: false,
      });
    } else {
      toast.warning(
        `${this.t("ProjectsController.PleaseUploadValid")} ${ans.attributes?.image_url?.type?.split("/")[0]
        }  file`
      );
    }
  };



  toolIdchnage = (e: any) => {
    this.setState({ selectedTool: e?.label });
    this.setState({
      CurrentEditQuetion: {
        ...this.state.CurrentEditQuetion,
        attributes: {
          ...this.state.CurrentEditQuetion.attributes,
          online_tool_id: e?.value,
        },
      },
    });
  };

  editQuestion = async () => {
    this.setState({ loading: true });

    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("accesToken"),
    };
    const optionArray = this.state.EditedAnswers?.map((item: any) => {
      return {
        id: item.id,
        answer: item.attributes.answer,
        img: item.attributes.imageChange
          ? { data: item.attributes.image_url.url ?? null }
          : undefined,
        correct_answer: item.attributes.correct_answer,
      };
    });
    const httpBody = {
      name: this.state?.QuestionText,
      level: this.state?.CurrentEditQuetion?.attributes?.level,
      mark: this.state?.CurrentEditQuetion?.attributes?.mark,
      online_tool_id: this.state?.CurrentEditQuetion?.attributes?.online_tool_id.toString(),
      industry_id: this.state?.CurrentEditQuetion?.attributes?.industry_id.toString(),
      competancy_id: this.state?.CurrentEditQuetion?.attributes?.competancy_id.toString(),
      answers_attributes: optionArray,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.editQuestionApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_assessmenttest/questions/${this.state?.CurrentEditQuetion?.id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getTableData = () => {
    this.setState({ loading: true });
    const header = { "Content-Type": "application/json", token: localStorage.getItem("accesToken"), };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.Tabledata = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "/bx_block_profile/projects");

    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));

    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getToolsData = () => {
    this.setState({
      loading: true,
    });
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("accesToken"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.toolsData = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/bx_block_admin/online_tools"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getQuestionsData = () => {
    this.setState({
      loading: true,
    });

    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("accesToken"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.questionsData = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/bx_block_assessmenttest/questions"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  createProject = async (values: any) => {
    let companySection = {
      parent_heading: this.state.ParentContentData[0].heading,
      parent_content: this.state.ParentContentData[0].content,
      project_content_sections_attributes:
        this.state.CompanySection.map((data: any) => {
          return {
            heading: data.heading,
            title: data.title,
            html: data.content,
            image: {
              data: data.uploadFile[0]
            }
          }
        })
    }
    let httpBody = {};
    let idsofTools = this.state.selectedTools.map((item: any) =>
      Number(item.id)
    );
    let idsOnlineTool = this.state.selectedTools
      .filter((item: any) => item.type === "online_tool")
      .map((item: any) => Number(item.id));
    let assorleadtools = this.state.selectedTools
      .filter((item: any) => item.type === "assessor_lead_tools")
      .map((item: any) => Number(item.id));
    httpBody = {
      name: values.project_name,
      project_type: values.project_type,
      manager_id: Number(values.manager_id),
      co_manager_id: Number(values.coManager_id),
      client_id: Number(values.client_id),
      start_date: values.start_date,
      end_date: values.end_date,
      ...(values.project_type === "virtual" && {
        online_tool_ids: idsofTools,
      }),
      ...(values.project_type === "assessor" && {
        assessor_tool_ids: idsofTools,
        assessor_ids: values.assessor_id,
        competancy_ids: this.state.competencyId,
      }),
      ...(values.project_type === "hybrid" && {
        assessor_tool_ids: assorleadtools,
        online_tool_ids: idsOnlineTool,
        assessor_ids: values.assessor_id,
        competancy_ids: this.state.competencyId,
      }),
      max_score: "",
      question_ids: this.state.finalQuetionForApi.map((item: any) => item.id),
      is_negative_marking: values.isNegative === "Yes" ? true : false,
      negative_mark: values.negative_marks,
      documents_attributes: values.pdfFile,
      set_time_to_online_tools_attributes: this.state.TimerForTools,
      set_increament: this.state.setIncrement,
      project_content_attributes: companySection,
      project_ratings_attributes:
        this.state.setIncrement === "0.5"
          ? this.state.SaverangeHalfData.map((item: any) => ({
            rating_id: item.value,
            description: item.project_ratings,
          }))
          : this.state.SaverangeOneData.map((item: any) => ({
            rating_id: item.value,
            description: item.project_ratings,
          })),
    };

    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("accesToken"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.projectData = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/bx_block_profile/projects"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleUpdateProject = async (id: any, values: any) => {
    let companySection = {
      parent_heading: this.state.ParentContentData[0].heading,
      parent_content: this.state.ParentContentData[0].content,
      project_content_sections_attributes:
        this.state.CompanySection.map((data: any) => {
          return {
            heading: data.heading,
            title: data.title,
            html: data.content,
            image: {
              data: data.uploadFile[0].url === undefined ? data.uploadFile[0] : data.uploadFile[0].url
            }
          }
        })
    }
    let location =
      this.props?.history?.location.pathname.split("/")[1] === "CloneProject";
    let idsOnlineTool = this.state.selectedTools
      .filter((item: any) => item.type === "online_tool")
      .map((item: any) => Number(item.id));
    let assorleadtools = this.state.selectedTools
      .filter((item: any) => item.type === "assessor_lead_tools")
      .map((item: any) => Number(item.id));

    let httpBody = {};
    let newDatapass = this.state.TimerForTools.map((item: any) => {
      return {
        hour: item.hour,
        min: item.min,
        online_tool_id: item.online_tool_id,
        ...(location === false && {
          id: item.id,
        }),
      };
    });
    let deletetimerdata = this.state.UnSelectTimerTools.map((item: any) => {
      return {
        id: item.id,
        _destroy: item.isDestroy,
      };
    });
    deletetimerdata = deletetimerdata.filter((item: any) => item._destroy);
    let projectId =
      window.location.pathname.split("/")[1] === "EditProject"
        ? window.location.pathname.split("/")[2]
        : this.state.clonning_id;
    let ratingAttributes =
      this.state.setIncrement === "0.5"
        ? this.state.rangeHalfData.map((item: any) => ({
          rating_id: item.value,
          description: item.project_ratings,
          project_id: projectId,
        }))
        : this.state.rangeOneData.map((item: any) => ({
          rating_id: item.value,
          description: item.project_ratings,
          project_id: projectId,
        }));

    httpBody = {
      name: values.project_name,
      project_type: values.project_type,
      manager_id: values.manager_id,
      co_manager_id: values.coManager_id,
      client_id: values.client_id,
      start_date: values.start_date,
      end_date: values.end_date,
      assessor_ids: values.assessor_id,
      project_content_attributes: companySection,
      max_score: "",
      ...(values.project_type === "virtual" && {
        online_tool_ids: idsOnlineTool,
      }),
      ...(values.project_type === "assessor" && {
        assessor_tool_ids: assorleadtools,
        assessor_ids: values.assessor_id,
        competancy_ids: this.state.competencyId,
        project_ratings_attributes: ratingAttributes,
        set_increament: this.state.setIncrement,
      }),
      ...(values.project_type === "hybrid" && {
        assessor_tool_ids: assorleadtools,
        online_tool_ids: idsOnlineTool,
        assessor_ids: values.assessor_id,
        competancy_ids: this.state.competencyId,
        project_ratings_attributes: ratingAttributes,
        set_increament: this.state.setIncrement,
      }),
      ...(location && {
        documents_attributes: values.pdfFile,
      }),
      question_ids: this.state.finalQuetionForApi.map((item: any) => item.id),
      is_negative_marking: values.isNegative === "Yes" ? true : false,
      negative_mark: values.negative_marks,
      ...(window.location.pathname.split("/")[1] === "CloneProject" && {
        documents_attributes: values.pdfFile,
        competancy_ids: this.state.competencyId,
      }),
      set_time_to_online_tools_attributes: [...newDatapass, ...deletetimerdata],
    };

    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("accesToken"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateProjectData = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_profile/projects/${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleEditProject = (id: any) => {
    this.setState({
      loading: true,
    });
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("accesToken"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.showProject = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_profile/projects/${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleCloneClick = (proj: any) => {
    localStorage.setItem("ProjectCloneItem", JSON.stringify(proj));
    let localProjectCloneItem = localStorage.getItem("ProjectCloneItem") as any;
    this.props.history.push({
      pathname: `/CloneProject/${proj.id}`,
      state: JSON.parse(localProjectCloneItem),
    });
    this.handleEditProject(proj.id);
  };

  handleCloneProject = (id: any) => {
    this.setState({
      loading: true,
    });
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("accesToken"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.cloneProject = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_profile/projects/clone?id=${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  DeleteProject = (deleteId: any) => {
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("accesToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteProjectData = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_profile/projects/${deleteId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getAssessorData(): boolean {
    this.setState({
      loading: true,
    });
    const header = {
      token: localStorage.getItem("accesToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAssessorsAPICall = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "account_block/assessors"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  getOnlineToolsList = () => {
    this.setState({ loading: true });

    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("accesToken"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getOnlineToolsListApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_admin/online_tools`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.onlineToolsListApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  GetLeadToolsDataApi = () => {
    this.setState({ loading: true });
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("accesToken"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.GetLeadToolsData = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_admin/assessor_lead_tools"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getCompetencyData = (params: any) => {
    this.setState({
      loading: true,
    });
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("accesToken"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.competencyData = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_assessmenttest/competancies${params}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  editCompetency = async (values: any) => {
    this.setState({ loading: true });

    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("accesToken"),
    };

    const httpBody = {
      name: values.competencyName,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.editCompetencyCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_assessmenttest/competancies/${this.state.editCompetencyId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  TimerModelOpenClose = () => {
    if (this.state.ChangeTimeOfTools.length === 0) {
      toast.warn(this.t("ProjectsController.SelectToolsFirst"));
    } else {
      this.setState({
        SetTimeModel: !this.state.SetTimeModel,
        CurrentToolIndex: 0,
        CurrentToolName: ""
      });
    }
  };

  handleScoreModal = (page: any, setIncrement: any) => {
    if (
      (page === "create" || page === "clone") &&
      (this.state.rangeHalfData.length == 0 ||
        this.state.rangeOneData.length === 0)
    ) {
      this.getGlobalRatingsData();
    } else {
      this.setState({
        openDialogName: "scoreRating",
        rating:
          setIncrement === "0.5"
            ? this.state.rangeHalfData[0]?.value
            : this.state.rangeOneData[0]?.value,
      });
    }
  };

  ChangeToolsTime = (e: any, name: any) => {
    let chnagetime = [...this.state.ChangeTimeOfTools];
    if (name === "hours") {
      if (e.target.value > 0) {
        chnagetime[this.state.CurrentToolIndex].errorHours = true;
        toast.warn(this.t("ProjectsController.Max1Hour"));
      }
      if (e.target.value <= 0) {
        chnagetime[this.state.CurrentToolIndex].errorHours = false;
        chnagetime[this.state.CurrentToolIndex].hours = e.target.value;
      }
    }
    if (name === "min") {
      if (e.target.value <= 60) {
        chnagetime[this.state.CurrentToolIndex].min = e.target.value;
      }
    }
    this.setState({
      ChangeTimeOfTools: chnagetime,
    });
  };

  SoltTimeUpdate = () => {
    this.setState({
      TimerForTools: this.state.ChangeTimeOfTools,
    });
  };

  competencySchema = () => {
    return Yup.object().shape({
      competencyName: Yup.string().required(this.t("ProjectsController.competencySchema.competencyName")),
    });
  };

  setScoreRatingSchema = () => {
    return Yup.object().shape({
      setIncrement: Yup.string().required(this.t("ProjectsController.setScoreRatingSchema.setIncrement")),
      rating: Yup.string().required(this.t("ProjectsController.setScoreRatingSchema.rating")),
      description: Yup.string().required(this.t("ProjectsController.setScoreRatingSchema.description")),
    });
  };

  getErrorMessage = (touched: any, errors: any, value: string) => {
    return (
      errors[value] &&
      touched[value] && <div className="text-danger">{errors[value]}</div>
    );
  };

  DeletePdfFile = (props: any, index: number, fileObj: any) => {
    props.setFieldValue(
      "pdfFile",
      props.values.pdfFile.filter((_: any, idx: any) => index !== idx)
    );
    this.setState({
      pdfFile: this.state.pdfFile.filter((item: any) => item !== fileObj),
    });
  };

  AddPdfFile = (e: any, props: any) => {
    if (e.target.files.length > 0) {
      let data = Object.values(e.target.files).map((item: any) => {
        return { item, };
      });
      let newData = data.map(async (item: any) => { return { doc_type: "pdf", doc: { data: await this.getBase64(item.item) }, }; });
      Promise.all(newData).then((values: any) => { props.setFieldValue("pdfFile", [...props.values.pdfFile, ...values]); });
      this.setState({
        pdfFile: [...this.state.pdfFile, ...data.map((item: any) => {
          return {
            file: URL.createObjectURL(item.item), name: item.item.name, fromApi: true,
          };
        }),
        ],
      });
    }
  };

  handleDrag = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      this.setState({ dragActive: true });
    } else if (e.type === "dragleave") {
      this.setState({ dragActive: false });
    }
  };

  handleDrop = async (e: any, props: any, fileType: any) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ dragActive: false });
    if (e.dataTransfer.files[0].size > 50 * 1024 * 1024) {
      toast.warn(this.t("ProjectsController.Uploadvideowithsizelessthan50MB"))
    } else {
      if (e.dataTransfer.files[0].type.split('/')[0] !== fileType) {
        toast.warn(this.t("CreateNewProject.AddCompanysection.toast.UploadValid", { value: fileType }))
      } else {
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
          props.setFieldValue('uploadFile', [await this.getBase64(e.dataTransfer.files[0])])
          this.setState({
            isfileEdit: true
          });
        }
      }
    }
  };

  handleDropFile = async (e: any, props: any, fileType: any) => {
    e.preventDefault(); e.stopPropagation();
    this.setState({ dragActive: false });
    if (e.dataTransfer.files[0].type.split('/')[1] !== fileType) {
      toast.warn(this.t("CreateNewProject.AddCompanysection.toast.UploadValid", { value: fileType }))
    } else {
      if (e.dataTransfer.files && e.dataTransfer.files[0]) {
        let data = Object.values(e.dataTransfer.files).map((item: any) => {
          return { item, };
        });
        let newData = data.map(async (item: any) => {
          return { doc_type: "pdf", doc: { data: await this.getBase64(item.item) }, };
        });
        Promise.all(newData).then((values: any) => {
          props.setFieldValue("pdfFile", [...props.values.pdfFile, ...values]);
        });
        this.setState({
          pdfFile: [
            ...this.state.pdfFile, ...data.map((item: any) => {
              return { file: URL.createObjectURL(item.item), name: item.item.name, fromApi: true, };
            }),
          ],
        });
        this.setState({
          isfileEdit: true
        });
      }
    }
  };
  onDrop = async (acceptedFiles: File[], props: any) => {
    if (!Array.isArray(acceptedFiles)) {
      return;
    }
    const base64Files = await Promise.all(
      acceptedFiles.map(async (file) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          /* istanbul ignore next */
          reader.onload = (event) => {
            const base64String = event?.target?.result;
            if (base64String) {
              resolve(base64String);
            } else {
              reject(new Error('Failed to convert file to base64'));
            }
          };
          reader.readAsDataURL(file);
        });
      })
    );
    
    const baseFile = [];
     /* istanbul ignore next */
    baseFile.push(...base64Files);
    /* istanbul ignore next */
    const formattedFiles = baseFile.map((base64String) => ({
      doc_type: 'pdf',
      doc: { data: base64String },
    }));
     /* istanbul ignore next */
    this.setState((prevState: any) => ({
          pdfFile: [...prevState.pdfFile, ...acceptedFiles],
        }), () => {
          props.setFieldValue('pdfFile', [...props.values.pdfFile, ...formattedFiles])
        });
  };

  removeFile = (index:any, file: File,props: any) => {
    if (this.state.pdfFile && this.state.pdfFile.length >= 0) {
      this.setState((prevState: any) => ({
        pdfFile: prevState.pdfFile.filter((f: File) => f !== file),
      }));
    }
    props.setFieldValue(
      "pdfFile",
      props.values.pdfFile.filter((_: any, idx: any) => index !== idx)
    );
  };

  SubmitApiCall = (values: any) => {
    if (
      this.state.ChangeTimeOfTools.length !== 0 &&
      this.state.ChangeTimeOfTools.filter(
        (item: any) => item.hours === "" || item.min === ""
      ).length === 0
    ) {
      this.SubmitApiCallElseCondtion(values);
    } else {
      let everyFill = this.state.ChangeTimeOfTools.filter(
        (item: any) => item.hours === "" || item.min === ""
      );
      everyFill.length === 0
        ? toast.warn(this.t("ProjectsController.PleaseSelectTools"))
        : toast.warn(`${this.t("ProjectsController.PleaseAddCorrectTimeFor")} ${everyFill[0]?.label}`);
    }
  };

  SubmitApiCallElseCondtion = (values: any) => {
    if (this.state.TimerForTools.length === 0) {
      toast.warn(this.t("ProjectsController.PleaseSaveToolsTools"));
    } else {
      let filter = this.state.TimerForTools.filter(
        (item: any) => item.hour == 0 && item.min == 0
      );

      if (filter.length === 0) {
        if ((this.state.SaverangeHalfData.length === 0 ||
          this.state.SaverangeOneData.length === 0) && (this.state.projectType === "assessor" || this.state.projectType === "hybrid")
        ) {
          toast.warn(this.t("ProjectsController.PleaseAddDescriptionForRating"));
          return;
        }
        this.createProject(values)
        this.setState({
          loading: true,
        });
      } else {
        let findIndex = this.state.ChangeTimeOfTools.filter(
          (item: any) =>
            item.online_tool_id === filter[0]?.online_tool_id.toString()
        );
        toast.warn(`${this.t("ProjectsController.PleaseAddCorrectTimeFor")} ${findIndex[0].label}`);
      }
    }
  };

  createProjectHandle = (values: any) => {
    if ((this.state.rangeHalfData.length === 0 ||
      this.state.rangeOneData.length === 0)
    ) {
      toast.warn(this.t("ProjectsController.PleaseAddDescriptionForRating"));
      return;
    }
    this.createProject(values)
    this.setState({
      loading: true,
    });
  }

  EditApiCall = (id: any, values: any) => {
    if (
      this.state.ChangeTimeOfTools.filter(
        (item: any) => item.hours === "" || item.min === ""
      ).length === 0 &&
      this.state.ChangeTimeOfTools.length !== 0
    ) {
      if (this.state.TimerForTools.length === 0) {
        toast.warn(this.t("ProjectsController.PleaseSaveToolsTools"));
      } else {
        let editFilter: any = this.state.TimerForTools.filter(
          (item: any) => item.hour == 0 && item.min == 0
        );
        if (editFilter.length === 0) {
          this.handleUpdateProject(id, values);
          this.setState({
            loading: true,
          });
        } else {
          let findIndexEdit = this.state.ChangeTimeOfTools.filter(
            (item: any) =>
              item.online_tool_id === editFilter[0]?.online_tool_id.toString()
          );
          toast.warn(`${this.t("ProjectsController.PleaseAddCorrectTimeFor")} ${findIndexEdit[0].label}`);
        }
      }
    } else {
      let everyFillEdit = this.state.ChangeTimeOfTools.filter(
        (item: any) => item.hours === "" || item.min === ""
      );
      everyFillEdit.length === 0
        ? toast.warn(this.t("ProjectsController.PleaseSelectTools"))
        : toast.warn(`${this.t("ProjectsController.PleaseAddCorrectTimeFor")} ${everyFillEdit[0]?.label}`);
    }
  };

  handleUpdateProjectMethod = (id: any, values: any) => {
    if ((this.state.rangeHalfData.length === 0 ||
      this.state.rangeOneData.length === 0)) {
      toast.warn(this.t("ProjectsController.PleaseAddDescriptionForRating"));
      return;
    }
    this.handleUpdateProject(id, values)
  }

  CloneAPiCall = (id: any, values: any) => {
    if (
      this.state.ChangeTimeOfTools.length !== 0 &&
      this.state.ChangeTimeOfTools.filter(
        (item: any) => item.hours === "" || item.min === ""
      ).length === 0
    ) {
      this.CloneAPiElseFuncation(id, values)
    } else {
      let everyFillEdit = this.state.ChangeTimeOfTools.filter(
        (item: any) => item.hours === "" || item.min === ""
      );
      everyFillEdit.length === 0
        ? toast.warn(this.t("ProjectsController.PleaseSelectTools"))
        : toast.warn(`${this.t("ProjectsController.PleaseAddCorrectTimeFor")} ${everyFillEdit[0]?.label}`);
    }
  };

  CloneAPiElseFuncation = (id: any, values: any) => {
    if (this.state.TimerForTools.length === 0) {
      toast.warn(this.t("ProjectsController.PleaseSaveToolsTools"));
    } else {

      let CloneFilter = this.state.TimerForTools.filter(
        (item: any) => item.hour == 0 && item.min == 0
      );
      if (CloneFilter.length === 0) {
        if ((this.state.rangeHalfData.length === 0 ||
          this.state.rangeOneData.length === 0) && (this.state.projectType === "assessor" || this.state.projectType === "hybrid")
        ) {
          toast.warn(this.t("ProjectsController.PleaseAddDescriptionForRating"));
          return;
        }
        this.handleUpdateProject(id, values)
        this.setState({
          loading: true,
        });
      } else {
        let cloneAPiFilter = this.state.ChangeTimeOfTools.filter(
          (item: any) =>
            item.online_tool_id === CloneFilter[0]?.online_tool_id.toString()
        );
        toast.warn(`${this.t("ProjectsController.PleaseAddCorrectTimeFor")} ${cloneAPiFilter[0].label}`);
      }
    }
  }

  TimerSave = () => {
    let everyFill = this.state.ChangeTimeOfTools.filter(
      (item: any) => item.hours === "" || item.min === ""
    );
    let location =
      this.props?.history?.location.pathname === "/CloneProject" ||
      this.props?.history?.location.pathname?.split("/")[1] === "EditProject";
    if (everyFill.length === 0) {
      let data = this.state.ChangeTimeOfTools.map((item: any) => {
        let OldTools = this.state.UnSelectTimerTools.map((item: any) =>
          Number(item.online_tool_id)
        ).indexOf(Number(item.online_tool_id));
        return {
          online_tool_id: Number(item.online_tool_id),
          hour: Number(item.hours),
          min: Number(item.min),
          ...(location && {
            id: this.state.UnSelectTimerTools[OldTools]?.id
              ? this.state.UnSelectTimerTools[OldTools]?.id
              : item.id,
          }),
          isDestroy: item.isDestroy,
        };
      });
      this.setState({
        TimerForTools: data,
        SetTimeModel: false,
        CurrentToolIndex: 0,
        CurrentToolName: ""
      });
    } else {
      toast.warn(`${this.t("ProjectsController.PleaseAddCorrectTimeFor")} ${everyFill[0]?.label}`);
    }
  };

  setScoreEdit = () => {
    this.setState({
      loading: true,
    });
    let id = Number(window.location.pathname.split("/")[2]);
    const httpBody = {
      id: id,
    };
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("accesToken"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.editGlobalRatingsData = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_profile/projects/ratings`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getGlobalRatingsData = () => {
    this.setState({
      loading: true,
    });
    const header = {
      "Content-Type": "application/json",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.globalRatingsData = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_profile/ratings?token=${localStorage.getItem("accesToken")}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleCloneCompetency = (id: any) => {
    this.setState({
      loading: true,
      competencyIdToBeReplacedWhileEdit: id,
    });
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("accesToken"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.cloneCompetency = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_assessmenttest/competancies/clone?id=${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  checkDuplicity = () => {
    this.setState({
      loading: true,
    });

    let old_Array = [...this.state.competencyId];
    let len = old_Array.length;
    let newString = ""
    for (let i = 0; i < len; ++i) {
      newString = newString + `ids[]=${old_Array[i]}&`
    }

    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("accesToken"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.checkDuplicityAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_assessmenttest/competancies/check_duplicacy?${newString}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  showCompetency = (id: any) => {
    this.setState({
      loading: true,
    });

    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("accesToken"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.showCompetencyAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_assessmenttest/competancies/${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  RemoveSection = (item: any) => {
    if (this.state.CompanySection.length === 1) {
      toast.warn(this.t('ProjectsController.YouCantDeleteAllContent'));
    } else {
      this.setState({
        CompanySection: this.state.CompanySection.filter((allSection: any) => allSection !== item)
      })
    }
  }

  HtmlConverter = (data: any) => {
    return { __html: data };
  };

  AddDatainSections = (values: any) => {
    if (this.state.EditSectionIndex === -1) {
      this.setState({
        CompanySection: this.state.CompanySection.concat(values),
      })
    } else {
      let EditData = [...this.state.CompanySection]
      EditData[this.state.EditSectionIndex] = values
      let setData = EditData
      this.setState({
        CompanySection: setData
      })
    }
    this.setState({
      CompanyModel: false,
      SectionNumber: -1,
      EditSectionIndex: -1
    })
  }

  OpenSectionFiles = (fileRender: any) => {
    let formateofData = fileRender.url !== undefined ? fileRender?.url.includes("video") ? "video" : "image" : fileRender.includes("video") ? "video" : "image"
    this.setState({
      ViewFileModel: true,
      FileType: formateofData,
      ViewFileSource: fileRender.url ?? fileRender
    })
  }

  ProjectTypeChnageInClone = (event: any) => {
    const { selectedTools, ChangeTimeOfTools, TimerForTools, finalQuetionForApi, AssosserHybridData } = this.state
    if (event.value === "assessor") {
      this.setState({
        AssosserHybridData: [{
          selectedTools: selectedTools,
          ChangeTimeOfTools: ChangeTimeOfTools,
          TimerForTools: TimerForTools,
          finalQuetionForApi: finalQuetionForApi
        }]
      }, () => {
        this.setState({
          selectedTools: [],
          ChangeTimeOfTools: [],
          TimerForTools: [],
          finalQuetionForApi: []
        })
      })
    }
    if (event.value === "hybrid") {
      this.setState({
        selectedTools: AssosserHybridData[0].selectedTools,
        ChangeTimeOfTools: AssosserHybridData[0].ChangeTimeOfTools,
        TimerForTools: AssosserHybridData[0].TimerForTools,
        finalQuetionForApi: AssosserHybridData[0].finalQuetionForApi
      })
    }
  }

  // Customizable Area End
}
