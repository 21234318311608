// Customizable Area Start
import React from "react";
import {
    Box,
    Button,
    Checkbox,
    Typography,
    FormControlLabel,
} from "@material-ui/core";
import { styled } from "@material-ui/styles";
import { withRouter } from "react-router-dom";
import TestInstructionController, { Props } from "./TestInstructionController.web";
import AppHeader from "../../../components/src/AppHeader.web";
import AppFooter from "../../../components/src/AppFooter.web";
import Loader from "../../../components/src/Loader.web";
import "./TestInstruction.web.css";
// Customizable Area End

// Customizable Area Start
class TestInstruction extends TestInstructionController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    render() {
        return (
            //Merge Engine Start DefaultContainer
            <Box className="test-instruction">
                {this.state.loading && <Loader loading={this.state.loading} />}
                <AppHeader />
                <Box margin="0px 30px 60px">
                    <Box className="top">
                        <Typography variant="h3" className="title">
                            Test Instructions
                        </Typography>
                    </Box>
                    <Box>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                    </Box>
                    <Box mt="20px" mb="40px">
                        For each question in this section, kindly select the priority of the task and immediate action for the same.
                    </Box>
                    <Box>
                        <Typography variant="h4" className="title-info">Test Instructions:</Typography>
                        <ul>
                            <li>Total no. of questions: <span>{this.state.totalQuestion}</span></li>
                            <li>Time allocated: <span>{this.state.duration} Minutes</span></li>
                            <li>There is <span>No negative marking</span> in the test.</li>
                        </ul>
                    </Box>
                    <Box>
                        <Typography className="info">Please read the following attentively to understand the test screen:</Typography>
                        <ul>
                            <li>Click the <span>"Start Test"</span> button given in the bottom of this page to start the test.</li>
                            <li>Test will be submitted automatically if the <span>time expires.</span></li>
                            <li><span>Don't refresh the page.</span></li>
                            <li>Use <span>"Previous"</span> and <span>"Next"</span> button on the screen to navigate between questions.</li>
                            <li>The right hand side panel indicates the answer status. If the box color is <span>"Grey"</span>. The question is answered and if it is <span>"Orange"</span>, the question is unanswered.</li>
                            <li>To clear the selected option, click on the option again.</li>
                            <li>Click on <span>"Finish"</span> button to submit the test.</li>
                        </ul>
                    </Box>
                    <Box className="center-title">Wishing you all the Very Best!</Box>
                    <hr />
                    <Box>
                        <Typography variant="h4" className="title-info">Important Information</Typography>
                        <ul>
                            <li>Your test will be <span>Terminated</span> & considered as complete if you attempt to switch to another window.</li>
                            <li>Use of computer calculator will also be considered as switching window.</li>
                            <li>Please note: Your Test Score will be considered as <span>Invalid.</span></li>
                        </ul>
                    </Box>
                    <hr />
                    <Box>
                        <Typography className="content" style={{ marginBottom: "15px" }}>I hereby confirm to have read the Test Instruction & Important Information regarding the test.</Typography>
                        <FormControlLabel
                            style={{ marginLeft: "-12px", marginTop: "-4px" }}
                            control={
                                <Checkbox
                                    style={{ color: "#288A0A" }}
                                    checked={this.state.isCheck}
                                    onChange={(event: any) => {
                                        this.setState({ isCheck: event.target.checked });
                                    }}
                                />
                            }
                            label="I agree to the Terms & Conditions mentioned above."
                        />
                    </Box>
                    <Box textAlign="center" mt="50px">
                        <CancelButton onClick={this.onCancel}>Cancel</CancelButton>
                        {console.log(!this.state.isCheck && this.state.disableBtnNext === true,"&&")}
                        <StartButton disabled={!this.state.isCheck} onClick={this.onStart}>Start</StartButton>
                    </Box>
                </Box>
                <AppFooter />
            </Box>
            //Merge Engine End DefaultContainer
        );
    }
}

export default withRouter(TestInstruction);

// Customizable Area Start
const CancelButton = styled(Button)({
    fontFamily: "ProximaNova-Semibold",
    borderRadius: "10px",
    marginRight: "30px",
    fontSize: "18px",
    padding: "7px 15px",
    color: "#1b3c69",
    backgroundColor: "#fff",
    border: "1px solid #1b3c69",
    textTransform: "none",
});

const StartButton = styled(Button)({
    fontFamily: "ProximaNova-Semibold",
    borderRadius: "10px",
    padding: "7px 22px",
    fontSize: "18px",
    backgroundColor: "#1b3c69",
    color: "#fff",
    border: "1px solid #1b3c69",
    textTransform: "none",
    "&:hover": {
        color: "#fff",
        backgroundColor: "#1b3c69",
    },
    "&.MuiButton-root.Mui-disabled": {
        border: "1px solid hsl(0,0%,60%)",
        color: "hsl(0,0%,60%)",
        backgroundColor: "hsl(0,0%,95%)",
        cursor: "no-drop",
        pointerEvents: "auto"
    },
});
// Customizable Area End