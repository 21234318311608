import React from "react";

import {
  StyleSheet,
  Text,
  Image,
  TouchableOpacity,
  View,
  TextInput,
  ScrollView,
  TouchableWithoutFeedback,
  Button,
  Platform,
  // Customizable Area Start
  // Customizable Area End
} from "react-native";

import AdminConsole3Controller, {
  Props,
  configJSON
} from "./AdminConsole3Controller";

export default class AdminConsole3 extends AdminConsole3Controller {constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
       }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ScrollView keyboardShouldPersistTaps="always" style=
      {styles.container}>
        <TouchableWithoutFeedback
          onPress={
            () => {this.hideKeyboard();
          }
        }>
          {/* Customizable Area Start */}
          {/* Merge Engine UI Engine Code */}
  <View>{this.isPlatformWeb() ? (
                 <Text  style={styles.title} //UI Engine::From Sketch
                testID="labelTitle" //Merge Engine::From BDS
              >       {configJSON.labelTitleText}
              </Text> //UI Engine::From Sketch
            ) : null
            }

            <Text
              style=
              {styles.body} //UI Engine::From Sketch
              testID="labelBody" //Merge Engine::From BDS

            >              {" "}
              {/* UI Engine::From Sketch */}
              {configJSON.labelBodyText} {/* UI Engine::From Sketch */}
            </Text>

            <Text testID="txtSaved">              This is the reviced value:
              {this.state.txtSavedValue}              {" "}
            </Text>

            <View style={styles.bgPasswordContainer}>
              <TextInput
                testID="txtInput" //Merge Engine::From BDS  
                {...this.txtInputProps} //Merge Engine::From BDS - {...this.testIDProps}
             
                placeholder={configJSON.txtInputPlaceholder} //UI Engine::From Sketch
                style={styles.bgMobileInput} //UI Engine::From Sketch
              />

              <TouchableOpacity
                style={styles.showHide} //UI Engine::From Sketch
                {...this.btnShowHideProps} //Merge Engine::From BDS - {...this.testIDProps}

                testID={"btnShowHide"} //Merge Engine::From BDS
              >
                <Image
                  style={styles.imgShowhide} //UI Engine::From Sketch
                  {...this.btnShowHideImageProps} //Merge Engine::From BDS - {...this.testIDProps}

                  testID={"btnShowHideImage"} //Merge Engine::From BDS - testIDImage
                />
              </TouchableOpacity>
            </View>

            <Button
                          {...this.btnExampleProps} //Merge Engine::From BDS - {...this.testIDProps}

              title={configJSON.btnExampleTitle} //UI Engine::From Sketch
              testID={"btnExample"} //Merge Engine::From BDS
            />
          </View>
           {/* Customizable Area End */}
        </TouchableWithoutFeedback></ScrollView>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles =
 StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
    width: Platform.OS === "web" ? "75%" : "100%",
    maxWidth: 650,
    marginLeft: "auto",
    marginRight: "auto",
    backgroundColor: "#ffffffff"
  },
  title: {
    marginBottom: 32,
    marginVertical: 8,
    fontSize: 16,
    textAlign: "left",
  },
  body: {
    marginBottom: 32,
    marginVertical: 8,
    fontSize: 16,
    textAlign: "left",
  },
  bgPasswordContainer: {
    flexDirection: "row",
    backgroundColor: "#00000000",
    borderColor: "#767676",
    borderRadius: 2,
    padding: 10,
    borderWidth: Platform.OS === "web" ? 0 : 1,
    marginBottom: 16,
    borderBottomWidth: 1,
  },
  bgMobileInput: {
    flex: 1,
    padding:10
  },
  showHide: {
    alignItems: "center"
  },
  imgShowhide: Platform.OS === "web" ? {width: 30, height: 30,} : {}
});
// Customizable Area End
