import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { toast } from "react-toastify";
export const configJSON = require("../config");

export interface Props {
    t: any;
    id?: string;
    navigation?: any;
    openDialogName: string | null;
    onlineToolList: any[];
    competencyList: any[];
    industryList: any[];
    item: any;
    changeDialog: (name: string | null, obj?: any) => void;
}

export interface S {
    // Customizable Area Start
    loading: boolean;
    openDialogName: string | null;
    uploadSubmitBtn: boolean;
    isSubmitBtnClick: boolean;
    questionFile: any;
    questionImage: any;
    isfileEdit: boolean;
    question: string;
    mark: string;
    competency: string;
    industry: string;
    level: string;
    onlineToolId: any;
    editQuestionId: boolean | null;
    deleteQuestionId: boolean | null;
    onlineToolList: any[];
    competencyList: any[];
    industryList: any[];
    optionArray: any[];
    removedOption: any[];
    noOfOption: any;
    totalQuestion: number;
    errorQuestion: number;
    successQuestion: number;
    errorCSVFile: any;
    errorFileObj: string | null;
    editToolId: any;
    deleteTool: any;
    deleteOnlineToolId: any;
    currentIndex: number;
    currentToolLogo: any;
    currentToolName: any;
    currentToolDescription: string | null;
    isAddToolButtonClick: boolean;
    isNoOfOptionChange: boolean;
    dragActive: boolean;
    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class OnlineToolsDialogController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    addQuestionApiCallId: string = "";
    editQuestionApiCallId: string = "";
    deleteQuestionApiCallId: string = "";
    addBulkQuestionApiCallId: string = "";
    addToolApiCallId: string = "";
    editToolApiCallId: string = "";
    deleteToolApiCallId: string = "";
    deleteErrorCSVApiCallId: string = "";

    inputRef: any;
    t: any;
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage)
        ];

        this.state = {
            // Customizable Area Start
            loading: false,
            uploadSubmitBtn: false,
            isSubmitBtnClick: false,
            questionFile: null,
            questionImage: this.props.item?.questionImageURL,
            isfileEdit: false,
            openDialogName: this.props.openDialogName,
            onlineToolId: this.props.item?.onlineToolId ?? "",
            deleteQuestionId: this.props.item?.id,
            editQuestionId: this.props.item?.id,
            question: this.props.item?.question,
            mark: this.props.item?.mark ?? "",
            competency: this.props.item?.competencyId,
            industry: this.props.item?.industryId,
            level: this.props.item?.level,
            onlineToolList: this.props.onlineToolList,
            competencyList: this.props.competencyList,
            industryList: this.props.industryList,
            optionArray: this.props.item?.answers ?? [],
            removedOption: [],
            totalQuestion: 0,
            errorQuestion: 0,
            successQuestion: 0,
            errorCSVFile: null,
            errorFileObj: null,
            noOfOption: this.props.item?.answers.length,
            editToolId: null,
            deleteTool: null,
            deleteOnlineToolId: null,
            currentIndex: -1,
            currentToolLogo: null,
            currentToolName: "",
            currentToolDescription: "",
            isAddToolButtonClick: false,
            isNoOfOptionChange: false,
            dragActive: false
            // Customizable Area End
        };
        this.t = this.props.t;
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (apiRequestCallId && responseJson) {
                if (responseJson.status !== 500) {
                    if (Array.isArray(responseJson.errors) && responseJson.errors[0].name) {
                        toast.error(responseJson.errors[0].name);
                        this.setState({ loading: false });
                    } else {
                        this.handleApiResponse(apiRequestCallId, responseJson);
                    }
                }
                this.setState({ loading: false });
            }
        }
    }

    componentDidUpdate(prevProps: any, prevState: any) {
        if (prevProps.onlineToolList !== this.props.onlineToolList) {
            this.setState({ onlineToolList: this.props.onlineToolList, isAddToolButtonClick: false });
        }
        if (prevState.isNoOfOptionChange) {
            this.setState({ isNoOfOptionChange: false });
        }
    }

    handleApiResponse(apiRequestCallId: string, responseJson: any) {
        if (apiRequestCallId === this.addQuestionApiCallId) {
            toast.success(this.t("onlineToolDialog.toast.addQuestion"));
            this.props.changeDialog("Add", responseJson.data);
        } else if (apiRequestCallId === this.editQuestionApiCallId) {
            toast.success(this.t("onlineToolDialog.toast.editQuestion"));
            this.props.changeDialog("Edit", responseJson.data);
        } else if (apiRequestCallId === this.deleteQuestionApiCallId) {
            toast.success(this.t("onlineToolDialog.toast.deleteQuestion"));
            this.props.changeDialog("Delete", responseJson.data);
        } else if (apiRequestCallId === this.addBulkQuestionApiCallId) {
            const successQuestion = responseJson.data?.success_count;
            const errorQuestion = responseJson.data?.fail_count;
            if (!successQuestion && !errorQuestion) {
                toast.error(responseJson.message);
                return;
            }
            if (errorQuestion === 0) {
                toast.success(this.t("onlineToolDialog.toast.allQuestionAdd"));
                this.props.changeDialog("");
            } else {
                this.setState({
                    successQuestion,
                    errorQuestion,
                    errorCSVFile: responseJson.data.url,
                    totalQuestion: successQuestion + errorQuestion,
                    errorFileObj: responseJson.data.obj_key,
                    openDialogName: "CSVError"
                });
                this.props.changeDialog("CSVError");
            }
        } else if (apiRequestCallId === this.addToolApiCallId) {
            toast.success(this.t("onlineToolDialog.toast.addTool"));
            this.props.changeDialog("AddEditTool");
        } else if (apiRequestCallId === this.editToolApiCallId) {
            toast.success(this.t("onlineToolDialog.toast.editTool"));
            this.setState({ editToolId: null });
            this.props.changeDialog("AddEditTool");
        } else if (apiRequestCallId === this.deleteToolApiCallId) {
            toast.success(this.t("onlineToolDialog.toast.deleteTool"));
            this.props.changeDialog("AddEditTool");
        }
        this.setState({ loading: false });
    }

    onMarkChange = (event: any, setFieldValue: any) => {
        const re = /^[0-9\b]+$/;
        if (event.target.value === '' || re.test(event.target.value)) {
            setFieldValue("mark", event.target.value);
            this.setState({ mark: event.target.value });
        }
    }

    onCancel = () => {
        if (this.state.openDialogName === "Clone") {
            this.deleteQuestion();
        } else {
            this.props.changeDialog(null);
        }
    }

    handleDrag = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragenter" || e.type === "dragover") {
            this.setState({ dragActive: true });
        } else if (e.type === "dragleave") {
            this.setState({ dragActive: false });
        }
    };

    handleDrop = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({
            dragActive: false,
            questionFile: e.dataTransfer.files[0]
        });
    };

    handleFileDrop = (e: any, setFieldValue: any) => {
        e.preventDefault();
        e.stopPropagation();
        setFieldValue("questionImage", e.dataTransfer.files[0]);
        this.setState({
            dragActive: false,
            isfileEdit: true,
            questionImage: e.dataTransfer.files[0]
        });
    };

    onCSVFileDownload = () => {
        const link = document.createElement('a');
        link.href = this.state.errorCSVFile;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setTimeout(() => {
            this.setState({ openDialogName: "Upload", questionFile: null });
            this.deleteErrorCSVFile();
        }, 3000);
    }

    onCorrectAnswerClick = (index: number, isChecked: boolean) => {
        let optionArray = [...this.state.optionArray];
        optionArray = optionArray.map((option: any, index2: any) => {
            if (index2 === index) {
                return { ...option, correct_answer: isChecked }
            } else {
                return { ...option, correct_answer: false }
            }
        })
        this.setState({ optionArray });
    }

    onOptionValueChange = (index: number, value: string) => {
        let optionArray = [...this.state.optionArray];
        optionArray[index].answer = value;
        this.setState({ optionArray });
    }

    getBase64 = (file: Blob) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    }

    removeFile = (index: number) => {
        let optionArray = [...this.state.optionArray];
        optionArray[index].file = null;
        optionArray[index].image_url = null;
        optionArray[index].FileName = "";
        optionArray[index].isEdit = true;
        this.setState({ optionArray });
    }

    onImageVideoSelect = async (index: number, event: any) => {
        let optionArray = [...this.state.optionArray];
        const file = event.target.files[0];
        optionArray[index].image_url = file ? await this.getBase64(file) : null;
        optionArray[index].isEdit = true;
        optionArray[index].FileName = file.name;
        optionArray[index].file = file;
        this.setState({ optionArray });
    }

    onDeleteToolButtonClick = (tool: any) => {
        this.setState({ deleteTool: tool, openDialogName: "DeleteTool" });
    }

    onNoOfOptionChange = (event: any) => {
        let optionArray = [...this.state.optionArray];
        let removedOption = [...this.state.removedOption];
        const totalOption = event.value;
        const difference = totalOption - optionArray.length;

        if (difference < 0) {
            removedOption = [...optionArray.slice(totalOption), ...removedOption];
            optionArray = optionArray.splice(0, totalOption);
        } else if (difference > 0) {
            let array = [];
            if (this.state.openDialogName == "Add") {
                array = Array.from({ length: difference }, () => ({ answer: "", image_url: null, correct_answer: false, FileName : "" }));
            } else {
                array = [...Array(difference).keys()].map((_, index: number) => {
                    let obj = { answer: "", image_url: null, correct_answer: false, FileName : "" };
                    if (index < (removedOption.length + index)) {
                        const id = removedOption[0].id;
                        removedOption.shift();
                        return { id, ...obj };
                    } else {
                        return obj;
                    }
                });
            }
            optionArray = [...optionArray, ...array];
        }
        this.setState({ removedOption, noOfOption: totalOption, optionArray, isNoOfOptionChange: true });
    }

    onToolNameChange = (event: any, index: number) => {
        let onlineToolList = [...this.state.onlineToolList];
        onlineToolList[index].label = event.target.value;
        this.setState({ onlineToolList });
    }

    onToolDescriptionChange = (event: any, index: number) => {
        let onlineToolList = [...this.state.onlineToolList];
        onlineToolList[index].description = event.target.value;
        this.setState({ onlineToolList });
    }

    onToolLogoChange = async (file: any, index: number) => {
        let onlineToolList = [...this.state.onlineToolList];
        onlineToolList[index].isLogoChange = true;
        onlineToolList[index].image = file;
        this.setState({ onlineToolList });
    }

    onToolLogoColor = async (file: any, index: number) => {
        let onlineToolList = [...this.state.onlineToolList];
        onlineToolList[index].color = file.target.value;
        this.setState({ onlineToolList });
    }

    onEditButtonClick = (tool: any, index: number) => {
        let onlineToolListNew = [...this.state.onlineToolList];

        if (this.state.editToolId) {
            onlineToolListNew[this.state.currentIndex].label = this.state.currentToolName;
            onlineToolListNew[this.state.currentIndex].description = this.state.currentToolDescription
            onlineToolListNew[this.state.currentIndex].image = this.state.currentToolLogo;
        }


        if (!onlineToolListNew[onlineToolListNew.length - 1].value) {
            onlineToolListNew.pop();
        }

        this.setState({
            editToolId: tool.value,
            currentToolLogo: tool.image,
            currentIndex: index,
            onlineToolList: onlineToolListNew,
            currentToolName: tool.label,
            currentToolDescription: tool.description
        });
    }

    onSaveTool = (value: any) => {
        if (!this.state.editToolId) {
            this.addTool();
        } else if (value !== null) {
            this.editTool();
        }
    }

    onDialogClose = () => {
        switch (this.state.openDialogName) {
            case "Add":
                this.props.changeDialog(null);
                break;
            case "Edit":
                this.props.changeDialog(null);
                break;
            case "Clone":
                this.deleteQuestion();
                break;
            default:
                this.props.changeDialog(null);
        }
    }

    checkUniqueLabel = () => {
        let onlineToolList = [...this.state.onlineToolList];
        const uniqueArr = new Set(
            onlineToolList.map((s: any) => {
                return s.label.trim().toLowerCase();
            })
        );
        return uniqueArr.size === onlineToolList.length;
    }

    addToolRecord = () => {
        let onlineToolList = [...this.state.onlineToolList];
        const obj = { image: null, label: "", description: "", value: null };
        if (onlineToolList[onlineToolList.length - 1]?.value === null) {
            onlineToolList[onlineToolList.length - 1] = obj;
            this.inputRef.focus();
        } else {
            onlineToolList = [...onlineToolList, obj];
        }
        if (this.state.editToolId) {
            onlineToolList[this.state.currentIndex].image = this.state.currentToolLogo;
            onlineToolList[this.state.currentIndex].label = this.state.currentToolName;
            onlineToolList[this.state.currentIndex].description = this.state.currentToolDescription;
        }
        this.setState({ onlineToolList, editToolId: null, isAddToolButtonClick: true });
    }

    addQuestion = async () => {
        this.setState({ loading: true });

        const header = {
            "Content-Type": configJSON.addQuestionApiContentType,
            token: localStorage.getItem("accesToken")
        };

        const optionArray = this.state.optionArray.map((item: any) => {
            return {
                answer: item.answer ? item.answer : undefined,
                img: item.image_url ? { "data": item.image_url } : undefined,
                correct_answer: item.correct_answer
            }
        });

        const httpBody = {
            "name": this.state.question,
            "level": this.state.level,
            "mark": parseInt(this.state.mark),
            "online_tool_id": parseInt(this.state.onlineToolId),
            "industry_id": parseInt(this.state.industry),
            "competancy_id": parseInt(this.state.competency),
            "img": this.state.questionImage ? { "data": await this.getBase64(this.state.questionImage) } : undefined,
            "answers_attributes": optionArray
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.addQuestionApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.addQuestionApiEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.addQuestionApiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }

    editQuestion = async () => {
        this.setState({ loading: true });

        const header = {
            "Content-Type": configJSON.editQuestionApiContentType,
            token: localStorage.getItem("accesToken")
        };

        const optionArray = this.state.optionArray.map((item: any) => {
            const obj = item.image_url ? { data: item.image_url } : null;
            return {
                id: item.id,
                answer: item.answer,
                img: item.isEdit ? obj : undefined,
                correct_answer: item.correct_answer
            }
        });

        const removedOptionArray = this.state.removedOption.map((item: any) => {
            return {
                id: item.id,
                _destroy: true
            }
        });

        const obj = this.state.questionImage && this.state.isfileEdit ? { data: await this.getBase64(this.state.questionImage) } : null;
        const httpBody = {
            "name": this.state.question,
            "level": this.state.level,
            "mark": parseInt(this.state.mark),
            "online_tool_id": this.state.onlineToolId,
            "industry_id": this.state.industry,
            "competancy_id": this.state.competency,
            "img": this.state.isfileEdit ? obj : undefined,
            "answers_attributes": [...optionArray, ...removedOptionArray],
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.editQuestionApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.editQuestionApiEndPoint}/${this.state.editQuestionId}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.editQuestionApiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }

    deleteQuestion = () => {
        this.setState({ loading: true });

        const header = {
            "Content-Type": configJSON.deleteQuestionApiContentType,
            token: localStorage.getItem("accesToken")
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.deleteQuestionApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.deleteQuestionApiEndPoint}/${this.state.deleteQuestionId}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.deleteQuestionApiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }

    addBulkQuestion = () => {

        if (!this.state.questionFile) {
            this.setState({ uploadSubmitBtn: true });
            return;
        }

        this.setState({ loading: true });

        const header = {
            token: localStorage.getItem("accesToken")
        };

        const formData = new FormData();
        formData.append("file", this.state.questionFile);

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.addBulkQuestionApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formData
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.addBulkQuestionApiEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.addBulkQuestionApiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }

    addTool = () => {
        const onlineToolListNew = [...this.state.onlineToolList];
        const tool = onlineToolListNew[onlineToolListNew.length - 1];
        if (!tool.image) {
            toast.warning(this.t("onlineToolDialog.toast.selectLogo"));
            return;
        } else if (!tool.label || !tool.description) {
            toast.warning(this.t("onlineToolDialog.toast.blankField"));
            return;
        }
        if (!this.checkUniqueLabel()) {
            toast.warning(this.t("onlineToolDialog.toast.sameTool"));
            return;
        }

        this.setState({ loading: true, openDialogName: "AddEditTool" });

        const header = {
            token: localStorage.getItem("accesToken")
        };

        const formData = new FormData();
        formData.append("logo", tool.image);
        formData.append("name", tool.label);
        formData.append("description", tool.description);
        formData.append("tool_color", tool.color);

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.addToolApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formData
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.addToolApiEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.addToolApiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }

    editTool = () => {
        const editedToolNew = this.state.onlineToolList.filter((tool: any) => tool.value === this.state.editToolId)[0];
        if (!editedToolNew.image) {
            toast.warning(this.t("onlineToolDialog.toast.selectLogo"));
            return;
        } else if (!editedToolNew.label || !editedToolNew.description) {
            toast.warning(this.t("onlineToolDialog.toast.blankField"));
            return;
        }
        if (!this.checkUniqueLabel()) {
            toast.warning(this.t("onlineToolDialog.toast.sameTool"));
            return;
        }

        this.setState({ loading: true, openDialogName: "AddEditTool" });

        const header = {
            token: localStorage.getItem("accesToken")
        };

        const formData = new FormData();
        editedToolNew.isLogoChange && formData.append("logo", editedToolNew.image);
        formData.append("name", editedToolNew.label);
        formData.append("description", editedToolNew.description);
        formData.append("tool_color", editedToolNew.color);

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.editToolApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formData
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.editToolApiEndPoint}/${this.state.editToolId}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.editToolApiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }

    deleteTool = () => {
        if (!this.state.deleteTool?.value) {
            let onlineToolListNew = [...this.state.onlineToolList];
            onlineToolListNew.pop();
            this.setState({ onlineToolList: onlineToolListNew, openDialogName: "AddEditTool" });
            return;
        }

        this.setState({
            loading: true,
            openDialogName: "AddEditTool"
        });
        const header = {
            "Content-Type": configJSON.deleteToolApiContentType,
            token: localStorage.getItem("accesToken")
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.deleteToolApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.deleteToolApiEndPoint}/${this.state.deleteTool?.value}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.deleteToolApiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }

    deleteErrorCSVFile = () => {
        const header = {
            "Content-Type": configJSON.deleteErrorCSVApiContentType,
            token: localStorage.getItem("accesToken")
        };

        const httpBody = {
            "obj": this.state.errorFileObj
        }

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.deleteErrorCSVApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.deleteErrorCSVApiEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.deleteErrorCSVApiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }
}

// Customizable Area End
