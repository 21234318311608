// Customizable Area Start
import React, { useState, useEffect, useRef } from "react";
import {
  Badge, Avatar, Box, Typography,
  Modal, Button, Accordion, AccordionSummary, AccordionDetails,
  Collapse, List, ListItem, ListItemIcon, ListItemText,
  Grid, InputBase, Dialog, DialogContent
} from "@material-ui/core";
import moment from "moment";
import { styled } from "@material-ui/styles";
import { NavLink, withRouter } from 'react-router-dom';
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CloseIcon from '@material-ui/icons/Close';
import * as Yup from "yup";
import { Formik } from "formik";
import AltSvg from "./SvgAlt";
import { toast } from "react-toastify";
import ReportsSvg from "./SvgReports";
import ProjectsSvg from "./SvgProjects";
import AnalyticsSvg from "./SvgAnalytics";
import OnlineToolsSvg from "./SvgOnlineTools";
import ManageUsersSvg from "./SvgManageUsers";
import ManageClientsSvg from "./SvgManageClients";
import ManageAssessorSvg from "./SvgManageAssessor";
import UnavailabilityIcon from "./SvgUnavailabilityIcon";
import AssignAvailableIcon from "./SvgAssignAvailableAssessor";
import ManageParticipantsSvg from "./SvgManageParticipants";
import { useTranslation } from "react-i18next";
import {
  menu, shrm, calendar, notification, notificationWhite
} from "./assets";
const LanguageData = localStorage.getItem("language") === "ar";
//import "./style.css";
// @ts-ignore
import shrmLogo from '../assets/shrm.png';
const { baseURL } = require("../../framework/src/config.js");

function AppSidebar(props: any) {
  const { t } = useTranslation();
  const ref = useRef(null);
  const [isAccordionOpen, setIsAccordionOpen] = useState(!!props.openAccordion);
  const [isOrganizationOpen, setIsOrganizationOpen] = useState(!!props.openOrganization);
  const [openSidebar, setOpenSidebar] = useState(true);
  const [onlineTools, setOnlineTools] = useState([]);
  const [logoutModal, OpenLogoutModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [openDialogName, setDialogName] = useState("");
  const [copyright, setCopyright] = useState("");
  const [copyrightId, setCopyrightId] = useState("");
  const [showCopyright, setShowCopyright] = useState(`© ${new Date().getFullYear()} SHRM India. All Rights Reserved.`);
  const [isSettingClicked, setIsSettingClicked] = useState(false);
  const [scroll, setScroll] = useState(parseInt(localStorage.getItem("scroll") ?? "0"));
  const userInfo = localStorage.getItem("user") ?? "";
  const userRole = localStorage.getItem("userRole") ?? "";

  const copyrightSchema = () => {
    return Yup.object().shape({
      copyright: Yup.string().required(`*${t("AppHeader.CopyrightModel.Copyrightisrequired")}`)
    });
  }

  useEffect(() => {
    getCopyright();
    localStorage.removeItem("scroll");
    if (document.getElementById('sidebar')) {
      document.getElementById('sidebar')!.scrollTop = scroll;
    }
  }, []);

  const handleArrowClick = () => {
    getOnlineTools();
    setOpen(!open);
  };

  const getOnlineTools = () => {
    const token = localStorage.getItem("accesToken");
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        "token": `${token}`
      },
    };
    fetch(`${baseURL}/bx_block_admin/online_tools`, requestOptions)
      .then(response => {
        if (response.status === 200) {
          return response.json()
        }
        else {
          throw new Error("Something Went Wrong")
        }
      }
      )
      .then(res => {
        if (res) {
          console.log("res", res)
          setOnlineTools(res?.data)
        }
      }).catch((err) => {
        console.log(err)
      })
  }

  let location = window.location.pathname;
  let adminManagerCondition = userRole === 'manager' || userRole === 'admin' || userRole === 'co_manager';

  const onSaveCopyright = async () => {
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "token": localStorage.getItem("accesToken") ?? ""
      },
      body: JSON.stringify({ copyright: copyright })
    };

    const res = await fetch(`${baseURL}/bx_block_admin/footer_copyrights/${copyrightId}`, requestOptions);
    if (!res.ok) {
      toast.error("Something went wrong!");
      return;
    }
    toast.success("Copyright updated successfully!");
    setDialogName("");
    setShowCopyright(copyright);
  }

  const HandleNavigate = () =>{
    const localdata = localStorage.getItem("userRole")
    if(localdata === "assessor"){
      if(location !== "/AssessorDashboard"){
        window.location.replace("/AssessorDashboard")
      }
    }
    if(localdata === "admin" || localdata === "co_manager" || localdata === "manager"){
      if (location !== "/myproject") {
        window.location.replace("/myproject");
      }
    }
  }

  const getCopyright = async () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      }
    };

    const res = await fetch(`${baseURL}/bx_block_admin/footer_copyrights`, requestOptions);
    if (!res.ok) {
      return;
    }
    const response = await res.json();
    if (Array.isArray(response)) {
      setCopyrightId(response[0].id);
      setShowCopyright(response[0].copyright);
    }
  }

  const handleMenuExpand = () => {
    setOpenSidebar(!openSidebar);
  };

  const handleLogoutModalClose = () => {
    OpenLogoutModal(false);
  };

  const logoutUser = () => {
    Object.keys(localStorage).forEach((item: any) => {
      if (item !== "language") {
        localStorage.removeItem(item);
      }
    })
    window.location.replace("/AdminLogin");
  };

  const onRedirect = () => {
    localStorage.setItem("scroll", scroll.toString());
  };

  const renderSidepanel = () => {
    return (<>
      {adminManagerCondition && <li>
        <CustomLink exact
          className="nav_link"
          activeClassName="active active_item"
          to="/myproject"
          isActive={() => ['myproject', 'EditProject', 'CloneProject'].includes(location.split('/')[1])}
          onClick={() => onRedirect()}
        >
          <div className="nav_name"><div className="nav_name_arrow"><ProjectsSvg /> {openSidebar &&t("appSidebar.menuProjects")}  </div>
            {props.location.pathname === "/myproject" && <KeyboardArrowRight />}
          </div>
        </CustomLink>
      </li>}
      {(adminManagerCondition) && <li>
        <CustomLink exact className="nav_link" activeClassName="active active_item" to="/ManageAssessor" onClick={() => onRedirect()}>
          <div className="nav_name"><div className="nav_name_arrow"><ManageAssessorSvg /> {openSidebar &&t("appSidebar.menuManageAssessors")} </div>
            {props.location.pathname === "/ManageAssessor" && <KeyboardArrowRight />}
          </div>
        </CustomLink>
      </li>}
      {(adminManagerCondition) &&
        <li>
          <CustomAccordion
            onChange={() => setIsAccordionOpen(!isAccordionOpen)}
            expanded={isAccordionOpen}
            className={!!props.openAccordion || isAccordionOpen ? "openAccordion" : "closeAccordion"}
          >
            <AccordionSummary className="nav_name" expandIcon={<ExpandMoreIcon />}>
              <div className="nav_name_arrow">
                <ManageUsersSvg /> {openSidebar &&t("appSidebar.menuManageUsers")}
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <NavLink exact className="nav_link" activeClassName="active active_item" to="/ManageDcManagers" onClick={() => onRedirect()}>
                {openSidebar &&t("appSidebar.menuAddDcmanager")}
              </NavLink>
              <NavLink exact className="nav_link" activeClassName="active active_item" to="/ManageCoDcManagers" onClick={() => onRedirect()}>
                {openSidebar &&t("appSidebar.menuAddCoManager")}
              </NavLink>
            </AccordionDetails>
          </CustomAccordion>
        </li>
      }
      {(adminManagerCondition) && <li>
        <CustomLink exact className="nav_link" activeClassName="active active_item" to="/ManageParticipant" onClick={() => onRedirect()}>
          <div className="nav_name"><div className="nav_name_arrow"> <ManageParticipantsSvg />  {openSidebar &&t("appSidebar.menuManageParticipant")} </div>
            {props.location.pathname === "/ManageParticipant" && <KeyboardArrowRight />}
          </div>
        </CustomLink>
      </li>}
      {(adminManagerCondition) && <li>
        <CustomLink exact className="nav_link" activeClassName="active active_item" to="/ManageClient" onClick={() => onRedirect()}>
          <div className="nav_name"><div className="nav_name_arrow"><ManageClientsSvg />  {openSidebar &&t("appSidebar.menuManageClient")} </div>
            {props.location.pathname === "/ManageClient" && <KeyboardArrowRight />}</div>
        </CustomLink>
      </li>}
      {(adminManagerCondition) && <li>
        <CustomLink exact className="nav_link" activeClassName="active active_item" to="/reports" isActive={() => ['reports', 'projectDetail'].includes(location.split('/')[1])} onClick={() => onRedirect()}>
          <div className="nav_name"><div className="nav_name_arrow"><ReportsSvg />{openSidebar &&t("appSidebar.menuReports")}</div>
            {props.location.pathname === "/reports" && <KeyboardArrowRight />}
          </div>
        </CustomLink>
      </li>}

    </>)
  }

  const renderBottomSidePanel = () => {
    return (
      <>
        {(adminManagerCondition) && <li>
          <CustomLink exact className="nav_link" activeClassName="active active_item" to="/OnlineTool" isActive={() => ['OnlineTool', 'AccessRepository'].includes(location.split('/')[1])} onClick={() => onRedirect()}>
            <div className="nav_name" onClick={() => handleArrowClick()}>
              <div className="nav_name_arrow">
                <List
                  component="nav"
                  aria-labelledby="nested-list-subheader"
                  style={{ height: "auto", width: "100%", padding: 0, margin: 0 }}
                >
                  <ListItem button style={{ padding: 0, margin: 0, width: "100%" }}>
                    <ListItemIcon style={{ minWidth: "30px" }}>
                      <OnlineToolsSvg />
                    </ListItemIcon>
                    <ListItemText primary={openSidebar &&t("appSidebar.menuOnlineTools")} className="nav_name_arrow" style={{ padding: 0, margin: 0, width: "100%" }} />
                  </ListItem>
                  <Collapse in={open} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      {onlineTools?.map((tool: any, index: number) => {
                        return <ListItem button key={`${index + 1}`}>
                          <ListItemText primary={tool?.attributes.name} />
                        </ListItem>
                      })}
                    </List>
                  </Collapse>
                </List>
              </div>
              {props.location.pathname === "/onlineTool" && <KeyboardArrowRight />}
            </div>
          </CustomLink>
        </li>}
        {(adminManagerCondition) && <li>
          <CustomLink exact className="nav_link" activeClassName="active active_item" to="/AssessorLeadTools" onClick={() => onRedirect()}>
            <div className="nav_name"><div className="nav_name_arrow"> <AltSvg />  {openSidebar &&t("appSidebar.menuAlt")}</div>
              {props.location.pathname === "/AssessorLeadTools" && <KeyboardArrowRight />}
            </div>
          </CustomLink>
        </li>}
        {(userRole === 'admin') && <li>
          <CustomAccordion
            onChange={() => setIsOrganizationOpen(!isOrganizationOpen)}
            expanded={isOrganizationOpen}
            className={!!props.openOrganization || isOrganizationOpen ? "openAccordion" : "closeAccordion"}
          >
            <AccordionSummary className="nav_name" expandIcon={<ExpandMoreIcon />}>
              <div className="nav_name_arrow">
                <ManageUsersSvg /> {openSidebar &&t("appSidebar.menuOrgFun")}
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <NavLink exact className="nav_link" activeClassName="active active_item" to="/Sector" onClick={() => onRedirect()}>
              {t("appSidebar.menuSector")}
              </NavLink>
              <NavLink exact className="nav_link" activeClassName="active active_item" to="/Industry" onClick={() => onRedirect()}>
              {t("appSidebar.menuIndustry")}
              </NavLink>
              <NavLink exact className="nav_link" activeClassName="active active_item" to="/Function" onClick={() => onRedirect()}>
              {t("appSidebar.menuFunction")}
              </NavLink>
              <NavLink exact className="nav_link" activeClassName="active active_item" to="/Designation" onClick={() => onRedirect()}>
              {t("appSidebar.menuDesignation")}
              </NavLink>
              <NavLink exact className="nav_link" activeClassName="active active_item" to="/EmploymentType" onClick={() => onRedirect()}>
              {t("appSidebar.menuEmployment")}
              </NavLink>
            </AccordionDetails>
          </CustomAccordion>
        </li>
        }
      </>
    )
  }

  const renderAdminSidebar = () => {
    return (
      <ul
        id="sidebar"
        onScroll={(e) => { setScroll(e?.currentTarget?.scrollTop) }}
      >
        {renderSidepanel()}
        {renderBottomSidePanel()}

        {(userRole === 'admin') && <li>
          <CustomLink exact className="nav_link" activeClassName="active active_item" to="/CreateGlobalSlot" onClick={() => onRedirect()}>
            <div className="nav_name"><div className="nav_name_arrow"> <AltSvg />  {openSidebar &&t("appSidebar.menuCreateGlobalSlot")}</div>
              {props.location.pathname === "/CreateGlobalSlot" && <KeyboardArrowRight />}
            </div>
          </CustomLink>
        </li>}
        {(userRole === 'admin') && <li>
          <CustomLink isActive={() => ["UnavailabilityCalander", "UnavailabilityofAssessor"].includes(props.location.pathname.split('/')[1])} exact className="nav_link unavailability" activeClassName="active active_item" to="/UnavailabilityofAssessor" onClick={() => onRedirect()}>
            <div className="nav_name"><div className="nav_name_arrow"> <UnavailabilityIcon />  {openSidebar &&t("appSidebar.menuUnavailabilityOfAssessor")}</div>
              {props.location.pathname === "/UnavailabilityofAssessor" || props.location.pathname === "/UnavailabilityCalander" && <KeyboardArrowRight />}
            </div>
          </CustomLink>
        </li>}
        {(userRole === 'admin') && <li>
          <CustomLink exact className="nav_link" activeClassName="active active_item" to="/AssignAvailableAssessor" onClick={() => onRedirect()}>
            <div className="nav_name"><div className="nav_name_arrow"> <AssignAvailableIcon />  {openSidebar &&t("appSidebar.menuAssignAvailableAssessor")}</div>
              {props.location.pathname === "/AssignAvailableAssessor" && <KeyboardArrowRight />}
            </div>
          </CustomLink>
        </li>}
        {(adminManagerCondition) && <li>
          <CustomLink exact className="nav_link" activeClassName="active active_item" to="/ManageCompetency" onClick={() => onRedirect()}>
            <div className="nav_name"><div className="nav_name_arrow"><AltSvg />  {openSidebar &&t("appSidebar.menuManageCompetency")}  </div>
              {props.location.pathname === "/ManageCompetency" && <KeyboardArrowRight />}</div>
          </CustomLink>
        </li>}
        {(adminManagerCondition) && <li>
          <CustomLink exact className="nav_link" activeClassName="active active_item" to="/AppAnalytics" onClick={() => onRedirect()}>
            <div className="nav_name"><div className="nav_name_arrow"><AnalyticsSvg />  {openSidebar && t("appSidebar.menuAnalytics")}  </div>
              {props.location.pathname === "/AppAnalytics" && <KeyboardArrowRight />}</div>
          </CustomLink>
        </li>}
        {(userRole === 'admin') && <li>
          <CustomLink exact className="nav_link" activeClassName="active active_item" to="/PsychometricUrl" onClick={() => onRedirect()}>
            <div className="nav_name"><div className="nav_name_arrow"> <AssignAvailableIcon />  {openSidebar && t("appSidebar.menuPsychometricURL")}</div>
              {props.location.pathname === "/PsychometricUrl" && <KeyboardArrowRight />}
            </div>
          </CustomLink>
        </li>}
      </ul>
    );
  }

  const renderAssessorSidebar = () => {
    return (
      <>
        <ul id="sidebar" style={{ marginBottom: "30px" }}>
          <li>{openSidebar ? t("appSidebar.navigation") : ""}</li>
        </ul>
        <ul>
          <li>
            <CustomLink exact className="nav_link" activeClassName="active active_item" to="/AssessorDashboard">
              <div className="nav_name"><div className="nav_name_arrow"><ProjectsSvg />{openSidebar ? t("appSidebar.menuDashboard") : ""}</div>
                {props.location.pathname === "/AssessorDashboard" && <KeyboardArrowRight />}
              </div>
            </CustomLink>
          </li>
          <li>
            <CustomLink exact className="nav_link" activeClassName="active active_item" to="/AssessorCalendar">
              <div className="nav_name"><div className="nav_name_arrow"><ProjectsSvg /> {openSidebar ? t("appSidebar.menuCalendar") : ""}</div>
                {props.location.pathname === "/AssessorCalendar" && <KeyboardArrowRight />}
              </div>
            </CustomLink>
          </li>
          <li>
            <CustomLink exact className="nav_link" activeClassName="active active_item" to="/AssessorProjectDocs">
              <div className="nav_name"><div className="nav_name_arrow"><ProjectsSvg />{openSidebar ? t("appSidebar.menuProjectDoc") : ""}</div>
                {props.location.pathname === "/AssessorProjectDocs" && <KeyboardArrowRight />}
              </div>
            </CustomLink>
          </li>
          <li>
            <CustomLink exact className="nav_link" activeClassName="active active_item" to="/AssessorParticipants">
              <div className="nav_name"><div className="nav_name_arrow"><ProjectsSvg />{openSidebar ? t("appSidebar.menuAssessorParticipant") : ""}</div>
                {props.location.pathname === "/AssessorParticipants" && <KeyboardArrowRight />}
              </div>
            </CustomLink>
          </li>
        </ul>
      </>
    );
  }

  const stringAvatar = (name: string) => {
    return {
      children: name?.indexOf(' ') >= 0 ? `${name?.split(' ')[0][0]?.toUpperCase()}${name?.split(' ')[1][0]?.toUpperCase()}` : `${name?.substring(0, 1)}`,
    };
  }

  const useOnClickOutside = (ref: any, handler: any) => {
    useEffect(() => {
      const listener = (event: any) => {
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }
        handler(event);
      };
      document.addEventListener("mousedown", listener);
      document.addEventListener("touchstart", listener);
      return () => {
        document.removeEventListener("mousedown", listener);
        document.removeEventListener("touchstart", listener);
      };
    }, [ref, handler]);
  }
  useOnClickOutside(ref, () => setIsSettingClicked(false));

  return (
    <div className="adminLayout">
      <div className="sidebarWrapper">
        <div className={`${openSidebar ? "expander" : ""} sideBarContainer`} id="navbar">
          <nav className="nav">
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", overflow: "auto" }}>
              <div className="nav_brand">
                {openSidebar && <img src={shrmLogo} className="nav_logo" onClick={HandleNavigate} />}
                <img
                  src={menu}
                  onClick={handleMenuExpand}
                  className="nav_menu"
                  style={{
                    margin: `${!openSidebar ? "30px 5px" : LanguageData ? "30px 20px 35px 0px" : "30px 20px 35px 137px"}`,
                  }}
                />
              </div>
              {props.type === "admin"
                ? renderAdminSidebar()
                : renderAssessorSidebar()
              }
            </div>
            <div className={openSidebar ? "navFooter" : "navNoFooter"} dir="ltr">
              Version: 1.1
              <br />{showCopyright}
            </div>
          </nav>
        </div>
      </div>
      <div className={openSidebar ? 'adminHeader adminHeaderExpanded webHeader' : `adminHeader adminHeaderCollapsed webHeader`}>
        <div className="currentDate" style={{ display: LanguageData ? "flex": "unset", flexDirection: LanguageData? "row-reverse": "row", alignItems: LanguageData ? "center" : "unset" }}>
          <img src={calendar} alt="" className="calendarIcon" />
          <span className="date">{moment().format("MMMM DD, YYYY")}</span>
        </div>
        <div className="userProfile">
          <div className="notificationBadge">
            <img src={notification} alt="" className="notificationIcon" />
          </div>
          <div className="userAvatar">
            <Avatar {...stringAvatar(userInfo ? userInfo : "User")} alt="User Name" src="/broken-image.jpg" className="helpPage" />
          </div>
          <div className="setting" ref={ref} style={{ justifyContent: LanguageData? "flex-end" : "flex-start" }}>
            <div className="name" onClick={() => setIsSettingClicked(!isSettingClicked)}>
              <span className="helpPage">{userInfo ? userInfo : "User"}</span>
              <KeyboardArrowDownIcon className="downArrowIcon" />{" "}
            </div>
            <div className="avatar">
              <Avatar
                alt="User Name"
                src="/broken-image.jpg"
                onClick={() => setIsSettingClicked(!isSettingClicked)}
              >
                {userInfo.split(" ").map((word) => word[0]).join("").slice(0, 2).toUpperCase()}
              </Avatar>
            </div>
            <div
              className="setting-wrapper"
              style={{
                transition: "opacity 0.2s ease-in-out,visibility 0.2s ease-in-out",
                visibility: isSettingClicked ? "visible" : "hidden",
                opacity: isSettingClicked ? "1" : "0",
                right: LanguageData ? "unset" : "31px"
              }}
            >
              <div className="box" style={{ width: "180px" }}>
                {userRole === 'admin' && <button onClick={() => { setDialogName("Footer"); setCopyright(showCopyright) }}>{t("AppHeader.logout.Settings")}</button>}
                <button onClick={() => OpenLogoutModal(true)}>{t("AppHeader.logout.btn")}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="responsiveHeader adminHeader">
        <div className="leftarea">
          <img src={shrm} className={"companylogoImage"} />
          <img src={menu} onClick={handleMenuExpand} className="nav_menu" />
        </div>
        <div className="userProfile">
          <div className="notificationBadge">
            <Badge
              overlap="rectangular"
              badgeContent={1}
              invisible={true}
              max={999}
            >
              <img src={notificationWhite} alt="" className="notificationIcon" />
            </Badge>
          </div>
        </div>
      </div>
      <Modal
          open={logoutModal}
          onClose={handleLogoutModalClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className="modalStyle logoutModal">
            <div
              className="closeImg"
              onClick={handleLogoutModalClose}
            >
              <CloseIcon />
            </div>
            <Typography
              className="logoutModalTitle helpModalTitle"
              variant="h6"
              component="h2"
            >
              {t("AppHeader.logout.header")}
            </Typography>
            <Typography className="centerText" variant="h6" component="h4">
              {t("AppHeader.logout.text")}
            </Typography>
            <div className="parent-btn">
              <Button className="cancelBtn" onClick={handleLogoutModalClose}>{t("AppHeader.logout.cancel")}</Button>
              <Button className="submitBtn" onClick={logoutUser}>{t("AppHeader.logout.yes")}</Button>
            </div>
          </Box>
        </Modal>

      {/* for footer */}
      <Dialog
        open={openDialogName === "Footer"}
        fullWidth={false}
        maxWidth={"sm"}
        scroll="body"
        transitionDuration={0}
        onClose={() => { }}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        PaperProps={{
          style: {
            width: "500px"
          }
        }}
      >
        <DialogContent style={{ padding: "0px", width: "100%" }}>
          <Box style={{ textAlign: "right", margin: "10px 10px 0px 0px" }}>
            <CloseIcon onClick={() => setDialogName("")} style={{ cursor: "pointer", fontSize: "35px", color: "#787878" }} />
          </Box>
          <Box padding={{ xs: "15px 25px 25px", sm: "15px 35px 36px" }}>
            <Box
              id="modal-title"
              fontWeight="bolder"
              fontSize={{ xs: "16px", sm: "24px" }}
              fontFamily="ProximaNova-Bold"
              mb="20px"
              whiteSpace={{ xs: "wrap", md: "nowrap" }}
            >
              {t("AppHeader.CopyrightModel.CopyrightIntellectualPropertyRights")}
            </Box>
            <Formik
              initialValues={{
                copyright: copyright
              }}
              validationSchema={copyrightSchema}
              onSubmit={(values) => onSaveCopyright()}
            >
              {({ errors, touched, setFieldValue, handleSubmit }) => (
                <form
                  onSubmit={handleSubmit}
                  autoComplete="off"
                  noValidate
                >
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <div style={{ marginBottom: "5px", fontSize: "15px" }}>
                      {t("AppHeader.CopyrightModel.Copyright")}<span style={{ color: "red" }}>*</span>
                      </div>
                      <CustomInputBase
                        fullWidth
                        multiline
                        placeholder="Copyright"
                        value={copyright}
                        onChange={(event: any) => {
                          setFieldValue("copyright", event.target.value);
                          setCopyright(event.target.value);
                        }}
                      />
                      {touched.copyright && errors.copyright &&
                        <div className="text-danger">{errors.copyright}</div>
                      }
                    </Grid>
                  </Grid>
                  <Box mt="30px" justifyContent="start" display={{ xs: "block", sm: "flex" }}>
                    <CancelButton onClick={() => setDialogName("")}>{t("AppHeader.CopyrightModel.Cancel")}</CancelButton>
                    <SaveButton type="submit">{t("AppHeader.CopyrightModel.Save")}</SaveButton>
                  </Box>
                </form>
              )}
            </Formik>
          </Box>
        </DialogContent>
      </Dialog>
      <Box className={openSidebar ? "mb360" : "mb110"}>
        {props.children}
      </Box>
    </div>
  );
}

export default withRouter(AppSidebar);

const CustomAccordion = styled(Accordion)({
  boxShadow: "none",
  marginBottom: "1rem",
  borderRadius: "0.5rem !important",
  "&:hover": {
    backgroundColor: "#fff !important",
    color: "#1b3c69 !important",
    "& svg": {
      fill: "#1b3c69"
    }
  },
  "& .MuiAccordionSummary-root": {
    paddingRight: "0px",
    minHeight: "44px",
    paddingLeft: "9px"
  },
  "&.Mui-expanded:last-child": {
    marginBottom: "1rem !important"
  },
  "& .MuiAccordionDetails-root": {
    display: "flex",
    flexDirection: "column",
    marginLeft: "20px",
    color: "#1b3c69",
    padding: "8px 16px 6px 16px",
    paddingTop: "0px"
  },
  "& .MuiAccordionSummary-content.Mui-expanded": {
    margin: "10px 0px 0px",
    "& svg": {
      fill: "#1b3c69"
    }
  },
  "& .MuiAccordionSummary-expandIcon.Mui-expanded": {
    marginTop: "9px",
    "& svg": {
      fill: "#1b3c69"
    }
  },
  "& .MuiIconButton-root": {
    padding: "3px",
  },
  "& .MuiSvgIcon-root": {
    fill: "#fff"
  },
  "& .nav_name_arrow svg": {
    marginRight: "10px",
    fill: "#fff"
  },
  "& .nav_name svg": {
    marginRight: "10px",
    fill: "#fff"
  },

  "& .nav_link": {
    fontSize: "15px",
    padding: "7px 10px",
    marginBottom: "5px",
    fontFamily: "ProximaNova-Light"
  },
  "& .nav_link.active": {
    fontFamily: "ProximaNova-SemiBold"
  },
  "& .nav_link:hover": {
    fontFamily: "ProximaNova-SemiBold"
  }
});

const CustomLink = styled(NavLink)({
  boxShadow: "none",
  marginBottom: "1rem",
  borderRadius: "0.5rem !important",
  "&:hover": {
    backgroundColor: "#fff !important",
    color: "#1b3c69 !important",
    "& svg": {
      fill: "#1b3c69",
      "& g": {
        stroke: "#1b3c69",
        fill: "#1b3c69",
        strokeWidth: "0.5"
      },
    }
  },
  "&.active": {
    backgroundColor: "#fff !important",
    color: "#1b3c69 !important",
    "& svg": {
      fill: "#1b3c69",
      "& g": {
        stroke: "#1b3c69",
        fill: "#1b3c69",
        strokeWidth: "0.5"
      },
    }
  },
  "&.unavailability.active": {
    backgroundColor: "#fff !important",
    color: "#1b3c69 !important",
    "& svg path": {
      fill: "#1b3c69",
    }
  },
  "&.unavailability:hover": {
    backgroundColor: "#fff !important",
    color: "#1b3c69 !important",
    "& svg path": {
      fill: "#1b3c69",
    }
  },
  "& .MuiAccordionSummary-root": {
    paddingRight: "0px",
    minHeight: "44px",
    paddingLeft: "9px"
  },
  "&.Mui-expanded:last-child": {
    marginBottom: "1rem !important"
  },
  "& .MuiAccordionDetails-root": {
    display: "flex",
    flexDirection: "column",
    marginLeft: "20px",
    color: "#1b3c69",
    padding: "8px 16px 6px 16px",
    paddingTop: "0px"
  },
  "& .MuiIconButton-root": {
    padding: "3px",
  },
  "& .MuiSvgIcon-root": {
    fill: "#fff"
  },
  "& .nav_name svg": {
    marginRight: "10px",
    fill: "#fff"
  },

  "& .nav_link": {
    fontSize: "15px",
    padding: "7px 10px",
    marginBottom: "5px",
    fontFamily: "ProximaNova-Light"
  },
  "& .nav_link.active": {
    fontFamily: "ProximaNova-SemiBold"
  },
  "& .nav_link:hover": {
    fontFamily: "ProximaNova-SemiBold"
  }
});

const CustomInputBase = styled(InputBase)({
  border: "1px solid #c7b9b9",
  borderRadius: "6px",
  minHeight: "45px",
  paddingLeft: "15px",
  fontSize: "16px",
  '& .MuiInputBase-inputMultiline': {
    "&::placeholder": {
      fontSize: "17px",
    }
  }
});

const SaveButton = styled(Button)({
  fontSize: "18px",
  color: "white",
  backgroundColor: "#f94b4b",
  borderRadius: "10px",
  fontFamily: "ProximaNova-Semibold",
  textTransform: "none",
  padding: "7px 27px",
  "&:hover": {
    backgroundColor: "#f94b4b",
    color: "white",
  },
  "@media(max-width:600px)": {
    padding: "6px 24px"
  }
});

const CancelButton = styled(Button)({
  backgroundColor: "white",
  color: "#f26a42",
  border: "1px solid #f26a42",
  padding: "5px 18px",
  fontFamily: "ProximaNova-Semibold",
  marginRight: "15px",
  borderRadius: "8px",
  textTransform: "none",
  fontSize: "18px",
  "&:hover": {
    color: "#f26a42",
    backgroundColor: "white",
  },
  "@media(max-width:600px)": {
    padding: "5px 15px",
  }
});
// Customizable Area End
