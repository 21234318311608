// Customizable Area Start
import React from "react";
import {
    Box,
    Button,
    Grid,
    Checkbox,
    TextField,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Dialog,
    DialogContent
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import { styled } from "@material-ui/styles";
import Select, { components } from "react-select";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';
import moment from "moment";
import * as Yup from "yup";
import { Formik } from "formik";
import { withTranslation } from "react-i18next";
import CreateGlobalSlotController, { Props } from "./CreateGlobalSlotController.web";
import AppSidebar from "../../../../components/src/AppSidebar.web";
import Spinner from "../../../../components/src/Spinner.web";
const LanguageData = localStorage.getItem("language") === "ar";

const editIcon = require("../../assets/edit.png");
const deleteIcon = require("../../assets/delete.png");

class CreateGlobalSlot extends CreateGlobalSlotController {
    constructor(props: Props) {
        super(props);
    }

    slotSchema = () => {
        return Yup.object().shape({
            startTime: Yup.string().required(this.t("createGlobalSlot.errorMessages.startTime")),
            endTime: Yup.string()
                .test("is-greater", this.t("createGlobalSlot.errorMessages.greaterThen"), function (value) {
                    const { startTime } = this.parent;
                    if (startTime && value) {
                        return moment(value, "HH:mm").isSameOrAfter(moment(startTime, "HH:mm"));
                    }
                    return true;
                })
                .test("is-greater-30-min", this.t("createGlobalSlot.errorMessages.greaterThanHalfHour"), function (value) {
                    const { startTime } = this.parent;
                    if (startTime && value) {
                        return moment(value, "HH:mm").diff(moment(startTime, "HH:mm"), "minute") >= 30;
                    }
                    return true;
                })
                .test("is-greater-1-hour", this.t("createGlobalSlot.errorMessages.greaterThanHour"), function (value) {
                    const { startTime } = this.parent;
                    if (startTime && value) {
                        return moment(value, "HH:mm").diff(moment(startTime, "HH:mm"), "minute") <= 60;
                    }
                    return true;
                })
                .required(this.t("createGlobalSlot.errorMessages.endTime")),
            assessorIds: Yup.array()
                .test('assessor', this.t("createGlobalSlot.errorMessages.assessor"),
                    (obj: any) => {
                        return obj.length !== 0;
                    }
                )
        });
    }

    TableBodyRender = () => {
        return (
            <TableBody>
                {this.state.data.length > 0 ?
                    <>
                        {this.state.data.map((item: any, index: number) => (
                            <TableRow key={`${index + 1}`}>
                                <TableCell align={LanguageData ? "right" : "left"} style={{ fontFamily: "ProximaNova-Regular", fontSize: "18px", color: "#000" }}>{item.startTime} to {item.endTime}</TableCell>
                                <TableCell align={LanguageData ? "left" : "right"} style={{ fontFamily: "ProximaNova-Regular", fontSize: "18px", color: "#000" }}>
                                    <img src={editIcon} id="edit" style={{ cursor: "pointer", margin: "0 10px" }} onClick={() => this.setState({ slotId: item.id, startTime: moment(item.startTime, 'hh:mm A').format('HH:mm'), endTime: moment(item.endTime, 'hh:mm A').format('HH:mm'), assessorIds: item.assessorIds, previousAssessorIds: item.assessorIds, openDialogName: "Edit" })} />
                                    <img src={deleteIcon} id="delete" style={{ cursor: "pointer" }} onClick={() => this.setState({ slotId: item.id, startTime: item.startTime, endTime: item.endTime, openDialogName: "Delete" })} />
                                </TableCell>
                            </TableRow>
                        ))}
                    </>
                    : <TableRow>
                        <TableCell colSpan={6} align="center" style={{ fontFamily: "ProximaNova-Regular", fontSize: "18px", color: "#000", whiteSpace: "nowrap" }}>{this.t("createGlobalSlot.label.notFound")}</TableCell>
                    </TableRow>
                }
            </TableBody>
        )
    }

    SelectAssessorRender = (setFieldValue: any, touched: any, errors: any) => {
        return (
            <Grid item xs={12}>
                <div style={{ marginBottom: "5px", fontSize: "18px", textAlign: LanguageData ? "right" : "left" }}>
                    {this.t("createGlobalSlot.label.selectAssessor")}<span style={{ color: "red" }}>*</span>
                </div>
                <Select
                    classNamePrefix="react-select"
                    className="checkbox-dropdown"
                    placeholder={this.t("createGlobalSlot.placeholder.selectAssessor")}
                    options={this.state.assessorList}
                    isMulti={true}
                    allowSelectAll={true}
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    components={{ Option }}
                    value={
                        this.state.assessorList.filter((option: any) =>
                            !!this.state.assessorIds.find((ele: string) => ele === option.value)
                        )
                    }
                    openMenuOnClick={true}
                    onChange={(event: any) => {
                        let ids = [];
                        if (event) {
                            ids = event.map((obj: any) => {
                                return obj.value;
                            });
                        }
                        setFieldValue("assessorIds", ids);
                        this.setState({ assessorIds: ids });
                    }}
                />
                {touched.assessorIds && errors.assessorIds &&
                    <div className="text-danger" style={{ textAlign: LanguageData ? "right" : "left" }}>{errors.assessorIds}</div>
                }
            </Grid>
        )
    }
    EndTimeRender = (setFieldValue: any, touched: any, errors: any) => {
        return (
            <Grid item xs={12}>
                <div style={{ marginBottom: "5px", fontSize: "18px", textAlign: LanguageData ? "right" : "left" }}>
                    {this.t("createGlobalSlot.label.endTime")}<span style={{ color: "red" }}>*</span>
                </div>
                <CustomTextField
                    fullWidth
                    type="time"
                    value={this.state.endTime}
                    onChange={(event: any) => {
                        setFieldValue("endTime", event.target.value);
                        this.setState({ endTime: event.target.value });
                    }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    inputProps={{
                        step: 300,
                    }}
                />
                {touched.endTime && errors.endTime &&
                    <div className="text-danger" style={{ textAlign: LanguageData ? "right" : "left" }}>{errors.endTime}</div>
                }
            </Grid>
        )
    }
    StartTimeRender = (setFieldValue: any, touched: any, errors: any) => {
        return (
            <Grid item xs={12}>
                <div style={{ marginBottom: "5px", fontSize: "18px", textAlign: LanguageData ? "right" : "left" }}>
                    {this.t("createGlobalSlot.label.startTime")}<span style={{ color: "red" }}>*</span>
                </div>
                <CustomTextField
                    fullWidth
                    type="time"
                    value={this.state.startTime}
                    onChange={(event: any) => {
                        setFieldValue("startTime", event.target.value);
                        this.setState({ startTime: event.target.value });
                    }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    inputProps={{
                        step: 300,
                    }}
                />
                {touched.startTime && errors.startTime &&
                    <div className="text-danger" style={{ textAlign: LanguageData ? "right" : "left" }}>{errors.startTime}</div>
                }
            </Grid>
        )
    }

    CustomDialogRender = () => {
        return (
            <CustomDialog
                open={["Add", "Edit"].includes(this.state.openDialogName ?? "")}
                fullWidth={false}
                maxWidth={"sm"}
                scroll="body"
                transitionDuration={0}
                onClose={() => { }}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogContent style={{ padding: "0px", width: "100%" }}>
                    <Box style={{ textAlign: "right", margin: "10px 10px 0px 0px" }}>
                        <CloseIcon onClick={() => this.setState({ slotId: null, startTime: "", endTime: "", assessorIds: [], openDialogName: null })} style={{ cursor: "pointer", fontSize: "35px", color: "#787878" }} />
                    </Box>
                    <Box padding={{ xs: "15px 25px 25px", sm: "15px 40px 40px" }}>
                        <Box
                            id="modal-title"
                            fontWeight="bolder"
                            fontSize={{ xs: "20px", sm: "30px" }}
                            fontFamily="ProximaNova-Bold"
                            mb="20px"
                            color="#1b3c69"
                            style={{ textAlign: LanguageData ? "right" : "left" }}
                        >
                            {this.t(`createGlobalSlot.title.${this.state.openDialogName}`)}
                        </Box>
                        <Formik
                            initialValues={{
                                startTime: this.state.startTime,
                                endTime: this.state.endTime,
                                assessorIds: this.state.assessorIds
                            }}
                            validationSchema={this.slotSchema}
                            onSubmit={(values) => {
                                this.state.openDialogName === "Add"
                                    ? this.addGlobalSlot()
                                    : this.editGlobalSlot()
                            }}
                        >
                            {({ errors, touched, setFieldValue, handleSubmit }) => (
                                <form
                                    onSubmit={handleSubmit}
                                    noValidate
                                    autoComplete="off"
                                >
                                    <Grid container spacing={3}>
                                        {this.StartTimeRender(setFieldValue, touched, errors)}
                                        {this.EndTimeRender(setFieldValue, touched, errors)}
                                        {this.SelectAssessorRender(setFieldValue, touched, errors)}
                                    </Grid>
                                    <Box mt="30px" justifyContent="end" display={{ xs: "block", sm: "flex" }}>
                                        <CustomButton className="cancelBtn" onClick={() => this.setState({ slotId: null, startTime: "", endTime: "", assessorIds: [], openDialogName: null })}>{this.t("createGlobalSlot.button.cancel")}</CustomButton>
                                        <RedButton style={{ marginRight: LanguageData ? "15px" : "unset" }} type="submit">{this.t(`createGlobalSlot.button.${this.state.openDialogName}`)}</RedButton>
                                    </Box>
                                </form>
                            )}
                        </Formik>
                    </Box>
                </DialogContent>
            </CustomDialog>
        )
    }

    DeleteModelRender = () => {
        return (
            <Dialog
                open={this.state.openDialogName === "Delete"}
                fullWidth={false}
                maxWidth={"sm"}
                scroll="body"
                transitionDuration={0}
                onClose={() => { }}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogContent style={{ padding: "0px", maxWidth: "500px" }}>
                    <Box style={{ paddingTop: "0px !important", textAlign: "right", margin: "10px 10px 0px 0px" }}>
                        <CloseIcon onClick={() => this.setState({ slotId: null, startTime: "", endTime: "", openDialogName: null })} style={{ cursor: "pointer", fontSize: "35px", color: "#787878" }} />
                    </Box>
                    <Box display="flex" alignItems="center" flexDirection="column" padding={{ xs: "15px 25px 25px", sm: "15px 70px 40px" }}>
                        <Box
                            id="modal-title"
                            fontWeight="bolder"
                            fontSize={{ xs: "20px", sm: "30px" }}
                            fontFamily="ProximaNova-Bold"
                            mb="20px"
                        >
                            {this.t("createGlobalSlot.title.delete")}
                        </Box>
                        <Box textAlign="center" fontSize="18px">
                            {this.t("createGlobalSlot.description.delete", { startTime: this.state.startTime, endTime: this.state.endTime })}
                        </Box>
                        <Box mt="30px" display="flex">
                            <CustomButton className="cancelBtn" onClick={() => this.setState({ slotId: null, startTime: "", endTime: "", openDialogName: null })} style={{ marginBottom: "0px", padding: "5px 10px" }}>{this.t("createGlobalSlot.button.cancel")}</CustomButton>
                            <RedButton onClick={this.deleteGlobalSlot} style={{ padding: "5px 10px", marginRight: LanguageData ? "15px" : "unset" }}>{this.t("createGlobalSlot.button.delete")}</RedButton>
                        </Box>
                    </Box>
                </DialogContent>
            </Dialog>
        )
    }

    render() {
        return (
            <CreateGlobalRender style={{ fontFamily: "ProximaNova-Regular" }}>
                {this.state.loading && <Spinner spinnerModal={this.state.loading} />}
                <AppSidebar type="admin">
                    <Box margin="35px 0px" mr="30px">
                        <Box display={{ xs: "block", sm: "flex" }} justifyContent="space-between" mb="40px">
                            <Box><Box mb="5px" fontFamily="ProximaNova-Bold" fontSize={{ xs: "24px", sm: "30px", md: "44px" }} color="#1b3c69">{this.t("createGlobalSlot.title.main")}</Box><Box color="#1b3c69" fontSize={{ xs: "14px", sm: "20px" }}>{this.t("createGlobalSlot.description.main")}</Box></Box>
                            <Box mt={{ xs: "20px", sm: "0px" }}>
                                <RedButton onClick={() => this.setState({ openDialogName: "Add" })} style={{ backgroundColor: "#f26a42" }}>{this.t("createGlobalSlot.button.Add")}</RedButton>
                            </Box>
                        </Box>
                        <Box mt="60px"><Box><Grid container>
                            <Grid item xs={12} className={LanguageData ? "SearchIconRightSide" : ""}>
                                <Box mb="25px" display="flex" justifyContent="end" alignItems="center">
                                    <TextField
                                        id="search"
                                        InputLabelProps={{
                                            style: {
                                                fontFamily: "ProximaNova-Regular",
                                                zIndex: 0
                                            }
                                        }}
                                        onChange={(event: any) => {
                                            this.onChangeHandler(event.target.value, 0);
                                        }}
                                        label={this.t("createGlobalSlot.label.search")}
                                        variant="outlined"
                                        InputProps={{
                                            endAdornment: <SearchIcon />
                                        }}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} style={{ overflow: "auto" }}>
                                <Table aria-label="simple table" style={{ border: "1px solid #E0E0E0", borderRadius: "10px", borderCollapse: "separate" }} className="custom-table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align={LanguageData ? "right" : "left"} style={{ fontFamily: "ProximaNova-Bold", fontSize: "16px" }}>{this.t("createGlobalSlot.label.globalSlot")}</TableCell>
                                            <TableCell align={LanguageData ? "left" : "right"} style={{ fontFamily: "ProximaNova-Bold", fontSize: "16px" }}>{this.t("createGlobalSlot.label.action")}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    {this.TableBodyRender()}
                                </Table>
                                {this.state.data.length > 0 &&
                                    <div className="custom-pagination">
                                        <Pagination
                                            page={this.state.page + 1}
                                            count={Math.ceil(this.state.dataLength / this.state.rowsPerPage)}
                                            showLastButton
                                            siblingCount={0}
                                            boundaryCount={1}
                                            dir="ltr"
                                            variant="outlined"
                                            shape="rounded"
                                            showFirstButton
                                            onChange={(event: unknown, newPage: number) => {
                                                this.onChangeHandler(this.state.search, newPage - 1);
                                            }}
                                        />
                                    </div>
                                }
                            </Grid>
                        </Grid>
                        </Box>
                        </Box>
                    </Box>
                </AppSidebar>

                {/* for add/edit user */}
                {this.CustomDialogRender()}

                {/* for delete */}
                {this.DeleteModelRender()}
            </CreateGlobalRender>
        );
    }
}

export default withTranslation()(CreateGlobalSlot);

const Option = (props: any) => {
    return (
        <div>
            <components.Option {...props}>
                <Checkbox
                    disableRipple
                    icon={<div className="unchecked" />}
                    checkedIcon={<div className="checked"><DoneIcon /></div>}
                    checked={props.isSelected}
                    onChange={() => null}
                />
                <label>{props.label}</label>
            </components.Option>
        </div>
    );
};

const CustomDialog = styled(Dialog)({
    "& .MuiDialog-paper": {
        overflowY: "unset",
        width: "100%"
    },
    "& .MuiDialogContent-root": {
        overflowY: "unset"
    }
});

const CreateGlobalRender = styled('div')({
    '& .SearchIconRightSide': {
        '& .MuiInputBase-root': {
            display: "flex",
            flexDirection: "row-reverse"
        }
    }
})

const CustomTextField = styled(TextField)({
    border: "1px solid #d6d6d6",
    borderRadius: "10px",
    minHeight: "45px",
    fontSize: "16px",
    outline: "none",
    '& .MuiInputBase-inputMultiline': {
        "&::placeholder": {
            fontSize: "17px",
        }
    },
    '& .MuiInput-underline:before': {
        border: "none"
    },
    '& .MuiInputBase-input': {
        padding: "11px 0px 13px 15px"
    },
    '& .MuiInput-underline:after': {
        border: "none"
    },
    '& .MuiInputBase-root': {
        border: "none"
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
        border: "none"
    }
});

const CustomButton = styled(Button)({
    backgroundColor: "white",
    color: "#f26a42",
    border: "1px solid #f26a42",
    fontFamily: "ProximaNova-Semibold",
    marginRight: "15px",
    borderRadius: "8px",
    fontSize: "18px",
    textTransform: "none",
    padding: "7px 17px",
    "&:hover": {
        color: "#f26a42",
        backgroundColor: "white",
    },
    "@media(max-width:600px)": {
        width: "100%",
    },
    "@media(max-width:992px)": {
        padding: "7px 15px",
    }
});

const RedButton = styled(Button)({
    backgroundColor: "#f94b4b",
    color: "white",
    borderRadius: "10px",
    fontFamily: "ProximaNova-Semibold",
    fontSize: "18px",
    textTransform: "none",
    padding: "9px 30px",
    "&:hover": {
        color: "white",
        backgroundColor: "#f94b4b",
    },
    "@media(max-width:600px)": {
        width: "100%",
        marginTop: "27px",
    }
});
// Customizable Area End