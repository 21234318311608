// Customizable Area Start
import React from "react";
import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  InputBase,
  Dialog,
  DialogContent,
} from "@material-ui/core";
import * as Yup from "yup";
import Pagination from "@material-ui/lab/Pagination";
import { styled } from "@material-ui/styles";
import { withRouter } from "react-router-dom";
import Select, { components } from "react-select";
import CloseIcon from "@material-ui/icons/Close";
import { Formik } from "formik";
import AppSidebar from "../../../components/src/AppSidebar.web";
import ProjectsController, { Props } from "./ProjectsController.web";
import Accordion from "@material-ui/core/Accordion";
import Tooltip from '@material-ui/core/Tooltip';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Spinner from "../../../components/src/Spinner.web";
import BorderColorOutlinedIcon from "@material-ui/icons/BorderColorOutlined";
import AddIcon from '@material-ui/icons/Add';
import InsertDriveFileOutlined from '@material-ui/icons/InsertDriveFileOutlined';
import ReactQuill from "react-quill";
import CloudUploadOutlined from "@material-ui/icons/CloudUploadOutlined";
import HighlightOffOutlinedIcon from "@material-ui/icons/HighlightOffOutlined";
import EditIconForSection from '@material-ui/icons/Edit';
import AccordionSummary from "@material-ui/core/AccordionSummary";
export const uploadIcon = require("../assets/upload.png");
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
export const calender = require("../assets/calender.png");
import DoneIcon from "@material-ui/icons/Done";
const LanguageData = localStorage.getItem("language") === "ar";
import { toast } from "react-toastify";
import { withTranslation } from "react-i18next";
import { editIcon } from "./assets";
import Dropzone from 'react-dropzone';

class CreateNewProject extends ProjectsController {
  constructor(props: Props) {
    super(props);
  }

  projectTypeListCreate = [
    {
      label: "Hybrid",
      value: "hybrid",
    },
    {
      label: "Virtual",
      value: "virtual",
    },
    {
      label: "Assessor",
      value: "assessor",
    },
  ];

  negativeMarkingListCreate = [
    {
      label: "0.5",
      value: "0.5",
    },
    {
      label: "1",
      value: "1",
    },
    {
      label: "1.5",
      value: "1.5",
    },
    {
      label: "2",
      value: "2",
    },
  ];

  incrementList = [
    {
      label: "0.5",
      value: "0.5",
    },
    {
      label: "1",
      value: "1",
    },
  ];

  sortByList = [
    { label: "Status", value: "status" },
    { label: "Project", value: "name" },
  ];

  async componentDidMount(): Promise<void> {
    this.getQuestionsData();
    this.getFilteredClientsData();
    this.getAssessorData();
    this.getCoManagersData();
    this.getManagersData();
    this.getCompetencyData("/");
  }

  imageHandleChange = async (e: any, props: any) => {
    if (e.target.files[0].size > 50 * 1024 * 1024) {
      toast.warn(this.t("ProjectsController.Uploadvideowithsizelessthan50MB"))
    } else {
      if (e.target.files[0].type.split('/')[0] !== "image") {
        toast.warn(this.t("CreateNewProject.AddCompanysection.toast.UploadValid", { value: "image" }))
      } else {
        props.setFieldValue('uploadFile', [await this.getBase64(e.target.files[0])])
      }
    }
  }
  videoHandleChange = async (e: any, props: any) => {
    if (e.target.files[0].size > 50 * 1024 * 1024) {
      toast.warn(this.t("ProjectsController.Uploadvideowithsizelessthan50MB"))
    } else {
      if (e.target.files[0].type.split('/')[0] !== "video") {
        toast.warn(this.t("CreateNewProject.AddCompanysection.toast.UploadValid", { value: 'video' }))
      } else {
        props.setFieldValue('uploadFile', [await this.getBase64(e.target.files[0])])
      }
    }
  }
  sectionHandleClick = () => {
    this.setState({
      AddParentContentModel: "EditParent",
      CompanyModel: !this.state.CompanyModel,
    })
  }
  sectionsHandleClick = (index: number) => {
    this.setState({
      EditSectionIndex: index,
      CompanyModel: !this.state.CompanyModel,
      SectionNumber: index + 1
    })
  }
  closeHandleClick = () => {
    this.setState({
      CompanyModel: !this.state.CompanyModel,
      AddParentContentModel: ""
    })
  }
  handleSubmits = (values: any) => {
    this.setState({
      ParentContentData: [values],
      AddParentContentModel: ""
    })
    if (this.state.CompanySection.length !== 0) {
      this.setState({
        CompanyModel: false
      })
    }
  }
  ratingCloseClick = () => {
    this.setState({
      ViewFileModel: !this.state.ViewFileModel,
      AddParentContentModel: ""
    })
  }
  cancelBtnClick = () => {
    this.setState({
      CompanyModel: false,
      AddParentContentModel: ""
    })
  }
  createProjectSubmit = (values: any) => {
    if (this.state.projectType === "assessor") {
      this.createProjectHandle(values);
    } else {
      if (this.state.finalQuetionForApi.length === 0) {
        toast.warn(
          this.t("ProjectsController.PleaseAddQuestions")
        );
      } else {
        this.SubmitApiCall(values);
      }
    }
  }
  projectSubmit = (values: any) => {
    if (this.state.ParentContentData.length === 0 || this.state.CompanySection.length === 0) {
      if (this.state.ParentContentData.length === 0) {
        toast.warn(this.t("CreateNewProject.AddCompanysection.toast.addsection"))
      }
      if (this.state.CompanySection.length === 0) {
        toast.warn(this.t("CreateNewProject.AddCompanysection.toast.AddSubSection"))
      }
    } else {
      this.createProjectSubmit(values)
    }
  }
  createNewProjectSchema = () => {
    return Yup.object().shape({
      project_name: Yup.string().required(
        this.t("CreateNewProject.createNewProjectSchema.Projectname")
      ),
      project_type: Yup.string().required(
        this.t("CreateNewProject.createNewProjectSchema.Projecttype")
      ),
      assessor_id: Yup.string().when(
        "project_type",
        (project_type: any, schema: any) => {
          if (project_type !== "virtual")
            return schema.required(
              this.t("CreateNewProject.createNewProjectSchema.Assessor")
            );
          return schema;
        }
      ),
      client_id: Yup.string().required(
        this.t("CreateNewProject.createNewProjectSchema.Client")
      ),
      manager_id: Yup.string().required(
        this.t("CreateNewProject.createNewProjectSchema.Manager")
      ),
      coManager_id: Yup.string().required(
        this.t("CreateNewProject.createNewProjectSchema.Comanager")
      ),
      start_date: Yup.string().required(
        this.t("CreateNewProject.createNewProjectSchema.Startdate")
      ),
      competency_id: Yup.string().when(
        "project_type",
        (project_type: any, schema: any) => {
          if (project_type !== "virtual")
            return schema.required(
              this.t("CreateNewProject.createNewProjectSchema.Competency")
            );
          return schema;
        }
      ),
      end_date: Yup.date().when(
        ["start_date"],
        (start_date: any, schema: any) => {
          if (start_date) {
            const dayAfter = new Date(
              new Date(start_date).getTime() + 86400000
            );
            return schema
              .min(
                dayAfter,
                "*" +
                this.t(
                  "CreateNewProject.createNewProjectSchema.EndDateLessThenStartDate"
                )
              )
              .required(
                "*" +
                this.t(
                  "CreateNewProject.createNewProjectSchema.PleaseSelectDate"
                )
              );
          }
          return schema.required(
            "*" +
            this.t("CreateNewProject.createNewProjectSchema.PleaseSelectDate")
          );
        }
      ),
      negative_marks: Yup.string().required(
        this.t("CreateNewProject.createNewProjectSchema.NegativeMarkRequired")
      ),
      pdfFile: Yup.array().required(
        this.t("CreateNewProject.createNewProjectSchema.DocumentsRequired")
      ),
    });
  };

  renderButtonContainer = () => {
    return (
      <Box className="ButtonContainer">
        {this.state.tools.sort((a: any, b: any) => { return a.attributes.name.toLowerCase().localeCompare(b.attributes.name.toLowerCase()) }).map((tool: any, index: any) => {
          return (
            <Box key={tool.id}>
              <Button
                onClick={() => this.handleTool(tool)}
                data-testid={"toolNameBtn-" + tool.id}
                style={{ textTransform: "capitalize" }}
                className={
                  this.state.selectedTools
                    .map((item: any) => item.id)
                    .includes(tool.id)
                    ? "SelectBtn"
                    : "UnSelectBtn"
                }
              >
                {tool.attributes.name}
              </Button>
            </Box>
          );
        })}
      </Box>
    );
  };
  renderGrid1 = (props: any) => {
    return (
      <Grid item xs={12} sm={6}>
        <Typography
          className="Projectlabel"
          variant="body2"
          style={{ marginBottom: "5px" }}
        >
          {this.t("CreateNewProject.TextField.SelectClient")}
          <span style={{ color: "red" }}>*</span>
        </Typography>
        <Select
          classNamePrefix="react-select"
          placeholder={this.t("CreateNewProject.TextField.SelectClient")}
          isSearchable
          options={this.state.clientsList}
          value={
            this.state.clientsList.filter(
              (option: any) => option.value === this.state.client_id
            )[0]
          }
          id="SelectClient"
          onChange={(event: any) => {
            props.setFieldValue("client_id", event.value);
            this.setState({ client_id: event.value });
          }}
        />
        {this.getErrorMessage(props.touched, props.errors, "client_id")}
      </Grid>
    );
  };
  renderGrid2 = (props: any) => {
    return (
      <Grid item xs={12} sm={6}>
        <Typography
          className="Projectlabel"
          variant="body2"
          style={{ marginBottom: "5px" }}
        >
          {this.t("CreateNewProject.TextField.StartDate")}
          <span style={{ color: "red" }}>*</span>
        </Typography>
        <DatePicker
          id="start_date"
          name="start_date"
          selected={props.values.start_date}
          onChange={(date: any) => {
            props.setFieldValue("start_date", date);
          }}
          onBlur={props.handleBlur}
          peekNextMonth
          customInput={<CustomInput />}
          showMonthDropdown
          showYearDropdown
          minDate={new Date()}
          dropdownMode="select"
          placeholderText="DD/MM/YYYY HH:MM a"
          data-testid="startDate"
          timeInputLabel="Time:"
          dateFormat="dd/MM/yyyy h:mm aa"
          showTimeInput
        />
        {this.getErrorMessage(props.touched, props.errors, "start_date")}
      </Grid>
    );
  };
  renderGrid3 = (props: any) => {
    return (
      <Grid item xs={6}>
        <Box>
          <Typography
            className="Projectlabel"
            variant="body2"
            style={{ marginBottom: "5px" }}
          >
            {this.t("CreateNewProject.TextField.EndDate")}
            <span style={{ color: "red" }}>*</span>
          </Typography>
          <DatePicker
            id="end_date"
            name="end_date"
            selected={props.values.end_date}
            onChange={(date: any) => {
              props.setFieldValue("end_date", date);
            }}
            onBlur={props.handleBlur}
            peekNextMonth
            customInput={<CustomInput />}
            showMonthDropdown
            showYearDropdown
            minDate={new Date()}
            dropdownMode="select"
            placeholderText="DD/MM/YYYY HH:MM a"
            data-testid="end_date"
            timeInputLabel="Time:"
            dateFormat="dd/MM/yyyy h:mm aa"
            showTimeInput
          />
          {this.getErrorMessage(props.touched, props.errors, "end_date")}
        </Box>
      </Grid>
    );
  };
  renderGrid = (props: any) => {
    return (
      <Grid item xs={12} sm={6}>
        <Typography
          className="Projectlabel"
          variant="body2"
          style={{ marginBottom: "5px" }}
        >
          {this.t("CreateNewProject.TextField.SelectAssessor")}
          <span style={{ color: "red" }}>*</span>
        </Typography>
        <Select
          id='assessorIds-select'
          data-testid='select-assessorIds'
          classNamePrefix="react-select"
          placeholder={this.t("CreateNewProject.TextField.SelectAssessor")}
          isMulti={true}
          isSearchable={true}
          isDisabled={this.state.projectType === "virtual" ? true : false}
          options={this.state.assessorList}
          value={
            this.state.assessorList.filter(
              (option: any) => option.value === this.state.assessor_id
            )[0]
          }
          onChange={(event: any) => {
            let assessorIds = [];
            if (event) {
              assessorIds = event.map((obj: any) => {
                return obj.value;
              });
            }
            props.setFieldValue("assessor_id", assessorIds);
            this.setState({ assessor_id: assessorIds });
          }}
        />
        {this.getErrorMessage(props.touched, props.errors, "assessor_id")}
      </Grid>
    );
  };

  imagechangeTrueCreate = (ans: any) => {
    return ans.attributes.MediaType.includes("video") ? (
      <div className="videoRender">
        <video controls>
          <source src={ans.attributes.image_url.url} type="video/mp4" />
        </video>
      </div>
    ) : (
      <img
        height="200px"
        width="100%"
        alt="true"
        src={ans.attributes.image_url.url}
      />
    );
  };

  imagechangefalseCreate = (ans: any) => {
    return ( ans.attributes.image_url?.url !== null &&
    ans.attributes.image_url?.url !== "undefined" &&
    typeof ans.attributes.image_url === "object" &&
    ans.attributes.image_url.type?.includes("video") ? (
      <video width="100%" height="200px" controls>
        <source src={ans.attributes.image_url.url} type="video/mp4" />
      </video>
    ) : (
      ans.attributes.image_url.url !== null && (
        <img
          width="100%"
          height="200px"
          src={ans.attributes.image_url.url}
          alt="flase"
        />
      )
    ));
  };

  renderGrid4 = (props: any) => {
    return (
      <Grid container spacing={2}>
        {this.state.EditedAnswers?.map((ans: any, ansIndex: number) => {
          let color = ans?.attributes?.correct_answer ? "#dff8d1" : "";
          return (
            <Grid item key={ans?.id} style={{ margin: "20px 0" }} xs={6}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <label
                  style={{
                    marginBottom: "10px",
                  }}
                  className="AddOption"
                  data-testid={ansIndex + "createproject"}
                >
                  {this.t("CreateNewProject.TextField.AddOption")}{" "}
                  {String.fromCharCode(65 + ansIndex)}
                </label>
                {ans.attributes.image_url?.url !== null && (
                  <div>
                    {color &&
                      <Checkbox
                        style={{ color: "#057605" }}
                        disabled
                        checked={true}
                      />
                    }
                    <Button
                      variant="outlined"
                      size="small"
                      className="redButton"
                      component="label"
                    >
                      {this.t("CreateNewProject.button.Edit")}{" "}
                      {String.fromCharCode(65 + ansIndex)}
                      <input
                        type="file"
                        className="videoUpload"
                        onChange={(e: any) =>
                          this.HandelImageChange(e, ansIndex, ans)
                        }
                        accept={
                          ans.attributes.image_url.type?.includes("video")
                            ? "video/*"
                            : "image/*"
                        }
                        style={{ display: "none" }}
                        id={`${ans?.id}`}
                        data-testid='file-image'
                      />
                    </Button>
                  </div>
                )}
              </div>
              {ans.attributes.imageChange
                ? this.imagechangeTrueCreate(ans)
                : this.imagechangefalseCreate(ans)}
              {ans.attributes.image_url.url === null && (
                <TextField
                  fullWidth
                  onChange={(e) => this.handleAnswer(e, ansIndex)}
                  style={{ backgroundColor: color }}
                  value={ans?.attributes?.answer}
                  variant="outlined"
                  className="QuetionTextField"
                />
              )}
            </Grid>
          );
        })}
      </Grid>
    );
  };

  renderBox = (props: any, toolsList: any) => {
    return (
      <Box style={{ marginTop: "3rem" }}>
        <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            {this.state.selectedQuestions
              ?.slice(
                this.state.page * this.state.rowsPerPage,
                this.state.page * this.state.rowsPerPage +
                this.state.rowsPerPage
              )
              .map((items: any, index: any) => {
                return (
                  <div
                    className="ContainerQuetion"
                    key={items?.id}
                    data-testid={items?.id}
                  >
                    <Accordion
                      expanded={this.state.isExpendQue === items?.id}
                      style={{
                        backgroundColor: "#eff6ff",
                        marginBottom: "10px",
                      }}
                    >
                      <AccordionSummary
                        expandIcon={false}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                        className="panel1bh-header"
                      >
                        <div className="AcorenHeading">
                          <div className="labelAcordin" style={{ backgroundColor: `${items?.attributes?.online_tool?.tool_color === null ? "#ffd9d8" : items?.attributes?.online_tool?.tool_color}` }}>
                            <h5>{items?.attributes?.online_tool?.name}</h5>
                          </div>
                          <div className="checkBoxAndQueHeading">
                            <div className="InputContainer">
                              <Checkbox
                                checked={this.state.finalQuetionForApi
                                  .map((item: any) => Number(item.id))
                                  .includes(Number(items.id))}
                                onChange={(event: any) =>
                                  this.handleCheck(event, items)
                                }
                                inputProps={{
                                  "aria-label": "Checkbox demo",
                                }}
                              />
                            </div>
                            {this.state.isExpendQue === items?.id ? (
                              <Grid container>
                                <Grid
                                  item
                                  xs={12}
                                  style={{ marginTop: "20px" }}
                                >
                                  <div className="MarksContainer">
                                    <h5>
                                      {this.t(
                                        "CreateNewProject.TextField.Question"
                                      )}
                                    </h5>
                                    {items?.attributes?.mark !== null && (
                                      <h5>
                                        {this.t(
                                          "CreateNewProject.TextField.Mark"
                                        )}{" "}
                                        {items?.attributes?.mark}
                                      </h5>
                                    )}
                                  </div>
                                  <TextField
                                    fullWidth
                                    onChange={(e: any) => this.ChnageQueText(e)}
                                    style={{ width: "100%" }}
                                    variant="outlined"
                                    className="QuetionTextField"
                                    value={this.state.QuestionText}
                                  />
                                </Grid>
                              </Grid>
                            ) : (
                              <div className="TextandButton">
                                <div className="QuetionRender">
                                  <div>
                                    <h3
                                      style={{
                                        verticalAlign: "middle",
                                        alignSelf: "center",
                                      }}
                                    >
                                      {this.t(
                                        "CreateNewProject.TextField.Question"
                                      )}{" "}
                                      {20 * (this.state.page + 1 - 1) +
                                        index +
                                        1}
                                    </h3>
                                  </div>
                                  <div className="quetions">
                                    <h4>{items?.attributes?.name}</h4>
                                  </div>
                                </div>
                                <div style={{ display: "grid", placeItems: "center" }}>
                                  <Button
                                    onClick={() => this.AcordinOpen(items)}
                                    style={{
                                      marginRight: "15px",
                                      minWidth: "130px",
                                      border: "1px solid #f94b4c",
                                      borderRadius: "10px",
                                      color: "#f94b4c",
                                      textTransform: "capitalize"
                                    }}
                                  >
                                    {this.t("CreateNewProject.button.View")}
                                  </Button>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container>
                          <Grid item style={{ margin: "20px 0" }} xs={6}>
                            <label>
                              {this.t(
                                "CreateNewProject.TextField.SelectOnlineTool"
                              )}
                            </label>
                            <Select
                              options={toolsList}
                              onChange={(e) => {
                                this.toolIdchnage(e);
                              }}
                              value={
                                toolsList.filter(
                                  (item: any) =>
                                    item.value ===
                                    this.state.CurrentEditQuetion?.attributes?.online_tool_id.toString()
                                )[0]
                              }
                              classNamePrefix="react-select"
                              placeholder={this.t(
                                "CreateNewProject.TextField.SelectOnlineTool"
                              )}
                              isSearchable={false}
                            />
                          </Grid>
                          {this.renderGrid4(props)}
                          <Grid container>
                            <Grid
                              item
                              style={{
                                margin: "20px 0",
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                              xs={12}
                            >
                              <Button
                                onClick={this.editQuestion}
                                style={{
                                  marginRight: "20px",
                                  backgroundColor: "#f94b4c",
                                  color: "white",
                                  textTransform: "capitalize"
                                }}
                              >
                                {this.t("CreateNewProject.button.Edit")}
                              </Button>
                              <Button
                                onClick={() => this.AcordinOpen(items)}
                                style={{
                                  marginRight: "20px",
                                  backgroundColor: "#bebebe",
                                  color: "white",
                                  textTransform: "capitalize"
                                }}
                              >
                                {this.t("CreateNewProject.button.Cancel")}
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                );
              })}
          </Grid>
        </Grid>
      </Box>
    );
  }

  rednerProjectName = (props: any) => {
    return (
      <Grid item xs={6}>
        <Typography
          className="Projectlabel"
          variant="body2"
          style={{ marginBottom: "5px" }}
        >
          {this.t("CreateNewProject.TextField.ProjectName")}
          <span style={{ color: "red" }}>*</span>
        </Typography>
        <Box className="textfield-parent">
          <TextField
            name="project_name"
            id="project_name"
            value={props.values.project_name}
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            placeholder={this.t("CreateNewProject.TextField.EnterProjectName")}
            className="client-textfields"
            variant="outlined"
            fullWidth
          />
          {this.getErrorMessage(props.touched, props.errors, "project_name")}
        </Box>
      </Grid>
    );
  };

  ProjectTypeRedner = (props: any) => {
    return (
      <Grid item xs={6}>
        <Box>
          <Typography
            variant="body2"
            className="Projectlabel"
            style={{ marginBottom: "5px" }}
          >
            {this.t("CreateNewProject.TextField.ProjectType")}
            <span style={{ color: "red" }}>*</span>
          </Typography>
          <Select
            name="project_type"
            classNamePrefix="react-select"
            placeholder={this.t("CreateNewProject.TextField.SelectProjectType")}
            isSearchable={false}
            options={this.projectTypeListCreate}
            onChange={(event: any) => {
              props.setFieldValue("project_type", event.value);
              this.toolsApicall(event.value);
              this.setState({ projectType: event.value });
            }}
          />
          {this.getErrorMessage(props.touched, props.errors, "project_type")}
        </Box>
      </Grid>
    );
  };

  DcMangerRender = (props: any) => {
    return (
      <Grid item xs={6}>
        <Typography
          className="Projectlabel"
          variant="body2"
          style={{ marginBottom: "5px" }}
        >
          {this.t("CreateNewProject.TextField.DCManager")}
          <span style={{ color: "red" }}>*</span>
        </Typography>
        <Select
          classNamePrefix="react-select"
          placeholder={this.t("CreateNewProject.TextField.DCManager")}
          isSearchable
          options={this.state.managerList}
          value={
            this.state.managerList.filter(
              (option: any) => option.value === this.state.manager_id
            )[0]
          }
          onChange={(event: any) => {
            props.setFieldValue("manager_id", event.value);
            this.setState({ manager_id: event.value });
          }}
        />
        {this.getErrorMessage(props.touched, props.errors, "manager_id")}
      </Grid>
    );
  };

  CoManagerRender = (props: any) => {
    return (
      <Grid item xs={12} sm={6}>
        <Typography
          className="Projectlabel"
          style={{ marginBottom: "5px" }}
          variant="body2"
        >
          {this.t("CreateNewProject.TextField.CoManager")}
          <span data-testid="Co-ManagerCreate" style={{ color: "red" }}>
            *
          </span>
        </Typography>
        <Select
          placeholder={this.t("CreateNewProject.TextField.CoManager")}
          classNamePrefix="react-select"
          isSearchable
          options={this.state.coManagerList}
          value={
            this.state.coManagerList.filter(
              (option: any) => option.value === this.state.coManager_id
            )[0]
          }
          onChange={(event: any) => {
            props.setFieldValue("coManager_id", event.value);
            this.setState({ coManager_id: event.value });
          }}
        />
        {this.getErrorMessage(props.touched, props.errors, "coManager_id")}
      </Grid>
    );
  };

  uploadDocumentRender = (props: any) => {
    return (
      <Grid item xs={6}>
        <Box>
          <Typography
            className="Projectlabel"
            variant="body2"
            style={{ marginBottom: "5px" }}
          >
            {this.t("CreateNewProject.TextField.UploadDocument")}
          </Typography>
          <Box className="dragElement"
            style={{ width: '100%' }}>
            <Dropzone onDrop={(acceptedFiles) => this.onDrop(acceptedFiles, props)} multiple={true} >
              {({ getRootProps, getInputProps }) => (
                <div className="dropzone">
                  <div {...getRootProps()} className="dropzone-content">
                    <input {...getInputProps()}
                      data-testid="creaetedfileupload"
                      type="file"
                      name="pdf"
                      id="pdfUpload"
                      multiple
                      accept="application/pdf"
                      style={{ display: "none" }} />
                    <label htmlFor="pdfUpload"
                      style={{ width: "100%" }}
                      className={this.state.dragActive ? "drag-active label-file-upload" : "label-file-upload"}>
                      <Box textAlign="center" className='customUploadArea'>
                        <div data-testid="centerTextcretae"><CloudUploadOutlined />
                          <Box mt="15px">{this.t("CreateNewProject.TextField.DropOrBrowseYourFileHere")}</Box></div>
                      </Box>
                    </label>
                  </div>
                </div>
              )}
            </Dropzone>
          </Box>
          <Grid alignItems="flex-start" container spacing={1}>
            {this.state.pdfFile?.map((fileObj: any, index: any) => {
              return (
                <div key={`${index + 1}`} className="viewUploadedFiles">
                  <a
                    href={fileObj?.file}
                    target="_blank"
                    style={{ cursor: "pointer" }}
                  >
                    {fileObj?.name}
                  </a>
                  <HighlightOffOutlinedIcon
                    id='delete'
                    data-testid='delete'
                    onClick={() => {
                      this.removeFile(index,fileObj,props);
                    }}
                    titleAccess="Delete"
                    style={{
                      cursor: "pointer",
                      marginLeft: "7px",
                      height: "15px",
                      width: "15px",
                      color: "white",
                      verticalAlign: "middle",
                    }}
                  />
                </div>
              );
            })}
          </Grid>
          {this.getErrorMessage(props.touched, props.errors, "pdfFile")}
        </Box>
      </Grid>
    );
  };

  RenderRating = () => {
    return (
      <Grid item xs={12} sm={6}>
        <Box>
          <Typography variant="body2" style={{ marginBottom: "5px" }}>
            {this.t("CreateNewProject.TextField.Rating")}
            <span style={{ color: "red" }}>*</span>
          </Typography>
          <Select
            name="rating"
            id='rating-id'
            className="rating-dropdown"
            classNamePrefix="react-select"
            isSearchable={false}
            value={
              this.state.setIncrement === "0.5"
                ? this.state.rangeHalfData.filter(
                  (option: any) => option.value == this.state.rating
                )[0]
                : this.state.rangeOneData.filter(
                  (option: any) => option.value == this.state.rating
                )[0]
            }
            options={
              this.state.setIncrement === "0.5"
                ? this.state.rangeHalfData
                : this.state.rangeOneData
            }
            onChange={(event: any) => {
              this.setState({ rating: event.value });
            }}
          />
        </Box>
      </Grid>
    );
  };

  AddParentsectionuploadSchema = () => {
    return Yup.object().shape({
      heading: Yup.string().required(this.t("CreateNewProject.sectionuploadSchema.Headingisrequired")),
      content: Yup.string().required(this.t("CreateNewProject.sectionuploadSchema.Contentisrequired")),
    });
  };

  incrementHandleChange = (event: any) => {
    this.setState({
      setIncrement: event.value,
      rating:
        event.value === "0.5"
          ? this.state.rangeHalfData[0]?.value
          : this.state.rangeOneData[0]?.value,
    });
  }
  sectionuploadSchema = () => {
    return Yup.object().shape({
      heading: Yup.string().required(this.t("CreateNewProject.sectionuploadSchema.Headingisrequired")),
      title: Yup.string().required(this.t("CreateNewProject.sectionuploadSchema.Titleisrequired")),
      content: Yup.string().required(this.t("CreateNewProject.sectionuploadSchema.Contentisrequired")),
      uploadFile: Yup.array().required(this.t("CreateNewProject.sectionuploadSchema.UploadFileisrequired")),
    });
  };
  sectionHandleChange = () => {
    this.setState({ SectionNumber: this.state.CompanySection.length + 1, CompanyModel: true })
  }
  AddCompanySection = () => {
    return (
      <Grid container data-testid="AddCompanySectionCreate">
        {this.state.CompanySection.length === 0 && this.state.ParentContentData.length === 0 ?
          <Grid item xs={12}>
            <div className="CompanysectionMainDiv" onClick={() => this.setState({
              CompanyModel: !this.state.CompanyModel,
              AddParentContentModel: "EditParent"
            })}>
              <AddIcon /><Typography style={{ fontWeight: "bold" }}>{this.t("CreateNewProject.AddCompanysection.AddCompanysectionText")}</Typography>
            </div>
          </Grid> :
          <Grid item xs={12} data-testid="AddCompanySectionCreate1">
            <div className="SectionContainer">
              <div className="SectionHeading">
                <div style={{ fontWeight: "bolder" }}>
                  <Typography>{this.t("CreateNewProject.AddCompanysection.CompanyDetails")}</Typography>
                </div>
                <div style={{ display: 'flex' }}>
                  <Button className="addnewsection" id='#sectionHandleClick' data-testid='sectionHandleClick' style={{ marginRight: '10px' }} onClick={() => this.sectionHandleClick()}>
                    <EditIconForSection />
                    <Typography data-testid="AddCompanySectionCreate2">{this.t("CreateNewProject.AddCompanysection.EditParentDetails")}</Typography>
                  </Button>
                  <Button
                    className="addnewsection" onClick={() => this.sectionHandleChange()}>
                    <Typography>
                      {this.t("CreateNewProject.AddCompanysection.AddCompanySection")}
                    </Typography>
                  </Button>
                </div>
              </div>
              {this.state.ParentContentData.map((item: any, index: any) => {
                return (
                  <div data-testid="AddCompanySectionCreate3" className="AllSection" key={`${item.heading + index + 1}`}>
                    <div className="SectionHeading">
                      <div>
                        <Typography variant="h4">
                          {item.heading}
                        </Typography>
                      </div>
                    </div>
                    <div style={{ marginLeft: "30px" }} data-testid="AddCompanySectionCreate4">
                      <Typography variant="subtitle1" dangerouslySetInnerHTML={this.HtmlConverter(
                        item.content
                      )} />
                    </div>
                  </div>
                )
              })}
              {this.state.CompanySection.map((item: any, index: any) => {
                return (
                  this.CompanySectionMapCreate(item, index)
                )
              })}
            </div>
          </Grid>
        }
        {this.ViewFileModel()}
      </Grid>
    )
  }

  CompanySectionMapCreate = (item: any, index: any) => {
    return (
      <div className="AllSection" data-testid="AddCompanySectionCreate5" key={`${item.heading + index + 1}`}>
        <div className="SectionHeading">
          <div>
            <Typography variant="h4">
              {item.heading}
            </Typography>
          </div>
          <Button className="addnewsection" style={{ textDecoration: "none" }} onClick={() =>
            this.sectionsHandleClick(index)
          }>
            <EditIconForSection />
            <Typography data-testid="AddCompanySectionCreate6">
              {this.t("CreateNewProject.AddCompanysection.EditDetails")}
            </Typography>
          </Button>
        </div>
        <div>
          <Typography variant="h5">
            {item.title}
          </Typography>
        </div>
        <div style={{ marginLeft: "30px" }}>
          <Typography data-testid="AddCompanySectionCreate7" variant="subtitle1" dangerouslySetInnerHTML={this.HtmlConverter(
            item.content
          )} />
        </div>
        <div className="MediaContainer">
          <div className="AllMediaSection">
            {item.uploadFile.map((fileRender: any, index: any) =>
              <Tooltip data-testid='OpenSectionFiles-id' title={this.t('ProjectsController.ViewFile')} key={fileRender} placement="bottom" onClick={() => this.OpenSectionFiles(fileRender)}>
                {fileRender.includes("video") ? <PlayCircleOutlineIcon style={{ width: "60px", height: "60px" }} /> :
                  <img style={{ width: "60px", height: "60px" }} src={fileRender} alt={`${fileRender + index + 1}`} />
                }
              </Tooltip>
            )}
          </div>
          <div style={{ display: 'flex', alignItems: 'flex-end' }} data-testid="AddCompanySectionCreat8">
            <Button className="addnewsection" data-test-id="addnewsection" id="addnewsection" style={{ color: "#fa6c7d", paddingBottom: '0px' }} onClick={() => this.RemoveSection(item)} >
              <Typography>
                {this.t("CreateNewProject.AddCompanysection.RemoveSection")}
              </Typography>
            </Button>
          </div>
        </div>
      </div>
    )
  }

  AddCompanyModel = () => {
    return (
      <Dialog
        open={this.state.CompanyModel}
        scroll="body"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        maxWidth={"lg"}
        fullWidth={false}
        className="setScoreRating"
        PaperProps={{
          style: {
            width: "100%",
          },
        }}
        transitionDuration={0}
      >
        <DialogContent style={{ padding: "0px", width: "100%" }} data-testid="AddCompanySectionCreate9">
          {this.state.AddParentContentModel === "EditParent" &&
            this.ParentSection()
          }
          {this.state.ParentContentData.length !== 0 && this.state.AddParentContentModel !== "EditParent" &&
            this.SubSection()
          }
        </DialogContent>
      </Dialog>
    )
  }

  ParentSection = () => {
    return (
      <StyleNewPRoject>
        <Box
          style={{
            textAlign: "right",
            margin: "10px 10px 0px 0px",
          }}
        >
          <CloseIcon
            onClick={() => this.closeHandleClick()}
            style={{
              cursor: "pointer",
              fontSize: "35px",
              color: "#787878",
            }}
          />
        </Box>
        <Box data-testid="AddCompanySectionCreate10" padding={{ xs: "15px 25px 25px", sm: "15px 40px 40px" }}>
          <Box
            id="modal-title"
            color="#111"
            fontSize={{ xs: "20px", sm: "30px" }}
            fontFamily="ProximaNova-Bold"
            mb="20px"
            style={{ fontFamily: "ProximaNova-Regular" }}
            fontWeight="bolder"
          >
            {this.t("CreateNewProject.AddCompanysection.ParentSection")}
          </Box>
          <Formik
            validationSchema={this.AddParentsectionuploadSchema}
            onSubmit={(values, { setSubmitting }) => this.handleSubmits(values)}
            validateOnChange={true}
            validateOnBlur={true}
            initialValues={{
              heading: this.state.ParentContentData[0]?.heading || "",
              content: this.state.ParentContentData[0]?.content || ""
            }}
          >
            {(props) => (
              <form
                id="my-form"
                onSubmit={props.handleSubmit}
                noValidate
                autoComplete="off"
                data-testid="AddCompanySectionCreate11"
              >
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12}>
                    <Box>
                      <Typography
                        variant="body2"
                        style={{ marginBottom: "5px", fontFamily: "ProximaNova-Regular" }}
                      >
                        {this.t("CreateNewProject.AddCompanysection.Heading")}
                      </Typography>
                      <TextField
                        onBlur={props.handleBlur}
                        className="client-textfields"
                        id="heading"
                        name="heading"
                        fullWidth
                        value={props.values.heading}
                        placeholder={
                          this.t("CreateNewProject.AddCompanysection.PlaceholderHeading")
                        }
                        onChange={props.handleChange}
                        variant="outlined"
                      />
                    </Box>
                    {this.getErrorMessage(
                      props.touched,
                      props.errors,
                      "heading"
                    )}
                  </Grid>
                </Grid>
                <Grid container spacing={3} data-testid="AddCompanySectionCreate11">
                  <Grid item xs={12}>
                    <Typography
                      variant="body2"
                      style={{ marginBottom: "5px", fontFamily: "ProximaNova-Regular" }}
                    >
                      {this.t("CreateNewProject.AddCompanysection.HTML")}
                      <span style={{ color: "red" }}>*</span>
                    </Typography>
                    <div data-testid="AddCompanySectionCreate1234">
                      <ReactQuill
                        className="reactQuill"
                        placeholder={this.t("CreateNewProject.AddCompanysection.PlaceholderHTML")}
                        theme="snow"
                        onChange={(e: any) => props.setFieldValue('content', e)}
                        modules={{
                          toolbar: [
                            [{ header: [1, 2, 3, 4, 5, 6, 7] }],
                            ["bold", "italic", "underline", "strike", "blockquote",],
                            [{ list: "ordered" }, { list: "bullet" },
                            { indent: "-1" }, { indent: "+1" },], ["link"], ["clean"],],
                        }}
                        formats={[
                          "header", "bold",
                          "italic", "underline", "strike",
                          "blockquote", "list", "bullet",
                          "indent", "link", "image",
                        ]}
                        id="content"
                        value={props.values.content}
                      />
                    </div>
                    {this.getErrorMessage(props.touched, props.errors, "content")}
                  </Grid>
                </Grid>
                <Box mt="30px" display="flex" justifyContent="end" data-testid="AddCompanySectionCreate2332">
                  <CancelButton
                    className="cancelBtn"
                    style={{
                      marginLeft: LanguageData ? "10px" : ""
                    }}
                    onClick={() =>
                      this.cancelBtnClick()
                    }
                  >
                    {this.t("CreateNewProject.button.Cancel")}
                  </CancelButton>
                  <SaveButton form="my-form" type="submit">
                    {this.t("CreateNewProject.button.SAVE")}
                  </SaveButton>
                </Box>
              </form>
            )}
          </Formik>
        </Box>
      </StyleNewPRoject>
    )
  }

  SubSection = () => {
    return (
      <StyleNewPRoject>
        <Box
          style={{
            textAlign: "right",
            margin: "10px 10px 0px 0px",
          }}
          data-testid="AddCompanySectionCreate14"
        >
          <CloseIcon
            onClick={() =>
              this.setState({
                CompanyModel: !this.state.CompanyModel,
              })
            }
            style={{
              cursor: "pointer",
              fontSize: "35px",
              color: "#787878",
            }}
          />
        </Box>
        <Box data-testid="AddCompanySectionCreate15"
          padding={{ xs: "15px 25px 25px", sm: "15px 40px 40px" }}
        >
          <Box
            id="modal-title"
            color="#111"
            fontSize={{ xs: "20px", sm: "30px" }}
            fontFamily="ProximaNova-Bold"
            style={{ fontFamily: "ProximaNova-Regular" }}
            mb="20px"
            fontWeight="bolder"
          >
            {this.t("CreateNewProject.AddCompanysection.Section")} {this.state.CompanySection.length === 0 ? 1 : this.state.SectionNumber}
          </Box>
          <Formik
            validationSchema={this.sectionuploadSchema}
            initialValues={{ heading: this.state.CompanySection[this.state.EditSectionIndex]?.heading || "", title: this.state.CompanySection[this.state.EditSectionIndex]?.title || "", content: this.state.CompanySection[this.state.EditSectionIndex]?.content || "", uploadFile: this.state.CompanySection[this.state.EditSectionIndex]?.uploadFile || [], }} validateOnBlur={true}
            onSubmit={(values, { setSubmitting }) => {
              this.AddDatainSections(values)
            }}
            validateOnChange={true}
          >
            {(props) => (
              <form
                id="my-form"
                onSubmit={props.handleSubmit}
                noValidate
                autoComplete="off"
                onDragEnter={this.handleDrag} onDragOver={this.handleDrag}
              >
                <Grid container spacing={3} data-testid="AddCompanySectionCreate16">
                  <Grid item xs={12} sm={6}>
                    <Box>
                      <Typography
                        variant="body2"
                        style={{ marginBottom: "5px", fontFamily: "ProximaNova-Regular" }}
                      >
                        {this.t("CreateNewProject.AddCompanysection.Heading")}
                      </Typography>
                      <TextField
                        name="heading"
                        id="heading"
                        value={props.values.heading}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        placeholder={this.t("CreateNewProject.AddCompanysection.PlaceholderHeading")}
                        className="client-textfields"
                        variant="outlined"
                        fullWidth
                      />
                    </Box>
                    {this.getErrorMessage(
                      props.touched,
                      props.errors,
                      "heading"
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6} data-testid="AddCompanySectionCreate18">
                    <Box>
                      <Typography
                        variant="body2"
                        style={{ marginBottom: "5px", fontFamily: "ProximaNova-Regular" }}
                      >
                        {this.t("CreateNewProject.AddCompanysection.Title")}
                      </Typography>
                      <TextField
                        name="title"
                        id="title"
                        value={props.values.title}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        placeholder={this.t("CreateNewProject.AddCompanysection.PlaceholderTitle")}
                        className="client-textfields"
                        variant="outlined"
                        fullWidth
                      />
                    </Box>
                    {this.getErrorMessage(
                      props.touched,
                      props.errors,
                      "title"
                    )}
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Typography
                      variant="body2"
                      style={{ marginBottom: "5px", fontFamily: "ProximaNova-Regular" }}
                      data-testid="AddCompanySectionCreate343"
                    >
                      {this.t("CreateNewProject.AddCompanysection.HTML")}
                      <span style={{ color: "red" }}>*</span>
                    </Typography>
                    <div>
                      <ReactQuill
                        placeholder={this.t("CreateNewProject.AddCompanysection.PlaceholderHTML")}
                        theme="snow"
                        onChange={(e: any) => props.setFieldValue('content', e)}
                        modules={{
                          toolbar: [
                            [{ header: [1, 2, 3, 4, 5, 6, 7] }],
                            [
                              "bold",
                              "italic",
                              "underline",
                              "strike",
                              "blockquote",
                            ],
                            [
                              { list: "ordered" },
                              { list: "bullet" },
                              { indent: "-1" },
                              { indent: "+1" },
                            ],
                            ["link"],
                            ["clean"],
                          ],
                        }}
                        formats={[
                          "header",
                          "bold",
                          "italic",
                          "underline",
                          "strike",
                          "blockquote",
                          "list",
                          "bullet",
                          "indent",
                          "link",
                          "image",
                        ]}
                        id="content"
                        value={props.values.content}
                      />
                    </div>
                    {this.getErrorMessage(
                      props.touched,
                      props.errors,
                      "content"
                    )}
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={5}>
                    <Typography
                      variant="body2"
                      style={{ marginBottom: "5px", fontFamily: "ProximaNova-Bold", fontWeight: "bold" }}
                      data-testid="AddCompanySectidsdsdsonCreate"
                    >
                      {this.t("CreateNewProject.AddCompanysection.UploadImage")}
                      <span style={{ color: "red" }}>*</span>
                    </Typography>
                    <Button className="dragElement" disabled={props.values.uploadFile.length !== 0} style={{ width: '100%' }}>
                      <input
                        data-testid="creaetedfileuploadcompanyfdfdfd"
                        type="file"
                        accept="image/*"
                        name="ImageUpload"
                        id="ImageUpload"
                        style={{ display: "none" }}
                        onChange={async (e: any) => {
                          this.imageHandleChange(e, props)
                        }}
                        onBlur={props.handleBlur}
                      />
                      <label htmlFor="ImageUpload" style={{ width: "100%" }} className={this.state.dragActive ?
                        "drag-active label-file-upload" : "label-file-upload"}>
                        <Box textAlign="center"
                          className='customUploadAreaCompany'>
                          <div
                            style={{
                              display: 'flex', alignItems: 'center',
                              textTransform: "capitalize"
                            }}>
                            <InsertDriveFileOutlined /> <Box style={{ fontWeight: "bold" }}>
                              {this.t("CreateNewProject.TextField.DropOrBrowseYourFileHere")}
                            </Box>
                          </div>
                        </Box>
                      </label>
                      {this.state.dragActive && <div
                        className="drag-file-element"
                        onDragEnter={(event) => this.handleDrag(event)}
                        data-testid="createprojectdarg12"
                        onDragLeave={(event) => this.handleDrag(event)} onDragOver={(event) => this.handleDrag(event)} onDrop={(event) => this.handleDrop(event, props, "image")}></div>}
                    </Button>
                    {this.getErrorMessage(
                      props.touched,
                      props.errors,
                      "uploadFile"
                    )}
                  </Grid>
                  <Grid item xs={2} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}><Typography variant="body2" style={{ display: "flex", alignItems: "center", justifyContent: "center" }} data-testid="AddCompanySectidsdsdsonCreates">{this.t("ProjectsController.OR")}</Typography></Grid>
                  <Grid item xs={5}>
                    <Typography variant="body2" style={{ marginBottom: "5px", fontFamily: "ProximaNova-Bold", fontWeight: "bold" }} >{this.t("CreateNewProject.AddCompanysection.UploadVideo")}<span style={{ color: "red" }}>*</span></Typography>
                    <Button className="dragElement" disabled={props.values.uploadFile.length !== 0} style={{ width: '100%' }}>
                      <input
                        data-testid="creaetedfileuploadcompanyfdfdfd"
                        type="file"
                        accept="video/*"
                        name="VideoUpload"
                        id="VideoUpload"
                        style={{ display: "none" }}
                        onChange={async (e: any) => {
                          this.videoHandleChange(e, props)
                        }}
                        onBlur={props.handleBlur}
                      />
                      <label htmlFor="VideoUpload" style={{ width: "100%" }} className={this.state.dragActive ? "drag-active label-file-upload" : "label-file-upload"}>
                        <Box textAlign="center" className='customUploadAreaCompany'>
                          <div style={{ display: 'flex', alignItems: 'center', textTransform: "capitalize" }}>
                            <InsertDriveFileOutlined />
                            <Box style={{ fontWeight: "bold" }}>
                              {this.t("CreateNewProject.TextField.DropOrBrowseYourFileHere")}
                            </Box>
                          </div>
                        </Box>
                      </label>
                      {this.state.dragActive && <div className="drag-file-element" data-testid="draganddropcreate12"
                        onDragEnter={(event) => this.handleDrag(event)} onDragLeave={(event) => this.handleDrag(event)}
                        onDragOver={(event) => this.handleDrag(event)} onDrop={(event) => this.handleDrop(event, props, 'video')}>
                      </div>}
                    </Button>
                    {this.getErrorMessage(props.touched, props.errors, "uploadFile")}
                  </Grid>
                  <Grid item xs={12}>
                    {props.values.uploadFile.length !== 0 &&
                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <SaveButton data-test-id="ViewUploadedFile-id" className="ViewUploadedFile" onClick={() => this.OpenSectionFiles(props.values.uploadFile[0])}>
                          {this.t("CreateNewProject.AddCompanysection.ViewUploadedFile")}
                        </SaveButton>
                        <SaveButton data-test-id="DeleteUploadedFile-id" onClick={() => props.setFieldValue('uploadFile', [])} >
                          {this.t("CreateNewProject.AddCompanysection.DeleteUploadedFile")}
                        </SaveButton>
                      </div>
                    }
                  </Grid>
                </Grid>
                <Box mt="30px" display="flex" justifyContent="end">
                  <CancelButton
                    className="cancelBtn"
                    style={{
                      marginLeft: LanguageData ? "10px" : ""
                    }}
                    onClick={() =>
                      this.setState({
                        CompanyModel: false,
                      })
                    }
                  >
                    {this.t("CreateNewProject.button.Cancel")}
                  </CancelButton>
                  <SaveButton form="my-form" type="submit">
                    {this.t("CreateNewProject.button.SAVE")}
                  </SaveButton>
                </Box>
              </form>
            )}
          </Formik>
        </Box>
      </StyleNewPRoject>
    )
  }

  ViewFileModel = () => {
    return (
      <Dialog
        open={this.state.ViewFileModel}
        fullWidth={false}
        data-testid="setScoreRating"
        className="setScoreRating"
        transitionDuration={0}
        scroll="body"
        onClose={() => this.setState({
          ViewFileModel: !this.state.ViewFileModel,
        })}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        maxWidth={"lg"}
        PaperProps={{
          style: {
            width: "100%",
          },
        }}
      >
        <DialogContent style={{ padding: "0px", width: "100%" }}>
          <StyleNewPRoject>
            <Box
              style={{
                textAlign: "right",
                margin: "10px 10px 0px 0px",
              }}
            >
              <CloseIcon
                onClick={() =>
                  this.ratingCloseClick()
                }
                style={{
                  cursor: "pointer",
                  fontSize: "35px",
                  color: "#787878",
                }}
              />
            </Box>
            <Box padding={{ xs: "15px 25px 25px", sm: "15px 40px 40px" }}>
              {this.state.FileType === "video" ?
                <div className="FilevideoRender" data-testid="AddCompanySectionCreatesds33">
                  <video controls>
                    <source src={this.state.ViewFileSource} type="video/mp4" />
                  </video>
                </div> :
                <img
                  height="500px"
                  width="100%"
                  alt="true"
                  src={this.state.ViewFileSource}
                />
              }
            </Box>
          </StyleNewPRoject>
        </DialogContent>
      </Dialog>
    )
  }

  FormikRedner = (props: any) => {
    // NegativeMarking and other lowerpart
    return (
      <Grid item xs={6}>
        <Box>
          <Typography
            variant="body2"
            style={{
              marginBottom: "5px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <span className="Projectlabel">
              {this.t(
                "CreateNewProject.TextField.NegativeMarking"
              )}
              ?<span style={{ color: "red" }}>*</span>
            </span>
            <RadioGroup
              aria-label="isNegative"
              name="isNegative"
              value={this.state.isNegative}
              onChange={(event: any) => {
                props.setFieldValue(
                  "isNegative",
                  event.target.value
                );
                this.setState({
                  isNegative: (event.target as HTMLInputElement)
                    .value,
                });
                if (this.state.isNegative === "No") {
                  props.setFieldValue("negative_marks", 0);
                  this.setState({ negative_marks: 0 });
                }
              }}
              style={{ flexDirection: "row" }}
            >
              <FormControlLabel
                value="Yes"
                control={<Radio />}
                label="Yes"
                className="radio"
              />
              <FormControlLabel
                value="No"
                control={<Radio />}
                label="No"
                className="radio"
              />
            </RadioGroup>
          </Typography>
          <Select
            name="negative_marks"
            classNamePrefix="react-select"
            placeholder="0"
            isDisabled={
              this.state.isNegative === "No" ? true : false
            }
            options={this.negativeMarkingListCreate}
            isSearchable={false}
            onChange={(event: any) => {
              this.setState({ negative_marks: event.value });
              props.setFieldValue(
                "negative_marks",
                event.value
              );
            }}
            value={
              this.state.isNegative === "No"
                ? { label: "0", value: "0" }
                : {
                  label: props.values.negative_marks,
                  value: props.values.negative_marks,
                }
            }
          />
          {this.getErrorMessage(
            props.touched,
            props.errors,
            "negative_marks"
          )}
        </Box>
        <Box mt="20px">
          <Typography
            style={{ marginBottom: "5px" }}
            variant="body2"
            className="Projectlabel"
          >
            {this.t(
              "CreateNewProject.TextField.SelectCompetency"
            )}
            <span style={{ color: "red" }}>*</span>
          </Typography>
          <Select
            id='CreateNewProject-id'
            classNamePrefix="react-select"
            className="competency"
            placeholder={this.t(
              "CreateNewProject.TextField.Competency"
            )}
            closeMenuOnSelect={false}
            menuIsOpen={this.state.menuOpen}
            options={this.state.competencyIds}
            isMulti={true}
            allowSelectAll={true}
            openMenuOnClick={true}
            hideSelectedOptions={false}
            components={{ Option }}
            value={this.state.competencyIds.filter(
              (option: any) =>
                !!this.state.competencyId.find(
                  (ele: string) =>
                    ele.toString() === option.value
                )
            )}
            isDisabled={
              this.state.projectType === "virtual"
                ? true
                : false
            }
            onBlur={() => {
              this.setState({ menuOpen: false });
            }}
            onFocus={() => {
              this.setState({ menuOpen: true });
            }}
            onChange={(event: any) => {
              let ids = [];
              if (event) {
                ids = event.map((obj: any) => {
                  return obj.value;
                });
              }
              this.setState({ competencyId: ids });
              props.setFieldValue("competency_id", ids);
            }}
            onEditOptionClicked={(obj: any) => {
              this.handleCloneCompetency(obj.value);
            }}
          />
          {this.getErrorMessage(
            props.touched,
            props.errors,
            "competency_id"
          )}
        </Box>
        {(this.state.projectType === "assessor" ||
          this.state.projectType === "hybrid") && (
            <Box>
              <div
                className="SetRatingContainer"
                onClick={() =>
                  this.handleScoreModal(
                    "create",
                    this.state.setIncrement
                  )
                }
              >
                <BorderColorOutlinedIcon className="EditTimePen" />{" "}
                <p>
                  {this.t(
                    "CreateNewProject.TextField.SetScoreRating"
                  )}
                </p>
              </div>
            </Box>
          )}
      </Grid>
    )

  }

  EditCompetencyDialog = () => {
    return (
      <Dialog
        open={this.state.openDialogName === "Edit"}
        fullWidth={false}
        maxWidth={"sm"}
        transitionDuration={0}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        scroll="body"
        PaperProps={{
          style: {
            width: "500px",
          },
        }}
      >
        <DialogContent style={{ padding: "0px", width: "100%" }}>
          <Box
            style={{
              textAlign: "right",
              margin: "10px 10px 0px 0px",
            }}
          >
            <CloseIcon
              onClick={() => this.deleteCompetency()}
              style={{
                cursor: "pointer",
                fontSize: "35px",
                color: "#787878",
              }}
            />
          </Box>
          <Box
            padding={{ xs: "15px 25px 25px", sm: "15px 40px 40px" }}
          >
            <Box
              id="modal-title"
              fontWeight="bolder"
              fontSize={{ xs: "20px", sm: "30px" }}
              fontFamily="ProximaNova-Bold"
              mb="20px"
              color="#111"
              style={{
                textAlign: LanguageData ? "right" : "unset"
              }}
            >
              {this.t("CreateNewProject.TextField.EditCompetency")}
            </Box>
            <Formik
              initialValues={{
                competencyName: this.state.competencyName,
              }}
              validationSchema={this.competencySchema}
              onSubmit={(values) => {
                let currentListcreate = this.state.competencyIds.filter(
                  (item: any) => item.label === values.competencyName
                );
                if (currentListcreate.length === 0) {
                  this.editCompetency(values);
                } else {
                  toast.error(
                    this.t(
                      "CreateNewProject.ToastMessage.DifferentName"
                    )
                  );
                }
              }}
              validateOnChange={true}
              validateOnBlur={true}
            >
              {({ errors, touched, setFieldValue, handleSubmit }) => (
                <form
                  id="my-form"
                  onSubmit={handleSubmit}
                  data-testid="centerTextcretaeform"
                  noValidate
                  autoComplete="off"
                >
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <div
                        style={{
                          marginBottom: "5px",
                          fontSize: "18px",
                          textAlign: LanguageData ? "right" : "unset"
                        }}
                        data-testid="centerTextcretaeCompetencyName"
                      >
                        {this.t(
                          "CreateNewProject.TextField.CompetencyName"
                        )}
                        <span
                          style={{ color: "red" }}
                          data-testid="centerTextcretaeCompetencyName*"
                        >
                          *
                        </span>
                      </div>
                      <CustomInputBase
                        fullWidth
                        name="competencyName"
                        id="competencyName"
                        value={this.state.competencyName}
                        onChange={(event: any) => {
                          this.setState({
                            competencyName: event.target.value,
                          });
                          setFieldValue(
                            "competencyName",
                            event.target.value
                          );
                        }}
                        placeholder="Competency name"
                      />
                      {touched.competencyName &&
                        errors.competencyName && (
                          <div
                            className="text-danger"
                            data-testid="centerTextcretaeCtext-danger"
                          >
                            {errors.competencyName}
                          </div>
                        )}
                    </Grid>
                  </Grid>
                  <Box mt="30px" display="flex" justifyContent="end">
                    <CancelButton
                      className="cancelBtn"
                      data-testid="cancelBtnCreateProject"
                      onClick={() => this.deleteCompetency()}
                    >
                      {this.t("CreateNewProject.button.Cancel")}
                    </CancelButton>
                    <SaveButton style={{ marginRight: LanguageData ? "15px" : "unset" }} form="my-form" type="submit">
                      {this.t("CreateNewProject.button.SAVE")}
                    </SaveButton>
                  </Box>
                </form>
              )}
            </Formik>
          </Box>
        </DialogContent>
      </Dialog>
    )
  }

  RateingDialog = () => {
    return (
      <Dialog
        open={this.state.openDialogName === "scoreRating"}
        fullWidth={false}
        className="setScoreRating"
        transitionDuration={0}
        scroll="body"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        maxWidth={"sm"}
        PaperProps={{
          style: {
            width: "600px",
          },
        }}
      >
        <DialogContent style={{ padding: "0px", width: "100%" }}>
          <Box
            style={{
              textAlign: "right",
              margin: "10px 10px 0px 0px",
            }}
          >
            <CloseIcon
              onClick={() =>
                this.setState({
                  openDialogName: null,
                  rangeHalfData: [],
                  rangeOneData: [],
                })
              }
              style={{
                cursor: "pointer",
                fontSize: "35px",
                color: "#787878",
              }}
            />
          </Box>
          <Box
            padding={{ xs: "15px 25px 25px", sm: "15px 40px 40px" }}
          >
            <Box
              id="modal-title"
              color="#111"
              fontSize={{ xs: "20px", sm: "30px" }}
              fontFamily="ProximaNova-Bold"
              mb="20px"
              fontWeight="bolder"
            >
              {this.t("CreateNewProject.TextField.SetScoreRating")}
            </Box>
            <form
              id="my-form"
              onSubmit={this.onSubmitRating}
              noValidate
              autoComplete="off"
            >
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <Box>
                    <Typography
                      variant="body2"
                      style={{ marginBottom: "5px" }}
                    >
                      {this.t(
                        "CreateNewProject.TextField.SetIncrement"
                      )}
                      <span style={{ color: "red" }}>*</span>
                    </Typography>
                    <Select
                      name="setIncrement"
                      className="selectIncrement"
                      data-testid='select-increment'
                      classNamePrefix="react-select"
                      value={
                        this.incrementList.filter(
                          (option: any) =>
                            option.value == this.state.setIncrement
                        )[0]
                      }
                      isSearchable={false}
                      options={this.incrementList}
                      onChange={(event: any) => {
                        this.incrementHandleChange(event)
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
              {this.state.setIncrement === "1" && (
                <>
                  {this.state.rangeOneData.map((item: any) => {
                    return (
                      <Grid
                        style={{
                          color: "#8a8888",
                          fontSize: "12px",
                          margin: "20px 0",
                        }}
                        key={item.label}
                      >
                        {item.label}
                        {" - "}
                        {item.description}
                      </Grid>
                    );
                  })}
                </>
              )}
              {this.state.setIncrement === "0.5" && (
                <>
                  {this.state.rangeHalfData.map((item: any) => {
                    return (
                      <Grid
                        style={{
                          color: "#8a8888",
                          fontSize: "12px",
                          margin: "20px 0",
                        }}
                        key={item.label}
                      >
                        {item.label}
                        {" - "}
                        {item.description}
                      </Grid>
                    );
                  })}
                </>
              )}
              <Grid container spacing={3}>
                {this.RenderRating()}
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography
                    variant="body2"
                    style={{ marginBottom: "5px" }}
                  >
                    {this.t("CreateNewProject.TextField.Description")}
                    <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <CustomTwoInputBase
                    multiline
                    fullWidth
                    minRows={4}
                    onChange={this.handleProjectRatings}
                    value={this.handleRatingsDescription()}
                    placeholder={this.t(
                      "CreateNewProject.TextField.WriteDescription"
                    )}
                  />
                </Grid>
              </Grid>
              <Box mt="30px" display="flex" justifyContent="end">
                <CancelButton
                  className="cancelBtn"
                  onClick={() =>
                    this.setState({
                      isCancelClicked: true,
                      openDialogName: "",
                    })
                  }
                >
                  {this.t("CreateNewProject.button.Cancel")}
                </CancelButton>
                <SaveButton style={{ marginRight: LanguageData ? "15px" : "unset" }} form="my-form" type="submit">
                  {this.t("CreateNewProject.button.SAVE")}
                </SaveButton>
              </Box>
            </form>
          </Box>
        </DialogContent>
      </Dialog>
    )
  }

  PaginationBoxRender = () => {
    return (
      this.state.tools.length > 0 && this.state.selectedTools.length > 0 &&
      this.state.projectType !== "assessor" && (
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <div className="table-Pagination-Data">
              <Pagination
                count={Math.ceil(
                  this.state.selectedQuestions?.length / this.state.rowsPerPage
                )}
                className='rounded'
                onChange={(event: unknown, newPage: number) =>
                  this.setState({
                    ...this.state,
                    page: newPage - 1,
                  })
                }
                dir="ltr"
                variant="outlined"
                shape="rounded"
                showFirstButton
                showLastButton
                page={this.state.page === 0 ? 1 : this.state.page + 1}
                siblingCount={0}
                boundaryCount={1}
              />
            </div>
          </div>
        </Grid>
      )
    )
  }

  DeleteModelForProject = () => {
    return (
      <DeleteDialog
        fullWidth={false}
        aria-describedby="scroll-dialog-description"
        open={this.state.SetTimeModel}
        aria-labelledby="scroll-dialog-title"
        onClose={this.TimerModelOpenClose}
        scroll="body"
      >
        <DialogContent style={{ padding: "0px", overflow: "hidden" }}>
          <div className="firstDiv" data-testid="firstDivcretea">
            <Box
              style={{
                paddingTop: "0px !important",
                textAlign: "right",
              }}
            >
              <CloseIcon
                onClick={this.TimerModelOpenClose}
                style={{
                  cursor: "pointer",
                  fontSize: "35px",
                  color: "#787878",
                }}
              />
            </Box>
            <Box
              padding={{ xs: "15px 25px" }}
              data-testid="centerTextcretaeCompetencyNamemodal-title"
            >
              <Box
                id="modal-title"
                fontFamily="ProximaNova-Bold"
                fontWeight="bolder"
                fontSize={{ xs: "20px", sm: "30px" }}
              >
                {this.t("CreateNewProject.TextField.SetTimer")}
              </Box>
            </Box>
            <Box padding={{ xs: "15px 25px" }}>
              <Grid container>
                <Grid item xs={12}>
                  <label className="labels">
                    {this.t("CreateNewProject.TextField.SelectToolName")}
                    <span style={{ color: "#f94b4b" }}>*</span>
                  </label>
                  <Box className="textfield-parent">
                    <Select
                      className="SelectToolName"
                      classNamePrefix="react-select"
                      options={this.state.ChangeTimeOfTools}
                      placeholder={this.t(
                        "CreateNewProject.TextField.SelectToolName"
                      )}
                      value={
                        this.state.CurrentToolName === ""
                          ?
                          this.state.ChangeTimeOfTools[0] :
                          this.state.tools.filter(
                            (option: any) =>
                              option.value === this.state.CurrentToolName
                          )[0]
                      }
                      onChange={(event: any, index: any) => {
                        this.setState({
                          CurrentToolIndex: this.state.ChangeTimeOfTools.map(
                            (item: any) => item.online_tool_id
                          ).indexOf(event.value),
                          CurrentToolName: event.value,
                        });
                      }}
                      isSearchable={false}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box padding={{ xs: "15px 25px" }} data-testid="SetTimer">
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <label className="labels">
                    {this.t("CreateNewProject.TextField.SetTimer")}{" "}<span style={{ fontSize: "10px", fontFamily: "ProximaNova-Regular", color: "red" }}>{`(${this.t("ProjectsController.Max60minutesallowed")})`}</span>
                  </label>
                </Grid>
                <Grid item xs={12}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                    className="ModelMainDiv"
                  >
                    <div data-testid="numberMIn">
                      <TextField
                        className="CreateNewProject-min"
                        type="number"
                        variant="outlined"
                        InputProps={{ inputProps: { min: 0, max: 60 } }}
                        InputLabelProps={{
                          style: {
                            fontFamily: "ProximaNova-Regular",
                            zIndex: 0,
                          },
                        }}
                        onChange={(e: any) =>
                          this.ChangeToolsTime(e, "min")
                        }
                        value={
                          this.state?.ChangeTimeOfTools[
                            this.state.CurrentToolIndex
                          ]?.min
                        }
                        label={this.t("CreateNewProject.TextField.Min")}
                        error={
                          this.state?.ChangeTimeOfTools[
                            this.state.CurrentToolIndex
                          ]?.errorMin ||
                          this.state?.ChangeTimeOfTools[
                            this.state.CurrentToolIndex
                          ]?.min === ""
                        }
                      />
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <Button
                      onClick={this.TimerModelOpenClose}
                      className="cancelBtn"
                      size="large" style={{
                        textTransform: "capitalize",
                        fontFamily: "ProximaNova-Regular !important",
                      }}
                    >
                      {this.t("CreateNewProject.button.Cancel")}
                    </Button>
                    <Button
                      onClick={this.TimerSave}
                      type="submit"
                      data-testid="createProjectBtn"
                      style={{
                        backgroundColor: "#f94b4c",
                        color: "white",
                        textTransform: "capitalize",
                        fontFamily: "ProximaNova-Regular !important",
                      }}
                    >
                      {this.t("CreateNewProject.button.SAVE")}
                    </Button> </div> </Grid> </Grid> </Box> </div> </DialogContent>
      </DeleteDialog>
    )
  }

  render() {
    const toolsList = this.state.tools
      ?.filter((item: any) => item?.type !== "assessor_lead_tools")
      ?.map((tool: any) => {
        return {
          label: tool.attributes.name,
          value: tool.id,
        };
      })
      .filter((item: any) => item.label !== "Psychometric");

    return (
      <StyleNewPRoject
        style={{ fontFamily: "ProximaNova-Regular" }}
        data-testid="Heading"
      >
        {this.state.loading && <Spinner spinnerModal={this.state.loading} />}
        <AppSidebar type="admin">
          <Box margin="35px 0px" mr="30px" className="createNewProject">
            <Box display={{ xs: "block" }} mb="40px">
              <Box>
                <Box
                  mb="5px"
                  fontFamily="ProximaNova-Bold"
                  fontSize={{ xs: "24px", sm: "30px", md: "44px" }}
                  color="#1b3c69"
                >
                  {this.t("CreateNewProject.title.title")}
                </Box>
                <Box color="#1b3c69" fontSize={{ xs: "14px", sm: "20px" }}>
                  {this.t("CreateNewProject.title.SubTitile")}
                </Box>
              </Box>
              <Box sx={{ marginTop: "2rem" }}>
                <Formik
                  initialValues={{
                    project_name: "",
                    project_type: "",
                    assessor_id: [],
                    client_id: "",
                    manager_id: "",
                    coManager_id: "",
                    start_date: "",
                    end_date: "",
                    pdfFile: [],
                    negative_marks: "0",
                    isNegative: "No",
                    competency_id: [],
                  }}
                  validationSchema={this.createNewProjectSchema}
                  onSubmit={(values, { setSubmitting }) => {
                    this.projectSubmit(values)

                  }}
                  validateOnChange={true}
                  validateOnBlur={true}
                >
                  {(props) => (
                    <form
                      onSubmit={props.handleSubmit}
                      onDragEnter={this.handleDrag}
                      onDragOver={this.handleDrag}>
                      <Grid className="label-parent" container spacing={3}>
                        {this.rednerProjectName(props)}
                        {this.ProjectTypeRedner(props)}
                        {this.DcMangerRender(props)}
                        {this.CoManagerRender(props)}
                        {this.renderGrid(props)}
                        {this.renderGrid1(props)}
                        {this.renderGrid2(props)}
                        {this.renderGrid3(props)}
                        {this.uploadDocumentRender(props)}
                        {this.FormikRedner(props)}
                      </Grid>
                      {this.AddCompanySection()}
                      {this.state.tools.length > 0 && (
                        <>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              marginTop: "20px",
                            }}
                          >
                            <Typography style={{ fontFamily: "ProximaNova-Bold", display: 'flex' }}>
                              {this.t(
                                "CreateNewProject.TextField.SelectTool"
                              )}{' '}<span style={{ color: "red" }}>*</span>
                            </Typography>
                            {(this.state.projectType === "assessor") ===
                              false && (
                                <div>
                                  <div
                                    className="SetTimerContainer"
                                    onClick={this.TimerModelOpenClose}
                                  >
                                    <BorderColorOutlinedIcon className="EditTimePen" />{" "}
                                    <p>
                                      {this.t(
                                        "CreateNewProject.TextField.SetTimer"
                                      )}
                                    </p>
                                  </div>
                                </div>
                              )}
                          </div>
                          {this.renderButtonContainer()}
                        </>
                      )}
                      {this.state.tools.length > 0 && this.state.selectedTools.length > 0 &&
                        this.state.projectType !== "assessor" && (
                          <Box
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              marginTop: "3rem",
                            }}
                          >
                            <Box>
                              <Typography>
                                {this.t(
                                  "CreateNewProject.TextField.QuestionairesFromTheRepository"
                                )}
                              </Typography>
                            </Box>
                          </Box>
                        )}
                      {this.state.tools.length > 0 &&
                        this.state.projectType !== "assessor" &&
                        this.state.selectedTools.length > 0 &&
                        this.renderBox(props, toolsList)}
                      {this.PaginationBoxRender()}
                      <Grid
                        item
                        xs={12}
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          marginTop: "10px",
                        }}
                      >
                        <Button
                          style={{
                            backgroundColor: "#f94b4c",
                            color: "white",
                            marginRight: !LanguageData ? "12px" : "",
                            marginLeft: LanguageData ? "12px" : "",
                            textTransform: "capitalize",
                            fontFamily: "ProximaNova-Regular !important"
                          }}
                          type="submit"
                          data-testid="createProjectBtn"
                        >
                          {this.t("CreateNewProject.button.SAVE")}
                        </Button>
                        <Button
                          style={{
                            backgroundColor: "#bebebe",
                            color: "white",
                            fontFamily: "ProximaNova-Regular !important",
                            textTransform: "capitalize"
                          }}
                          onClick={this.handleCancel}
                        >
                          {this.t("CreateNewProject.button.Cancel")}
                        </Button>
                      </Grid>
                    </form>
                  )}
                </Formik>

                {/* Edit competency dialog */}
                {this.EditCompetencyDialog()}

                {/* Rating dialog */}
                {this.RateingDialog()}
              </Box>
            </Box>
          </Box>
          {this.DeleteModelForProject()}

          {this.AddCompanyModel()}
        </AppSidebar>
      </StyleNewPRoject>
    );
  }
}

export default withRouter(withTranslation()(CreateNewProject));

const CustomInputBase = styled(InputBase)({
  border: "1px solid #d6d6d6",
  backgroundColor: "white",
  borderRadius: "10px",
  padding: "10px 15px",
  minHeight: "50px",
  fontSize: "14px",
  "& .MuiInputBase-input": {
    "&::placeholder": {
      fontSize: "14px",
    },
  },
});
const CustomTwoInputBase = styled(InputBase)({
  border: "1px solid #d6d6d6",
  minHeight: "50px",
  padding: "10px",
  paddingLeft: "15px",
  fontSize: "14px",
  borderRadius: "5px",
  "& .MuiInputBase-input": {
    "&::placeholder": {
      fontSize: "14px",
    },
  },
});
const CancelButton = styled(Button)({
  fontFamily: "ProximaNova-Semibold",
  borderRadius: "10px",
  marginRight: "30px",
  color: "#f26a42",
  fontSize: "18px",
  padding: "7px 15px",
  backgroundColor: "#fff",
  border: "1px solid #f26a42",
  textTransform: "none",
});

const SaveButton = styled(Button)({
  fontFamily: "ProximaNova-Semibold",
  borderRadius: "10px",
  padding: "7px 22px",
  fontSize: "18px",
  backgroundColor: "#f26a42",
  color: "#fff",
  textTransform: "none",
  border: "1px solid #f26a42",
  "&:hover": {
    color: "#fff",
    backgroundColor: "#f26a42",
  },
  "&.MuiButton-root.Mui-disabled": {
    border: "1px solid hsl(0,0%,60%)",
    color: "hsl(0,0%,60%)",
    backgroundColor: "hsl(0,0%,95%)",
    cursor: "no-drop",
    pointerEvents: "auto",
  },
});
const Option = (props: any) => {
  return (
    <div>
      <components.Option {...props}>
        <Checkbox
          disableRipple
          icon={<div className="unchecked" />}
          checkedIcon={
            <div className="checked">
              <DoneIcon />
            </div>
          }
          checked={props.isSelected}
          onChange={() => null}
        />
        <div className="comp_wrapper">
          <label>{props.label}</label>
          <img
            data-testid='edit-icon'
            src={editIcon}
            alt="Edit icon"
            onClick={(event: any) => {
              event.stopPropagation();
              props.selectProps?.onEditOptionClicked(props.data);
            }}
          />
        </div>
      </components.Option>
    </div>
  );
};
const CustomInput = React.forwardRef<any, { value?: any; onClick?: any }>(
  ({ value, onClick }, ref) => (
    <Box position="relative" border="1px solid #d6d6d6" borderRadius="10px">
      <img
        onClick={onClick}
        src={calender}
        style={{
          zIndex: 0,
          position: "absolute",
          right: LanguageData === false ? "12px" : "",
          left: LanguageData ? "12px" : "",
          top: "14px",
          cursor: "pointer",
          width: "20px",
        }}
      />
      <DateInput placeholder="DD/MM/YYYY" onClick={onClick} value={value} />
    </Box>
  )
);
const DateInput = styled(InputBase)({
  fontFamily: "ProximaNova-Regular",
  minHeight: "50px",
  fontSize: "14px",
  width: "100%",
  paddingLeft: !LanguageData ? "20px" : "",
  paddingRight: LanguageData ? "20px" : "",
  "& .MuiInputBase-input": {
    "&::placeholder": {
      fontSize: "17px",
    },
  },
});
const StyleNewPRoject = styled("div")({
  fontFamily: "ProximaNova-Regular !important",
  "& .Projectlabel": {
    fontFamily: "ProximaNova-Regular",
    fontWeight: "900",
    textTransform: "capitalize",
  },
  "& .UnSelectBtn": {
    flex: "10%",
    border: "1px solid black",
    backgroundColor: "white",
    margin: "5px",
    color: "black",
    minWidth: "80px",
  },
  "& .SelectBtn": {
    color: "white",
    margin: "5px",
    backgroundColor: "#f94b4b",
    minWidth: "80px",
  },
  "& .ButtonContainer": {
    display: "flex",
    padding: "5px",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    marginTop: "6px",
    backgroundColor: "#eff6ff",
  },
  "& .ContainerQuetion": {
    margin: "5px 0",
  },
  "& .panel1bh-header .MuiAccordionSummary-content": {
    flexGrow: "unset",
    width: "100%"
  },
  "& .AcorenHeading": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    "& .checkquetion": {
      display: "flex",
      alignItems: "center",
    },
  },
  "& .TextandButton": {
    display: "flex",
    justifyContent: "space-between",
    width: "calc(100% - 68px)",
  },
  "& .InputContainer": {
    "& .MuiSvgIcon-root": {
      backgroundColor: "white",
      width: "50px",
      height: "50px",
      borderRadius: "6px",
    },
    "& .MuiCheckbox-root": {
      borderWidth: "1px",
    },
  },
  "& .quetions": {
    maxWidth: "85%",
    width: "100%",
    margin: "0",
    paddingLeft: "10px",
    boxSizing: "border-box",
    textOverflow: "ellipsis",
    "& h4": {
      display: "inline-block",
      width: "inherit",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      maxWidth: "700px",
      fontFamily: "ProximaNova-Regular",
    },
  },
  "& .CloseAcordian": {
    position: "relative",
  },
  "& .AddOption": {
    fontFamily: "ProximaNova-Regular",
  },
  "& .labelAcordin": {
    position: "absolute",
    top: "0",
    padding: "2px 10px",
    left: !LanguageData ? "0" : "unset",
    right: LanguageData ? "0" : "unset",
    color: "black",
    borderTopLeftRadius: "4px",
    "& h5": {
      fontSize: "16px",
    },
  },
  "& .checkBoxAndQueHeading": {
    display: "flex",
    marginTop: "10px",
    width: "100%",
    flexWrap: "wrap",
    maxWidth: "100%",
  },
  "& .QuetionRender": {
    display: "flex",
    alignItems: "center",
    maxWidth: "100%",
    overflow: "hidden",
    width: "calc(100% - 155px)",
    "& div": {
      "& h3": {
        fontFamily: "ProximaNova-Regular",
        fontWeight: "400",
      },
    },
  },
  "& .MarksContainer": {
    display: "flex",
    justifyContent: "space-between",
  },
  "& .QuetionTextField": {
    "& input": {
      fontFamily: "ProximaNova-Regular",
      fontWeight: "700",
    },
  },
  '& .videoRender': {
    '& video': {
      width: '100%',
      height: '200px'
    }
  },
  "&  .redButton": {
    backgroundColor: "#f94b4b",
    color: "white",
    borderRadius: "10px",
    fontSize: "18px",
    fontFamily: "ProximaNova-Semibold",
    textTransform: "none",
    padding: "5px 25px",
    "&:hover": {
      color: "white",
      backgroundColor: "#f94b4b",
    },
    "@media(max-width:600px)": {
      width: "100%",
      marginTop: "27px",
    },
    "@media(max-width:992px)": {
      padding: "8px 15px",
    },
  },
  "& .SetTimerContainer": {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "space-between",
    cursor: "pointer",
    "& .EditTimePen": {
      marginRight: "10px",
      color: "#f94b4b",
      fontSize: "20px",
    },
    "& p": {
      color: "#f94b4b",
      fontWeight: "bold",
    },
  },
  '& .CompanysectionMainDiv': {
    marginTop: "10px",
    display: 'flex',
    justifyContent: 'center',
    padding: '10px',
    backgroundColor: '#eff6ff',
    borderRadius: '6px',
    cursor: 'pointer'
  },
  '& .SectionContainer': {
    backgroundColor: '#eff6ff',
    padding: "20px 20px",
    borderRadius: '6px',
    marginTop: "10px",
  },
  '& .SectionHeading': {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: '10px'
  },
  '& .FilevideoRender': {
    '& video': {
      width: '100%',
      height: '500px'
    }
  },
  '& .addnewsection': {
    display: 'flex',
    textDecoration: "underline",
    color: '#1b395c',
    cursor: "pointer",
    textTransform: "capitalize"
  },
  '& .MediaContainer': {
    display: "flex",
    justifyContent: "space-between"
  },
  '& .AllMediaSection': {
    display: "flex",
    '& >div': {
      marginLeft: "5px",
      marginRight: "5px",
      marginTop: "10px",
      borderRadius: "8px",
      cursor: "pointer"
    },
    '& :first-child': {
      marginLeft: '0px'
    },
    '& :last-child': {
      marginRight: "0px"
    }
  },
  '& .AllSection': {
    backgroundColor: "white",
    margin: "10px 0",
    padding: "15px",
    borderRadius: '6px'
  },
  '& .customUploadAreaCompany': {
    width: '100%',
    border: 'dashed',
    cursor: 'pointer',
    overflow: 'hidden',
    position: 'relative',
    boxSizing: 'border-box',
    minHeight: '60px',
    borderColor: 'rgba(0, 0, 0, 0.12)',
    borderRadius: '4px',
    backgroundColor: '#fff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '30px',
    '& .MuiSvgIcon-root': {
      marginBottom: '2px'
    }
  },
  '& .quill': {
    display: 'grid'
  },
  '& .ql-container': {
    minHeight: '100px'
  },
  '& .react-datepicker-wrapper': {
    width: "100%"
  },
  "& .SetRatingContainer": {
    cursor: "pointer",
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "15px",
    padding: "5px",
    "& .EditTimePen": {
      marginRight: "10px",
      color: "#f94b4b",
      fontSize: "20px",
    },
    "& p": {
      color: "#f94b4b",
      fontWeight: "bold",
    },
  }
});

const DeleteDialog = styled(Dialog)({
  "& .SelectToolName": {
    "& .react-select__menu-list": {
      zindex: 99,
      maxHeight: "170px",
    },
  },
  "& .ModelMainDiv": {
    "& div": {
      width: "100%",
    },
  },
  "& .MuiDialog-paper": {
    minWidth: "500px",
  },
  "@media(max-width:576px)": {
    margin: "0px",
  },
  margin: "0px 61px",
});

// Customizable Area End
