// Customizable Area Start
import React from "react";
import {
    Box,
    Button,
    Grid,
    TextField,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Dialog,
    DialogContent,
    Typography,
    InputBase,
    Slider
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import { styled } from "@material-ui/styles";
import { withStyles } from '@material-ui/core/styles';
import Select from "react-select";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from '@material-ui/icons/Close';
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
//@ts-ignore
import { CSVLink } from 'react-csv';
import ProjectDetailController, { Props } from "./ProjectDetailController.web";
import AppSidebar from "../../../../components/src/AppSidebar.web";
const LanguageData = localStorage.getItem("language") === "ar";
import Spinner from "../../../../components/src/Spinner.web";

const editIcon = require("../../assets/edit.png");
import { SvgFileImageComponent } from "../assets";

class ProjectDetail extends ProjectDetailController {
    constructor(props: Props) {
        super(props);
    }

    TableBodyRender = () => {
        return (
            <TableBody>
                {this.state.data.length > 0 ?
                    <>
                        {this.state.data.map((item: any, index: number) => (
                            <TableRow key={`${index + 1}`}>
                                <TableCell style={{ fontFamily: "ProximaNova-Regular", fontSize: "18px", color: "#000" }}>
                                    {item.participantName}
                                </TableCell>
                                <TableCell align='center' style={{ fontFamily: "ProximaNova-Regular", fontSize: "18px", color: "#000", whiteSpace: "nowrap" }}>
                                    {item.isReportAvailable ? item.score : "-"}
                                </TableCell>
                                <TableCell align='center' style={{ fontFamily: "ProximaNova-Regular", fontSize: "18px", color: "#000", whiteSpace: "nowrap" }}>
                                    {item.type}
                                </TableCell>
                                <TableCell align="right" style={{ fontFamily: "ProximaNova-Regular", fontSize: "18px", color: "#000" }}>
                                    <Box display="flex" alignItems="center" justifyContent="end" style={{ gap: "10px" }}>
                                        {item.toolName !== "OnlineTool" &&
                                            <img
                                                src={editIcon}
                                                style={{ cursor: item.isReportAvailable ? "pointer" : "not-allowed", display: this.state.Userrole === "admin" ? "unset" : "none" }}
                                                onClick={() => { item.isReportAvailable && this.onEditClick(item) }}
                                            />
                                        }
                                        <DownloadButton
                                            startIcon={<SvgFileImageComponent />}
                                            disabled={!item.isReportAvailable}
                                            onClick={() => this.onDownloadBtnClick(item)}
                                        >
                                            {this.t("projectDetail.button.download")}
                                        </DownloadButton>
                                    </Box>
                                </TableCell>
                            </TableRow>
                        ))}
                    </>
                    : <TableRow>
                        <TableCell align="center" colSpan={6} style={{ fontFamily: "ProximaNova-Regular", color: "#000", fontSize: "18px", whiteSpace: "nowrap", }}>{this.t("projectDetail.label.notFound")}</TableCell>
                    </TableRow>
                }
            </TableBody>
        )
    }

    render() {
        return (
            <StyleProjectDetails style={{ fontFamily: "ProximaNova-Regular" }}>
                {this.state.loading && <Spinner spinnerModal={this.state.loading} />}
                <AppSidebar type="admin">
                    <Box margin="35px 0px" mr="30px">
                        <Box display={{ xs: "block", md: "flex" }} justifyContent="space-between">
                            <Box>
                                <Box mb="5px" fontFamily="ProximaNova-Bold" fontSize={{ xs: "24px", sm: "30px", md: "44px" }} color="#1b3c69">{this.state.projectName}</Box>
                                <Box color="#1b3c69" fontSize={{ xs: "14px", sm: "20px" }}>{this.t("projectDetail.description.main")}</Box>
                            </Box>
                        </Box>
                        <Box mt="30px">
                            <Box>
                                <Grid container>
                                    <Grid item xs={12} className={LanguageData ? "SearchRight" : ""}>
                                        <Box mb="25px" display="flex" alignItems="center" justifyContent="end">
                                            <TextField
                                                label={this.t("projectDetail.label.search")}
                                                id="search"
                                                InputLabelProps={{
                                                    style: {
                                                        zIndex: 0,
                                                        fontFamily: "ProximaNova-Regular",
                                                    }
                                                }}
                                                onChange={(event: any) => {
                                                    this.onChangeHandler(event.target.value, 0);
                                                }}
                                                InputProps={{
                                                    endAdornment: <SearchIcon />
                                                }}
                                                variant="outlined"
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} style={{ overflow: "auto" }}>
                                        <Table aria-label="simple table" style={{ borderRadius: "10px", border: "1px solid #E0E0E0", borderCollapse: "separate" }} className="custom-table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell style={{ fontFamily: "ProximaNova-Bold", fontSize: "16px" }}>{this.t("projectDetail.label.participantName")}</TableCell>
                                                    <TableCell align="center" style={{ fontFamily: "ProximaNova-Bold", fontSize: "16px" }}>{this.t("projectDetail.label.score")}</TableCell>
                                                    <TableCell align="center" style={{ fontFamily: "ProximaNova-Bold", fontSize: "16px" }}>{this.t("projectDetail.label.type")}</TableCell>
                                                    <TableCell align="right" style={{ fontFamily: "ProximaNova-Bold", fontSize: "16px" }}> <span style={{ fontFamily: "ProximaNova-Bold", fontSize: "16px", marginRight: "36px" }}>{this.t("projectDetail.label.action")}</span></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            {this.TableBodyRender()}
                                        </Table>
                                        {this.state.data.length > 0 &&
                                            <div className="custom-pagination">
                                                <Pagination
                                                    onChange={(event: unknown, newPage: number) => {
                                                        this.onChangeHandler(this.state.search, newPage - 1);
                                                    }}
                                                    count={Math.ceil(this.state.dataLength / this.state.rowsPerPage)}
                                                    showFirstButton
                                                    variant="outlined"
                                                    showLastButton
                                                    dir="ltr"
                                                    boundaryCount={1}
                                                    shape="rounded"
                                                    siblingCount={0}
                                                />
                                            </div>
                                        }
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </Box>
                    <CSVLink
                        data={this.state.csvData}
                        filename={`${this.state.participantName.split(" ").join("_")}_report.csv`}
                        target="_blank"
                        ref={(ref: any) => { this.csvLink = ref; }}
                    />
                </AppSidebar>

                {/* for Set Score */}
                <Dialog
                    open={this.state.openDialogName === "SetScore"}
                    fullWidth={false}
                    maxWidth={"sm"}
                    scroll="body"
                    onClose={() => { }}
                    aria-labelledby="scroll-dialog-title"
                    aria-describedby="scroll-dialog-description"
                >
                    <DialogContent style={{ padding: "0px", maxWidth: "500px", width: "100%" }}>
                        <Box style={{ textAlign: "right", margin: "10px 10px 0px 0px" }}>
                            <CloseIcon onClick={() => this.setState({ openDialogName: "" })} style={{ cursor: "pointer", fontSize: "35px", color: "#787878" }} />
                        </Box>
                        <Box padding={{ xs: "15px 25px 25px", sm: "15px 40px 40px" }}>
                            <Box
                                id="modal-title"
                                fontWeight="bolder"
                                fontSize={{ xs: "20px", sm: "30px" }}
                                fontFamily="ProximaNova-Bold"
                                mb="20px"
                            >
                                {this.state.participantName}
                            </Box>
                            <Box mb={2}>
                                <Typography variant="body2" style={{ fontFamily: "ProximaNova-Semibold", fontSize: "20px", textAlign: LanguageData ? "right" : "unset" }}>
                                    {this.t("projectDetail.label.selectCompetency")}
                                </Typography>
                                <Select
                                    classNamePrefix="react-select"
                                    className="competency-dropdown"
                                    placeholder={this.t("projectDetail.placeholder.selectCompetency")}
                                    isSearchable={false}
                                    options={this.state.scoreDetail}
                                    value={
                                        this.state.scoreDetail.filter(
                                            (option: any) =>
                                                option.value === this.state.competency
                                        )[0] ?? []
                                    }
                                    onChange={(event: any) => {
                                        this.setState({ competency: event.value, competencyIndex: event.index });
                                    }}
                                />
                            </Box>
                            <Typography variant="body2" style={{ fontFamily: "ProximaNova-Semibold", fontSize: "20px", textAlign: LanguageData ? "right" : "unset" }}>
                                {this.t("projectDetail.label.setScore")}
                            </Typography>
                            <Box p="20px" borderRadius="5px" border="1px solid #d6d6d6">
                                <Box>{this.t("projectDetail.description.score")}</Box>
                                <Box mt="30px" mb="15px">
                                    <CustomSlider
                                        defaultValue={0}
                                        value={this.state.scoreDetail[this.state.competencyIndex]?.score}
                                        onChange={(event: React.ChangeEvent<{}>, value: number | number[]) => {
                                            this.onChange("score", value);
                                        }}
                                        aria-labelledby="discrete-slider-always"
                                        step={this.state.scoreGap}
                                        marks={[
                                            { label: "0", value: 0 },
                                            { label: "5", value: 5 }
                                        ]}
                                        min={0}
                                        max={5}
                                        valueLabelDisplay="on"
                                    />
                                </Box>
                                <CustomInputBase
                                    fullWidth
                                    multiline
                                    readOnly
                                    placeholder={this.t("projectDetail.placeholder.definition")}
                                    value={this.getDefinition(this.state.scoreDetail[this.state.competencyIndex]?.score, this.state.definition)}
                                />
                            </Box>
                            <Box mt="20px">
                                <CustomInputBase
                                    fullWidth
                                    multiline
                                    minRows={4}
                                    placeholder={this.t("projectDetail.placeholder.comment")}
                                    value={this.state.scoreDetail[this.state.competencyIndex]?.comment}
                                    onChange={(event: any) => {
                                        this.onChange("comment", event.target.value);
                                    }}
                                />
                            </Box>
                            <Box mt="25px" display="flex" style={{ gap: "15px" }}>
                                <CancelButton onClick={() => this.setState({ openDialogName: "" })}>{this.t("projectDetail.button.cancel")}</CancelButton>
                                <SubmitButton onClick={() => this.editFeedback()}>{this.t("projectDetail.button.submit")}</SubmitButton>
                            </Box>
                        </Box>
                    </DialogContent>
                </Dialog>
            </StyleProjectDetails>
        );
    }
}

export default withRouter(withTranslation()(ProjectDetail));

const CustomInputBase = styled(InputBase)({
    borderRadius: "5px",
    border: "1px solid #d6d6d6",
    paddingLeft: "15px",
    minHeight: "50px",
    padding: "10px",
    fontSize: "14px",
    '& .MuiInputBase-input': {
        "&::placeholder": {
            fontSize: "14px"
        }
    }
});

const SubmitButton = styled(Button)({
    fontFamily: "ProximaNova-Semibold",
    backgroundColor: "#f94b4b",
    borderRadius: "10px",
    color: "white",
    textTransform: "none",
    fontSize: "18px",
    padding: "7px 22px",
    "&:hover": {
        backgroundColor: "#f94b4b",
        color: "white",
    },
    "@media(max-width:600px)": {
        padding: "7px 16px",
    }
});

const CancelButton = styled(Button)({
    backgroundColor: "white",
    fontFamily: "ProximaNova-Semibold",
    border: "1px solid #f26a42",
    color: "#f26a42",
    textTransform: "none",
    fontSize: "18px",
    padding: "7px 21px",
    borderRadius: "8px",
    "&:hover": {
        color: "#f26a42",
        backgroundColor: "white",
    },
    "@media(max-width:600px)": {
        padding: "7px 15px",
    }
});

const DownloadButton = styled(Button)({
    fontFamily: "ProximaNova-Semibold",
    borderRadius: "10px",
    color: "#f26a42",
    backgroundColor: "white",
    border: "1.5px solid #f26a42",
    fontSize: "14px",
    textTransform: "none",
    padding: "8px 15px",
    "& svg": {
        stroke: "#ECA00C",
        fill: "#ECA00C",
    },
    "&:hover": {
        color: "#f26a42",
        backgroundColor: "white",
    },
    "&.MuiButtonBase-root.Mui-disabled": {
        color: "#bebebe",
        border: "1.5px solid #bebebe",
        cursor: "not-allowed",
        backgroundColor: "#fafafa",
        pointerEvents: "all",
        "& svg": {
            stroke: "#bebebe",
            fill: "#bebebe",
        }
    }
});

const StyleProjectDetails = styled('div')({
    '& .SearchRight': {
        '& .MuiInputBase-root': {
            display: "flex",
            flexDirection: "row-reverse"
        }
    }
})

const CustomSlider = withStyles({
    root: {
        padding: '15px 0',
        height: 10,
        color: '#288a0a',
        cursor: 'default'
    },
    thumb: {
        width: 30,
        height: 30,
        backgroundColor: "#288a0a",
        top: 15,
        marginLeft: -12,
        marginTop: -8,
        cursor: "pointer",
        '&:focus, &:hover, &$active': {
            boxShadow: '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)',
        },
    },
    valueLabel: {
        zIndex: 0,
        left: 'calc(-50% + 15px)',
        fontSize: 15,
        top: -22,
        '& *': {
            color: '#000',
            background: 'transparent'
        },
    },
    track: {
        borderRadius: 10,
        height: 15,
        cursor: "pointer"
    },
    rail: {
        backgroundColor: "#9ccf81",
        height: 15,
        cursor: "pointer",
        borderRadius: 10,
    },
    mark: {
        display: "none",
    },
    markLabel: {
        fontSize: 15,
        top: "40px",
    }
})(Slider);
// Customizable Area End