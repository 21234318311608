// Customizable Area Start
import React from "react";
import {
    Box,
    Button,
    InputBase,
    Grid,
    TextField,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Dialog,
    DialogContent
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import { styled } from "@material-ui/styles";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from '@material-ui/icons/Close';
import * as Yup from "yup";
import { Formik } from "formik";
import { withTranslation } from "react-i18next";
import EmploymentTypeController, { Props } from "./EmploymentTypeController.web";
import AppSidebar from "../../../../../components/src/AppSidebar.web";
import Spinner from "../../../../../components/src/Spinner.web";
const LanguageData = localStorage.getItem("language") === "ar";

const editIcon = require("../../../assets/edit.png");
const deleteIcon = require("../../../assets/delete.png");

class EmploymentType extends EmploymentTypeController {
    constructor(props: Props) {
        super(props);
    }

    employmentTypeSchema = () => {
        return Yup.object().shape({
            employmentTypeName: Yup.string().required(this.t("organizationFunction.employmentType.errorMessages.name"))
        });
    }

    TableRender = () => {
        return (
            <Table
                aria-label="simple table"
                style={{ border: "1px solid #E0E0E0", borderRadius: "10px", borderCollapse: "separate" }}
                className="custom-table">
                <TableHead>
                    <TableRow>
                        <TableCell style={{ fontFamily: "ProximaNova-Bold", fontSize: "16px" }} align={LanguageData ? "right" : "left"}>
                            {this.t("organizationFunction.employmentType.label.tableName")}
                        </TableCell>
                        <TableCell style={{ fontFamily: "ProximaNova-Bold", fontSize: "16px" }} align={LanguageData ? "left" : "right"}>
                            {this.t("organizationFunction.employmentType.label.action")}
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {this.state.data.length > 0 ?
                        <>
                            {this.state.data.map((item: any, index: number) => (
                                <TableRow key={`${index + 1}`}>
                                    <TableCell align={LanguageData ? "right" : "left"}
                                        style={{ fontFamily: "ProximaNova-Regular", fontSize: "18px", color: "#000" }}
                                    >{item.employmentTypeName}</TableCell>
                                    <TableCell
                                        style={{ fontFamily: "ProximaNova-Regular", fontSize: "18px", color: "#000" }}
                                        align={LanguageData ? "left" : "right"}>
                                        <img
                                            src={editIcon}
                                            id="edit" style={{ cursor: "pointer", marginRight: "10px" }} onClick={() => this.setState({ employmentTypeId: item.id, employmentTypeName: item.employmentTypeName, openDialogName: "Edit" })} />
                                        <img
                                            src={deleteIcon} id="delete" style={{ cursor: "pointer" }} onClick={() => this.setState({ employmentTypeId: item.id, employmentTypeName: item.employmentTypeName, openDialogName: "Delete" })} />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </>
                        : <TableRow>
                            <TableCell colSpan={6} align="center" style={{ fontFamily: "ProximaNova-Regular", fontSize: "18px", color: "#000", whiteSpace: "nowrap" }}>{this.t("organizationFunction.employmentType.label.notFound")}</TableCell>
                        </TableRow>
                    }
                </TableBody>
            </Table>
        )
    }

    EditDilogRender = () => {
        return (
            <Dialog
                open={["Add", "Edit"].includes(this.state.openDialogName ?? "")}
                fullWidth={false}
                maxWidth={"sm"}
                transitionDuration={0}
                onClose={() => { }}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                scroll="body"
                PaperProps={{
                    style: {
                        width: "500px"
                    }
                }}
            >
                <DialogContent style={{ padding: "0px", width: "100%" }}>
                    <Box style={{ textAlign: "right", margin: "10px 10px 0px 0px" }}>
                        <CloseIcon onClick={() => this.setState({ employmentTypeId: null, employmentTypeName: "", openDialogName: null })} style={{ cursor: "pointer", fontSize: "35px", color: "#787878" }} />
                    </Box>
                    <Box padding={{ xs: "15px 25px 25px", sm: "15px 40px 40px" }}>
                        <Box
                            id="modal-title"
                            fontWeight="bolder"
                            fontSize={{ xs: "20px", sm: "30px" }}
                            fontFamily="ProximaNova-Bold"
                            mb="20px"
                            color="#1b3c69"
                            style={{ textAlign: LanguageData ? "right" : "unset" }}
                        >
                            {this.t(`organizationFunction.employmentType.title.${this.state.openDialogName}`)}
                        </Box>
                        <Formik
                            initialValues={{
                                employmentTypeName: this.state.employmentTypeName
                            }}
                            validationSchema={this.employmentTypeSchema}
                            onSubmit={(values) => {
                                this.state.openDialogName === "Add"
                                    ? this.addEmploymentType()
                                    : this.editEmploymentType()
                            }}
                        >
                            {({ errors, touched, setFieldValue, handleSubmit }) => (
                                <form onSubmit={handleSubmit} noValidate autoComplete="off">
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <div style={{ marginBottom: "5px", fontSize: "18px", textAlign: LanguageData ? "right" : "unset" }}>{this.t(`organizationFunction.employmentType.title.${this.state.openDialogName}`)}</div>
                                            <CustomInputBase fullWidth placeholder={this.t("organizationFunction.employmentType.placeholder.name")}
                                                value={this.state.employmentTypeName}
                                                onChange={(event: any) => {
                                                    setFieldValue("employmentTypeName", event.target.value); this.setState({ employmentTypeName: event.target.value });
                                                }}
                                            />
                                            {touched.employmentTypeName && errors.employmentTypeName &&
                                                <div className="text-danger" style={{ textAlign: LanguageData ? "right" : "unset" }}>{errors.employmentTypeName}</div>
                                            }
                                        </Grid>
                                    </Grid>
                                    <Box mt="30px" justifyContent="end" display={{ xs: "block", sm: "flex" }}>
                                        <CustomButton
                                            className="cancelBtn"
                                            onClick={() => this.setState({
                                                employmentTypeId: null,
                                                employmentTypeName: "", openDialogName: null
                                            })}>{this.t("organizationFunction.employmentType.button.cancel")}</CustomButton>
                                        <RedButton style={{ marginRight: LanguageData ? '15px' : 'unset' }}
                                            type="submit"
                                        >{this.t(`organizationFunction.employmentType.button.${this.state.openDialogName}`)}</RedButton>
                                    </Box>
                                </form>
                            )}
                        </Formik>
                    </Box>
                </DialogContent>
            </Dialog>
        )
    }

    DeleteDilogRender = () => {
        return (
            <Dialog
                open={this.state.openDialogName === "Delete"}
                onClose={() => { }}
                maxWidth={"sm"}
                transitionDuration={0}
                fullWidth={false}
                aria-labelledby="scroll-dialog-title"
                scroll="body"
                aria-describedby="scroll-dialog-description"
            >
                <DialogContent style={{ padding: "0px", maxWidth: "500px" }}>
                    <Box
                        style={{ paddingTop: "0px !important", textAlign: "right", margin: "10px 10px 0px 0px" }}
                    >
                        <CloseIcon
                            onClick={() => this.setState({ employmentTypeId: null, employmentTypeName: "", openDialogName: null })} style={{ cursor: "pointer", fontSize: "35px", color: "#787878" }} />
                    </Box>
                    <Box display="flex" alignItems="center"
                        flexDirection="column"
                        padding={{ xs: "15px 25px 25px", sm: "15px 70px 40px" }}>
                        <Box
                            id="modal-title"
                            fontSize={{ xs: "20px", sm: "30px" }}
                            fontWeight="bolder"
                            fontFamily="ProximaNova-Bold"
                            mb="20px"
                        >
                            {this.t("organizationFunction.employmentType.title.delete")}
                        </Box>
                        <Box textAlign="center" fontSize="18px">
                            {this.t("organizationFunction.employmentType.description.delete", { value: this.state.employmentTypeName })}
                        </Box>
                        <Box mt="30px" display="flex">
                            <CustomButton className="cancelBtn" onClick={() => this.setState({ employmentTypeId: null, employmentTypeName: "", openDialogName: null })} style={{ marginBottom: "0px", padding: "5px 10px" }}>{this.t("organizationFunction.employmentType.button.cancel")}</CustomButton>
                            <RedButton onClick={this.deleteEmploymentType} style={{ padding: "5px 10px", marginRight: LanguageData ? '15px' : 'unset' }}>{this.t("organizationFunction.industry.button.delete")}</RedButton>
                        </Box>
                    </Box>
                </DialogContent>
            </Dialog>
        )
    }

    render() {
        return (
            <EmploymentTypeStyle style={{ fontFamily: "ProximaNova-Regular" }} data-testid="EmploymentType">
                {this.state.loading && <Spinner spinnerModal={this.state.loading} />}
                <AppSidebar type="admin" openOrganization>
                    <Box margin="35px 0px" mr="30px">
                        <Box display={{ xs: "block", sm: "flex" }}
                            justifyContent="space-between"
                            mb="40px">
                            <Box>
                                <Box mb="5px"
                                    fontFamily="ProximaNova-Bold"
                                    fontSize={{ xs: "24px", sm: "30px", md: "44px" }} color="#1b3c69">{this.t("organizationFunction.employmentType.title.main")}</Box>
                                <Box color="#1b3c69"
                                    fontSize={{ xs: "14px", sm: "20px" }}
                                >{this.t("organizationFunction.employmentType.description.main")}</Box>
                            </Box>
                            <Box mt={{ xs: "20px", sm: "0px" }}>
                                <RedButton
                                    onClick={() => this.setState({ openDialogName: "Add" })}
                                    style={{ backgroundColor: "#f26a42" }}
                                >{this.t("organizationFunction.employmentType.button.Add")}</RedButton>
                            </Box>
                        </Box>
                        <Box mt="60px">
                            <Box>
                                <Grid container>
                                    <Grid item xs={12} className={LanguageData ? "SearchIconRightSide" : ""}>
                                        <Box mb="25px"
                                            display="flex"
                                            justifyContent="end"
                                            alignItems="center">
                                            <TextField
                                                InputLabelProps={{
                                                    style: {
                                                        fontFamily: "ProximaNova-Regular",
                                                        zIndex: 0
                                                    }
                                                }}
                                                onChange={(event: any) => {
                                                    this.onChangeHandler(event.target.value, 0);
                                                }}
                                                id="search"
                                                label={this.t("organizationFunction.employmentType.label.search")}
                                                InputProps={{
                                                    endAdornment: <SearchIcon />
                                                }}
                                                variant="outlined"
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} style={{ overflow: "auto" }}>
                                        {this.TableRender()}
                                        {this.state.data.length > 0 &&
                                            <div className="custom-pagination">
                                                <Pagination
                                                    onChange={(event: unknown, newPage: number) => {
                                                        this.onChangeHandler(this.state.search, newPage - 1);
                                                    }}
                                                    page={this.state.page + 1}
                                                    count={Math.ceil(this.state.dataLength / this.state.rowsPerPage)}
                                                    shape="rounded"
                                                    showFirstButton
                                                    showLastButton
                                                    siblingCount={0}
                                                    boundaryCount={1}
                                                    dir="ltr"
                                                    variant="outlined"
                                                />
                                            </div>
                                        }
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </Box>
                </AppSidebar>

                {/* for add/edit user */}
                {this.EditDilogRender()}

                {/* for delete */}
                {this.DeleteDilogRender()}
            </EmploymentTypeStyle>
        );
    }
}

export default withTranslation()(EmploymentType);

const CustomInputBase = styled(InputBase)({
    paddingLeft: "15px",
    border: "1px solid #787878",
    minHeight: "45px",
    borderRadius: "10px",
    fontSize: "16px",
    '& .MuiInputBase-inputMultiline': {
        "&::placeholder": {
            fontSize: "17px",
        }
    }
});

const EmploymentTypeStyle = styled('div')({
    '& .SearchIconRightSide': {
        '& .MuiInputBase-root': {
            display: "flex",
            flexDirection: "row-reverse"
        }
    }
})

const CustomButton = styled(Button)({
    backgroundColor: "white",
    fontSize: "18px",
    marginRight: "15px",
    borderRadius: "8px",
    border: "1px solid #f26a42",
    textTransform: "none",
    fontFamily: "ProximaNova-Semibold",
    color: "#f26a42",
    padding: "7px 17px",
    "&:hover": {
        color: "#f26a42",
        backgroundColor: "white",
    },
    "@media(max-width:600px)": {
        width: "100%",
    },
    "@media(max-width:992px)": {
        padding: "7px 15px",
    }
});

const RedButton = styled(Button)({
    fontSize: "18px",
    backgroundColor: "#f94b4b",
    color: "white",
    borderRadius: "10px",
    fontFamily: "ProximaNova-Semibold",
    textTransform: "none",
    padding: "9px 30px",
    "&:hover": {
        color: "white",
        backgroundColor: "#f94b4b",
    },
    "@media(max-width:600px)": {
        width: "100%",
        marginTop: "27px",
    }
});
// Customizable Area End