import React from "react";
// Customizable Area Start
// import Button from '@material-ui/core/Button';
import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import ManageAssessorController from "./AddAssessorController.web"
import { TextField, Grid, Button } from "@material-ui/core";
import { Formik } from "formik";
import "./addAssessor.web.css";
import { addAssessorSchema } from "./validationSchema";
// Customizable Area End

// Customizable Area Start

class AddNewAssessor extends ManageAssessorController {
  constructor(props: any) {
    super(props);
  }

  handleConditionCheck = (fn: any, fnerr: any, ln: any, lnerr: any) => {
    return fn && !fnerr && ln && !lnerr
  }

  onFirstNameBlur = (props: any) => {
    (props.values.first_name && !props.errors.first_name) &&
      (props.values.last_name && !props.errors.last_name) && this.setNewPassword(props.setFieldValue);
    (props.values.first_name && !props.errors.first_name) &&
      (props.values.last_name && !props.errors.last_name) && this.setUsername(
        props.setFieldValue,
        props.values.first_name,
        props.values.last_name
      );
  }

  onLastNameBlur = (props: any) => {
    (props.values.first_name && !props.errors.first_name) &&
      (props.values.last_name && !props.errors.last_name) &&
      this.setNewPassword(props.setFieldValue);
    (props.values.first_name && !props.errors.first_name) &&
      (props.values.last_name && !props.errors.last_name) &&
      this.setUsername(
        props.setFieldValue,
        props.values.first_name,
        props.values.last_name
      );
  }

  getErrorMessages = (props: any, value: string) => {
    return props.errors[value] && props.touched[value] && (
      <p className="validation_errors">
        {props.errors[value]}
      </p>
    )
  }
  nameBlur = (e: any, props: any) => {
    props.handleBlur(e);
    (props.values.first_name && !props.errors.first_name) &&
      (props.values.last_name && !props.errors.last_name) && this.setNewPassword(props.setFieldValue);
    (props.values.first_name && !props.errors.first_name) &&
      (props.values.last_name && !props.errors.last_name) && this.setUsername(
        props.setFieldValue,
        props.values.first_name,
        props.values.last_name
      );
  }
  renderGrid = (props: any) => {
    return (
      <Grid item xs={6}>
        <label className="labels">
          {this.t("manageAssessor.label.firstName")}<span style={{ color: "#f94b4b" }}>*</span>
        </label>
        <Box className="textfield-parent">
          <TextField
            name="first_name"
            id="first_name"
            value={props.values.first_name}
            onChange={props.handleChange}
            onBlur={(e) => {
              this.nameBlur(e, props)

            }}
            className="client-textfields"
            variant="outlined"
            fullWidth
            style={{
              border: props.errors.first_name && props.touched.first_name
                ? "2px solid red"
                : "",
            }}
          />
          {props.errors.first_name && props.touched.first_name ? (
            <p className="validation_errors">
              {props.errors.first_name}
            </p>
          ) : null}
        </Box>
      </Grid>
    )
  }

  render() {
    return (
      <Box className="add-client">
        <Button
          onClick={this.handleOpenModal}
          style={{
            fontFamily: "ProximaNova-Semibold !important",
            fontSize: "22px",
            padding: "10px",
            marginLeft: "10px",
          }}
          className="ModalBtn"
          variant="outlined"
        >
          {this.t("manageAssessor.button.add")}
        </Button>
        <Dialog onClose={this.handleCloseModal2} aria-labelledby="customized-dialog-title" open={this.state.openModal}>
          <Box className="dialog-title" sx={{ display: "flex", justifyContent: "space-between" }}>
            <DialogTitle
              className="modal-title" style={{fontSize: "30px !important", marginLeft: "20px", fontFamily: "ProximaNova-Bold !important"}}
              id="customized-dialog-title"
            >
              {this.t("manageAssessor.button.add")}
            </DialogTitle>
            <Box style={{ margin: "20px", cursor: "pointer",
                fontSize: "30px !important", color: "rgba(0, 0, 0, 0.5)", }} onClick={this.handleCloseModal2}>
              <span style={{ color: "rgba(0, 0, 0, 0.5)", fontSize: "30px !important",display: "inline-block", }}>X</span>
            </Box>
          </Box>
          <DialogContent style={{ padding: "2.5rem", marginTop: "-30px" }}>
            <Formik
              validationSchema={() => addAssessorSchema(this.t)}
              initialValues={{
                full_name: "", first_name: "", last_name: "", email: "", user_name: "",password: "",
              }}
              validateOnChange={true}
              validateOnBlur={true}
              onSubmit={(values) => {
                this.sentMessage("SAVENEWPARTICIPANT");
                this.addAssessorData(values);
              }}
            >
              {(props) => (
                <form onSubmit={props.handleSubmit}>
                  <Grid className="label-parent" container spacing={3}>
                    {this.renderGrid(props)}

                    <Grid item xs={6}>
                      <label className="labels">
                        {this.t("manageAssessor.label.lastName")}<span style={{ color: "#f94b4b" }}>*</span>
                      </label>
                      <Box className="textfield-parent">
                        <TextField
                          name="last_name"
                          id="last_name"
                          value={props.values.last_name}
                          onChange={props.handleChange}
                          onBlur={(e) => {
                            props.handleBlur(e);
                            this.onLastNameBlur(props);
                          }}
                          className="client-textfields"
                          variant="outlined"
                          fullWidth
                          style={{
                            border:
                              props.errors.last_name && props.touched.last_name
                                ? "2px solid red"
                                : "",
                          }}
                        />{this.getErrorMessages(props, "last_name")}
                      </Box>
                    </Grid>

                    <Grid item xs={6}>
                      <label className="labels">
                        {this.t("manageAssessor.label.emailId")}<span style={{ color: "#f94b4b" }}>*</span>
                      </label>
                      <Box className="textfield-parent">
                        <TextField
                          name="email"
                          id="email"
                          value={props.values.email}
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          className="client-textfields"
                          fullWidth
                          variant="outlined"
                          placeholder=""
                          style={{
                            border:
                              props.errors.email && props.touched.email
                                ? "2px solid red"
                                : "",
                          }}
                        />
                        {this.getErrorMessages(props, "email")}
                      </Box>
                    </Grid>

                    <Grid item xs={6}>
                      <label className="labels">
                        {this.t("manageAssessor.label.username")} <span style={{ color: "#f94b4b" }}>*</span>
                      </label>
                      <Box className="textfield-parent">
                        <TextField
                          name="user_name"
                          id="user_name"
                          value={props.values.user_name}
                          onBlur={props.handleBlur}
                          InputProps={{ readOnly: true }}
                          className="client-textfields"
                          variant="outlined"
                          fullWidth
                          style={{
                            border:
                              props.errors.user_name && props.touched.user_name
                                ? "2px solid red"
                                : "",
                            backgroundColor: "#eff6ff",
                          }}
                        />
                        {this.getErrorMessages(props, "user_name")}
                      </Box>
                    </Grid>

                    <Grid item xs={12}>
                      <label className="labels">
                        {this.t("manageAssessor.label.passwordLabel")}<span style={{ color: "#f94b4b" }}>*</span>
                      </label>
                      <Box className="textfield-parent">
                        <TextField
                          name="password"
                          id="password"
                          InputProps={{ readOnly: true }}
                          value={props.values.password}
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          className="client-textfields"
                          fullWidth
                          variant="outlined"
                          placeholder=""
                          style={{
                            border:
                              props.errors.password && props.touched.password
                                ? "2px solid red"
                                : "",
                            backgroundColor: "#eff6ff",
                          }}
                        />
                      </Box>
                    </Grid>



                    <Grid item xs={3}>
                      <Button
                        fullWidth
                        onClick={this.handleCloseModal2}
                        variant="outlined"
                        style={{
                          border: "1px solid #f94b4b", color: "#f94b4b", borderRadius: "10px", padding: "8px 30px", marginTop: "20px",
                          fontWeight: "bold", textTransform: "capitalize", fontFamily: "ProximaNova-Semibold", fontSize: "18px",
                          lineHeight: 1, minHeight: '54px'
                        }}
                      >
                        {this.t("manageAssessor.button.cancel")}
                      </Button>
                    </Grid>
                    <Grid item xs={3}>
                      <Button type="submit" fullWidth  
                      variant="contained" className="AddParticipantBtn"
                      >
                        {this.t("manageAssessor.button.save")}
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              )}
            </Formik>
          </DialogContent>
        </Dialog>
      </Box>
    );
  }
}

export default AddNewAssessor;
// Customizable Area End
