import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { runEngine } from "../../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { toast } from "react-toastify";
// Customizable Area End

export const configJSON = require("../config");

export interface Props {
  id: string;
  navigation: any;
  t: any;
}

export interface S {
  // Customizable Area Start
  loading: boolean;
  page: number;
  rowsPerPage: number;
  dataLength: number;
  count: number | null;
  competencyId: any;
  item: any;
  name: string;
  description: string;
  search: string;
  openDialogName: string | null;
  competencyFile: any;
  dragActive: boolean;
  uploadSubmitBtn: boolean;
  isReuploadClicked: boolean;
  successRecord: number;
  filterData: any[];
  totalRecord: number;
  errorCSVFile: any;
  errorFileObj: string | null;
  tableData: any[];
  data: any[];
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class ManageCompetencyController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getCompetencyListApiCallId: string = "";
  addCompetencyApiCallId: string = "";
  editCompetencyApiCallId: string = "";
  deleteCompetencyApiCallId: string = "";
  addBulkCompetencyApiCallId: string = "";
  deleteErrorCSVApiCallId: string = "";
  t: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      loading: false,
      page: 0,
      rowsPerPage: 20,
      dataLength: 0,
      count: null,
      competencyId: null,
      item: null,
      name: "",
      description: "",
      search: "",
      openDialogName: null,
      competencyFile: null,
      dragActive: false,
      uploadSubmitBtn: false,
      isReuploadClicked: false,
      successRecord: 0,
      totalRecord: 0,
      filterData: [],
      errorCSVFile: null,
      errorFileObj: null,
      tableData: [],
      data: [],
      // Customizable Area End
    };
    this.t = this.props.t;
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      if (apiRequestCallId && responseJson) {
        this.newApicallFuncation(apiRequestCallId, responseJson)
        this.setState({ loading: false });
      }
    }
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    this.getCompetencyList();
  }

  newApicallFuncation = (apiRequestCallId: any, responseJson: any) => {
    if (responseJson.status === 500) {
      this.setState({ loading: false });
      toast.error(this.t("manageCompetency.toast.somethingWrong"));
    } else if (!Array.isArray(responseJson.errors) && responseJson.errors) {
      toast.error(responseJson.errors);
    } else {
      this.handleApiResponses(apiRequestCallId, responseJson);
    }
  }

  handleApiResponses = (apiRequestCallId: string, responseJson: any) => {
    if (Array.isArray(responseJson.errors)) {
      if (responseJson.errors[0].message) {
        toast.error(responseJson.errors[0].message);
      } 
      if (responseJson.errors[0]?.name?.error) {
        this.setState({ loading: false });
        toast.error(this.t("manageCompetency.errorMessages.Namealreadyexists"));
      } else if (responseJson.errors[0].name) {
        toast.error(responseJson.errors[0].name);
      } else {
        toast.error(this.t("manageCompetency.toast.somethingWrong"));
        this.setState({ loading: false });
      }
      this.setState({ loading: false });
    } else if (apiRequestCallId === this.getCompetencyListApiCallId) {
      this.handleCompetencyListApiResponse(responseJson);
    } else if (apiRequestCallId === this.addCompetencyApiCallId) {
      this.handleAddCompetencyApiResponse(responseJson);
    } else if (apiRequestCallId === this.editCompetencyApiCallId) {
      this.handleEditCompetencyApiResponse(responseJson);
    } else if (apiRequestCallId === this.deleteCompetencyApiCallId) {
      this.handleDeleteCompetencyApiResponse();
    } else if (apiRequestCallId === this.addBulkCompetencyApiCallId) {
      this.handleBulkCompetencyApiResponse(responseJson);
    }
  }

  handleCompetencyListApiResponse = (responseJson: any) => {
    const data = responseJson.data.map((item: any) => {
      return {
        id: item.id,
        name: item.attributes.name,
        description: item.attributes.description
      }
    });
    this.setState({ tableData: data, filterData: data, loading: false },
      () => { this.onChangeHandler(this.state.search, 0) }
    );
  }

  handleAddCompetencyApiResponse = (responseJson: any) => {
    toast.success(this.t("manageCompetency.toast.add"));
    let tableData = [...this.state.tableData];
    const obj = {
      id: responseJson.data.id,
      name: responseJson.data.attributes.name,
      description: responseJson.data.attributes.description
    };
    tableData = [obj, ...tableData];
    this.setState({ tableData, filterData: tableData, competencyId: null, name: "", description: "", openDialogName: null, loading: false },
      () => { this.onChangeHandler(this.state.search, 0) }
    );
  }

  handleEditCompetencyApiResponse = (responseJson: any) => {
    toast.success(this.t("manageCompetency.toast.edit"));
    let tableData = [...this.state.tableData];
    tableData = tableData.map((item: any) => {
      if (item.id === this.state.competencyId) {
        return {
          id: responseJson.data.id,
          name: responseJson.data.attributes.name,
          description: responseJson.data.attributes.description
        };
      } else {
        return item;
      }
    });

    this.setState({ tableData, filterData: tableData, competencyId: null, name: "", description: "", openDialogName: null, loading: false },
      () => { this.onChangeHandler(this.state.search, this.state.page) }
    );
  }

  handleDeleteCompetencyApiResponse = () => {
    toast.success(this.t("manageCompetency.toast.delete"));
    let tableData = [...this.state.tableData];
    tableData = tableData.filter((item: any) => item.id !== this.state.competencyId);
    this.setState({ tableData, filterData: tableData, competencyId: null, name: "", description: "", openDialogName: null, loading: false },
      () => { this.onChangeHandler(this.state.search, this.state.page) }
    );
  }

  handleBulkCompetencyApiResponse = (responseJson: any) => {
    const successRecord = responseJson.success;
    const errorRecord = responseJson.failed;
    if (!successRecord && !errorRecord) {
      toast.error(responseJson.message);
      this.setState({ openDialogName: null, competencyFile: null, uploadSubmitBtn: false, loading: false });
    } else if (errorRecord === 0) {
      toast.success(this.t("manageCompetency.toast.allAdded"));
      this.setState({ openDialogName: null, competencyFile: null, uploadSubmitBtn: false, loading: false }, () => this.getCompetencyList());
    } else {
      this.setState({
        successRecord,
        errorCSVFile: responseJson.url,
        totalRecord: successRecord + errorRecord,
        openDialogName: "CSVError"
      });
      this.setState({ openDialogName: "CSVError", loading: false });
    }
  }

  onChangeHandler = (search: string, page: number) => {
    const { filterData, rowsPerPage } = this.state;
    let data = filterData.filter((item: any) => {
      if (search === "") {
        return true;
      } else if (
        item.name.toString().toLowerCase().includes(search.toLowerCase()) ||
        item.description.toString().toLowerCase().includes(search.toLowerCase())) {
        return item;
      }
    });

    const dataLength = data.length;
    let totalPage = Math.ceil(dataLength / this.state.rowsPerPage);
    page = totalPage === page ? page - 1 : page;
    data = data.slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    );
    this.setState({ data, dataLength, page, search });
  }

  handleDrag = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragleave") {
      this.setState({ dragActive: false });
    } else if (e.type === "dragenter" || e.type === "dragover") {
      this.setState({ dragActive: true });
    }
  };

  handleDrop = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      dragActive: false,
      competencyFile: e.dataTransfer.files[0]
    });
  };

  onCSVFileDownload = () => {
    const link = document.createElement('a');
    link.href = this.state.errorCSVFile;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  getCompetencyList = () => {
    this.setState({ loading: true, isReuploadClicked: false });

    const header = {
      "Content-Type": configJSON.competencyListContentType,
      token: localStorage.getItem("accesToken")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCompetencyListApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.competencyListApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.competencyListApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  addCompetency = () => {
    this.setState({ loading: true });

    const header = {
      "Content-Type": configJSON.addCompetencyApiContentType,
      token: localStorage.getItem("accesToken")
    };

    const httpBody = {
      "name": this.state.name,
      "description": this.state.description
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addCompetencyApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addCompetencyApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.addCompetencyApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  editCompetency = () => {
    this.setState({ loading: true });

    const header = {
      "Content-Type": configJSON.editCompetencyApiContentType,
      token: localStorage.getItem("accesToken")
    };

    const httpBody = {
      "name": this.state.name,
      "description": this.state.description
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.editCompetencyApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.editCompetencyApiEndPoint}/${this.state.competencyId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.editCompetencyApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  deleteCompetency = () => {
    this.setState({ loading: true });
    const header = {
      "Content-Type": configJSON.deleteCompetencyApiContentType,
      token: localStorage.getItem("accesToken")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteCompetencyApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deleteCompetencyApiEndPoint}/${this.state.competencyId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteCompetencyApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  addBulkCompetency = () => {
    if (!this.state.competencyFile) {
      this.setState({ uploadSubmitBtn: true });
      return;
    }

    this.setState({ loading: true });

    const header = {
      token: localStorage.getItem("accesToken")
    };

    const formData = new FormData();
    formData.append("file", this.state.competencyFile);

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addBulkCompetencyApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addBulkCompetencyApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.addBulkCompetencyApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
}
// Customizable Area End