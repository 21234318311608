import React from "react";
// Customizable Area Start
// import Button from '@material-ui/core/Button';
import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import AddParticipantController from "./AddParticipantController.web";
import { TextField, Grid, Button, Select, MenuItem, Typography } from "@material-ui/core";
import { Formik } from "formik";
import "./addNewParticipant.web.css";
import { addParticipantSchema } from "./validationSchema";
import CloseIcon from '@material-ui/icons/Close';
import { styled } from "@material-ui/styles";
import AsynSelect from "react-select";
const help = require("../assets/error.png");
const download = require("../assets/download.svg")
const locallanguage = localStorage.getItem("language") === "ar";
//@ts-ignore
import { CSVLink } from 'react-csv';
const uploadIcon = require("../assets/upload.png");

// Customizable Area End

// Customizable Area Start

class AddNewParticipant extends AddParticipantController {
  constructor(props: any) {
    super(props);
  }


  handleBlurFunction = (e: any, props: any) => {
    props.handleBlur(e);
    (props.values.first_name && !props.errors.first_name) &&
      (props.values.last_name && !props.errors.last_name) && this.setNewPassword(props.setFieldValue);
    (props.values.first_name && !props.errors.first_name) &&
      (props.values.last_name && !props.errors.last_name) &&
      this.setUsername(
        props.setFieldValue,
        props.values.first_name,
        props.values.last_name
      );
  }

  getErrorMessage = (touched: any, errors: any, value: string) => {
    return touched[value] && errors[value] &&
      <div className="text-danger">{errors[value]}</div>
  }
  getRedBorder = (props: any, value: string) => {
    return (props.errors[value] && props.touched[value]) ? "2px solid red" : ""
  }

  renderAddParticipantDialog = () => {
    return (
      <Dialog
        scroll="body"
        open={this.state.openDialogName === "addParticipant"}
        fullWidth={false}
        aria-labelledby="scroll-dialog-title"
        maxWidth={"sm"}
        onClose={() => { }}
        aria-describedby="scroll-dialog-description"
      >
        <DialogContent style={{ padding: "0px", maxWidth: "500px", width: "100%" }}>
          <Box style={{ textAlign: "right", margin: "10px 10px 0px 0px" }}>
            <CloseIcon
              onClick={() => this.setState({ openDialogName: "", participantFile: null })}
              style={{ cursor: "pointer", fontSize: "35px", color: "#787878" }} />
          </Box>
          <Box padding={{ xs: "15px 25px 25px", sm: "15px 40px 40px" }}>
            <Box
              id="modal-title"
              fontWeight="bolder" fontSize={{ xs: "20px", sm: "30px" }}
              fontFamily="ProximaNova-Bold" mb="20px"
              style={{
                textAlign:locallanguage ? "right": "unset"
              }}
            >
              {this.t("manageParticipant.title.addOption")}
            </Box>
            <Typography style={{
                textAlign:locallanguage ? "right": "unset"
              }} variant="body2" id="modal-description">
              {this.t("manageParticipant.label.singleOption")}<span style={{ color: "red" }}>*</span>
            </Typography>
            <Box mt="5px" display={{ xs: "block", sm: "flex" }}>
              <CustomButton style={{ marginLeft:locallanguage ? "10px" : "unset"}}
                onClick={() => this.setState({ openDialogName: "Upload" })}>{this.t("manageParticipant.button.uploadParticipant")}</CustomButton>
              <RedButton
                onClick={() => this.setState({ openDialogName: "Add" })}>{this.t("manageParticipant.button.singleParticipant")}</RedButton>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    );
  }

  renderErrorDialog = () => {

    const { fail_count, file_path, success_count } = this.state.uploadStatus;
    return (
      <Dialog
        open={this.state.openDialogName === "error"}
        fullWidth={false}
        maxWidth={"sm"}
        scroll="body"
        onClose={() => { }}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogContent style={{ padding: "0px" }}>
          <Box style={{ textAlign: "right", margin: "10px 10px 0px 0px" }}>
            <CloseIcon
              onClick={() => this.setState({ openDialogName: "", participantFile: null })}
              style={{ cursor: "pointer", fontSize: "35px", color: "#787878" }} />
          </Box>
          <Box padding={{ xs: "15px 25px 25px", sm: "15px 40px 40px" }}>
            <div className="imageWrapper">
              <img src={help} className="helpModal" />
            </div>
            <Typography
              className="helpModalTitle"
              variant="h6"
              component="h2"
              style={{ color: "#f94b4b" }}
            >
              {this.t("manageParticipant.title.error")}
            </Typography>
            <Typography style={{ textAlign: "center" }}>{this.t("manageParticipant.label.uploadError", { success: success_count, total: fail_count + success_count })}</Typography>
            <Box style={{ textAlign: "center", display: "flex", alignItems: "center", justifyContent: "center", gap: "1rem", color: "#f94b4b" }}>
              <img src={download} />
              <a target="_blank" href={file_path} style={{ cursor: "pointer", color: "#f94b4b" }} onClick={this.deleteCSVFunction}>participant.csv</a>
            </Box>

            <Box mt="5px" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              <CustomButton id="cancelBtn" style={{ marginLeft: locallanguage ? "10px" : "" }} onClick={() => this.setState({ openDialogName: "", participantFile: null })}>{this.t("manageParticipant.button.cancel")}</CustomButton>
              <RedButton id="addBtn" style={{ padding: "5px 15px" }} onClick={() => this.setState({ openDialogName: "addParticipant" })}>{this.t("manageParticipant.button.reupload")}</RedButton>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    );
  }

  renderUploadParticipantDialog = () => {
    return (
      <Dialog
        open={this.state.openDialogName === "Upload"}
        fullWidth={false}
        maxWidth={"sm"}
        scroll="body"
        onClose={() => { }}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogContent style={{ padding: "0px" }} onDragEnter={this.handleDrag}
          onDragOver={this.handleDrag}>
          <Box style={{
            textAlign: "right",
            margin: "10px 10px 0px 0px"
          }}>
            <CloseIcon
              onClick={() => this.setState({
                openDialogName: "",
                participantFile: null
              })} style={{ fontSize: "35px", color: "#787878", cursor: "pointer" }} />
          </Box>
          <Box padding={{ xs: "15px 25px 25px", sm: "15px 40px 40px" }}>
            <Box
              id="modal-title"
              fontWeight="bolder"
              fontSize={{ xs: "20px", sm: "30px" }}
              fontFamily="ProximaNova-Bold"
              mb="20px"
              style={{ textAlign : locallanguage ? "right" : "unset"}}
            >
              {this.t("manageParticipant.title.upload")}
            </Box>
            <Box display="flex" alignItems={{ xs: "start", sm: "center" }}
              justifyContent="space-between"
              flexDirection={{ xs: "column-reverse", sm: "row" }}>
              <Typography variant="body2" id="modal-description">
                {this.t("manageParticipant.label.dropCsv")}<span style={{ color: "red" }}>*</span>
              </Typography>
              <Box mb={{ xs: "10px", sm: "0px" }}>
                <CSVLink
                  data={SampleFileData}
                  target="_blank"
                  filename={'Upload Participant.csv'}
                  style={{
                    borderBottom: "1px solid blue"
                  }}
                >
                  {this.t("manageParticipant.label.sampleFile")}
                </CSVLink>
              </Box>
            </Box>
            <Box mt="15px" mb="25px" className="dragElement">
              <input
                type="file"
                accept=".csv"
                style={{ display: "none" }}
                id="participantFile"
                onChange={(event: any) => {
                  this.setState({
                    participantFile: event.target.files[0]
                  });
                }}
              />
              <label htmlFor="participantFile" style={{ width: "100%" }} className={this.state.dragActive ? "drag-active label-file-upload" : "label-file-upload"}>
                <Box p={{ xs: "25px", sm: "25px 105px" }} textAlign="center" width="100%" border="1px dashed #bebebe" borderRadius="8px" style={{ cursor: "pointer" }}>
                  <img src={uploadIcon} />
                  <Box mt="15px">{this.t("manageParticipant.label.dropFile")}</Box>
                </Box>
              </label>
              {this.state.dragActive && <div
                className="drag-file-element"
                onDragEnter={(event) =>
                  this.handleDrag(event)}
                onDragLeave={(event) =>
                  this.handleDrag(event)} onDragOver={(event) =>
                    this.handleDrag(event)} onDrop={(event) =>
                      this.handleDrop(event, "csv")}>
              </div>}
              <Box mt="3px">{this.state.participantFile?.name}</Box>
              {(this.state.uploadSubmitBtn && !this.state.participantFile) && <div className="text-danger">{this.t("manageParticipant.errorMessages.addFile")}</div>}
            </Box>
            <Box mt="5px" display={{ xs: "block", sm: "flex" }}>
              <CustomButton id="cancelUpload" style={{ marginLeft: locallanguage ? "10px" : "" }} onClick={() => this.setState({ openDialogName: "", participantFile: null })}>{this.t("manageParticipant.button.cancel")}</CustomButton>
              <RedButton id="submitUpload" style={{ padding: "5px 15px" }} onClick={this.addBulkParticipant}>{this.t("manageParticipant.button.submit")}</RedButton>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    );
  }

  renderUploadSingleParticipant = () => {
    return (
      <Dialog
        onClose={this.handleCloseModal2}
        aria-labelledby="customized-dialog-title"
        open={this.state.openDialogName === "Add"}
      >
        <Box
          className="dialog-title"
          sx={{
            display: "flex",
            justifyContent: "space-between"
          }}
        >
          <DialogTitle
            style={{
              fontSize: "30px !important",
              fontFamily: "ProximaNova-Bold !important",
              marginLeft: "20px",
            }}
            className="modal-title"
            id="customized-dialog-title"
          >
            {this.t("manageParticipant.title.addOption")}
          </DialogTitle>
          <Box
            style={{
              margin: "20px",
              fontSize: "30px !important",
              cursor: "pointer",
              color: "rgba(0, 0, 0, 0.5)",
            }}
            onClick={this.handleCloseModal2}
          >
            <span
              style={{
                color: "rgba(0, 0, 0, 0.5)",
                fontSize: "30px !important",
                display: "inline-block",
              }}
              data-testid="addNewParticipantHeading"
            >
              X
            </span>
          </Box>
        </Box>
        <DialogContent style={{ padding: "2.5rem", marginTop: "-30px" }}>
          <Formik
            initialValues={{
              first_name: "",
              last_name: "",
              user_name: "",
              email: "",
              password: "",
              client_id: "",
            }}
            validationSchema={() => addParticipantSchema(this.t)}
            onSubmit={(values) => {
              this.sentMessage("SAVENEWPARTICIPANT")
              this.addParticipantData(values);
            }}
            validateOnChange={true}
            validateOnBlur={true}
          >
            {(props) => (
              <form onSubmit={props.handleSubmit}>
                <Grid className="label-parent" container spacing={3}>
                  <Grid item xs={6}>
                    <label className="labels">
                      {this.t("manageParticipant.label.firstName")}<span style={{ color: "#f94b4b" }}>*</span>
                    </label>
                    <Box className="textfield-parent">
                      <TextField
                        name="first_name"
                        value={props.values.first_name}
                        onBlur={(e) => {
                          this.handleBlurFunction(e, props)
                        }}
                        onChange={props.handleChange}
                        id="first_name"
                        className="client-textfields"
                        fullWidth
                        variant="outlined"
                        style={{ border: this.getRedBorder(props, "first_name") }}
                      />
                      {this.getErrorMessage(props.touched, props.errors, "first_name")}
                    </Box>
                  </Grid>

                  <Grid item xs={6}>
                    <label className="labels">
                      {this.t("manageParticipant.label.lastName")}<span style={{ color: "#f94b4b" }}>*</span>
                    </label>
                    <Box className="textfield-parent">
                      <TextField
                        id="last_name"
                        value={props.values.last_name}
                        onChange={props.handleChange}
                        onBlur={(e) => {
                          this.handleBlurFunction(e, props)
                        }}
                        name="last_name"
                        fullWidth
                        className="client-textfields"
                        style={{ border: this.getRedBorder(props, "last_name") }}
                        variant="outlined"
                      />
                      {this.getErrorMessage(props.touched, props.errors, "last_name")}
                    </Box>
                  </Grid>

                  <Grid item xs={6}>
                    <label className="labels">
                      {this.t("manageParticipant.label.userNameLab")} <span style={{ color: "#f94b4b" }}>*</span>
                    </label>
                    <Box className="textfield-parent">
                      <TextField
                        name="user_name"
                        id="user_name"
                        value={props.values.user_name}
                        onBlur={props.handleBlur}
                        InputProps={{ readOnly: true }}
                        className="client-textfields"
                        variant="outlined"
                        fullWidth
                        style={{ border: this.getRedBorder(props, "user_name"), backgroundColor: "#eff6ff" }}
                      />
                      {this.getErrorMessage(props.touched, props.errors, "user_name")}
                    </Box>
                  </Grid>

                  <Grid item xs={6}>
                    <label className="labels">
                      {this.t("manageParticipant.label.email")}<span style={{ color: "#f94b4b" }}>*</span>
                    </label>
                    <Box className="textfield-parent">
                      <TextField
                        name="email"
                        placeholder=""
                        value={props.values.email}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        className="client-textfields"
                        fullWidth
                        variant="outlined"
                        id="email"
                        style={{ border: this.getRedBorder(props, "email") }}
                      />
                      {this.getErrorMessage(props.touched, props.errors, "email")}
                    </Box>
                  </Grid>

                  <Grid item xs={6}>
                    <label className="labels">
                      {this.t("manageParticipant.label.passwordLab")}<span style={{ color: "#f94b4b" }}>*</span>
                    </label>
                    <Box className="textfield-parent">
                      <TextField
                        name="password"
                        id="password"
                        value={props.values.password}
                        onBlur={props.handleBlur}
                        className="client-textfields"
                        fullWidth
                        variant="outlined"
                        placeholder=""
                        InputProps={{ readOnly: true }}
                        style={{ border: this.getRedBorder(props, "password"), backgroundColor: "#eff6ff" }}
                      />
                      {this.getErrorMessage(props.touched, props.errors, "password")}
                    </Box>
                  </Grid>

                  <Grid item xs={6}>
                    <label className="labels">
                      {this.t("manageParticipant.label.orgName")}
                      <span style={{ color: "#f94b4b" }}>*</span>
                    </label>
                    <Box className="textfield-parent" style={{ marginTop: "15px" }}>
                      <Mangeparticepent>
                        <AsynSelect
                          classNamePrefix="react-select"
                          autofocus={true}
                          isSearchable={true}
                          isClearable={true}
                          maxHeight={200}
                          className="AsyncSelect"
                          name="client_id"
                          id="client_id"
                          openOnFocus={false}
                          options={this.state.allClients.map((item: any) => {
                            return {
                              label: item.attributes.full_name,
                              value: item.attributes.id
                            }
                          })}
                          value={
                            this.state.allClients.map((item: any) => {
                              return {
                                label: item.attributes.full_name,
                                value: item.attributes.id
                              }
                            }).filter(
                              (option: any) => option.value === this.state.filterselectedclinetname
                            )[0] ?? []
                          }
                          onChange={(e) => this.handleFilterBy(e, props)}
                          onBlur={props.handleBlur}
                        />
                      </Mangeparticepent>
                    </Box>
                    {this.getErrorMessage(props.touched, props.errors, "client_id")}
                  </Grid>

                  <Grid item xs={3}>
                    <Button
                      fullWidth
                      onClick={this.handleCloseModal2}
                      style={{
                        border: "1px solid #f94b4b",
                        fontSize: "18px",
                        borderRadius: "10px",
                        padding: "8px 30px",
                        marginTop: "20px",
                        fontWeight: "bold",
                        color: "#f94b4b",
                        textTransform: "capitalize",
                        fontFamily: "ProximaNova-Semibold",
                        lineHeight: 1,
                        minHeight: '54px'
                      }}
                      variant="outlined"
                    >
                      {this.t("manageParticipant.button.cancel")}
                    </Button>
                  </Grid>
                  <Grid item xs={3}>
                    <Button
                      variant="contained"
                      type="submit"
                      className="AddParticipantBtn"
                      fullWidth
                    >
                      {this.t("manageParticipant.button.add")}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    )
  }

  render() {
    return (
      <Box className="add-client">
        <Button
          onClick={() => this.setState({ openDialogName: "addParticipant" })}
          style={{
            fontFamily: "ProximaNova-Semibold",
            fontSize: "22px",
            padding: "10px",
            marginLeft: "10px",
          }}
          className="ModalBtn"
          variant="outlined"
        >
          {this.t("manageParticipant.button.addParticipant")}
        </Button>
        {this.renderAddParticipantDialog()}
        {this.renderUploadSingleParticipant()}
        {this.renderUploadParticipantDialog()}
        {this.renderErrorDialog()}

      </Box>
    );
  }
}

const SampleFileData = [
  ["first_name", "last_name", "email", "client_full_name"],
  ["Participant 1", "Sharma", "participant1@yopmail.com", "Bosch"],
  ["Participant 2", "Advani", "participant2@yopmail.com", "Bosch"],
];

const CustomButton = styled(Button)({
  border: "1px solid #f94b4b",
  color: "#f94b4b",
  textTransform: "none",
  fontFamily: "ProximaNova-Semibold",
  marginRight: "15px",
  borderRadius: "8px",
  fontSize: "18px",
  backgroundColor: "white",
  padding: "4px 15px",
  "&:hover": {
    color: "#f94b4b",
    backgroundColor: "white",
  },
  "@media(max-width:992px)": {
    padding: "8px",
    width: "50%"
  },
  "@media(max-width:576px)": {
    width: "100%",
    marginBottom: "20px",
    padding: "3px",
  },
});

const StyleAddNewParticipant = styled("div")({

})

const RedButton = styled(Button)({
  padding: "5px 25px",
  borderRadius: "8px",
  fontFamily: "ProximaNova-Semibold",
  backgroundColor: "#f94b4b",
  fontSize: "18px",
  color: "white",
  textTransform: "none",
  "&:hover": {
    color: "white",
    backgroundColor: "#f94b4b",
  },
  "@media(max-width:992px)": {
    padding: "8px",
    width: "50%"
  },
  "@media(max-width:576px)": {
    width: "100%",
    padding: "3px",
  },
});

const Mangeparticepent = styled('div')({
  '& .AsyncSelect':{
    borderRadius: '15px',
    boxShadow: '0',
    marginTop: '15px !important',
    '& .react-select__control':{
      border:"1px solid #787878 !important"
    },
  },
})

export default AddNewParticipant;
// Customizable Area End
