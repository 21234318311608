// Customizable Area Start
import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { toast } from "react-toastify";
import { Surface, Symbols } from "recharts"

export const configJSON = require("./config");

export interface Props {
    navigation?: any;
    id?: string;
    t: any;
}

interface S {
    loader: boolean;
    selectedValue: string;
    anchorEl: any;
    error: boolean;
    data: any;
    industryData: any,
    expanded: string;
    optionList: any[];
    functionData: any,
    sectorData: any,
    sortBy: string;
    option: string;
    search: string;
}

interface SS {
    id: any;
}

const genderList = [
    {
        label: "Male",
        value: "male"
    },
    {
        label: "Female",
        value: "female"
    },
    {
        label: "Other",
        value: "other"
    },
]

export default class AnalyticsController extends BlockComponent<
    Props,
    S,
    SS
> {
    getIndustryAPICall: string = '';
    getAnalyticsAPICall: string = '';
    getFunctionAPICall: string = '';
    getSectorAPICall: string = '';
    getHighestEduAPICall: string = '';
    t: any = '';


    constructor(props: Props) {

        super(props);
        this.receive = this.receive.bind(this)


        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.PostDetailDataMessage)
        ]



        this.state = {
            loader: true,
            expanded: 'all',
            sectorData: null,
            selectedValue: 'all',
            data: [],
            option: "",
            error: false,
            functionData: null,
            industryData: null,
            anchorEl: null,
            sortBy: "",
            search: "",
            optionList: [],

        };
        this.t = this.props.t;
        // @ts-ignore
        this.callRef = React.createRef();
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        const { expanded } = this.state;
        this.getAnalyticsData(expanded, "all")
        this.getSectorData();
        this.getIndustryData();
        this.getFunctionData();
    }


    async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
            if (apiRequestCallId === this.getAnalyticsAPICall) {
                if (responseJson != null && !responseJson.message) {
                    this.setState({ data: responseJson, loader: false, error: false })
                }
                else {
                    this.setState({ error: true })
                }
            }
            if (apiRequestCallId === this.getFunctionAPICall) {
                if (responseJson != null && !responseJson.message) {
                    const functionData = responseJson.data.map((item: any) => {
                        return {
                            label: item.attributes.name, value: item.id,
                        }
                    });
                    this.setState({ functionData, loader: false, error: false })
                }
                else {
                    this.setState({ error: true })
                }
            }
            this.checkFetchedData(apiRequestCallId, responseJson)
        }
    }

    checkFetchedData = (apiRequestCallId: any, responseJson: any) => {
        if (apiRequestCallId === this.getSectorAPICall) {
            if (responseJson != null && !responseJson.message) {
                const sectorData = responseJson.data.map((item: any) => {
                    return { label: item.attributes.name, value: item.id, }
                });
                this.setState({ sectorData, loader: false, error: false })
            }
            else {
                this.setState({ error: true })
            }
        }



        if (apiRequestCallId === this.getIndustryAPICall) {
            if (responseJson != null && !responseJson.message) {
                const industryData = responseJson.data.map((item: any) => {
                    return {
                        label: item.attributes.industry_name,
                        value: item.id,
                    }
                });
                this.setState({ industryData, loader: false, error: false })
            } else {
                this.setState({ error: true })
            }
        }
    }

    handleToastMessage() {
        toast.success('participant added succesfully', {
            position: toast.POSITION.TOP_RIGHT
        });
    }
    onApplyButtonClick = () => {
        let { sortBy, option, sectorData, functionData, industryData } = this.state;
        let optionId;
        if(sortBy !== ""){
            if(sortBy !== "all" && option === ""){
                toast.warn(this.t("analytics.toast.SelectOption",{data: sortBy.charAt(0).toUpperCase() + sortBy.slice(1)}))
            } else {
                if (sortBy === "sector") {
                    optionId = sectorData.filter((obj: any) => obj.label === option)[0].value
                }
                else if (sortBy === "function") {
                    optionId = functionData.filter((obj: any) => obj.label === option)[0].value
                }
    
                else if (sortBy === "industry") {
                    optionId = industryData.filter((obj: any) => obj.label === option)[0].value
                }
                else if (sortBy === "all") {
                    optionId = "all"
                }
                else if (sortBy === "gender") {
                    optionId = genderList.filter((obj: any) => obj.label === option)[0].label
                }
                this.getAnalyticsData(sortBy, optionId);
            }
        }
    }

    onsortByValueChange = (sortBy: any) => {
        let optionList: any[] = this.state.data;
        if(sortBy === null){
            this.getAnalyticsData(this.state.expanded, "all")
        }
        if (sortBy !== null) {
            if (sortBy.value === "all") {
                optionList = [];
            } else if (sortBy.value === "sector") {
                optionList = this.state.sectorData;
            } else if (sortBy.value === "function") {
                optionList = this.state.functionData;
            } else if (sortBy.value === "industry") {
                optionList = this.state.industryData;
            } else if (sortBy.value === "gender") {
                optionList = genderList
            }
        } else {
            optionList = []
        }
        this.setState({ sortBy: sortBy !== null ? sortBy.value : "", optionList, option: "" });
    }

    handleButtonClick = (event: React.MouseEvent<HTMLElement>) => {
        this.setState({ anchorEl: event.currentTarget })
    };

    handleClose = () => {
        this.setState({ anchorEl: null })
    };


    handleDangerToastMessage() {
        toast.error('Participant already exists', {
            position: toast.POSITION.TOP_RIGHT
        });
    }

    sentMessage(data: any) {
        const msg: Message = new Message(getName(MessageEnum.PostDetailDataMessage))
        msg.properties['text'] = data
        this.send(msg)
    }

    renderAssesmentLegend = (props: any) => {
        const { payload } = props;

        return (
            <div className="newLegend">
                {
                    payload.map((entry: any, index: number) => {
                        const { payload: Py } = entry
                        const { payload } = Py
                        return (
                            <span key={`${index + 1}1`} className="legend-wrapper">
                                <Surface width={20} height={20}  >
                                    <Symbols cx={10} cy={8} type="square" size={150} viewBox="0 0 15 15" fill={entry.color} />
                                </Surface>
                                <span className="legend-item"><span >{entry.value}{"\xa0\xa0"}</span> <span>{(payload.count < 2) ? `${payload.count} Assesment` : `${payload.count} Assesments`}</span></span>
                            </span>
                        )
                    })
                }
            </div>
        );
    }
    renderLegend = (props: any) => {
        const { payload } = props;

        return (
            <div className="newLegend">
                {
                    payload.map((entry: any, index: number) => {
                        const { payload: Py } = entry
                        const { payload } = Py
                        return (
                            <span className="legend-wrapper" key={`${index + 1}1`}>
                                <Surface width={20} height={20}  >
                                    <Symbols cx={10} cy={8} type="square" size={150} viewBox="0 0 15 15" fill={entry.color} />
                                </Surface>
                                <span className="legend-item"><span >{entry.value}{"\xa0\xa0"}</span> <span>{(payload.count < 2) ? `${payload.count} Participant` : `${payload.count} Participants`}</span></span>
                            </span>
                        )
                    })
                }
            </div>
        );
    }

    renderColorfulLegendText = (value: string, entry: any) => {
        return <span style={{ color: "#000" }}>{value}</span>;
    };


    CustomizedLabel = ({ x, y, stroke, value }: any) => {
        return (
            <text x={x} y={y} dy={-4} fill={stroke} fontSize={10} textAnchor="middle">
                {value}
            </text>
        );

    }

    getAnalyticsData = (type: any, sortBy: any) => {
        let endpoint = type && sortBy && `${configJSON.getAnalyticsData}?type=${type}&sort_by=${sortBy}`
        const header = {
            token: localStorage.getItem('accesToken')
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getAnalyticsAPICall = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endpoint
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;

    }

    getSectorData = () => {
        const header = {
            token: localStorage.getItem('accesToken')
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getSectorAPICall = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            "/bx_block_admin/sectors"
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;

    }

    getIndustryData = () => {
        const header = {
            token: localStorage.getItem('accesToken')
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getIndustryAPICall = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            "/bx_block_profile/industries"
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;

    }

    getFunctionData = () => {

        const header = {
            token: localStorage.getItem('accesToken')
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getFunctionAPICall = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            "bx_block_admin/org_functions"
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;

    }

}
 // Customizable Area End
