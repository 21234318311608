// Customizable Area Start
import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TextField from "@material-ui/core/TextField";
import TableCell from "@material-ui/core/TableCell";
import Pagination from "@material-ui/lab/Pagination";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import AppSidebar from "../../../components/src/AppSidebar.web";
import Modal from "@material-ui/core/Modal";
import Box from "@material-ui/core/Box";
import { Grid, MenuItem } from "@material-ui/core";
import AsycSelect from "react-select";
import Select from '@material-ui/core/Select'
import Typography from "@material-ui/core/Typography";
import { styled } from "@material-ui/styles";
import SearchIcon from "@material-ui/icons/Search";
import { withRouter } from "react-router-dom";
import { editPen } from "./assets";
import "./ManageClient.web.css";
import AssignAvailableAssessorController, {
  Props,
} from "./AssignAvailableAssessorController.web";
import Spinner from "../../../components/src/Spinner.web";
import { Formik } from "formik";
import { withTranslation } from "react-i18next";
import { AssignAvailableAssessorEditSchema } from "./validationSchema";
import moment from "moment";

class AssignAvailableAssessor extends AssignAvailableAssessorController {
  constructor(props: Props) {
    super(props);
  }
  updateList = () => {
    return this.state.UpdateNameist?.map((item: any) => {
      return (
        <MenuItem key={item.id} value={item.id}>
          {item.attributes.full_name}
        </MenuItem>
      );
    });
  };
  renderSlot = (props: any) => {
    return (
      <Grid item xs={12}>
        <label className="labels">
          {this.t("AssignAvailableAssessor.EditModel.Slot")}
          <span style={{ color: "#f94b4b" }}>*</span>
        </label>
        <Box className="textfield-parent">
          <TextField
            name="slot"
            id="slot"
            value={props.values.slot}
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            className="client-textfields"
            variant="outlined"
            fullWidth
            autoFocus
            disabled
            style={{
              border:
                props.errors.slot && props.touched.slot ? "2px solid red" : "",
            }}
          />
          {props.errors.slot && props.touched.slot ? (
            <p className="validation_errors">{props.errors.slot}</p>
          ) : null}
        </Box>
      </Grid>
    );
  };
  renderAssessorName = (props: any) => {
    return (
      <Grid item xs={12}>
        <label className="labels">
          {this.t("AssignAvailableAssessor.EditModel.AssessorName")}
          <span style={{ color: "#f94b4b" }}>*</span>
        </label>
        <Box className="textfield-parent">
          <Select
            name="AssessorName"
            id="AssessorName"
            onChange={props.handleChange}
            defaultValue={props.values.AssessorName}
            onBlur={props.handleBlur}
            className="client-textfields"
            variant="outlined"
            fullWidth
            style={{
              border:
                props.errors.AssessorName && props.touched.AssessorName
                  ? "2px solid red"
                  : "",
            }}
          >
            {this.updateList()}
          </Select>
          {props.errors.full_name && props.touched.full_name ? (
            <p className="validation_errors">{props.errors.full_name}</p>
          ) : null}
        </Box>
      </Grid>
    );
  };
  renderModal = () => {
    return (
      <Modal
        open={this.state.EditModal}
        onClose={this.handleEditmodalClose}
        aria-labelledby="modal-modal-title"
        closeAfterTransition
        BackdropProps={{
          timeout: 500,
        }}
        aria-describedby="modal-modal-description"
      >
        <Box className="modalStyle editModal">
          <div
            className="closeImg"
            style={{ top: "unset", fontWeight: "bolder" }}
            onClick={this.handleEditmodalClose}
          >
            X
          </div>

          <Typography
            className="helpModalTitle"
            variant="h6"
            component="h2"
            style={{
              color: "#1b3c69",
              fontSize: "40px",
              fontFamily: "ProximaNova-Bold",
              textAlign: "left",
            }}
          >
            {this.t("AssignAvailableAssessor.EditModel.UpdateAssessor")}
          </Typography>

          <div>
            <Formik
              initialValues={{
                full_name: this.state.clientDetails.name || "",
                date: this.state.clientDetails.schedule_date || "",
                slot:
                moment(this.state.clientDetails.start_time).utc().format('hh:mm') +
                  " " +
                  "to" +
                  " " +
                  moment(this.state.clientDetails.end_time).utc().format('hh:mm') || "",
                AssessorName:
                  this.state.clientDetails.assessor_id.toString() || "",
                ProjectName: this.state.clientDetails.project_name || "",
              }}
              validationSchema={() => AssignAvailableAssessorEditSchema(this.t)}
              onSubmit={(values) => {
                this.handleUpdateClient(values);
              }}
              validateOnChange={true}
              validateOnBlur={true}
            >
              {(props) => (
                <form onSubmit={props.handleSubmit}>
                  {this.state.UpdateNameist?.length > 0 ? (
                    <div style={{ marginTop: "15px" }}>
                      <Grid className="label-parent" container spacing={3}>
                        {this.ProjectNameRender(props)}
                        <Grid item xs={12}>
                          <label className="labels">
                            {this.t("AssignAvailableAssessor.EditModel.ParticipantName")}
                            <span style={{ color: "#f94b4b" }}>*</span>
                          </label>
                          <Box className="textfield-parent">
                            <TextField
                              name="full_name*"
                              id="full_name"
                              value={props.values.full_name}
                              onChange={props.handleChange}
                              onBlur={props.handleBlur}
                              className="client-textfields"
                              variant="outlined"
                              fullWidth
                              disabled
                              style={{
                                border:
                                  props.errors.full_name &&
                                    props.touched.full_name
                                    ? "2px solid red"
                                    : "",
                              }}
                            />
                            {props.errors.full_name &&
                              props.touched.full_name ? (
                              <p className="validation_errors">
                                {props.errors.full_name}
                              </p>
                            ) : null}
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <label className="labels">
                            {this.t("AssignAvailableAssessor.EditModel.Date")}
                            <span style={{ color: "#f94b4b" }}>*</span>
                          </label>
                          <Box className="textfield-parent">
                            <TextField
                              name="date"
                              id="date"
                              type="date"
                              value={props.values.date}
                              onChange={props.handleChange}
                              onBlur={props.handleBlur}
                              className="client-textfields"
                              fullWidth
                              variant="outlined"
                              disabled
                              placeholder=""
                              style={{
                                border:
                                  props.errors.date && props.touched.date
                                    ? "2px solid red"
                                    : "",
                              }}
                            />
                            {props.errors.date && props.touched.date ? (
                              <p className="validation_errors">
                                {props.errors.date}
                              </p>
                            ) : null}
                          </Box>
                        </Grid>
                        {this.renderSlot(props)}
                        {this.renderAssessorName(props)}
                      </Grid>
                    </div>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        minHeight: "90px",
                        height: "100%",
                      }}
                    >
                      <p>{this.t("AssignAvailableAssessor.TableKeys.notFound")}</p>
                    </div>
                  )}

                  <div
                    style={{
                      color: "#1b3c69",
                      fontSize: "18px",
                      fontFamily: "ProximaNova-regular",
                      textAlign: "left",
                      lineHeight: "1.27",
                      padding: "2px",
                    }}
                  >
                    <Button
                      className="cancelBtn"
                      size="large"
                      style={{ lineHeight: 1 }}
                      onClick={this.handleEditmodalClose}
                    >
                      {this.t("AssignAvailableAssessor.EditModel.Cancel")}
                    </Button>
                    <Button className="saveBtn" type="submit" data-testid="SaveBtnSubmit" size="large">
                      {this.t("AssignAvailableAssessor.EditModel.Save")}
                    </Button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </Box>
      </Modal>
    );
  };

  ProjectNameRender = (props: any) => {
    return (
      <Grid item xs={12}>
        <label className="labels">
          {this.t("AssignAvailableAssessor.label.name")}
          <span style={{ color: "#f94b4b" }}>*</span>
        </label>
        <Box className="textfield-parent">
          <TextField
            name="ProjectName*"
            id="ProjectName"
            value={props.values.ProjectName}
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            className="client-textfields"
            variant="outlined"
            fullWidth
            disabled
            style={{
              border:
                props.errors.ProjectName && props.touched.ProjectName
                  ? "2px solid red"
                  : "",
            }}
          />
          {props.errors.ProjectName && props.touched.ProjectName ? (
            <p className="validation_errors">{props.errors.ProjectName}</p>
          ) : null}
        </Box>
      </Grid>
    );
  };
  render() {
    return (
      <div className="manage-client" data-testid="Heading">
        {this.state.loader && <Spinner spinnerModal={this.state.loader} />}
        <AppSidebar type="admin">
          <Box margin="35px 0px" mr="30px" className="main_page">
            <Box
              display={{ xs: "block", md: "flex" }}
              justifyContent="space-between"
              mb="40px"
            >
              <Box>
                <Box
                  mb="5px"
                  fontFamily="ProximaNova-Bold"
                  fontSize={{ xs: "24px", sm: "30px", md: "44px" }}
                  color="#1b3c69"
                >
                  {this.props.t("AssignAvailableAssessor.title")}
                </Box>
                <Box color="#1b3c69" fontSize={{ xs: "14px", sm: "20px" }}>
                  {this.props.t("AssignAvailableAssessor.SubTitile")}
                </Box>
              </Box>
            </Box>
            <Box style={{ borderRadius: "10px", backgroundColor: "#eff6ff" }}>
              <Grid
                container
                spacing={2}
                style={{ alignItems: "end", padding: "20px 15px 10px 15px" }}
              >
                <Grid item xs={12} sm={6} md={5}>
                  <Box style={{ color: "#1b3c69" }}>
                    <div style={{ marginBottom: "5px", fontSize: "18px" }}>
                      {this.t("AssignAvailableAssessor.label.sortBy")}
                    </div>
                    <AsycSelect
                      autofocus={true}
                      openOnFocus={true}
                      classNamePrefix="react-select"
                      placeholder={this.t("AssignAvailableAssessor.placeholder.sortBy")}
                      isSearchable={true}
                      isClearable={true}
                      data-testid="AsycSelect"
                      options={this.state.projectList}
                      value={
                        this.state.projectList.filter(
                          (option: any) => option.value === this.state.projectName
                        )[0]
                      }
                      onChange={(event: any) => {
                        this.onProjectValueChange(event?.value);
                      }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={5}>
                  <Box style={{ color: "#1b3c69" }}>
                    <div style={{ marginBottom: "5px", fontSize: "18px" }}>
                      {this.t("AssignAvailableAssessor.label.sortByPar")}
                    </div>
                    <AsycSelect
                      classNamePrefix="react-select"
                      placeholder={this.t("AssignAvailableAssessor.placeholder.sortByPar")}
                      isSearchable={true}
                      isClearable={true}
                      options={this.state.participantList}
                      value={
                        this.state.participantList.filter(
                          (option: any) => option?.value === this.state.participantName
                        )[0] ?? []
                      }
                      onChange={(event: any) => {
                        this.setState({ participantName: event?.value });
                      }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                  <Box>
                    <RedButton onClick={this.onApplyButtonClick}>
                      {this.t("AssignAvailableAssessor.button.apply")}
                    </RedButton>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <div style={{ margin: "100px 30px 0px 0px" }}>
              <Grid container>
                <Grid item xs={12}>
                  <AssignAvailableAssessorStyle>
                    <Box
                      mb="10px"
                      display="flex"
                      justifyContent="end"
                      alignItems="center"
                    >
                      <TextField
                        id="search"
                        className="SearchFiled"
                        size="small"
                        InputLabelProps={{
                          style: {
                            fontFamily: "ProximaNova-Regular",
                            zIndex: 0,
                          },
                        }}
                        value={this.state.search}
                        onChange={(event: any) => {
                          this.onChangeHandler(event.target.value, 0);
                        }}
                        placeholder={this.t("AssignAvailableAssessor.label.search")}
                        variant="outlined"
                        InputProps={{
                          startAdornment: <SearchIcon color="disabled" />
                        }}
                        inputProps={{
                          "data-testid": "SearchFiled"
                        }}
                      />
                    </Box>
                  </AssignAvailableAssessorStyle>
                </Grid>
              </Grid>
              <div className="manage-client-table" style={{ overflow: "auto" }}>
                <Table style={{ border: "1px solid #E0E0E0", borderRadius: "10px", borderCollapse: "separate" }} className="custom-table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        align="center"
                        data-testid="usernme"
                        style={{
                          fontFamily: "ProximaNova-Bold",
                          fontSize: "16px",
                          textTransform: "uppercase",
                        }}
                      >
                        {this.t("AssignAvailableAssessor.TableKeys.project")}
                      </TableCell>
                      <TableCell
                        align="center"
                        data-testid="usernme"
                        style={{
                          fontFamily: "ProximaNova-Bold",
                          fontSize: "16px",
                          textTransform: "uppercase",
                        }}
                      >
                        {this.t("AssignAvailableAssessor.TableKeys.PARTICIPANTNAME")}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          fontFamily: "ProximaNova-Bold",
                          fontSize: "16px",
                          textTransform: "uppercase",
                        }}
                      >
                        {this.t("AssignAvailableAssessor.TableKeys.Date")}
                      </TableCell>
                      <TableCell
                        align="center"
                        data-testid="UserName"
                        style={{
                          fontFamily: "ProximaNova-Bold",
                          fontSize: "16px",
                          textTransform: "uppercase",
                        }}
                      >
                        {this.t("AssignAvailableAssessor.TableKeys.SLOT")}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          fontFamily: "ProximaNova-Bold",
                          fontSize: "16px",
                          textTransform: "uppercase",
                        }}
                      >
                        {this.t("AssignAvailableAssessor.TableKeys.AssignedAssessors")}
                      </TableCell>
                      <TableCell
                        className="ActionClass"
                        align="center"
                        style={{
                          fontFamily: "ProximaNova-Bold",
                          fontSize: "16px",
                          textTransform: "uppercase",
                        }}
                      >
                        {this.t("AssignAvailableAssessor.TableKeys.ACTION")}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody data-testid="TableBodyData">
                    {this.state.data.length > 0 ? (
                      this.state.data.map((item: any, index: any) => {
                        return (
                          <TableRow key={item.id}>
                            <TableCell
                              style={{
                                fontFamily: "ProximaNova-Regular",
                                fontSize: "18px",
                                color: "#000",
                              }}
                              data-testid={`FullName${index}`}
                              align="center"
                            >
                              <span style={{display: 'block',width: '200px',whiteSpace: 'nowrap',overflow: 'hidden',textOverflow: 'ellipsis'}}>{item.attributes?.project_name}</span>
                            </TableCell>
                            <TableCell
                              style={{
                                fontFamily: "ProximaNova-Regular",
                                fontSize: "18px",
                                color: "#000",
                              }}
                              data-testid={`FullName${index}`}
                              align="center"
                            >
                              {item.attributes.name}
                            </TableCell>
                            <TableCell
                              style={{
                                fontFamily: "ProximaNova-Regular",
                                fontSize: "18px",
                                color: "#000",
                              }}
                              align="center"
                            >
                              <span
                                style={{
                                  display: "block",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  width: "100px",
                                }}
                              >
                                {moment(item.attributes.schedule_date).format(
                                  "DD/MM/YYYY"
                                )}
                              </span>
                            </TableCell>
                            <TableCell
                              style={{
                                fontFamily: "ProximaNova-Regular",
                                fontSize: "18px",
                                color: "#000",
                              }}
                              align="center"
                            >
                              <span>
                                {moment(item.attributes.start_time).utc().format('hh:mm')} to{" "}
                                {moment(item.attributes.end_time).utc().format("hh:mm")}
                              </span>
                            </TableCell>
                            <TableCell
                              style={{
                                fontFamily: "ProximaNova-Regular",
                                fontSize: "18px",
                                color: "#000",
                              }}
                              align="center"
                            >
                              <span
                                style={{
                                  display: "block",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  width: "100px",
                                }}
                              >
                                {item.attributes.assessor_name}
                              </span>
                            </TableCell>
                            <TableCell
                              style={{
                                fontFamily: "ProximaNova-Regular",
                                fontSize: "18px",
                                color: "#000",
                              }}
                              align="center"
                            >
                              {item.attributes.available === false && (
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <div
                                    style={{
                                      color: "#f94b4b",
                                      fontFamily: "ProximaNova-semibold",
                                      cursor: "Pointer",
                                    }}
                                    data-testid="editBtn"
                                    onClick={() =>
                                      this.handleEditmodalOpen(item)
                                    }
                                  >
                                    <figure>
                                      {" "}
                                      <img
                                        src={editPen}
                                        alt="Pen"
                                        width="21"
                                        height="21"
                                      />
                                    </figure>
                                  </div>
                                </div>
                              )}
                            </TableCell>
                          </TableRow>
                        );
                      })
                    ) : (
                      <TableRow>
                        <TableCell
                          colSpan={12}
                          align="center"
                          className="ActionClass"
                          style={{
                            fontFamily: "ProximaNova-Regular",
                            fontSize: "18px",
                            color: "#000",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {this.t("AssignAvailableAssessor.TableKeys.noData")}
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </div>
              {this.state.data.length > 0 &&
                <div className="custom-pagination">
                  <Pagination
                    count={Math.ceil(this.state.dataLength / this.state.rowsPerPage)}
                    onChange={(event: unknown, newPage: number) => {
                      this.onChangeHandler(this.state.search, newPage - 1);
                    }}
                    variant="outlined"
                    shape="rounded"
                    showFirstButton
                    dir="ltr"
                    showLastButton
                    siblingCount={0}
                    boundaryCount={1}
                  />
                </div>
              }
            </div>
            {this.state.EditModal && this.renderModal()}
          </Box>
        </AppSidebar>
      </div>
    );
  }
}

export default withRouter(withTranslation()(AssignAvailableAssessor));

const RedButton = styled(Button)({
  backgroundColor: "#f94b4b",
  color: "white",
  borderRadius: "10px",
  fontFamily: "ProximaNova-Semibold",
  fontSize: "18px",
  textTransform: "none",
  padding: "9px 25px",
  "&:hover": {
    color: "white",
    backgroundColor: "#f94b4b",
  },
  "@media(max-width:600px)": {
    width: "100%",
    marginTop: "27px",
  },
  "@media(max-width:992px)": {
    padding: "8px 15px",
  },
});

const AssignAvailableAssessorStyle = styled("div")({
  "& .MuiOutlinedInput-root": {
    height: "unset !important",
  },
  "& .SearchFiled": {
    "& .MuiInputBase-root": {
      borderRadius: "8px",
    },
  },
});

const filterByList = [
  { label: "All", value: "all" },
  { label: "Status", value: "status" },
  { label: "Projects", value: "project_name" },
];
// Customizable Area End
