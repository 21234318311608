export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const welcome = require("../assets/welcome.png");
export const play = require("../assets/play.png");
export const pause = require("../assets/pause.png");
export const welcome2 = require("../assets/welcome2.png");
export const shrm = require("../assets/shrm.png");
export const help = require("../assets/help.svg");
export const logo = require("../assets/logo.png");
export const india = require("../assets/india.png");
export const calender = require("../assets/calender.png")
export const India = require("../assets/INDIAFLAG.png")
export const UAE = require("../assets/UAE.png")
export const UK = require("../assets/uk.png")
