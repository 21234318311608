// Customizable Area Start
import React from "react";
import { Box, TextField, Button, Grid } from "@material-ui/core";
import { styled } from "@material-ui/styles";
import { withTranslation } from "react-i18next";
import PsychometricUrlController from "./PsychometricUrlController.web";
import AppSidebar from "../../../components/src/AppSidebar.web";
const LanguageData = localStorage.getItem("language") === "ar";
class PsychometricUrl extends PsychometricUrlController {
  validateURLTwo = () => {
    let str = this.state.url
    if (str.indexOf("http://") == 0 || str.indexOf("https://") == 0) {
      this.setState({ invalidURL: false})
      this.updateURL()
    }
    else {
      this.setState({ invalidURL: true })
    }
  }
  render() {
    return (
      <AppSidebar type="admin">
        <PsychometricUrlStyle>
          <CustomBox>{this.t("psychometricUrl.title.main")}</CustomBox>
          <Grid container spacing={3}>
            <Grid item md={10} style={{ width: "100%" }}>
              <TextField
                name="email"
                id="email"
                className="client-textfields"
                fullWidth
                variant="outlined"
                placeholder={this.t("psychometricUrl.placeholder.url")}
                value={this.state.url}
                style={{ fontFamily: "ProximaNova-Semibold" }}
                onChange={(e: any) => {
                  this.setState({ url: e?.target?.value });
                }}
              />
              {this.state.invalidURL ? <p style={{ marginTop: "0.5rem", color: "red" }}>
              {this.t("psychometricUrl.errorMsg.message")}
                </p> : null}
            </Grid>
            <Grid item md={4}>
              <CustomButton
                variant="contained"
                onClick={() => this.validateURLTwo()}
              >
                {this.t("psychometricUrl.button.update")}
              </CustomButton>
            </Grid>
          </Grid>
        </PsychometricUrlStyle>
      </AppSidebar>
    );
  }
}

export default withTranslation()(PsychometricUrl);

const CustomBox = styled(Box)({
  margin: "0 0 1rem 0",
});

const CustomButton = styled(Button)({
  color: "white",
  backgroundColor: "#f26a42",
  border: "1px solid #f26a42",
  fontFamily: "ProximaNova-Semibold",
  marginRight: "15px",
  borderRadius: "8px",
  fontSize: "18px",
  textTransform: "none",
  padding: "8px 25px",
  "&:hover": {
    color: "#f26a42",
    backgroundColor: "white",
  },
  "@media(max-width:600px)": {
    width: "100%",
  },
  "@media(max-width:992px)": {
    padding: "7px 15px",
  },
});

const PsychometricUrlStyle = styled("div")({
  marginRight: LanguageData ? "25px": "unset", 
  "& .MuiGrid-spacing-xs-3": {
    width: "100%",
    transition: 'ease'
  },
});
// Customizable Area End
