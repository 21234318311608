export const logo = require("../assets/group.jpg");
export const shrm = require("../assets/shrm.png");
export const help = require("../assets/help.png");
export const close = require("../assets/close.png");
export const analytics = require("../assets/analytics.svg");
export const alt = require("../assets/ALT.svg");
export const manageClients = require("../assets/manageClients.svg");
export const manageParticipants = require("../assets/manageParticipants.svg");
export const manageUsers = require("../assets/manageUsers.svg");
export const onlineTools = require("../assets/onlineTools.svg");
export const projects = require("../assets/projects.svg");
export const reports = require("../assets/reports.svg");
export const hamburger = require("../assets/hamburger.svg");
//export const downArrow = require("../assets/down-arrow.png");
export const menu = require("../assets/menu.png");
export const calendar = require("../assets/calendar.png")
export const notification = require("../assets/notification.png");
export const notificationWhite = require("../assets/notificationWhite.png")

