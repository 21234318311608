// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { preventDefault } from "@fullcalendar/react";
import { toast } from "react-toastify";

// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  // Customizable Area Start
  id?: string;
  navigation?: any;
  location: any;
  history: any;
  match: any;
  t: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  questionArray: any;
  questionNo: any;
  currentQue: number;
  RemainingTime: string;
  hour: number;
  minutes: number;
  seconds: number;
  interval: any;
  isMedia: boolean;
  totalAnswered: number;
  totalQue: number;
  showWarning: boolean;
  showModal: boolean;
  showMedia: boolean;
  selectedAns: any;
  errorMsg: boolean;
  tool: any;
  popup: any;
  swichedTabCount: number;
  GridState: any;
  updatedTime: any;
  flaggedQue: any;
  loading: boolean;
  UnanswaredQuetion: any,
  totalTime : number
  // Customizable Area End
}
interface SS {
  id: any;
}
// Customizable Area Start
export default class McqSjtTestController extends BlockComponent<Props, S, SS> {

  interval: any = null;
  t: any = "";


  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      questionArray: [],
      questionNo: [],
      currentQue: 0,
      RemainingTime: "15:00",
      hour: 0,
      minutes: 15,
      seconds: 0,
      interval: null,
      isMedia: false,
      totalAnswered: 0,
      totalQue: 15,
      showWarning: false,
      showModal: false,
      showMedia: false,
      selectedAns: null,
      errorMsg: false,
      tool: localStorage.getItem("tool"),
      popup: "",
      swichedTabCount: 0,
      GridState: 2,
      updatedTime: null,
      flaggedQue: 0,
      loading: true,
      UnanswaredQuetion: 0,
      totalTime : 0
    };
    this.t = this.props.t;

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    let tools = JSON.parse(this.state.tool);
    let temp = localStorage.getItem("reload")
    if (temp === "true") {
      this.props.navigation.navigate("Dashboard")
    }
    else {
      this.setState({
        tool: tools,
        hour: tools.hour ?? 0,
        minutes: tools.mint ?? 15,
        seconds: 0,
        totalTime : Number(tools.mint + (tools.hour  * 60))
      });
    }


    window.onbeforeunload = (event: any) => {
      // Cancel the event
      event.preventDefault();
      if (event) {
        event.returnValue = ''; // Legacy method for cross browser support
      }
      return ''; // Legacy method for cross browser support
    };

    setTimeout(() => {
      this.getQuestions()
      this.setState({ loading: true })
    }, 100);

    window.addEventListener('beforeunload', this.handleLeavePage);
  }


  componentDidUpdate() {
    window.onpopstate = () => this.AreyouSureFun();
    window.onbeforeunload = (e: any) => this.handleLeavePage(e)
  }

  async componentWillUnmount() {
    window.removeEventListener('beforeunload', this.handleLeavePage);
  }

  AreyouSureFun = () => {
    localStorage.setItem("reloadDashboard", "true")
    alert("you clicked on back button your test will submitted succesfully");
    this.props.navigation.navigate("Dashboard")
    this.submitTest();
  };


  handleLeavePage(e: any) {
    this.submitTest()
    localStorage.setItem("reload", "true")
    const confirmationMessage = "Some message This is";
    e.returnValue = confirmationMessage; // Gecko, Trident, Chrome 34+
    return alert("Don't Leave"); // Gecko, WebKit, Chrome <34
  }

  async receive(from: string, message: Message) {

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    if (apiRequestCallId && responseJson) {
      if (apiRequestCallId === this.getQuestionApiCallID) {
        this.getQuestionApiFuncation(responseJson);
      }
      if (apiRequestCallId === this.submitAnswerApiCallID) {
        this.setState({
          selectedAns:null
        })
      }
      if (apiRequestCallId === this.submitTestApiCallID) {
        localStorage.setItem("reloadDashboard","true")
      }
      
    }
    
    if (apiRequestCallId && responseJson) {
      console.log("ans");
    } else {
      this.parseApiErrorResponse(responseJson);
    }


  }


  getQuestionApiCallID: string = "";
  endTestApiCallID: string = "";
  submitAnswerApiCallID: string = "";
  submitTestApiCallID: string = "";
  showResultApiCallID: string = "";

  apiCall = async (data: any) => {
    const token = localStorage.getItem("accesToken") as string;

    const { contentType, method, endPoint, body, type } = data;
    const header = {
      "Content-Type": contentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body && type != "formData"
      ? requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      )
      : requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    console.log("requestMessage", requestMessage);
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  getQuestionApiFuncation = (responseJson: any) => {
    if (responseJson?.data) {
      let temp = responseJson?.data?.filter((que: any) => que?.attributes?.answer_ids.length === 0 ||
        que?.attributes?.answer_ids[0] === 0)
      this.setState(
        {
          loading: false,
          questionArray: temp,
          totalQue: temp.length,
          updatedTime: responseJson.meta?.updated_duration > 0 ? responseJson.meta?.updated_duration : null
        },
        () => {
          this.interval = setInterval(() => {
            this.Timer();
          }, 1000);
        }
      );
      this.fetchQuestions();
    } else {
      this.setState({
        questionArray: null,
      });
    }
  };

  getQuestions = async () => {
    localStorage.setItem("disableNext", this.state.tool?.id)
    this.setState({ loading: true })
    this.getQuestionApiCallID = await this.apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `/bx_block_profile/get_Q_and_A?online_tool_id=${this.state.tool?.id}`,
    });
  };

  fetchQuestions = async () => {
    let temp = [];
    for (let i = 1; i <= this.state.totalQue; i++) {
      temp.push(i);
    }
    this.setState({ questionNo: temp });
  };
  handleNext = () => {
    if (this.state.currentQue < this.state.totalQue) {
      this.setState({
        totalAnswered: this.state.questionArray.filter(
          (item: any) => item.color === "Checked"
        ).length
      })
      this.setState({ currentQue: this.state.currentQue + 1 });
      this.submitAnswer();
    }
  };
  handlePrevious = () => {
    if (this.state.currentQue > 0) {
      this.setState({ currentQue: this.state.currentQue - 1 });
    }
  };
  submitAnswer = async () => {
    this.submitAnswerApiCallID = await this.apiCall({
      contentType: "application/json",
      method: "POST",
      endPoint: "/bx_block_profile/participant_answers",
      body: {
        data: {
          attributes: {
            question_id: this.state.selectedAns?.attributes?.question_id,
            answer_ids: [Number(this.state.selectedAns?.id)],
            left_duration: parseFloat(
              (
                60 * this.state.hour +
                this.state.minutes +
                this.state.seconds / 60
              ).toString()
            ).toFixed(2),
            total_duration : this.state.totalTime,
            tool_id: Number(this.state.tool?.id),
            online_tool_id: Number(this.state.tool?.id),
            left_questions: (this.state.totalQue - 1) - this.state.totalAnswered,
            time_consumed : Number(this.state.totalTime - (this.state.minutes + (this.state.hour * 60)))
          },
        },
      },
    });
  };

  Timer = () => {
    let hour = this.state.hour;
    let minutes = this.state.minutes;
    let seconds = this.state.seconds;

    if (hour <= 0 && minutes <= 0 && seconds <= 1) {
      clearInterval(this.interval);
    }
    seconds = Number(seconds) - 1;

    if (seconds < 0 && minutes > 0) {
      minutes = Number(minutes) - 1;
      seconds = Number(seconds) + 59;
    }
    if (seconds <= 0 && minutes <= 0 && hour > 0) {
      hour = Number(hour) - 1;
      minutes = Number(minutes) + 59;
      seconds = 59;
    }
    this.setState({
      hour: Number(hour),
      minutes: Number(minutes),
      seconds: Number(seconds),
    });

    // Update Time
    this.updatedTimeFuncation();
    // check if swiched tab
    window.addEventListener("blur", () => this.BluerFuncation());

    // Prevent Refresh
    document.addEventListener("keydown", (e: any) => {
      if (e.key === "F5" || e.ctrlKey === true) {
        e.preventDefault();
      }
    });
    // Check Flagged QUe
    let totalFlagged = this.state.questionArray.filter(
      (que: any) => que.color === "Flag"
    );
    let UnanswaredQuetion = this.state.questionArray.filter((item: any) => {
      return item.ansSubmited === undefined
    })?.length
    if (totalFlagged || UnanswaredQuetion) {
      this.setState({ flaggedQue: totalFlagged?.length, UnanswaredQuetion });
    }
  };

  BluerFuncation = () => {
    if (this.state.swichedTabCount === 0) {
      const count = Number(this.state.swichedTabCount) + 1;
      this.setState({
        showModal: true,
        swichedTabCount: count,
        popup: "switchTab",
      });
    } else if (this.state.swichedTabCount === 2) {
      this.setState({ showModal: true, popup: "submit" });
      this.submitTest();
      const count = Number(this.state.swichedTabCount) + 1;
      this.setState({ swichedTabCount: count });
    }
  };

  updatedTimeFuncation = () => {
    if (this.state.updatedTime) {
      const totalMinutes = Number(this.state.updatedTime);
      const hour = Math.floor(totalMinutes / 60);
      let minutes = totalMinutes - 60 * hour;
      let seconds = 0;
      if (Number(minutes) === minutes && minutes % 1 !== 0) {
        let min = Math.floor(minutes);
        seconds = Math.floor((minutes - min) * 60);
        minutes = min;
      }
      this.setState({ hour, minutes, seconds, updatedTime: null });
    }
    // Check if Times up
    if (
      this.state.hour <= 0 &&
      this.state.minutes <= 0 &&
      this.state.seconds <= 0
    ) {
      this.setState({ showModal: true, popup: "timesUp" });
      this.submitTest();
    }
  };

  handleWarning = (val: any) => {
    if (val === "close") {
      this.setState({ showModal: false });
    }
    if (val === "cancel") {
      this.setState({ showModal: false });
    }
    if (val === "ok") {
      this.setState({ showModal: false });
    }
    if (val === "submit") {
      this.setState({ showModal: false });
      this.props.navigation.navigate("Dashboard");
    }
  };

  submitTest = () => {
    this.submitAnswer();
    this.finishTest();
  };
  finishTest = async () => {
    this.submitTestApiCallID = await this.apiCall({
      contentType: "application/json",
      method: "POST",
      endPoint: "/bx_block_profile/participant_test_status",
      body: {
        online_tool_id: this.state.tool?.id,
      },
    })
    this.setState({ showModal: true, popup: "submit" })
  };
  handleSwitchQue = (no: any, index: any) => {
    let questionArray = [...this.state.questionArray];
    if(questionArray[this.state.currentQue].ansSubmited !==  undefined){
      this.submitAnswer();
    }
    this.setState({ currentQue: index });
  };
  handleFlagQue = (que: any) => {
    let questionArray = [...this.state.questionArray];
    if (questionArray[this.state.currentQue].color === "Flag") {
      if(typeof questionArray[this.state.currentQue].ansSubmited === "string"){
        questionArray[this.state.currentQue].color = "Checked"
      } else {
        questionArray[this.state.currentQue].color = "unAnswered"
      }
    } else {
      if(questionArray[this.state.currentQue].ansSubmited === undefined)
      {
        questionArray[this.state.currentQue].color = "Flag";
      if (this.state.totalQue !== this.state.currentQue + 1) {
        this.setState({ currentQue: this.state.currentQue + 1 });
      }}
      if(typeof questionArray[this.state.currentQue].ansSubmited === "string" || 
      typeof questionArray[this.state.currentQue].ansSubmited === "number"){
        toast.error(this.t("test.toast.Youcannotflagansweredquestion"))
      }
    }
    this.setState({
      questionArray,
    });
  };

  AnswerdOnchanges = (ans: any) => {
    let questionArray = [...this.state.questionArray];
    questionArray[this.state.currentQue].ansSubmited =
      ans.id;
    questionArray[this.state.currentQue].color =
      "Checked";
    this.setState({
      selectedAns: ans,
      questionArray,
    });
  }

  AnsOnclick = (ans: any) => {
    let questionArray = [...this.state.questionArray];
    if (Number(questionArray[this.state.currentQue].ansSubmited) === Number(ans.id)) {
      questionArray[this.state.currentQue].ansSubmited = -1;
      questionArray[this.state.currentQue].color =
        "unAnswered";
    }
    this.setState({
      selectedAns: ans,
      questionArray,
    });
  }
}
// Customizable Area End