// Customizable Area Start
import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { toast } from "react-toastify";

export const configJSON = require("./config");

export interface Props {
  navigation?: any;
  id?: string;
  t: any;
}

interface S {
  data: any;
  allClients: any;
  clientName: any;
  clientEmail: any;
  clientUserName: string;
  clientPassword: any;
  openModal: boolean;
  openURLModal: boolean;
  URLmodal: boolean;
  dynamicUrl: any;
  rederectUser: any;
  activeBtn: boolean;
  page: any;
  rowsPerPage: any;
  search: any;
  loader: boolean;
  questionImage: any;
  dragActive: boolean;
}

interface SS {
  id: any;
}

export default class ManageParticipantController extends BlockComponent<
  Props,
  S,
  SS
> {
  getParticipantAPICall: string = "";
  addParticipantCallId: string = "";
  addClientCallId: string = "";
  t: any;
  dragDrop: any = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleopenURLModal = this.handleopenURLModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleCloseModal2 = this.handleCloseModal2.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.PostDetailDataMessage),
    ];

    this.state = {
      data: [],
      allClients: [],
      clientName: "",
      clientEmail: "",
      clientUserName: "",
      clientPassword: "",
      openModal: false,
      openURLModal: false,
      URLmodal: false,
      activeBtn: false,
      dynamicUrl: "",
      rederectUser: "",
      page: 0,
      rowsPerPage: 20,
      search: "",
      loader: false,
      questionImage: "",
      dragActive: false
    };
    this.t = this.props.t;
    // @ts-ignore
    this.callRef = React.createRef();
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    if (
      this.addClientCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiResponse?.errors) {
        this.HandelErros(apiResponse)
      } else {
        if(apiResponse.status === undefined && apiResponse.status !== 500){
          this.handleToastMessage();
          this.sentMessage("CLiENT_ADD");
          this.setState({ openModal: false });
        }else{
          this.sentMessage("CLiENT_ADD_FAILD");
          this.setState({ openModal: false });
        }
      }
    }
  }

  HandelErros = (apiResponse:any) =>{
    apiResponse?.errors?.map((option: any) => {
      for (const i in option) {
        if (option[i] == "has already been taken") {
          this.sentMessage("LOADERFALSE");
          toast.error(
            this.t("ManageClient.AddNewClinet.ToastMessage.ClientEmail")
            );
          } else if (option[i] == "Admin with same name already exists.") {
            this.sentMessage("LOADERFALSE");
            toast.error(
            this.t("ManageClient.ToastMessage.ClientWithSameName")
          );
        }
      }
    });
  }

  handleToastMessage() {
    toast.success(this.t("ManageClient.AddNewClinet.ToastMessage.ClientAddEmail"), {
      position: toast.POSITION.TOP_RIGHT
    });
  }

  handleDangerToastMessage() {
    toast.error(this.t("ManageClient.AddNewClinet.ToastMessage.ClientAlready"), {
      position: toast.POSITION.TOP_RIGHT,
    });
  }

  sentMessage(data: any) {
    const msg: Message = new Message(
      getName(MessageEnum.PostDetailDataMessage)
    );
    msg.properties["text"] = data;
    this.send(msg);
  }
  handleOpenModal() {
    this.setState({ openModal: true });
  }
  handleCloseModal2() {
    this.setState({ openModal: false, questionImage: "" });
  }
  handleopenURLModal() {
    this.setState({ openURLModal: true });
  }
  handleCloseModal() {
    this.setState({ openURLModal: false });
  }
  handleURLmodalOpen = (item: any) => {
    this.setState({ clientName: `${item.attributes.first_name}` });
    // this.setState({ ParticipantDetails: item.attributes })
    this.setState({ URLmodal: true });
  };
  handleURLmodalClose = () => {
    this.setState({ URLmodal: false });
  };

  generateUniquePassword = (passwordLength: any) => {
    let numberChars = "0123456789";
    let upperChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    let lowerChars = "abcdefghiklmnopqrstuvwxyz";
    let specialChars = "!@#$%^&*)(:',.?/><";
    let allChars = numberChars + upperChars + lowerChars + specialChars;
    let randPasswordArray = Array(passwordLength);
    randPasswordArray[0] = numberChars;
    randPasswordArray[1] = upperChars;
    randPasswordArray[2] = lowerChars;
    randPasswordArray[3] = specialChars;
    randPasswordArray = randPasswordArray.fill(allChars, 4);
    return this.shuffleArray(
      randPasswordArray.map(function (x) {
        const tempArr = new Uint8Array(1)
        const randomValue = crypto.getRandomValues(tempArr)[0]
        const randomFloat = randomValue / Math.pow(2, 8)
        return x[Math.floor(randomFloat * x.length)];
      })
    ).join("");
  };

  shuffleArray = (array: any) => {
    for (let i = array.length - 1; i > 0; i--) {
      const ganrateNum = new Uint8Array(1)
      const randomValue = crypto.getRandomValues(ganrateNum)[0]
      const randomFloat = randomValue / Math.pow(2, 8)
      let j = Math.floor(randomFloat * (i + 1));
      let temp = array[i];
      array[i] = array[j];
      array[j] = temp;
    }
    return array;
  };

  setNewPassword = (setFieldValue: any) => {
    const newPassword = this.generateUniquePassword(8);
    setFieldValue("password", newPassword);
  };

  setUsername = (setFieldValue: any, fname?: any) => {
    const username =
      fname.substring(0, 3) +
      new Date().getDate().toString() +
      (new Date().getMonth() + 1).toString() +
      new Date().getHours().toString() +
      new Date().getMinutes().toString() +
      new Date().getMilliseconds().toString();
    setFieldValue("user_name", username);
  };

  // handle drag events
  handleDrag = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      this.setState({ dragActive: true });
    } else if (e.type === "dragleave") {
      this.setState({ dragActive: false });
    }
  };

  // triggers when file is dropped
  handleDrop = (e: any, props: any) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ dragActive: false });
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      props.setFieldValue("questionImage", e.dataTransfer.files[0]);
      this.setState({
        questionImage: e.dataTransfer.files[0],
      });
    }
  };

  // triggers when file is selected with click
  handleChange = (e: any, props: any) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      props.setFieldValue("questionImage", e.target.files[0]);
      this.setState({
        questionImage: e.target.files[0],
      });
    }
  };

  // triggers the input when the button is clicked
  onButtonClick = () => {
    this.dragDrop.click();
  };

  getBase64 = (file: Blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  handleAddClient = async (values: any) => {
    const { full_name, user_name, email, password } = values
    let headers = {
      "Content-Type": "application/json",
      token: localStorage.getItem("accesToken"),
    };
    let httpBody = {};
    httpBody = {
      data: {
        attributes: {
          full_name: full_name,
          email: email,
          user_name: user_name,
          password: password,
          profile: this.state.questionImage
            ? { data: await this.getBase64(this.state.questionImage) }
            : undefined,
        },
        type: "client",
      },
    };
    const addClientApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addClientCallId = addClientApiMsg.messageId;
    addClientApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/account_block/clients"
    );
    addClientApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    addClientApiMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    addClientApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(addClientApiMsg.id, addClientApiMsg);
    this.setState({
      openModal: false,
      clientName: "",
      clientEmail: "",
      clientPassword: "",
      clientUserName: "",
      questionImage: "",
    });
  };
}
// Customizable Area End
