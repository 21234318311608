// Customizable Area Start
import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { toast } from "react-toastify";

export const configJSON = require("./config");

export interface Props {
  navigation?: any;
  id?: string;
  t: any;
}

interface S {
    anchorEl: HTMLElement | null;
    isReinitailize: boolean;
    copyright: string;
}

interface SS {
  id: any;
}

export default class CoverImageController extends BlockComponent<
  Props,
  S,
  SS
> {
  getWelcomeAPICall: string = '';
  getHeaderAPICall: string = '';
  resetPasswordCall: string = '';
  getCopyrightApiCallId: string = "";
  t: any;

  constructor(props: Props) {

    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.PostDetailDataMessage)
    ]

    this.state = {
        anchorEl: null,
        isReinitailize: true,
        copyright: `© ${new Date().getFullYear()} SHRM India. All Rights Reserved.`
    };
    this.t = this.props.t;
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.getCopyright();
    setTimeout(() => {this.setState({isReinitailize: false})}, 2000);
  }

  

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.checkResponse(apiRequestCallId,responseJson)
    }
  }

  checkResponse = (apiRequestCallId:any,responseJson:any) =>{
    if (apiRequestCallId === this.resetPasswordCall) {
      if (responseJson != null && !responseJson.errors) {
       let toastText = responseJson.meta.message
       const accoutType = localStorage.getItem("accoutType")
       toast.success(`Congratulations, ${toastText}`)
       
       if( accoutType === "Admin" || accoutType === "Assessor"){
         this.props.navigation.navigate("AdminLogin")
       }
       else{
         this.props.navigation.navigate("Home")
       }
      } else {
        toast.error(responseJson.errors[0].message)
      }
    } else if (apiRequestCallId === this.getCopyrightApiCallId) {
      if(Array.isArray(responseJson)){
        this.setState({ copyright: responseJson[0].copyright });
      }
    }
  }

  handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({ anchorEl : event.currentTarget});
  };

  handlePopoverClose = () => {
    this.setState({ anchorEl : null});
  };

  handleReset = (resetForm: any) => {
    resetForm();
  }

 checkPassword = (str: any) => {
    let regex = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    return regex.test(str);
  }

  handleSubmit = (values: any) => {
    console.log(values, "values")
    const token = localStorage.getItem('accesToken')
    const header = {
      // "token": token
      "Content-Type": configJSON.validationApiContentType,
    };
    const payload = {
      data: {
        attributes: {
          token: token,
          new_password: values.newPassword,
          confirm_password: values.confirmNewPassword,
        },
      },
    };
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.resetPasswordCall = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.resetPasswordEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypePost
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(payload)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  };

  getCopyright = () => {

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      "Content-Type": "application/json",
    };

    this.getCopyrightApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      '/bx_block_admin/footer_copyrights'
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  handleCancel = () =>{
    const accoutType = localStorage.getItem("accoutType")
    if( accoutType === "Admin" || accoutType === "Assessor"){
      this.props.navigation.navigate("AdminLogin")
    }
    else{
      this.props.navigation.navigate("Home")
    }
  }
 
}
 // Customizable Area End
