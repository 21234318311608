// Customizable Area Start
import React from "react";
import {
    Box,
    Button,
    Grid,
    Tooltip,
    TextField,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import { styled } from "@material-ui/styles";
import moment from "moment";
import SearchIcon from "@material-ui/icons/Search";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
//@ts-ignore
import { CSVLink } from 'react-csv';
import Select from "react-select";
import ReportsController, { Props } from "./ReportsController.web";
import AppSidebar from "../../../../components/src/AppSidebar.web";
const LanguageData = localStorage.getItem("language") === "ar";
import Spinner from "../../../../components/src/Spinner.web";

const fileIcon = require("../../assets/file.svg");

class Reports extends ReportsController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return (
            <StyleReport style={{ fontFamily: "ProximaNova-Regular" }}>
                {this.state.loading && <Spinner spinnerModal={this.state.loading} />}
                <AppSidebar type="admin">
                    <Box margin="35px 0px" mr="30px">
                        <Box display={{ xs: "block", md: "flex" }} justifyContent="space-between" mb="40px">
                            <Box>
                                <Box mb="5px" fontFamily="ProximaNova-Bold" fontSize={{ xs: "24px", sm: "30px", md: "44px" }} color="#1b3c69">{this.t("reports.title.main")}</Box>
                                <Box color="#1b3c69" fontSize={{ xs: "14px", sm: "20px" }}>{this.t("reports.description.main")}</Box>
                            </Box>
                        </Box>
                        <Box style={{ borderRadius: "10px", backgroundColor: "#eff6ff" }}>
                            <Grid container spacing={2} style={{ alignItems: "end", padding: "20px 15px 10px 15px" }}>
                                <Grid item xs={12} sm={6} md={5}>
                                    <Box style={{ color: "#1b3c69" }}>
                                        <div style={{ marginBottom: "5px", fontSize: "18px" }}>{this.t("reports.label.filterBy")}</div>
                                        <Select
                                            autofocus={true}
                                            openOnFocus={true}
                                            classNamePrefix="react-select"
                                            placeholder={this.t("reports.placeholder.filterBy")}
                                            isSearchable={false}
                                            options={filterByList}
                                            isClearable={true}
                                            value={
                                                filterByList.filter((option: any) => option.value === this.state.filterBy)[0]
                                            }
                                            onChange={(event: any) => {
                                                this.onFilterByValueChange(event);
                                            }}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={5}>
                                    <Box style={{ color: "#1b3c69" }}>
                                        <div data-testid="reports.placeholder.selectOptionnewnwe" style={{ marginBottom: "5px", fontSize: "18px" }}>{this.t("reports.label.selectOption")}</div>
                                        <Select
                                            classNamePrefix="react-select"
                                            placeholder={this.t("reports.placeholder.selectOption")}
                                            isSearchable={true}
                                            isClearable={true}
                                            options={this.state.optionList}
                                            value={
                                                this.state.optionList.filter(
                                                    (option: any) =>
                                                        option?.label === this.state.option
                                                )[0] ?? []
                                            }
                                            onChange={(event: any) => {
                                                this.setState({ option: event !== null ? event.label : "" });
                                            }}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={2}>
                                    <Box id="reportsselectOptionid">
                                        <RedButton onClick={this.onApplyButtonClick}>{this.t("reports.button.apply")}</RedButton>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box mt="60px">
                            <Box>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Box display="flex" justifyContent="end" mb="25px">
                                            <ExportButton
                                                startIcon={<img src={fileIcon} style={{margin:"0 10px"}} alt="not found" />}
                                                onClick={this.onExportData}
                                            >
                                                {this.t("reports.button.export")}
                                            </ExportButton>
                                        </Box>
                                        <Box mb="25px" className={LanguageData ? "SearchRight" : ""}
                                            display="flex" id="reports.label.searchBox" justifyContent="end" alignItems="center">
                                            <TextField
                                                id="search"
                                                InputLabelProps={{
                                                    style: {
                                                        fontFamily: "ProximaNova-Regular",
                                                        zIndex: 0
                                                    }
                                                }}
                                                onChange={(event: any) => {
                                                    this.onChangeHandler(event.target.value, 0);
                                                }}
                                                label={this.t("reports.label.search")}
                                                variant="outlined"
                                                InputProps={{
                                                    endAdornment: <SearchIcon />
                                                }}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} style={{ overflow: "auto" }} id="mapoftabkedata">
                                        <Table aria-label="simple table" style={{ border: "1px solid #E0E0E0", borderRadius: "10px", borderCollapse: "separate" }} className="custom-table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell style={{ fontFamily: "ProximaNova-Bold", fontSize: "16px" }}>{this.t("reports.label.project")}</TableCell>
                                                    <TableCell align="left" style={{ fontFamily: "ProximaNova-Bold", fontSize: "16px" }}>{this.t("reports.label.status")}</TableCell>
                                                    <TableCell align='left' style={{ fontFamily: "ProximaNova-Bold", fontSize: "16px" }}>{this.t("reports.label.counter")}</TableCell>
                                                    <TableCell align="left" style={{ fontFamily: "ProximaNova-Bold", fontSize: "16px" }}>{this.t("reports.label.type")}</TableCell>
                                                    <TableCell align="left" style={{ fontFamily: "ProximaNova-Bold", fontSize: "16px" }}>{this.t("reports.label.dcManager")}</TableCell>
                                                    <TableCell align="center" style={{ fontFamily: "ProximaNova-Bold", fontSize: "16px" }}>{this.t("reports.label.action")}</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {this.state.data.length > 0 ?
                                                    <>
                                                        {this.state.data.map((item: any, index: number) => {
                                                            let element = <CompletedStatus>{item.status}</CompletedStatus>;
                                                            if (item.status === "On Going") {
                                                                element = <OnGoingStatus>{item.status}</OnGoingStatus>;
                                                            } else if (item.status === "Yet to be started") {
                                                                element = <NotStartedStatus>{item.status}</NotStartedStatus>
                                                            }

                                                            return <TableRow key={`${index + 1}`}>
                                                                <TableCell style={{ fontFamily: "ProximaNova-Regular", fontSize: "18px", color: "#000" }}>
                                                                    <Box id="btn" style={{ cursor: "pointer" }} onClick={() => this.redirectToProjectDetail(item)}>
                                                                        <Box display="flex">
                                                                            <span style={{
                                                                                display: "block",
                                                                                whiteSpace: "nowrap",
                                                                                overflow: "hidden",
                                                                                textOverflow: "ellipsis",
                                                                                maxWidth: "140px"
                                                                            }}>{item.project}</span>
                                                                            <CustomTooltip
                                                                                arrow
                                                                                placement="right"
                                                                                title={
                                                                                    <Box>
                                                                                        <Box mt="10px" fontSize="18px">{this.t("reports.tooltip.assessmentDetails")}</Box>
                                                                                        <Box m="10px 0px">{this.t("reports.tooltip.completedBy")}: {moment(item.completedBy).format('DD MMM YYYY, h:mm a')}</Box>
                                                                                        <Box style={{ border: "0.1px solid #cacaca" }} />
                                                                                        <Box m="10px 0px">{this.t("reports.tooltip.toolUsed")}: {item.toolUsed}</Box>
                                                                                        <Box style={{ border: "0.1px solid #cacaca" }} />
                                                                                        <Box m="10px 0px">{this.t("reports.tooltip.evaluationType")}: {item.evaluationType}</Box>
                                                                                    </Box>
                                                                                }
                                                                            >
                                                                                <InfoOutlinedIcon style={{ width: "18px", height: "18px", cursor: "pointer", marginTop: "2px", marginLeft: "4px", color: "#333333" }} />
                                                                            </CustomTooltip>
                                                                        </Box>
                                                                    </Box>
                                                                </TableCell>
                                                                <TableCell align='left' style={{ fontFamily: "ProximaNova-Regular", fontSize: "18px", color: "#000", whiteSpace: "nowrap" }}>
                                                                    <Box display="flex" justifyContent="left">
                                                                        {element}
                                                                    </Box>
                                                                </TableCell>
                                                                <TableCell align='left' style={{ fontFamily: "ProximaNova-Regular", fontSize: "18px", color: "#000", whiteSpace: "nowrap" }}>
                                                                    {item.counter}
                                                                </TableCell>
                                                                <TableCell align='left' style={{ fontFamily: "ProximaNova-Regular", fontSize: "18px", color: "#000", whiteSpace: "nowrap", textTransform: "capitalize" }}>
                                                                    {item.type}
                                                                </TableCell>
                                                                <TableCell align='left' style={{ fontFamily: "ProximaNova-Regular", fontSize: "18px", color: "#000", whiteSpace: "nowrap" }}>
                                                                    {item.DCManager}
                                                                </TableCell>
                                                                <TableCell align='center' style={{ fontFamily: "ProximaNova-Regular", fontSize: "18px", color: "#000" }}>
                                                                    <DownloadButton
                                                                        id="download"
                                                                        startIcon={<img src={fileIcon} style={{margin:"0 10px"}} alt="not found" />}
                                                                        onClick={() => this.getParticipantDetail(item.id, item.project)}
                                                                    >
                                                                        {this.t("reports.button.download")}
                                                                    </DownloadButton>
                                                                </TableCell>
                                                            </TableRow>
                                                        })}
                                                    </>
                                                    : <TableRow>
                                                        <TableCell colSpan={6} align="center" style={{ fontFamily: "ProximaNova-Regular", fontSize: "18px", color: "#000", whiteSpace: "nowrap" }}>{this.t("reports.label.notFound")}</TableCell>
                                                    </TableRow>
                                                }
                                            </TableBody>
                                        </Table>
                                        {this.state.data.length > 0 &&
                                            <div className="custom-pagination">
                                                <Pagination
                                                    count={Math.ceil(this.state.dataLength / this.state.rowsPerPage)}
                                                    onChange={(event: unknown, newPage: number) => {
                                                        this.onChangeHandler(this.state.search, newPage - 1);
                                                    }}
                                                    variant="outlined"
                                                    shape="rounded"
                                                    showFirstButton
                                                    showLastButton
                                                    dir="ltr"
                                                    siblingCount={0}
                                                    boundaryCount={1}
                                                />
                                            </div>
                                        }
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </Box>
                    <CSVLink
                        data={this.state.csvData}
                        filename={this.state.fileName}
                        target="_blank"
                        ref={(ref: any) => { this.csvLink = ref; }}
                    />
                </AppSidebar>
            </StyleReport>
        );
    }
}

export default withRouter(withTranslation()(Reports));

const CustomTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    "& .MuiTooltip-tooltip": {
        padding: "4px 15px",
        borderRadius: "10px",
        margin: "0px 8px",
        fontSize: "13px",
        color: "black",
        backgroundColor: "white",
        border: "1.5px solid #d6d6d6",
    },
    "& .MuiTooltip-arrow": {
        color: "white",
    },
    "& .MuiTooltip-arrow::before": {
        border: "1.5px solid #d6d6d6",
    }
}));

const StyleReport = styled('div')({
    '& .SearchRight': {
        '& .MuiInputBase-root': {
            display: "flex",
            flexDirection: "row-reverse"
        }
    }
})

const NotStartedStatus = styled(Box)({
    backgroundColor: "#ffe1a6",
    borderRadius: "25px",
    fontSize: "18px",
    width: "fit-content",
    color: "#288a0a",
    fontFamily: "ProximaNova-Regular",
    padding: "3px 20px",
});

const OnGoingStatus = styled(Box)({
    fontFamily: "ProximaNova-Regular",
    backgroundColor: "#f5d3d3",
    fontSize: "18px",
    width: "fit-content",
    color: "#cd1414",
    borderRadius: "25px",
    padding: "3px 20px",
});

const CompletedStatus = styled(Box)({
    borderRadius: "25px",
    width: "fit-content",
    fontFamily: "ProximaNova-Regular",
    padding: "3px 20px",
    backgroundColor: "#dff8d2",
    color: "#288a0a",
    fontSize: "18px",
});

const RedButton = styled(Button)({
    backgroundColor: "#f94b4b",
    color: "white",
    borderRadius: "10px",
    fontFamily: "ProximaNova-Semibold",
    fontSize: "18px",
    "&:hover": {
        color: "white",
        backgroundColor: "#f94b4b",
    },
    "@media(max-width:600px)": {
        width: "100%",
        marginTop: "27px",
    },
    "@media(max-width:992px)": {
        padding: "8px 15px",
    },
    textTransform: "none",
    padding: "9px 25px",
});

const ExportButton = styled(Button)({
    backgroundColor: "white",
    color: "#eca00c",
    borderRadius: "10px",
    fontFamily: "ProximaNova-Semibold",
    fontSize: "14px",
    textTransform: "none",
    border: "1.5px solid #eca00c",
    padding: "10px 15px",
    "&:hover": {
        color: "#f26a42",
        backgroundColor: "white",
    },
});

const DownloadButton = styled(Button)({
    backgroundColor: "white",
    color: "#f26a42",
    borderRadius: "10px",
    fontFamily: "ProximaNova-Semibold",
    fontSize: "14px",
    textTransform: "none",
    border: "1.5px solid #f26a42",
    padding: "8px 15px",
    "&:hover": {
        color: "#f26a42",
        backgroundColor: "white",
    },
    "@media(max-width:600px)": {
        width: "100%",
    },
});

const filterByList = [
    { label: "All", value: "all" },
    { label: "Status", value: "status" },
    { label: "Projects", value: "project" },
];
// Customizable Area End