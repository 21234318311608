// Customizable Area Start
import React from "react";
import {
    Box,
    Button,
    Grid,
    TextField,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    InputBase,
    Dialog,
    DialogContent,
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import { styled } from "@material-ui/styles";
import PhoneInput from 'react-phone-input-2';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from "@material-ui/icons/Search";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import Select from "react-select";
import * as Yup from "yup";
import { Formik } from "formik";
import ManageSHRMUserController, { Props } from "./ManageSHRMUserController.web";
import 'react-phone-input-2/lib/style.css';
import AppSidebar from "../../../../components/src/AppSidebar.web";
const locallanguage = localStorage.getItem("language") === "ar";
import Spinner from "../../../../components/src/Spinner.web";

const editIcon = require("../../assets/edit.png");
const deleteIcon = require("../../assets/delete.png");

class ManageSHRMUser extends ManageSHRMUserController {
    constructor(props: Props) {
        super(props);
    }

    userSchema = () => {
        return Yup.object().shape({
            managerName: Yup.string().required(this.t("manageDCManager.errorMessages.managerName")),
            email: Yup.string()
                .email(this.t("manageDCManager.errorMessages.validEmail"))
                .required(this.t("manageDCManager.errorMessages.email")),
        });
    }

    getErrorMessage = (touched: any, errors: any, value: string) => {
        return touched[value] && errors[value] &&
            <div className="text-danger">{errors[value]}</div>
    }

    AddEditDilog = () => {
        return (
            <Dialog
                open={["Add", "Edit"].includes(this.state.openDialogName ?? "")}
                fullWidth={false}
                maxWidth={"sm"}
                scroll="body"
                transitionDuration={0}
                onClose={() => { }}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogContent style={{ padding: "0px", width: "100%" }}>
                    <Box style={{ textAlign: "right", margin: "10px 10px 0px 0px" }}>
                        <CloseIcon onClick={() => this.setState({ id: null, managerName: "", phoneNumber: "", email: "", userName: "", password: "", openDialogName: null })} style={{ cursor: "pointer", fontSize: "35px", color: "#787878" }} />
                    </Box>
                    <Box padding={{ xs: "15px 25px 25px", sm: "15px 40px 40px" }}>
                        <Box
                            id="modal-title"
                            fontWeight="bolder"
                            fontSize={{ xs: "20px", sm: "30px" }}
                            fontFamily="ProximaNova-Bold"
                            mb="20px"
                            color="#1b3c69"
                        >
                            {this.t(`manageDCManager.title.${this.state.openDialogName}`)} {this.t("manageDCManager.title.dcManager")}
                        </Box>
                        <Formik
                            initialValues={{
                                managerName: this.state.managerName,
                                phoneNumber: this.state.phoneNumber,
                                email: this.state.email,
                            }}
                            validationSchema={this.userSchema}
                            onSubmit={(values) => {
                                this.state.openDialogName === "Add"
                                    ? this.addUser()
                                    : this.editUser()
                            }}
                        >
                            {({ errors, touched, setFieldValue, handleSubmit }) => (
                                <StyleManageSHRMUser>
                                    <form
                                        onSubmit={handleSubmit}
                                        noValidate
                                        autoComplete="off"
                                    >
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} sm={6}>
                                                <div className="rightDirection" style={{ marginBottom: "5px", fontSize: "18px" }}>
                                                    {this.t("manageDCManager.label.managerName")}<span style={{ color: "red" }}>*</span>
                                                </div>
                                                <CustomInputBase
                                                    fullWidth
                                                    multiline
                                                    placeholder={this.t("manageDCManager.placeholder.managerName")}
                                                    value={this.state.managerName}
                                                    onBlur={(event: any) => this.onBlur()}
                                                    onChange={(event: any) => {
                                                        setFieldValue("managerName", event.target.value);
                                                        this.setState({ managerName: event.target.value });
                                                    }}
                                                />
                                                {this.getErrorMessage(touched, errors, "managerName")}
                                            </Grid>
                                            <Grid item xs={12} sm={6} className={locallanguage ? "CountrySelect" : ""}>
                                                <div className="rightDirection" style={{ marginBottom: "5px", fontSize: "18px" }}>
                                                    {this.t("manageDCManager.label.phoneNumber")}
                                                </div>
                                                <PhoneInput
                                                    country={'in'}
                                                    countryCodeEditable={false}
                                                    enableSearch={true}
                                                    disableSearchIcon={true}
                                                    placeholder={this.t("manageDCManager.placeholder.phoneNumber")}
                                                    containerClass="country-phone-input"
                                                    value={this.state.phoneNumber?.toString() ?? ""}
                                                    onChange={(phoneNumber: any) => {
                                                        setFieldValue("phoneNumber", phoneNumber);
                                                        this.setState({ phoneNumber });
                                                    }}
                                                    inputProps={{
                                                        name: 'phone',
                                                        required: true,
                                                    }}
                                                />
                                                {this.getErrorMessage(touched, errors, "phoneNumber")}
                                            </Grid>
                                            <Grid item xs={12}>
                                                <div className="rightDirection" style={{ marginBottom: "5px", fontSize: "18px" }}>
                                                    {this.t("manageDCManager.label.email")}<span style={{ color: "red" }}>*</span>
                                                </div>
                                                <CustomInputBase
                                                    placeholder={this.t("manageDCManager.placeholder.email")}
                                                    value={this.state.email}
                                                    multiline
                                                    onChange={(event: any) => {
                                                        setFieldValue("email", event.target.value);
                                                        this.setState({ email: event.target.value });
                                                    }}
                                                    fullWidth
                                                />
                                                {this.getErrorMessage(touched, errors, "email")}
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <div className="rightDirection" style={{ marginBottom: "5px", fontSize: "18px" }}>
                                                    {this.t("manageDCManager.label.username")}
                                                </div>
                                                <CustomInputBase
                                                    fullWidth
                                                    multiline
                                                    placeholder={this.t("manageDCManager.placeholder.username")}
                                                    value={this.state.userName}
                                                    onChange={(e: any) => this.setState({
                                                        userName: e.target.value
                                                    })}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <div className="rightDirection" style={{ marginBottom: "5px", fontSize: "18px" }}>
                                                    {this.t("manageDCManager.label.password")}
                                                </div>
                                                <CustomInputBase
                                                    fullWidth
                                                    multiline
                                                    placeholder={this.t("manageDCManager.placeholder.password")}
                                                    value={this.state.password}
                                                    onChange={(e: any) => this.setState({
                                                        password: e.target.value
                                                    })}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Box mt="30px" display={{ xs: "block", sm: "flex" }}>
                                            <CustomButton onClick={() => this.setState({ id: null, managerName: "", phoneNumber: "", email: "", userName: "", password: "", openDialogName: null })} style={{ padding: "5px 10px", marginLeft: locallanguage ? "10px" : "unset" }}>{this.t("manageDCManager.button.cancel")}</CustomButton>
                                            <RedButton type="submit">{this.t(`manageDCManager.title.${this.state.openDialogName}`)}</RedButton>
                                        </Box>
                                    </form>
                                </StyleManageSHRMUser>
                            )}
                        </Formik>
                    </Box>
                </DialogContent>
            </Dialog>
        )
    }

    render() {
        return (
            <div style={{ fontFamily: "ProximaNova-Regular" }}>
                {this.state.loading && <Spinner spinnerModal={this.state.loading} />}
                <AppSidebar type="admin" openAccordion>
                    <Box margin="35px 0px" mr="30px">
                        <Box display={{ xs: "block", md: "flex" }} justifyContent="space-between" mb="40px">
                            <Box>
                                <Box mb="5px" fontFamily="ProximaNova-Bold" fontSize={{ xs: "24px", sm: "30px", md: "44px" }} color="#1b3c69">{this.t("manageDCManager.title.main")}</Box>
                                <Box color="#1b3c69" fontSize={{ sm: "20px", xs: "14px" }}>{this.t("manageDCManager.description.main")}</Box>
                            </Box>
                            <Box mt={{ xs: "20px", md: "0px" }}>
                                <RedButton onClick={() => this.setState({ openDialogName: "Add" })} style={{ backgroundColor: "#f26a42", fontFamily: "ProximaNova-Semibold !important" }}>{this.t("manageDCManager.button.add")}</RedButton>
                            </Box>
                        </Box>
                        <Box style={{ borderRadius: "10px", backgroundColor: "#eff6ff" }}>
                            <Grid container spacing={2} style={{ alignItems: "end", padding: "20px 15px 10px 15px" }}>
                                <Grid item xs={12} sm={6} md={5}>
                                    <Box style={{ color: "#1b3c69" }}>
                                        <div style={{ fontSize: "18px", marginBottom: "5px" }}>{this.t("manageDCManager.label.sortBy")}</div>
                                        <Select
                                            openOnFocus={true}
                                            classNamePrefix="react-select"
                                            placeholder={this.t("manageDCManager.placeholder.sortBy")}
                                            autofocus={true}
                                            isSearchable={true}
                                            isClearable={true}
                                            options={this.state.clientList}
                                            value={
                                                this.state.clientList.filter(
                                                    (option: any) =>
                                                        option.value === this.state.client
                                                )[0]
                                            }
                                            onChange={(event: any) => {
                                                this.onsortByValueChange(event?.value);
                                            }}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={5}>
                                    <Box style={{ color: "#1b3c69" }}>
                                        <div style={{ marginBottom: "5px", fontSize: "18px" }}>{this.t("manageDCManager.label.project")}</div>
                                        <Select
                                            classNamePrefix="react-select"
                                            placeholder={this.t("manageDCManager.placeholder.project")}
                                            isSearchable={true}
                                            isClearable={true}
                                            options={this.state.projectList}
                                            value={
                                                this.state.projectList.filter(
                                                    (option: any) =>
                                                        option?.value === this.state.project
                                                )[0] ?? []
                                            }
                                            onChange={(event: any) => {
                                                this.setState({ project: event?.value });
                                            }}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={2}>
                                    <Box>
                                        <RedButton onClick={this.onApplyButtonClick}>{this.t("manageDCManager.button.apply")}</RedButton>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box mt="60px">
                            <Box>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Box mb="25px" display="flex" justifyContent="end">
                                            <TextField
                                                id="search"
                                                InputLabelProps={{
                                                    style: {
                                                        fontFamily: "ProximaNova-Regular",
                                                        zIndex: 0
                                                    }
                                                }}
                                                onChange={(event: any) => {
                                                    this.onChangeHandler(event.target.value, 0);
                                                }}
                                                label={this.t("manageDCManager.label.search")}
                                                variant="outlined"
                                                InputProps={{
                                                    endAdornment: localStorage.getItem("language") !== "ar" && <SearchIcon />,
                                                    startAdornment: localStorage.getItem("language") === "ar" && <SearchIcon />
                                                }}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} style={{ overflow: "auto" }} className="table-scrollbar">
                                        <Table aria-label="simple table" style={{ border: "1px solid #E0E0E0", borderRadius: "10px", borderCollapse: "separate" }} className="custom-table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell style={{ fontFamily: "ProximaNova-Bold", fontSize: "16px" }}>{this.t("manageDCManager.label.name")}</TableCell>
                                                    <TableCell align="left" style={{ fontFamily: "ProximaNova-Bold", fontSize: "16px", textTransform: "uppercase" }}>{this.t("manageDCManager.label.username")}</TableCell>
                                                    <TableCell align="center" style={{ fontSize: "16px", fontFamily: "ProximaNova-Bold" }}>{this.t("manageDCManager.label.phoneNo")}</TableCell>
                                                    <TableCell align="left" style={{ fontFamily: "ProximaNova-Bold", fontSize: "16px" }}>{this.t("manageDCManager.label.emailId")}</TableCell>
                                                    <TableCell align="left" style={{ fontSize: "16px", fontFamily: "ProximaNova-Bold" }}>{this.t("manageDCManager.label.passwordCapital")}</TableCell>
                                                    <TableCell align="right" style={{ fontFamily: "ProximaNova-Bold", fontSize: "16px" }}>{this.t("manageDCManager.label.action")}</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {this.state.data.length > 0 ?
                                                    <>
                                                        {this.state.data.map((item: any, index: number) => (
                                                            <TableRow key={`${index + 1}`}>
                                                                <TableCell>
                                                                    <span style={{ fontFamily: "ProximaNova-Regular", fontSize: "18px", color: "#000",display: 'block',maxWidth: "140px",whiteSpace: 'nowrap',overflow: 'hidden',textOverflow: 'ellipsis' }}>{item.managerName}</span>
                                                                </TableCell>
                                                                <TableCell style={{ fontFamily: "ProximaNova-Regular", fontSize: "18px", color: "#000" }}>
                                                                    {item.userName}
                                                                </TableCell>
                                                                <TableCell align='center' style={{ fontFamily: "ProximaNova-Regular", fontSize: "18px", color: "#000", whiteSpace: "nowrap" }}>
                                                                    {item.phoneNumber ? item.phoneNumber : "-"}
                                                                </TableCell>
                                                                <TableCell align='left' style={{ fontFamily: "ProximaNova-Regular", fontSize: "18px", color: "#000", whiteSpace: "nowrap" }}>
                                                                    {item.email}
                                                                </TableCell>
                                                                <TableCell style={{ fontFamily: "ProximaNova-Regular", fontSize: "18px", color: "#000" }}>
                                                                    {item.password}
                                                                </TableCell>
                                                                <TableCell align='right' style={{ fontFamily: "ProximaNova-Regular", fontSize: "18px", color: "#000" }}>
                                                                    <img id="edit" src={editIcon} style={{ cursor: "pointer", margin: "0 10px" }} onClick={() => this.setState({ id: item.id, managerName: item.managerName, userName: item.userName, phoneNumber: item.phoneNumber, email: item.email, password: item.password, openDialogName: "Edit" })} />
                                                                    <img id="delete" src={deleteIcon} style={{ cursor: "pointer" }} onClick={() => this.setState({ id: item.id, openDialogName: "Delete" })} />
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </>
                                                    : <TableRow>
                                                        <TableCell colSpan={6} align="center" style={{ fontSize: "18px", color: "#000", fontFamily: "ProximaNova-Regular", whiteSpace: "nowrap" }}>{this.t("manageDCManager.label.notFound")}</TableCell>
                                                    </TableRow>
                                                }
                                            </TableBody>
                                        </Table>
                                        {this.state.data.length > 0 &&
                                            <div className="custom-pagination">
                                                <Pagination
                                                    boundaryCount={1}
                                                    page={this.state.page + 1}
                                                    count={Math.ceil(this.state.dataLength / this.state.rowsPerPage)}
                                                    onChange={(event: unknown, newPage: number) => {
                                                        this.onChangeHandler(this.state.search, newPage - 1);
                                                    }}
                                                    dir="ltr"
                                                    shape="rounded"
                                                    showFirstButton
                                                    showLastButton
                                                    siblingCount={0}
                                                    variant="outlined"
                                                />
                                            </div>
                                        }
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </Box>
                </AppSidebar>

                {/* for add/edit user */}
                {this.AddEditDilog()}

                {/* for delete */}
                <Dialog
                    open={this.state.openDialogName === "Delete"}
                    fullWidth={false}
                    maxWidth={"sm"}
                    scroll="body"
                    onClose={() => { }}
                    aria-labelledby="scroll-dialog-title"
                    aria-describedby="scroll-dialog-description"
                >
                    <DialogContent style={{ padding: "0px", maxWidth: "500px" }}>
                        <Box style={{ paddingTop: "0px !important", textAlign: "right", margin: "10px 10px 0px 0px" }}>
                            <CloseIcon onClick={() => this.setState({ openDialogName: null })} style={{ cursor: "pointer", fontSize: "35px", color: "#787878" }} />
                        </Box>
                        <Box display="flex" alignItems="center" flexDirection="column" padding={{ xs: "15px 25px 25px", sm: "15px 70px 40px" }}>
                            <Box
                                id="modal-title"
                                fontWeight="bolder"
                                fontSize={{ xs: "20px", sm: "30px" }}
                                fontFamily="ProximaNova-Bold"
                                mb="20px"
                            >
                                {this.t("manageDCManager.title.delete")}
                            </Box>
                            <Box textAlign="center" fontSize="18px">
                                {this.t("manageDCManager.description.delete")}
                            </Box>
                            <Box mt="30px" display="flex">
                                <CustomButton id="cancel" onClick={() => this.setState({ openDialogName: null })} style={{ marginBottom: "0px", padding: "5px 10px", marginLeft: locallanguage ? "10px" : "unset" }}>{this.t("manageDCManager.button.cancel")}</CustomButton>
                                <RedButton onClick={this.deleteUser} style={{ padding: "5px 10px" }}>{this.t("manageDCManager.button.delete")}</RedButton>
                            </Box>
                        </Box>
                    </DialogContent>
                </Dialog>
            </div>
        );
    }
}

export default withRouter(withTranslation()(ManageSHRMUser));

const CustomInputBase = styled(InputBase)({
    border: "1px solid #787878",
    borderRadius: "10px",
    minHeight: "45px",
    paddingLeft: "15px",
    fontSize: "16px",
    '& .MuiInputBase-inputMultiline': {
        "&::placeholder": {
            fontSize: "17px",
        }
    }
});

const StyleManageSHRMUser = styled('div')({
    "& .rightDirection": {
        textAlign: locallanguage ? "right" : "unset",
    },
    "& .CountrySelect": {
        '& .react-tel-input': {
            display: "flex",
            flexDirection: "row-reverse",
            '& .flag-dropdown ': {
                width: "50px",
                "& .selected-flag": {
                    '& .flag': {
                        left: "0px"
                    }
                }
            }
        }
    }
})

const CustomButton = styled(Button)({
    backgroundColor: "white",
    color: "#f26a42",
    border: "1px solid #f26a42",
    fontFamily: "ProximaNova-Semibold",
    marginRight: "15px",
    borderRadius: "8px",
    fontSize: "18px",
    textTransform: "none",
    padding: "8px 25px",
    "&:hover": {
        color: "#f26a42",
        backgroundColor: "white",
    },
    "@media(max-width:600px)": {
        width: "100%",
    },
    "@media(max-width:992px)": {
        padding: "7px 15px",
    }
});

const RedButton = styled(Button)({
    backgroundColor: "#f94b4b",
    whiteSpace: "nowrap",
    color: "white",
    borderRadius: "10px",
    fontFamily: "ProximaNova-Semibold !important",
    fontSize: "18px",
    textTransform: "none",
    padding: "9px 25px",
    '& .MuiButton-label':{
        fontFamily: "ProximaNova-Semibold !important",
    },
    "&:hover": {
        color: "white",
        backgroundColor: "#f94b4b",
    },
    "@media(max-width:600px)": {
        width: "100%",
        marginTop: "27px",
    },
    "@media(max-width:992px)": {
        padding: "8px 15px",
    }
});
// Customizable Area End