// Customizable Area Start
import React from "react";
import {
  Button,
  Dialog,
  Grid,
  Typography,
  DialogContent,
  DialogActions,
  DialogTitle,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CircularProgress,
  Box,
  TextField,
  Table,
  TableCell,
  TableBody,
  TableRow,
  TableHead,
  Modal,
  Backdrop,
  Divider,
} from "@material-ui/core";
import { styled } from "@material-ui/styles";
import { withRouter } from "react-router-dom";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CloseIcon from "@material-ui/icons/Close";
import EditOutlined from "@material-ui/icons/EditOutlined";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import ThumbUpAltIcon from "@material-ui/icons/ThumbUp";
import ThumbDownAltIcon from "@material-ui/icons/ThumbDownAlt";
import SaveIcon from "@material-ui/icons/Save";
import CloudUploadOutlined from "@material-ui/icons/CloudUploadOutlined";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Field, Formik, isObject } from "formik";
import { Image } from "react-native";
import ContentManagementControllerWeb, {
  Props,
} from "./ContentManagementController.web";

class ContentManagementWeb extends ContentManagementControllerWeb {
  constructor(props: Props) {
    super(props);
  }

  renderImageElement = (tool: any, index: any) => {
    let element = (
      <Button
        variant="contained"
        fullWidth
        disabled={tool.image === null}
        onClick={() => this.pdfModelDataSet(tool.image, "Preview Image")}
      >
        Preview Image {index === 0 ? index + 1 : index}
      </Button>
    );
    if (tool.value === this.state.editToolId && tool.image) {
      element = (
        <Box
          textAlign="center"
          width="100%"
          border="1px solid #bebebe"
          borderRadius="8px"
        >
          <Box p="7px 0" fontSize="18px" whiteSpace="nowrap">
            <Box
              fontSize="16px"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Button
                variant="contained"
                onClick={() =>
                  this.pdfModelDataSet(tool.image, "Preview Image")
                }
              >
                {`Image${index}`} Preview
              </Button>
              <CloseIcon
                style={{
                  cursor: "pointer",
                }}
                onClick={() => this.onImageChange(null, index)}
              />
            </Box>
          </Box>
        </Box>
      );
    } else if (tool.value === this.state.editToolId) {
      element = (
        <Box>
          <input
            type="file"
            accept="image/*"
            style={{
              display: "none",
            }}
            id={`logo-${index}`}
            onChange={(event: any) => {
              this.onImageChange(event.target.files[0], index);
            }}
          />
          <label htmlFor={`logo-${index}`}>
            <Box
              textAlign="center"
              width="100%"
              border="1px solid #bebebe"
              style={{
                cursor: "pointer",
              }}
            >
              <Box p="6px 11px" whiteSpace="nowrap">
                Upload Image
              </Box>
            </Box>
          </label>
        </Box>
      );
    }
    return element;
  };

  renderGrid = () => {
    return (
      <Grid container spacing={2}>
        <Grid container style={{ padding: "20px 20px" }}>
          <Grid item xs={6}>
            <Typography variant="h4">Content Management Web</Typography>
          </Grid>
          <Grid item xs={6}>
            <Box>
              <Button variant="contained" onClick={this.CretaModelOpenAndClose}>
                Create Content
              </Button>
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="left" data-testid="usernme">
                  Content Management Heading
                </TableCell>
                {this.state.Usertype && (
                  <TableCell align="center">Status</TableCell>
                )}
                <TableCell align="center">Preview</TableCell>
                <TableCell align="center">ACTION</TableCell>
                {this.state.Usertype && (
                  <TableCell align="center">Approve/Reject</TableCell>
                )}
              </TableRow>
            </TableHead>
            {this.TableBodyGrid()}
          </Table>
        </Grid>
      </Grid>
    );
  };

  TableBodyGrid = () => {
    return (
      <TableBody data-testid="TableBodyData">
        {this.state.data.length > 0 ? (
          this.state.data.map((items: any, index: any) => {
            return (
              <TableRow key={items.id}>
                <TableCell>
                  {items.attributes.description.data.attributes.heading}
                </TableCell>
                {this.state.Usertype && (
                  <TableCell align="center">
                    {items.attributes.status}
                  </TableCell>
                )}
                <TableCell align="center">
                  <Button
                    data-testid="redbtn"
                    onClick={() => {
                      this.ViewDataOfContent(items.attributes.id);
                      this.setState({
                        StutasOfData:
                          items?.attributes?.status === "pending" ||
                          items?.attributes?.status === "rejected",
                      });
                    }}
                  >
                    Preview
                    {(items?.attributes?.status === "pending" ||
                      items?.attributes?.status === "rejected") &&
                      " Update Data"}
                  </Button>
                </TableCell>
                <TableCell
                  style={{
                    fontFamily: "ProximaNova-Regular",
                    fontSize: "18px",
                    color: "#000",
                  }}
                  align="center"
                >
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <EditOutlined
                        data-testid="handelOnlineToolsApiResponse"
                        width="21"
                        height="21"
                        style={{
                          cursor: "Pointer",
                        }}
                        onClick={() => this.handelOnlineToolsApiResponse(items)}
                      />
                      <Box
                        style={{
                          cursor: "Pointer",
                        }}
                        id="DeleteTableDataMode"
                        onClick={() => this.DeleteTableDataMode(items)}
                      >
                        <DeleteOutline width="21" height="21" />
                      </Box>
                    </Box>
                  </Box>
                </TableCell>
                {this.state.Usertype && (
                  <TableCell
                    style={{
                      fontFamily: "ProximaNova-Regular",
                      fontSize: "18px",
                      color: "#000",
                    }}
                    align="center"
                  >
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Box>
                        <Box>
                          <Button
                            disabled={items?.attributes?.status === "approved"}
                            onClick={() =>
                              this.ApproveModelFuncation(items, "Approve")
                            }
                          >
                            <ThumbUpAltIcon
                              data-testid="handelOnlineToolsApiResponse"
                              width="21"
                              height="21"
                            />
                          </Button>
                          <Button
                            disabled={
                              items?.attributes?.status === "pending" ||
                              items?.attributes?.status === "rejected"
                            }
                            onClick={() =>
                              this.ApproveModelFuncation(items, "Reject")
                            }
                          >
                            <ThumbDownAltIcon width="21" height="21" />
                          </Button>
                        </Box>
                      </Box>
                    </Box>
                  </TableCell>
                )}
              </TableRow>
            );
          })
        ) : (
          <TableRow>
            <TableCell colSpan={12} align="center">
              No record found!
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    );
  };

  renderPriviewDilog = () => {
    return (
      <Dialog
        open={this.state.PdfModel}
        onClose={this.PdfModelClose}
        scroll="body"
        fullScreen
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <StyleWraper>
          <Box className="heading" data-testid="model">
            <DialogTitle id="scroll-dialog-title">
              <Box>{this.state.ContentType}</Box>
            </DialogTitle>
            <Button
              size="small"
              onClick={this.PdfModelClose}
              variant="contained"
            >
              Close
            </Button>
          </Box>
          <DialogContent dividers>
            {this.state.ContentType === "Images" && (
              <Grid item xs={12}>
                <Box className="ImgContainer">
                  <Image
                    style={{
                      width: "100%",
                    }}
                    source={this.state.MediaFile}
                  />
                </Box>
              </Grid>
            )}
            {this.state.ContentType === "label" && (
              <Grid item xs={12}>
                <Typography variant="h4">
                  {this.state?.onlineToolList[this.state.Lableindex]?.label}
                </Typography>
              </Grid>
            )}
            {this.state.ContentType === "description" && (
              <Grid item xs={12}>
                <Typography variant="h5">
                  <Box
                    dangerouslySetInnerHTML={this.HtmlConverter(
                      this.state?.onlineToolList[this.state.Lableindex]
                        ?.description
                    )}
                  />
                </Typography>
              </Grid>
            )}
            {this.state.ContentType === "Preview Image" && (
              <Grid container spacing={2}>
                {isObject(this.state.MediaFile) ? (
                  <Grid item xs={12}>
                    <Box style={{ width: "100%" }} className="ImgContainer">
                      <Image
                        style={{
                          width: "100%",
                        }}
                        source={{
                          uri: URL.createObjectURL(this.state.MediaFile),
                        }}
                      />
                    </Box>
                  </Grid>
                ) : (
                  <Grid item xs={12}>
                    <Box style={{ width: "100%" }} className="ImgContainer">
                      <Image
                        style={{
                          width: "100%",
                        }}
                        source={{ uri: this.state.MediaFile }}
                      />
                    </Box>
                  </Grid>
                )}
              </Grid>
            )}
          </DialogContent>
        </StyleWraper>
        <DialogActions>
          <Button onClick={this.PdfModelClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    );
  };

  descriptionheading = (props: any) => {
    return (
      <Grid item xs={12}>
        <Box>description heading</Box>
        <Box>
          <TextField
            name="heading"
            id="heading"
            value={props.values.heading}
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            variant="outlined"
            fullWidth
            style={{
              border:
                props.errors.heading && props.touched.heading
                  ? "2px solid red"
                  : "",
            }}
          />
          {props.errors.heading && props.touched.heading ? (
            <Typography variant="h6" className="validation_errors">
              {props.errors.heading}
            </Typography>
          ) : null}
        </Box>
      </Grid>
    );
  };

  descriptioncontent = (props: any) => {
    return (
      <Grid item xs={12} style={{ marginBottom: "60px" }}>
        <Box>description content</Box>
        <Box style={{ marginTop: "10px" }}>
          <Field name="content">
            {(data: any) => {
              let modules = {
                toolbar: [
                  [{ header: [1, 2, 3, 4, 5, 6, 7] }],
                  ["bold", "italic", "underline", "strike", "blockquote"],
                  [
                    { list: "ordered" },
                    { list: "bullet" },
                    { indent: "-1" },
                    { indent: "+1" },
                  ],
                  ["link", "image"],
                  ["clean"],
                ],
              };
              let formats = [
                "header",
                "bold",
                "italic",
                "underline",
                "strike",
                "blockquote",
                "list",
                "bullet",
                "indent",
                "link",
                "image",
              ];
              return (
                <ReactQuill
                  theme="snow"
                  value={data.field.value}
                  onBlur={data.field.handleBlur}
                  onChange={data.field.onChange(data.field.name)}
                  modules={modules}
                  formats={formats}
                />
              );
            }}
          </Field>
          {props.errors.content && props.touched.content ? (
            <Typography variant="h6" className="validation_errors">
              {props.errors.content}
            </Typography>
          ) : null}
        </Box>
      </Grid>
    );
  };

  renderGridItem = (tool: any, index: number) => {
    return (
      <Grid
      item
      xs={12}
      style={{
        fontFamily: "ProximaNova-Regular",
        fontSize: "18px",
        color: "#000",
      }}
    >
      {tool.value === this.state.editToolId ? (
        <ReactQuill
          placeholder="Enter Content"
          theme="snow"
          onChange={(event: any) =>
            this.onToolDescriptionChange(
              event,
              index
            )
          }
          modules={{
            toolbar: [
              [{ header: [1, 2, 3, 4, 5, 6, 7] }],
              [
                "bold",
                "italic",
                "underline",
                "strike",
                "blockquote",
              ],
              [
                { list: "ordered" },
                { list: "bullet" },
                { indent: "-1" },
                { indent: "+1" },
              ],
              ["link", "image"],
              ["clean"],
            ],
          }}
          formats={[
            "header",
            "bold",
            "italic",
            "underline",
            "strike",
            "blockquote",
            "list",
            "bullet",
            "indent",
            "link",
            "image",
          ]}
          value={tool.description}
        />
      ) : (
        <Button
          fullWidth
          onClick={() =>
            this.LableDataSet(
              tool.description,
              "description",
              index
            )
          }
        >
          View Content
        </Button>
      )}
    </Grid>
    );
  };
  HeadingGridItem = (tool: any, index: number) => {
    return (
      <Grid item xs={12}>
        {tool.value === this.state.editToolId ? (
          <TextField
            variant="outlined"
            style={{ width: "100%" }}
            value={tool.label}
            placeholder="Enter Heading"
            inputRef={(input) => (this.inputRef = input)}
            onChange={(event: any) => this.onToolNameChange(event, index)}
          />
        ) : (
          <Button
            fullWidth
            onClick={() => this.LableDataSet(tool.label, "label", index)}
          >
            View Heading
          </Button>
        )}
      </Grid>
    );
  };

  CreateContents = () => {
    return (
      <Dialog
        open={this.state.CreateContentModel}
        onClose={this.CretaModelOpenAndClose}
        scroll="body"
        fullScreen
      >
        <StyleWraper>
          <Box className="heading" data-testid="model">
            <DialogTitle id="scroll-dialog-title">
              {this.state.ContentMainData.length !== 0 ? "Edit " : "Create "}
              Contents
            </DialogTitle>
            <Button onClick={this.CretaModelOpenAndClose} variant="contained">
              Close
            </Button>
          </Box>
          <DialogContent dividers>
            <Formik
              initialValues={{
                heading: this.state.ContentMainData?.heading || "",
                content: this.state.ContentMainData?.content || "",
                image: this.state.ContentMainData?.image || "",
                video: this.state.ContentMainData?.video || "",
                audio: this.state.ContentMainData?.audio || "",
                pdf: this.state.ContentMainData?.pdf || "",
              }}
              validationSchema={this.AssignAvailableAssessorEditSchema}
              onSubmit={(values) => {
                this.SubmitData(values);
              }}
              validateOnChange={true}
              validateOnBlur={true}
            >
              {(props) => (
                <form onSubmit={props.handleSubmit}>
                  <Box style={{ marginTop: "15px" }}>
                    <Grid className="label-parent" container spacing={3}>
                      {this.descriptionheading(props)}
                      {this.descriptioncontent(props)}
                      <Grid item xs={12}>
                        <Box>description image</Box>
                        <Box
                          style={{
                            marginTop: "15px",
                          }}
                        >
                          <Box>
                            <Box component="label" style={{ width: "100%" }}>
                              <Box
                                p={{ xs: "25px" }}
                                textAlign="center"
                                width="100%"
                                style={{ cursor: "pointer" }}
                              >
                                <input
                                  type="file"
                                  accept="image/png, image/jpeg, image/jpg"
                                  style={{ display: "none" }}
                                  data-testid="props.image"
                                  onChange={(event: any) => {
                                    props.setFieldValue(
                                      "image",
                                      event.target.files[0]
                                    );
                                  }}
                                />
                                {props.values.image !== "" ? (
                                  <Box style={{ border: "1px solid grey" }}>
                                    <Box mt="15px">
                                      Drop or Browse your file here for Update
                                      (.png, .jpg)
                                    </Box>
                                    <Button
                                      onClick={() =>
                                        this.pdfModelDataSet(
                                          props.values.image,
                                          "Preview Image"
                                        )
                                      }
                                    >
                                      Preview Image
                                    </Button>
                                  </Box>
                                ) : (
                                  <Box style={{ border: "1px solid grey" }}>
                                    <CloudUploadOutlined />
                                    <Box mt="15px">
                                      Drop or Browse your file here for Upload
                                      (.png, .jpg)
                                    </Box>
                                  </Box>
                                )}
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="h4">
                          Multiple Section part start
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Box
                          mt={{ xs: "10px", sm: "0px" }}
                          style={{ color: "#f26a42" }}
                        >
                          <Button
                            onClick={this.addToolRecord}
                            disabled={this.state.isAddToolButtonClick}
                          >
                            Click Add New Section
                          </Button>
                        </Box>
                      </Grid>
                      {this.state.onlineToolList.length > 0 ? (
                        this.state.onlineToolList.map(
                          (tool: any, index: number) => {
                            let ImageElement = this.renderImageElement(
                              tool,
                              index
                            );
                            return (
                              <Box
                                key={`${index + 1}`}
                                style={{ width: "100%" }}
                              >
                                <Grid
                                  container
                                  spacing={1}
                                  style={{
                                    borderRadius: "10px",
                                    borderCollapse: "separate",
                                    marginTop: "10px",
                                    marginBottom: "10px",
                                    padding: "5px",
                                  }}
                                >
                                  <Grid
                                    item
                                    xs={12}
                                    style={{
                                      fontFamily: "ProximaNova-Regular",
                                      fontSize: "18px",
                                      color: "#000",
                                    }}
                                  >
                                    {ImageElement}
                                  </Grid>
                                  {this.HeadingGridItem(tool, index)}
                                  {this.renderGridItem(tool, index)}
                                </Grid>
                                <Grid container spacing={1}>
                                  <Grid item xs={6}>
                                    <Button
                                      fullWidth
                                      onClick={() =>
                                        this.onDeleteToolButtonClick(tool)
                                      }
                                    >
                                      Delete {tool.index}
                                      <DeleteOutline />
                                    </Button>
                                  </Grid>
                                  <Grid item xs={6}>
                                    {tool.value === this.state.editToolId && (
                                      <Button
                                        fullWidth
                                        onClick={() => this.onSaveTool(index)}
                                      >
                                        Save {tool.index}
                                        <SaveIcon
                                          style={{
                                            cursor: "pointer",
                                            color: "white",
                                          }}
                                        />
                                      </Button>
                                    )}
                                    {tool.value !== this.state.editToolId && (
                                      <Button
                                        fullWidth
                                        onClick={() =>
                                          this.onEditButtonClick(tool, index)
                                        }
                                      >
                                        Edit {tool.index}
                                        <EditOutlined
                                          style={{
                                            cursor: "pointer",
                                          }}
                                        />
                                      </Button>
                                    )}
                                  </Grid>
                                </Grid>
                              </Box>
                            );
                          }
                        )
                      ) : (
                        <Grid container>
                          <Grid item xs={12}>
                            Click Add New Section For Add Section
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  </Box>
                  <Box
                    style={{
                      fontSize: "18px",
                      fontFamily: "ProximaNova-regular",
                      textAlign: "left",
                      lineHeight: "1.27",
                      padding: "2px",
                      marginTop: "10px",
                    }}
                  >
                    <Button
                      variant="contained"
                      size="large"
                      onClick={this.CretaModelOpenAndClose}
                    >
                      Cancel
                    </Button>
                    <Button
                      className="saveBtn"
                      type="submit"
                      size="large"
                      data-testid="Submit"
                    >
                      Save
                    </Button>
                  </Box>
                </form>
              )}
            </Formik>
          </DialogContent>
        </StyleWraper>
        <DialogActions>
          <Button onClick={this.CretaModelOpenAndClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    );
  };

  DeleteModel = () => {
    return (
      <Dialog
        open={this.state.DeleteTableData}
        keepMounted
        onClose={this.DeleteTableDataMode}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>Are you Sure want Delete{} ?</DialogTitle>
        <StyleWraper>
          <DialogActions>
            <Button variant="contained" onClick={this.DeleteTableDataMode}>
              Cancel
            </Button>
            <Button variant="contained" onClick={this.DeleteData}>
              Yes
            </Button>
          </DialogActions>
        </StyleWraper>
      </Dialog>
    );
  };

  ApproveModelRender = () => {
    return (
      <Dialog
        open={this.state.ApproveModel}
        keepMounted
        onClose={this.ApproveModelFuncation}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>Are you Sure want {this.state.TextForModel} ?</DialogTitle>
        <StyleWraper>
          <DialogActions>
            <Button
              variant="contained"
              onClick={() => this.ApproveModelFuncation("", "")}
            >
              Cancel
            </Button>
            <Button
              id="ApproveId"
              variant="contained"
              onClick={() =>
                this.state.TextForModel === "Approve"
                  ? this.ApproveAPiCall()
                  : this.RejectAPiCall()
              }
            >
              Yes
            </Button>
          </DialogActions>
        </StyleWraper>
      </Dialog>
    );
  };

  PreviewDataOfSeletectData = () => {
    return (
      <Dialog
        open={this.state.Previewdata.length !== 0}
        onClose={this.PreviewDataClose}
        scroll="body"
        fullScreen
      >
        <StyleWraper>
          <Box className="heading" data-testid="model">
            <DialogTitle id="scroll-dialog-title">
              <Box style={{ textTransform: "uppercase" }}>Preview</Box>
            </DialogTitle>
            <Button
              size="small"
              onClick={this.PreviewDataClose}
              variant="contained"
            >
              Close
            </Button>
          </Box>
          <Box
            style={{
              backgroundColor: "#1b5a91",
              color: "white",
              padding: "5px 10px",
            }}
          >
            <Typography variant="h4">New Data</Typography>
          </Box>
          {this.state.Previewdata?.map((items: any, index: number) => {
            return (
              <Grid
                item
                xs={12}
                key={`${items.id + index}`}
                style={{ padding: "20px 20px", backgroundColor: "#1b5a91" }}
              >
                <Accordion
                  expanded={this.state.isExpanded === items.id}
                  onChange={this.AcordingOpen(items.id)}
                  data-testid={`first${index}`}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2bh-content"
                    id="panel2bh-header"
                  >
                    <Typography
                      gutterBottom
                      variant="h5"
                      className="Heading"
                      component="div"
                    >
                      {items.attributes.description.data.attributes.heading}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails style={{ padding: "20px 10px" }}>
                    <Box className="MainDiv" data-testid={`test${index}`}>
                      <Grid container spacing={2} justifyContent="center">
                        <Grid item xs={12}>
                          <Typography
                            style={{ margin: "10px  0px" }}
                            variant="body2"
                          >
                            <Box
                              style={{ marginLeft: "20px" }}
                              dangerouslySetInnerHTML={this.HtmlConverter(
                                items?.attributes?.description?.data?.attributes
                                  .content
                              )}
                            />
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          {items.attributes.description.data.attributes
                            .image !== null && (
                            <Button
                              variant="contained"
                              style={{ marginLeft: "20px" }}
                              id="showImage1"
                              onClick={() =>
                                this.pdfModelDataSet(
                                  items.attributes.description.data.attributes
                                    .image,
                                  "Images"
                                )
                              }
                            >
                              Show Image
                            </Button>
                          )}
                        </Grid>
                      </Grid>
                    </Box>
                  </AccordionDetails>
                  <Divider />
                  <Box
                    style={{ color: "white" }}
                    data-testid={`Sections${index}`}
                  >
                    <Typography variant="h4">Sections part</Typography>
                  </Box>
                  <Box className="MainDiv">
                    {items.attributes.sections.data.map(
                      (datas: any, index: number) => {
                        return (
                          <AccordionDetails
                            key={`${items.id + index}`}
                            style={{
                              margin: "20px",
                              padding: "10px",
                              backgroundColor: "#0000003d",
                            }}
                          >
                            <Grid item xs={12}>
                              <Accordion
                                expanded={this.state.isExpandedInner === index}
                                onChange={this.AcordingOpenInner(index)}
                              >
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel2bh-content"
                                  id="panel2bh-header"
                                >
                                  <Typography
                                    gutterBottom
                                    variant="h5"
                                    component="div"
                                  >
                                    {
                                      datas.attributes.description.data
                                        .attributes.heading
                                    }
                                  </Typography>
                                </AccordionSummary>
                                <AccordionDetails style={{ padding: "20px" }}>
                                  <Grid
                                    container
                                    spacing={2}
                                    justifyContent="center"
                                    alignItems="center"
                                  >
                                    <Grid item xs={12}>
                                      <Typography
                                        style={{
                                          margin: "10px  0",
                                          padding: "20px",
                                        }}
                                        variant="body2"
                                      >
                                        <Box
                                          style={{ marginLeft: "20px" }}
                                          dangerouslySetInnerHTML={this.HtmlConverter(
                                            datas.attributes.description.data
                                              .attributes.content
                                          )}
                                        />
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                      {datas.attributes.description.data
                                        .attributes.image !== null && (
                                        <Box style={{ display: "block" }}>
                                          <Button
                                            variant="contained"
                                            style={{ marginLeft: "20px" }}
                                            onClick={() =>
                                              this.pdfModelDataSet(
                                                datas.attributes.description
                                                  .data.attributes.image,
                                                "Images"
                                              )
                                            }
                                          >
                                            Show Image
                                          </Button>
                                        </Box>
                                      )}
                                    </Grid>
                                  </Grid>
                                </AccordionDetails>
                              </Accordion>
                            </Grid>
                          </AccordionDetails>
                        );
                      }
                    )}
                  </Box>
                </Accordion>
              </Grid>
            );
          })}
          {this.state.OldContent.length > 0 &&
            this.state.Previewdata.length > 0 &&
            this.state.StutasOfData && (
              <Box
                style={{
                  backgroundColor: "grey",
                  color: "white",
                  padding: "5px 10px",
                }}
              >
                <Typography variant="h4">Old Data</Typography>
              </Box>
            )}
          {this.state.OldContent.length > 0 &&
            this.state.StutasOfData &&
            this.state.OldContent?.map((items: any, index: number) => {
              return (
                <Grid
                  item
                  xs={12}
                  key={`${items.id + index}`}
                  style={{ padding: "20px 20px", backgroundColor: "grey" }}
                >
                  <Accordion
                    expanded={this.state.isExpanded === index}
                    onChange={this.AcordingOpen(index)}
                    data-testid={`first${index}`}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2bh-content"
                      id="panel2bh-header"
                    >
                      <Typography
                        gutterBottom
                        variant="h5"
                        className="Heading"
                        component="div"
                      >
                        {items.attributes.description.data.attributes.heading}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{ padding: "20px 0" }}>
                      <Box className="MainDiv" data-testid={`test${index}`}>
                        <Grid container spacing={2} justifyContent="center">
                          <Grid item xs={12}>
                            <Typography
                              style={{ margin: "10px  20px" }}
                              variant="body2"
                            >
                              <Box
                                style={{ marginLeft: "20px" }}
                                dangerouslySetInnerHTML={this.HtmlConverter(
                                  items?.attributes?.description?.data
                                    ?.attributes.content
                                )}
                              />
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            {items.attributes.description.data.attributes
                              .image !== null && (
                              <Button
                                variant="contained"
                                style={{ marginLeft: "20px" }}
                                onClick={() =>
                                  this.pdfModelDataSet(
                                    items.attributes.description.data.attributes
                                      .image,
                                    "Images"
                                  )
                                }
                              >
                                Show Image
                              </Button>
                            )}
                          </Grid>
                        </Grid>
                      </Box>
                    </AccordionDetails>
                    <Divider />
                    <Box style={{ color: "white" }}>
                      <Typography variant="h4">Sections part</Typography>
                    </Box>
                    <Box className="MainDiv">
                      {items.attributes.sections.data.map(
                        (datas: any, index: number) => {
                          return (
                            <AccordionDetails
                              key={`${items.id + index}`}
                              style={{
                                margin: "20px",
                                padding: "10px",
                                backgroundColor: "#0000003d",
                              }}
                            >
                              <Grid item xs={12}>
                                <Accordion
                                  expanded={
                                    this.state.isExpandedInner === index
                                  }
                                  onChange={this.AcordingOpenInner(index)}
                                >
                                  <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel2bh-content"
                                    id="panel2bh-header"
                                  >
                                    <Typography
                                      gutterBottom
                                      variant="h5"
                                      component="div"
                                    >
                                      {
                                        datas.attributes.description.data
                                          .attributes.heading
                                      }
                                    </Typography>
                                  </AccordionSummary>
                                  <AccordionDetails style={{ padding: "20px" }}>
                                    <Grid
                                      container
                                      spacing={2}
                                      justifyContent="center"
                                      alignItems="center"
                                    >
                                      <Grid item xs={12}>
                                        <Typography
                                          style={{
                                            margin: "10px  0",
                                            padding: "20px",
                                          }}
                                          variant="body2"
                                        >
                                          <Box
                                            style={{ marginLeft: "20px" }}
                                            dangerouslySetInnerHTML={this.HtmlConverter(
                                              datas.attributes.description.data
                                                .attributes.content
                                            )}
                                          />
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={4}>
                                        {datas.attributes.description.data
                                          .attributes.image !== null && (
                                          <Box style={{ display: "block" }}>
                                            <Button
                                              variant="contained"
                                              style={{ marginLeft: "20px" }}
                                              onClick={() =>
                                                this.pdfModelDataSet(
                                                  datas.attributes.description
                                                    .data.attributes.image,
                                                  "Images"
                                                )
                                              }
                                            >
                                              Show Image
                                            </Button>
                                          </Box>
                                        )}
                                      </Grid>
                                    </Grid>
                                  </AccordionDetails>
                                </Accordion>
                              </Grid>
                            </AccordionDetails>
                          );
                        }
                      )}
                    </Box>
                  </Accordion>
                </Grid>
              );
            })}
        </StyleWraper>
      </Dialog>
    );
  };

  render() {
    return (
      <StyleWraper data-testid="Heading">
        {this.state.loader && (
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            open={this.state.loader}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
            disableAutoFocus={true}
          >
            <CircularProgress value={10} size={60} />
          </Modal>
        )}

        {this.renderGrid()}
        {this.renderPriviewDilog()}
        {this.CreateContents()}
        {this.DeleteModel()}
        {this.ApproveModelRender()}
        {this.PreviewDataOfSeletectData()}
      </StyleWraper>
    );
  }
}

export default withRouter(ContentManagementWeb);

const StyleWraper = styled("div")({
  width: "100%",
  "& .MuiButton-root": {
    backgroundColor: "#1a3189",
    color: "white",
    fontWeight: "bolder",
    margin: "0px 5px",
  },
  "& .MuiTableCell-root": {
    fontFamily: "ProximaNova-Regular",
    fontSize: "18px",
    color: "#000",
    fontWeight: 700,
    textTransform: "uppercase",
  },
  "& .Mui-disabled": {
    backgroundColor: "grey",
    color: "white",
    fontWeight: "bolder",
    margin: "0px 5px",
  },
  "& .labels": {
    textTransform: "capitalize",
  },
  "& .Heading": {
    fontWeight: "bolder",
  },
  "& .MuiGrid-spacing-xs-2": {
    width: "100%",
  },
  "& .MainDiv": {
    width: "100%",
  },
  "& .ImgContainer": {
    display: "flex",
    justifyContent: "center",
    "& div": {
      position: "sticky",
      backgroundImage: "none",
      "& img": {
        opacity: "0",
        position: "sticky",
        transform: "scale(0.9,0.9)",
      },
    },
  },
  "@media(max-width:600px)": {
    "& .MuiTypography-root": {
      fontSize: "16px",
    },
  },
});
// Customizable Area End
