// Customizable Area Start
import React, { useState } from "react"
import {
    Box,
} from "@material-ui/core";

interface Props {
    name?: string,
    docurl?: string
}

const AssessorDocument = (props:Props) => {    
    const [numPages, setNumPages] = useState(null);
    const pageNumber = 1;
    
    function onDocumentLoadSuccess({ numPages }:any) {
        setNumPages(numPages);
    }
    
    return (
        <div>
            
                <Box margin="35px 0px" mr="30px">
                    <Box display={{ xs: "block", md: "flex" }} justifyContent="space-between" mb="40px">
                        <Box>
                            <Box mb="5px" fontFamily="ProximaNova-Bold" fontSize={{ xs: "24px", sm: "30px", md: "44px" }} color="#1b3c69">{props.name}</Box>
                            <Box color="#1b3c69" fontSize={{ xs: "14px", sm: "20px" }}>Below is the list of mapped project document</Box>
                            {/* <Document
                                file={props.docurl}
                                onLoadSuccess={onDocumentLoadSuccess}
                            >
                                <Page pageNumber={pageNumber} />
                            </Document> */}
                            <p>Page {pageNumber} of {numPages}</p>
                        </Box>
                    </Box>
                </Box>

        </div>
    )
}
export default AssessorDocument
// Customizable Area End
