import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { runEngine } from "../../../../framework/src/RunEngine";
import MessageEnum, {
    getName,
} from "../../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { toast } from "react-toastify";
import moment from "moment";
// Customizable Area End

export const configJSON = require("../config");

export interface Props {
    id?: string;
    navigation?: any;
    location: any;
    history: any;
    match: any;
    t: any;
}

export interface S {
    // Customizable Area Start
    loading: boolean;
    projectId: any;
    projectName: string;
    page: number;
    rowsPerPage: number;
    dataLength: number;
    count: number | null;
    search: string;
    openDialogName: string | null;
    data: any[];
    tableData: any[];
    recordId: string;
    slotId: string;
    participantName: string;
    toolType: string;
    definition: any[];
    scoreGap: number;
    competency: string;
    competencyIndex: number;
    csvData: any[];
    scoreDetail: any[];
    Userrole:any
    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class ProjectDetailController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    getParticipantDetailApiCallId: string = "";
    getParticipantReportApiCallId: string = "";
    editFeedbackApiCallId: string = "";

    csvLink: any = "";
    t: any;
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage),
        ];
        this.receive = this.receive.bind(this);
        runEngine.attachBuildingBlock(this, this.subScribedMessages);

        this.state = {
            // Customizable Area Start
            loading: false,
            projectId: null,
            projectName: "",
            page: 0,
            rowsPerPage: 20,
            dataLength: 0,
            count: null,
            search: "",
            openDialogName: "",
            data: [],
            tableData: [],
            recordId: "",
            slotId: "",
            participantName: "",
            toolType: "",
            scoreGap: 1,
            definition: [],
            competency: "",
            competencyIndex: 0,
            csvData: [],
            scoreDetail: [],
            Userrole: localStorage.getItem("userRole")?.toString()
            // Customizable Area End
        };
        this.t = this.props.t;
    }

    async receive(from: string, message: Message) {
        this.RecievedCallApiCall(from,message)
    }

    RecievedCallApiCall = (from:any,message:any) => {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (apiRequestCallId && responseJson) {

                if (responseJson.status === 500) {

                    toast.error(this.t("projectDetail.toast.somethingWrong"));
                    this.setState({ loading: false });
                    
                } else {

                    this.handleApiResponses(apiRequestCallId, responseJson);
                    
                }
            }
        }
    }

    handleApiResponses = (apiRequestCallId: string, responseJson: any) => {
        if (Array.isArray(responseJson.errors)) {
            if (responseJson.errors[0].message) {
                toast.error(responseJson.errors[0].message);
            } else if (responseJson.errors[0].token) {
                toast.error(responseJson.errors[0].token);
            } else {
                toast.error(this.t("projectDetail.toast.somethingWrong"));
            }
            this.setState({ loading: false });
        } else if (apiRequestCallId === this.getParticipantDetailApiCallId) {
            this.handleTableDataApiResponse(responseJson);
        } else if (apiRequestCallId === this.getParticipantReportApiCallId) {
            this.handleParticipantReportResponse(responseJson);
        } else if (apiRequestCallId === this.editFeedbackApiCallId) {
            this.handleFeedbackApiResponse(responseJson);
        }
    }

    async componentDidMount() {
        window.scrollTo(0, 0);
        let state = this.props.location.state;
        if (state) {
            this.setState({ projectId: state.projectId, projectName: state.projectName });
            this.getParticipantDetail(state.projectId);
        }
    }

    handleTableDataApiResponse = (responseJson: any) => {
        const data = responseJson.data.map((item: any, index: number) => {
            let totalScore = 0;
            let scoreDetail = [];
            const toolName = item.attributes.tool_name;
            let slotId = 0;
            let scoreGap = 1;
            let ratings: any[] = [];
            if (toolName !== "OnlineTool") {
                scoreDetail = item.attributes.set_score.map((item: any, idx: number) => {
                    slotId = item.assessor_participant_slot_id;
                    const score = parseFloat(item.score);
                    totalScore += score;
                    return {
                        index: idx,
                        score: score,
                        label: item.name,
                        value: item.competancy_id?.toString(),
                        comment: item.comment
                    }
                });
                scoreGap = item.attributes.set_increament ?? "0.5";
                ratings = item.attributes.ratings.data.filter((rating: any) => {
                    if (rating.attributes.range === scoreGap) {
                        return true;
                    } else {
                        return false;
                    }
                });
            }
            return {
                id: index,
                participantId: item.attributes.id,
                slotId: slotId.toString(),
                definition: ratings,
                scoreGap: toolName === "OnlineTool" ? 1 : Number(scoreGap),
                participantName: item.attributes.full_name,
                toolId: item.attributes.tool_id,
                type: item.attributes.tool_type,
                client: item.attributes.client,
                toolName: toolName,
                scoreDetail: scoreDetail,
                isReportAvailable: toolName === "OnlineTool" ? item.attributes.test_status === "completed" : item.attributes.set_score.length > 0,
                score: toolName === "OnlineTool" ? item.attributes.score : parseFloat(totalScore.toString()).toFixed(1)
            }
        });

        this.setState({ tableData: data, loading: false },
            () => { this.onChangeHandler(this.state.search, 0) }
        );
    }

    handleParticipantReportResponse = (responseJson: any) => {
        if (responseJson.data.length === 0) {
            toast.error(this.t("projectDetail.toast.reportNotAvailable"));
            this.setState({ loading: false });
            return;
        }
        const csvData = responseJson?.data?.map((item: any) => {
            let options = "";
            let correctAnswer = "";
            let participantAnswer = "";

            item.attributes.question_ids.data.attributes.answers.data.forEach((option: any, index: number) => {
                let optionText = "";
                if (option.attributes.answer) {
                    optionText = option.attributes.answer;
                } else {
                    optionText = option.attributes.image_url?.url;
                }
                if (option.attributes.correct_answer) {
                    correctAnswer += `${String.fromCharCode(65 + index)}` + ", ";
                }
                if (item.attributes.question_ids.data.attributes.answer_ids.some((ans: any) => ans.toString() === option.id.toString())) {
                    participantAnswer += `${String.fromCharCode(65 + index)}` + ", ";
                }
                options += `(${String.fromCharCode(65 + index)}) ` + optionText + "  ";
            });

            return [
                item.attributes.question_ids.data.attributes.name,
                item.attributes.question_ids.data.attributes.image_url?.url ?? "N/A",
                options.slice(0, -1),
                correctAnswer.slice(0, -2),
                participantAnswer.slice(0, -2),
                item.attributes.question_ids.data.attributes.mark ?? "N/A",
                item.attributes.question_ids.data.attributes.online_tool.name,
                item.attributes.question_ids.data.attributes.level,
                item.attributes.question_ids.data.attributes.industry.industry_name,
                item.attributes.question_ids.data.attributes.competancy.name,
            ]
        });

        const row1 = ['Participant Name', this.state.participantName];
        const row2 = ['Project Name', this.state.projectName];
        const row3 = ['Tool Name', 'Online Tool'];
        const row4 = ['Tool Type', this.state.toolType];
        const row5 = ['Report Generated On', moment().format('DD MMM YYYY, h:mm a')];
        const header = ['Question', 'Question Image', 'Options', 'Correct Answer', 'Participant Answer', 'Marks', 'Online tool Type', 'Level', 'Industry', 'Competency'];
        csvData.unshift(header);
        csvData.unshift(['']);
        csvData.unshift(row5);
        csvData.unshift(row4);
        csvData.unshift(row3);
        csvData.unshift(row2);
        csvData.unshift(row1);

        this.setState({ csvData, loading: false }, () => this.csvLink && this.csvLink.link && this.csvLink.link.click());
    }

    handleFeedbackApiResponse = (responseJson: any) => {
        toast.success(this.t("projectDetail.toast.feedback"));
        let tableData = [...this.state.tableData];
        tableData = tableData.map((item: any) => {
            if (item.id === this.state.recordId) {
                let totalScore = 0;
                const scoreDetail = responseJson.data.map((item2: any, idx: number) => {
                    const score = parseFloat(item2.attributes.score);
                    totalScore += score;
                    return {
                        index: idx,
                        score: score,
                        label: item2.attributes.competancy_name,
                        value: item2.attributes.competancy_id.toString(),
                        comment: item2.attributes.comment
                    }
                });
                return { ...item, scoreDetail, score: parseFloat(totalScore.toString()).toFixed(1) };
            } else {
                return item;
            }
        });

        this.setState({ tableData, openDialogName: "", loading: false },
            () => { this.onChangeHandler(this.state.search, this.state.page) }
        );
    }

    onChange = (name: string, value: any) => {
        const scoreDetail = [...this.state.scoreDetail];
        const obj = {
            ...scoreDetail[this.state.competencyIndex],
            [name]: value
        };
        scoreDetail[this.state.competencyIndex] = obj;
        this.setState({ scoreDetail });
    }

    getDefinition = (score: number, definition: any[]) => {
        for (const element of definition) {
            if (element.min <= score && element.max >= score) {
                return element.description;
            }
        }
        return "-";
    }

    onDownloadBtnClick = (item: any) => {
        if (item.toolName === "OnlineTool") {
            this.getParticipantReport(item);
        } else {
            this.onDownloadReport(item.definition, item.scoreDetail, item.participantName, item.type);
        }
    }

    onDownloadReport = (definition: any[], scoreDetail: any, participantName: string, toolType: string) => {
        const definitions = definition.map((obj: any) => {
            return {
                min: Number(obj.attributes.min),
                max: Number(obj.attributes.max),
                description: obj.attributes.project_ratings
                    ? obj.attributes.project_ratings.description
                    : obj.attributes.description
            }
        });
        const csvData = scoreDetail.map((item: any) => {
            return [
                item.label,
                item.score,
                this.getDefinition(item.score, definitions),
                item.comment
            ];
        });

        const row1 = ['Participant Name', participantName];
        const row2 = ['Project Name', this.state.projectName];
        const row3 = ['Tool Name', 'Assessor Lead Tool'];
        const row4 = ['Tool Type', toolType];
        const row5 = ['Report Generated On', moment().format('DD MMM YYYY, h:mm a')];
        const header = ['Competency Name', 'Score', 'Definition', 'Comment'];
        csvData.unshift(header);
        csvData.unshift(['']);
        csvData.unshift(row5);
        csvData.unshift(row4);
        csvData.unshift(row3);
        csvData.unshift(row2);
        csvData.unshift(row1);

        this.setState({ csvData, participantName, loading: false }, () => this.csvLink && this.csvLink.link && this.csvLink.link.click());
    }

    onChangeHandler = (search: string, page: number) => {
        const { tableData, rowsPerPage } = this.state;

        let data = tableData.filter((item: any) => {
            if (search === "") {
                return true;
            } else if (
                item.participantName.toString().toLowerCase().includes(search.toLowerCase()) ||
                item.score?.toString().toLowerCase().includes(search.toLowerCase()) ||
                item.type.toString().toLowerCase().includes(search.toLowerCase())
            ) {
                return item;
            }
        });

        const dataLength = data.length;
        data = data.slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage
        );
        this.setState({ data, dataLength, page, search });
    }

    onEditClick = (item: any) => {
        let definition = item.definition.map((obj: any) => {
            return {
                min: Number(obj.attributes.min),
                max: Number(obj.attributes.max),
                description: obj.attributes.project_ratings
                    ? obj.attributes.project_ratings.description
                    : obj.attributes.description
            }
        });
        this.setState({
            definition,
            scoreGap: item.scoreGap,
            recordId: item.id,
            slotId: item.slotId,
            participantName: item.participantName,
            scoreDetail: item.scoreDetail,
            competencyIndex: 0,
            competency: item.scoreDetail[0].value,
            openDialogName: "SetScore"
        });
    }

    getParticipantDetail = (id: any) => {
        this.setState({ loading: true });

        const header = {
            "Content-Type": configJSON.participantDetailContentType,
            token: localStorage.getItem("accesToken")
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getParticipantDetailApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.participantDetailApiEndPoint}?id=${id}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.participantDetailApiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }

    getParticipantReport = (item: any) => {
        this.setState({ participantName: item.participantName, toolType: item.type, loading: true });

        const header = {
            "Content-Type": configJSON.participantReportContentType,
            token: localStorage.getItem("accesToken")
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getParticipantReportApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.participantReportApiEndPoint}?participant_id=${item.participantId}&online_tool_id=${item.toolId}&id=${this.state.projectId}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.participantReportApiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }

    editFeedback = () => {
        this.setState({ loading: true });

        const header = {
            "Content-Type": configJSON.editFeedbackApiContentType,
            token: localStorage.getItem("accesToken")
        };

        const arr = this.state.scoreDetail.map((item: any) => {
            return {
                competancy_id: item.value,
                score: item.score,
                comment: item.comment
            }
        });

        const httpBody = {
            "assessor_participant_slots": arr
        }

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.editFeedbackApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.editFeedbackApiEndPoint}?id=${this.state.slotId}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.editFeedbackApiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }
}
// Customizable Area End
