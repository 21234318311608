import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { runEngine } from "../../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { toast } from "react-toastify";
// Customizable Area End

export const configJSON = require("../config");

export interface Props {
  id?: string;
  navigation?: any;
  location: any;
  history: any;
  match: any;
  t: any;
}

export interface S {
  // Customizable Area Start
  loading: boolean;
  page: number;
  rowsPerPage: number;
  dataLength: number;
  client: string;
  project: string;
  search: string;
  openDialogName: string | null;
  userName: string;
  password: string;
  id: any;
  managerName: string;
  phoneNumber: string;
  email: string;
  data: any[];
  tableData: any[];
  filterData: any[];
  clientList: any[];
  projectList: any[];
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class ManageSHRMUserController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getSHRMUserListApiCallId: string = "";
  getClientListApiCallId: string = "";
  getProjectListApiCallId: string = "";
  addUserApiCallId: string = "";
  editUserApiCallId: string = "";
  deleteUserApiCallId: string = "";
  t: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      dataLength: 0,
      loading: false,
      page: 0,
      rowsPerPage: 20,
      client: "",
      search: "",
      userName: "",
      password: "",
      project: "",
      id: null,
      managerName: "",
      phoneNumber: "",
      email: "",
      data: [],
      openDialogName: "",
      clientList: [],
      tableData: [],
      projectList: [],
      filterData: [],
      // Customizable Area End
    };
    this.t = this.props.t;
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId && responseJson) {
        if (responseJson.status === 500) {
          toast.error(this.t("manageDCManager.toast.somethingWrong"));
          this.setState({ loading: false });
        } else if (responseJson.message === "co-manager not found") {
          this.setState({
            data: [],
            tableData: [],
            filterData: [],
            loading: false,
          });
        } else {
          this.handelApiResponse(apiRequestCallId, responseJson);
        }
      }
    }
  }

  async componentDidMount() {
    this.setState({ loading: true });
    window.scrollTo(0, 0);
    this.getTableDetail();
    this.getClientList();
  }

  handelApiResponse = (apiRequestCallId: string, responseJson: any) => {
    if (Array.isArray(responseJson.errors)) {
      if (responseJson.errors[0].message) {
        toast.error(responseJson.errors[0].message);
      } else if (responseJson.errors[0].token) {
        toast.error(responseJson.errors[0].token);
      } else if (responseJson.errors[0].email) {
        toast.error("Email " + responseJson.errors[0].email);
      } else {
        toast.error(this.t("manageDCManager.toast.somethingWrong"));
      }
      this.setState({ loading: false });
    } else if (apiRequestCallId === this.getSHRMUserListApiCallId) {
      this.handelTableDataApiResponse(responseJson);
    } else if (apiRequestCallId === this.getClientListApiCallId) {
      let clientList = responseJson.data.map((item: any) => {
        return {
          label: item.attributes.full_name,
          value: item.attributes.id,
        };
      });
      this.setState({ clientList, loading: false });
    } else if (apiRequestCallId === this.getProjectListApiCallId) {
      const projectList = responseJson.data.map((item: any) => {
        return {
          label: item.attributes.name,
          value: item.attributes.id,
        };
      });
      this.setState({ projectList, loading: false });
    } else if (apiRequestCallId === this.addUserApiCallId) {
      this.handelAddApiResponse(responseJson);
    } else if (apiRequestCallId === this.editUserApiCallId) {
      this.handelEditApiResponse(responseJson);
    } else if (apiRequestCallId === this.deleteUserApiCallId) {
      this.handelDeleteApiResponse();
    }
  };

  handelTableDataApiResponse = (responseJson: any) => {
    const dataNew =responseJson?.data === undefined ? [] : responseJson?.data.map((item: any) => {
      return {
        password: item.attributes.password,
        userName: item.attributes.user_name,
        email: item.attributes.email,
        managerName: item.attributes.full_name,
        phoneNumber: item.attributes.phone_number,
        id: item.id,
      };
    });

    this.setState({ tableData: dataNew, filterData: dataNew, loading: false }, () => {
      this.onChangeHandler(this.state.search, 0);
    });
  };

  handelAddApiResponse = (responseJson: any) => {
    toast.success(this.t("manageDCManager.toast.add"));
    let tableData = [...this.state.tableData];
    const obj = {
      id: responseJson.data.id,
      password: responseJson.data.attributes.password,
      userName: responseJson.data.attributes.user_name,
      phoneNumber: responseJson.data.attributes.phone_number,
      email: responseJson.data.attributes.email,
      managerName: responseJson.data.attributes.full_name,
    };
    tableData = [obj, ...tableData];
    this.setState(
      {
        filterData: tableData,
        id: null,
        tableData,
        managerName: "",
        phoneNumber: "",
        email: "",
        userName: "",
        password: "",
        openDialogName: null,
        loading: false,
      },
      () => {
        this.onChangeHandler(this.state.search, 0);
      }
    );
  };

  handelEditApiResponse = (responseJson: any) => {
    toast.success(this.t("manageDCManager.toast.edit"));
    let tableData = [...this.state.tableData];
    tableData = tableData.map((item: any) => {
      if (item.id === this.state.id) {
        return {
          id: item.id,
          managerName: responseJson.data.attributes.full_name,
          userName: responseJson.data.attributes.user_name,
          phoneNumber: responseJson.data.attributes.phone_number,
          email: responseJson.data.attributes.email,
          password: responseJson.data.attributes.password,
        };
      } else {
        return item;
      }
    });

    this.setState(
      {
        tableData,
        filterData: tableData,
        id: "",
        managerName: "",
        phoneNumber: "",
        email: "",
        userName: "",
        password: "",
        openDialogName: null,
        loading: false,
      },
      () => {
        this.onChangeHandler(this.state.search, this.state.page);
      }
    );
  };

  handelDeleteApiResponse = () => {
    toast.success(this.t("manageDCManager.toast.delete"));
    let tableDataNew = [...this.state.tableData];
    tableDataNew = tableDataNew.filter((item: any) => item.id !== this.state.id);
    this.setState(
      {
        tableData: tableDataNew,
        filterData: tableDataNew,
        openDialogName: null,
        loading: false,
      },
      () => {
        this.onChangeHandler(this.state.search, this.state.page);
      }
    );
  };

  onChangeHandler = (search: string, page: number) => {
    const { filterData, rowsPerPage } = this.state;

    let data = filterData.filter((item: any) => {
      if (search === "") {
        return true;
      } else if (
        item.managerName
          .toString()
          .toLowerCase()
          .includes(search.toLowerCase()) ||
        item.userName
          .toString()
          .toLowerCase()
          .includes(search.toLowerCase()) ||
        item.phoneNumber
          ?.toString()
          .toLowerCase()
          .includes(search.toLowerCase()) ||
        item.email
          .toString()
          .toLowerCase()
          .includes(search.toLowerCase())
      ) {
        return item;
      }
    });

    const dataLength = data.length;
    let totalPage = Math.ceil(dataLength / this.state.rowsPerPage);
    page = totalPage === page ? page - 1 : page;
    data = data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    this.setState({ data, dataLength, page, search });
  };

  onApplyButtonClick = () => {
    this.setState({ loading: true });
    this.getTableDetail();
  };

  onsortByValueChange = (client: string) => {
    console.log(client, "client");

    if (client) {
      this.getProjectList(client);
    }
    this.setState({ client: client ?? "", project: "", projectList: [] });
    if (client === undefined) {
      this.setState({ client: client ?? "", project: "", projectList: [] }, () => this.getTableDetail());
    }
  };

  shuffleArray = (array: any) => {
    for (let i = array.length - 1; i > 0; i--) {
      const val = new Uint8Array(1);
      const randomValue = crypto.getRandomValues(val)[0];
      const randomFloat = randomValue / Math.pow(2, 8);
      const j = Math.floor(randomFloat * (i + 1));
      let temp = array[i];
      array[i] = array[j];
      array[j] = temp;
    }
    return array;
  };

  generateUniquePassword = (passwordLength: number) => {
    const upperChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const lowerChars = "abcdefghijklmnopqrstuvwxyz";
    const numberChars = "0123456789";
    const specialChars = "!@#$%^&*)(:',.?/><";
    const allChars = numberChars + upperChars + lowerChars + specialChars;
    let randPasswordArray = Array(passwordLength);
    randPasswordArray[1] = upperChars;
    randPasswordArray[0] = numberChars;
    randPasswordArray[2] = lowerChars;
    randPasswordArray[3] = specialChars;
    randPasswordArray = randPasswordArray.fill(allChars, 4);
    return this.shuffleArray(
      randPasswordArray.map((x) => {
        const value = new Uint8Array(1);
        const randomValue = crypto.getRandomValues(value)[0];
        const randomFloat = randomValue / Math.pow(2, 8);
        return x[Math.floor(randomFloat * x.length)];
      })
    ).join("");
  };

  generateUsername = () => {
    return (
      this.state.managerName.substring(0, 3) +
      new Date().getDate().toString() +
      (new Date().getMonth() + 1).toString() +
      new Date().getHours().toString() +
      new Date().getMinutes().toString() +
      new Date().getMilliseconds().toString()
    );
  };

  onBlur = () => {
    if (!this.state.id && this.state.managerName) {
      const userName = this.generateUsername();
      const password = this.generateUniquePassword(8);
      this.setState({ userName, password });
    }
  };

  getTableDetail = () => {

    const header = {
      "Content-Type": configJSON.SHRMUserListContentType,
      token: localStorage.getItem("accesToken"),
    };

    let url = `${configJSON.SHRMUserListApiEndPoint}`;
    if (this.state.client && this.state.project) {
      url += `?client_id=${this.state.client}&project_id=${this.state.project}`;
    } else if (this.state.client !== "") {
      url += `?client_id=${this.state.client}`;
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSHRMUserListApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      url
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.SHRMUserListApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getClientList = () => {
    this.setState({ loading: true });

    const header = {
      "Content-Type": configJSON.clientListContentType,
      token: localStorage.getItem("accesToken"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getClientListApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.clientListApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.clientListApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getProjectList = (client: string) => {

    const header = {
      "Content-Type": configJSON.projectListContentType,
      token: localStorage.getItem("accesToken"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getProjectListApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.projectListApiEndPoint}/${client}/client_projects`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.projectListApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  addUser = () => {
    this.setState({ loading: true });

    const header = {
      "Content-Type": configJSON.addUserApiContentType,
      token: localStorage.getItem("accesToken"),
    };

    const data = {
      full_name: this.state.managerName,
      email: this.state.email,
      phone_number:
        this.state.phoneNumber.length > 4 ? this.state.phoneNumber : "",
      user_name: this.state.userName,
      password: this.state.password,
    };

    const httpBody = {
      data: {
        attributes: data,
        type: "manager",
      },
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addUserApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addUserApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.addUserApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  editUser = () => {
    this.setState({ loading: true });

    const header = {
      "Content-Type": configJSON.editUserApiContentType,
      token: localStorage.getItem("accesToken"),
    };

    const data = {
      full_name: this.state.managerName,
      email: this.state.email,
      phone_number:
        this.state.phoneNumber?.length > 4 ? this.state.phoneNumber : "",
      user_name: this.state.userName,
      password: this.state.password,
    };

    const httpBody = {
      data: {
        attributes: data,
        type: "manager",
      },
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.editUserApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.editUserApiEndPoint}/${this.state.id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.editUserApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  deleteUser = () => {
    this.setState({ loading: true });

    const header = {
      "Content-Type": configJSON.deleteUserApiContentType,
      token: localStorage.getItem("accesToken"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteUserApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deleteUserApiEndPoint}/${this.state.id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteUserApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
}

// Customizable Area End
