import { Message } from "../../../../../framework/src/Message";
import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import { runEngine } from "../../../../../framework/src/RunEngine";
import MessageEnum, {
    getName,
} from "../../../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { toast } from "react-toastify";
// Customizable Area End

export const configJSON = require("../../config");

export interface Props {
    id: string;
    navigation: any;
    t: any;
}

export interface S {
    // Customizable Area Start
    loading: boolean;
    page: number;
    rowsPerPage: number;
    dataLength: number;
    count: number | null;
    functionId: any;
    functionName: string;
    search: string;
    openDialogName: string | null;
    data: any[];
    tableData: any[];
    filterData: any[];
    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class FunctionController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    getFunctionListApiCallId: string = "";
    addFunctionApiCallId: string = "";
    editFunctionApiCallId: string = "";
    deleteFunctionApiCallId: string = "";
    t: any;
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage),
        ];
        this.receive = this.receive.bind(this);
        runEngine.attachBuildingBlock(this, this.subScribedMessages);

        this.state = {
            // Customizable Area Start
            page: 0,
            rowsPerPage: 20,
            dataLength: 0,
            count: null,
            functionId: null,
            functionName: "",
            search: "",
            openDialogName: "",
            data: [],
            tableData: [],
            filterData: [],
            loading: false,
            // Customizable Area End
        };
        this.t = this.props.t;
    }

    async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            this.checkErrorResponse(apiRequestCallId, responseJson)
        }
    }
    checkErrorResponse= (apiRequestCallId:any, responseJson:any) =>{
        if (apiRequestCallId && responseJson) {
            if (responseJson.status === 500) {
                toast.error(this.t("organizationFunction.function.toast.somethingWrong"));
                this.setState({ loading :false });
            } else if (!Array.isArray(responseJson.errors) && responseJson.errors) {
                toast.error(responseJson.errors);
                this.setState({ loading: false });
            } else {
                this.handleApiResponse(apiRequestCallId, responseJson);
            }
        }
    }

    async componentDidMount() {
        window.scrollTo(0, 0);
        this.getFunctionList();
    }

    handleApiResponse = (apiRequestCallId: string, responseJson: any) => {
        if (Array.isArray(responseJson.errors)) {
            if (responseJson.errors[0].message) {
                toast.error(responseJson.errors[0].message);
                this.setState({ loading: false });
            } else if (responseJson.errors[0].name) {
                toast.error(responseJson.errors[0].name);
            } else {
                toast.error(this.t("organizationFunction.function.toast.somethingWrong"));
            }
            this.setState({ loading: false });
        } else if (apiRequestCallId === this.getFunctionListApiCallId) {
            this.handelFunctionListApiResponse(responseJson);
        } else if (apiRequestCallId === this.addFunctionApiCallId) {
            this.handelAddFunctionApiResponse(responseJson);
        } else if (apiRequestCallId === this.editFunctionApiCallId) {
            this.handelEditFunctionApiResponse(responseJson);
        } else if (apiRequestCallId === this.deleteFunctionApiCallId) {
            this.handelDeleteFunctionApiResponse();
        }
    }

    handelFunctionListApiResponse = (responseJson: any) => {
        const data = responseJson.data.map((item: any) => {
            return {
                id: item.id,
                functionName: item.attributes.name
            }
        });
        this.setState({ tableData: data, filterData: data, loading: false },
            () => { this.onChangeHandler(this.state.search, 0) }
        );
    }

    handelAddFunctionApiResponse = (responseJson: any) => {
        toast.success(this.t("organizationFunction.function.toast.add"));
        let tableData = [...this.state.tableData];
        const obj = {
            id: responseJson.data.id,
            functionName: responseJson.data.attributes.name
        };
        tableData = [obj, ...tableData];
        this.setState({ tableData, filterData: tableData, functionId: null, functionName: "", openDialogName: null, loading: false },
            () => { this.onChangeHandler(this.state.search, 0) }
        );
    }

    handelEditFunctionApiResponse = (responseJson: any) => {
        toast.success(this.t("organizationFunction.function.toast.edit"));
        let tableData = [...this.state.tableData];
        tableData = tableData.map((item: any) => {
            if (item.id === this.state.functionId) {
                return {
                    id: responseJson.data.id,
                    functionName: responseJson.data.attributes.name
                };
            } else {
                return item;
            }
        });

        this.setState({ tableData, filterData: tableData, functionId: null, functionName: "", openDialogName: null, loading: false },
            () => { this.onChangeHandler(this.state.search, this.state.page) }
        );
    }

    handelDeleteFunctionApiResponse = () => {
        toast.success(this.t("organizationFunction.function.toast.delete"));
        let tableData = [...this.state.tableData];
        tableData = tableData.filter((item: any) => item.id !== this.state.functionId);
        this.setState({ tableData, filterData: tableData, functionId: null, functionName: "", openDialogName: null, loading: false },
            () => { this.onChangeHandler(this.state.search, this.state.page) }
        );
    }

    onChangeHandler = (search: string, page: number) => {
        const { filterData, rowsPerPage } = this.state;
        let data = filterData.filter((item: any) => {
            if (search === "") {
                return true;
            } else if (item.functionName.toString().toLowerCase().includes(search.toLowerCase())) {
                return item;
            }
        });

        const dataLength = data.length;
        let totalPage = Math.ceil(dataLength / this.state.rowsPerPage);
        page = totalPage === page ? page - 1 : page;
        data = data.slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage
        );
        this.setState({ data, dataLength, page, search });
    }

    getFunctionList = () => {
        this.setState({ loading: true });

        const header = {
            "Content-Type": configJSON.functionListContentType,
            token: localStorage.getItem("accesToken")
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getFunctionListApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.functionListApiEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.functionListApiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }

    addFunction = () => {
        this.setState({ loading: true });

        const header = {
            "Content-Type": configJSON.addFunctionApiContentType,
            token: localStorage.getItem("accesToken")
        };

        const httpBody = {
            "name": this.state.functionName,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.addFunctionApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.addFunctionApiEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.addFunctionApiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }

    editFunction = () => {
        this.setState({ loading: true });

        const header = {
            "Content-Type": configJSON.editFunctionApiContentType,
            token: localStorage.getItem("accesToken")
        };

        const httpBody = {
            "name": this.state.functionName,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.editFunctionApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.editFunctionApiEndPoint}/${this.state.functionId}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.editFunctionApiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }

    deleteFunction = () => {
        this.setState({ loading: true });
        const header = {
            "Content-Type": configJSON.deleteFunctionApiContentType,
            token: localStorage.getItem("accesToken")
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.deleteFunctionApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.deleteFunctionApiEndPoint}/${this.state.functionId}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.deleteFunctionApiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }
}
// Customizable Area End