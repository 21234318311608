
// Customizable Area Start
import { Message } from "../../../../framework/src/Message";
import { IBlock } from "../../../../framework/src/IBlock";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";

// Customizable Area Start
import { toast } from "react-toastify";
// Customizable Area End

export const configJSON = require("../config");

export interface Props {
    navigation?: any;
    id?: string;
    location: any;
    history: any;
    match: any;
    t: any;
}

interface S {
    // Customizable Area Start
    loading: boolean;
    hasMore: boolean;
    dataLength: number;
    onlineToolId: any;
    onlineToolList: any[];
    questionsData: any[];
    filterData: any[];
    // Customizable Area End
}

interface SS {
    id: any;
}

export default class AccessRepositoryController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    getTableDetailApiCallId: string = "";
    t: any;
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
        ];

        this.state = {
            loading: false,
            hasMore: true,
            dataLength: 10,
            onlineToolId: "",
            onlineToolList: [],
            questionsData: [],
            filterData: []
        };

        this.t = this.props.t;
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    // Customizable Area Start
    async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (apiRequestCallId && responseJson) {
                this.handleApiResponse(apiRequestCallId, responseJson);
            }
        }
    }

    async componentDidMount() {
        window.scrollTo(0, 0);
        this.getTableDetail();
        let state = this.props.location.state;
        if (state) {
            let onlineToolList = [{ label: "All", value: "" }, ...state.onlineToolList.filter((item:any)=> item?.label.toLowerCase() !== 'psychometric')];
            this.setState({ onlineToolList },
                () => {
                    this.onOnlineToolChange("");
                }
            );
        }
    }

    handleApiResponse = (apiRequestCallId: string, responseJson: any) => {
        if (responseJson.status === 500) {
            toast.error(this.t("accessRepository.toast.somethingWrong")); this.setState({ loading: false });
        } else if (Array.isArray(responseJson.errors)) {
            if (responseJson.errors[0].message) {
                toast.error(responseJson.errors[0].message) } else if (responseJson.errors[0].token) {
                toast.error(responseJson.errors[0].token);
} else {
                toast.error(this.t("accessRepository.toast.somethingWrong"));
}
            this.setState({ loading: false });
        } else if (responseJson.errors) {
            toast.error(responseJson.errors);
this.setState({ loading: false });
        } else if (apiRequestCallId === this.getTableDetailApiCallId) {
this.handelTableDataApiResponse(responseJson);
        }
    }

    handelTableDataApiResponse = (responseJson: any) => {
        const data = responseJson.data.map((item: any) => {
            const options = item.attributes.answers.data.map((option: any, index: number) => {
                return {
                    id: option.id,
                    answer: option.attributes.answer,
                    image_url: option.attributes.image_url?.url,
                    type: option.attributes.image_url?.type,
                    correct_answer: option.attributes.correct_answer
                }
            });

            return {
                question: item.attributes.name,
                questionImageURL: item.attributes.image_url?.url,
                type: item.attributes.image_url?.type,
                options: options,
                onlineToolId: item.attributes.online_tool_id?.toString(),
            }
        });

        this.setState({ questionsData: data, filterData: data, loading: false });
    }

    onOnlineToolChange = (value: string) => {
        let questionsData = [...this.state.questionsData];
        let filterData = questionsData;

        if (value !== "") {
            filterData = questionsData.filter((question: any) => {
                if (question.onlineToolId === value)
                    return true;
            });
        }
        this.setState({ onlineToolId: value, filterData, hasMore: true, dataLength: 10 });
    }

    addMoreData = () => {
        if (this.state.filterData.length > this.state.dataLength) {
            this.setState({
                hasMore: true,
                dataLength: this.state.dataLength + 10
            });
        } else {
            this.setState({ hasMore: false });
        }
    }

    getTableDetail = () => {
        this.setState({ loading: true });

        const header = {
            "Content-Type": configJSON.tableDetailContentType,
            token: localStorage.getItem("accesToken")
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getTableDetailApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.tableDetailApiEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.tableDetailApiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }
    // Customizable Area End
}
// Customizable Area End
