// Customizable Area Start
import React from 'react';
import {
    Box,
    Typography,
    FormControlLabel,
    Grid,
    Checkbox,
    InputBase
} from '@material-ui/core';
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { styled } from "@material-ui/styles";
import Select from "react-select";
//@ts-ignore
import InfiniteScroll from 'react-infinite-scroller';
import ParticipantReportController from "./ParticipantReportController.web";
import AppSidebar from "../../../../components/src/AppSidebar.web";
import Spinner from "../../../../components/src/Spinner.web";

class ParticipantReport extends ParticipantReportController {

    renderOption = (item: any, optionName: string) => {
        let element =
            <div style={{ backgroundColor: item.participant_answer ? "green" : "unset", width: "100%" }} >
                <img src={item.image_url} alt="not found!" style={{ height: "100px", width: "100px" }} />
            </div>
        if (item.answer && item.answer?.includes("https://")) {
            element =
                <div style={{ backgroundColor: item.participant_answer ? "green" : "unset", width: "100%" }}>
                    <img src={item.answer} alt="not found!" style={{ height: "100px", width: "100px" }} />
                </div>
        } else if (item.type?.includes("video")) {
            element = <Box width={{ sx: "100%", md: "320px" }} style={{ backgroundColor: item.participant_answer ? "green" : "unset", width: "100%" }}>
                <video width="100%" height="auto" controls style={{ maxHeight: "240px" }}>
                    <source src={item.image_url} type="video/mp4" />
                    {this.t("participantReport.label.videoNotSupport")}
                </video>
            </Box>
        } else if (item.answer) {
            element = <CustomInputBase
                readOnly
                fullWidth
                multiline
                style={{
                    color: item.participant_answer ? "white" : "black",
                    backgroundColor: item.participant_answer ? "green" : "white"
                }}
                value={item.answer ?? ""}
                placeholder={`${this.t("participantReport.placeholder.option")} ${optionName}`}
            />
        }
        return element
    }

    renderQuestionMedia = (mcq: any) => {
        let element = null;
        if (mcq.questionImageURL) {
            if (mcq.type?.includes("video")) {
                element = <Box width={{ xs: "100%", md: "320px" }} mt="15px" mb="25px">
                    <video controls style={{ maxHeight: "240px" }} width="100%" height="auto">
                        <source src={mcq.questionImageURL} type="video/mp4" />
                        {this.t("participantReport.label.videoNotSupport")}
                    </video>
                </Box>
            } else {
                element = <img src={mcq.questionImageURL} alt="not found!" style={{ marginTop: "15px", height: "100px", width: "100px" }} />
            }
        }
        return element;
    }

    RenderInfiniteScrollBox = (mcq: any) => {
        return (
            <Box>
                <Grid container spacing={3}>
                    {mcq.options?.map((item: any, index: any) => {
                        const optionName = (index + 10).toString(36).toUpperCase();
                        const element = this.renderOption(item, optionName);
                        return (
                            <Grid item xs={12} md={6} key={`${index + 1}`}>
                                <Box display="flex">
                                    {(!item.answer || item.answer?.includes("https://")) &&
                                        <FormControlLabel
                                            style={{ margin: "-11px 0px -5px -12px" }}
                                            control={
                                                <Checkbox
                                                    disabled
                                                    style={{ color: "green", display: "none" }}
                                                    name={`Option ${optionName}`}
                                                    checked={item.correct_answer}
                                                />
                                            }
                                            label=""
                                        />
                                    }
                                    <Typography style={{ marginBottom: "5px" }} variant="body2">
                                        {this.t("participantReport.label.option")} {optionName}
                                    </Typography>
                                </Box>
                                <Box>
                                    {element}
                                </Box>
                            </Grid>
                        )
                    })}
                </Grid>
            </Box>
        )
    }
    handleChange = (event: any) => {
        let questionsData = [...this.state.forfilter];
        let filterData = questionsData;
        let label = ""
        if (event === null) {
            label = this.state.Toolsafterbackup.split('/')[0]
            filterData = this.state.forfilter.filter((question: any) => {
                if (question.toolid === this.state.forfilter[0].toolid)
                    return true;
            });
        }
        else {
            label = event.label
            if (event.value !== "") {
                filterData = questionsData.filter((question: any) => {
                    if (question.toolid === event.value)
                        return true;
                });
            }
        }
        this.setState({ questionsData: filterData, toolNames: label });
    }
    render() {
        return (
            <Box>
                <Spinner spinnerModal={this.state.loading} />
                <AppSidebar type="assessor">
                    <Box margin="35px 0px" mr="30px">
                        <Box display={{ sm: "block", md: "flex" }} justifyContent="space-between" mb="40px">
                            <Box>
                                <Box mb="5px" fontFamily="ProximaNova-Bold" fontSize="44px" color="#1b3c69">{this.state.participantName}</Box>
                                <Box color="#1b3c69">{this.t("participantReport.description.main", { name: this.state.participantName, project: this.state.participantProject })}</Box>
                            </Box>
                            <Box maxWidth="300px" width="100%" color="#1b3c69">
                                <Box>
                                    <Box mb="5px">{this.t("participantReport.label.SelectTool")}</Box>
                                    <Select
                                        autofocus={true}
                                        openOnFocus={true}
                                        classNamePrefix="react-select"
                                        placeholder={this.t("participantReport.label.SelectTool")}
                                        isSearchable={false}
                                        value={
                                                this.state.toolsdropDown?.filter(
                                                    (option: any) =>
                                                        option.label === this.state.toolNames
                                                )[0]
                                        }
                                        options={this.state.toolsdropDown}
                                        onChange={(event: any) => this.handleChange(event)}
                                    />
                                </Box>
                            </Box>
                        </Box>
                        <Box mb="15px">
                            <Box>
                                <Box fontSize="22px" fontWeight="bold" fontFamily="ProximaNova-Semibold">
                                    {this.state.toolNames}
                                </Box>
                            </Box>
                        </Box>
                        {this.state.questionsData.length > 0
                            ? <InfiniteScroll
                                pageStart={0}
                                loadMore={this.addMoreData}
                                hasMore={this.state.hasMore}
                                useWindow={true}
                                loader={<Box display="flex" justifyContent="center">{this.t("participantReport.label.loading")}</Box>}
                            >
                                {this.state.questionsData
                                    .slice(0, this.state.dataLength)
                                    .map((mcq: any, index: number) => {
                                        return (
                                            <Box key={`${index + 1}`} border="1.5px solid #95A0A4" p="20px" mb="10px" borderRadius="8px" style={{ backgroundColor: "#eff6ff" }}>
                                                <Box mb="20px">
                                                    <Typography style={{ fontFamily: "ProximaNova-Semibold" }}>{this.t("participantReport.label.question")} {index + 1}</Typography>
                                                    <CustomInputBase
                                                        multiline
                                                        readOnly
                                                        fullWidth
                                                        value={mcq.question ?? ""}
                                                        placeholder={this.t("participantReport.placeholder.question")}
                                                    />
                                                    {this.renderQuestionMedia(mcq)}
                                                </Box>
                                                {this.RenderInfiniteScrollBox(mcq)}
                                            </Box>
                                        );
                                    })
                                }
                            </InfiniteScroll>
                            :
                            <Box>
                                {this.t("participantReport.label.notFound")}
                            </Box>
                        }
                    </Box>
                </AppSidebar>
            </Box>
        );
    }
}

export default withRouter(withTranslation()(ParticipantReport));

const CustomInputBase = styled(InputBase)({
    border: "1px solid #d6d6d6",
    backgroundColor: "white",
    borderRadius: "10px",
    minHeight: "50px",
    paddingLeft: "20px",
    fontSize: "14px",
    '& .MuiInputBase-input': {
        "&::placeholder": {
            fontSize: "14px"
        }
    }
});

// Customizable Area End