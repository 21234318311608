// Customizable Area Start
import React from "react";
import {
  Box,
  Button,
  InputBase,
  Grid,
  TextField,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Dialog,
  DialogContent
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import { styled } from "@material-ui/styles";
import * as Yup from "yup";
import { Formik } from "formik";
import { withTranslation } from "react-i18next";
//@ts-ignore
import { CSVLink } from 'react-csv';
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from '@material-ui/icons/Close';
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';
import ManageCompetencyController, { Props } from "./ManageCompetencyController.web";
import AppSidebar from "../../../../components/src/AppSidebar.web";
import Spinner from "../../../../components/src/Spinner.web";
const LanguageData = localStorage.getItem("language") === "ar";

const editIcon = require("../../assets/edit.png");
const deleteIcon = require("../../assets/delete.png");
const uploadIcon = require("../../assets/upload.png");
const warningIcon = require("../../assets/warning.png");
const downloadIcon = require("../../assets/download.svg");

class ManageCompetency extends ManageCompetencyController {
  constructor(props: Props) {
    super(props);
  }

  competencySchema = () => {
    return Yup.object().shape({
      name: Yup.string().required(this.t("manageCompetency.errorMessages.name")),
      description: Yup.string().required(this.t("manageCompetency.errorMessages.description"))
    });
  }

  getErrorMessage = (touched: any, errors: any, value: string) => {
    return touched[value] && errors[value] &&
      <div className="text-danger">{errors[value]}</div>
  }

  TableRender = () => {
    return (
      <Table aria-label="simple table" style={{ border: "1px solid #E0E0E0", borderRadius: "10px", borderCollapse: "separate" }} className="custom-table">
        <TableHead>
          <TableRow>
            <TableCell align={LanguageData ? "right" : "left"} style={{ fontFamily: "ProximaNova-Bold", fontSize: "16px" }}>{this.t("manageCompetency.label.competency")}</TableCell>
            <TableCell align={LanguageData ? "right" : "left"} style={{ fontFamily: "ProximaNova-Bold", fontSize: "16px" }}>{this.t("manageCompetency.label.descriptionLab")}</TableCell>
            <TableCell align={LanguageData ? "left" : "right"} style={{ fontFamily: "ProximaNova-Bold", fontSize: "16px" }}>{this.t("manageCompetency.label.action")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {this.state.data.length > 0 ?
            <>
              {this.state.data.map((item: any, index: number) => (
                <TableRow key={`${index + 1}`}>
                  <TableCell align={LanguageData ? "right" : "left"} style={{ fontFamily: "ProximaNova-Regular", fontSize: "18px", color: "#000" }}>{item.name}</TableCell>
                  <TableCell align={LanguageData ? "right" : "left"} style={{ fontFamily: "ProximaNova-Regular", fontSize: "18px", color: "#000" }}>{item.description}</TableCell>
                  <TableCell align={LanguageData ? "left" : "right"} style={{ fontFamily: "ProximaNova-Regular", fontSize: "18px", color: "#000" }}>
                    <img src={editIcon} id="edit" style={{ cursor: "pointer", marginRight: "10px" }} onClick={() => this.setState({ competencyId: item.id, name: item.name, description: item.description, openDialogName: "Edit" })} />
                    <img src={deleteIcon} id="delete" style={{ cursor: "pointer" }} onClick={() => this.setState({ competencyId: item.id, name: item.name, description: item.description, openDialogName: "Delete" })} />
                  </TableCell>
                </TableRow>
              ))}
            </>
            : <TableRow id="manageCompetency.label.notFoundNew">
              <TableCell colSpan={6} align="center" style={{ fontFamily: "ProximaNova-Regular", fontSize: "18px", color: "#000", whiteSpace: "nowrap" }}>{this.t("manageCompetency.label.notFound")}</TableCell>
            </TableRow>
          }
        </TableBody>
      </Table>
    )
  }

  renderCompetencyList = () => {
    return (
      <AppSidebar type="admin">
        <Box margin="35px 0px" mr="30px">
          <Box display={{ xs: "block", sm: "flex" }} justifyContent="space-between" mb="40px">
            <Box>
              <Box mb="5px" id="manageCompetency.main" fontFamily="ProximaNova-Bold" color="#1b3c69" fontSize={{ xs: "24px", sm: "30px", md: "44px" }}>{this.t("manageCompetency.title.main")}</Box>
              <Box color="#1b3c69" fontSize={{ xs: "14px", sm: "20px" }}>{this.t("manageCompetency.description.main")}</Box>
            </Box>
            <Box mt={{ xs: "20px", sm: "0px" }}>
              <RedButton id="dsdmain" onClick={() => this.setState({ openDialogName: "AddCompetency" })} style={{ backgroundColor: "#f26a42", fontFamily: "ProximaNova-Semibold" }}>{this.t("manageCompetency.button.addCompetency")}</RedButton>
            </Box>
          </Box>
          <Box mt="60px">
            <Box>
              <Grid container>
                <Grid item xs={12} className={LanguageData ? "SearchIconRightSide" : ""}>
                  <Box mb="25px" display="flex" alignItems="center" justifyContent="end">
                    <TextField
                      id="search"
                      InputLabelProps={{
                        style: {
                          fontFamily: "ProximaNova-Regular",
                          zIndex: 0
                        }
                      }}
                      onChange={(event: any) => {
                        this.onChangeHandler(event.target.value, 0);
                      }}
                      variant="outlined"
                      label={this.t("manageCompetency.label.search")}
                      InputProps={{
                        endAdornment: <SearchIcon />
                      }}
                    />
                  </Box>
                </Grid>
                <Grid item id="newcompantcy" xs={12} style={{ overflow: "auto" }}>
                  {this.TableRender()}
                  {this.state.data.length > 0 &&
                    <div className="custom-pagination" data-testid="custom-pagination">
                      <Pagination
                        page={this.state.page + 1}
                        count={Math.ceil(this.state.dataLength / this.state.rowsPerPage)}
                        onChange={(event: unknown, newPage: number) => {
                          this.onChangeHandler(this.state.search, newPage - 1);
                        }}
                        dir="ltr"
                        showFirstButton
                        showLastButton
                        variant="outlined"
                        shape="rounded"
                        siblingCount={0}
                        boundaryCount={1}
                      />
                    </div>
                  }
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </AppSidebar>
    );
  }

  renderAddOptionsDialog = () => {
    return (
      <Dialog
        open={this.state.openDialogName === "AddCompetency"}
        fullWidth={false}
        maxWidth={"sm"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        scroll="body"
        onClose={() => { }}
      >
        <DialogContent style={{ maxWidth: "600px", padding: "0px", width: "100%" }}>
          <Box style={{ textAlign: "right", margin: "10px 10px 0px 0px" }}>
            <CloseIcon onClick={() => this.setState({ openDialogName: null })} style={{ cursor: "pointer", fontSize: "35px", color: "#787878" }} />
          </Box>
          <Box padding={{ xs: "15px 25px 25px", sm: "15px 40px 40px" }}>
            <Box
              mb="20px"
              id="modal-title"
              fontSize={{ xs: "20px", sm: "30px" }}
              fontWeight="bolder"
              fontFamily="ProximaNova-Bold"
            >
              {this.t("manageCompetency.title.addOption")}
            </Box>
            <Typography variant="body2" id="modal-description">
              {this.t("manageCompetency.label.singleOption")}<span id="manageCompetency.label.singleOption" style={{ color: "red" }}>*</span>
            </Typography>
            <Box mt="5px" id="manageCompetency.button.bulkCompetency" display={{ xs: "block", sm: "flex" }}>
              <CustomButton onClick={() => this.setState({ openDialogName: "Upload" })}>{this.t("manageCompetency.button.bulkCompetency")}</CustomButton>
              <RedButton style={{ marginRight: LanguageData ? "15px" : "unset" }} onClick={() => this.setState({ openDialogName: "Add" })}>{this.t("manageCompetency.button.singleCompetency")}</RedButton>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    );
  }

  renderAddEditCompetencyDialog = () => {
    return (
      <Dialog
        open={["Add", "Edit"].includes(this.state.openDialogName ?? "")}
        fullWidth={false}
        maxWidth={"sm"}
        transitionDuration={0}
        scroll="body"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        onClose={() => { }}
      >
        <DialogContent style={{ padding: "0px", width: "100%" }}>
          <Box style={{ textAlign: "right", margin: "10px 10px 0px 0px" }}>
            <CloseIcon onClick={() => this.setState({ competencyId: null, name: "", description: "", openDialogName: null })} style={{ cursor: "pointer", fontSize: "35px", color: "#787878" }} />
          </Box>
          <Box padding={{ xs: "15px 25px 25px", sm: "15px 40px 40px" }}>
            <Box
              id="modal-title"
              mb="20px"
              fontFamily="ProximaNova-Bold"
              fontWeight="bolder"
              fontSize={{ xs: "20px", sm: "30px" }}
              color="#1b3c69"
              style={{ textAlign: LanguageData ? "right" : "unset" }}
            >
              {this.t(`manageCompetency.title.${this.state.openDialogName}`)}
            </Box>
            <Formik
              initialValues={{
                name: this.state.name,
                description: this.state.description
              }}
              validationSchema={this.competencySchema}
              onSubmit={(values) => {
                this.state.openDialogName === "Add"
                  ? this.addCompetency()
                  : this.editCompetency()
              }}
            >
              {({ errors, touched, setFieldValue, handleSubmit }) => (
                <form
                  onSubmit={handleSubmit}
                  noValidate
                  autoComplete="off"
                >
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <div style={{ marginBottom: "5px", fontSize: "18px", textAlign: LanguageData ? "right" : "unset" }}>
                        {this.t("manageCompetency.label.name")}<span style={{ color: "red" }}>*</span>
                      </div>
                      <CustomInputBase
                        fullWidth
                        placeholder={this.t("manageCompetency.placeholder.name")}
                        value={this.state.name}
                        onChange={(event: any) => {
                          setFieldValue("name", event.target.value);
                          this.setState({ name: event.target.value });
                        }}
                      />
                      {this.getErrorMessage(touched, errors, "name")}
                    </Grid>
                    <Grid item xs={12}>
                      <div style={{ marginBottom: "5px", fontSize: "18px", textAlign: LanguageData ? "right" : "unset" }}>
                        {this.t("manageCompetency.label.description")}<span style={{ color: "red" }}>*</span>
                      </div>
                      <CustomInputBase
                        fullWidth
                        placeholder={this.t("manageCompetency.placeholder.description")}
                        value={this.state.description}
                        onChange={(event: any) => {
                          setFieldValue("description", event.target.value);
                          this.setState({ description: event.target.value });
                        }}
                      />
                      {this.getErrorMessage(touched, errors, "description")}
                    </Grid>
                  </Grid>
                  <Box mt="30px" display="flex">
                    <CancelButton className="cancelBtn" onClick={() => this.setState({ competencyId: null, name: "", description: "", openDialogName: null })}>{this.t("manageCompetency.button.cancel")}</CancelButton>
                    <SubmitButton padding={{ xs: "7px 28px", sm: "9px 33px" }} type="submit">{this.t(`manageCompetency.button.${this.state.openDialogName}`)}</SubmitButton>
                  </Box>
                </form>
              )}
            </Formik>
          </Box>
        </DialogContent>
      </Dialog>
    );
  }

  renderUploadCSVDialog = () => {
    return (
      <Dialog
        open={this.state.openDialogName === "Upload"}
        fullWidth={false}
        scroll="body"
        onClose={() => { }}
        maxWidth={"sm"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogContent style={{ padding: "0px" }}>
          <Box style={{ textAlign: "right", margin: "10px 10px 0px 0px" }}>
            <CloseIcon onClick={() => this.setState({ openDialogName: null, uploadSubmitBtn: false, competencyFile: null }, () => { this.state.isReuploadClicked && this.getCompetencyList() })} style={{ fontSize: "35px", color: "#787878", cursor: "pointer" }} />
          </Box>
          <Box padding={{ xs: "15px 25px 25px", sm: "15px 40px 40px" }}>
            <Box display="flex" justifyContent="space-between" mb="15px">
              <Box
                mt="5px"
                id="modal-title"
                fontWeight="bolder"
                fontFamily="ProximaNova-Bold"
                fontSize={{ xs: "20px", sm: "30px" }}
              >
                {this.t("manageCompetency.title.upload")}
              </Box>
              <Box mb={{ xs: "10px", sm: "0px" }}>
                <CSVLink
                  target="_blank"
                  data={SampleCompetencyFileData}
                  filename={'Upload Competencies.csv'}
                  style={{ display: "flex", color: "#f26a42", borderRadius: "8px", padding: "10px", border: "1.5px solid #f26a42" }}
                >
                  <img src={downloadIcon} style={{ marginRight: "10px" }} />
                  <span style={{ marginTop: "1px" }}>{this.t("manageCompetency.button.sampleCsv")}</span>
                </CSVLink>
              </Box>
            </Box>
            <Box display="flex" justifyContent="space-between" alignItems={{ xs: "start", sm: "center" }} flexDirection={{ xs: "column-reverse", sm: "row" }}>
              <Typography variant="body2" id="modal-description">
                {this.t("manageCompetency.label.bulkUpload")}<span style={{ color: "red" }}>*</span>
              </Typography>
            </Box>
            <Box mb="25px" mt="10px">
              <form onDragEnter={this.handleDrag} onSubmit={(e: any) => e.preventDefault()} style={{ position: "relative" }}>
                <input
                  id="competencyFile"
                  type="file"
                  accept=".csv"
                  style={{ display: "none" }}
                  onChange={(event: any) => {
                    if (event.target.files[0]) {
                      this.setState({
                        competencyFile: event.target.files[0]
                      });
                    }
                  }}
                />
                <label htmlFor="competencyFile" style={{ width: "100%" }}>
                  <Box p={{ xs: "25px", sm: "25px 105px" }} textAlign="center" width="100%" border="1px dashed #bebebe" borderRadius="8px" style={{ cursor: "pointer" }}>
                    <img src={uploadIcon} />
                    <Box mt="15px">{this.t("manageCompetency.label.dropFile")}</Box>
                  </Box>
                </label>
                {this.state.dragActive && <div id="drag-questions-file-element" onDragEnter={this.handleDrag} onDragLeave={this.handleDrag} onDragOver={this.handleDrag} onDrop={this.handleDrop}></div>}
              </form>
              {this.state.competencyFile &&
                <Box mt="3px" display="flex" alignItems="center">
                  <Box>{this.state.competencyFile.name}</Box>
                  <HighlightOffOutlinedIcon
                    titleAccess="Delete"
                    onClick={() => this.setState({ competencyFile: null })}
                    style={{ cursor: "pointer", marginLeft: "4px", height: "14px", width: "14px", color: "red" }}
                  />
                </Box>
              }
              {(this.state.uploadSubmitBtn && !this.state.competencyFile) && <div className="text-danger">{this.t("manageCompetency.errorMessages.addFile")}</div>}
            </Box>
            <Box mt="5px" display={{ xs: "block", sm: "flex" }}>
              <CancelButton className="cancelBtn" onClick={() => this.setState({ openDialogName: null, uploadSubmitBtn: false, competencyFile: null }, () => { this.state.isReuploadClicked && this.getCompetencyList() })}>{this.t("manageCompetency.button.cancel")}</CancelButton>
              <SubmitButton padding={{ xs: "9px 15px", sm: "9px 20px" }} onClick={this.addBulkCompetency}>{this.t("manageCompetency.button.upload")}</SubmitButton>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    );
  }

  renderCSVErrorDialog = () => {
    return (
      <Dialog
        maxWidth={"sm"}
        open={this.state.openDialogName === "CSVError"}
        fullWidth={false}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        scroll="body"
        onClose={() => { }}
      >
        <DialogContent style={{ padding: "0px" }}>
          <Box style={{ textAlign: "right", margin: "10px 10px 0px 0px" }}>
            <CloseIcon onClick={() => this.setState({ openDialogName: null, competencyFile: null }, () => { this.getCompetencyList() })} style={{ fontSize: "35px", color: "#787878", cursor: "pointer" }} />
          </Box>
          <Box display="flex" alignItems="center" flexDirection="column" padding={{ xs: "15px 25px 25px", sm: "15px 70px 40px" }}>
            <img src={warningIcon} alt="warning.png" />
            <Box
              id="modal-title"
              mt="15px"
              mb="20px"
              style={{ color: "#f94b4b" }}
              fontWeight="bolder"
              fontSize={{ xs: "20px", sm: "30px" }}
              fontFamily="ProximaNova-Bold"
            >
              {this.t("manageCompetency.title.error")}
            </Box>
            <Typography variant="body2" id="modal-description" style={{ fontFamily: "ProximaNova-Light", textAlign: "center", fontSize: "18px" }}>
              {this.t("manageCompetency.label.uploadError", { success: this.state.successRecord, total: this.state.totalRecord })}
            </Typography>
            <Box display="flex" justifyContent="center">
              <CustomButton
                startIcon={<img src={downloadIcon} />}
                onClick={this.onCSVFileDownload}
                style={{ border: "none", marginBottom: "20px" }}
              >
                {this.t("manageCompetency.button.csvFile")}
              </CustomButton>
            </Box>
            <Box display="flex">
              <CancelButton style={{ padding: "7px 21px" }} onClick={() => { this.setState({ openDialogName: null, competencyFile: null }, () => { this.getCompetencyList() }) }}>{this.t("manageCompetency.button.cancel")}</CancelButton>
              <SubmitButton padding={{ xs: "9px 12px", sm: "9px 12px" }} onClick={() => { this.setState({ openDialogName: "Upload", uploadSubmitBtn: false, competencyFile: null, isReuploadClicked: true }) }}>{this.t("manageCompetency.button.reupload")}</SubmitButton>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    );
  }

  renderDeleteCompetencyDialog = () => {
    return (
      <Dialog
        open={this.state.openDialogName === "Delete"}
        fullWidth={false}
        scroll="body"
        maxWidth={"sm"}
        onClose={() => { }}
        transitionDuration={0}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogContent style={{ padding: "0px", maxWidth: "500px" }}>
          <Box style={{ paddingTop: "0px !important", textAlign: "right", margin: "10px 10px 0px 0px" }}>
            <CloseIcon onClick={() => this.setState({ competencyId: null, name: "", description: "", openDialogName: null })} style={{ cursor: "pointer", fontSize: "35px", color: "#787878" }} />
          </Box>
          <Box display="flex" alignItems="center" flexDirection="column" padding={{ xs: "15px 25px 25px", sm: "15px 70px 40px" }}>
            <Box
              id="modal-title"
              fontFamily="ProximaNova-Bold"
              fontWeight="bolder"
              fontSize={{ xs: "20px", sm: "30px" }}
              mb="20px"
            >
              {this.t("manageCompetency.title.delete")}
            </Box>
            <Box textAlign="center" fontSize="18px">
              {this.t("manageCompetency.label.delete", { name: this.state.name })}
            </Box>
            <Box mt="30px" display="flex">
              <CancelButton className="cancelBtn" onClick={() => this.setState({ competencyId: null, name: "", description: "", openDialogName: null })}> {this.t("manageCompetency.button.cancel")}</CancelButton>
              <SubmitButton padding={{ xs: "7px 23px", sm: "9px 23px" }} onClick={this.deleteCompetency}> {this.t("manageCompetency.button.delete")}</SubmitButton>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    );
  }


  render() {
    return (
      <ManageCompetencyStyle style={{ fontFamily: "ProximaNova-Regular" }}>
        {this.state.loading && <Spinner spinnerModal={this.state.loading} />}
        {this.renderCompetencyList()}
        {this.renderAddOptionsDialog()}
        {this.renderAddEditCompetencyDialog()}
        {this.renderCSVErrorDialog()}
        {this.renderUploadCSVDialog()}
        {this.renderDeleteCompetencyDialog()}
      </ManageCompetencyStyle>
    );
  }
}

export default withTranslation()(ManageCompetency);

const CustomInputBase = styled(InputBase)({
  borderRadius: "10px",
  minHeight: "45px",
  border: "1px solid #787878",
  paddingLeft: "15px",
  fontSize: "16px",
  '& .MuiInputBase-inputMultiline': {
    "&::placeholder": {
      fontSize: "17px",
    }
  }
});

const ManageCompetencyStyle = styled('div')({
  '& .SearchIconRightSide': {
    '& .MuiInputBase-root': {
      display: "flex",
      flexDirection: "row-reverse"
    }
  }
})

const CustomButton = styled(Button)({
  border: "1px solid #f26a42",
  backgroundColor: "white",
  color: "#f26a42",
  fontFamily: "ProximaNova-Semibold",
  borderRadius: "8px",
  marginRight: "15px",
  fontSize: "18px",
  padding: "7px 17px",
  textTransform: "none",
  "&:hover": {
    color: "#f26a42",
    backgroundColor: "white",
  },
  "@media(max-width:600px)": {
    width: "100%",
  },
  "@media(max-width:992px)": {
    padding: "7px 15px",
  }
});

const RedButton = styled(Button)({
  fontFamily: "ProximaNova-Semibold",
  backgroundColor: "#f94b4b",
  color: "white",
  padding: "9px 30px",
  borderRadius: "10px",
  textTransform: "none",
  fontSize: "18px",
  "&:hover": {
    backgroundColor: "#f94b4b",
    color: "white",
  },
  "@media(max-width:600px)": {
    marginTop: "27px",
    width: "100%",
  }
});

const SubmitButton = (props: any) => {
  const Comp = styled(Button)({
    backgroundColor: "#f94b4b",
    color: "white",
    fontFamily: "ProximaNova-Semibold",
    borderRadius: "10px",
    textTransform: "none",
    fontSize: "18px",
    marginRight: LanguageData ? "15px" : "unset",
    padding: props.padding.sm,
    "&:hover": {
      backgroundColor: "#f94b4b",
      color: "white",
    },
    "@media(max-width:600px)": {
      padding: props.padding.xs,
    }
  });
  return <Comp onClick={props.onClick} type={props.type}>{props.children}</Comp>;
};

const CancelButton = styled(Button)({
  backgroundColor: "white",
  padding: "7px 21px",
  color: "#f26a42",
  fontSize: "18px",
  border: "1px solid #f26a42",
  borderRadius: "8px",
  fontFamily: "ProximaNova-Semibold",
  marginRight: "15px",
  textTransform: "none",
  "&:hover": {
    color: "#f26a42",
    backgroundColor: "white",
  },
  "@media(max-width:600px)": {
    padding: "7px 15px",
  }
});

const SampleCompetencyFileData = [
  ["name", "description"],
  ["Competency1", "This is description"],
  ["Competency2", "This is description"]
];
// Customizable Area End