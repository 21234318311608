// Customizable Area Start
import React from "react";

function OnlineToolsSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="20"
      height="20"
      viewBox="0 0 20 20"
    >
      <defs>
        <path id="path-1" d="M0 0H20V20H0z"></path>
      </defs>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(-35 -466)">
          <g transform="translate(35 466)">
            <mask id="mask-2" fill="#fff">
              <use xlinkHref="#path-1"></use>
            </mask>
            <path
              fill="#FFF"
              d="M18.437 13.437c.69 0 1.25-.56 1.25-1.25v-1.562a1.564 1.564 0 00-1.562-1.562h-.937V1.875A1.564 1.564 0 0015.625.312H1.875c-.863.002-1.561.7-1.563 1.563v10c.002.863.7 1.562 1.563 1.562h4.687v1.876H6.25c-.69 0-1.25.56-1.25 1.25v.937c0 .173.14.313.313.313h6.875c.172 0 .312-.14.312-.313v-.938c0-.69-.56-1.25-1.25-1.25h-.313v-1.875h4.688a1.564 1.564 0 001.563-1.562V9.688h.937c.518 0 .937.42.937.937v1.563c0 .345-.28.624-.625.624-.69 0-1.25.56-1.25 1.25v.026a2.187 2.187 0 00-1.875 2.162v1.25a2.187 2.187 0 004.376 0v-1.25a2.187 2.187 0 00-1.875-2.162v-.026c0-.345.28-.625.624-.625zm-6.562 3.126v.625h-6.25v-.625c0-.346.28-.625.625-.625h5c.345 0 .625.28.625.624zm-1.563-1.25H7.188v-1.876h3.125v1.876zm6.25-3.438c0 .518-.42.938-.937.938H1.875a.938.938 0 01-.938-.938v-10c0-.518.42-.938.938-.938h13.75c.518 0 .938.42.938.938v7.188h-.625V1.875a.312.312 0 00-.313-.312H1.875a.312.312 0 00-.313.312v8.75c0 .173.14.313.313.313h13.75c.173 0 .313-.14.313-.313v-.938h.624v2.188zM11.25 3.438H8.438c-.36 0-.703.158-.938.431a1.242 1.242 0 00-.937-.432H3.75a.312.312 0 00-.312.313v5c0 .173.14.313.312.313h2.813c.36-.002.702-.16.937-.432.235.273.577.43.938.432h2.812c.173 0 .313-.14.313-.313v-.937h.312c.345 0 .625.28.625.625 0 .69.56 1.25 1.25 1.25h1.563v.624H2.187V2.188h13.126v6.875H13.75a.625.625 0 01-.625-.625c0-.69-.56-1.25-1.25-1.25h-.312V3.75a.312.312 0 00-.313-.312zm-3.438 1.25c0-.346.28-.625.625-.625h2.5v4.374h-2.5a.625.625 0 01-.624-.625V4.687zm-.625 3.124c0 .346-.28.625-.625.625h-2.5V4.063h2.5c.346 0 .625.28.625.624v3.125zm10 6.907v1.219H15.97a1.567 1.567 0 011.219-1.22zm.313 4.344a1.564 1.564 0 01-1.563-1.563v-.938h3.126v.938a1.564 1.564 0 01-1.563 1.563zm1.531-3.125h-1.218v-1.22a1.567 1.567 0 011.218 1.22z"
              mask="url(#mask-2)"
            ></path>
            <path
              fill="#FFF"
              fillRule="nonzero"
              d="M4.6875 4.6875L6.5625 4.6875 6.5625 5.3125 4.6875 5.3125z"
              mask="url(#mask-2)"
            ></path>
            <path
              fill="#FFF"
              fillRule="nonzero"
              d="M4.6875 5.9375L6.5625 5.9375 6.5625 6.5625 4.6875 6.5625z"
              mask="url(#mask-2)"
            ></path>
            <path
              fill="#FFF"
              fillRule="nonzero"
              d="M4.6875 7.1875L6.5625 7.1875 6.5625 7.8125 4.6875 7.8125z"
              mask="url(#mask-2)"
            ></path>
            <path
              fill="#FFF"
              fillRule="nonzero"
              d="M8.4375 4.6875L10.3125 4.6875 10.3125 5.3125 8.4375 5.3125z"
              mask="url(#mask-2)"
            ></path>
            <path
              fill="#FFF"
              fillRule="nonzero"
              d="M8.4375 5.9375L10.3125 5.9375 10.3125 6.5625 8.4375 6.5625z"
              mask="url(#mask-2)"
            ></path>
            <path
              fill="#FFF"
              fillRule="nonzero"
              d="M8.4375 7.1875L10.3125 7.1875 10.3125 7.8125 8.4375 7.8125z"
              mask="url(#mask-2)"
            ></path>
            <path
              fill="#FFF"
              fillRule="nonzero"
              d="M15.3125 11.5625L15.9375 11.5625 15.9375 12.1875 15.3125 12.1875z"
              mask="url(#mask-2)"
            ></path>
            <path
              fill="#FFF"
              fillRule="nonzero"
              d="M14.0625 11.5625L14.6875 11.5625 14.6875 12.1875 14.0625 12.1875z"
              mask="url(#mask-2)"
            ></path>
            <path
              fill="#FFF"
              fillRule="nonzero"
              d="M12.8125 11.5625L13.4375 11.5625 13.4375 12.1875 12.8125 12.1875z"
              mask="url(#mask-2)"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default OnlineToolsSvg;

// Customizable Area End