// Customizable Area Start
import React from "react";
import {
  Box,
  Button,
  Grid,
  TextField,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Tooltip,
  Dialog,
  DialogContent,
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import { styled } from "@material-ui/styles";
import CloseIcon from "@material-ui/icons/Close";
//@ts-ignore
import Select from "react-select";
import "./Projects.web.css";

import Spinner from "../../../components/src/Spinner.web";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import AppSidebar from "../../../components/src/AppSidebar.web";
const LanguageData = localStorage.getItem("language") === "ar";
import ProjectsController from "./ProjectsController.web";
import { editIcon, deleteIcon, copyIcon } from "./assets";
import { Link, withRouter } from "react-router-dom";
import Search from "@material-ui/icons/Search";
import { withTranslation } from "react-i18next";
import moment from "moment";
import { toast } from "react-toastify";
class CreateProject extends ProjectsController {
  async componentDidMount(): Promise<void> {
    const id = localStorage.getItem("deleteId");
    localStorage.removeItem("deleteId");
    if (id) {
      this.DeleteProject(id);
    } else {
      this.getTable();
    }
  }
  sortByList = [
    { label: "Status", value: "status" },
    { label: "Project", value: "name" },
  ];

  ProjectTableBodyRender = () => {
    return (
      <TableBody>
        {this.state.data?.length > 0 ? (
          <>
            {this.state.data?.map((proj: any) => {
              let element = (
                <CompletedStatus>
                  {proj.attributes.status}
                </CompletedStatus>
              );
              if (proj.attributes.status === "On Going") {
                element = (
                  <OnGoingStatus>
                    {proj.attributes.status}
                  </OnGoingStatus>
                );
              } else if (
                proj.attributes.status === "Yet to be started"
              ) {
                element = (
                  <NotStartedStatus>
                    {proj.attributes.status}
                  </NotStartedStatus>
                );
              }

              let toolsName = [
                ...proj.attributes?.assessor_tools,
                ...proj.attributes?.online_tools,
              ];

              return (
                <TableRow key={proj.id}>
                  <TableCell>
                    <div className="projetName">
                      <span>{proj.attributes.name}</span>
                      <CustomTooltip
                        arrow
                        placement="right"
                        title={
                          <Box>
                            <Box mt="10px" fontSize="18px">
                              {this.t(
                                "MyProjects.AssessmentDetails"
                              )}
                            </Box>
                            <Box m="10px 0px">
                              {this.t("MyProjects.CompletedBy")}
                              :
                              {moment(proj.attributes.end_date).format(
                                "DD-MM-YYYY"
                              )}{" "}
                              |{" "}
                              {moment(proj.attributes.end_date).format(
                                "hh:mm A"
                              )}
                            </Box>
                            <Box
                              style={{
                                border: "0.1px solid #cacaca",
                              }}
                            />
                            <Box m="10px 0px">
                              {this.t("MyProjects.ToolsUsed")}:{" "}
                              {toolsName
                                ?.map((item: any) => item.name)
                                .join(",")}
                            </Box>
                            <Box
                              style={{
                                border: "0.1px solid #cacaca",
                              }}
                            />
                            <Box m="10px 0px">
                              {this.t("MyProjects.EvaluationType")}:{" "}
                              {proj.attributes.project_type}
                            </Box>
                          </Box>
                        }
                      >
                        <InfoOutlinedIcon
                          style={{
                            width: "18px",
                            height: "18px",
                            cursor: "pointer",
                            marginLeft: "4px",
                            color: "#333333",
                          }}
                        />
                      </CustomTooltip>
                    </div>
                  </TableCell>
                  <TableCell>{element}</TableCell>
                  <TableCell style={{ textTransform: "capitalize" }}>
                    {proj.attributes.project_type}
                  </TableCell>
                  <TableCell>
                  <Tooltip title={proj.attributes.manager.full_name} placement="top">
                  <span style={{display: "block", whiteSpace: "nowrap", overflow: "hidden",textOverflow: "ellipsis", maxWidth: "140px"}}>
                    {proj.attributes.manager.full_name}
                    </span>
                  </Tooltip>
                  </TableCell>
                  <TableCell>
                    {moment(proj.attributes.start_date).format(
                      "DD-MM-YYYY"
                    )}
                  </TableCell>
                  <TableCell>
                    {moment(proj.attributes.end_date).format(
                      "DD-MM-YYYY"
                    )}
                  </TableCell>
                  <TableCell>
                    <div className="ActionName">
                      <Link
                        to={{
                          pathname: `/EditProject/${proj.id}`,
                          state: proj,
                        }}
                      >
                        <img src={editIcon} alt="Edit icon" />
                      </Link>
                      <div
                        onClick={() => {
                          this.handleCloneClick(proj);
                          this.setState({
                            clonnedFrom_id: proj.id,
                            CloneProjectIten: proj,
                          });
                        }}
                      >
                        <img
                          src={copyIcon}
                          id="clone"
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                      <div
                        onClick={() =>
                          this.setState({
                            ...this.state,
                            deleteModelText: proj.attributes,
                            deleteModel: true,
                            DeteleId: proj.id,
                          })
                        }
                        style={{ cursor: "Pointer" }}
                      >
                        <img src={deleteIcon} alt="Edit icon" />
                      </div>
                    </div>
                  </TableCell>
                </TableRow>
              );
            })}
          </>
        ) : (
          <TableRow>
            <TableCell
              colSpan={6}
              align="center"
              style={{
                fontFamily: "ProximaNova-Regular",
                fontSize: "18px",
                color: "#000",
                whiteSpace: "nowrap",
              }}
            >
              {this.t("MyProjects.NoRecord'sfound!")}
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    )
  }

  handleClick = () => {
    let { tableData, filterBy, option } = this.state;
    let filterData = tableData;
    if(filterBy !== ""){
      if(option === ""){
        let data = filterBy === "name" ? "project" : filterBy
        toast.warning(this.t("MyProjects.ToastMessage.SelectOption", {data: data.charAt(0).toUpperCase() + data.slice(1) }))
      }
    }
    if (filterBy && option && option !== "") {
      filterData = tableData.filter(
        (project: any) => project.attributes[filterBy] === option
      );
      this.setState({ filterData }, () => {
        this.onSearch(this.state.search, 0);
      });
    }
  };

  render() {
    const statusList = Array.from(
      new Set(
        this.state.tableData?.map((project: any) => {
          return project.attributes.status;
        })
      )
    ).map((status: any) => {
      return {
        label: status,
        value: status,
      };
    });
    const projectList = Array.from(
      new Set(
        this.state.tableData?.map((project: any) => {
          return project.attributes.name;
        })
      )
    ).map((name: any) => {
      return {
        label: name,
        value: name,
      };
    });

    let optionList =
      this.state.filterBy === "status" ? statusList : projectList;
    optionList = this.state.filterBy ? optionList : [];
    return (
      <StyleOfThisPage style={{ fontFamily: "ProximaNova-Regular" }}>
        {this.state.loading && <Spinner spinnerModal={this.state.loading} />}
        <AppSidebar type="admin">
          <Box margin="35px 0px" mr="30px">
            <Box
              mb="40px"
              display={{ xs: "block", md: "flex" }}
              justifyContent="space-between"
            >
              <Box>
                <Box
                  mb="5px" fontFamily="ProximaNova-Bold" fontSize={{ xs: "24px", sm: "30px", md: "44px" }} color="#1b3c69"
                >
                  {this.t("MyProjects.title.title")}
                </Box>
                <Box
                  color="#1b3c69"
                  fontSize={{ xs: "14px", sm: "20px" }}>
                  {this.t("MyProjects.title.SubTitile")}
                </Box>
              </Box>
              <Box mt={{ xs: "20px" }}>
                <Link to="/newproject">
                  <RedButton style={{ backgroundColor: "#f94b4b" }}>
                    {this.t("MyProjects.button.CreateNewProject")}
                  </RedButton>
                </Link>
              </Box>
            </Box>
            <Box style={{ borderRadius: "10px", backgroundColor: "#eff6ff" }}>
              <Grid
                spacing={2}
                style={{ alignItems: "end", padding: "20px 15px 10px 15px" }}
                container
              >
                <Grid item xs={12} sm={6} md={5}>
                  <Box style={{ color: "#1b3c69" }}>
                    <div style={{ marginBottom: "5px", fontSize: "18px" }}>
                      {this.t("MyProjects.FilterBy.FilterByLabel")}
                    </div>
                    <Select
                      classNamePrefix="react-select"
                      placeholder={this.t("MyProjects.FilterBy.FilterByLabel")}
                      autofocus={true}
                      isSearchable={false}
                      isClearable={true}
                      openOnFocus={true}
                      options={this.sortByList}
                      value={
                        this.sortByList.filter(
                          (option: any) => option.value === this.state.filterBy
                        )[0] ?? []
                      }
                      onChange={this.handleFilterBy}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={5}>
                  <Box style={{ color: "#1b3c69" }}>
                    <div style={{ marginBottom: "5px", fontSize: "18px" }}>
                      {this.t("MyProjects.FilterBy.SelectOption")}
                    </div>
                    <Select
                      classNamePrefix="react-select"
                      placeholder={this.t("MyProjects.FilterBy.SelectOption")}
                      isSearchable={true}
                      isClearable={true}
                      options={optionList}
                      value={
                        optionList.filter(
                          (option: any) => option.value === this.state.option
                        )[0] ?? []
                      }
                      onChange={this.handleOption}
                      data-testid="selectOption"
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                  <Box>
                    <RedButton data-testid="applyBtn" onClick={this.handleClick}>
                      {this.t("MyProjects.button.Apply")}
                    </RedButton>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box mt="60px">
              <Box>
                <Grid container>
                  <Grid item xs={12} className={LanguageData ? "SearchIcon" : ""}>
                    <div
                      className="TextfieldSearch"
                      style={{
                        marginBottom: "10px",
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <TextField
                        id="outlined-basic"
                        onChange={(e: any) => {
                          this.onSearch(e.target.value, 0);
                        }}
                        value={this.state.search}
                        label={this.t("MyProjects.Search")}
                        variant="outlined"
                        InputProps={{
                          endAdornment: <Search />,
                        }}
                        InputLabelProps={{
                          style: {
                            fontFamily: "ProximaNova-Regular",
                            zIndex: 0,
                          },
                        }}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} style={{ overflow: "auto" }}>
                    <Table
                      aria-label="simple table"
                      style={{
                        border: "1px solid #E0E0E0",
                        borderRadius: "10px",
                        borderCollapse: "separate",
                      }}
                      className="custom-table"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell
                            id="TableLabels"
                            style={{
                              fontFamily: "ProximaNova-Bold",
                              fontSize: "16px",
                            }}
                          >
                            {this.t("MyProjects.TableLabels.PROJECT")}
                          </TableCell>
                          <TableCell
                            id="STATUS"
                            style={{
                              fontSize: "16px",
                              fontFamily: "ProximaNova-Bold",
                            }}
                          >
                            {this.t("MyProjects.TableLabels.STATUS")}
                          </TableCell>
                          <TableCell
                            id="TYPE"
                            style={{
                              fontSize: "16px",
                              fontFamily: "ProximaNova-Bold",
                            }}
                          >
                            {this.t("MyProjects.TableLabels.TYPE")}
                          </TableCell>
                          <TableCell
                            id="DCMANAGER"
                            style={{
                              fontFamily: "ProximaNova-Bold",
                              fontSize: "16px",
                            }}
                          >
                            {this.t("MyProjects.TableLabels.DCMANAGER")}
                          </TableCell>
                          <TableCell
                            id="STARTDATE"
                            style={{
                              fontFamily: "ProximaNova-Bold",
                              fontSize: "16px",
                            }}
                          >
                            {this.t("MyProjects.TableLabels.STARTDATE")}
                          </TableCell>
                          <TableCell
                            id="ENDDATE"
                            style={{
                              fontSize: "16px",
                              fontFamily: "ProximaNova-Bold",
                            }}
                          >
                            {this.t("MyProjects.TableLabels.ENDDATE")}
                          </TableCell>
                          <TableCell
                            id="ACTION"
                            align="center"
                            style={{
                              fontFamily: "ProximaNova-Bold",
                              fontSize: "16px",
                            }}
                          >
                            {this.t("MyProjects.TableLabels.ACTION")}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      {this.ProjectTableBodyRender()}
                    </Table>
                    {this.state.data.length > 0 && (
                      <div
                        className="custom-pagination"
                        data-testid="Pagination"
                      >
                        <Pagination
                          count={Math.ceil(
                            this.state.dataLength / this.state.rowsPerPage
                          )}
                          onChange={(event: unknown, newPage: number) => {
                            this.onSearch(this.state.search, newPage - 1);
                          }}
                          dir="ltr"
                          variant="outlined"
                          shape="rounded"
                          showFirstButton
                          showLastButton
                          siblingCount={0}
                          boundaryCount={1}
                        />
                      </div>
                    )}
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>
          {this.state.deleteModel && (
            <Dialog
              open={this.state.deleteModel}
              maxWidth={"sm"}
              fullWidth={false}
              onClose={() => this.setState({ deleteModel: false })}
              scroll="body"
              aria-labelledby="scroll-dialog-title"
              aria-describedby="scroll-dialog-description"
            >
              <DialogContent style={{ padding: "0px", maxWidth: "500px" }}>
                <Box
                  style={{
                    textAlign: "right",
                    margin: "10px 10px 0px 0px",
                    paddingTop: "0px !important",
                  }}
                >
                  <CloseIcon
                    style={{
                      color: "#787878",
                      cursor: "pointer",
                      fontSize: "35px",
                    }}
                    onClick={() => this.setState({ deleteModel: false })}
                  />
                </Box>
                <Box
                  display="flex"
                  padding={{
                    xs: "15px 25px 25px",
                    sm: "15px 70px 40px"
                  }}
                  flexDirection="column"
                  alignItems="center"
                >
                  <Box
                    fontWeight="bolder"
                    id="modal-title"
                    mb="20px"
                    fontFamily="ProximaNova-Bold"
                    fontSize={{
                      xs: "20px",
                      sm: "30px"
                    }}
                  >
                    {this.t("MyProjects.Delete")}
                  </Box>
                  <Box fontSize="18px"
                    textAlign="center">
                    {this.t("MyProjects.AreYouSureYouWantToDeleteProject?")}
                  </Box>
                  <Box display="flex" mt="30px">
                    <CustomButton
                      onClick={() => this.setState({ deleteModel: false })}
                      id="cancel"
                      style={{
                        marginBottom: "0px", padding: "5px 10px"
                      }}
                    >
                      {this.t("MyProjects.button.Cancel")}
                    </CustomButton>
                    <RedButton
                      onClick={() => this.DeleteProject(this.state.DeteleId)}
                      style={{
                        padding: "5px 10px"
                      }}
                    >
                      {this.t("MyProjects.Delete")}
                    </RedButton>
                  </Box>
                </Box>
              </DialogContent>
            </Dialog>
          )}
        </AppSidebar>
      </StyleOfThisPage>
    );
  }
}

export default withRouter(withTranslation()(CreateProject));

const StyleOfThisPage = styled("div")({
  "& .ActionName": {
    display: "flex",
    justifyContent: "space-between",
    "& *": {
      marginLeft: "2px",
      marginRight: "2px",
    },
  },
  "& .projetName": {
    display: "flex",
    alignItems: "center",
    "& span": {
      display: "block",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      maxWidth: "140px",
    },
  },
  "& .MuiTableBody-root": {
    "& .MuiTableCell-root": {
      fontFamily: "ProximaNova-Regular",
      fontSize: "18px",
      color: "#000",
    },
  },
  '& .SearchIcon': {
    '& .MuiInputBase-root': {
      display: "flex",
      flexDirection: "row-reverse"
    }
  }
});

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  "& .MuiTooltip-tooltip": {
    padding: "4px 15px",
    borderRadius: "10px",
    margin: "0px 8px",
    fontSize: "13px",
    color: "black",
    backgroundColor: "white",
    border: "1.5px solid #d6d6d6",
  },
  "& .MuiTooltip-arrow": {
    color: "white",
  },
  "& .MuiTooltip-arrow::before": {
    border: "1.5px solid #d6d6d6",
  },
}));

const CustomButton = styled(Button)({
  backgroundColor: "white",
  color: "#f26a42",
  border: "1px solid #f26a42",
  fontFamily: "ProximaNova-Semibold",
  marginRight: "15px",
  borderRadius: "8px",
  fontSize: "18px",
  textTransform: "none",
  padding: "8px 25px",
  "&:hover": {
    color: "#f26a42",
    backgroundColor: "white",
  },
  "@media(max-width:600px)": {
    width: "100%",
  },
  "@media(max-width:992px)": {
    padding: "7px 15px",
  },
});

const CompletedStatus = styled(Box)({
  backgroundColor: "#dff8d2",
  width: "fit-content",
  color: "#288a0a",
  fontFamily: "ProximaNova-Regular",
  borderRadius: "25px",
  fontSize: "18px",
  padding: "3px 20px",
});

const NotStartedStatus = styled(Box)({
  backgroundColor: "#ffe1a6",
  width: "fit-content",
  color: "#288a0a",
  fontFamily: "ProximaNova-Regular",
  borderRadius: "25px",
  fontSize: "18px",
  padding: "3px 20px",
});

const OnGoingStatus = styled(Box)({
  backgroundColor: "#f5d3d3",
  width: "fit-content",
  fontSize: "18px",
  color: "#cd1414",
  borderRadius: "25px",
  fontFamily: "ProximaNova-Regular",
  padding: "3px 20px",
});

const RedButton = styled(Button)({
  textTransform: "none",
  borderRadius: "10px",
  fontSize: "18px",
  backgroundColor: "#f94b4b",
  color: "white",
  padding: "9px 25px",
  fontFamily: "ProximaNova-Semibold",
  "&:hover": {
    color: "white",
    backgroundColor: "#f94b4b",
  },
  "@media(max-width:600px)": {
    width: "100%",
    marginTop: "27px",
  },
  "@media(max-width:992px)": {
    padding: "8px 15px",
  },
});

// Customizable Area End
