// Customizable Area Start
import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { toast } from "react-toastify";

export const configJSON = require("./config");

export interface Props {
  navigation?: any;
  id?: string;
  t: any;
}

interface S {
  data: any;
  filterData: any,
  allClients: any;
  listTools: any
  ParticipantName: any;
  ParticipantDetails: any;
  openModal: boolean;
  openURLModal: boolean;
  URLmodal: boolean;
  deleteModal: boolean;
  deleteId: any;
  dynamicUrl: any;
  rederectUser: any;
  activeBtn: boolean,
  page: any,
  rowsPerPage: any,
  search: any;
  loader: boolean;
  isClientChanged: boolean;
  initialClientId: any;
  latestData: any;
  tools: any;
  selectedTools: any,
  timerButton: any;
  extendTime: any;
  filterselectedclinetname: any,
  filterselectedParticipantName: any,
  clientList: any[],
  forFilterData: any[],
  ParticipantList: any[],
  FormUpdate: boolean,
  setTool: any
}

interface SS {
  id: any;
}

export default class ManageParticipantController extends BlockComponent<
  Props,
  S,
  SS
> {
  getParticipantAPICall: string = '';
  getAllClinetsAPICall: string = '';
  getToolsListAPICall: string = '';
  updateParticipantAPICall: string = '';
  deleteParticipantAPICall: string = '';
  cloneParticipantAPICall: string = '';
  resetTimerAPICall: string = '';
  toolData: string = '';
  t: any = "";


  constructor(props: Props) {

    super(props);
    this.receive = this.receive.bind(this)
    this.handleCloseModal2 = this.handleCloseModal2.bind(this)
    this.handleOpenModal = this.handleOpenModal.bind(this)
    this.handleCloseModal = this.handleCloseModal.bind(this)
    this.handleopenURLModal = this.handleopenURLModal.bind(this)

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.PostDetailDataMessage)
    ]

    this.state = {
      data: [],
      filterData: [],
      allClients: [],
      listTools: [],
      ParticipantName: "",
      ParticipantDetails: "",
      openModal: false,
      openURLModal: false,
      URLmodal: false,
      deleteModal: false,
      deleteId: "",
      activeBtn: false,
      dynamicUrl: "",
      rederectUser: "",
      page: 0,
      rowsPerPage: 20,
      search: "",
      loader: true,
      isClientChanged: false,
      initialClientId: 0,
      latestData: {},
      selectedTools: 0,
      tools: [],
      timerButton: "",
      clientList: [],
      forFilterData: [],
      ParticipantList: [],
      FormUpdate: false,
      setTool: null,
      extendTime: [
        {
          value: 15,
          label: "15 Minutes"
        },
        {
          value: 30,
          label: "30 Minutes"
        },
        {
          value: 45,
          label: "45 Minutes"
        },
        {
          value: 60,
          label: "60 Minutes"
        }
      ],
      filterselectedclinetname: "",
      filterselectedParticipantName: "",
    };
    this.t = this.props.t;
    // @ts-ignore
    this.callRef = React.createRef();
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.getParticipantData()
    this.getAllClientsData()
    this.getAllToolsData()
  }

  onSearch(search: string, pageNumber: number) {

    const FilteredData = this.state.data.filter((item: any) => {
      if (search === "") {
        return true;
      } else if (
        item.attributes.first_name.toLowerCase().includes(search.toLowerCase()) ||
        item.attributes.email.toLowerCase().includes(search.toLowerCase())
      ) {
        return item;
      }
    })
    this.setState({ ...this.state, filterData: FilteredData, search: search, page: 0 })
  }


  async receive(from: string, message: Message) {
    if (getName(MessageEnum.PostDetailDataMessage) === message.id) {
      if (message.properties.text === "SAVE_PARTICIPANT") {
        this.setState({ search: "", loader: true });
        this.getParticipantData();
      }
      if (message.properties.text === "SAVENEWPARTICIPANT") {
        this.setState({ search: "", loader: true });
      }
    }
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (responseJson.status === 500) {
          toast.error(this.t("manageParticipant.toast.somethingWrong"));
        } else if (!Array.isArray(responseJson.errors) && responseJson.errors) {
          toast.error(responseJson.errors);
        } else {
          this.handleApiResponse(apiRequestCallId, responseJson);
        }
      }
    }
  }

  sentMessage(data: any) {
    const msg: Message = new Message(getName(MessageEnum.PostDetailDataMessage))
    msg.properties['text'] = data
    this.send(msg)
  }

  handleApiResponse = (apiRequestCallId: string, responseJson: any) => {
    if (Array.isArray(responseJson.errors)) {
      if (responseJson.errors[0].message) {
        toast.error(responseJson.errors[0].message);
      }
      else if (responseJson.errors[0].token) {
        toast.error(responseJson.errors[0].token);
      }
      else if (responseJson.errors[0].user_name) {
        toast.error(this.t("manageParticipant.toast.Userhasalreadybeentaken"));
      }
      this.setState({ loader: false });
    } else if (apiRequestCallId === this.resetTimerAPICall) {
      this.ResetTimeApicallFuncation(apiRequestCallId, responseJson)
    } else if (apiRequestCallId === this.updateParticipantAPICall) {
      this.sentMessage("SAVE_PARTICIPANT");
      this.setState({ loader: true })
      this.getParticipantData();
      if (this.state.timerButton === "") {
        this.handleUpdatedToast();
      } else {
        toast.success(this.t("manageParticipant.toast.ParticipantdetailshasbeenupdatedExtendtimehasbeendonesuccessfully", { time: this.state.timerButton }))
      }
      this.setState({ search: "", URLmodal: false });
    } else if (apiRequestCallId === this.deleteParticipantAPICall) {
      this.getParticipantData();
      this.handleDeleteToastMessage();
      this.setState({ search: "" });
    }
    this.handleApiResponseTwo(apiRequestCallId, responseJson)
  }

  ResetTimeApicallFuncation = (apiRequestCallId: string, responseJson: any) => {
    if (this.state.FormUpdate === false) {
      if (this.state.timerButton === "extend") {
        toast.success(this.t("manageParticipant.toast.Extendtimedonesuccessfullyforthisparticipant"))
      }
      if (this.state.timerButton === "reset") {
        toast.success(this.t("manageParticipant.toast.ResetTimedonesuccessfullyforthisparticipant"))
      }
    }
    this.setState({ tools: [], selectedTools: 0, setTool: null })
  }

  handleApiResponseTwo = (apiRequestCallId: string, responseJson: any) => {
    if (apiRequestCallId === this.getParticipantAPICall) {
      let clientList = responseJson.data.map((item: any) => {
        return {
          value: item.attributes.client_id,
          label: item.attributes.client_name
        }
      }).sort((a: any, b: any) => {
        return a.label.toLowerCase().localeCompare(b.label.toLowerCase())
      })
      this.setState({ data: responseJson.data, filterData: responseJson.data, loader: false, isClientChanged: false, clientList: clientList, forFilterData: responseJson.data, setTool: null })
    } else if (apiRequestCallId === this.getAllClinetsAPICall) {
      this.setState({ allClients: responseJson.data })
    } else if (apiRequestCallId === this.cloneParticipantAPICall) {
      const { latestData } = this.state
      let objData = {
        email: latestData.email,
        client_id: latestData.client_id,
        first_name: latestData.first_name,
        last_name: latestData.last_name,
        password: latestData.password,
        user_name: latestData.user_name
      }
      this.updateParticipantData(objData, responseJson.id)
      this.getParticipantData();
      this.setState({ search: "", setTool: null });
    } else if (apiRequestCallId === this.toolData) {
      console.log("responseJson", responseJson)
      this.handleResetFunctionality(responseJson)
    }
  }

  handleResetFunctionality = (responseJson: any) => {
    if (!responseJson.message) {
      responseJson.online_tools && this.setState({ tools: responseJson.online_tools });
    } else {
      this.setState({ tools: [] })
    }
  }

  handleUpdatedToast() {
    toast.success(this.t("manageParticipant.toast.edit"), {
      position: toast.POSITION.TOP_RIGHT
    });
  }

  handleDeleteToastMessage() {
    toast.success(this.t("manageParticipant.toast.delete"), {
      position: toast.POSITION.TOP_RIGHT
    });
  }

  handleTool = (toolId: any, tool: any) => {
    this.setState({ selectedTools: this.state.selectedTools === toolId ? 0 : toolId, setTool: this.state.setTool === tool ? null : tool })
  }

  handleTimerButton = (selection: any) => {
    this.setState({ timerButton: selection })
  }

  handleOpenModal() {
    this.setState({ openModal: true })

  }
  handleCloseModal2() {
    this.setState({ openModal: false })
  }
  handleopenURLModal() {
    this.setState({ openURLModal: true })
  }
  handleCloseModal() {
    this.setState({ openURLModal: false })
  }
  handleURLmodalOpen = (item: any) => {
    this.setState({ ParticipantName: `${item.attributes.first_name} ${item.attributes.last_name}` })
    this.setState({ ParticipantDetails: item.attributes, initialClientId: item.attributes.client_id })
    this.setState({ URLmodal: true, selectedTools: 0, timerButton: "" })
  }

  handleFilterBy = (item: any) => {
    if (item === null) {
      this.setState({
        data: this.state.forFilterData,
      }, () => this.onSearch("", 0))
    }
    let filterlist = this.state.forFilterData.filter((data: any) => {
      return data.attributes?.client_id === Number(item?.value)
    }).map((item: any) => {
      return {
        value: item.attributes.id,
        label: item.attributes.first_name + " " + item.attributes.last_name
      }
    })
    this.setState({
      ParticipantList: filterlist,
      filterselectedclinetname: item?.value,
      filterselectedParticipantName: ""
    })
  }

  handleOption = (item: any) => {
    this.setState({
      filterselectedParticipantName: item?.value
    })
  }

  HandeApplyFilter = () => {
    if (this.state.filterselectedclinetname !== "") {
      let data;
      if (this.state.filterselectedclinetname === undefined && this.state.filterselectedParticipantName === "") {
        data = this.state.forFilterData
      } else if (this.state.filterselectedclinetname !== "" && this.state.filterselectedParticipantName === "") {
        data = this.state.forFilterData.filter((item: any) => {
          return item.attributes.client_id === this.state.filterselectedclinetname
        });
      }
      else {
        data = this.state.forFilterData.filter((item: any) => {
          return item.attributes.client_id === this.state.filterselectedclinetname && item.attributes.id === this.state.filterselectedParticipantName;
        });
      }
      this.setState({
        data: data
      }, () => this.onSearch("", 0))
    }
  }

  handleBlurFunction = (e: any, props: any) => {
    props.handleBlur(e);
    (props.values.first_name && !props.errors.first_name) &&
      (props.values.last_name && !props.errors.last_name) && this.setNewPassword(props.setFieldValue);
    (props.values.first_name && !props.errors.first_name) &&
      (props.values.last_name && !props.errors.last_name) &&
      this.setUsername(
        props.setFieldValue,
        props.values.last_name,
        props.values.first_name,
      );
  }

  generateUniquePassword = (passwordLength: any) => {
    let numberChars = "0123456789";
    let upperChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    let specialChars = "!@#$%^&*)(:',.?/><";
    let lowerChars = "abcdefghiklmnopqrstuvwxyz";
    let allChars = numberChars + upperChars + lowerChars + specialChars;
    let randPasswordArray = Array(passwordLength);
    randPasswordArray[1] = upperChars;
    randPasswordArray[2] = lowerChars;
    randPasswordArray[3] = specialChars;
    randPasswordArray[0] = numberChars;
    randPasswordArray = randPasswordArray.fill(allChars, 4);
    return this.shuffleArray(
      randPasswordArray.map(function (x) {
        const getNum = new Uint8Array(1)
        const randomValue = crypto.getRandomValues(getNum)[0]
        const randomFloat = randomValue / Math.pow(2, 8)
        return x[Math.floor(randomFloat * x.length)];
      })
    ).join("");
  };

  shuffleArray = (array: any) => {
    for (let i = array.length - 1; i > 0; i--) {
      const randomNum = new Uint8Array(1)
      const randomValue = crypto.getRandomValues(randomNum)[0]
      const randomFloat = randomValue / Math.pow(2, 8)
      let j = Math.floor(randomFloat * (i + 1));
      let temp = array[i];
      array[i] = array[j];
      array[j] = temp;
    }
    return array;
  };

  setUsername = (setFieldValue: any, fname?: any, lname?: any) => {
    const username = fname.substring(0, 3) + lname.substring(0, 3) + new Date().getDate().toString() + (new Date().getMonth() + 1).toString() + new Date().getHours().toString() + new Date().getMinutes().toString() + new Date().getMilliseconds().toString();
    setFieldValue("user_name", username);
  };
  setNewPassword = (setFieldValue: any) => {
    const newPassword = this.generateUniquePassword(8);
    setFieldValue("password", newPassword);
  };

  handleClientChange = (changedValue: any, e: any, props: any) => {
    const { initialClientId } = this.state
    if (initialClientId === changedValue) {
      this.setState({ isClientChanged: false })
      props.setFieldValue("user_name", this.state.ParticipantDetails.user_name)
      props.setFieldValue("password", this.state.ParticipantDetails.password)
    } else {
      this.setState({ isClientChanged: true })
      props.handleBlur(e);
      (props.values.first_name && !props.errors.first_name) &&
        (props.values.last_name && !props.errors.last_name) && this.setNewPassword(props.setFieldValue);
      (props.values.first_name && !props.errors.first_name) &&
        (props.values.last_name && !props.errors.last_name) &&
        this.setUsername(
          props.setFieldValue,
          props.values.first_name,
          props.values.last_name
        );
    }
  }

  handleURLmodalClose = () => {
    this.setState({ URLmodal: false, isClientChanged: false, selectedTools: 0, tools: [], setTool: null })
  };

  handleOpenDeleteModal = (id: string | number) => {
    this.setState({ deleteId: id });
    this.setState({ deleteModal: true });

  }

  handleCloseDeleteModal = () => {
    this.setState({ deleteModal: false })
  }

  getParticipantData(): boolean {

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      token: localStorage.getItem('accesToken')
    };
    this.getParticipantAPICall = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getParticipantData
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;

  }

  getAllClientsData(): boolean {
    const header = {
      token: localStorage.getItem('accesToken')
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAllClinetsAPICall = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllClientsData
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;

  }

  getAllToolsData(): boolean {
    const header = {
      token: localStorage.getItem('accesToken')
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getToolsListAPICall = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getToolsData
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;

  }

  getParticipantToolData = (id: any) => {
    const header = {
      token: localStorage.getItem("accesToken"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.toolData = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/account_block/participants/get_participant_project_tool?id=${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleResetData = (values: any, id: any) => {
    let headers = {
      token: localStorage.getItem('accesToken'),
    };

    let time = this.state.timerButton === 'reset' ? 0 : values.time

    const addClientApiMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.resetTimerAPICall = addClientApiMsg.messageId
    addClientApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/participants/reset_extend_time?id=${id}&tool_id=${this.state.selectedTools}&time=${time}`
    );
    addClientApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    addClientApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(addClientApiMsg.id, addClientApiMsg);
    this.setState({ URLmodal: false })
  }

  updateParticipantData = (values: any, id: any) => {
    let headers = {
      token: localStorage.getItem('accesToken'),
      "Content-Type": "application/json",
    };

    let objToPass = {
      client_id: values.client_id,
      email: values.email,
      first_name: values.first_name,
      last_name: values.last_name,
      password: values.password,
      user_name: values.user_name,
    }
    let httpBody = {}
    httpBody = {
      "data": {
        "attributes": objToPass,
      }
    }
    const addClientApiMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.updateParticipantAPICall = addClientApiMsg.messageId
    addClientApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getParticipantData}/${id}`
    );
    addClientApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    addClientApiMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    addClientApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
    runEngine.sendMessage(addClientApiMsg.id, addClientApiMsg);
  }

  cloneParticipantData = () => {
    let headers = {
      token: localStorage.getItem('accesToken'),
      "Content-Type": "application/json",
    };

    const addClientApiMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.cloneParticipantAPICall = addClientApiMsg.messageId
    addClientApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/participants/clone?id=${this.state.ParticipantDetails.id}`
    );
    addClientApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    addClientApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(addClientApiMsg.id, addClientApiMsg);
    this.setState({ URLmodal: false })
  }

  deleteParticipantData = (id: string | number) => {
    let headers = {
      token: localStorage.getItem('accesToken'),
      "Content-Type": "application/json",
    };

    const addClientApiMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.deleteParticipantAPICall = addClientApiMsg.messageId
    addClientApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getParticipantData}/${id}`
    );
    addClientApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    addClientApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );
    runEngine.sendMessage(addClientApiMsg.id, addClientApiMsg);
    this.setState({ deleteModal: false })
  }
}
 // Customizable Area End
