// Customizable Area Start
import * as Yup from "yup";
// Customizable Area End

// Customizable Area Start
export const addClientSchema = (t: any) => {
  return Yup.object().shape({
    full_name: Yup.string().required(t("ManageClient.errorMessages.name")),
    user_name: Yup.string().required(t("ManageClient.errorMessages.username")),
    email: Yup.string()
      .email(t("ManageClient.errorMessages.emailInvalid"))
      .required(t("ManageClient.errorMessages.email")),
    password: Yup.string()
      .matches(
        /^(?=.*\d)(?=.*[!(@#$%^&*)|}"'{:;<,>.?/])(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
        t("ManageClient.errorMessages.passwordRule")
      )
      .required(t("ManageClient.errorMessages.password")),
    questionImage: Yup.mixed()
      .required(t("ManageClient.errorMessages.logo"))
      .test("fileSize", t("ManageClient.errorMessages.fileSize"), (file) => {
        return typeof file !== "string"
          ? (!file || (file && file.size <= 10 * 1024 * 1024))
          : true
      })
  })

}
// Customizable Area End

// Customizable Area Start
export const addParticipantSchema = (t: any) => {
  return Yup.object().shape({
    user_name: Yup.string().required(t("manageParticipant.errorMessages.username")),
    last_name: Yup.string().required(t("manageParticipant.errorMessages.lastName")),
    email: Yup.string()
      .required(t("manageParticipant.errorMessages.email"))
      .email(t("manageParticipant.errorMessages.emailInvalid")),
    first_name: Yup.string().required(t("manageParticipant.errorMessages.firstName")),
    password: Yup.string()
      .required(t("manageParticipant.errorMessages.password"))
      .matches(
        /^(?=.*\d)(?=.*[!(@#$%^&*)|}"'{:;<,>.?/])(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
        t("manageParticipant.errorMessages.passwordRule")
      ),
    client_id: Yup.mixed()
      .required(t("manageParticipant.errorMessages.organizationName"))
      .nullable()
  });
};
// Customizable Area End

// Customizable Area Start
export const updateParticipantSchema = (t: any) => {
  return Yup.object().shape({
    first_name: Yup.string().required(t("manageParticipant.errorMessages.firstName")),
    last_name: Yup.string().required(t("manageParticipant.errorMessages.lastName")),
    user_name: Yup.string().required(t("manageParticipant.errorMessages.username")),
    email: Yup.string()
      .required(t("manageParticipant.errorMessages.email"))
      .email(t("manageParticipant.errorMessages.emailInvalid")),
    password: Yup.string()
      .required(t("manageParticipant.errorMessages.password"))
      .matches(
        /^(?=.*\d)(?=.*[!(@#$%^&*)|}"'{:;<,>.?/])(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
        t("manageParticipant.errorMessages.passwordRule")
      )
      .nullable(),
    client_id: Yup.mixed()
      .required(t("manageParticipant.errorMessages.organizationName"))
      .nullable()
  });
};
// Customizable Area End

// Customizable Area Start
export const addAssessorSchema = (t: any) => {
  return Yup.object().shape({
    first_name: Yup.string().required(t("manageAssessor.errorMessages.firstName")),
    last_name: Yup.string().required(t("manageAssessor.errorMessages.lastName")),
    email: Yup.string()
      .email(t("manageAssessor.errorMessages.emailInvalid"))
      .required(t("manageAssessor.errorMessages.email"))
  });
};

export const updateAssessorSchema = (t: any) => {
  return Yup.object().shape({
    full_name: Yup.string().required(t("manageAssessor.errorMessages.name")),
    user_name: Yup.string().required(t("manageAssessor.errorMessages.username")),
    email: Yup.string()
      .email(t("manageAssessor.errorMessages.emailInvalid"))
      .required(t("manageAssessor.errorMessages.email")),
    password: Yup.string().matches(
      /^(?=.*\d)(?=.*[!(@#$%^&*)|}"'{:;<,>.?/])(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
      t("manageAssessor.errorMessages.passwordRule")
    ).required(t("manageAssessor.errorMessages.password"))
  });
};

export const AssignAvailableAssessorEditSchema = (t: any) => {
  return Yup.object().shape({
    full_name: Yup.string().required(t("AssignAvailableAssessor.errorMessages.participantName")),
    date: Yup.string().required(t("AssignAvailableAssessor.errorMessages.date")),
    slot: Yup.string().required(t("AssignAvailableAssessor.errorMessages.slot")),
    AssessorName: Yup.string().required(t("AssignAvailableAssessor.errorMessages.assessorName")),
    ProjectName: Yup.string().required(t("AssignAvailableAssessor.errorMessages.projectName"))
  });
};
// Customizable Area End
