
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { toast } from "react-toastify";
import generator from 'generate-password'
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  t: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  data: any[];
  openModal: boolean;
  openURLModal: boolean;
  URLmodal: boolean;
  dynamicUrl: any;
  rederectUser: any;
  clientName: "",
  clientEmail: "",
  clientUserName: string,
  clientPassword: any,
  clientNameError: any,
  clientEmailError: any,
  clientUserNameError: any,
  clientPasswordError: any,
  page: any,
  rowsPerPage: any,
  search: any,
  deleteModel: any,
  deleteModelText: any,
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class AssessorListController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  addClientCallId: string = "";
  getURLdataAPICall = "string"
  Assessorlistdata: any = ""
  Userdelete: any = ""
  t: any = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.handleOpenModal = this.handleOpenModal.bind(this)
    this.handleopenURLModal = this.handleopenURLModal.bind(this)
    this.handleCloseModal = this.handleCloseModal.bind(this)
    this.handleCloseModal2 = this.handleCloseModal2.bind(this)
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      data: [],
      openModal: false,
      openURLModal: false,
      URLmodal: false,
      dynamicUrl: "",
      rederectUser: "",
      clientName: "",
      clientEmail: "",
      clientUserName: "",
      clientPassword: "",
      clientNameError: "",
      clientEmailError: "",
      clientUserNameError: "",
      clientPasswordError: "",
      page: 0,
      rowsPerPage: 20,
      search: "",
      deleteModel: false,
      deleteModelText: []
    };
    this.t = this.props.t;
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  // Customizable Area Start
  async componentDidMount(): Promise<void> {
    return this.getTable()
  }

  createPassword() {
    let password = generator.generate({
      length: 8,
      numbers: true,
      symbols: true
    });
    return password
  }

  handleCompanyNameBlur() {
    this.setState({ clientUserName: this.createUsername(this.state.clientName), clientPassword: this.createPassword() }, () => {
      console.log(this.state.clientUserName, this.state.clientPassword, "password")
    })
  }

  createUsername(organisation: string) {
    const num = new Uint8Array(1)
    const randomValue = crypto.getRandomValues(num)[0]
    const randomFloat = randomValue / Math.pow(2, 8)
    let userName = organisation + Math.floor(randomFloat * 1000)
    return userName

  }

  handleValid() {
    console.log("handleValid func")
    if (this.state.clientName === "" || !this.state.clientEmail.includes('@') || this.state.clientUserName === "" || this.state.clientPassword.length < 4) {
      if (this.state.clientName === "") {

        this.setState({ clientNameError: "Name cannot be empty" })

      }
      if (!this.state.clientEmail.includes('@')) {
        this.setState({ clientEmailError: "Email must be valid" })

      }
      if (this.state.clientUserName === "") {
        this.setState({ clientUserNameError: "Username cannot be empty" })

      }
      if (this.state.clientPassword.length < 8) {

        this.setState({ clientPasswordError: "Password must be atleast 8 characters long" })

      }
      return false

    }

    return true

  }

  handleToastMessage() {
    toast.success('Client added succesfully', {
      position: toast.POSITION.TOP_RIGHT
    });
  }

  handleClientName(e: any) {
    this.setState({ clientName: e.target.value })
    this.setState({ clientNameError: "" })
  }
  handleClientEmail(e: any) {
    this.setState({ clientEmail: e.target.value })
    this.setState({ clientEmailError: "" })
  }
  handleClientUserName(e: any) {
    this.setState({ clientUserName: e.target.value })
    this.setState({ clientUserNameError: "" })

  }
  handleClientPassword(e: any) {
    this.setState({ clientPassword: e.target.value })
    this.setState({ clientPasswordError: "" })

  }
  handleAddClient(e: any) {
    e.preventDefault()
    let { clientEmail, clientName, clientPassword, clientUserName } = this.state
    console.log(this.state.clientEmail, this.state.clientName, this.state.clientUserName)


    let headers = {
      "Content-Type": "application/json",
      token: localStorage.getItem("accesToken")
    };
    let httpBody = {}
    httpBody = {
      "data": {
        "attributes": {
          "full_name": clientName,
          "email": clientEmail,
          "user_name": clientUserName,
          "password": clientPassword
        },
        type: "client"
      }
    }
    const addClientApiMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.addClientCallId = addClientApiMsg.messageId
    addClientApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/account_block/clients"
    );
    addClientApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    addClientApiMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    addClientApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(addClientApiMsg.id, addClientApiMsg);
    this.setState({ openModal: false, clientName: "", clientEmail: "", clientPassword: "", clientUserName: "", clientNameError: "", clientEmailError: "", clientUserNameError: "", clientPasswordError: "" })
  }

  // Customizable Area End

  // Customizable Area Start
  async receive(from: string, message: Message) {
    if (this.addClientCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiResponse?.errors) {
        apiResponse?.errors?.map((option: any) => {
          for (const i in option) {
            if (option[i] == "has already been taken") {
              toast.error("client with same email already exists")

            }
            else if (option[i] == "Admin with same name already exists.") {
              alert("Admin with same name already exists.")
            }

          }
        })

      } else {
        this.getTable()
        this.forceUpdate()
        this.handleToastMessage()
      }
    }
    this.setStateData(message)
  }

  setStateData = (message:any)=>{
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );


      if (apiRequestCallId === this.getURLdataAPICall) {
        if (!responseJson.errors) {
          this.setState({ dynamicUrl: responseJson.info, rederectUser: responseJson.info.split("/")[3] })
          toast.success(`Congratulations, ${responseJson.meta.message}`)
        } else {
          toast.error(responseJson.errors[0].message)
        }
      }
      else if (apiRequestCallId === this.Assessorlistdata) {
        localStorage.setItem("AssessorList", JSON.stringify(responseJson))
        this.setState((e) => ({
          ...e, data: responseJson.data
        }))
      }
      else if (apiRequestCallId === this.Userdelete) {
        this.getTable()
        this.setState({
          ...this.state, deleteModel: false
        })
        toast.success('Client Delete succesfully', {
          position: toast.POSITION.TOP_RIGHT
        });
      }
    }
  }

  handleOpenModal() {

    this.setState({ openModal: true })

  }
  handleCloseModal2() {
    this.setState({ openModal: false, clientUserName: "", clientPassword: "" })
  }
  // Customizable Area End

  // Customizable Area Start
  handleopenURLModal() {
    this.setState({ openURLModal: true })
  }
  handleCloseModal() {
    this.setState({ openURLModal: false })
  }
  goToHome() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }
  getTable = () => {
    this.GetTableAssessorList()

  }

  handleURLmodalOpen = (item: any) => {
    this.getURLData(item.attributes?.full_name, item.attributes?.id);
    this.setState({ URLmodal: true })
  }

  handleURLmodalClose = () => {
    this.setState({ URLmodal: false })
  };

  getURLData(comp_name: any, comp_id: any): boolean {
    const header = {
      token: localStorage.getItem('accesToken'),
      "Content-Type": "application/json",
    };

    const payload = {
      company_name: comp_name,
      id: localStorage.getItem('userId'),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getURLdataAPICall = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointApiGetURLData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(payload)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  GetTableAssessorList = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("accesToken")
    };

    this.Assessorlistdata = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/bx_block_profile/slots/get_assessors"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  DeleteUser = () => {
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("accesToken")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.Userdelete = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/account_block/clients/${this.state.deleteModelText.id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // Customizable Area End
}
