// Customizable Area Start
import React from "react";
import {
  Box,
  Grid,
  Button,
  InputBase,
  Typography
} from "@material-ui/core";
import moment from "moment";
import { styled } from "@material-ui/styles";
import { withRouter } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import Select from "react-select";
import { withTranslation } from "react-i18next";
import PhoneInput from 'react-phone-input-2';
//@ts-ignore
import DatePicker from "react-datepicker";
import { Country, State, City } from 'country-state-city';
import BasicInformationController, {
  Props
} from "./BasicInformationController";
const shrmLogo = require("../assets/shrmlogo.png");
import { calender } from "../src/assets";
import Spinner from "../../../components/src/Spinner.web";
const locallanguage = localStorage.getItem("language") === "ar"

class BasicInformation extends BasicInformationController {
  constructor(props: Props) {
    super(props);
  }

  basicInfoSchema = () => {
    return Yup.object().shape({
      firstName: Yup.string().required(this.t("basicInfo.Errors.firstName")),
      lastName: Yup.string().required(this.t("basicInfo.Errors.lastName")),
      gender: Yup.string().required(this.t("basicInfo.Errors.gender")).nullable(),
      dob: Yup.string().required(this.t("basicInfo.Errors.dob"))
        .test("dob", this.t("basicInfo.Errors.validDate"), (value) => {
          return moment().diff(moment(value), "years") >= 18;
        }).nullable(),
      phoneNumber: Yup.string().required(this.t("basicInfo.Errors.phone"))
        .min(12, this.t("basicInfo.Errors.validPhone")).nullable(),
      designation: Yup.string().required(this.t("basicInfo.Errors.management")).nullable(),
      workExperience: Yup.string().required(this.t("basicInfo.Errors.workExp")).nullable(),
      country: Yup.string().required(this.t("basicInfo.Errors.country")).nullable(),
      state: Yup.string().required(this.t("basicInfo.Errors.state")).nullable(),
      city: Yup.string().required(this.t("basicInfo.Errors.city")).nullable(),
      highestEducation: Yup.string().required(this.t("basicInfo.Errors.highestEdu")).nullable(),
      tenure: Yup.string().required(this.t("basicInfo.Errors.tenure")).nullable()
    });
  }

  getErrorMessage = (touched: any, errors: any, value: string) => {
    return touched[value] && errors[value] &&
      <div className="text-danger">{errors[value]}</div>
  }

  render() {
    const countryList = Country.getAllCountries().map((country: any) => ({ label: country.name, value: country.isoCode }));
    const list = State.getStatesOfCountry(this.state.countryCode);
    const stateList = !!list ? list.map((state: any) => ({ label: state.name, value: state.isoCode })) : [];
    const list2 = City.getCitiesOfState(this.state.countryCode, this.state.stateCode);
    const cityList = !!list2 ? list2.map((city: any) => ({ label: city.name, value: city.name })) : [];
    const isPresent = cityList.filter((city: any) => city.value.toLowerCase() === this.state.city.toLowerCase())[0];
    !isPresent && this.state.city && cityList.unshift({ label: this.state.city, value: this.state.city });

    return (
      <BasicStyle>
        {this.state.loading && <Spinner spinnerModal={this.state.loading} />}
        <Box style={{ backgroundColor: "#1b3c69" }}>
          <Box position="relative">
            <Box display="flex" justifyContent="flex-end" paddingTop="30px" paddingRight="30px" className="image_logo" >
              <img src={shrmLogo} alt="logo" className="logo_image" />
            </Box>
          </Box>
          <Box className="view_basic_info">
            <Box className="basic_info_container" p={{ xs: "15px", sm: "20px", md: "15px" }}>
              <Box mt={{ xs: "10px", md: "0px" }} mb={{ xs: "20px", sm: "20px" }} fontSize={{ xs: "23px", sm: "26px", md: "32px", lg: "32px" }} fontWeight="bold">
                {this.t("basicInfo.title")}</Box>
              <Formik
                enableReinitialize={this.state.isInfoFetched}
                initialValues={{
                  firstName: this.state.firstName,
                  lastName: this.state.lastName,
                  gender: this.state.gender,
                  dob: this.state.dob,
                  phoneNumber: this.state.phoneNumber,
                  designation: this.state.designation,
                  workExperience: this.state.workExperience,
                  country: this.state.countryCode,
                  state: this.state.countryCode,
                  city: this.state.city,
                  highestEducation: this.state.highestEducation,
                  organizationSector: this.state.organizationSector,
                  organizationIndustry: this.state.organizationIndustry,
                  tenure: this.state.tenure,
                }}
                validationSchema={this.basicInfoSchema}
                onSubmit={(values) => {
                  this.handleSubmit();
                }}
              >
                {({ errors, touched, setFieldValue, handleSubmit }) => (
                  <form
                    onSubmit={handleSubmit}
                    noValidate
                    autoComplete="off"
                  >
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={6} md={6} lg={6}>
                        <Typography variant="body2" style={{ marginBottom: "5px" }}>
                          {this.t("basicInfo.Fields.firstName")}<span style={{ color: "red" }}>*</span>
                        </Typography>
                        <CustomInputBase
                          fullWidth
                          minRows={1}
                          placeholder={this.t("basicInfo.Fields.firstName")}
                          value={this.state.firstName}
                          onChange={(event: any) => {
                            setFieldValue("firstName", event.target.value);
                            this.setState({ firstName: event.target.value});
                          }}
                        />
                        {this.getErrorMessage(touched, errors, "firstName")}
                      </Grid>
                      <Grid item xs={12} sm={6} md={6} lg={6}>
                        <Typography variant="body2" style={{ marginBottom: "5px" }}>
                        {this.t("basicInfo.Fields.lastName")}<span style={{ color: "red" }}>*</span>
                        </Typography>
                        <CustomInputBase
                          fullWidth
                          minRows={1}
                          placeholder={this.t("basicInfo.Fields.lastName")}
                          value={this.state.lastName}
                          onChange={(event: any) => {
                            setFieldValue("lastName", event.target.value);
                            this.setState({ lastName: event.target.value });
                          }}
                        />
                        {this.getErrorMessage(touched, errors, "lastName")}
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={4}>
                        <Typography variant="body2" style={{ marginBottom: "5px" }}>
                        {this.t("basicInfo.Fields.company")}
                        </Typography>
                        <CustomInputBase
                          fullWidth
                          minRows={1}
                          placeholder={this.t("basicInfo.Fields.company")}
                          value={this.state.companyName}
                          onChange={(event: any) => {
                            this.setState({ companyName: event.target.value });
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={4} lg={4} sm={6}>
                        <Typography variant="body2" style={{ marginBottom: "5px" }}>
                        {this.t("basicInfo.Fields.gender")}<span style={{ color: "red" }}>*</span>
                        </Typography>
                        <Select
                          classNamePrefix="react-select"
                          placeholder={this.t("basicInfo.Fields.gender")}
                          isSearchable={false}
                          options={genderList}
                          value={
                            genderList.filter(
                              (option: any) =>
                                option.value === this.state.gender
                            )[0]
                          }
                          onChange={(event: any) => {
                            setFieldValue("gender", event.value);
                            this.setState({ gender: event.value });
                          }}
                        />
                        {this.getErrorMessage(touched, errors, "gender")}
                      </Grid>
                      <Grid item xs={12} md={4} lg={4} sm={6} className={locallanguage ? "DateOfBrith widthDob" : "widthDob"}>
                        <Typography variant="body2" style={{ marginBottom: "5px" }}>
                        {this.t("basicInfo.Fields.dob")}<span style={{ color: "red" }}>*</span>
                        </Typography>
                        <DatePicker
                          selected={this.state.dob}
                          onChange={(date: any) => {
                            setFieldValue("dob", date);
                            this.setState({ dob: date });
                          }}
                          peekNextMonth
                          customInput={<CustomInput />}
                          showMonthDropdown
                          showYearDropdown
                          maxDate={new Date()}
                          dropdownMode="select"
                          dateFormat="dd/MM/yyyy"
                          placeholderText="DD/MM/YYYY"
                        />
                        {this.getErrorMessage(touched, errors, "dob")}
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={4} className={locallanguage ? "CountrySelect" : ""}>
                        <Typography variant="body2" style={{ marginBottom: "5px" }}>
                        {this.t("basicInfo.Fields.phone")}<span style={{ color: "red" }}>*</span>
                        </Typography>
                        <PhoneInput
                          country={'in'}
                          countryCodeEditable={false}
                          enableSearch={true}
                          disableSearchIcon={true}
                          placeholder={this.t("basicInfo.Fields.phone")}
                          containerClass="country-phone-input"
                          value={this.state.phoneNumber?.toString() ?? ""}
                          onChange={(number: any) => {
                            setFieldValue("phoneNumber", number.toString());
                            this.setState({ phoneNumber: number.toString() });
                          }}
                          inputProps={{
                            name: 'phone_number',
                            required: true,
                          }}
                          inputStyle={{
                            height: "50px"
                          }}
                        />
                        {this.getErrorMessage(touched, errors, "phoneNumber")}
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={4}>
                        <Typography variant="body2" style={{ marginBottom: "5px" }}>
                        {this.t("basicInfo.Fields.management")}<span style={{ color: "red" }}>*</span>
                        </Typography>
                        <Select
                          placeholder={this.t("basicInfo.Fields.management")}
                          isSearchable={false}
                          options={this.state.designationList}
                          value={
                            this.state.designationList.filter(
                              (option: any) =>
                                option.value === this.state.designation
                            )[0]
                          }
                          classNamePrefix="react-select"
                          onChange={(event: any) => {
                            setFieldValue("designation", event.value);
                            this.setState({ designation: event.value });
                          }}
                        />
                        {this.getErrorMessage(touched, errors, "designation")}
                      </Grid>
                      <Grid id="basicInfo.Fields.workExpNewgrid" item xs={12} sm={6} md={4} lg={4}>
                        <Typography id="basicInfo.Fields.workExpNew" variant="body2" style={{ marginBottom: "5px" }}>
                          {this.t("basicInfo.Fields.workExp")}<span style={{ color: "red" }}>*</span>
                        </Typography>
                        <Select
                          classNamePrefix="react-select"
                          placeholder={this.t("basicInfo.Fields.workExp")}
                          value={
                            overallWorkExp.filter(
                              (option: any) =>
                                option.value === this.state.workExperience
                            )[0]
                          }
                          isSearchable={false}
                          options={overallWorkExp}
                          onChange={(event: any) => {
                            setFieldValue("workExperience", event.value);
                            this.setState({ workExperience: event.value });
                          }}
                        />
                        {this.getErrorMessage(touched, errors, "workExperience")}
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={4}>
                        <Typography variant="body2" style={{ marginBottom: "5px" }}>
                        {this.t("basicInfo.Fields.country")}<span style={{ color: "red" }}>*</span>
                        </Typography>
                        <Select
                          classNamePrefix="react-select"
                          placeholder={this.t("basicInfo.Fields.country")}
                          value={
                            countryList.filter(
                              (option: any) =>
                                option.value === this.state.countryCode
                            )[0]
                          }
                          isSearchable={true}
                          options={countryList}
                          onChange={(event: any) => {
                            setFieldValue("country", event.value);
                            this.setState({ country: event.label, countryCode: event.value, state: "", stateCode: "", city: "" }, () => {
                              setFieldValue("state", "");
                            });
                          }}
                        />
                        {this.getErrorMessage(touched, errors, "country")}
                      </Grid>
                      <Grid item id="basicInfo.Fields.stateNewData" xs={12} sm={6} md={4} lg={4}>
                        <Typography id="basicInfo.Fields.state" variant="body2" style={{ marginBottom: "5px" }}>
                        {this.t("basicInfo.Fields.state")}<span style={{ color: "red" }}>*</span>
                        </Typography>
                        <Select
                          classNamePrefix="react-select"
                          value={
                            stateList.filter(
                              (option: any) =>
                                option.value === this.state.stateCode
                            )[0] ?? ""
                          }
                          isSearchable={true}
                          options={stateList ?? []}
                          placeholder={this.t("basicInfo.Fields.state")}
                          onChange={(event: any) => {
                            setFieldValue("state", event.value);
                            this.setState({ state: event.label, stateCode: event.value, city: "" }, () => {
                              setFieldValue("city", "");
                            });
                          }}
                        />
                        {this.getErrorMessage(touched, errors, "state")}
                      </Grid>
                      <Grid item id="basicInfo.Fields.cityIdGrid" xs={12} sm={6} md={4} lg={4}>
                        <Typography id="basicInfo.Fields.city" variant="body2" style={{ marginBottom: "5px" }}>
                        {this.t("basicInfo.Fields.city")}<span style={{ color: "red" }}>*</span>
                        </Typography>
                        <Select
                          placeholder={this.t("basicInfo.Fields.city")}
                          classNamePrefix="react-select"
                          isSearchable={true}
                          value={
                            cityList.filter(
                              (option: any) =>
                                option.value === this.state.city
                            )[0] ?? ""
                          }
                          options={cityList}
                          onChange={(event: any) => {
                            setFieldValue("city", event.value);
                            this.setState({ city: event.value });
                          }}
                          onInputChange={(value: any) => {
                            let val = value ? value : this.state.city;
                            setFieldValue("city", val);
                            this.setState({ city: val });
                          }}
                        />
                        {this.getErrorMessage(touched, errors, "city")}
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={4}>
                        <Typography id="basicInfo.Fields.highestEduIdNew" variant="body2" style={{ marginBottom: "5px" }}>
                        {this.t("basicInfo.Fields.highestEdu")}<span style={{ color: "red" }}>*</span>
                        </Typography>
                        <Select
                          placeholder={this.t("basicInfo.Fields.highestEdu")}
                          isSearchable={false}
                          classNamePrefix="react-select"
                          options={this.state.highestEductionList}
                          value={
                            this.state.highestEductionList.filter(
                              (option: any) =>
                                option.value === this.state.highestEducation
                            )[0]
                          }
                          onChange={(event: any) => {
                            setFieldValue("highestEducation", event.value);
                            this.setState({ highestEducation: event.value });
                          }}
                        />
                        {this.getErrorMessage(touched, errors, "highestEducation")}
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={4}>
                        <Typography variant="body2" style={{ marginBottom: "5px" }}>
                          {this.t("basicInfo.Fields.employment")}
                        </Typography>
                        <Select
                          classNamePrefix="react-select"
                          placeholder={this.t("basicInfo.Fields.employment")}
                          isSearchable={false}
                          options={this.state.employmentTypeList}
                          value={
                            this.state.employmentTypeList.filter(
                              (option: any) =>
                                option.value === this.state.employmentType
                            )[0]
                          }
                          onChange={(event: any) => {
                            this.setState({ employmentType: event.value });
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={4}>
                        <Typography variant="body2" style={{ marginBottom: "5px" }}>
                        {this.t("basicInfo.Fields.orgFunction")}
                        </Typography>
                        <Select
                          classNamePrefix="react-select"
                          placeholder={this.t("basicInfo.Fields.orgFunction")}
                          isSearchable={false}
                          options={this.state.organizationFunctionList}
                          value={
                            this.state.organizationFunctionList.filter(
                              (option: any) =>
                                option.value === this.state.organizationFunction
                            )[0]
                          }
                          onChange={(event: any) => {
                            this.setState({ organizationFunction: event.value });
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={4}>
                        <Typography variant="body2" style={{ marginBottom: "5px" }}>
                        {this.t("basicInfo.Fields.orgSector")}
                        </Typography>
                        <Select
                          classNamePrefix="react-select"
                          placeholder={this.t("basicInfo.Fields.orgSector")}
                          isSearchable={false}
                          options={this.state.organizationSectorList}
                          value={
                            this.state.organizationSectorList.filter(
                              (option: any) =>
                                option.value === this.state.organizationSector
                            )[0]
                          }
                          onChange={(event: any) => {
                            this.setState({ organizationSector: event.value });
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={4}>
                        <Typography variant="body2" style={{ marginBottom: "5px" }}>
                        {this.t("basicInfo.Fields.orgIndustry")}
                        </Typography>
                        <Select
                          classNamePrefix="react-select"
                          placeholder={this.t("basicInfo.Fields.orgIndustry")}
                          isSearchable={false}
                          options={this.state.organizationIndustryList}
                          value={
                            this.state.organizationIndustryList.filter(
                              (option: any) =>
                                option.value === this.state.organizationIndustry
                            )[0]
                          }
                          onChange={(event: any) => {
                            this.setState({ organizationIndustry: event.value });
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={4}>
                        <Typography variant="body2" style={{ marginBottom: "5px" }}>
                        {this.t("basicInfo.Fields.orgWorkExp")}<span style={{ color: "red" }}>*</span>
                        </Typography>
                        <Select
                          classNamePrefix="react-select"
                          placeholder={this.t("basicInfo.Fields.orgWorkExp")}
                          isSearchable={false}
                          options={tenureList}
                          value={
                            tenureList.filter(
                              (option: any) =>
                                option.value === this.state.tenure
                            )[0]
                          }
                          onChange={(event: any) => {
                            setFieldValue("tenure", event.value);
                            this.setState({ tenure: event.value });
                          }}
                        />
                        {this.getErrorMessage(touched, errors, "tenure")}
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="body2" style={{ marginBottom: "5px" }}>
                        {this.t("basicInfo.Fields.professionalCerti")}
                        </Typography>
                        <CustomInputBase
                          fullWidth
                          multiline
                          minRows={1}
                          placeholder={this.t("basicInfo.Fields.certiPlaceholder")}
                          value={this.state.certificate}
                          onChange={(event: any) => {
                            this.setState({ certificate: event.target.value });
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Box display="flex" justifyContent="end">
                          <RedButton type="submit">{this.t("basicInfo.button.submit")}</RedButton>
                        </Box>
                      </Grid>
                    </Grid>
                  </form>
                )}
              </Formik>
            </Box>
          </Box>
          <section className="text_footer" style={{ paddingBottom: "10px" }} dir="ltr">
            <p className="text_footer_basic_info">{this.state.copyright}</p>
          </section>
        </Box>
      </BasicStyle>
    );
  }
}

export default withRouter(withTranslation()(BasicInformation));

const CustomInput = React.forwardRef<any, { value?: any, onClick?: any }>(({ value, onClick }, ref) => (
  <Box position="relative" className="SelecterDate" border="1px solid #d6d6d6" borderRadius="10px">
    <img src={calender} onClick={onClick} style={{ zIndex: 1, position: "absolute", right: locallanguage === false ? "12px" : "",
          left: locallanguage ? "12px" : "", top: "14px", cursor: "pointer", width: "20px" }} />
    <DateInput
      placeholder="DD/MM/YYYY"
      onClick={onClick}
      value={value}
    />
  </Box>
));

const genderList = [
  { label: "Male", value: "Male" },
  { label: "Female", value: "Female" },
  { label: "Other", value: "Other" }
];

const overallWorkExp = [
  { label: "< 1 Year", value: ">= 1 year" },
  { label: "1 Year", value: "0-1 years" },
  { label: "2 Years", value: "2 years" },
  { label: "3 Years", value: "3 years" },
  { label: "4 Years", value: "4 years" },
  { label: "5 years", value: "5 years" },
  { label: "6 years", value: "6 years" },
  { label: "7 years", value: "7 years" },
  { label: "8 years", value: "8 years" },
  { label: "9 years", value: "9 years" },
  { label: "10 years", value: "10 years" },
  { label: "11 years", value: "11 years" },
  { label: "12 years", value: "12 years" },
  { label: "13 years", value: "13 years" },
  { label: "14 years", value: "14 years" },
  { label: "15 years", value: "15 years" },
  { label: "16 years", value: "16 years" },
  { label: "17 years", value: "17 years" },
  { label: "18 years", value: "18 years" },
  { label: "19 years", value: "19 years" },
  { label: "20 years", value: "20 years" },
  { label: "20+ years", value: "20+ years" }
];

const tenureList = [
  { label: "< 1 Year", value: ">= 1 year" },
  { label: " 1 Year", value: "0-1 years" },
  { label: " 2 Years", value: "2 years" },
  { label: " 3 Years", value: "3 years" },
  { label: " 4 Years", value: "4 years" },
  { label: " 5 years", value: "5 years" },
  { label: " 6 years", value: "6 years" },
  { label: " 7 years", value: "7 years" },
  { label: " 8 years", value: "8 years" },
  { label: " 9 years", value: "9 years" },
  { label: " 10 years", value: "10 years" },
  { label: " 11 years", value: "11 years" },
  { label: " 12 years", value: "12 years" },
  { label: " 13 years", value: "13 years" },
  { label: " 14 years", value: "14 years" },
  { label: " 15 years", value: "15 years" },
  { label: "15+ years", value: "15+ years" }
];

const DateInput = styled(InputBase)({
  fontFamily: "ProximaNova-Regular",
  minHeight: "50px",
  paddingLeft: !locallanguage? "20px" : "",
  paddingRight: locallanguage? "15px" : "",
  fontSize: "14px",
  '& .MuiInputBase-input': {
    "&::placeholder": {
      fontSize: "17px"
    }
  }
});

const CustomInputBase = styled(InputBase)({
  fontFamily: "ProximaNova-Regular",
  border: "1px solid #d6d6d6",
  borderRadius: "10px",
  minHeight: "50px",
  paddingLeft: !locallanguage? "20px" : "",
  paddingRight: locallanguage? "15px" : "",
  fontSize: "15px",
  '& .MuiInputBase-input': {
    "&::placeholder": {
      fontSize: "17px"
    }
  }
});

const RedButton = styled(Button)({
  backgroundColor: "#f94b4b",
  color: "white",
  borderRadius: "10px",
  fontFamily: "ProximaNova-Semibold",
  fontSize: "20px",
  textTransform: "none",
  padding: "12px 25px",
  "&:hover": {
    color: "white",
    backgroundColor: "#f94b4b"
  },
  "@media(max-width:600px)": {
    width: "100%",
    marginTop: "10px"
  },
  "@media(max-width:992px)": {
    fontSize: "18px",
    padding: "10px 20px"
  }
});

const BasicStyle = styled('div')({
  "& .CountrySelect":{
    '& .react-tel-input':{
      display:"flex",
      flexDirection:"row-reverse",
      '& .flag-dropdown ':{
        width:"50px",
        "& .selected-flag":{
          '& .flag':{
            left:"0px"
          }
        }
      }
    }
  },
  "& .widthDob":{
    "& .react-datepicker-wrapper":{
      width:"100%"
    }
  }
})

// Customizable Area End

