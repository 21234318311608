import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { toast } from "react-toastify";
import moment from "moment";
// Customizable Area End


export interface Props {
  id?: string;
  navigation?: any;
  location: any;
  history: any;
  match: any;
  // Customizable Area Start
  t: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  data: any[];
  filterData: any[];
  openModal: boolean;
  openURLModal: boolean;
  URLmodal: boolean;
  EditModal: boolean;
  dynamicUrl: any;
  rederectUser: any;
  clientName: any;
  clientEmail: any;
  clientUserName: string;
  clientPassword: any;
  clientNameError: any;
  clientEmailError: any;
  clientUserNameError: any;
  clientPasswordError: any;
  loader: boolean;
  page: any;
  rowsPerPage: any;
  search: any;
  deleteModel: any;
  deleteModelText: any;
  SlotModel: any;
  SelectedDate: any;
  clientDetails: any;
  questionImage: any;
  AllSolts: any[];
  AvailableSlot: any;
  RemoveSlots: any;
  SlotUpdateApiCall: any;
  BookSlotIds: any[];
  AlertModel: boolean;
  AlertText: any;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class UnavailabilityCalanderController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  addClientCallId: string = "";
  getURLdataAPICall = "string";
  Tabledata: any = "";
  Userdelete: any = "";
  updateClientAPICall: string = "";
  SlotDataAPi: string = "";
  AssignSlots: string = "";
  t: any;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.PostDetailDataMessage),
    ];

    this.state = {
      data: [],
      filterData: [],
      openModal: false,
      openURLModal: false,
      URLmodal: false,
      dynamicUrl: "",
      rederectUser: "",
      clientName: "",
      clientEmail: "",
      clientUserName: "",
      clientPassword: "",
      clientNameError: "",
      clientEmailError: "",
      clientUserNameError: "",
      clientPasswordError: "",
      loader: true,
      page: 0,
      rowsPerPage: 20,
      search: "",
      SlotModel: false,
      deleteModel: false,
      deleteModelText: [],
      EditModal: false,
      clientDetails: null,
      questionImage: "",
      SelectedDate: "",
      AllSolts: [],
      AvailableSlot: [],
      RemoveSlots: [],
      SlotUpdateApiCall: false,
      BookSlotIds: [],
      AlertModel: false,
      AlertText: "",
    };
    this.t = this.props.t;
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  // Customizable Area Start
  async componentDidMount(): Promise<void> {
    return this.getTable();
  }

  // Customizable Area End

  // Customizable Area Start
  async receive(from: string, message: Message) {
    if (
      this.addClientCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) 

    if (
      this.updateClientAPICall ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      toast.success(
        this.t("UnavailabilityCalander.ToastMessage.Clientupdated")
      );
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.Tabledata) {
        this.setState((e) => ({
          ...e,
          data: responseJson.data,
          filterData: responseJson.data,
          loader: false,
        }));
      } else if (apiRequestCallId === this.SlotDataAPi) {
        let filterSlots = responseJson.data
          .filter(
            (item: any) =>
              item.attributes.available.includes("available") ||
              item.attributes.available.includes("booked")
          ).filter((items: any) => (items.attributes.available.toString() === "offline-booked") === false)
          .map((items: any) => items.attributes.id);
        this.setState({
          ...this.state,
          AllSolts: responseJson.data,
          AvailableSlot: filterSlots,
        });
      } else if (apiRequestCallId === this.AssignSlots) {
        this.setState({
          ...this.state,
          BookSlotIds: [],
          SelectedDate: "",
          SlotModel: false,
          RemoveSlots: [],
          AlertModel: true,
          AlertText: responseJson.meta.message,
          SlotUpdateApiCall: false,
        });
      }
    }
  }

  // Customizable Area End

  // Customizable Area Start

  getTable = () => {
    this.getTableData();
  };

  getTableData = () => {
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("accesToken"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.Tabledata = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/account_block/assessors/"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  SlotOpen = (date: any) => {
    this.setState({
      ...this.state,
      SlotModel: true,
      SelectedDate: date.dateStr,
    });
    this.GetSlotData();
  };

  GetSlotData = () => {
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("accesToken"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.SlotDataAPi = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/account_block/assessors/${
        this.props.location.state.id
      }?slot_date=${moment(this.state.SelectedDate).format("YYYY-MM-DD")}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  async AssignSlotsApiCall() {
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("accesToken"),
    };

    const httpBody = {
      id: this.props.location.state.id,
      remove_assessor_slot_id: this.state.RemoveSlots,
      assign_assessor_slot_id: this.state.BookSlotIds,
      slot_date: moment(this.state.SelectedDate).format("YYYY-MM-DD"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.AssignSlots = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/account_block/assessors/offline_assessor_date"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // Customizable Area End
}
