// Customizable Area Start
import React from "react";
import {
  Box,
  Button,
  Grid,
  TextField,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  InputBase,
  DialogContent,
  Dialog,
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import { styled } from "@material-ui/styles";
import SaveIcon from "@material-ui/icons/Save";
import SearchIcon from "@material-ui/icons/Search";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import Select from "react-select";
import CloseIcon from "@material-ui/icons/Close";
import AssessorLeadToolsContoller, {
  Props,
} from "./AssessorLeadToolsController.web";
import AppSidebar from "../../../../components/src/AppSidebar.web";
import Spinner from "../../../../components/src/Spinner.web";
const LanguageData = localStorage.getItem("language") === "ar";

const editIcon = require("../../assets/edit.png");
const deleteIcon = require("../../assets/delete.png");

class AssessorLeadTools extends AssessorLeadToolsContoller {
  constructor(props: Props) {
    super(props);
  }

  renderStatusComponent = (item: any) => {
    let element = <CompletedStatus>{item.status}</CompletedStatus>;
    if (item.status === "On Going") {
      element = <OnGoingStatus>{item.status}</OnGoingStatus>;
    } else if (item.status === "Yet to be started") {
      element = <NotStartedStatus>{item.status}</NotStartedStatus>;
    }
    return element;
  };

  renderTool = (tool: any, index: any) => {
    let element = (
      <img
        src={tool.image}
        alt="not found!"
        style={{ height: "30px", width: "30px" }}
      />
    );
    if (tool.value === this.state.editToolId && tool.image) {
      element = (
        <Box
          textAlign="center"
          width="100%"
          border="1px solid #bebebe"
          borderRadius="8px"
        >
          <Box p="7px 0" color="red" fontSize="18px" whiteSpace="nowrap">
            <Box
              pl="11px"
              fontSize="16px"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <span style={{ paddingRight: "15px" }}>{`logo${index}.jpg`}</span>
              <CloseIcon
                style={{ cursor: "pointer", paddingRight: "5px" }}
                onClick={() => this.onToolLogoChange(null, index)}
              />
            </Box>
          </Box>
        </Box>
      );
    } else if (tool.value === this.state.editToolId) {
      element = (
        <Box>
          <input
            type="file"
            accept="image/*"
            style={{ display: "none" }}
            id={`logo-${index}`}
            onChange={(event: any) => {
              this.onToolLogoChange(event.target.files[0], index);
            }}
          />
          <label htmlFor={`logo-${index}`}>
            <Box
              textAlign="center"
              width="100%"
              border="1px solid #bebebe"
              borderRadius="8px"
              style={{ cursor: "pointer" }}
            >
              <Box p="6px 11px" color="red" fontSize="18px" whiteSpace="nowrap">
                {this.t("assessorLeadTool.label.browseFile")}
              </Box>
            </Box>
          </label>
        </Box>
      );
    }
    return element;
  };

  TableBodyAssosser = (item: any, index: any, element: any) => {
    return (
      <TableRow key={`${index + 1}`}>
        <TableCell
          style={{
            fontFamily: "ProximaNova-Regular",
            fontSize: "18px",
            color: "#000",
          }}
        >
          <Box style={{ cursor: "pointer" }}>
            <Box display="flex">
              <span style={{display: 'block',maxWidth: "140px",whiteSpace: 'nowrap',overflow: 'hidden',textOverflow: 'ellipsis'}} >{item.project}</span>
            </Box>
          </Box>
        </TableCell>
        <TableCell
          style={{
            fontFamily: "ProximaNova-Regular",
            fontSize: "18px",
            color: "#000",
            whiteSpace: "nowrap",
          }}
        >
          {item.toolUsed}
        </TableCell>
        <TableCell
          style={{
            fontFamily: "ProximaNova-Regular",
            fontSize: "18px",
            color: "#000",
            whiteSpace: "nowrap",
          }}
        >
          <Box display="flex">{element}</Box>
        </TableCell>
        <TableCell
          style={{
            fontFamily: "ProximaNova-Regular",
            fontSize: "18px",
            color: "#000",
            whiteSpace: "nowrap",
          }}
        >
          {item.completion_status}
        </TableCell>
        <TableCell
          style={{
            fontFamily: "ProximaNova-Regular",
            fontSize: "18px",
            color: "#000",
            whiteSpace: "nowrap",
            textTransform: "capitalize"
          }}
        >
          {item.type}
        </TableCell>
        <TableCell
          style={{
            fontFamily: "ProximaNova-Regular",
            fontSize: "18px",
            color: "#000",
          }}
        >
          {item.DCManager}
        </TableCell>
      </TableRow>
    )
  }

  AddAndEditDilogStateModel = () => {
    return (
      <Dialog
        open={this.state.AddAndEditDilogState}
        fullWidth={false}
        maxWidth={"md"}
        scroll="body"
        onClose={this.AddAndEditDilog}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogContent style={{ padding: "0px" }}>
          <Box
            style={{
              paddingTop: "0px !important",
              textAlign: "right",
              margin: "10px 10px 0px 0px",
            }}
          >
            <CloseIcon
              onClick={this.AddAndEditDilog}
              style={{
                cursor: "pointer",
                fontSize: "35px",
                color: "#787878",
              }}
            />
          </Box>
          <Box padding={{ xs: "15px 25px 25px", sm: "15px 40px 40px" }}>
            <Box
              mb="20px"
              display={{ xs: "block", sm: "flex" }}
              justifyContent="space-between"
              alignItems="center"
            >
              <Box
                id="modal-title"
                fontWeight="bolder"
                mr="42px"
                fontSize={{ xs: "20px", sm: "30px" }}
                fontFamily="ProximaNova-Bold"
              >
                {this.t("assessorLeadTool.title.addEdit")}
              </Box>
              <Box
                mt={{ xs: "10px", sm: "0px" }}
                style={{ color: "#f26a42" }}
              >
                <span
                  onClick={this.addToolRecord}
                  style={{ cursor: "pointer" }}
                >
                  {" "}
                  {this.t("assessorLeadTool.link.addTool")}
                </span>
              </Box>
            </Box>
            <Box>
              <Grid container>
                <Grid item xs={12} style={{ overflow: "auto" }}>
                  <Table
                    aria-label="simple table"
                    style={{
                      border: "1px solid #E0E0E0",
                      borderRadius: "10px",
                      borderCollapse: "separate",
                    }}
                    className="custom-table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell
                          align="left"
                          style={{
                            fontFamily: "ProximaNova-Bold",
                            fontSize: "16px",
                          }}
                        >
                          {this.t("assessorLeadTool.label.logo")}
                        </TableCell>
                        <TableCell
                          style={{
                            fontFamily: "ProximaNova-Bold",
                            fontSize: "16px",
                          }}
                        >
                          {this.t("assessorLeadTool.label.tool")}
                        </TableCell>
                        <TableCell
                          style={{
                            fontFamily: "ProximaNova-Bold",
                            fontSize: "16px",
                          }}
                        >
                          {this.t(
                            "assessorLeadTool.label.shortDescription"
                          )}
                        </TableCell>
                        <TableCell
                          style={{
                            fontFamily: "ProximaNova-Bold",
                            fontSize: "16px",
                          }}
                        >
                          {this.t("assessorLeadTool.label.action")}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.onlineToolList.length > 0 ? (
                        <>
                          {this.state.onlineToolList.map(
                            (tool: any, index: number) => {
                              let element = this.renderTool(tool, index);
                              return (
                                <TableRow key={`${index + 1}`}>
                                  <TableCell
                                    align="left"
                                    style={{
                                      fontFamily: "ProximaNova-Regular",
                                      fontSize: "18px",
                                      color: "#000",
                                    }}
                                  >
                                    {element}
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      fontFamily: "ProximaNova-Regular",
                                      fontSize: "18px",
                                      color: "#000",
                                      width: "200px",
                                    }}
                                  >
                                    {tool.value ===
                                      this.state.editToolId ? (
                                      <OnlineToolInputBase
                                        autoFocus
                                        value={tool.label}
                                        placeholder={this.t(
                                          "assessorLeadTool.placeholder.tool"
                                        )}
                                        inputRef={(input) =>
                                          (this.inputRef = input)
                                        }
                                        onChange={(event: any) =>
                                          this.onToolNameChange(
                                            event,
                                            index
                                          )
                                        }
                                      />
                                    ) : (
                                      tool.label
                                    )}
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      fontFamily: "ProximaNova-Regular",
                                      fontSize: "18px",
                                      color: "#000",
                                      width: "350px",
                                    }}
                                  >
                                    {tool.value ===
                                      this.state.editToolId ? (
                                      <OnlineToolInputBase
                                        multiline
                                        value={tool.description}
                                        placeholder={this.t(
                                          "assessorLeadTool.placeholder.description"
                                        )}
                                        onChange={(event: any) =>
                                          this.onToolDescriptionChange(
                                            event,
                                            index
                                          )
                                        }
                                      />
                                    ) : (
                                      tool.description
                                    )}
                                  </TableCell>
                                  <TableCell
                                    align="right"
                                    style={{
                                      fontFamily: "ProximaNova-Regular",
                                      fontSize: "18px",
                                      color: "#000",
                                    }}
                                  >
                                    {tool.value ===
                                      this.state.editToolId && (
                                        <SaveIcon
                                          onClick={() =>
                                            this.onSaveTool(tool.value)
                                          }
                                          style={{
                                            cursor: "pointer",
                                            color: "#736d6d",
                                          }}
                                        />
                                      )}
                                    {tool.value &&
                                      tool.value !==
                                      this.state.editToolId && (
                                        <img
                                          id="editBtn"
                                          src={editIcon}
                                          style={{ cursor: "pointer" }}
                                          onClick={() =>
                                            this.onEditButtonClick(
                                              tool,
                                              index
                                            )
                                          }
                                        />
                                      )}
                                    {tool.value !==
                                      this.state.editToolId && (

                                        <img
                                          id="deleteBtn"
                                          src={deleteIcon}
                                          style={{
                                            marginLeft: "15px",
                                            cursor: "pointer",
                                          }}
                                          onClick={() =>
                                            this.onDeleteToolButtonClick(tool)
                                          }
                                        />
                                      )}
                                  </TableCell>
                                </TableRow>
                              );
                            }
                          )}
                        </>
                      ) : (
                        <TableRow>
                          <TableCell
                            colSpan={6}
                            align="center"
                            style={{
                              fontFamily: "ProximaNova-Regular",
                              fontSize: "18px",
                              color: "#000",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {this.t("assessorLeadTool.label.notFound")}
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </Grid>
              </Grid>
            </Box>
            <Box mt="20px" display="flex" justifyContent="end">
              <CustomButton
                onClick={this.AddAndEditDilog}
                style={{
                  marginRight: "0px",
                  marginBottom: "0px",
                  padding: "5px 10px",
                }}
              >
                {this.t("assessorLeadTool.button.cancel")}
              </CustomButton>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    )
  }

  render() {
    return (
      <StyleAssessorLeadTools style={{ fontFamily: "ProximaNova-Regular" }}>
        {this.state.loading && <Spinner spinnerModal={this.state.loading} />}
        <AppSidebar type="admin">
          <Box margin="35px 0px" mr="30px">
            <Box
              display={{ xs: "block", md: "flex" }}
              justifyContent="space-between"
              mb="40px"
            >
              <Box>
                <Box
                  mb="5px"
                  fontFamily="ProximaNova-Bold"
                  fontSize={{ xs: "24px", sm: "30px", md: "44px" }}
                  color="#1b3c69"
                  id="assessorLeadTool.title.mainidNew"
                >
                  {this.t("assessorLeadTool.title.main")}
                </Box>
                <Box color="#1b3c69" fontSize={{ xs: "14px", sm: "20px" }}>
                  {this.t("assessorLeadTool.description.main")}
                </Box>
              </Box>
              <Box mt={{ xs: "20px" }}>
                <CustomButton onClick={this.AddAndEditDilog}>
                  {this.t("assessorLeadTool.button.addEdit")}
                </CustomButton>
              </Box>
            </Box>
            <Box style={{ borderRadius: "10px", backgroundColor: "#eff6ff" }}>
              <Grid
                container
                spacing={2}
                style={{ alignItems: "end", padding: "20px 15px 10px 15px" }}
              >
                <Grid item xs={12} sm={6} md={5}>
                  <Box style={{ color: "#1b3c69" }}>
                    <div id="assessorLeadTool.title.filterBy" style={{ marginBottom: "5px", fontSize: "18px" }}>
                      {this.t("assessorLeadTool.label.filterBy")}
                    </div>
                    <Select
                      autofocus={true}
                      openOnFocus={true}
                      classNamePrefix="react-select"
                      placeholder={this.t(
                        "assessorLeadTool.placeholder.filterBy"
                      )}
                      isSearchable={false}
                      isClearable={true}
                      options={filterByList}
                      value={
                        filterByList.filter(
                          (option: any) => option.value === this.state.filterBy
                        )[0]
                      }
                      onChange={(event: any) => {
                        this.onFilterByValueChange(event);
                      }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={5}>
                  <Box style={{ color: "#1b3c69" }}>
                    <div style={{ marginBottom: "5px", fontSize: "18px" }}>
                      {this.t("assessorLeadTool.label.selectOption")}
                    </div>
                    <Select
                      classNamePrefix="react-select"
                      placeholder={this.t(
                        "assessorLeadTool.placeholder.selectOption"
                      )}
                      isSearchable={true}
                      isClearable={true}
                      options={this.state.optionList}
                      value={
                        this.state.optionList.filter(
                          (option: any) => option?.label === this.state.option
                        )[0] ?? []
                      }
                      onChange={(event: any) => {
                        this.setState({ option: event !== null ? event.label : "" });
                      }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                  <Box>
                    <RedButton id="assessorLeadTool.button.apply" onClick={this.onApplyButtonClick}>
                      {this.t("assessorLeadTool.button.apply")}
                    </RedButton>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box mt="60px">
              <Box id="assessorLeadTool.button.applyNBox">
                <Grid container>
                  <Grid item xs={12} className={LanguageData ? "SearchIconRight" : ""}>
                    <Box
                      mb="25px"
                      display="flex"
                      justifyContent="end"
                      alignItems="center"
                    >
                      <TextField
                        id="search"
                        InputLabelProps={{
                          style: {
                            fontFamily: "ProximaNova-Regular",
                            zIndex: 0,
                          },
                        }}
                        onChange={(event: any) => {
                          this.onChangeHandler(event.target.value, 0);
                        }}
                        label={this.t("assessorLeadTool.label.search")}
                        variant="outlined"
                        InputProps={{
                          endAdornment: <SearchIcon />,
                        }}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} style={{ overflow: "auto" }}>
                    <Table
                      aria-label="simple table"
                      style={{
                        border: "1px solid #E0E0E0",
                        borderRadius: "10px",
                        borderCollapse: "separate",
                      }}
                      className="custom-table"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell
                            id="assessorLeadTool.label.project"
                            style={{
                              fontFamily: "ProximaNova-Bold",
                              fontSize: "16px",
                            }}
                          >
                            {this.t("assessorLeadTool.label.project")}
                          </TableCell>
                          <TableCell
                            id="assessorLeadTool.label.name"
                            style={{
                              fontFamily: "ProximaNova-Bold",
                              fontSize: "16px",
                            }}
                          >
                            {this.t("assessorLeadTool.label.name")}
                          </TableCell>
                          <TableCell
                            id="assessorLeadTool.label.status"
                            style={{
                              fontFamily: "ProximaNova-Bold",
                              fontSize: "16px",
                            }}
                          >
                            {this.t("assessorLeadTool.label.status")}
                          </TableCell>
                          <TableCell
                            id="assessorLeadTool.label.completionStatus"
                            style={{
                              fontFamily: "ProximaNova-Bold",
                              fontSize: "16px",
                            }}
                          >
                            {this.t("assessorLeadTool.label.completionStatus")}
                          </TableCell>
                          <TableCell
                            id="assessorLeadTool.label.type"
                            style={{
                              fontFamily: "ProximaNova-Bold",
                              fontSize: "16px",
                            }}
                          >
                            {this.t("assessorLeadTool.label.type")}
                          </TableCell>
                          <TableCell
                            id="assessorLeadTool.label.dcManager"
                            style={{
                              fontFamily: "ProximaNova-Bold",
                              fontSize: "16px",
                            }}
                          >
                            {this.t("assessorLeadTool.label.dcManager")}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.state.data.length > 0 ? (
                          <>
                            {this.state.data.map((item: any, index: number) => {
                              let element = this.renderStatusComponent(item);
                              return (
                                this.TableBodyAssosser(item, index, element)
                              );
                            })}
                          </>
                        ) : (
                          <TableRow>
                            <TableCell
                              colSpan={6}
                              align="center"
                              style={{
                                fontFamily: "ProximaNova-Regular",
                                fontSize: "18px",
                                color: "#000",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {this.t("assessorLeadTool.label.notFound")}
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                    {this.state.data.length > 0 && (
                      <div className="custom-pagination">
                        <Pagination
                          count={Math.ceil(
                            this.state.dataLength / this.state.rowsPerPage
                          )}
                          onChange={(event: unknown, newPage: number) => {
                            this.onChangeHandler(
                              this.state.search,
                              newPage - 1
                            );
                          }}
                          dir="ltr"
                          variant="outlined"
                          shape="rounded"
                          showFirstButton
                          showLastButton
                          siblingCount={0}
                          boundaryCount={1}
                          page={this.state.page === 0 ? 1 : this.state.page + 1}
                        />
                      </div>
                    )}
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>
          {this.AddAndEditDilogStateModel()}
          <DeleteDialog
            open={this.state.DeleteModel}
            fullWidth={false}
            maxWidth={"sm"}
            scroll="body"
            onClose={this.onDeleteToolButtonClick}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
          >
            <DialogContent style={{ padding: "0px", maxWidth: "500px" }}>
              <Box
                style={{
                  paddingTop: "0px !important",
                  textAlign: "right",
                  margin: "10px 10px 0px 0px",
                }}
              >
                <CloseIcon
                  onClick={this.onDeleteToolButtonClick}
                  style={{
                    cursor: "pointer",
                    fontSize: "35px",
                    color: "#787878",
                  }}
                />
              </Box>
              <Box
                display="flex"
                alignItems="center"
                flexDirection="column"
                padding={{ xs: "15px 25px 25px", sm: "15px 70px 40px" }}
              >
                <Box
                  id="modal-title"
                  fontWeight="bolder"
                  fontSize={{ xs: "20px", sm: "30px" }}
                  fontFamily="ProximaNova-Bold"
                  mb="20px"
                >
                  {this.t("assessorLeadTool.title.delete")}
                </Box>
                <Box textAlign="center" className="centerText" fontSize="18px">
                  {this.t("assessorLeadTool.description.delete", {
                    tool: this.state.deleteTool?.label,
                  })}
                </Box>
                <Box mt="30px" display="flex">
                  <CustomButton
                    onClick={this.onDeleteToolButtonClick}
                    style={{ marginBottom: "0px", padding: "5px 10px" }}
                  >
                    {this.t("assessorLeadTool.button.cancel")}
                  </CustomButton>
                  <RedButton
                    onClick={this.deleteTool}
                    style={{ padding: "5px 10px" }}
                  >
                    {this.t("assessorLeadTool.button.delete")}
                  </RedButton>
                </Box>
              </Box>
            </DialogContent>
          </DeleteDialog>
        </AppSidebar>
      </StyleAssessorLeadTools>
    );
  }
}

export default withRouter(withTranslation()(AssessorLeadTools));

const DeleteDialog = styled(Dialog)({
  margin: "0px 61px",
  "@media(max-width:576px)": {
    margin: "0px",
  },
});

const StyleAssessorLeadTools = styled('div')({
  '& .SearchIconRight': {
    '& .MuiInputBase-root': {
      display: "flex",
      flexDirection: "row-reverse"
    }
  }
})

const OnlineToolInputBase = styled(InputBase)({
  fontFamily: "ProximaNova-Regular",
  border: "1px solid #bebebe",
  borderRadius: "8px",
  minHeight: "40px",
  width: "100%",
  paddingLeft: "9px",
  fontSize: "18px",
  "& .MuiInputBase-inputMultiline": {
    "&::placeholder": {
      fontSize: "16px",
    },
  },
});

const NotStartedStatus = styled(Box)({
  backgroundColor: "#ffe1a6",
  width: "fit-content",
  color: "#288a0a",
  fontFamily: "ProximaNova-Regular",
  borderRadius: "25px",
  fontSize: "18px",
  padding: "3px 20px",
});

const OnGoingStatus = styled(Box)({
  backgroundColor: "#f5d3d3",
  width: "fit-content",
  color: "#cd1414",
  fontFamily: "ProximaNova-Regular",
  borderRadius: "25px",
  fontSize: "18px",
  padding: "3px 20px",
});

const CompletedStatus = styled(Box)({
  backgroundColor: "#dff8d2",
  width: "fit-content",
  color: "#288a0a",
  fontFamily: "ProximaNova-Regular",
  borderRadius: "25px",
  fontSize: "18px",
  padding: "3px 20px",
});

const RedButton = styled(Button)({
  backgroundColor: "#f94b4b",
  color: "white",
  borderRadius: "10px",
  fontFamily: "ProximaNova-Semibold",
  fontSize: "18px",
  textTransform: "none",
  padding: "9px 25px",
  "&:hover": {
    color: "white",
    backgroundColor: "#f94b4b",
  },
  "@media(max-width:600px)": {
    width: "100%",
    marginTop: "27px",
  },
  "@media(max-width:992px)": {
    padding: "8px 15px",
  },
});

const CustomButton = styled(Button)({
  backgroundColor: "white",
  color: "#f26a42",
  border: "1px solid #f26a42",
  fontFamily: "ProximaNova-Semibold",
  marginRight: "15px",
  borderRadius: "8px",
  fontSize: "18px",
  textTransform: "none",
  padding: "8px 25px",
  "&:hover": {
    color: "#f26a42",
    backgroundColor: "white",
  },
  "@media(max-width:600px)": {
    width: "100%",
  },
  "@media(max-width:992px)": {
    padding: "7px 15px",
  },
});

const filterByList = [
  { label: "All", value: "all" },
  { label: "Status", value: "status" },
  { label: "Projects", value: "project" },
];
// Customizable Area End