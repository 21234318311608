// Customizable Area Start
import React from "react";
import "./adminLogin.web.css";
import { logo, India, UAE, UK } from "../src/assets";
import FormControl from "@material-ui/core/FormControl";
import {
  Button,
  TextField,
  Grid,
  Paper,
  Typography,
  Box,
} from "@material-ui/core";
import { Props } from "./adminLoginController";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

import {Link} from 'react-router-dom'
import { withTranslation } from "react-i18next";
import adminControllerLogin from './adminControllerLogin'
import AsyncSelect from "react-select";
const Alert = (props : any) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}


class AdminLogin extends adminControllerLogin {
  constructor(props: Props) {
    super(props);
  }
  onLanguageChange = (language: string) => {
    this.setState({ selected: language });
    localStorage.setItem("language", language);
    this.props.i18n.changeLanguage(language);
    window.location.reload()
  }

  render() {
    const emailregsx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return (
      <Box className="page">
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            margin: "1.2rem 3.5rem",
          }}
        >
          <Box>
            <img src={logo} alt="logo" />
          </Box>
          <Box className="select-icon" >
            <FormControl style={{ width:"150px" }}>
              <AsyncSelect
                classNamePrefix="react-select"
                options={languageList}
                isSearchable={false}
                value={
                  languageList.filter(
                    (option: any) =>
                      option.value === this.state.selected
                  )[0]
                }
                formatOptionLabel={(country: any) => {
                  return (
                    <div className="country-option" style={{ display: "flex", alignItems: "center" }}>
                      <img src={country.flag} width="20px" height="20px" alt="india" />
                      <p style={{ fontSize: "16px",fontFamily: "ProximaNova-Semibold", marginLeft: "5px", marginRight: "5px" }}>{country.label}</p>
                    </div>
                  )
                }}
                onChange={(event: any) => {
                  this.onLanguageChange(event.value);
                }}
              />
            </FormControl>
          </Box>
        </Box>

        <Grid
          className="login-outer"
          container
          spacing={0}
          justify="center"
          direction="row"
        >
          <Grid item>
            <Grid
              container
              direction="column"
              justify="center"
              spacing={2}
              className="login-form"
            >
              <Paper
                variant="elevation"
                elevation={2}
                className="login-background"
              >
                <Grid item>
                  <Typography
                    className="form-heading"
                    component="h1"
                    variant="h5"
                    style={{textAlign:"center"}}
                  >
                    
                    {this.t("adminLogin.title")} 
                  </Typography>
                </Grid>
                <Grid item>
                  <form onSubmit={(e) => this.handleLogin(e)}>
                    <Grid container direction="column" spacing={2}>
                      <Grid item className="fields">
                        <label style={{fontSize:"18px"}} className="labels">{this.t("adminLogin.username")} <span style={{color:"#f94b4b"}}>*</span></label>
                        <TextField
                          className="text"
                          type="email"
                          fullWidth
                          name="username"
                          inputProps={{style: {fontFamily:"ProximaNova-Semibold",fontSize:"18px"}}}
                          variant="outlined"
                          value={this.state.userName}
                          onChange={(e) => this.handleUserEmail(e)}
                          required
                          autoFocus
                          error={this.state.errorUserName && this.state.userName === "" || emailregsx.test(this.state.userName)}
                          helperText={this.state.errorUserName && this.state.userName === "" || emailregsx.test(this.state.userName) ? this.t(this.state.ErrorUserText) : false}
                        />
                      </Grid>
                      <Grid item className="fields">
                        <label className="labels">{this.t("adminLogin.password")} <span style={{color:"#f94b4b"}}>*</span></label>
                        <TextField
                          className="text"
                          value={this.state.pass}
                          onChange={(e) => this.handleUserPass(e)}
                          type="password"
                          fullWidth
                          inputProps={{style: {fontFamily:"ProximaNova-Semibold",fontSize:"18px"}}}
                          name="password"
                          variant="outlined"
                          helperText={this.state.errorPassword ? this.t(this.state.ErrorPasswordText) : false}
                          error={this.state.errorPassword}
                          // value={this.state.password}
                          // onChange={(event) =>
                          // this.setState({
                          // [event.target.name]: event.target.value,
                          // })
                          // }
                          required
                        />
                      </Grid>
                      {/* <Snackbar
                        open={open}
                        autoHideDuration={6000}
                        onClose={handleClose}
                      >
                        <Alert onClose={handleClose} severity="success">
                          This is a success message!
                        </Alert>
                      </Snackbar> */}
                      <Grid item>
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                          className="button-block"
                          onClick={(e) => this.handleLogin(e)}
                          style={{fontSize:"22px"}}
                        >
                          {this.t("adminLogin.button.login")}
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                </Grid>
                <Grid item className="forgot-password">
                  
                    <Link className="forgotPassword" to="EmailAccountForgotPassword" onClick={() => {
localStorage.setItem('forgateLink',JSON.stringify('admin'))
      }}>
                    {this.t("adminLogin.link.forgotPassword")}
                    </Link>
                  
                </Grid>
              </Paper>
              <Typography className="footer-text" dir="ltr">
                {this.state.copyright}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Snackbar open={this.state.snakbarAlert}  anchorOrigin={{
    vertical: 'bottom',
    horizontal: 'left',
  }}>
     <Alert className='close-id' onClose={()=> this.setState({
      ...this.state, snakbarAlert:false
     })} severity="error" style={{height:"46px"}}>
{this.state.snakbarText}
</Alert>
      </Snackbar>
      </Box>
    );
    
  }
}

const languageList = [
  { label: "English", value: "en", flag:UK },
  { label: "Hindi", value: "hi", flag:India },
  { label: "Arabic", value: "ar", flag:UAE },
];

const LoginAdmin = withTranslation()(AdminLogin);
export { LoginAdmin };

export default AdminLogin
// Customizable Area End