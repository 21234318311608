// Customizable Area Start
import React from "react";
import Button from "@material-ui/core/Button";
import AppSidebar from "../../../components/src/AppSidebar.web";
import Modal from "@material-ui/core/Modal";
import Box from "@material-ui/core/Box";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction"; // needed for dayClick
import { Grid, Snackbar, CircularProgress } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { styled } from "@material-ui/styles";
import { withRouter } from "react-router-dom";
import "./ManageClient.web.css";
import { withTranslation } from "react-i18next";
import UnavailabilityCalanderController, {
  Props,
} from "./UnavailabilityCalanderController.web";
import Spinner from "../../../components/src/Spinner.web";
import MuiAlert from "@material-ui/lab/Alert";
import timeGridPlugin from '@fullcalendar/timegrid';
import moment from "moment";

const Alert = (props: any) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};
class UnavailabilityCalander extends UnavailabilityCalanderController {
  constructor(props: Props) {
    super(props);
  }
  handleChange = (e: any, item: any) => {
    if (e.target.checked === false) {
      this.setState({
        ...this.state,
        AvailableSlot: this.state.AvailableSlot.filter(
          (items: any) =>
            items !== item.attributes.id
        ),
        RemoveSlots: this.state.RemoveSlots.concat(
          item.attributes.id
        ),
        SlotUpdateApiCall: true,
      });
    } else {
      this.setState({
        ...this.state,
        BookSlotIds: this.state.BookSlotIds.concat(
          item.attributes.id
        ),
        RemoveSlots: this.state.RemoveSlots.filter(
          (items: any) =>
            items !== item.attributes.id
        ),
        AvailableSlot: this.state.AvailableSlot.concat(
          item.attributes.id
        ),
        SlotUpdateApiCall: true,
      });
    }
  }
  checkboxHandleChange = (e: any, item: any) => {
    if (e.target.checked === false) {
      this.setState({
        ...this.state,
        AvailableSlot: this.state.AvailableSlot.filter(
          (items: any) => items !== item.attributes.id
        ),
        SlotUpdateApiCall: true,
        RemoveSlots: this.state.RemoveSlots.concat(
          item.attributes.id
        ),
      });
    } else {
      this.setState({
        ...this.state,
        BookSlotIds: this.state.BookSlotIds.concat(
          item.attributes.id
        ),
        SlotUpdateApiCall: true,
        RemoveSlots: this.state.RemoveSlots.filter(
          (items: any) => items !== item.attributes.id
        ),
        AvailableSlot: this.state.AvailableSlot.concat(
          item.attributes.id
        ),
      });
    }
  }
  renderGrid = (item: any, ColorChnage: any, Starttime: any, EndTime: any, index: any) => {
    return (
      <Grid item xs={6} key={item.id}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
          className={ColorChnage}
        >
          <input
            disabled={
              Number(
                moment(item.attributes.start_time)
                  .utc()
                  .format("H")
              ) < Number(moment().format("H")) &&
              moment(this.state.SelectedDate).format(
                "DD-MM-YYYY"
              ) === moment().format("DD-MM-YYYY")
            }
            checked={this.state.AvailableSlot.includes(
              item.attributes.id
            )}
            data-testid={`input${index}`}
            type="checkbox"
            onClick={(e: any) => {
              this.handleChange(e, item)
            }}
            id={item.id}
            name={item.id}
            style={{ margin:"0px 5px" }}
            className="CheckBox"
          />
          <label htmlFor={item.id} className="SlotTime">
            <Button
              disabled={
                Number(
                  moment(item.attributes.start_time)
                    .utc()
                    .format("H")
                ) < Number(moment().format("H")) &&
                moment(this.state.SelectedDate).format(
                  "DD-MM-YYYY"
                ) === moment().format("DD-MM-YYYY")
              }
              fullWidth
              variant="outlined"
              component="span"
              dir="ltr"
            >
              {Starttime} to {EndTime}{" "}
            </Button>
          </label>
        </div>
      </Grid>
    )
  }
  renderInput = (item: any, index: any) => {
    return (
      <input
        data-testid={`input${index}`}
        onClick={(e: any) => {
          this.checkboxHandleChange(e, item)

        }}
        disabled={
          Number(
            moment(item.attributes.start_time).format("hh")
          ) < Number(moment().format("hh")) &&
          moment(this.state.SelectedDate).format(
            "DD-MM-YYYY"
          ) === moment().format("DD-MM-YYYY")
        }
        checked={this.state.AvailableSlot.includes(
          item.attributes.id
        )}
        type="checkbox"
        id={item.id}
        name={item.id}
        className="CheckBox"
      />
    )
  }
  modelRender = () => {
    return (
      <Modal
        open={this.state.SlotModel}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <AssessorListStyle>
          <Box className="modalStyle" style={{ padding: "30px" }}>
            <Typography
              variant="h6"
              style={{ fontWeight: 600, fontSize: "30px" }}
              component="h1"
            >
              {this.t("UnavailabilityCalander.EditModeltitle")}
            </Typography>
            <Typography
              className=""
              variant="h6"
              component="h4"
              style={{
                paddingBottom: "10px",
                textTransform: "capitalize",
              }}
            >
              {moment(this.state.SelectedDate)
                .locale(`${localStorage.getItem("language") as any}`)
                .format(
                  `${localStorage.getItem("language") === "hi" ? "MMMM" : "MMM"
                  } D, YYYY`
                )}{" "}
              {moment(this.state.SelectedDate).format("dddd")}{" "}
              {this.props.location.state.full_name}
            </Typography>
            <Grid
              container
              spacing={2}
              style={{ overflowX: "auto", maxHeight: "300px" }}
            >
              {this.state.AllSolts.length > 0 ? (
                this.state.AllSolts.map((item: any, index: any) => {
                  let ColorChnage = `${item.attributes.available.includes("available")
                    ? "green"
                    : ""
                    } ${item.attributes.available.includes("booked") ? "red" : ""
                    }`;
                  return (
                    <Grid item xs={6} key={item.id}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                        className={ColorChnage}
                      >
                        {this.renderInput(item, index)}
                        <label htmlFor={item.id} className="SlotTime">
                          <Button fullWidth variant="outlined" component="span">
                            {moment(item.attributes.start_time).format(
                              "hh:mm a"
                            )}{" "}
                            to{" "}
                            {moment(item.attributes.end_time).format("hh:mm a")}{" "}
                          </Button>
                        </label>
                      </div>
                    </Grid>
                  );
                })
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    minHeight: "90px",
                    height: "100%",
                  }}
                >
                  <CircularProgress value={10} size={40} />
                </div>
              )}
            </Grid>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                data-test-id="cancelBtn"
                onClick={() => {
                  this.setState({ SlotModel: false, AllSolts: [] });
                }}
                className="cancelBtn"
              >
                {this.t("UnavailabilityCalander.Cancel")}
              </Button>
              <Button
                className="submitBtn"
                onClick={() => {
                  this.state.SlotUpdateApiCall
                    ? this.AssignSlotsApiCall()
                    : this.setState({ ...this.state, SlotModel: false, });
                }}>
                {this.t("UnavailabilityCalander.Yes")}
              </Button>
            </div>
          </Box>
        </AssessorListStyle>
      </Modal>
    );
  };
  render() {
    return (
      <AssessorListStyle className="manage-client" data-testid="Heading">
        {this.state.loader && <Spinner spinnerModal={this.state.loader} />}
        <AppSidebar type="admin">
          <div className="main_page">
            <div className="client_container">
              <div data-testid="UserName">
                <h4>{this.props.location.state.full_name}{" "}{this.t("UnavailabilityCalander.title")}</h4>
                <p>{this.t("UnavailabilityCalander.SubTitile")}</p>
              </div>
            </div>
            <div style={{ marginTop: "50px" }} data-testid="TableBodyData">
              <FullCalendar
                // @ts-ignore
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                //Dayclick open sweetalert
                headerToolbar={{
                  left: "today",
                  center: "prev title next",
                  right: "dayGridMonth,timeGridWeek,timeGridDay",
                }}
                locale={`${localStorage.getItem("language") ?? "en"}`}
                firstDay={1}
                initialView="dayGridMonth"
                validRange={{
                  start: moment().format("YYYY-MM-DD"),
                }}
                dateClick={(date: any) => this.SlotOpen(date)}
              />
            </div>
            <Modal
              open={this.state.SlotModel}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <AssessorListStyle>
                <Box className="modalStyle" style={{ padding: "30px" }}>
                  <Typography
                    variant="h6"
                    style={{ fontWeight: 600, fontSize: "30px" }}
                    component="h1"
                  >
                    {this.t("UnavailabilityCalander.EditModeltitle")}
                  </Typography>
                  <Typography
                    className=""
                    variant="h6"
                    component="h4"
                    style={{
                      paddingBottom: "10px",
                      textTransform: "capitalize",
                    }}
                  >
                    {moment(this.state.SelectedDate).format("MMMM Do, YYYY")}{" "}
                    {moment(this.state.SelectedDate).format("dddd")}{", "}
                    {this.props.location.state.full_name}
                  </Typography>
                  <Grid
                    container
                    spacing={2}
                    style={{ overflowX: "auto", maxHeight: "300px" }}
                  >
                    {this.state.AllSolts.length > 0 ? (
                      this.state.AllSolts.map((item: any, index: any) => {
                        let ColorChnage = `${item.attributes.available.toString() === "available"
                          ? "green"
                          : ""
                          } ${item.attributes.available.toString() === "booked"
                            ? "red"
                            : ""
                          }`;
                        let Starttime = moment(item.attributes.start_time)
                          .utc()
                          .format("hh:mm a");
                        let EndTime = moment(item.attributes.end_time)
                          .utc()
                          .format("hh:mm a");
                        return (
                          this.renderGrid(item, ColorChnage, Starttime, EndTime, index)
                        );
                      })
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "100%",
                          minHeight: "90px",
                          height: "100%",
                        }}
                      >
                        <p>{this.t("UnavailabilityCalander.NoSlot")}</p>
                      </div>
                    )}
                  </Grid>
                  <div
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <Button
                      data-testid="cancelBtn"
                      className="cancelBtn"
                      onClick={() => { this.setState({ SlotModel: false, AllSolts: [] }); }}
                    >
                      {this.t("UnavailabilityCalander.Cancel")}
                    </Button>
                    <Button
                      className="submitBtn"
                      onClick={() => {
                        this.state.SlotUpdateApiCall
                          ? this.AssignSlotsApiCall() : this.setState({
                            ...this.state,
                            SlotModel: false,
                          });
                      }}
                    >
                      {this.t("UnavailabilityCalander.Yes")}
                    </Button>
                  </div>
                </Box>
              </AssessorListStyle>
            </Modal>
            <Snackbar
              onClose={() =>
                this.setState({
                  ...this.state,
                  AlertModel: false,
                })
              }
              autoHideDuration={3000}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              open={this.state.AlertModel}
            >
              <Alert
                severity="success"
                onClose={() =>
                  this.setState({
                    ...this.state,
                    AlertModel: false,
                  })
                }
                style={{ height: "46px" }}
              >
                {this.state.AlertText}
              </Alert>
            </Snackbar>
          </div>
        </AppSidebar>
      </AssessorListStyle>
    );
  }
}

export default withRouter(withTranslation()(UnavailabilityCalander));

const AssessorListStyle = styled("div")({
  "& .MuiGrid-spacing-xs-2": {
    width: "100%",
  },
  "& .CardContainer": {
    backgroundColor: "white",
    minWidth: "300px",
    height: "100%",
  },
  "& .client_container": {
    "& h4": {
      textTransform: "capitalize",
    },
  },
  "& .userLabelAvaible": {
    backgroundColor: "#dff8d2",
    maxWidth: "100px",
    borderRadius: "4px",
    padding: "5px 10px",
    textAlign: "center",
    color: "#288a0a",
  },
  "& .userLabelDeActive": {
    padding: "5px 10px",
    maxWidth: "100px",
    backgroundColor: "#eaeaea",
    color: "#288a0a",
    textAlign: "center",
    borderRadius: "4px",
  },
  "& .CardWhitePart": {
    borderTopRightRadius: "4px",
    minHeight: "150px",
    borderTopLeftRadius: "4px",
    boxShadow: "0px 0px 2px 0px grey",
  },
  "& .UserName": {
    display: "flex",
    padding: "10px 0",
    justifyContent: "center",
    "& h2": {
      textTransform: "capitalize",
    },
  },
  "& .SlotTime": {
    width: "100%",
  },
  "& .nameandPhoto": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
  },
  "& .avtart": {
    position: "absolute",
  },
  "& .MuiCircularProgress-circle": {
    strokeWidth: 1,
  },
  "& .bookNowBtn": {
    backgroundColor: "#1b3c69",
    color: "white",
    fontWeight: "600",
    textTransform: "unset",
  },
});

// Customizable Area End
