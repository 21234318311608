// Customizable Area Start
import React from "react";
import {
  Grid,
  Typography,
  TextField,
  Box,
} from "@material-ui/core";

import EmailAccountRegistrationController, {
  Props,
} from "./EmailAccountRegistrationController";

import { Link } from "react-router-dom"
import { toast } from "react-toastify";
// @ts-ignore
import logo from '../assets/shrm.png'
import { withTranslation } from "react-i18next";
const { baseURL } = require("../../../framework/src/config")


class EmailAccountForgetPassword extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
  }

  emailValidation = () => {
    let isValid = true;
    let errors = {
      email: ""
    }
    const { email } = this.state.forgetPassword;
    const regEx = /[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z{2,8}])?/g;
    if (regEx.test(email)) {
      isValid = true;
    } else if (regEx.test(email) === false || email === "") {
      errors.email = this.t('ForgotYourPassword.PleaseEnterValidEmailAddress');
      isValid = false
    }
    else {
      errors.email = this.t('ForgotYourPassword.PleaseEnterExistingEmailAddress')
      isValid = false
    }
    this.setState({ forgetPassword: { errors } })
    return isValid;
  }
  handleInputChange = (event: any) => {
    const { value } = event.target
    this.setState({ forgetPassword: { email: value } })
  }
  handleSubmit = () => {
    let isValid = this.emailValidation();

    if (isValid) {
      const jsonpayload = {
        "data": {
          "attributes": {
            "email": this.state.forgetPassword.email
          }
        }
      }
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(jsonpayload)
      };

      fetch(`${baseURL}/account_block/forgot_password`, requestOptions)
        .then((response) => {
          if (response.status === 200) {
            this.props.navigation.navigate("CheckEmail")
            return response.json()
          }
          else {
            throw new Error("Account not Exist")
          }
        })
        .then(res => {
          const accessToken = res?.meta.token
          localStorage.setItem("accesToken", accessToken)
          localStorage.setItem("accoutType", res?.meta?.type)
        }).catch(err => {
          this.setState({ errors: { email: this.t('ForgotYourPassword.AccountNotExist') } })
          toast.error(this.t('ForgotYourPassword.AccountNotExist'))
        })
    }

  }
  render() {
    const { email, errors } = this.state.forgetPassword;
    return (
      <div className="forgetPassword">
        <Box style={{ display: "flex", justifyContent: "flex-end" }}>
          <Box
            style={{ top: 0, right: 0, paddingTop: "30px", paddingRight: "30px" }}
          ><img
              src={logo}
              alt="logo"
              className="logo_image"
            />
          </Box>
        </Box>
        <div className="box_container">
          <Grid container spacing={3}>
            <div>
              <Typography component="h2">{this.t('ForgotYourPassword.ForgotYourPassword')}</Typography>
              <p className="forget-text">
                {this.t('ForgotYourPassword.EnterYourEmail')}
              </p>
              <Grid item xs={12}>
                <TextField
                  name="email"
                  variant="outlined"
                  placeholder={this.t('ForgotYourPassword.EnterYourEmail')}
                  value={email}
                  onChange={this.handleInputChange}
                  error={!!errors?.email}
                  helperText={errors?.email}
                  autoComplete="off"
                  fullWidth />
              </Grid>
              <Box className="forgetPassword-btns" style={{
                width: '100%',
                display: 'flex',
                boxSizing: 'border-box',
                marginTop: '30px'
              }}>
                <Grid item md={6} lg={6} xs={6} sm={6} style={{ display:"flex", justifyContent:"center" }}>
                  <Link to={localStorage.getItem('forgateLink')?.includes('login') ? "/login" : '/Adminlogin'}>
                    <button
                      className="cancel_btn"
                    >{this.t('ForgotYourPassword.Cancel')}</button>
                  </Link>
                </Grid>
                <Grid item md={6} lg={6} xs={6} sm={6} style={{ display:"flex", justifyContent:"center" }}>
                  <button
                    className="submit_btn"
                    style={{ background: "#1b3c69", color: "#fff" }}
                    onClick={this.handleSubmit}
                  >{this.t('ForgotYourPassword.Submit')}</button>
                </Grid>
              </Box>
            </div>
          </Grid>
        </div>
        <section className="text_footer" dir="ltr">
          <p>{this.state.copyright}</p>
        </section>
      </div>
    );
  }
}
export default withTranslation()(EmailAccountForgetPassword)
// Customizable Area End