Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.placeHolderEmail = "Email";

exports.labelHeader = "Value proposition: why users should sign up.";
exports.labelFirstName = "First name";
exports.lastName = "Last name";
exports.labelEmail = "Email";
exports.labelPassword = "Password";
exports.labelRePassword = "Re-Type Password";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorBlankField = "can't be blank";

exports.labelLegalText =
  "Legal text: By signing up you agree to our Terms &amp; conditions and Privacy policy.";

exports.labelLegalTermCondition = "Terms and conditions";
exports.labelLegalPrivacyPolicy = "Privacy policy";
exports.btnTextSignUp = "Sign up";
exports.errorPasswordNotValid = "Password not valid.";

exports.errorTitle = "Error";

exports.errorBothPasswordsNotSame = "Passwords should be same.";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";

exports.contentTypeApiAddDetail = "application/json";

exports.accountsAPiEndPoint =
  "account/accounts";

exports.apiMethodTypeAddDetail = "POST";

exports.errorEmailNotValid = "Email not valid.";
exports.urlGetValidations =
  "profile/validations";
exports.validationApiContentType = "application/json";
// Customizable Area Start
exports.methodTypeGet = "GET";
exports.endPointApiGetWelcomeData = "bx_block_dashboard/company_informations";
exports.endPointApiGetHeaderData = "/account_block/get_current_user_name";
exports.endPointApiGetManagerData = "/account_block/help_dc_manager";
exports.endPointApiGetCoManagerData = "/account_block/help_co_manager";
exports.endPointClientLogo = "/account_block/clients/"

exports.resetPasswordEndpoint = "account_block/password";
exports.methodTypePost = "POST";
exports.validationApiMethodType = "GET";
// Customizable Area End
