// Customizable Area Start
import React from "react";

function NotificationSvg() {
  return (
    <svg width="36" height="40" viewBox="0 0 36 40" xmlns="http://www.w3.org/2000/svg">
      <g fill="#F94B4B" fillRule="nonzero">
        <path d="M29.688 20.536v-3.74c0-5.454-3.626-10.077-8.594-11.587V3.516A3.52 3.52 0 0 0 17.578 0a3.52 3.52 0 0 0-3.515 3.516v1.693c-4.968 1.51-8.594 6.133-8.594 11.588v3.74A18.4 18.4 0 0 1 .326 33.33a1.172 1.172 0 0 0 .846 1.983h10.665A5.87 5.87 0 0 0 17.578 40a5.87 5.87 0 0 0 5.742-4.688h10.664a1.172 1.172 0 0 0 .846-1.982 18.4 18.4 0 0 1-5.142-12.794zM16.405 3.516c0-.647.526-1.172 1.172-1.172.646 0 1.172.525 1.172 1.172v1.228a12.207 12.207 0 0 0-2.344 0V3.516zm1.172 34.14c-1.528 0-2.83-.98-3.314-2.343h6.628a3.521 3.521 0 0 1-3.314 2.343zM3.698 32.97a20.72 20.72 0 0 0 4.114-12.433v-3.74c0-5.384 4.381-9.765 9.766-9.765s9.766 4.381 9.766 9.766v3.74a20.72 20.72 0 0 0 4.115 12.432H3.698zM32.813 16.797a1.172 1.172 0 0 0 2.343 0c0-4.695-1.828-9.11-5.148-12.43a1.172 1.172 0 0 0-1.658 1.657 15.135 15.135 0 0 1 4.462 10.773zM1.172 17.969c.647 0 1.172-.525 1.172-1.172 0-4.07 1.584-7.895 4.462-10.772a1.172 1.172 0 1 0-1.657-1.658A17.463 17.463 0 0 0 0 16.797c0 .647.525 1.172 1.172 1.172z" />
      </g>
    </svg>
  );
}

export default NotificationSvg;
// Customizable Area End