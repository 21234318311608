// Customizable Area Start
import React, { useState, useEffect, useRef } from "react";
import { Badge, Box } from "@material-ui/core";
import moment from "moment";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import CloseIcon from '@material-ui/icons/Close';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import NotificationSvg from "./SvgNotification";
const { baseURL } = require("../../framework/src/config.js");
import "./style.css";

interface NotificationList {
  id: string;
  title: string;
  message: string;
  isRead: boolean;
  date: string;
}

export default function Notification() {
  const { t } = useTranslation();
  const [isNotificationClicked, setIsNotificationClicked] = useState<boolean>(false);
  const [notificationList, setNotificationList] = useState<NotificationList[]>([]);
  const ref = useRef(null);

  useEffect(() => {
    getNotification();
  }, []);

  useEffect(() => {
    const interval = setTimeout(() => {
      getNotification();
    }, 60 * 1000);

    return () => {
      clearInterval(interval);
    }
  }, [notificationList]);

  const getNotification = async () => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "token": localStorage.getItem("accesToken") ?? ""
      }
    };

    const res = await fetch(`${baseURL}/bx_block_notifications/notifications/get_notification`, requestOptions);
    if (!res.ok) {
      toast.error(t("notification.toast.somethingWrong"));
      return;
    }
    const response = await res.json();
    if (response.errors) {
      setNotificationList([]);
      return;
    }
    const list = response.data.map((item: any) => {
      return {
        id: item.id,
        title: item.attributes.headings,
        message: item.attributes.contents,
        isRead: item.attributes.is_read,
        date: item.attributes.updated_at
      }
    });
    setNotificationList(list);
  }

  const readNotification = async (id: string, index: number) => {
    const list = [...notificationList];
    if (list[index].isRead) {
      return;
    }
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "token": localStorage.getItem("accesToken") ?? ""
      }
    };

    const res = await fetch(`${baseURL}/bx_block_notifications/notifications/${id}`, requestOptions);
    if (!res.ok) {
      toast.error(t("notification.toast.somethingWrong"));
      return;
    }
    list[index].isRead = true;
    setNotificationList(list);
  }

  const deleteNotification = async (event: any, id: string, index: number) => {
    event.stopPropagation();
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "token": localStorage.getItem("accesToken") ?? ""
      }
    };

    const res = await fetch(`${baseURL}/bx_block_notifications/notifications/${id}`, requestOptions);
    if (!res.ok) {
      toast.error(t("notification.toast.somethingWrong"));
      return;
    }
    let list = [...notificationList];
    list.splice(index, 1);
    setNotificationList(list);
  }

  const markAllRead = async () => {
    const count = notificationList.filter((item: NotificationList) => !item.isRead).length;
    if (count == 0) {
      return;
    }

    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "token": localStorage.getItem("accesToken") ?? ""
      }
    };

    const res = await fetch(`${baseURL}/bx_block_notifications/notifications/all`, requestOptions);
    if (!res.ok) {
      toast.error(t("notification.toast.somethingWrong"));
      return;
    }

    let list = [...notificationList];
    list = list.map((notification: NotificationList) => {
      return { ...notification, isRead: true };
    });
    setNotificationList(list);
  }

  const useOnClickOutside = (ref: any, handler: any) => {
    useEffect(() => {
      const listener = (event: any) => {
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }
        handler(event);
      };
      document.addEventListener("mousedown", listener);
      document.addEventListener("touchstart", listener);
      return () => {
        document.removeEventListener("mousedown", listener);
        document.removeEventListener("touchstart", listener);
      };
    }, [ref, handler]);
  }
  useOnClickOutside(ref, () => setIsNotificationClicked(false));

  return (
    <div ref={ref} className="notifications">
      <Box position="relative">
        <Badge
          max={99}
          overlap="rectangular"
          color="secondary"
          className="custom-badge"
          onClick={() => setIsNotificationClicked(!isNotificationClicked)}
          badgeContent={notificationList.filter((item: NotificationList) => !item.isRead).length}
        >
          <NotificationsNoneIcon className="icon" />
        </Badge>
        <div
          className="notification-wrapper"
          style={{
            transition: "opacity 0.2s ease-in-out,visibility 0.2s ease-in-out",
            opacity: isNotificationClicked ? "1" : "0",
            visibility: isNotificationClicked ? "visible" : "hidden"
          }}
        >
          <div className="notification-list">
            <div className="triangle"></div>
            <div className="top">
              <div className="heading">{t("notification.title")}</div>
              {notificationList.length > 0 && <div className="mark-read" title={t("notification.allRead") ?? ""} onClick={() => markAllRead()}><DoneAllIcon /></div>}
            </div>
            <ul>
              {notificationList.length > 0 ?
                notificationList.map((notification: NotificationList, index: number) => {
                  return (
                    <li
                      title={t("notification.markRead") ?? ""}
                      key={notification.id}
                      className={notification.isRead ? "item" : "new-item item"}
                      onClick={() => readNotification(notification.id, index)}
                    >
                      <div className="title-wrapper">{notification.title}</div>
                      <span>{notification.message}</span>
                      <CloseIcon className="close" titleAccess={t("notification.markRead") ?? ""} onClick={(event: any) => deleteNotification(event, notification.id, index)} />
                      <div className="time"><AccessTimeIcon /> {moment(notification.date).startOf('hour').fromNow()}</div>
                    </li>
                  );
                })
                :
                <div className="no-notification">
                  <NotificationSvg />
                  <span>{t("notification.noNotification")}</span>
                </div>
              }
            </ul>
          </div>
        </div>
      </Box>
    </div>
  );
}
// Customizable Area End