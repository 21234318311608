// Customizable Area Start
import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { toast } from "react-toastify";

export const configJSON = require("./config");

export interface Props {
  navigation?: any;
  id?: string;
  t: any;
}

interface S {
  url: any,
  invalidURL: boolean
}

interface SS {
  id: any;
}

export default class ManageAssessorController extends BlockComponent<
  Props,
  S,
  SS
> {
  getAssessorAPICall: string = '';
  deleteAssessorAPICall: string = '';
  updateAssessorAPICall: string = '';
  t: any = "";


  constructor(props: Props) {

    super(props);
    this.receive = this.receive.bind(this)


    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.PostDetailDataMessage)
    ]

    this.state = {
      url: "",
      invalidURL: false
    };
    this.t = this.props.t;
    // @ts-ignore
    this.callRef = React.createRef();
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }


  async receive(from: string, message: Message) {
    // Customizable Area Start
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    if (apiRequestCallId && responseJson) {
      if (apiRequestCallId === this.updateURLApiCallID) {
        if (responseJson?.data) {
          toast.success(this.t("psychometricUrl.toast.update"));
        } else {
          toast.error(this.t("psychometricUrl.toast.somethingWrong"));
        }
      }
    }
    else {
      this.parseApiErrorResponse(responseJson);
    }

    // Customizable Area End
  }


  apiCall = async (data: any) => {
    const token = localStorage.getItem("accesToken") as string

    const { contentType, method, endPoint, body, type } = data
    const header = {
      'Content-Type': contentType,
      token: token
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    )
    body && type != 'formData' ?
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      )

      : requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    console.log("requestMessage", requestMessage)
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  updateURLApiCallID: string = ""

  validateURL = () => {
    let str = this.state.url
    if (str.indexOf("http://") == 0 || str.indexOf("https://") == 0) {
      this.setState({invalidURL:false,url:""})
      this.updateURL()
    }
    else {
       this.setState({invalidURL:true})
    }
  }

  updateURL = async () => {
    this.updateURLApiCallID = await this.apiCall({
      contentType: "application/json",
      method: "POST",
      endPoint: `/bx_block_admin/update_url?name=Psychometric`,
      body: {
        "url": this.state.url
      }
    });
  }

}
 // Customizable Area End