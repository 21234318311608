// Customizable Area Start
import React from "react";
import { Button, Card, CardContent, Typography, Grid, Box, Container } from '@material-ui/core';
import ClientsDashboardController, { Props } from "./ClientsDashboardController.web";
import {
  PieChart, Pie, Cell, ResponsiveContainer, Legend, Tooltip, BarChart,
  Bar, CartesianGrid, XAxis, YAxis} from 'recharts';
import { withTranslation } from "react-i18next";
import AppHeader from "../../../components/src/AppHeader.web";
import AppFooter from "../../../components/src/AppFooter.web";
import Spinner from "../../../components/src/Spinner.web";
import { styled } from "@material-ui/styles";
import Select from "react-select";

const COLORS = ['#1b3c69', '#f26a42', '#24b793'];

const sortByList = [
  {
    label: 'All',
    value: 'all'
  },
  {
    label: 'Gender',
    value: 'gender'
  }, {
    label: 'Industry',
    value: 'industry'
  }, {
    label: 'Sector',
    value: 'sector'
  }, {
    label: 'Function',
    value: 'function'
  }, {
    label: 'Projects',
    value: 'projects'
  }
];


class ClientsDashboard extends ClientsDashboardController {
  constructor(props: Props) {
    super(props);
  }
  
  render() {
    const { loader, data, error } = this.state;
    return (
      <Box className="analyticsPage manage-participant">
        {loader && <Spinner spinnerModal={loader} />}
        <AppHeader type="client" />
        <Container maxWidth={"lg"}>
        <div className="main_page" data-testid="MainDiv">
            <div className="client_container" data-testid="client_container">
              <div data-testid="Heading">
                <h4> {this.t("ClientDashboard.title")} </h4>
                <p>{this.t("ClientDashboard.description")} </p>
              </div>
              <div className="sort_analytics" data-testid="AllData">
                {data && <RedButton style={{ backgroundColor: "#f26a42" }} href={data.url}>{this.t("ClientDashboard.downloadButton")} </RedButton>}
              </div>
            </div>
            <Box data-testid="sortBy" style={{ borderRadius: "10px", backgroundColor: "#eff6ff", margin: "15px 15px 15px 0" }}>
              <Grid container spacing={2} style={{ alignItems: "end", padding: "20px 15px 10px 15px" }}>
                <Grid item xs={12} sm={6} md={5}>
                  <Box style={{ color: "#1b3c69" }}>
                    <div data-testid="sortBy1" style={{ marginBottom: "5px", fontSize: "18px" }}>{this.t("ClientDashboard.label.sortBy")}</div>
                    <Select
                      autofocus={true}
                      options={sortByList}
                      classNamePrefix="react-select"
                      openOnFocus={true}
                      isSearchable={false}
                      isClearable={true}
                      placeholder={this.t("ClientDashboard.label.sortBy")} 
                      value={
                        sortByList.filter(
                          (option: any) =>
                            option.value === this.state.sortBy
                        )[0]
                      }
                      onChange={(event: any) => {
                        this.onsortByValueChange(event?.value);
                      }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={5}>
                  <Box style={{ color: "#1b3c69" }}>
                    <div data-testid="sortByselectOption" style={{ marginBottom: "5px", fontSize: "18px" }} >{this.t("ClientDashboard.label.selectOption")} </div>
                    <Select
                      classNamePrefix="react-select"
                      options={this.state.optionList}
                      placeholder={this.t("ClientDashboard.label.selectOption")} 
                      isSearchable={false}
                      value={
                        this.state.optionList.filter(
                          (option: any) =>
                            option?.label === this.state.option
                        )[0] ?? []
                      }
                      onChange={(event: any) => {
                        this.setState({ option: event.label });
                      }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                  <Box>
                    <RedButton data-testid="applyButton" onClick={this.onApplyButtonClick} style={{ width: "100%" }}>{this.t("ClientDashboard.applyButton")} </RedButton>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            {!error ?
              <Grid container spacing={2} >
                {data.age_group &&
                  <Grid item xs={6}>
                    <Card className="card">
                      <CardContent >
                        <Typography className="CardTitle" data-testid="CardTitle">
                        {this.t("ClientDashboard.charts.age")}
                        </Typography>

                        <ResponsiveContainer width="100%" height="100%" className="customChart">
                          <PieChart width={400} height={400}>
                            <Pie
                              data={data.age_group}
                              cy="50%"
                              outerRadius={80}
                              dataKey="count"
                              labelLine={false}
                              cx="50%"
                              fill="#8884d8"
                            >
                              {data.age_group.map((entry: any, index: any) => (
                                <Cell key={`cell-${entry.toString()}`} fill={COLORS[index % COLORS.length]} />
                              ))}
                            </Pie>
                            <Tooltip />
                            <Legend layout="vertical" content={this.renderLegend} />
                          </PieChart>
                        </ResponsiveContainer>

                      </CardContent>
                    </Card>
                  </Grid>
                }

                {data.employment_types &&
                  <Grid item xs={6}>
                    <Card className="card" data-testid="CardTitle22">
                      <CardContent >
                        <Typography className="CardTitle" data-testid="CardTitle121">
                        {this.t("ClientDashboard.charts.empType")}
                        </Typography>
                        <ResponsiveContainer width="99%" height={250}>
                          <PieChart width={400} height={400}>
                            <Pie
                              data={data.employment_types}
                              cy="50%"
                              labelLine={false}
                              fill="#8884d8"
                              outerRadius={80}
                              cx="50%"
                              dataKey="count"
                            >
                              {data.employment_types.map((entry: any, index: any) => (
                                <Cell key={`cell-${entry.toString()}`} fill={COLORS[index % COLORS.length]} />
                              ))}
                            </Pie>
                            <Tooltip />
                            <Legend layout="vertical" content={this.renderLegend}
                              formatter={this.renderColorfulLegendText} />
                          </PieChart>
                        </ResponsiveContainer>
                      </CardContent>
                    </Card>
                  </Grid>
                }

                {data.gender &&
                  <Grid item xs={6}>
                    <Card className="card">
                      <CardContent >
                        <Typography className="CardTitle" data-testid="CardTitle12932">
                        {this.t("ClientDashboard.charts.gender")}
                        </Typography>
                        <ResponsiveContainer width="100%" height="100%" className="customChart">
                          <PieChart width={400} height={400}>
                            <Pie
                              cx="50%"
                              cy="50%"
                              labelLine={false}
                              outerRadius={80}
                              fill="#8884d8"
                              dataKey="count"
                              data={data.gender}
                            >
                              {data.gender.map((entry: any, index: any) => (
                                <Cell key={`cell-${entry.toString()}`} fill={COLORS[index % COLORS.length]} />
                              ))}
                            </Pie>
                            <Tooltip />
                            <Legend layout="vertical" content={this.renderLegend} formatter={this.renderColorfulLegendText} />
                          </PieChart>
                        </ResponsiveContainer>
                      </CardContent>
                    </Card>
                  </Grid>
                }


                {data.total_assessments &&
                  <Grid item xs={6}>
                    <Card className="card">
                      <CardContent>
                        <Typography className="CardTitle" data-testid="CardTitle12121232312">
                        {this.t("ClientDashboard.charts.assesment")}
                        </Typography>
                        <ResponsiveContainer width="100%" height="100%" className="customChart">
                          <PieChart width={400} height={400}>
                            <Pie
                              cx="50%"
                              cy="50%"
                              labelLine={false}
                              data={data.total_assessments}
                              outerRadius={80}
                              fill="#8884d8"
                              dataKey="count"
                            >
                              {data.total_assessments.map((entry: any, index: any) => (
                                <Cell key={`cell-${entry.toString()}`} fill={COLORS[index % COLORS.length]} />
                              ))}
                            </Pie>
                            <Tooltip />
                            <Legend layout="vertical" content={this.renderAssesmentLegend}
                              formatter={this.renderColorfulLegendText} />
                          </PieChart>
                        </ResponsiveContainer>
                      </CardContent>
                    </Card>
                  </Grid>
                }

                {data.work_experience &&
                  <Grid item xs={6}>
                    <Card className="card">
                      <CardContent >
                        <Typography className="CardTitle" data-testid="CardTitledsddsdw12">
                        {this.t("ClientDashboard.charts.exp")}
                        </Typography>
                        <ResponsiveContainer width="100%" height="100%" className="customChart">
                          <PieChart width={400} height={400}>
                            <Pie
                              cx="50%"
                              labelLine={false}
                              cy="50%"
                              dataKey="count"
                              outerRadius={80}
                              data={data.work_experience}
                              fill="#8884d8"
                            >
                              {data.work_experience.map((entry: any, index: any) => (
                                <Cell key={`cell-${entry.toString()}`} fill={COLORS[index % COLORS.length]} />
                              ))}
                            </Pie>
                            <Tooltip />
                            <Legend layout="vertical"
                              content={this.renderLegend}
                            />
                          </PieChart>
                        </ResponsiveContainer>
                      </CardContent>
                    </Card>
                  </Grid>
                }

                {data.organization_function &&
                  <Grid item xs={6}>
                    <Card className="card">
                      <CardContent >
                        <Typography className="CardTitle" data-testid="CardTitledasa12sd">
                        {this.t("ClientDashboard.charts.fun")}
                        </Typography>
                        <ResponsiveContainer width="100%" height="100%" className="customChart">
                          <PieChart width={400} height={400}>
                            <Pie
                              cx="50%"
                              dataKey="count"
                              cy="50%"
                              outerRadius={80}
                              fill="#8884d8"
                              labelLine={false}
                              data={data.organization_function}
                            >
                              {data.organization_function.map((entry: any, index: any) => (
                                <Cell key={`cell-${entry.toString()}`} fill={COLORS[index % COLORS.length]} />
                              ))}
                            </Pie>
                            <Tooltip />

                            <Legend layout="vertical"
                              content={this.renderLegend} />
                          </PieChart>
                        </ResponsiveContainer>
                      </CardContent>
                    </Card>
                  </Grid>
                }

                {data.organization_sector &&
                  <Grid item xs={6}>
                    <Card className="card">
                      <CardContent >
                        <Typography className="CardTitle">
                        {this.t("ClientDashboard.charts.orgSector")}
                        </Typography>
                        <ResponsiveContainer width="99%" height={250}>
                          <BarChart
                            width={500}
                            height={250}
                            data={data.organization_sector}
                            margin={{
                              right: 0,
                              bottom: 0,
                              left: -40,
                              top: 10
                            }}
                          >
                            <CartesianGrid strokeDasharray="4 4" />
                            <XAxis dataKey="name" dy={5} />
                            <YAxis />
                            <Tooltip cursor={{ fill: '#f3f3f3' }} />
                            <Bar barSize={20} dataKey="count" fill="#1b3c69"/>
                          </BarChart>
                        </ResponsiveContainer>
                      </CardContent>
                    </Card>
                  </Grid>
                }


                {data.organization_industry &&
                  <Grid item xs={6}>
                    <Card className="card" data-testid="CardTitle121sd12fd">
                      <CardContent >
                        <Typography className="CardTitle" data-testid="CardTitle121sd12fdwe">
                        {this.t("ClientDashboard.charts.orgIndustry")}
                        </Typography>
                        <ResponsiveContainer width="99%" height={250}>
                          <BarChart
                            width={500}
                            height={250}
                            data={data.organization_industry}
                            margin={{
                              right: 0,
                              top: 10,
                              left: -40,
                              bottom: 0
                            }}
                          >
                            <CartesianGrid strokeDasharray="4 4" />
                            <XAxis dataKey="name" dy={5} />
                            <YAxis />
                            <Tooltip cursor={{ fill: '#f3f3f3' }} />
                            <Bar dataKey="count" barSize={20} fill="#1b3c69" />
                          </BarChart>
                        </ResponsiveContainer>
                      </CardContent>
                    </Card>
                  </Grid>
                }

                {data.highest_education &&
                  <Grid item xs={6}>
                    <Card className="card">
                      <CardContent >
                        <Typography className="CardTitle" data-testid="CadsssrdTitle121sd12fd">
                        {this.t("ClientDashboard.charts.highestEdu")}
                        </Typography>
                        <ResponsiveContainer width="99%" height={250}>
                          <BarChart
                            width={500}
                            height={250}
                            data={data.highest_education}
                            margin={{
                              top: 10,
                              right: 0,
                              left: -40,
                              bottom: 0
                            }}
                          >
                            <CartesianGrid strokeDasharray="4 4" />
                            <XAxis dataKey="name" dy={5} />
                            <YAxis />
                            <Tooltip cursor={{ fill: '#f3f3f3' }} />
                            <Bar dataKey="count" barSize={20} fill="#1b3c69" />
                          </BarChart>
                        </ResponsiveContainer>
                      </CardContent>
                    </Card>
                  </Grid>
                }

                {data.per_tool_progress &&
                  <Grid item xs={6}>
                    <Card className="card">
                      <CardContent >
                        <Typography className="CardTitle" data-testid="swssCardTitle121sd12fd">
                        {this.t("ClientDashboard.charts.perToolCompletion")}
                        </Typography>
                        <ResponsiveContainer width="99%" height={250}>
                          <BarChart
                            width={500}
                            height={250}
                            data={data.per_tool_progress}
                            margin={{
                              top: 10,
                              right: 0,
                              left: -40,
                              bottom: 0
                            }}
                          >
                            <CartesianGrid strokeDasharray="4 4" />
                            <XAxis dataKey="name" dy={5} />
                            <YAxis />
                            <Tooltip cursor={{ fill: '#f3f3f3' }} />
                            <Bar dataKey="count" barSize={20} fill="#1b3c69" />
                          </BarChart>
                        </ResponsiveContainer>
                      </CardContent>
                    </Card>
                  </Grid>
                }


                {data.designation &&
                  <Grid item xs={6}>
                    <Card className="card">
                      <CardContent >
                        <Typography className="CardTitle" data-testid="CardTitle121sd12fdsd112">
                          {this.t("ClientDashboard.charts.designation")}
                        </Typography>
                        <ResponsiveContainer width="99%" height={250}>
                          <BarChart
                            width={500}
                            height={250}
                            data={data.designation}
                            margin={{
                              top: 10,
                              right: 0,
                              left: -40,
                              bottom: 0
                            }}
                          >
                            <CartesianGrid strokeDasharray="4 4" />
                            <XAxis dataKey="name" dy={5} />
                            <YAxis />
                            <Tooltip cursor={{ fill: '#f3f3f3' }} />
                            <Bar dataKey="count" barSize={20} fill="#1b3c69" />
                          </BarChart>
                        </ResponsiveContainer>
                      </CardContent>
                    </Card>

                  </Grid>
                }


              </Grid>
              : <div className="noDataFound" data-testid="CardTitle121sd12fdssdsdsd">No data found!</div>}
          </div>
        </Container>
        <AppFooter />
      </Box>
    );
  }
}

const RedButton = styled(Button)({
  backgroundColor: "#f94b4b",
  color: "white",
  textTransform: "none",
  fontSize: "18px",
  fontFamily: "ProximaNova-Semibold",
  marginTop: "20px",
  borderRadius: "10px",
  padding: "9px 25px",
  "&:hover": {
    color: "white",
    backgroundColor: "#f94b4b",
  },
  "@media(max-width:600px)": {
    marginTop: "27px",
    width: "100%",
  },
  "@media(max-width:992px)": {
    padding: "8px 15px",
  }
});

export default withTranslation()(ClientsDashboard);
// Customizable Area End
