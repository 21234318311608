import React from "react";

import {
  StyleSheet,
  Text,
  Image,
  TouchableOpacity,
  View,
  TextInput,
  ScrollView,
  TouchableWithoutFeedback,
  Button,
  Platform,
  // Customizable Area Start
  // Customizable Area End
} from "react-native";

import RolesPermissions2Controller, {
  Props,
  configJSON
} from "./RolesPermissions2Controller";

export default class RolesPermissions2 extends RolesPermissions2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ScrollView keyboardShouldPersistTaps="always"
        style={styles.container}>        <TouchableWithoutFeedback
          onPress={() => {            this.hideKeyboard();}}
        >{/* Customizable Area Start */}
          {/* Merge Engine UI Engine Code */}
          <View>{this.isPlatformWeb() ? (
<Text
                style={styles.title} //UI Engine::From Sketch
                testID="labelTitle" //Merge Engine::From BDS
>
                {configJSON.labelTitleText}
</Text> //UI Engine::From Sketch
            ) : null}

<Text style={styles.body} //UI Engine::From Sketch
              testID="labelBody" //Merge Engine::From BDS 
              >{" "}
              {/* UI Engine::From Sketch */}
              {configJSON.labelBodyText} {/* UI Engine::From Sketch */}</Text>

<Text testID="txtSaved">
              This is the reviced value
              :
{this.state.txtSavedValue}
              {" "}
              {/* Merge Engine::From BDS - {...this.testIDValue} */}
</Text>

            <View style={styles.bgPasswordContainer}>
<TextInput
                              {...this.txtInputProps} //Merge Engine::From BDS - {...this.testIDProps}
 testID="txtInput" //Merge Engine::From BDS
                style={styles.bgMobileInput} //UI Engine::From Sketch
 placeholder={configJSON.txtInputPlaceholder} //UI Engine::From Sketch
              />

              <TouchableOpacity     {...this.btnShowHideProps} //Merge Engine::From BDS - {...this.testIDProps}
                testID={"btnShowHide"} //Merge Engine::From BDS 
              style={styles.showHide} //UI Engine::From Sketch
              >
                <Image               {...this.btnShowHideImageProps} //Merge Engine::From BDS - {...this.testIDProps}
                  testID={"btnShowHideImage"} //Merge Engine::From BDS - testIDImage
style={styles.imgShowhide} //UI Engine::From Sketch
                />
              </TouchableOpacity>
            </View>

            <Button {...this.btnExampleProps} //Merge Engine::From BDS - {...this.testIDProps}
              testID={"btnExample"} //Merge Engine::From BDS
              title={configJSON.btnExampleTitle} //UI Engine::From Sketch
/>
          </View>{/* Merge Engine UI Engine Code */}{/* Customizable Area End */}</TouchableWithoutFeedback></ScrollView>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  container: {
    marginLeft: "auto",
    marginRight: "auto",
    maxWidth: 650,
    flex: 1,
    padding: 16,    
    width: Platform.OS === "web" ? "75%" : "100%",
    backgroundColor: "#ffffffff"
  },
  title: {
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8,
    marginBottom: 32,
    
  },
  body: {
    textAlign: "left",
    fontSize: 16,
    marginBottom: 32,
        marginVertical: 8
  },
  bgPasswordContainer: {
    flexDirection: "row",
    backgroundColor: "#00000000",
        borderColor: "#767676",
    borderRadius: 2,
    marginBottom: 16,
    padding: 10,
    borderWidth: Platform.OS === "web" ? 0 : 1,
    borderBottomWidth: 1,
  },
  bgMobileInput: {
    padding:10,
    flex: 1,
  },
  imgShowhide: Platform.OS === "web" ? {width: 30, height: 30,  } : {},
  showHide: {
    alignItems: "center"
  }
});
// Customizable Area End
