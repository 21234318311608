import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    id?: string;
    navigation?: any;
    location: any;
    history: any;
    match: any;
}

export interface S {
    // Customizable Area Start
    loading: boolean;
    isCheck: boolean;
    toolData : any
    totalQuestion: number;
    duration: number;
    disableBtnNext: boolean
    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class TestInstructionController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage),
        ];
        this.receive = this.receive.bind(this);
        runEngine.attachBuildingBlock(this, this.subScribedMessages);

        this.state = {
            // Customizable Area Start
            loading: false,
            isCheck: false,
            totalQuestion: 0,
            toolData : localStorage.getItem("tool"),
            duration: 0,
            disableBtnNext: false
            // Customizable Area End
        };
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    async componentDidMount() {
        window.scrollTo(0, 0);
        let data = this.props.location.state;
        const toolLocaldata = localStorage.getItem("tool") as any
        const disableNext = localStorage.getItem("disableNext") as any
        const localdata = JSON.parse(toolLocaldata)
        const disableornot = Number(localdata.id) === Number(disableNext)
        this.setState({
            disableBtnNext: disableornot
        })
        this.setTool()
        
    }

    setTool=()=>{
        let tool = JSON.parse(this.state.toolData)
        let time = 0
        if(tool.updated_duration > 0){
            time = tool.updated_duration
        }
        else{ 
            time = Number(tool.hour) * 60 + Number(tool.mint)
        }
            this.setState({
                totalQuestion: tool.no_of_questions,
                duration: time,
            });
    }
    onStart = () => {
        localStorage.setItem("reload", "false")
        this.props.navigation.navigate("Test");
    }

    onCancel = () => {
        this.props.navigation.navigate("Dashboard");
    }
    // Customizable Area End
}
