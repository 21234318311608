
// Customizable Area Start
import React from "react";
import AppSidebar from "../../../components/src/AppSidebar.web";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import moment from "moment";
import Box from "@material-ui/core/Box";
import { styled } from "@material-ui/styles";
import AssesorDashboardController from './AssessorDashboardController.web'
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
const LanguageData = localStorage.getItem("language") === "ar";


// Customizable Area End

    
  
// Customizable Area Start
class AccessorDashboard extends AssesorDashboardController  {
 
    render(): React.ReactNode {      
        return(
            <>
            <AppSidebar type="assessor">
             <Box margin="35px 0px" mr="30px" className="manage-client main_page">
        <div className="main_page">
          <div className="client_container">
            <div>
              <h4>{this.t("assessorDashboard.title")}</h4>
              <p>{this.t("assessorDashboard.description")}</p>
            </div>
          </div>
          
            <div style={{ margin: LanguageData ? "100px 0px 0px 30px" : "100px 30px 0px 0px" }}>
              <div  style={{marginTop:"52px"}} className="manage-client-table">
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        align="center" 
                        style={{
                          fontFamily: "ProximaNova-Bold",
                          fontSize: "16px"
                        }}
                      >
                       {this.t("assessorDashboard.table.project")}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          fontFamily: "ProximaNova-Bold",
                          fontSize: "16px"
                        }}
                      >
                        {this.t("assessorDashboard.table.status")}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          fontFamily: "ProximaNova-Bold",
                          fontSize: "16px"
                        }}
                      >
                        {this.t("assessorDashboard.table.completedBy")}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                        {this.state.data?.map((proj:any) => {
                          let element = <CompletedStatus>{proj.attributes.status}</CompletedStatus>;
                          if (proj.attributes.status === "On Going") {
                              element = <OnGoingStatus>{proj.attributes.status}</OnGoingStatus>;
                          } else if (proj.attributes.status === "Yet to be started") {
                              element = <NotStartedStatus>{proj.attributes.status}</NotStartedStatus>
                          }
                          return (
                            <TableRow key={`${proj.attributes.id}`} onClick={() => this.NavigateToAssessorParticipants(proj)}>
                              <TableCell
                                align={LanguageData ? "right" : "left"}
                                style={{
                                  fontFamily: "ProximaNova-Regular",
                                  fontSize: "18px",
                                  color: "#000",
                                  whiteSpace: "nowrap",
                                  cursor: "pointer",
                                  textDecoration: "underline"
                                }}><span style={{display: 'block',maxWidth: '100px',whiteSpace: 'nowrap',overflow: 'hidden',textOverflow: 'ellipsis'}}>{proj.attributes.name}</span>
                              </TableCell>
                              <TableCell
                                align={LanguageData ? "right" : "left"}
                                style={{
                                  fontFamily: "ProximaNova-Regular",
                                  fontSize: "18px",
                                  color: "#000",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                <Box display="flex" justifyContent={LanguageData ? "right" : "left"}>
                                  {element}
                                </Box>
                              </TableCell>
                              <TableCell 
                              align={LanguageData ? "right" : "left"}
                              style={{
                                fontSize: "18px",
                                color: "#000",
                                whiteSpace: "nowrap",
                                fontFamily: "ProximaNova-Regular",
                                }}>
                                {" "}
                                {moment(
                                  proj.attributes.to_be_completed_by
                                ).format("DD-MM-YYYY")}{" "}
                                |{" "}
                                <span dir="ltr">
                                {moment(
                                  proj.attributes.to_be_completed_by
                                ).format("hh:mm A")}
                                </span>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    
                  </TableBody>
                </Table>
              </div>
            </div>
        </div>
      </Box>
      </AppSidebar>
            </>
        )
    }
}

export default withRouter(withTranslation()(AccessorDashboard))
const NotStartedStatus = styled(Box)({
  backgroundColor: "#ffe1a6",
  width: "fit-content",
  padding: "3px 20px",
  fontFamily: "ProximaNova-Regular",
  fontSize: "18px",
  borderRadius: "25px",
  color: "#288a0a",
});

const OnGoingStatus = styled(Box)({
  backgroundColor: "#f5d3d3",
  padding: "3px 20px",
  width: "fit-content",
  borderRadius: "25px",
  fontSize: "18px",
  fontFamily: "ProximaNova-Regular",
  color: "#cd1414",
});

const CompletedStatus = styled(Box)({
  backgroundColor: "#dff8d2",
  width: "fit-content",
  padding: "3px 20px",
  fontSize: "18px",
  borderRadius: "25px",
  fontFamily: "ProximaNova-Regular",
  color: "#288a0a",
});
// Customizable Area End