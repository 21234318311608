// Customizable Area Start
import {
    Box,
    Button,
    Grid,
    Avatar,
    CircularProgress
} from "@material-ui/core";
import { withTranslation } from "react-i18next";
import Styeld from '@emotion/styled';
import React from "react";
import AppHeader from "../../../components/src/AppHeader.web";
import AppFooter from "../../../components/src/AppFooter.web";
import AssessorListController, { Props } from "./AssessorListController.web";
import "./Calendar.web.css";

class AssessorList extends AssessorListController {
    constructor(props: Props) {
        super(props);
    }

    render() {

        return (
            <AssessorListStyle>
                <AppHeader />
                <Box className="title">{this.t("assessorList.title")}</Box>
                <Box
                    className="demo-app-main"
                    style={{
                        display: "flex",
                        minWidth: "100%",
                        minHeight: " 100%",
                        fontFamily: " Arial, Helvetica Neue, Helvetica, sans-serif",
                        fontSize: "14px",
                    }}
                >
                    <Grid container spacing={2} >
                        {this.state.data?.map((item: any,index: number) => {
                            return (
                                <Grid key={`${index+1}`} item xs={6} md={3} xl={3} className="GridItems">
                                    <div className="CardWhitePart">
                                        {item.attributes.status ? <div className="userLabelAvaible">{this.t("assessorList.label.available")}</div> :
                                        <div style={{backgroundColor:"f0f0f0",color:"black",width:"40%",textAlign:"center" , padding:"0.4rem"}}>{this.t("assessorList.label.unavailable")}</div>}
                                        <div className="nameandPhoto">
                                            {/* @ts-ignore */}
                                            <CircularProgress variant="determinate" size={70} value={70} />
                                            <div className="avtart" >
                                                {/* @ts-ignore */}
                                                <Avatar alt="Remy Sharp" sx={{ width: 56, height: 56 }} />
                                            </div>
                                        </div>
                                        <div className="UserName">
                                            <h2>{item?.attributes?.name}</h2>
                                        </div>
                                    </div>
                                    <div className={item.attributes.status ? "bookNowBtn" : ""}>
                                        <Button
                                        disabled={!item.attributes.status}
                                         onClick={() => {
                                            localStorage.setItem("AssessorId",JSON.stringify(item?.attributes?.id))
                                            this.props.navigation.navigate('Calendar',{state:item?.attributes})
                                        }} fullWidth variant="contained" >{this.t("assessorList.button.bookNow")}</Button>
                                    </div>
                                </Grid>
                            )
                        })}
                    </Grid>

                </Box>
                <AppFooter />
            </AssessorListStyle>
        );
    }
}
export default withTranslation()(AssessorList);
// Customizable Area End


// Customizable Area Start

const AssessorListStyle = Styeld.div({
    '& .CardContainer': {
        backgroundColor: "white",
        minWidth: "300px",
        height: "100%"
    },
    '& .userLabelAvaible': {
        backgroundColor: "#dff8d2",
        maxWidth: "100px",
        padding: "5px 10px",
        textAlign: "center",
        borderRadius: "4px",
        color: "#288a0a"
    },
    ' .CardWhitePart': {
        boxShadow: "0px 0px 2px 0px grey",
        borderTopLeftRadius: "4px",
        borderTopRightRadius: "4px",
        minHeight: "150px"
    },
    '& .UserName': {
        display: "flex",
        justifyContent: "center",
        padding: "10px 0"
    },
    '& .nameandPhoto': {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative"
    },
    '& .avtart': {
        position: "absolute",
    },
    '& .MuiCircularProgress-circle': {
        strokeWidth: 1
    },
    '& .bookNowBtn': {
        button: {
            backgroundColor: "#1b3c69",
            color: "white",
            fontWeight: "600",
            textTransform:"unset"
        }
    }
})

  // Customizable Area End