// index.js - WEB
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { I18nextProvider, initReactI18next } from 'react-i18next';
import i18next from 'i18next';
import Backend from 'i18next-xhr-backend';
import App from './App';
import './index.css';
import './App.css';
import translationEN from "../public/locales/en/translation.json";
import translationHI from "../public/locales/hi/translation.json";
import translationAR from "../public/locales/ar/translation.json";

const localData = localStorage.getItem('language')

if (localStorage.getItem("language") === null || (localData === "en" || localData === "ar" || localData === "hi") === false) {
  localStorage.setItem("language", 'en')
}

i18next.use(Backend).use(initReactI18next).init({
  lng: localStorage.getItem('language') ?? "en",
  fallbackLng: localStorage.getItem('language') ?? "en",
  resources: {
    en: {
      translation: translationEN
    },
    hi: {
      translation: translationHI
    },
    ar: {
      translation: translationAR
    },
  },
  interpolation: { escapeValue: false }, // not need for React since it already does escaping
});

import registerServiceWorker from '../../components/src/registerServiceWorker';

ReactDOM.render(
  <Router>
    <I18nextProvider i18n={i18next}>
      <App />
    </I18nextProvider>
  </Router>,
  document.getElementById('root')
);
registerServiceWorker();
