import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { runEngine } from "../../../../framework/src/RunEngine";
import MessageEnum, {
    getName,
} from "../../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { toast } from "react-toastify";
import moment from "moment";
// Customizable Area End

export const configJSON = require("../config");

export interface Props {
    id?: string;
    navigation?: any;
    location: any;
    history: any;
    match: any;
    t: any;
}

export interface S {
    // Customizable Area Start
    loading: boolean;
    page: number;
    rowsPerPage: number;
    dataLength: number;
    count: number | null;
    item: any;
    filterBy: string;
    option: string;
    search: string;
    fileName: string;
    projectName: string;
    data: any[];
    tableData: any[];
    filterData: any[];
    optionList: any[];
    csvData: any[];
    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

const statusList = [
    { label: "Completed", value: "completed" },
    { label: "On Going", value: "on going" },
    { label: "Yet to be started", value: "yet to be started" }
];

export default class ReportsController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    getReportDetailApiCallId: string = "";
    getParticipantDetailApiCallId: string = "";

    csvLink: any = "";
    t: any;
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage),
        ];
        this.receive = this.receive.bind(this);
        runEngine.attachBuildingBlock(this, this.subScribedMessages);

        this.state = {
            // Customizable Area Start
            loading: false,
            page: 0,
            rowsPerPage: 20,
            dataLength: 0,
            count: null,
            item: null,
            filterBy: "",
            option: "",
            search: "",
            fileName: "",
            projectName: "",
            data: [],
            tableData: [],
            filterData: [],
            optionList: [],
            csvData: [],
            // Customizable Area End
        };
        this.t = this.props.t;
    }

    async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(

                getName(MessageEnum.RestAPIResponceDataMessage));

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (apiRequestCallId && responseJson) {
                if (responseJson.status === 500) { toast.error(this.t("reports.toast.somethingWrong")); this.setState({ loading: false }); } else {
                    this.handelApiResponse(apiRequestCallId, responseJson);
                }
            }
        }
    }

    handelApiResponse = (apiRequestCallId: string, responseJson: any) => {
        if (Array.isArray(responseJson.errors)) {
            if (responseJson.errors[0].message) {
                toast.error(responseJson.errors[0].message);
            } else if (responseJson.errors[0].token) {
                toast.error(responseJson.errors[0].token);
            } else {
                toast.error(this.t("reports.toast.somethingWrong"));
            }
            this.setState({ loading: false });
        } else if (apiRequestCallId === this.getReportDetailApiCallId) {
            this.handelTableDataApiResponse(responseJson);
        } else if (apiRequestCallId === this.getParticipantDetailApiCallId) {
            this.handelParticipantDataApiResponse(responseJson);
        }
    }

    async componentDidMount() {
        window.scrollTo(0, 0);
        this.getReportDetail();
    }

    handelTableDataApiResponse = (responseJson: any) => {
        const data = responseJson.data.map((item: any) => {
            const onlineTool = item.attributes.tool_used.map((tool: any) => tool.name);
            const assessorLeadTool = item.attributes.assessor_tool_used.map((tool: any) => tool.name);
            return {
                id: item.id,
                project: item.attributes.name,
                status: item.attributes.status,
                counter: `${item.attributes.counter?.test_completed}/${item.attributes.counter?.total_participants}`,
                type: item.attributes.project_type,
                DCManager: item.attributes.manager,
                completedBy: item.attributes.to_be_completed_by,
                toolUsed: [...onlineTool, ...assessorLeadTool].join(", "),
                evaluationType: item.attributes.evalution_type
            }
        }).sort((a: any, b: any) => {
            return a.project.toLowerCase().localeCompare(b.project.toLowerCase())
        });

        this.setState({ tableData: data, filterData: data, loading: false },
            () => { this.onChangeHandler(this.state.search, 0) }
        );
    }

    handelParticipantDataApiResponse = (responseJson: any) => {
        if (responseJson.data?.length === 0) {
            toast.error(this.t("reports.toast.notAvailable"));
            this.setState({ loading: false });
            return;
        }

        const csvData = responseJson.data.map((item: any) => {
            const toolName = item.attributes.tool_name;
            let score: any = 0;
            if (toolName !== "OnlineTool") {
                item.attributes.set_score.map((item: any) => {
                    score += parseFloat(item.score);
                });
                score = item.attributes.set_score.length > 0 ? score : "-";
            } else {
                score = item.attributes.test_status === "completed" ? item.attributes.score : "-";
            }

            return [
                item.attributes.full_name,
                item.attributes.country,
                item.attributes.state,
                item.attributes.city,
                score,
                item.attributes.tool_type
            ];
        });

        const row1 = ['Project Name', this.state.projectName];
        const row2 = ['Report Generated On', moment().format('DD MMM YYYY, h:mm a')];
        const header = ['Participant Name', 'Country', 'State', 'City', 'Score', 'Type'];
        csvData.unshift(header);
        csvData.unshift(['']);
        csvData.unshift(row2);
        csvData.unshift(row1);
        this.setState({ csvData, loading: false }, () => this.csvLink && this.csvLink.link && this.csvLink.link.click());
    }

    onExportData = () => {
        const csvData = this.state.tableData.map((item: any) => {
            return [
                item.project,
                item.status,
                item.counter,
                item.type,
                item.toolUsed,
                item.evaluationType,
                item.DCManager,
                moment(item.completedBy).format('DD MMM YYYY, h:mm a')
            ];
        });

        const row1 = ['Report Generated On', moment().format('DD MMM YYYY, h:mm a')];
        const header = ['Project Name', 'Status', 'Counter', 'Type', 'Tool Used', 'Evaluation Type', 'DC Manager', 'Completion Date'];
        csvData.unshift(header);
        csvData.unshift(['']);
        csvData.unshift(row1);

        this.setState({ csvData, fileName: "Project_list.csv", loading: false }, () => this.csvLink && this.csvLink.link && this.csvLink.link.click());
    }

    redirectToProjectDetail = (item: any) => {
        this.props.history.push({
            pathname: "/projectDetail",
            state: {
                projectId: item.id,
                projectName: item.project
            }
        });
    }

    onApplyButtonClick = () => {
        let { tableData, filterBy, option } = this.state;
        let filterData = tableData;
        if ((filterBy === "status" || filterBy === "project") && option === "") {
            if (option === "") {
                toast.warn(this.t("reports.toast.Selectoption", { data: filterBy.charAt(0).toUpperCase() + filterBy.slice(1) }))
            }
        } else {
            if (filterBy !== "all") {
                if (filterBy && option) {
                    filterData = tableData.filter((item: any) => item[filterBy] === option);
                }
            }

            this.setState({ filterData }, () => {
                this.onChangeHandler(this.state.search, 0);
            });
        }
    }

    onFilterByValueChange = (filterBy: any) => {
        let optionList: any[] = this.state.tableData;
        if (filterBy === null) {
            this.setState({
                filterData: this.state.tableData
            }, () => {
                this.onChangeHandler(this.state.search, 0);
            })
        }
        if (filterBy !== null) {
            if (filterBy.value === "all") {
                // option list state
                optionList = [];
            } else if (filterBy.value === "status") {
                // option list state in here
                optionList = statusList;
            } else if (filterBy.value === "project") {
                optionList = [...new Set(this.state.tableData.map((item: any) => item.project))];
                // option list state project
                optionList = optionList.map((item: any) => ({ label: item, value: item }));
            }
        } else {
            optionList = []
        }
        this.setState({ filterBy: filterBy !== null ? filterBy.value : "", optionList, option: "" });
    }

    onChangeHandler = (search: string, page: number) => {
        const { filterData, rowsPerPage } = this.state;
        let data = filterData.filter((item: any) => {
            if (search === "") {
                return true;
            } else if (
                item.project.toString().toLowerCase().includes(search.toLowerCase()) || item.status.toString().toLowerCase().includes(search.toLowerCase()) || item.type.toString().toLowerCase().includes(search.toLowerCase()) || item.DCManager.toString().toLowerCase().includes(search.toLowerCase())
            ) {
                return item;
            }
        });

        const dataLength = data.length;
        data = data.slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage
        );
        this.setState({ data, dataLength, page, search });
    }

    getReportDetail = () => {
        this.setState({ loading: true });

        const header = {
            "Content-Type": configJSON.reportContentType,
            token: localStorage.getItem("accesToken")
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getReportDetailApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.reportApiEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.reportApiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }

    getParticipantDetail = (id: any, name: string) => {
        this.setState({ loading: true, projectName: name, fileName: name + "_participants_list.csv" });

        const header = {
            "Content-Type": configJSON.participantDetailContentType,
            token: localStorage.getItem("accesToken")
        };

        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

        this.getParticipantDetailApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.participantDetailApiEndPoint}?id=${id}`);

        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));

        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.participantDetailApiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }
}

// Customizable Area End
