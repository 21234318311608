// Customizable Area Start
import * as Yup from 'yup';

const passwordRegExp = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/

export const resetPasswordSchema = (t:any) => {
  return Yup.object().shape({
    newPassword: Yup.string().matches(passwordRegExp, t("ResetPassword.resetPasswordSchema.Passwordmusthaveminimum8lettersasymbolupperandlowercaseandanumber")).required(t("ResetPassword.resetPasswordSchema.EnternewpasswordSchema")),
    confirmNewPassword: Yup.string().required(t("ResetPassword.resetPasswordSchema.Enterconfirmnewpassword")).oneOf([Yup.ref('newPassword'), null], t("ResetPassword.resetPasswordSchema.Passwordsmustmatch")),
  })
};
// Customizable Area End