import { Message } from "../../../../../framework/src/Message";
import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import { runEngine } from "../../../../../framework/src/RunEngine";
import MessageEnum, {
    getName,
} from "../../../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { toast } from "react-toastify";
// Customizable Area End

export const configJSON = require("../../config");

export interface Props {
    id: string;
    navigation: any;
    t: any;
}

export interface S {
    // Customizable Area Start
    loading: boolean;
    page: number;
    rowsPerPage: number;
    dataLength: number;
    count: number | null;
    employmentTypeId: any;
    employmentTypeName: string;
    search: string;
    openDialogName: string | null;
    data: any[];
    tableData: any[];
    filterData: any[];
    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class EmploymentTypeController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    getEmploymentTypeListApiCallId: string = "";
    addEmploymentTypeApiCallId: string = "";
    editEmploymentTypeApiCallId: string = "";
    deleteEmploymentTypeApiCallId: string = "";
    t: any;
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage),
        ];
        this.receive = this.receive.bind(this);
        runEngine.attachBuildingBlock(this, this.subScribedMessages);

        this.t = this.props.t;
        this.state = {
            // Customizable Area Start
            loading: false,
            page: 0,
            rowsPerPage: 20,
            dataLength: 0,
            data: [],
            search: "",
            filterData: [],
            count: null,
            employmentTypeId: null,
            employmentTypeName: "",
            tableData: [],
            openDialogName: "",
            // Customizable Area End
        };
    }

    async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
            const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

            if (apiRequestCallId && responseJson) {
                this.ApicallInRecivedFuncation(apiRequestCallId,responseJson)
            }
        }
    }

    async componentDidMount() {
        window.scrollTo(0, 0);
        this.getEmploymentTypeList();
    }

    ApicallInRecivedFuncation = (apiRequestCallId:any,responseJson:any) =>{
        if (responseJson.status === 500) {
            this.setState({ loading :false });
            toast.error(this.t("organizationFunction.employmentType.toast.somethingWrong"));
        } else if (!Array.isArray(responseJson.errors) && responseJson.errors) {
            this.setState({ loading: false }); toast.error(responseJson.errors);
        } else {this.handleApiResponse(apiRequestCallId, responseJson);}
    }

    handleApiResponse = (apiRequestCallId: string, responseJson: any) => {
        if (Array.isArray(responseJson.errors)) {
            if (responseJson.errors[0].message) {
                toast.error(responseJson.errors[0].message);
            } else if (responseJson.errors[0].name) {
                toast.error(responseJson.errors[0].name);
            } else if (responseJson.errors[0].employment_type_name) {
                toast.error(responseJson.errors[0].employment_type_name);
            } else {
                toast.error(this.t("organizationFunction.employmentType.toast.somethingWrong"));
            }
            this.setState({ loading: false });
        } else if (apiRequestCallId === this.getEmploymentTypeListApiCallId) {
            this.handelEmploymentTypeListApiResponse(responseJson);
        } else if (apiRequestCallId === this.addEmploymentTypeApiCallId) {
            this.handelAddEmploymentTypeApiResponse(responseJson);
        } else if (apiRequestCallId === this.editEmploymentTypeApiCallId) {
            this.handelEditEmploymentTypeApiResponse(responseJson);
        } else if (apiRequestCallId === this.deleteEmploymentTypeApiCallId) {
            this.handelDeleteEmploymentTypeApiResponse();
        }
    }

    handelEmploymentTypeListApiResponse = (responseJson: any) => {
        const data = responseJson.data.map((item: any) => {
            return {
                id: item.id,
                employmentTypeName: item.attributes.employment_type_name
            }
        });
        this.setState({ tableData: data, filterData: data, loading: false },
            () => { this.onChangeHandler(this.state.search, 0) }
        );
    }

    handelAddEmploymentTypeApiResponse = (responseJson: any) => {
        toast.success(this.t("organizationFunction.employmentType.toast.add"));
        let tableData = [...this.state.tableData];
        const obj = {
            id: responseJson.data.id,
            employmentTypeName: responseJson.data.attributes.employment_type_name
        };
        tableData = [obj, ...tableData];
        this.setState({ tableData, filterData: tableData, employmentTypeId: null, employmentTypeName: "", openDialogName: null, loading: false },
            () => { this.onChangeHandler(this.state.search, 0) }
        );
    }

    handelEditEmploymentTypeApiResponse = (responseJson: any) => {
        toast.success(this.t("organizationFunction.employmentType.toast.edit"));
        let tableData = [...this.state.tableData];
        tableData = tableData.map((item: any) => {
            if (item.id === this.state.employmentTypeId) {
                return {
                    id: responseJson.data.id,
                    employmentTypeName: responseJson.data.attributes.employment_type_name
                };
            } else {
                return item;
            }
        });

        this.setState({ tableData, filterData: tableData, employmentTypeId: null, employmentTypeName: "", openDialogName: null, loading: false },
            () => { this.onChangeHandler(this.state.search, this.state.page) }
        );
    }

    handelDeleteEmploymentTypeApiResponse = () => {
        toast.success(this.t("organizationFunction.employmentType.toast.delete"));
        let tableData = [...this.state.tableData];
        tableData = tableData.filter((item: any) => item.id !== this.state.employmentTypeId);
        this.setState({ tableData, filterData: tableData, employmentTypeId: null, employmentTypeName: "", openDialogName: null, loading: false },
            () => { this.onChangeHandler(this.state.search, this.state.page) }
        );
    }

    onChangeHandler = (search: string, page: number) => {
        const { filterData, rowsPerPage } = this.state;
        let data = filterData.filter((item: any) => {
            if (search === "") {
                return true;
            } else if (item.employmentTypeName.toString().toLowerCase().includes(search.toLowerCase())) {
                return item;
            }
        });

        const dataLength = data.length;
        let totalPage = Math.ceil(dataLength / this.state.rowsPerPage);
        page = totalPage === page ? page - 1 : page;
        data = data.slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage
        );
        this.setState({ data, dataLength, page, search });
    }

    getEmploymentTypeList = () => {
        this.setState({ loading: true });

        const header = {
            "Content-Type": configJSON.employmentTypeListContentType,
            token: localStorage.getItem("accesToken")
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getEmploymentTypeListApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.employmentTypeListApiEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.employmentTypeListApiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }

    addEmploymentType = () => {
        this.setState({ loading: true });

        const header = {
            "Content-Type": configJSON.addEmploymentTypeApiContentType,
            token: localStorage.getItem("accesToken")
        };

        const httpBody = {
            "employment_type_name": this.state.employmentTypeName,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.addEmploymentTypeApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.addEmploymentTypeApiEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.addEmploymentTypeApiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }

    editEmploymentType = () => {
        this.setState({ loading: true });

        const header = {
            "Content-Type": configJSON.editEmploymentTypeApiContentType,
            token: localStorage.getItem("accesToken")
        };

        const httpBody = {
            "employment_type_name": this.state.employmentTypeName,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.editEmploymentTypeApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.editEmploymentTypeApiEndPoint}/${this.state.employmentTypeId}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.editEmploymentTypeApiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }

    deleteEmploymentType = () => {
        this.setState({ loading: true });
        const header = {
            "Content-Type": configJSON.deleteEmploymentTypeApiContentType,
            token: localStorage.getItem("accesToken")
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.deleteEmploymentTypeApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.deleteEmploymentTypeApiEndPoint}/${this.state.employmentTypeId}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.deleteEmploymentTypeApiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }
}
// Customizable Area End