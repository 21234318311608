// Customizable Area Start
import React from 'react';
import {
    Box,
    Typography,
    FormControlLabel,
    Grid,
    Checkbox,
    InputBase
} from '@material-ui/core';
import { withRouter } from "react-router-dom";
import { styled } from "@material-ui/styles";
import { withTranslation } from "react-i18next";
import Select from "react-select";
//@ts-ignore
import InfiniteScroll from 'react-infinite-scroller';
import AccessRepositoryController from "./AccessRepositoryController.web";
import AppSidebar from "../../../../components/src/AppSidebar.web";
import Spinner from "../../../../components/src/Spinner.web";

class AccessRepository extends AccessRepositoryController {

    renderOption = (item: any, optionName: string) => {
        let element = <img src={item.image_url} alt="not found!" style={{ height: "100px", width: "100px" }} />;
        if (item.answer && item.answer?.includes("https://")) {
            element = <img src={item.answer} alt="not found!" style={{ height: "100px", width: "100px" }} />;
        } else if (item.type?.includes("video")) {
            element = <Box width={{ sx: "100%", md: "320px" }}>
                <video width="100%" height="auto" controls style={{ maxHeight: "240px" }}>
                    <source src={item.image_url} type="video/mp4" />
                    {this.t("accessRepository.label.videoNotSupport")}
                </video>
            </Box>
        } else if (item.answer) {
            element = <CustomInputBase
                fullWidth
                multiline
                readOnly
                style={{
                    backgroundColor: item.correct_answer ? "green" : "white",
                    color: item.correct_answer ? "white" : "black"
                }}
                placeholder={`${this.t("accessRepository.placeholder.option")} ${optionName}`}
                value={item.answer ?? ""}
            />
        }
        return element;
    }

    renderQuestionMedia = (mcq: any) => {
        let element = null;
        if (mcq.questionImageURL) {
            if (mcq.type?.includes("video")) {
                element = <Box mt="15px" mb="25px" width={{ xs: "100%", md: "320px" }}>
                    <video width="100%" height="auto" controls style={{ maxHeight: "240px" }}>
                        <source src={mcq.questionImageURL} type="video/mp4" />
                        {this.t("accessRepository.label.videoNotSupport")}
                    </video>
                </Box>
            } else {
                element = <img src={mcq.questionImageURL} alt="not found!" style={{ marginTop: "15px", height: "100px", width: "100px" }} />
            }
        }
        return element;
    }

    render() {
        return (
            <Box>
                <Spinner spinnerModal={this.state.loading} />
                <AppSidebar type="admin">
                    <Box margin="35px 0px" mr="30px">
                        <Box display={{ sm: "block", md: "flex" }} justifyContent="space-between" mb="40px">
                            <Box>
                                <Box mb="5px" fontFamily="ProximaNova-Bold" fontSize="44px" color="#1b3c69">{this.t("accessRepository.title")}</Box>
                                <Box color="#1b3c69">{this.t("accessRepository.description")}</Box>
                            </Box>
                            <Box maxWidth="300px" width="100%" color="#1b3c69" >
                                <Box>
                                    <Box mb="5px">{this.t("accessRepository.label.onlineTool")}</Box>
                                    <Select
                                        autofocus={true}
                                        openOnFocus={true}
                                        classNamePrefix="react-select"
                                        placeholder={this.t("accessRepository.placeholder.onlineTool")}
                                        isSearchable={false}
                                        options={this.state.onlineToolList}
                                        value={
                                            this.state.onlineToolList.filter(
                                                (option: any) =>
                                                    option.value === this.state.onlineToolId
                                            )[0]
                                        }
                                        onChange={(event: any) => {
                                            this.onOnlineToolChange(event.value);
                                        }}
                                    />
                                </Box>
                            </Box>
                        </Box>
                        <Box mt="40px">
                            <Box mb="30px">
                                <Box fontSize="22px" fontWeight="bold" fontFamily="ProximaNova-Semibold">
                                    {this.state.onlineToolList.filter(
                                        (option: any) =>
                                            option.value === this.state.onlineToolId
                                    )[0]?.label}
                                </Box>
                            </Box>
                        </Box>
                        {this.state.filterData.length > 0
                            ? <InfiniteScroll
                                pageStart={0}
                                loadMore={this.addMoreData}
                                hasMore={this.state.hasMore}
                                useWindow={true}
                                loader={<Box display="flex" justifyContent="center">{this.t("accessRepository.label.loading")}</Box>}
                            >
                                {this.state.filterData
                                    .slice(0, this.state.dataLength)
                                    .map((mcq: any, index: number) => {
                                        return (
                                            <Box key={`${index + 1}`} p="20px" mb="10px" borderRadius="8px" border="1.5px solid #1b3c69" style={{ backgroundColor: "#eff6ff" }}>
                                                <Box mb="20px">
                                                    <Typography style={{ fontFamily: "ProximaNova-Semibold" }}>{this.t("accessRepository.label.question")} {index + 1}</Typography>
                                                    <CustomInputBase
                                                        fullWidth
                                                        multiline
                                                        readOnly
                                                        placeholder={this.t("accessRepository.label.question")}
                                                        value={mcq.question ?? ""}
                                                    />
                                                    {this.renderQuestionMedia(mcq)}
                                                </Box>
                                                <Box>
                                                    <Grid container spacing={3}>
                                                        {mcq.options?.map((item: any, index: any) => {
                                                            const optionName = (index + 10).toString(36).toUpperCase();
                                                            const element = this.renderOption(item, optionName);
                                                            return (
                                                                <Grid item xs={12} md={6} key={`${index + 1}`}>
                                                                    <Box display="flex">
                                                                        {(!item.answer || item.answer?.includes("https://")) &&
                                                                            <FormControlLabel
                                                                                style={{ margin: "-11px 0px -5px -12px" }}
                                                                                control={
                                                                                    <Checkbox
                                                                                        style={{ color: "green" }}
                                                                                        disabled
                                                                                        checked={item.correct_answer}
                                                                                        name={`${this.t("accessRepository.placeholder.option")} ${optionName}`}
                                                                                    />
                                                                                }
                                                                                label=""
                                                                            />
                                                                        }
                                                                        <Typography variant="body2" style={{ marginBottom: "5px" }}>
                                                                            {this.t("accessRepository.label.option")} {optionName}
                                                                        </Typography>
                                                                    </Box>
                                                                    <Box>
                                                                        {element}
                                                                    </Box>
                                                                </Grid>
                                                            )
                                                        })}
                                                    </Grid>
                                                </Box>
                                            </Box>
                                        );
                                    })
                                }
                            </InfiniteScroll>
                            : !this.state.loading &&
                            <Box>
                                {this.t("accessRepository.label.notFound")}
                            </Box>
                        }
                    </Box>
                </AppSidebar>
            </Box>
        );
    }
}

export default withRouter(withTranslation()(AccessRepository));

const CustomInputBase = styled(InputBase)({
    border: "1px solid #d6d6d6",
    backgroundColor: "white",
    borderRadius: "10px",
    minHeight: "50px",
    padding: "10px 15px",
    fontSize: "14px",
    '& .MuiInputBase-input': {
        "&::placeholder": {
            fontSize: "14px"
        }
    }
});

// Customizable Area End
