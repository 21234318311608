// Customizable Area Start
import React from "react";

function ProjectsSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="20"
      height="20"
      viewBox="0 0 20 20"
    >
      <defs>
        <path id="path-1" d="M0 0H20V20H0z"></path>
      </defs>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(-35 -226)">
          <g transform="translate(35 226)">
            <mask id="mask-2" fill="#fff">
              <use xlinkHref="#path-1"></use>
            </mask>
            <path
              fill="#FFF"
              d="M17.47 2.65h-1.687V.965A.967.967 0 0014.82 0H2.53a.967.967 0 00-.964.964v15.422c0 .53.434.963.964.963h1.687v1.687c0 .53.433.964.964.964h8.192c.53 0 1.28-.297 1.666-.66l2.692-2.535c.387-.363.702-1.094.702-1.624V3.615a.967.967 0 00-.963-.964zm-13.253.964v13.012H2.53c-.13 0-.24-.11-.24-.24V.964c0-.13.11-.241.24-.241h12.29c.13 0 .24.11.24.24v1.688H5.18a.967.967 0 00-.963.963zM17.236 16.28l-2.693 2.534a1.279 1.279 0 01-.206.151v-2.217a.6.6 0 01.602-.602h2.411c-.037.05-.075.097-.114.134zm.474-1.098c0 .071-.013.154-.032.24h-2.739a1.328 1.328 0 00-1.325 1.326v2.5a1.26 1.26 0 01-.24.03H5.18c-.131 0-.241-.11-.241-.24V3.613c0-.13.11-.24.24-.24h12.29c.13 0 .24.11.24.24v11.567z"
              mask="url(#mask-2)"
            ></path>
            <path
              fill="#FFF"
              d="M14.82 6.024H7.831a.361.361 0 100 .723h6.987a.361.361 0 100-.723zM14.82 10.843H7.831a.361.361 0 100 .723h6.987a.361.361 0 100-.723zM11.687 13.253H7.832a.361.361 0 100 .723h3.855a.361.361 0 100-.723zM14.82 8.434H7.831a.361.361 0 100 .723h6.987a.361.361 0 100-.723z"
              mask="url(#mask-2)"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default ProjectsSvg;

// Customizable Area End
