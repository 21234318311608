// Customizable Area Start
import React from "react";
import { withTranslation } from "react-i18next";
import Table from "@material-ui/core/Table";
import TextField from "@material-ui/core/TextField";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import Button from "@material-ui/core/Button";
import { Select, MenuItem, Grid, Dialog, DialogContent } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { styled } from "@material-ui/styles";
import { Formik } from "formik";
import AsynSelect from "react-select";
import AddNewParticipant from "./addNewParticipant.web";
import AppSidebar from "../../../components/src/AppSidebar.web";
import Modal from "@material-ui/core/Modal";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import "./ManageParticipant.web.css";
import * as Yup from 'yup';
import { Props } from "./LandingPageController";
import { toast } from "react-toastify";
import ManageParticipantController from "./ManageParticipantController";
import Search from "@material-ui/icons/Search";
import Pagination from "@material-ui/lab/Pagination";
import Spinner from "../../../components/src/Spinner.web";
const locallanguage = localStorage.getItem("language") === "ar";
import { editPen, deleteIcon } from "./assets";

class ManageParticipant extends ManageParticipantController {
  constructor(props: Props) {
    super(props);
  }
  sortByList = [
    { label: "Status", value: "status" },
    { label: "Project", value: "name" },
  ];

  updateParticipantSchema = () => {
    return Yup.object().shape({
      first_name: Yup.string().required(this.t("manageParticipant.errorMessages.firstName")),
      last_name: Yup.string().required(this.t("manageParticipant.errorMessages.lastName")),
      user_name: this.state.isClientChanged
        ? Yup.string().test('Please enter different username', this.t("manageParticipant.errorMessages.diffUsername"),
          (value) => !this.userNameChanged(value)).required(this.t("manageParticipant.errorMessages.username"))
        : Yup.string().required(this.t("manageParticipant.errorMessages.username")),
      email: this.state.isClientChanged
        ? Yup.string().test('Please enter different email', this.t("manageParticipant.errorMessages.diffEmail"),
          (value) => !this.containsDeviceId(value)).email(this.t("manageParticipant.errorMessages.emailInvalid")).required(this.t("manageParticipant.errorMessages.email"))
        : Yup.string().email(this.t("manageParticipant.errorMessages.emailInvalid")).required(this.t("manageParticipant.errorMessages.email")),
      password: Yup.string().matches(
        /^(?=.*\d)(?=.*[!(@#$%^&*|}"'{:;<,>.?/])(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
        this.t("manageParticipant.errorMessages.passwordRule")
      ).required(this.t("manageParticipant.errorMessages.password")).nullable(),
      client_id: Yup.mixed().required(this.t("manageParticipant.errorMessages.organizationName")).nullable(),
    })
  }
  paginationHandleChange = (event: unknown, newPage: number) => {
    this.setState({
      ...this.state,
      page: newPage - 1,
    })
  }
  filterPaginationHandleChange = (event: unknown, newPage: number) => {

    this.setState({
      ...this.state,
      page: newPage - 1,
    })
  }
  containsDeviceId = (value: any) => {
    const { ParticipantDetails } = this.state;
    if (ParticipantDetails.email !== value) {
      return false;
    } else {
      return true;
    }
  };


  userNameChanged = (value: any) => {
    const { ParticipantDetails } = this.state;
    if (ParticipantDetails.user_name !== value) {
      return false;
    } else {
      return true;
    }
  };

  getErrorMessage = (touched: any, errors: any, value: string) => {
    return errors[value] &&
      <div className="text-danger">{errors[value]}</div>
  }
  getRedBorder = (props: any, value: string) => {
    const { errors } = props;
    return errors[value] ? "2px solid red" : ""
  }
  handleSubmits = (values: any) => {
    if (this.state.isClientChanged) {
      this.setState({ latestData: values });
      this.cloneParticipantData();
    } else {
      if (this.state.tools.length !== 0 && this.state.selectedTools !== 0) {
        this.handelSumitFirstCondtion(values)
      } else {
        this.updateParticipantData(
          values,
          this.state.ParticipantDetails.id
        );
      }
    }
  }

  handelSumitFirstCondtion = (values: any) => {
    if (this.state.selectedTools !== 0 && this.state.timerButton !== "") {
      if (this.state.FormUpdate) {
        this.updateParticipantData(
          values,
          this.state.ParticipantDetails.id
        );
      }
      this.state.selectedTools !== 0 &&
        this.handleResetData(values, this.state.ParticipantDetails.id);
    }
    else {
      if (this.state.selectedTools === 0) {
        toast.warning(this.t("manageParticipant.toast.Pleaseselecttoolname"))
      }
      if (this.state.timerButton === "") {
        toast.warning(this.t("manageParticipant.toast.Pleaseselectresettimeorextendtime"))
      }
    }
  }

  editHandleChange = (item: any) => {
    this.handleURLmodalOpen(item);
    this.getParticipantToolData(item.id)
    this.setState({
      FormUpdate: false,
      timerButton: ""
    })
  }
  clientHandleChange = (e: any, props: any) => {
    props.handleChange(e);
    this.handleClientChange(e.target.value, e, props)
  }
  clientHandleChangeValue = (e: any, props: any) => {
    if (e !== null) {
      props.setFieldValue("client_id", e.value)
    } else {
      props.setFieldValue("client_id", "")
    }
  }
  searchHandleChange = (e: any) => {
    this.onSearch(e.target.value, 0);
  }
  deletehandleChange = (item: any) => {
    this.handleOpenDeleteModal(item.attributes.id)
  }
  firstNameChange = (event: any, props: any) => {
    props.handleChange(event);
    this.setState({
      FormUpdate: true
    })
  }
  lastNameChange = (event: any, props: any) => {
    props.handleChange(event);
    this.setState({
      FormUpdate: true
    })
  }
  userNameChange = (event: any, props: any) => {
    props.handleChange(event);
    this.setState({
      FormUpdate: true
    })
  }
  emailChange = (event: any, props: any) => {
    props.handleChange(event);
    this.setState({
      FormUpdate: true
    })
  }
  passwordChange = (event: any, props: any) => {
    props.handleChange(event);
    this.setState({
      FormUpdate: true
    })
  }
  renderPagination = () => {
    return (
      <div className="table-Pagination-Data" data-testid="Pagination">
        {this.state.search === "" ? (
          <Pagination
            count={Math.ceil(this.state.data.length / this.state.rowsPerPage)}
            onChange={(event: unknown, newPage: number) =>
              this.paginationHandleChange(event, newPage)

            }
            showLastButton
            shape="rounded"
            dir="ltr"
            siblingCount={0}
            variant="outlined"
            showFirstButton
            boundaryCount={1}
          />
        ) : (
          <Pagination
            count={Math.ceil(this.state.filterData.length / this.state.rowsPerPage)}
            onChange={(event: unknown, newPage: number) =>
              this.filterPaginationHandleChange(event, newPage)

            }
            showFirstButton
            showLastButton
            shape="rounded"
            boundaryCount={1}
            dir="ltr"
            siblingCount={0}
            variant="outlined"
          />
        )}
      </div>
    )
  }

  renderForm = (props: any) => {
    return (
      <Grid
        className="label-parent"
        container
        spacing={3}
      >
        <Grid item xs={6}>
          <label className="labels">
            {this.t("manageParticipant.label.firstName")}
            <span style={{ color: "#f94b4b" }}>*</span>
          </label>
          <Box className="textfield-parent">
            <TextField

              name="first_name"
              id="first_name"
              onChange={(event: any) => {
                this.firstNameChange(event, props)
              }}
              onBlur={(e) => {
                this.handleBlurFunction(e, props)
              }}
              inputProps={{
                "data-test-id": "first_name"
              }}
              value={props.values.first_name}
              fullWidth
              className="client-textfields"
              variant="outlined"
              style={{ border: this.getRedBorder(props, "first_name") }}
            />
            {this.getErrorMessage(props.touched, props.errors, "first_name")}
          </Box>
        </Grid>

        <Grid item xs={6}>
          <label className="labels">
            {this.t("manageParticipant.label.lastName")}
            <span style={{ color: "#f94b4b" }}>*</span>
          </label>
          <Box className="textfield-parent">
            <TextField
              name="last_name"
              id="last_name"
              value={props.values.last_name}
              onChange={(event: any) => {
                this.lastNameChange(event, props)
              }}
              onBlur={(e) => {
                this.handleBlurFunction(e, props)
              }}
              className="client-textfields"
              variant="outlined"
              fullWidth
              style={{ border: this.getRedBorder(props, "last_name") }}
            />
            {this.getErrorMessage(props.touched, props.errors, "last_name")}

          </Box>
        </Grid>

        <Grid item xs={6}>
          <label className="labels">
            {this.t("manageParticipant.label.userNameLab")}{" "}
            <span style={{ color: "#f94b4b" }}>*</span>
          </label>
          <Box className="textfield-parent">
            <TextField
              name="user_name"
              id="user_name"
              value={props.values.user_name}
              onChange={(event: any) => {
                this.userNameChange(event, props)
              }}
              onBlur={props.handleBlur} className="client-textfields" variant="outlined" style={{ border: this.getRedBorder(props, "user_name") }}
              fullWidth
            />
            {this.getErrorMessage(props.touched, props.errors, "user_name")}

          </Box>
        </Grid>

        <Grid item xs={6}>
          <label className="labels"> {this.t("manageParticipant.label.email")} <span style={{ color: "#f94b4b" }}>*</span> </label>
          <Box className="textfield-parent">
            <TextField
              id="email"
              value={props.values.email}
              variant="outlined"
              onChange={(event: any) => {
                this.emailChange(event, props)
              }}
              onBlur={props.handleBlur}
              placeholder=""
              name="email"
              className="client-textfields"
              fullWidth
              style={{ border: this.getRedBorder(props, "email") }}
            />
            {this.getErrorMessage(props.touched, props.errors, "email")}

          </Box>
        </Grid>

        <Grid item xs={6}>
          <label className="labels"> {this.t("manageParticipant.label.passwordLab")} <span style={{ color: "#f94b4b" }}>*</span> </label>
          <Box className="textfield-parent">
            <TextField
              id="password"
              style={{ border: this.getRedBorder(props, "password") }}
              value={props.values.password}
              placeholder=""
              onChange={(event: any) => {
                this.passwordChange(event, props)
              }}
              onBlur={props.handleBlur}
              className="client-textfields"
              fullWidth
              variant="outlined"
              name="password"
            />
            {this.getErrorMessage(props.touched, props.errors, "password")}
          </Box>
        </Grid>

        <Grid item xs={6}>
          <label className="labels">
            {this.t("manageParticipant.label.orgName")}
            <span
              style={{ color: "#f94b4b" }}
            >*</span>
          </label>
          <Box className="textfield-parent" style={{ marginTop: "15px", maxHeight: 100 }}>
            <Mangeparticepent>
              <AsynSelect
                classNamePrefix="react-select"
                autofocus={true}
                className="AsyncSelect"
                isSearchable={true}
                isClearable={true}
                openOnFocus={false}
                options={this.state.allClients.map((item: any) => {
                  return {
                    label: item.attributes.full_name,
                    value: item.attributes.id
                  }
                })}
                value={
                  this.state.allClients.map((item: any) => {
                    return {
                      label: item.attributes.full_name,
                      value: item.attributes.id
                    }
                  }).filter(
                    (option: any) => option.value === props.values.client_id
                  )[0] ?? []
                }
                onChange={(e: any) => {
                  this.clientHandleChangeValue(e, props)
                }}
                onBlur={props.handleBlur}
              />
            </Mangeparticepent>
            {this.getErrorMessage(props.touched, props.errors, "client_id")}
          </Box>
        </Grid>
      </Grid>
    )
  }
  renderTableBody = () => {
    return (
      <TableBody>
        {this.state.filterData.length > 0 ? (<>{this.state.loader ? (
          <Spinner spinnerModal={true} />) : (
          this.state.filterData
            .slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)
            .map((item: any, key: any) => (
              <TableRow key={item.id}>
                <TableCell
                  style={{
                    fontFamily: "ProximaNova-Regular",
                    fontSize: "18px",
                    color: "#000"
                  }}
                  align="center"
                >
                  <span style={{
                    display: 'block',
                    width: '200px',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  }}>
                    {item.attributes.first_name}{" "}
                    {item.attributes.last_name}
                  </span>
                </TableCell>
                <TableCell
                  style={{
                    fontFamily: "ProximaNova-Regular",
                    fontSize: "18px",
                    color: "#000",
                  }}
                  align="center"
                >
                  <span style={{
                    display: 'block',
                    width: '150px',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  }}>

                    {item.attributes.client_name === "" ? "---" : item.attributes.client_name}
                  </span>
                </TableCell>
                <TableCell
                  style={{
                    fontFamily: "ProximaNova-Regular",
                    fontSize: "18px",
                    color: "#000",
                  }}
                  align="center"
                >
                  <span style={{
                    display: 'block',
                    width: '100px',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  }}>

                    {item.attributes.user_name}
                  </span>
                </TableCell>
                <TableCell
                  style={{
                    fontFamily: "ProximaNova-Regular",
                    fontSize: "18px",
                    color: "#000",
                  }}
                  align="center"
                >
                  <span style={{
                    display: 'block',
                    width: '100px',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  }}>
                    {item.attributes.email}
                  </span>
                </TableCell>
                <TableCell
                  style={{
                    fontFamily: "ProximaNova-Regular",
                    fontSize: "18px",
                    color: "#000",
                  }}
                  align="center"
                >
                  <span style={{
                    display: 'block',
                    width: '100px',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  }}>
                  </span>
                    {item.attributes.password}
                </TableCell>
                <TableCell
                  style={{
                    fontFamily: "ProximaNova-Regular",
                    fontSize: "18px",
                    color: "#000",
                  }}
                  align="center"
                >
                  <div style={{ display: "flex" }}>
                    <div
                      style={{
                        color: "#f94b4b",
                        fontFamily: "ProximaNova-semibold",
                        cursor: "Pointer",
                      }}
                      id="edit"
                      onClick={() => {
                        this.editHandleChange(item)
                      }}
                    >
                      <figure>
                        {" "}
                        <img
                          src={editPen}
                          alt="Pen"
                          width="21"
                          height="21"
                        />
                      </figure>
                    </div>

                    <div
                      style={{
                        color: "#f94b4b",
                        fontFamily: "ProximaNova-semibold",
                        cursor: "Pointer",
                        margin: "0px 15px",
                      }}
                      id="delete"
                      onClick={() => { this.deletehandleChange(item) }}
                    >
                      <figure>
                        {" "}
                        <img
                          src={deleteIcon}
                          alt="Pen"
                          width="21"
                          height="21"
                        />
                      </figure>
                    </div>
                  </div>
                </TableCell>
              </TableRow>
            ))
        )}
        </>
        ) : (
          <TableRow>
            <TableCell
              colSpan={6}
              align="center"
              style={{
                fontFamily: "ProximaNova-Regular",
                fontSize: "18px",
                color: "#000",
                whiteSpace: "nowrap",
              }}
            >
              {this.t("manageParticipant.label.notFound")}
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    )
  }

  URLmodelRender = () => {
    return (
      this.state.URLmodal && (
        <Modal
          open={this.state.URLmodal}
          onClose={this.handleURLmodalClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <ModelEditStyle>
            <Box className="modalStyle editModal">
              <div className="closeImg" onClick={this.handleURLmodalClose}>
                X
              </div>

              <Typography
                className="helpModalTitle"
                variant="h6"
                component="h2"
                style={{
                  color: "#1b3c69",
                  fontSize: "40px",
                  fontFamily: "ProximaNova-Bold",
                  textAlign: "left",
                }}
              >
                {this.t("manageParticipant.title.edit", {
                  name: this.state.ParticipantName,
                })}
              </Typography>

              <div>
                <Formik
                  initialValues={{
                    first_name: this.state.ParticipantDetails.first_name,
                    last_name: this.state.ParticipantDetails.last_name,
                    user_name: this.state.ParticipantDetails.user_name,
                    email: this.state.ParticipantDetails.email,
                    password: this.state.ParticipantDetails.password,
                    client_id: this.state.ParticipantDetails.client_id,
                    time: 15
                  }}
                  validationSchema={this.updateParticipantSchema}
                  onSubmit={(values) => {
                    this.handleSubmits(values)
                  }}
                  validateOnChange={true}
                  validateOnBlur={true}
                >
                  {(props) => (
                    <form onSubmit={props.handleSubmit}>
                      <div style={{ marginTop: "15px" }}>
                        {this.renderForm(props)}
                      </div>
                        {this.state.tools.filter((item: any) => item?.name?.toLowerCase() !== "psychometric").length !== 0 && 
                        <Grid item xs={12} className="resetContainer">
                        <label className="labels">
                          {this.t("manageParticipant.label.selectTool")}as
                        </label>
                        <Box className="toolsbuttons">
                          {this.state.tools.filter((item: any) => item?.name?.toLowerCase() !== "psychometric").map(
                            (tool: any, index: any) => {
                              return (
                                <Box key={tool.id}>
                                  <Button
                                    onClick={() =>
                                      this.handleTool(tool.id, tool)
                                    }
                                    id="toolName"
                                    data-testid={
                                      "toolNameBtn-" + tool.id
                                    }
                                    className={this.state.selectedTools === tool.id ? "SelectBtn" : "UnSelectBtn"}>
                                    {tool.name}
                                  </Button>
                                </Box>
                              );
                            }
                          )}
                        </Box></Grid>
                        }
                      <Grid>
                        {this.state.setTool && <Box>{!this.state.setTool.answerd_question ? "0" : this.state.setTool.answerd_question} {" "} {this.t("manageParticipant.label.outof")} {" "} {!this.state.setTool.total_question ? "0" : this.state.setTool.total_question} {" "}
                          {this.t("manageParticipant.label.questionsareanswerdin")} {!this.state.setTool.time_consumed || this.state.setTool.time_consumed < 0 ? "0" : this.state.setTool.time_consumed} {this.t("manageParticipant.label.Minutes")} </Box>}
                      </Grid>
                      {this.state.tools.length !== 0 && (
                        <Grid item xs={12} className="resetContainer">
                          <label className="labels">
                            {this.t(
                              "manageParticipant.label.singleOption"
                            )}
                          </label>

                          <Box className="toolsbuttons">
                            <Button
                              onClick={() =>
                                this.handleTimerButton("reset")
                              }
                              id="reset"
                              style={{
                                textTransform: "capitalize",
                                fontFamily: "ProximaNova-Regular",
                              }}
                              disabled={this.state.selectedTools === 0}
                              className={
                                this.state.timerButton === "reset"
                                  ? "SelectBtn"
                                  : "UnSelectBtn"
                              }
                            >
                              {this.t(
                                "manageParticipant.button.resetTime"
                              )}
                            </Button>
                            <Button
                              onClick={() =>
                                this.handleTimerButton("extend")
                              }
                              id="extend"
                              style={{
                                textTransform: "capitalize",
                                fontFamily: "ProximaNova-Regular"
                              }}
                              disabled={this.state.selectedTools === 0}
                              className={
                                this.state.timerButton === "extend"
                                  ? "SelectBtn"
                                  : "UnSelectBtn"
                              }
                            >
                              {this.t(
                                "manageParticipant.button.extendTime"
                              )}
                            </Button>
                          </Box>
                          {this.state.timerButton === "extend" && (
                            <Box className="textfield-parent label-parent">
                              <Select
                                className="client-textfields client-select"
                                defaultValue={props.values.time}
                                fullWidth
                                name="time"
                                id="time"
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                                variant="outlined"
                              >
                                {this.state.extendTime.map(
                                  (item: any) => {
                                    return (
                                      <MenuItem
                                        key={item.value}
                                        value={item.value}
                                      >
                                        {item.label}
                                      </MenuItem>
                                    );
                                  }
                                )}
                              </Select>
                              {this.getErrorMessage(
                                props.touched,
                                props.errors,
                                "client_id"
                              )}
                            </Box>
                          )}
                        </Grid>
                      )}


                      <div
                        style={{
                          color: "#1b3c69",
                          fontSize: "18px",
                          fontFamily: "ProximaNova-regular",
                          textAlign: "left",
                          lineHeight: "1.27",
                          padding: "2px",
                        }}
                      >
                        <Button
                          className="cancelBtn"
                          size="large"
                          onClick={this.handleURLmodalClose}
                        >
                          {this.t("manageParticipant.button.cancel")}
                        </Button>
                        <Button
                          className="saveBtn"
                          type="submit"
                          size="large"
                        >
                          {this.state.isClientChanged
                            ? this.t("manageParticipant.button.clone")
                            : this.t("manageParticipant.button.save")}
                        </Button>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </Box>
          </ModelEditStyle>
        </Modal>
      )
    )
  }

  DeleteModalRender = () => {
    return (
      this.state.deleteModal && (
        <Dialog
          fullWidth={false}
          maxWidth={"sm"}
          scroll="body"
          open={this.state.deleteModal}
          onClose={this.handleCloseDeleteModal}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <DialogContent style={{ padding: "0px", maxWidth: "500px" }}>
            <Box
              style={{
                textAlign: "right",
                paddingTop: "0px !important",
                margin: "10px 10px 0px 0px",
              }}
            >
              <CloseIcon
                style={{
                  cursor: "pointer",
                  color: "#787878",
                  fontSize: "35px",
                }}
                id="open={this.state.deleteModal}sdsd"
                onClick={this.handleCloseDeleteModal}
              />
            </Box>
            <Box
              padding={{ xs: "15px 25px 25px", sm: "15px 70px 40px" }}
              display="flex"
              alignItems="center"
              id="open={this.state.deleteModal}"
              flexDirection="column"
            >
              <Box
                fontWeight="bolder"
                fontSize={{ xs: "20px", sm: "30px" }}
                id="modal-title"
                mb="20px"
                fontFamily="ProximaNova-Bold"
              >
                {this.t("manageParticipant.title.delete")}
              </Box>
              <Box fontSize="18px" textAlign="center">
                {this.t("manageParticipant.description.delete")}
              </Box>
              <Box display="flex" mt="30px">
                <CustomButton
                  onClick={this.handleCloseDeleteModal} id="cancel" style={{ marginBottom: "0px", padding: "5px 10px", marginLeft: locallanguage ? "10px" : "unset" }}>
                  {this.t("manageParticipant.button.cancel")}
                </CustomButton>
                <RedButton style={{ padding: "5px 10px" }} onClick={() => { this.deleteParticipantData(this.state.deleteId) }}
                >
                  {this.t("manageParticipant.button.delete")}
                </RedButton>
              </Box>
            </Box>
          </DialogContent>
        </Dialog>
      )
    )
  }
  render() {
    return (
      <Mangeparticepent className="manage-participant manage-client" data-testid="MainDiv">
        {this.state.loader && <Spinner spinnerModal={this.state.loader} />}
        <AppSidebar type="admin">
          <div className="main_page">
            <div className="client_container" style={{ marginBottom: "40px" }}>
              <div>
                <h4>{this.t("manageParticipant.title.main")}</h4>
                <p>{this.t("manageParticipant.description.main")}</p>
              </div>
              <div className="">
                <AddNewParticipant t={this.t} navigation={undefined} id={""} />
              </div>
            </div>
            <Box style={{ borderRadius: "10px", backgroundColor: "#eff6ff" }}>
              <Grid
                spacing={2}
                style={{ padding: "20px 15px 10px 15px", width: "100%" }}
                container
                alignItems="flex-end"
              >
                <Grid item xs={12} sm={6} md={5}>
                  <Box style={{ color: "#1b3c69" }}>
                    <div style={{ marginBottom: "5px", fontSize: "18px" }}>
                      {this.t("manageParticipant.FilterBy.FilterByLabel")}
                    </div>
                    <AsynSelect
                      classNamePrefix="react-select"
                      className="clientList"
                      id="clientList"
                      placeholder={this.t("manageParticipant.FilterBy.FilterByLabel")}
                      autofocus={true}
                      isSearchable={true}
                      isClearable={true}
                      openOnFocus={true}
                      options={this.state.clientList.filter((obj: any, index: any, arr: any) => {
                        return this.state.clientList.findIndex((item: any) => item.label.toLowerCase() === obj.label.toLowerCase()) === index
                      })}
                      value={
                        this.state.clientList.filter(
                          (option: any) => option.value === this.state.filterselectedclinetname
                        )[0] ?? []
                      }
                      onChange={(e) => this.handleFilterBy(e)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={5}>
                  <Box style={{ color: "#1b3c69" }}>
                    <div style={{ marginBottom: "5px", fontSize: "18px" }}>
                      {this.t("manageParticipant.FilterBy.SelectOption")}
                    </div>
                    <AsynSelect
                      classNamePrefix="react-select"
                      className="filterParticipant"
                      id="filterParticipant"
                      placeholder={this.t("manageParticipant.FilterBy.SelectOption")}
                      isSearchable={true}
                      isClearable={true}
                      options={this.state.ParticipantList}
                      value={
                        this.state.ParticipantList.filter(
                          (option: any) => option.value === this.state.filterselectedParticipantName
                        )[0] ?? []
                      }
                      onChange={(e: any) => this.handleOption(e)}
                      data-testid="selectOption"
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                  <Box>
                    <RedButton data-testid="applyBtn"
                      onClick={this.HandeApplyFilter}
                    >
                      {this.t("MyProjects.button.Apply")}
                    </RedButton>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <div style={{ margin: "100px 30px 0px 0px" }} className={locallanguage ? "SearchIcon" : ""}>
              <div
                className="TextfieldSearch"
                style={{
                  marginBottom: "10px",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <TextField
                  id="outlined-basicsas"
                  value={this.state.search}
                  onChange={(e: any) => {
                    this.searchHandleChange(e)

                  }}
                  label={this.t("manageParticipant.label.search")}
                  className="searchInput"
                  variant="outlined"
                  InputLabelProps={{
                    style: {
                      fontFamily: "ProximaNova-Regular",
                      zIndex: 0,
                    },
                  }}
                  InputProps={{
                    endAdornment: <Search />,
                  }}
                />
              </div>
              <div className="manage-client-table">
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        style={{
                          fontFamily: "ProximaNova-Bold",
                          fontSize: "16px",
                        }}
                        data-testid="UserName"
                        align="center"
                      >
                        {this.t("manageParticipant.label.name")}
                      </TableCell>
                      <TableCell
                        style={{
                          fontFamily: "ProximaNova-Bold",
                          fontSize: "16px",
                          textTransform: "uppercase"
                        }}
                        data-testid="UserName"
                        align="center"
                      >
                        {this.t("manageParticipant.label.ClientsName")}
                      </TableCell>
                      <TableCell
                        style={{
                          fontFamily: "ProximaNova-Bold",
                          fontSize: "16px"
                        }}
                        align="center"
                        id="manageParticipant.label.actionss"
                      >
                        {this.t("manageParticipant.label.username")}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          fontSize: "16px",
                          fontFamily: "ProximaNova-Bold",
                        }}
                        id="manageParticipant.label.action3"
                      >
                        {this.t("manageParticipant.label.emailId")}
                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: "16px",
                          fontFamily: "ProximaNova-Bold",
                        }}
                        id="manageParticipant.label.action2"
                        align="center"
                      >
                        {this.t("manageParticipant.label.password")}
                      </TableCell>
                      <TableCell
                        style={{
                          fontFamily: "ProximaNova-Bold",
                          fontSize: "16px",
                        }}
                        id="manageParticipant.label.action"
                        align="center"
                      >
                        {this.t("manageParticipant.label.action")}
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  {this.renderTableBody()}
                </Table>
              </div>
              {this.renderPagination()}
            </div>
            {this.URLmodelRender()}

            {/* Delete Modal */}

            {this.DeleteModalRender()}
          </div>
        </AppSidebar>
      </Mangeparticepent>
    );
  }
}
const CustomButton = styled(Button)({
  borderRadius: "8px",
  fontSize: "18px",
  color: "#f26a42",
  fontFamily: "ProximaNova-Semibold",
  backgroundColor: "white",
  border: "1px solid #f26a42",
  marginRight: "15px",
  textTransform: "none",
  padding: "8px 25px",
  "&:hover": {
    color: "#f26a42",
    backgroundColor: "white",
  },
  "@media(max-width:600px)": {
    width: "100%",
  },
  "@media(max-width:992px)": {
    padding: "7px 15px",
  },
});

const Mangeparticepent = styled('div')({
  '& .AsyncSelect': {
    borderRadius: '15px',
    boxShadow: '0',
    marginTop: '15px !important',
    '& .react-select__control': {
      border: "1px solid #787878 !important"
    },
  },
  '& .SearchIcon': {
    '& .MuiInputBase-root': {
      display: "flex",
      flexDirection: "row-reverse"
    }
  }
})

const RedButton = styled(Button)({
  borderRadius: "10px",
  fontFamily: "ProximaNova-Semibold",
  textTransform: "none",
  padding: "9px 25px",
  backgroundColor: "#f94b4b",
  color: "white",
  fontSize: "18px",
  "&:hover": {
    color: "white",
    backgroundColor: "#f94b4b",
  },
  "@media(max-width:600px)": {
    width: "100%",
    marginTop: "27px",
  },
  "@media(max-width:992px)": {
    padding: "8px 15px",
  },
});

const ModelEditStyle = styled("div")({
  "& h2 , label , .labels": {
    fontFamily: "ProximaNova-Regular",
    fontWeight: "400"
  },
  "& .SelectBtn": {
    border: '1px solid #f94b4c',
    borderRadius: '5px',
    margin: '10px 10px 0px 0',
    cursor: 'pointer',
    backgroundColor: '#f94b4c ',
    color: 'white'
  },
  '& .UnSelectBtn': {
    border: '1px solid #333',
    borderRadius: '5px',
    margin: '10px 10px 0px 0',
    cursor: 'pointer'
  }
})
export default withTranslation()(ManageParticipant);
// Customizable Area End
