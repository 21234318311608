export const behaviour = require("../assets/behaviour.png");
export const accessor = require("../assets/assessor.png");
export const critical = require("../assets/critical.png");
export const criticaly = require("../assets/critical2.png");
export const logo = require("../assets/logo.png");
export const flag = require("../assets/flag.png");
export const hazard = require("../assets/hazard.png");
export const question = require("../assets/question.png");
export const groupIcon = require("../assets/groupIcon.png");
export const groupIconGray = require("../assets/groupIcongrey.png");
export const questionIcon = require("../assets/questionIcon.png");
export const india = require("../assets/india.png");
export const groupCheck = require("../assets/groupCheck.png");
export const excelIcon = require("../assets/excel.svg");
export const pdfIcon = require("../assets/pdf.png")
export const editIcon = require("../assets/edit.png")
export const copyIcon = require("../assets/copy.png");
export const deleteIcon = require("../assets/delete.png")
export const pdfFile = require("../assets/delete.png")
