import { sendAPIRequest } from "../../../components/src/utils";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { IBlock } from "../../../framework/src/IBlock";
import { TableCellProps } from '@material-ui/core';
// Customizable Area Start
// Customizable Area End
export const configJSON = require("./config");
export interface Props {
    id?: string;
    navigation?:  {
        getParam: (param: string, defaultValue?: unknown) => unknown,
        goBack: (routeKey?: string | null) => boolean | null,
      };
}
export interface S {
    // Customizable Area Start
    page: number;
    rowsPerPage: number;
    open: boolean;
    startHasValue: boolean;
    startDateFocus: boolean;
    endHasValue: boolean;
    endDateFocus: boolean;
    token: string;
    coManagerList: {
        label: string;
        value: string;
    }[];
    managerList: {
        label: string;
        value: string;
    }[];
    client: {
        label: string;
        value: string;
    }[];
    tempObj: {
        name: string,
        start_date: string,
        end_date: string,
        manager_id: string,
        co_manager_id: string,
        client_id: string,
    },
    rows: {
        name: string,
        start_date: string,
        end_date: string,
        manager_id: string,
        co_manager_id: string,
        client_id: string,
    }[]
    columns: {
        columnId: string;
        label?: string;
        minWidth?: number;
        align?: TableCellProps['align'];
    }[];
    csvData: {
        name: string,
        start_date: string,
        end_date: string,
        manager_id: string,
        co_manager_id: string,
        client_id: string,
    }[]
    // Customizable Area End
}
export interface SS {
    // Customizable Area Start
    id: string;
    // Customizable Area End
}
export default class ProjectReportingController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getProjectReportingCallId: string = "";
    createProject: string = "";
    getCoManagersDataCallId: string = "";
    getManagersDataCallId: string = "";
    getClientCallId: string = "";
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
        ];
        this.state = {
            // Customizable Area Start
            page: 0,
            rowsPerPage: 20,
            open: false,
            startHasValue: false,
            startDateFocus: false,
            endHasValue: false,
            endDateFocus: false,
            token: (typeof localStorage !== "undefined" && localStorage.getItem('accesToken')) || '',
            coManagerList: [],
            managerList: [],
            client: [],
            tempObj: {
                name: '',
                start_date: '',
                end_date: '',
                manager_id: '',
                co_manager_id: '',
                client_id: ''
            },
            rows: [{
                name: '',
                start_date: '',
                end_date: '',
                manager_id: '',
                co_manager_id: '',
                client_id: ''
            }],
            columns: [
                { columnId: 'name', label: 'Project Name', minWidth: 170, },
                {
                    columnId: 'client',
                    label: 'Client',
                    minWidth: 170,
                },
                { columnId: 'start_date', label: 'Start Date', minWidth: 170, },
                {
                    columnId: 'end_date',
                    label: 'End Date',
                    minWidth: 170,
                },
            ],
            csvData: [
                {
                    name: '',
                    start_date: '',
                    end_date: '',
                    manager_id: '',
                    co_manager_id: '',
                    client_id: '',
                }
            ],
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        super.componentDidMount();
        this.getProjectReport();
        this.getCoManagerData();
        this.getManagerData();
        this.getClientData();
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);

        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );
        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (apiRequestCallId && responseJson) {
            this.apiAfterResCalls(apiRequestCallId, responseJson)
        }
        // Customizable Area Start
        // Customizable Area End
    }
    apiAfterResCalls = (apiRequestCallId: string, responseJson: { data: { id: number, attributes: { name: string, client: { email: string, }, start_date: string, end_date: string, manager_id: string, co_manager_id: string, client_id: string, full_name: string }, }[], errors: string }) => {

        if (apiRequestCallId === this.getProjectReportingCallId) {
            this.getProjectReportData(responseJson)
        }
        else if (apiRequestCallId === this.getCoManagersDataCallId) {
            this.handleCoManagerApiResponse(responseJson);
        }
        else if (apiRequestCallId === this.createProject) {
            this.handleCreateProjectApiResponse(responseJson);
        }
        else if (apiRequestCallId === this.getManagersDataCallId) {
            this.handleManagerApiResponse(responseJson);
        }
        else if (apiRequestCallId === this.getClientCallId) {
            this.handleClientApiResponse(responseJson);
        }
    }
    getProjectReportData = (responseJson: {
        data: {
            id: number, attributes: {
                name: string,
                client: { email: string },
                start_date: string,
                end_date: string,
                manager_id: string,
                co_manager_id: string,
                client_id: string
            }
        }[], errors: string
    }) => {

        if (!responseJson.errors) {
            let tempArr = responseJson.data.map((item: {
                attributes: {
                    name: string;
                    client: { email: string };
                    start_date: string;
                    end_date: string;
                    manager_id: string;
                    co_manager_id: string;
                    client_id: string;
                }
            }) => {
                let { name, start_date, end_date, manager_id, co_manager_id, client_id, } = item.attributes;
                return {
                    name,
                    client: item.attributes?.client?.email,
                    start_date,
                    end_date,
                    manager_id,
                    co_manager_id,
                    client_id,
                }
            })
            this.setState({ ...this.state, rows: tempArr })
        }
        else {
            this.parseApiErrorResponse(responseJson);
        }
    }
    getProjectReport = () => {
        this.getProjectReportingCallId = sendAPIRequest(configJSON.getProjectReportingCall, {
            headers: { 'token': this.state.token, 'Content-Type': 'application/json' },
        });
    }

    getCoManagerData = () => {
        this.getCoManagersDataCallId = sendAPIRequest(configJSON.getCoManagersDataAPICall, {
            headers: { 'token': this.state.token, 'Content-Type': 'application/json' },
        });
    }

    getManagerData = () => {
        this.getManagersDataCallId = sendAPIRequest(configJSON.getManagersDataAPICall, {
            headers: { 'token': this.state.token, 'Content-Type': 'application/json' },
        });
    }

    getClientData = () => {
        this.getClientCallId = sendAPIRequest(configJSON.getClientDataAPICall, {
            headers: { 'token': this.state.token, 'Content-Type': 'application/json' },
        });
    }
    handleCreateProjectApiResponse = (responseJson: { errors: string }) => {
        if (!responseJson.errors) {
            this.getProjectReport()
        }
    }
    handleCoManagerApiResponse = (responseJson: { data: { id: number, attributes: { full_name: string } }[] }) => {

        const coManagerList = responseJson.data.map((item: { id: number, attributes: { full_name: string } }) => {
            return {
                label: item.attributes.full_name,
                value: String(item.id),
            };
        });
        this.setState({ coManagerList });
    };
    handleManagerApiResponse = (responseJson: { data: { id: number, attributes: { full_name: string } }[] }) => {

        const managerList = responseJson.data.map((item: { id: number, attributes: { full_name: string } }) => {
            return {
                label: item.attributes.full_name,
                value: String(item.id),
            };
        });
        this.setState({ managerList });
    };
    handleClientApiResponse = (responseJson: { data: { id: number, attributes: { full_name: string } }[] }) => {

        const client = responseJson.data.map((item: { id: number, attributes: { full_name: string } }) => {
            return {
                label: item.attributes.full_name,
                value: String(item.id),
            };
        });
        this.setState({ client });
    };
    handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
        this.setState({ page: newPage })
    };
    handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ rowsPerPage: parseInt(event.target.value), page: 0 });
    };
    handleClickOpen = () => {
        this.setState({ open: true });
    };
    handleClose = () => {
        this.setState({
            open: false, tempObj: {
                name: '', start_date: '', end_date: '', manager_id: '',
                co_manager_id: '',
                client_id: '',
            }, startDateFocus: false, startHasValue: false, endDateFocus: false, endHasValue: false
        });
    };
    handleSubmitData = async () => {
        let body: object = this.state.tempObj
        this.createProject = sendAPIRequest(configJSON.createProjectCall, {
            method: "POST",
            body: body,
            headers: { 'token': this.state.token, 'Content-Type': 'application/json' },
        });
        this.handleClose()
        this.setState({ startDateFocus: false, startHasValue: false, endDateFocus: false, endHasValue: false })
    };

    startDateChange = (event: { target: { value: string, name: string } }) => {
        if (event.target.value) this.setState({ startHasValue: true });
        else this.setState({ startHasValue: false });
        this.handleChange({ value: event.target.value, name: event.target.name })
    }

    endDateChange = (event: { target: { value: string, name: string } }) => {
        if (event.target.value) this.setState({ endHasValue: true });
        else this.setState({ endHasValue: false })
        this.handleChange({ value: event.target.value, name: event.target.name })
    }

    startDateFocus = () => this.setState({ startDateFocus: true });
    startDateBlur = () => this.setState({ startDateFocus: false });
    endDateFocus = () => this.setState({ endDateFocus: true });
    endDateBlur = () => this.setState({ endDateFocus: false });

    downloadCsvFile = () => {
        this.setState({
            csvData: this.state.rows
        })
    }

    handleChange = (event: { value: unknown, name?: string }) => {
        event.name && this.setState({ tempObj: { ...this.state.tempObj, [event.name]: event.value } });
    }

    handleOnchange = (name: string, event: React.ChangeEvent<{ name?: string, value: unknown }>, setFieldValue: (name: string, value: string) => void) => {
        setFieldValue(name, event.target.value as string);
        this.handleChange({ value: event.target.value, name: event.target.name })
    }
}