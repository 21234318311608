// Customizable Area Start
import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { toast } from "react-toastify";

export const configJSON = require("./config");

export interface Props {
    navigation?: any;
    id?: string;
    t: any;
}

interface S {
    data: any;
    loader: boolean;
    classes: any;
    events: any;
    weekendsVisible:boolean;
}

interface SS {
    id: any;
}


export default class ParticipantEventsController extends BlockComponent<
    Props,
    S,
    SS
> {

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this)
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.PostDetailDataMessage)
        ]


        this.state = {
            data: [],
            loader: true,
            classes: "",
            weekendsVisible: true,
            events: [],
        };
        this.t = this.props.t;
        // @ts-ignore
        this.callRef = React.createRef();
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }
    t: any;

    handleToastMessage() {
        toast.success('participant added succesfully', {
            position: toast.POSITION.TOP_RIGHT
        });
    }

    handleDashboard = () => {
        this.props.navigation.navigate("Dashboard")
    }

    sentMessage(data: any) {
        const msg: Message = new Message(getName(MessageEnum.PostDetailDataMessage))
        msg.properties['text'] = data
        this.send(msg)
    }
}
// Customizable Area End
