// Customizable Area Start
import React from "react";
import {
  Box,
  Button,
  Card,
  Grid,
  Typography,
  Divider,
  IconButton,
  Radio,
  Modal,
  Fade,
  Backdrop,
} from "@material-ui/core";
import ArrowForwardIosOutlinedIcon from "@material-ui/icons/ArrowForwardIosOutlined";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import ArrowBackIosOutlinedIcon from "@material-ui/icons/ArrowBackIosOutlined";
import {
  flag,
  questionIcon,
  groupIcon,
  groupIconGray,
  groupCheck,
  hazard,
  question,
} from "./assets";
import { withTranslation } from "react-i18next";
import AppHeader from "../../../components/src/AppHeader.web";
import AppFooter from "../../../components/src/AppFooter.web";
import Spinner from "../../../components/src/Spinner.web";
import McqSjtTestController, {
  Props,
} from "./McqSjtTestController.web";
import "./McqTest.web.css";
const locallanguage = localStorage.getItem("language") === "ar";

class McqSjtTest extends McqSjtTestController {
  constructor(props: Props) {
    super(props);
  }

  showPopup = () => {
    const { popup } = this.state;
    let com = <></>;
    if (popup === "switchTab") {
      com = (
        <Box className="warning">
          <CloseOutlinedIcon
            className="closeIcon"
            id="switchTab"
            onClick={() => {
              const count = Number(this.state.swichedTabCount) + 1
              this.setState({ swichedTabCount: count })
              this.handleWarning("close")
            }}
          />
          <img className="imgSize" src={hazard} alt="Flag" />
          <p className="title">{this.t("test.title.warning")}</p>
          <p>
            {this.t("test.label.warning4-1")}{" "}
            <br />
            {this.t("test.label.warning4-2")}
          </p>
        </Box>
      );
    } else if (popup === "timesUp") {
      com = (
        <Box className="warning">
          <CloseOutlinedIcon
            className="closeIcon"
            onClick={() => this.handleWarning("close")}
          />
          <img className="imgSize" src={flag} alt="Flag" />
          <p className="title">{this.t("test.title.warning")}</p>
          <p>
            {this.t("test.label.warning1", { toolName: this.state.tool?.name })}
          </p>
          <Box>
            <Button
              variant="outlined"
              className="btnCancel"
              onClick={() => this.props.navigation.navigate("Dashboard")}
            >
              {this.t("test.button.cancel")}
            </Button>
            <Button
              variant="contained"
              className="btnSubmit"
              onClick={() => this.props.navigation.navigate("Dashboard")}
            >
              {this.t("test.button.okay")}
            </Button>
          </Box>
        </Box>
      );
    } else if (popup === "flagWarnig") {
      com = (
        <Box className="warning">
          <CloseOutlinedIcon
            className="closeIcon"
            onClick={() => this.handleWarning("close")}
          />
          <img className="imgSize" src={flag} alt="Flag" />
          <p className="title">{this.t("test.title.warning")}</p>
          <div>
            <p style={{ marginTop: "10px", marginBottom: "10px" }}>
              {this.t("test.label.warning2-1", { toolName: this.state.tool?.name })}
            </p>
            {Number(this.state.flaggedQue) !== 0 ? <p style={{ marginTop: "10px", marginBottom: "10px" }}>{this.t("test.label.warning2-2", { question: this.state.flaggedQue })}</p> : false}
            {Number(this.state.UnanswaredQuetion) !== 0 ? <p style={{ marginTop: "10px", marginBottom: "10px" }}>{this.t("test.label.warning2-3", { question: this.state.UnanswaredQuetion })}</p> : false}
          </div>
          <Box style={{ marginTop: "5px", marginBottom: "5px" }}>
            <Button
              variant="outlined"
              className="btnCancel"
              style={{
                marginTop: "0px"
              }}
              onClick={() => this.handleWarning("cancel")}
            >
              {this.t("test.button.cancel")}
            </Button>
            <Button
              style={{
                marginTop: "0px"
              }}
              variant="contained"
              className="btnSubmit"
              onClick={() => {
                this.setState({ showModal: true, popup: "submit" })
                this.submitTest()
              }}
            >
              {this.t("test.button.yesFinished")}
            </Button>
          </Box>
        </Box>
      );
    } else if (popup === "confirmSubmit") {
      com = (
        <Box className="warning">
          <CloseOutlinedIcon
            className="closeIcon"
            onClick={() => this.handleWarning("close")}
          />
          <img className="imgSize" src={question} alt="Flag" />
          <p className="title">{this.t("test.title.warning")}</p>
          <p>
            {this.t("test.label.warning3", { toolName: this.state.tool?.name })}
          </p>
          <Box>
            <Button
              variant="outlined"
              className="btnCancel"
              onClick={() => this.handleWarning("cancel")}
            >
              {this.t("test.button.cancel")}
            </Button>
            <Button
              variant="contained"
              className="btnSubmit"
              onClick={() => {
                this.submitTest();
                this.setState({ showModal: true, popup: "submit" });
              }}
            >
              {this.t("test.button.yesExit")}
            </Button>
          </Box>
        </Box>
      );
    } else if (popup === "submit") {
      com = (
        <Box className="success">
          <CloseOutlinedIcon
            className="closeIcon"
            onClick={() => this.handleWarning("submit")}
          />
          <img src={groupCheck} alt="groupCheck" className="modalImg" />
          <h2 className="modalTitle2">{this.t("test.title.congratulation")}</h2>
          <p className="modalText2">
            {this.t("test.label.success")}
          </p>
          <Box style={{ display: "flex", justifyContent: "center" }}>
            <Button onClick={() => this.handleWarning("submit")}
              style={{
                backgroundColor: '#f94b4b',
                color: "#fff",
                width: '140px',
                height: '50px',
                padding: '13px 16px 12px',
                borderRadius: '8px',
                border: 'solid 2px #f94b4b',
                marginTop: "1rem"
              }}
              variant="contained"
            >
              {this.t("test.button.okay")}</Button>
          </Box>
        </Box>
      );
    }
    return com;
  };

  quetionArryaRender = () => {
    return (
      <Box
        style={{
          display:
            this?.state?.questionArray?.[this.state.currentQue]?.attributes
              ?.answers?.data[0]?.attributes?.image_url?.url !== null
              ? "flex"
              : "block",
        }}
        className={this.state.isMedia ? "imageContainer" : " "}
      >
        {this?.state?.questionArray?.[this.state.currentQue]?.attributes.answers?.data
          ? this?.state?.questionArray?.[
            this.state.currentQue
          ]?.attributes.answers.data.map((ans: any, index: any) => {
            return (
              <>
                {ans?.attributes?.image_url?.type === null ? (
                  <Box key={ans?.id} className="radioContainer">
                    <Radio
                      onChange={() => this.AnswerdOnchanges(ans)}
                      checked={
                        this?.state?.questionArray?.[this.state.currentQue]
                          .ansSubmited === ans?.id
                      }
                      id={"first" + ans.id}
                      name="radio-buttons"
                      inputProps={{ "aria-label": "A" }}
                    />
                    <label htmlFor={"first" + ans.id} className="ans">
                      {ans?.attributes?.answer}
                    </label>
                  </Box>
                ) : (
                  <>
                    {ans?.attributes?.image_url?.type === "video/mp4" ? (
                      <Box
                        key={ans?.id}
                        style={{
                          margin: "0 5px",
                          paddingTop: "10px",
                        }}
                      >
                        <div>
                          <label htmlFor={ans.id}>
                            <video
                              src={ans?.attributes?.image_url?.url}
                              style={{
                                minHeight: "160px",
                                maxHeight: "170px",
                              }}
                              controls
                            >
                              {this.t("test.label.videoNotSupport")}
                            </video>
                          </label>
                        </div>
                        <div
                          style={{
                            justifyContent: "center",
                            display: "flex",
                          }}
                        >
                          <Radio
                            id={ans.id}
                            checked={
                              this?.state?.questionArray?.[
                                this.state.currentQue
                              ].ansSubmited === ans?.id
                            }
                            name="radio-buttons"
                            inputProps={{
                              "aria-label": "A",
                            }}
                            onChange={() => this.AnswerdOnchanges(ans)}
                          />
                        </div>
                      </Box>
                    ) : (
                      <Box
                        key={ans?.id}
                        style={{
                          margin: "0 5px",
                          paddingTop: "10px",
                        }}
                      >
                        <div>
                          <label htmlFor={ans.id}>
                            <img
                              src={ans?.attributes?.image_url?.url}
                              style={{
                                minHeight: "160px",
                                maxHeight: "170px",
                              }}
                              alt="img"
                            />
                          </label>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <Radio
                            onChange={() => this.AnswerdOnchanges(ans)}
                            checked={
                              this?.state?.questionArray?.[
                                this.state.currentQue
                              ].ansSubmited === ans?.id
                            }
                            id={ans.id}
                            name="radio-buttons"
                            inputProps={{
                              "aria-label": "A",
                            }}
                          />
                        </div>
                      </Box>
                    )}
                  </>
                )}
              </>
            );
          })
          : null}
      </Box>
    );
  };

  btnContainer = () => {
    return (
      <Box className="btns" data-testid="Btns">
        <Box>
          <Button
            variant="outlined"
            className={this.state.currentQue === 0 ? "finish" : "prevBtn"}
            disabled={this.state.currentQue === 0 ? true : false}
            style={{ marginRight: locallanguage ? "0" : "2rem" , marginLeft: locallanguage ? "2rem" : "0rem" }}
            onClick={() => this.handlePrevious()}
          >
            {" "}
            <ArrowBackIosOutlinedIcon className="arrowIcon" /> &nbsp; {this.t("test.button.previous")}
          </Button>
          <Button
            variant="contained"
            id="nextBtn"
            className={
              this.state.currentQue === this.state.totalQue - 1
                ? "disableBtn"
                : "nextBtn"
            }
            disabled={
              this.state.currentQue === this.state.totalQue - 1 ? true : false
            }
            onClick={() => this.handleNext()}
          >
            {this.t("test.button.next")} &nbsp; <ArrowForwardIosOutlinedIcon className="arrowIcon" />
          </Button>
        </Box>
        <Button
          variant="contained"
          className={
            this.state.currentQue === this.state.totalQue - 1 || this.state.UnanswaredQuetion === 0
              ? "nextBtn"
              : "finish"
          }
          disabled={
            this.state.currentQue === this.state.totalQue - 1 || this.state.UnanswaredQuetion === 0 ? false : true
          }
          onClick={() =>
            this.state.flaggedQue || this.state.UnanswaredQuetion ?
              this.setState({
                showModal: true,
                popup: "flagWarnig",
              }) :
              this.setState({
                showModal: true,
                popup: "confirmSubmit",
              })
          }
        >
          {this.t("test.button.finish")}
        </Button>
      </Box>
    );
  };

  numbersRender = () => {
    return this?.state?.questionArray ? (
      <Box>
        <img
          src={this?.state?.questionArray?.[this.state.currentQue]?.color === "Flag" ? groupIcon : groupIconGray}
          id="flagImg"
          alt="Flag"
          className={ locallanguage ? "flagIconAr" :"flagIcon"}
          onClick={() => {
            this.handleFlagQue(
              this?.state?.questionArray?.[this.state.currentQue]
            );
          }}
        />
        <Box className="questions">
          <Box className="displayRow align-center">
            <img src={questionIcon} alt="questionIcon" className="queIcon" style={{marginLeft : locallanguage ? "1rem" : "0"}}/>
            <Typography variant="body2" className="que">
              {this.t("test.label.question")} {this.state.currentQue + 1}
            </Typography>
          </Box>
          <p>
            {
              this?.state?.questionArray?.[this.state.currentQue]?.attributes
                ?.name
            }
          </p>
          {this?.state?.questionArray?.[this.state.currentQue]?.attributes
            ?.image_url?.url ?
            <>
              {this?.state?.questionArray?.[this.state.currentQue]?.attributes
                ?.image_url?.type === "video/mp4" ? <video src={this?.state?.questionArray?.[this.state.currentQue]?.attributes
                  ?.image_url?.url} controls className="queImg">
              </video> :
                <img className="queImg"
                  src={this?.state?.questionArray?.[this.state.currentQue]?.attributes
                    ?.image_url?.url} alt="demo" />}
            </>
            : null}


        </Box>
        <Divider />
        <Box className="p2">
          <Typography style={{ fontWeight: "bold" }}>
            {this.t("test.label.choose")}
          </Typography>
          {this.quetionArryaRender()}
        </Box>
      </Box>
    ) : null;
  };

  GridStateRender = () => {
    return (
      <Grid item md={this.state.GridState} xs={this.state.GridState} className="queController"
        data-testid="queController1">
        <Card>
          <Grid container className="numbers" spacing={1}>
            {this?.state?.questionArray?.map(

              (num: any, index: any) => {
                let colorsSelect = "unAnswered";

                if (num?.color === "Checked") {
                  colorsSelect = "answered";
                }
                if (num?.color === "Flag") {
                  colorsSelect = "Flag";
                }
                return (
                  <Grid item md={4} sm={2} key={num?.id}>
                    <Box
                      className={`number  ${colorsSelect}`}
                      onClick={() => this.handleSwitchQue(num, index)}
                    >
                      {index + 1}
                    </Box>
                  </Grid>
                );
              }
            )}
          </Grid>
          <Divider />
          <Box className="p1" data-testid="Pagination">
            <Box className="displayRow align-center pb1">
              <Box className="number answered"></Box> &nbsp; {this.t("test.label.answered")}
            </Box>
            <Box className="displayRow align-center pb1">
              <Box className="number unAnswered"></Box> &nbsp;
              {this.t("test.label.unAnswered")}
            </Box>
            <Box className="displayRow align-center ">
              <Box className="number flag"></Box> &nbsp; {this.t("test.label.flag")}
            </Box>
          </Box>
          <Divider />
          <Box className="p1">
            {this.t("test.label.totalAnswered")}:{" "}
            {
              this.state.questionArray.filter(
                (item: any) => item.color === "Checked"
              ).length
            }
          </Box>
        </Card>
      </Grid>
    )
  }

  ShowMediaModalRender = () => {
    return (
      <Modal
        open={this.state.showMedia}
        onClose={() => this.handleWarning("close")}
        className="modal modal2"
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={this.state.showMedia}>
          <div className="paper">
            <CloseOutlinedIcon
              className="closeIcon"
              onClick={() => this.handleWarning("close")}
            />
            <img src="demo.jpg" alt="img" className="imgFile" />
          </div>
        </Fade>
      </Modal>
    )
  }

  QuestionArraySection = () => {
    return(
      <section className="mcqTestSection" data-testid="Heading">
            <Box className="displayRow content-center align-center head">
              <h1> {this.state.tool?.name}</h1>
              <div className="displayRow align-center">
                <div style={{marginLeft :  locallanguage ? "2rem" : "0rem"}}>
                  <Typography
                    variant="body1"
                    style={{ fontWeight: "bold", paddingRight: "1rem" }}
                    component="h2"
                  >
                    {this.t("test.label.remaining", { remain: this.state.currentQue + 1, total: this.state.totalQue })}
                  </Typography>
                </div>
                <Button className="button" variant="contained" style={{ borderRadius: "7px" }}>
                  {(this.state.hour > 9 ? this.state.hour : '0' + this.state.hour) + ':' +
                    (this.state.minutes > 9 ? this.state.minutes : '0' + this.state.minutes) + ':'
                    + (this.state.seconds > 9 ? this.state.seconds : '0' + this.state.seconds)
                  }
                </Button>
              </div>
            </Box>
            <Box
              className="displayRow container1"
              data-testid="questionDisplay"
            >
              <Grid container className="testContainer" spacing={2} style={{margin:"0px"}}>
                <Grid item md={this.state.GridState === 0 ? 12 : 10} xs={12} className="displayQue">
                  <Card
                    className="queContainer"
                    data-testid="questionContainer"
                  >
                    {this.numbersRender()}
                    {this.state.errorMsg && (
                      <p className="error">
                        {this.t("test.errorMessages.selectAns")}
                      </p>
                    )}
                  </Card>
                  {this.btnContainer()}
                </Grid>
                {this.state.GridState !== 0 && (
                  this.GridStateRender()
                )}
              </Grid>
            </Box>
            <Box
              className={locallanguage ? "arrowIconContainerAr" : "arrowIconContainer"}
              data-testid="arrowIconContainer"
            >
              <IconButton onClick={() =>
                this.setState({
                  GridState: this.state.GridState === 0 ? 2 : 0,
                })
              } aria-label="delete" className="arrowBtn">
                <ArrowForwardIosOutlinedIcon className="arrowIcon" />
              </IconButton>
            </Box>
          </section>
    )
  }

  render() {
    return (
      <>
        <AppHeader />
        {this.state.loading && <Spinner spinnerModal={this.state.loading} />}
        {this.state.questionArray.length ? (
          this.QuestionArraySection()
        ) : (
          <section className="nodata">
            <h1>{this.state.questionArray.length === 0 && this.state.loading === false && this.t("test.label.notAvailable")}</h1>
          </section>
        )}
        <AppFooter />
        <Modal
          data-testid="warningModal"
          open={this.state.showModal}
          className="modal modal2"
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={this.state.showModal}>
            <div className="paper">{this.showPopup()}</div>
          </Fade>
        </Modal>

        {this.state.showMedia ? (
          this.ShowMediaModalRender()
        ): null}
      </>
    );
  }
}

export default withTranslation()(McqSjtTest);
// Customizable Area End
