import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { runEngine } from "../../../../framework/src/RunEngine";
import MessageEnum, {
    getName,
} from "../../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { toast } from "react-toastify";
// Customizable Area End

export const configJSON = require("../config");

export interface Props {
    id?: string;
    navigation?: any;
    location: any;
    history: any;
    match: any;
    t: any;
}

export interface S {
    // Customizable Area Start
    loading: boolean;
    page: number;
    rowsPerPage: number;
    dataLength: number;
    count: number | null;
    item: any;
    filterBy: string;
    option: string;
    search: string;
    openDialogName: string | null;
    data: any[];
    tableData: any[];
    filterData: any[];
    optionList: any[];
    onlineToolList: any;
    editToolId: any,
    currentIndex: number;
    isAddToolButtonClick: boolean;
    currentToolLogo: any;
    currentToolName: any;
    currentToolDescription: string | null;
    deleteTool: any,
    AddAndEditDilogState: any;
    DeleteModel: any;
    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

const statusList = [
    { label: "Yet to be started", value: "yet to be started" },
    { label: "On Going", value: "on going" },
    { label: "Completed", value: "completed" },
];

export default class AssessorLeadToolsContoller extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    GetProjectDetailApiCallId: string = "";
    GetLeadToolsData: string = '';
    addQuestionApiCallId: string = "";
    editQuestionApiCallId: string = "";
    deleteQuestionApiCallId: string = "";
    addBulkQuestionApiCallId: string = "";
    addToolApiCallId: string = "";
    editToolApiCallId: string = "";
    deleteToolApiCallId: string = "";
    deleteErrorCSVApiCallId: string = "";

    inputRef: any;
    t: any;
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage),
        ];
        this.receive = this.receive.bind(this);
        runEngine.attachBuildingBlock(this, this.subScribedMessages);

        this.state = {
            // Customizable Area Start
            page: 0,
            rowsPerPage: 20,
            loading: false,
            dataLength: 0,
            item: null,
            count: null,
            filterBy: "",
            search: "",
            option: "",
            openDialogName: "",
            data: [],
            tableData: [],
            filterData: [],
            optionList: [],
            onlineToolList: [],
            editToolId: null,
            currentIndex: -1,
            isAddToolButtonClick: false,
            currentToolLogo: '',
            currentToolName: '',
            currentToolDescription: '',
            deleteTool: [],
            AddAndEditDilogState: false,
            DeleteModel: false
            // Customizable Area End
        };
        this.t = this.props.t;
    }

    async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(
                    MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (apiRequestCallId && responseJson) {
                if (responseJson.status === 500) {
                    toast.error(this.t("assessorLeadTool.toast.somethingWrong"));
                    this.setState({ loading: false });
                } else if (!Array.isArray(responseJson.errors) && responseJson.errors) {
                    toast.error(responseJson.errors); this.setState({ loading: false });
                } else {
                    this.handleApisResponses(apiRequestCallId, responseJson);
                }
            }
        }
    }

    handleApisResponses = (apiRequestCallId: string, responseJson: any) => {
        if (Array.isArray(responseJson.errors)) {
            if (responseJson.errors[0].message) {
                toast.error(responseJson.errors[0].message);
            } else if (responseJson.errors[0].token) {
                toast.error(responseJson.errors[0].token);
            } else {
                toast.error(this.t("assessorLeadTool.toast.somethingWrong"));
            }
            this.setState({ loading: false });
        } else if (apiRequestCallId === this.GetProjectDetailApiCallId) {
            this.handelTableDataApiResponse(responseJson);
        } else if (apiRequestCallId === this.GetLeadToolsData) {
            let data = responseJson.data.map((item: any) => {
                return (
                    {
                        image: item.attributes.logo_url,
                        value: item.id,
                        label: item.attributes.name,
                        description: item.attributes.description,
                    }
                )
            })
            this.setState({ onlineToolList: data, loading: false });
        } else if (apiRequestCallId === this.editToolApiCallId) {
            toast.success(this.t("assessorLeadTool.toast.edit"));
            this.setState({ editToolId: null, loading: false });
            this.GetToolsData();
        } else if (apiRequestCallId === this.deleteToolApiCallId) {
            this.onDeleteToolButtonClick([]);
            toast.success(this.t("assessorLeadTool.toast.delete"));
            this.GetToolsData();
        } else if (apiRequestCallId === this.addToolApiCallId) {
            toast.success(this.t("assessorLeadTool.toast.add"));
            this.GetToolsData();
            this.setState({ loading: false });
        }
    }

    async componentDidMount() {
        window.scrollTo(0, 0);
        this.GetProjectList();
        this.GetToolsData();
    }

    AddAndEditDilog = () => {
        if(this.state.isAddToolButtonClick && this.state.editToolId === null){
            this.setState({
                onlineToolList: this.state.onlineToolList.splice(-1)
            })
        }
        this.setState({
            ...this.state, AddAndEditDilogState: !this.state.AddAndEditDilogState, editToolId: null,isAddToolButtonClick:false
        })
    }

    GetToolsData = () => {
        this.setState({ loading: true })
        const header = {
            "Content-Type": "application/json",
            token: localStorage.getItem("accesToken")
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.GetLeadToolsData = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            "bx_block_admin/assessor_lead_tools"
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "GET"
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);


    }

    handelTableDataApiResponse = (responseJson: any) => {
        const data = responseJson.data.map((item: any, index: any) => {
            return {
                id: index,
                project: item.name,
                status: item.status,
                type: item.type,
                DCManager: item.dc_manager,
                completion_status: item.completion_status,
                toolUsed: item.tool,
                evaluationType: 'evaluation_type'
            }
        }).sort((a: any, b: any) => {
            return a.project.toLowerCase().localeCompare(b.project.toLowerCase())
        });

        this.setState({ tableData: data, filterData: data, loading: false },
            () => { this.onChangeHandler(this.state.search, 0) }
        );
    }

    onApplyButtonClick = () => {
        let { tableData, filterBy, option } = this.state;
        let filterData = tableData;
        if ((filterBy === "status" || filterBy === "project") && option === "") {
            toast.warning(this.t("assessorLeadTool.toast.SelectOption", { data: filterBy.charAt(0).toUpperCase() + filterBy.slice(1) }));
        }
        if (filterBy !== "all") {
            if (filterBy && option && option !== "") {
                filterData = tableData.filter((item: any) => item[filterBy] === option);
                this.setState({ filterData }, () => {
                    this.onChangeHandler(this.state.search, 0);
                });
            }
        }
    }

    redirectToProjectDetail = (item: any) => {
        this.props.history.push({
            pathname: "/projectDetail",
            state: {
                projectId: item.id,
                projectName: item.project
            }
        });
    }

    onChangeHandler = (search: string, page: number) => {
        const { filterData, rowsPerPage } = this.state;
        let data = filterData.filter((item: any) => {
            if (search === "") { return true }
            else if (item.project.toString().toLowerCase().includes(search.toLowerCase()) || item.status.toString().toLowerCase().includes(search.toLowerCase()) || item.type.toString().toLowerCase().includes(search.toLowerCase()) || item.DCManager.toString().toLowerCase().includes(search.toLowerCase())) { return item; }
        });

        const dataLength = data.length;
        data = data.slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage); this.setState({ data, dataLength, page, search });
    }

    onFilterByValueChange = (filterBy: any) => {
        let optionList: any[] = this.state.tableData;
        if (filterBy === null) {
            this.setState({
                data: this.state.tableData,
                filterBy:"",
                option: ""
            })
        }
        if (filterBy !== null) {
            if (filterBy.value === "all") {
                optionList = [];
            } else if (filterBy.value === "status") {
                optionList = statusList;
            } else if (filterBy.value === "project") {
                optionList = [...new Set(this.state.tableData.map((item: any) => item.project))]; optionList = optionList.map((item: any) => ({ label: item, value: item }));
            }
        } else {
            optionList = []
        }
        this.setState({ filterBy: filterBy !== null ? filterBy.value : "", optionList, option: "" });
    }

    GetProjectList = () => {
        this.setState({ loading: true })
        const header = {
            "Content-Type": "application/json",
            token: localStorage.getItem("accesToken")
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.GetProjectDetailApiCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            "/bx_block_profile/projects/get_assessor_lead_projects"
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "GET"
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);


    }

    onToolLogoChange = async (file: any, index: number) => {
        let onlineToolList = [...this.state.onlineToolList];
        onlineToolList[index].isLogoChange = true;
        onlineToolList[index].image = file;
        this.setState({ onlineToolList });
    }

    addToolRecord = () => {
        let onlineToolList = [...this.state.onlineToolList];
        const obj = { image: null, label: "", description: "", value: null };
        if (onlineToolList[onlineToolList.length - 1]?.value === null) {
            onlineToolList[onlineToolList.length - 1] = obj;
            this.inputRef?.focus();
        } else {
            onlineToolList = [...onlineToolList, obj];
        }
        if (this.state.editToolId) {
            onlineToolList[this.state.currentIndex].image = this.state.currentToolLogo;
            onlineToolList[this.state.currentIndex].label = this.state.currentToolName;
            onlineToolList[this.state.currentIndex].description = this.state.currentToolDescription;
        }
        this.setState({ onlineToolList, editToolId: null, isAddToolButtonClick: true });
    }

    onEditButtonClick = (tool: any, index: number) => {
        let onlineToolList = [...this.state.onlineToolList];
        if (this.state.editToolId) {
            onlineToolList[this.state.currentIndex].description = this.state.currentToolDescription;
            onlineToolList[this.state.currentIndex].image = this.state.currentToolLogo;
            onlineToolList[this.state.currentIndex].label = this.state.currentToolName;
        }
        if (!onlineToolList[onlineToolList.length - 1].value) { onlineToolList.pop(); }

        this.setState({
            editToolId: tool.value,
            onlineToolList,
            currentIndex: index, currentToolLogo: tool.image,
            currentToolName: tool.label, currentToolDescription: tool.description
        });
    }

    checkUniqueLabel = () => {
        let onlineToolList = [...this.state.onlineToolList];
        const uniqueArr = new Set(
            onlineToolList.map((s: any) => {
                return s.label.trim().toLowerCase();
            })
        );
        return uniqueArr.size === onlineToolList.length;
    }

    onToolNameChange = (event: any, index: number) => {
        let onlineToolList = [...this.state.onlineToolList];
        onlineToolList[index].label = event.target.value;
        this.setState({ onlineToolList });
    }

    onToolDescriptionChange = (event: any, index: number) => {
        let onlineToolList = [...this.state.onlineToolList];
        onlineToolList[index].description = event.target.value;
        this.setState({ onlineToolList });
    }
    onDeleteToolButtonClick = (tool: any) => {
        this.setState({ deleteTool: tool, openDialogName: "DeleteTool", DeleteModel: !this.state.DeleteModel });
    }

    onSaveTool = (value: any) => {
        if (!this.state.editToolId) {
            this.addTool();
        } else if (value !== null) {
            this.editTool();
        }
    }

    addTool = () => {
        const onlineToolList = [...this.state.onlineToolList];
        const toolNew = onlineToolList[onlineToolList.length - 1];
        if (!toolNew.image) {
            toast.warning(this.t("assessorLeadTool.toast.selectLogo"));
            return;
        } else if (!toolNew.label || !toolNew.description) {
            toast.warning(this.t("assessorLeadTool.toast.blankField"));
            return;
        }
        if (!this.checkUniqueLabel()) {
            toast.warning(this.t("assessorLeadTool.toast.sameTool"));
            return;
        }

        this.setState({ loading: true, openDialogName: "AddEditTool" });

        const header = {
            token: localStorage.getItem("accesToken")
        };

        const formData = new FormData();
        formData.append("logo", toolNew.image);
        formData.append("name", toolNew.label);
        formData.append("description", toolNew.description);

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.addToolApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formData
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            "/bx_block_admin/assessor_lead_tools"
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "POST"
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }

    deleteTool = () => {
        if (!this.state.deleteTool?.value) {
            let onlineToolListNew = [...this.state.onlineToolList];
            onlineToolListNew.pop();
            this.setState({ onlineToolList: onlineToolListNew, openDialogName: "AddEditTool" });
            return;
        }

        this.setState({ loading: true, openDialogName: "AddEditTool" });
        const header = {
            "Content-Type": configJSON.deleteToolApiContentType,
            token: localStorage.getItem("accesToken")
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.deleteToolApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `/bx_block_admin/assessor_lead_tools/${this.state.deleteTool?.value}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "DELETE"
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }

    editTool = () => {
        const editedToolNew = this.state.onlineToolList.filter(
            (tool: any) => tool.value === this.state.editToolId
        )[0];
        if (!editedToolNew.image) {
            toast.warning(this.t("assessorLeadTool.toast.selectLogo"));
            return;
        } else if (!editedToolNew.label || !editedToolNew.description) {
            toast.warning(this.t("assessorLeadTool.toast.blankField"));
            return;
        }
        if (!this.checkUniqueLabel()) {
            toast.warning(this.t("assessorLeadTool.toast.sameTool"));
            return;
        }

        this.setState({ loading: true, openDialogName: "AddEditTool" });

        const header = {
            token: localStorage.getItem("accesToken"),
        };

        const formData = new FormData();
        editedToolNew.isLogoChange &&
            formData.append("logo", editedToolNew.image);
        formData.append("name", editedToolNew.label);
        formData.append("description", editedToolNew.description);

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.editToolApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formData
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `/bx_block_admin/assessor_lead_tools/${this.state.editToolId}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "PUT"
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }



}

// Customizable Area End
