// Customizable Area Start
import * as React from "react";
import { Box, Container } from "@material-ui/core";
import { useTranslation } from "react-i18next";
const { baseURL } = require("../../framework/src/config.js");

const AppFooter = () => {
  const { t } = useTranslation();
  const [copyRight, setCopyRight] = React.useState(`© ${new Date().getFullYear()} SHRM India. All Rights Reserved.`);
  React.useEffect(() => {
    getCopyright();
  }, []);

  const getCopyright = async () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      }
    };

    const res = await fetch(`${baseURL}/bx_block_admin/footer_copyrights`, requestOptions);
    if (!res.ok) {
      return;
    }
    const response = await res.json();
    if(Array.isArray(response)){
      setCopyRight(response[0].copyright);
    }
  }

  return (
    <div style={{ background: "#f2f2f2"}}>
      <Container maxWidth="xl" className="container" >
        <Box
          sx={{
            justifyContent: "space-between",
            width: "100%",
            padding: "20px",
            display: { xs: "block", md: "flex" },
          }}
        >
          <Box className="footerNote" sx={{ color: "#6f6f6f", fontSize: "14px" }}>{t("AppFooter.version")}</Box>
          <Box className="footerNote" sx={{ color: "#6f6f6f", fontSize: "14px" }}>
            {copyRight}
          </Box>
        </Box>
      </Container>
    </div>
  );
};
export default AppFooter;
// Customizable Area End