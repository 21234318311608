// Customizable Area Start
import React from "react";
import {
    Box,
    Button,
    InputBase,
    Grid,
    TextField,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Dialog,
    DialogContent
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import { styled } from "@material-ui/styles";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from '@material-ui/icons/Close';
import * as Yup from "yup";
import { Formik } from "formik";
import { withTranslation } from "react-i18next";
import FunctionController, { Props } from "./FunctionController.web";
import AppSidebar from "../../../../../components/src/AppSidebar.web";
import Spinner from "../../../../../components/src/Spinner.web";
const LanguageData = localStorage.getItem("language") === "ar";

const editIcon = require("../../../assets/edit.png");
const deleteIcon = require("../../../assets/delete.png");

class Function extends FunctionController {
    constructor(props: Props) {
        super(props);
    }

    functionSchema = () => {
        return Yup.object().shape({
            functionName: Yup.string().required(this.t("organizationFunction.function.errorMessages.name"))
        });
    }

    EditDilogRender = () => {
        return (
            <Dialog
                aria-describedby="scroll-dialog-description"
                fullWidth={false}
                scroll="body"
                transitionDuration={0}
                onClose={() => { }}
                maxWidth={"sm"}
                aria-labelledby="scroll-dialog-title"
                open={["Add", "Edit"].includes(this.state.openDialogName ?? "")}
                PaperProps={{
                    style: {
                        width: "500px"
                    }
                }}
            >
                <DialogContent style={{ width: "100%", padding: "0px" }}>
                    <Box style={{ textAlign: "right", margin: "10px 10px 0px 0px" }}>
                        <CloseIcon onClick={() => this.setState({ functionId: null, functionName: "", openDialogName: null })} style={{ cursor: "pointer", fontSize: "35px", color: "#787878" }} />
                    </Box>
                    <Box padding={{ xs: "15px 25px 25px", sm: "15px 40px 40px" }}>
                        <Box
                            color="#1b3c69"
                            id="modal-title"
                            fontSize={{ xs: "20px", sm: "30px" }}
                            fontFamily="ProximaNova-Bold"
                            mb="20px"
                            fontWeight="bolder"
                            style={{ textAlign: LanguageData ? "right" : "unset" }}
                        >
                            {this.t(`organizationFunction.function.title.${this.state.openDialogName}`)}
                        </Box>
                        <Formik
                            initialValues={{ functionName: this.state.functionName }}
                            onSubmit={(values) => { this.state.openDialogName === "Add" ? this.addFunction() : this.editFunction() }}
                            validationSchema={this.functionSchema}
                        >
                            {({ errors, touched, setFieldValue, handleSubmit }) => (
                                <form onSubmit={handleSubmit} noValidate autoComplete="off">
                                    <Grid container spacing={3}><Grid item xs={12}><div style={{ marginBottom: "5px", fontSize: "18px", textAlign: LanguageData ? "right" : "unset" }}>
                                        {this.t(`organizationFunction.function.title.${this.state.openDialogName}`)}</div>
                                        <CustomInputBase
                                            fullWidth
                                            placeholder={this.t("organizationFunction.function.placeholder.name")}
                                            value={this.state.functionName}
                                            onChange={(event: any) => {
                                                setFieldValue("functionName", event.target.value);
                                                this.setState({ functionName: event.target.value });
                                            }}
                                        />
                                        {touched.functionName && errors.functionName &&
                                            <div className="text-danger">{errors.functionName}</div>
                                        }
                                    </Grid>
                                    </Grid>
                                    <Box mt="30px" justifyContent="end" display={{ xs: "block", sm: "flex" }}>
                                        <CustomButton className="cancelBtn" onClick={() => this.setState({ functionId: null, functionName: "", openDialogName: null })}>{this.t("organizationFunction.function.button.cancel")}</CustomButton>
                                        <RedButton style={{ marginRight: LanguageData ? "15px" : "unset" }} type="submit">{this.t(`organizationFunction.function.button.${this.state.openDialogName}`)}</RedButton>
                                    </Box>
                                </form>
                            )}
                        </Formik>
                    </Box>
                </DialogContent>
            </Dialog>
        )
    }

    DeleteDilogRender = () => {
        return (
            <Dialog
                open={this.state.openDialogName === "Delete"}
                scroll="body"
                fullWidth={false}
                maxWidth={"sm"}
                onClose={() => { }}
                transitionDuration={0}
                aria-describedby="scroll-dialog-description"
                aria-labelledby="scroll-dialog-title"
            >
                <DialogContent style={{ padding: "0px", maxWidth: "500px" }}>
                    <Box style={{ paddingTop: "0px !important", textAlign: "right", margin: "10px 10px 0px 0px" }}>
                        <CloseIcon onClick={() => this.setState({ functionId: null, functionName: "", openDialogName: null })} style={{ cursor: "pointer", fontSize: "35px", color: "#787878" }} />
                    </Box>
                    <Box display="flex" alignItems="center" flexDirection="column" padding={{ xs: "15px 25px 25px", sm: "15px 70px 40px" }}>
                        <Box
                            id="modal-title"
                            fontWeight="bolder"
                            fontSize={{ xs: "20px", sm: "30px" }}
                            fontFamily="ProximaNova-Bold"
                            mb="20px"
                        >
                            {this.t("organizationFunction.function.title.delete")}
                        </Box>
                        <Box textAlign="center" fontSize="18px">
                            {this.t("organizationFunction.function.description.delete", { value: this.state.functionName })}
                        </Box>
                        <Box mt="30px" display="flex">
                            <CustomButton className="cancelBtn" onClick={() => this.setState({ functionId: null, functionName: "", openDialogName: null })} style={{ marginBottom: "0px", padding: "5px 10px" }}>{this.t("organizationFunction.function.button.cancel")}</CustomButton>
                            <RedButton onClick={this.deleteFunction} style={{ padding: "5px 10px", marginRight: LanguageData ? "15px" : "unset" }}>{this.t("organizationFunction.function.button.delete")}</RedButton>
                        </Box>
                    </Box>
                </DialogContent>
            </Dialog>
        )
    }

    TableRender = () => {
        return (
            <Table aria-label="simple table" style={{ borderCollapse: "separate", borderRadius: "10px", border: "1px solid #E0E0E0" }} className="custom-table">
                <TableHead>
                    <TableRow>
                        <TableCell align={LanguageData ? "right" : "left"} style={{ fontSize: "16px", fontFamily: "ProximaNova-Bold" }}>{this.t("organizationFunction.function.label.tableName")}</TableCell>
                        <TableCell align={LanguageData ? "left" : "right"} style={{ fontFamily: "ProximaNova-Bold", fontSize: "16px" }}>{this.t("organizationFunction.function.label.action")}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {this.state.data.length > 0 ?
                        <>
                            {this.state.data.map((item: any, index: number) => (
                                <TableRow key={`${index + 1}`}>
                                    <TableCell align={LanguageData ? "right" : "left"} style={{ fontFamily: "ProximaNova-Regular", fontSize: "18px", color: "#000" }}>{item.functionName}</TableCell>
                                    <TableCell align={LanguageData ? "left" : "right"} style={{ color: "#000", fontFamily: "ProximaNova-Regular", fontSize: "18px" }}>
                                        <img src={editIcon} id="edit" style={{ cursor: "pointer", marginRight: "10px" }} onClick={() => this.setState({ functionId: item.id, functionName: item.functionName, openDialogName: "Edit" })} />
                                        <img src={deleteIcon} id="delete" style={{ cursor: "pointer" }} onClick={() => this.setState({ functionId: item.id, functionName: item.functionName, openDialogName: "Delete" })} />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </>
                        : <TableRow>
                            <TableCell colSpan={6} align="center" style={{ fontFamily: "ProximaNova-Regular", fontSize: "18px", color: "#000", whiteSpace: "nowrap" }}>{this.t("organizationFunction.function.label.notFound")}</TableCell>
                        </TableRow>
                    }
                </TableBody>
            </Table>
        )
    }

    render() {
        return (
            <StyleFunction style={{ fontFamily: "ProximaNova-Regular" }}>
                {this.state.loading && <Spinner spinnerModal={this.state.loading} />}
                <AppSidebar type="admin" openOrganization>
                    <Box mr="30px" margin="35px 0px">
                        <Box justifyContent="space-between" mb="40px" display={{ xs: "block", sm: "flex" }}>
                            <Box data-testid="datafuncation"><Box mb="5px" color="#1b3c69" fontFamily="ProximaNova-Bold" fontSize={{ xs: "24px", sm: "30px", md: "44px" }} >{this.t("organizationFunction.function.title.main")}</Box><Box color="#1b3c69" fontSize={{ xs: "14px", sm: "20px" }}>{this.t("organizationFunction.function.description.main")}</Box></Box><Box mt={{ xs: "20px", sm: "0px" }}><RedButton onClick={() => this.setState({ openDialogName: "Add" })} style={{ backgroundColor: "#f26a42" }}>{this.t("organizationFunction.function.button.Add")}</RedButton></Box></Box>
                        <Box mt="60px">
                            <Box>
                                <Grid container>
                                    <Grid item xs={12} className={LanguageData ? "SearchIconRightSide" : ""}>
                                        <Box mb="25px" display="flex" justifyContent="end" alignItems="center">
                                            <TextField
                                                variant="outlined"
                                                id="search"
                                                InputLabelProps={{
                                                    style: {
                                                        zIndex: 0,
                                                        fontFamily: "ProximaNova-Regular"
                                                    }
                                                }}
                                                onChange={(event: any) => {
                                                    this.onChangeHandler(event.target.value, 0);
                                                }}
                                                InputProps={{
                                                    endAdornment: <SearchIcon />
                                                }}
                                                label={this.t("organizationFunction.function.label.search")}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} style={{ overflow: "auto" }}>
                                        {this.TableRender()}
                                        {this.state.data.length > 0 &&
                                            <div className="custom-pagination">
                                                <Pagination
                                                    shape="rounded"
                                                    page={this.state.page + 1}
                                                    count={Math.ceil(this.state.dataLength / this.state.rowsPerPage)}
                                                    onChange={(event: unknown, newPage: number) => {
                                                        this.onChangeHandler(this.state.search, newPage - 1);
                                                    }}
                                                    dir="ltr"
                                                    showFirstButton
                                                    variant="outlined"
                                                    siblingCount={0}
                                                    boundaryCount={1}
                                                    showLastButton
                                                />
                                            </div>
                                        }
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </Box>
                </AppSidebar>

                {/* for add/edit user */}
                {this.EditDilogRender()}

                {/* for delete */}
                {this.DeleteDilogRender()}
            </StyleFunction>
        );
    }
}

export default withTranslation()(Function);

const CustomInputBase = styled(InputBase)({
    minHeight: "45px",
    borderRadius: "10px",
    border: "1px solid #787878",
    fontSize: "16px",
    paddingLeft: "15px",
    '& .MuiInputBase-inputMultiline': {
        "&::placeholder": {
            fontSize: "17px",
        }
    }
});

const StyleFunction = styled("div")({
    '& .SearchIconRightSide': {
        '& .MuiInputBase-root': {
            display: "flex",
            flexDirection: "row-reverse"
        }
    }
})

const CustomButton = styled(Button)({
    borderRadius: "8px",
    color: "#f26a42",
    border: "1px solid #f26a42",
    fontFamily: "ProximaNova-Semibold",
    marginRight: "15px",
    backgroundColor: "white",
    fontSize: "18px",
    padding: "7px 17px",
    textTransform: "none",
    "&:hover": {
        backgroundColor: "white",
        color: "#f26a42",
    },
    "@media(max-width:600px)": {
        width: "100%",
    },
    "@media(max-width:992px)": {
        padding: "7px 15px",
    }
});

const RedButton = styled(Button)({
    fontSize: "18px",
    color: "white",
    fontFamily: "ProximaNova-Semibold",
    borderRadius: "10px",
    textTransform: "none",
    padding: "9px 30px",
    backgroundColor: "#f94b4b",
    "&:hover": {
        color: "white",
        backgroundColor: "#f94b4b",
    },
    "@media(max-width:600px)": {
        marginTop: "27px",
        width: "100%",
    }
});
// Customizable Area End