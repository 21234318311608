// Customizable Area Start
import React from "react";
import Button from "@material-ui/core/Button";
import AppSidebar from "../../../components/src/AppSidebar.web";
import { Grid, Avatar, CircularProgress } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import { styled } from "@material-ui/styles";
import { withRouter } from "react-router-dom";
import "./ManageClient.web.css";
import UnavailabilityOfAssessorController, {
  Props,
} from "./UnavailabilityOfAssessorController.web";
import { withTranslation } from "react-i18next";
import Spinner from "../../../components/src/Spinner.web";
const uploadIcon = require("../assets/upload.png");

class UnavailabilityofAssessor extends UnavailabilityOfAssessorController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    return (
      <AssessorListStyle className="manage-client" data-testid="Heading">
        {this.state.loader && <Spinner spinnerModal={this.state.loader} />}
        <AppSidebar type="admin">
          <div className="main_page" data-testid="username">
            <div className="client_container">
              <div>
                <h4>{this.t("UnavailabilityofAssessor.title")}</h4>
                <p>{this.t("UnavailabilityofAssessor.SubTitile")}</p>
              </div>
            </div>
            <div style={{ marginTop: "50px" }} data-testid="TableBodyData">
              <Grid container spacing={2}>
                {this.state.data?.map((item: any) => {
                  return (
                    <Grid
                      item
                      xs={6}
                      md={4}
                      xl={4}
                      className="GridItems"
                      key={item.id}
                    >
                      <div className="CardWhitePart">
                        <div
                          className={
                            item?.attributes?.activated
                              ? "userLabelAvaible"
                              : "userLabelDeActive"
                          }
                        >
                          {item?.attributes?.status}
                        </div>
                        <div className="nameandPhoto">
                          {/* @ts-ignore */}
                          <CircularProgress
                            variant="determinate"
                            size={70}
                            value={50}
                          />
                          <div className="avtart">
                            {/* @ts-ignore */}
                            <Avatar
                              alt="Remy Sharp"
                              sx={{ width: 56, height: 56 }}
                            />
                          </div>
                        </div>
                        <div className="UserName">
                          <h2>{item?.attributes?.full_name}</h2>
                        </div>
                      </div>
                      <div>
                        <Button
                          className="bookNowBtn"
                          onClick={() => this.NavigateToCalander(item)}
                          fullWidth
                          variant="contained"
                        >
                          {this.t("UnavailabilityofAssessor.ViewSlot")}
                        </Button>
                      </div>
                    </Grid>
                  );
                })}
              </Grid>
            </div>
          </div>
        </AppSidebar>
      </AssessorListStyle>
    );
  }
}

export default withRouter(withTranslation()(UnavailabilityofAssessor));

const AssessorListStyle = styled("div")({
  "& .MuiGrid-spacing-xs-2": {
    width: "100%",
  },
  "& .CardContainer": {
    backgroundColor: "white",
    minWidth: "300px",
    height: "100%",
  },
  "& .userLabelAvaible": {
    backgroundColor: "#dff8d2",
    maxWidth: "100px",
    padding: "5px 10px",
    textAlign: "center",
    borderRadius: "4px",
    color: "#288a0a",
  },
  "& .userLabelDeActive": {
    backgroundColor: "#eaeaea",
    maxWidth: "100px",
    padding: "5px 10px",
    textAlign: "center",
    borderRadius: "4px",
    color: "#288a0a",
  },
  "& .CardWhitePart": {
    borderTopLeftRadius: "4px",
    borderTopRightRadius: "4px",
    minHeight: "150px",
    boxShadow: "0px 0px 2px 0px grey",
  },
  "& .UserName": {
    display: "flex",
    justifyContent: "center",
    padding: "10px 0",
    "& h2": {
      textTransform: "capitalize",
    },
  },
  "& .nameandPhoto": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
  },
  "& .avtart": {
    position: "absolute",
  },
  "& .MuiCircularProgress-circle": {
    strokeWidth: 1,
  },
  "& .bookNowBtn": {
    backgroundColor: "#1b3c69",
    color: "white",
    fontWeight: "600",
    textTransform: "unset",
    fontFamily: "ProximaNova-Regular"
  },
});

const CustomButton = styled(Button)({
  backgroundColor: "white",
  color: "#f94b4b",
  border: "1px solid #f94b4b",
  fontFamily: "ProximaNova-Semibold",
  marginRight: "15px",
  borderRadius: "8px",
  fontSize: "18px",
  textTransform: "none",
  padding: "4px 15px",
  "&:hover": {
    color: "#f94b4b",
    backgroundColor: "white",
  },
  "@media(max-width:992px)": {
    padding: "8px",
    width: "50%",
  },
  "@media(max-width:576px)": {
    width: "100%",
    marginBottom: "20px",
    padding: "3px",
  },
});

const RedButton = styled(Button)({
  backgroundColor: "#f94b4b",
  color: "white",
  borderRadius: "8px",
  fontFamily: "ProximaNova-Semibold",
  fontSize: "18px",
  textTransform: "none",
  padding: "5px 25px",
  "&:hover": {
    color: "white",
    backgroundColor: "#f94b4b",
  },
  "@media(max-width:992px)": {
    padding: "8px",
    width: "50%",
  },
  "@media(max-width:576px)": {
    width: "100%",
    padding: "3px",
  },
});

const DeleteDialog = styled(Dialog)({
  margin: "0px 61px",
  "@media(max-width:576px)": {
    margin: "0px",
  },
});
// Customizable Area End
