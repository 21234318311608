// Customizable Area Start
import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { toast } from "react-toastify";

export const configJSON = require("./config");

export interface Props {
    navigation?: any;
    id?: string;
    t: any;
}

interface S {
    data: any;
    allClients: any;
    listTools: any
    ParticipantName: any;
    ParticipantDetails: any;
    openModal: boolean;
    openURLModal: boolean;
    URLmodal: boolean;
    dynamicUrl: any;
    rederectUser: any;
    activeBtn: boolean,
    page: any,
    rowsPerPage: any,
    search: any;
    loader: boolean;
    openDialogName: string | null;
    participantFile: any;
    uploadSubmitBtn: boolean;
    uploadStatus: any;
    dragActive:any,
    isfileEdit:any,
    filterselectedclinetname:any
}

interface SS {
    id: any;
}

export default class ManageParticipantController extends BlockComponent<
    Props,
    S,
    SS
> {
    getParticipantAPICall: string = '';
    addParticipantCallId: string = '';
    getAllClinetsAPICall: string = '';
    addBulkParticipantApiCallId: string = "";
    t: any = "";
    deleteCSVApiCallId: string ="";

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this)
        this.handleOpenModal = this.handleOpenModal.bind(this)
        this.handleopenURLModal = this.handleopenURLModal.bind(this)
        this.handleCloseModal = this.handleCloseModal.bind(this)
        this.handleCloseModal2 = this.handleCloseModal2.bind(this)

        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.PostDetailDataMessage)
        ]

        this.state = {
            data: [],
            allClients: [],
            listTools: [],
            ParticipantName: "",
            ParticipantDetails: "",
            openModal: false,
            openURLModal: false,
            URLmodal: false,
            activeBtn: false,
            dynamicUrl: "",
            rederectUser: "",
            page: 0,
            rowsPerPage: 20,
            search: "",
            loader: false,
            openDialogName: "",
            participantFile: null,
            uploadSubmitBtn: false,
            uploadStatus: {},
            dragActive: false,
            isfileEdit: false,
            filterselectedclinetname:""
        };
        this.t = this.props.t;
        // @ts-ignore
        this.callRef = React.createRef();
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        this.getParticipantData()
        this.getAllClientsData()
    }


    async receive(from: string, message: Message) {
        
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId === this.getParticipantAPICall) {
                if (responseJson != null && !responseJson.error) {
                    this.setState({ data: responseJson.data })
                } 
            }
            if (apiRequestCallId === this.getAllClinetsAPICall) {
                if (responseJson != null && !responseJson.error) {
                    this.setState({ allClients: responseJson.data })
                }
            }
            if(apiRequestCallId === this.deleteCSVApiCallId){
                if (responseJson.message) {
                    this.setState({ openDialogName: "" })
                }
            }
            this.checkData(apiRequestCallId,responseJson)
            
        }

    }

    checkData=(apiRequestCallId:any,responseJson:any)=>{
        if(apiRequestCallId === this.addParticipantCallId) {  
            if (responseJson?.errors) {
                responseJson?.errors?.map((option: any) => {
                    for (const i in option) {
                        if (option[i] === "has already been taken") {
                            toast.error(`${i.substring(0, 1).toUpperCase() + i.substring(1) } ${this.t("manageParticipant.toast.hasalreadybeentaken")}`);
                            this.setState({ openDialogName: "Add" })
                        }
                    }
                })
            } else {
                this.sentMessage("SAVE_PARTICIPANT")
                this.handleToastMessage()
                this.setState({ openDialogName: "" })
            }
        }
        else if (apiRequestCallId === this.addBulkParticipantApiCallId) {
           this.checkFailCount(responseJson)
        }
    }
    checkFailCount = (responseJson:any)=>{
        if(responseJson.data.fail_count && responseJson.data.fail_count > 0 ){
            toast.error(responseJson.message);
            this.sentMessage("SAVE_PARTICIPANT")
            this.setState({ openDialogName: "error", uploadStatus: responseJson.data, participantFile: null});
            this.getParticipantData();
        } else {
            this.sentMessage("SAVE_PARTICIPANT")
            toast.success(`${this.t("manageParticipant.toast.Allparticipantsaddedsuccessfullyandcredentialshavebeensenttotheirrespectiveemails")}`);
            this.setState({ openDialogName: "", participantFile: null });
            this.getParticipantData();
        }
    }
    handleToastMessage() {
        toast.success(`${this.t("manageParticipant.toast.Participantaddedsuccesfullycredentialshavebeensenttomail")}`, {
            position: toast.POSITION.TOP_RIGHT
        });
    }

    handleDangerToastMessage() {
        toast.error(`${this.t("manageParticipant.toast.Participantalreadyexists")}`, {
            position: toast.POSITION.TOP_RIGHT
        });
    }

    sentMessage (data:any) {
        const msg : Message = new Message(getName(MessageEnum.PostDetailDataMessage))
        msg.properties['text'] = data
        this.send(msg)
    }
    handleOpenModal(dialog:any) {
        this.setState({  openDialogName: dialog })
    }
    handleCloseModal2() {
        this.setState({ openDialogName: "" })
    }
    handleopenURLModal() {
this.setState({ openURLModal: true })}
    handleCloseModal() {
        this.setState({ openURLModal: false })
}
    handleURLmodalOpen = (item: any) => {
            this.setState({ ParticipantName: `${item.attributes.first_name} ${item.attributes.last_name}` })
        this.setState({ ParticipantDetails: item.attributes })
    this.setState({ URLmodal: true })
    }
    handleURLmodalClose = () => {
this.setState({ URLmodal: false })
    };

    generateUniquePassword = (passwordLength : any) => {
        let upperChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
        let lowerChars = "abcdefghiklmnopqrstuvwxyz";
        let specialChars = "!@#$%^&*)(:',.?/><";
        let numberChars = "0123456789";
        let randPasswordArray = Array(passwordLength);
        let allChars = numberChars + upperChars + lowerChars + specialChars;
        randPasswordArray[0] = numberChars;
        randPasswordArray[1] = upperChars;
        randPasswordArray[2] = lowerChars;
        randPasswordArray[3] = specialChars;
        randPasswordArray = randPasswordArray.fill(allChars, 4);
        return this.shuffleArray(
          randPasswordArray.map(function (x) {
            const NumberArray = new Uint8Array(1)
            const randomValue = crypto.getRandomValues(NumberArray)[0]
            const randomFloat = randomValue / Math.pow(2, 8)
            return x[Math.floor(randomFloat * x.length)];
          })
        ).join("");
      };
    
    shuffleArray = (array: any) => {
        for (let i = array.length - 1; i > 0; i--) {
            const numArray = new Uint8Array(1)
            const randomValue = crypto.getRandomValues(numArray)[0]
            const randomFloat = randomValue / Math.pow(2, 8)
          let j = Math.floor(randomFloat * (i + 1));
          let temp = array[i];
          array[i] = array[j];
          array[j] = temp;
        }
        return array;
    };

    setNewPassword = (setFieldValue :any) => {
          const newPassword = this.generateUniquePassword(10);
          setFieldValue("password", newPassword);
    };

    setUsername = ( setFieldValue: any, fname?: any, lname?: any) => {
          const username = fname.substring(0,3) + lname.substring(0,3) + new Date().getDate().toString() + (new Date().getMonth()+ 1).toString() + new Date().getHours().toString()+ new Date().getMinutes().toString() + new Date().getMilliseconds().toString();
          setFieldValue("user_name", username);
      };

      handleDrag = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragenter" || e.type === "dragover") {
          this.setState({ dragActive: true });
        } else if (e.type === "dragleave") {
          this.setState({ dragActive: false });
        }
      };
    
      handleDrop = async (e: any, fileType: any) => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({ dragActive: false });
          if (e.dataTransfer.files[0].type.split('/')[1] !== fileType) {
            toast.warn(this.t("CreateNewProject.AddCompanysection.toast.UploadValid", { value: fileType }))
          } else {
            if (e.dataTransfer.files && e.dataTransfer.files[0]) {
              this.setState({
                isfileEdit: true,
                participantFile: e.dataTransfer.files[0]
              });
            }
          }
      };

    addParticipantData = (values: any) => {
        this.setState({ loader: true })
        let headers = {
            token: localStorage.getItem('accesToken'),
            "Content-Type": "application/json",
        };
        let httpBody = {}
        httpBody = {
            "data": {
                "attributes": values,
                "type": "participant"
            }
        }
        const addClientApiMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.addParticipantCallId = addClientApiMsg.messageId
        addClientApiMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getParticipantData
        );
        addClientApiMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        addClientApiMsg.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );
        addClientApiMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.exampleAPiMethod
        );
        runEngine.sendMessage(addClientApiMsg.id, addClientApiMsg);
        
    }

    addBulkParticipant = () => {
        if (!this.state.participantFile) {
            this.setState({ uploadSubmitBtn: true });
            return;
        }

        this.setState({ loader: true });

        const header = {
            token: localStorage.getItem("accesToken")
        };

        const formData = new FormData();
        formData.append("file", this.state.participantFile);
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.addBulkParticipantApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formData
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            "/account_block/participants/import_participants"
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "POST"
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }

    deleteCSVFunction = () => {
        setTimeout(() => {
            this.deleteCSV()
          }, 5000);
    }

    deleteCSV = () => {
        this.setState({ loader: true });

        const header = {
            token: localStorage.getItem("accesToken")
        };

        
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.deleteCSVApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );


        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            "/account_block/participants/delete_csv_file"
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "DELETE"
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }


    getParticipantData(): boolean {
        const header = {
            token: localStorage.getItem('accesToken')
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getParticipantAPICall = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header));

        requestMessage.addData(
getName(MessageEnum.RestAPIRequestMethodMessage),configJSON.validationApiMethodType
        );

        requestMessage.addData(
getName(MessageEnum.RestAPIResponceEndPointMessage),configJSON.getParticipantData);

runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;

    }

    getAllClientsData(): boolean {
        const header = {token: localStorage.getItem('accesToken')};
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getAllClinetsAPICall = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header));

        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),configJSON.validationApiMethodType);

        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),configJSON.getAllClientsData
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;

    }

    handleFilterBy = (item: any, props:any) => {
        if(item !== null){
            props.setFieldValue("client_id",item.value)
            this.setState({
                filterselectedclinetname: item?.value
            })
        }else{
            props.setFieldValue("client_id","")
            this.setState({
                filterselectedclinetname: ""
            })
        }
    }

}
 // Customizable Area End
