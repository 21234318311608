// Customizable Area Start
import React from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import AddNewClientController from "./AddNewClientController.web";
import { TextField, Grid, Button, Box, Dialog, Typography, Link } from "@material-ui/core";
import { Formik } from "formik";
import "./addNewParticipant.web.css";
import { addClientSchema } from "./validationSchema";
import { withTranslation } from "react-i18next";
const uploadIcon = require("../assets/upload.png");

class AddNewClient extends AddNewClientController {
  constructor(props: any) {
    super(props);
  }
  nameBlur = (e: any, props: any) => {
    props.handleBlur(e);
    props.values.full_name &&
      !props.errors.full_name &&
      this.setNewPassword(props.setFieldValue);
    props.values.full_name &&
      !props.errors.full_name &&
      this.setUsername(props.setFieldValue, props.values.full_name);
  }
  acceptHandlechange = (event: any, props: any) => {
    this.handleChange(event, props)
  }
  handleSubmits = (values: any) => {
    this.handleAddClient(values);
  }
  fullNameRedner = (props: any) => {
    return (
      <Grid item xs={6}>
        <label className="labels">
          {this.t("ManageClient.AddNewClinet.OrganizationName")}
          <span style={{ color: "#f94b4b" }}>*</span>
        </label>
        <Box className="textfield-parent">
          <TextField
            name="full_name"
            id="full_name"
            data-test-id="full_name"
            value={props.values.full_name}
            onChange={props.handleChange}
            onBlur={(e) => {
              this.nameBlur(e, props)

            }}
            className="client-textfields"
            variant="outlined"
            fullWidth
            style={{
              border:
                props.errors.full_name && props.touched.full_name
                  ? "2px solid red"
                  : "",
            }}
          />
          {props.errors.full_name && props.touched.full_name ? (
            <p className="validation_errors">{props.errors.full_name}</p>
          ) : null}
        </Box>
      </Grid>
    );
  };
  emailRender = (props: any) => {
    return (
      <Grid item xs={6}>
        <label className="labels">
          {this.t("ManageClient.AddNewClinet.EmailID")}
          <span style={{ color: "#f94b4b" }}>*</span>
        </label>
        <Box className="textfield-parent">
          <TextField
            name="email"
            id="email"
            value={props.values.email}
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            className="client-textfields"
            fullWidth
            variant="outlined"
            placeholder=""
            style={{
              border:
                props.errors.email && props.touched.email
                  ? "2px solid red"
                  : "",
            }}
          />
          {props.errors.email && props.touched.email ? (
            <p className="validation_errors">{props.errors.email}</p>
          ) : null}
        </Box>
      </Grid>
    );
  }
  render() {
    return (
      <>
        <Button
          id='ModalBtn'
          className="ModalBtn"
          data-test-id='ModalBtn'
          variant="outlined"
          onClick={this.handleOpenModal}
          style={{
            fontFamily: "ProximaNova-Semibold",
            fontSize: "22px",
            padding: "10px",
            marginLeft: "10px",
            backgroundColor: "#f94b4b",
            color: "#fff",
            textTransform: "capitalize"
          }}
        >
          {this.t("ManageClient.AddNewClinet.title")}
        </Button>
        <Dialog
          onClose={this.handleCloseModal2}
          aria-labelledby="customized-dialog-title"
          open={this.state.openModal}
          scroll="body"
        >
          <Box
            className="dialog-title"
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <DialogTitle
              className="modal-title"
              style={{
                fontSize: "30px !important",
                marginLeft: "20px",
                fontFamily: "ProximaNova-Bold !important",
              }}
              id="customized-dialog-title"
            >
              {this.t("ManageClient.AddNewClinet.title")}
            </DialogTitle>
            <Box
              onClick={this.handleCloseModal2}
              style={{
                margin: "20px",
                cursor: "pointer",
                fontSize: "30px !important",
                color: "rgba(0, 0, 0, 0.5)",
              }}
            >
              <span
                style={{
                  color: "rgba(0, 0, 0, 0.5)",
                  fontSize: "30px !important",
                  display: "inline-block",
                }}
              >
                X
              </span>
            </Box>
          </Box>
          <DialogContent style={{ padding: "2.5rem", marginTop: "-30px" }}>
            <Formik
              initialValues={{
                full_name: "",
                user_name: "",
                email: "",
                password: "",
                logo: "",
                questionImage: "",
              }}
              validationSchema={() => addClientSchema(this.t)}
              onSubmit={(values, { setSubmitting }) => {
                this.sentMessage("AddNewMessage")
                this.handleSubmits(values)
              }}
              validateOnChange={true}
              validateOnBlur={true}
            >
              {(props) => (
                <form onSubmit={props.handleSubmit} onDragEnter={this.handleDrag} onDragOver={this.handleDrag}>
                  <Grid className="label-parent" container spacing={3}>
                    {this.fullNameRedner(props)}
                    {this.emailRender(props)}
                    <Grid item xs={6}>
                      <label className="labels">
                        {this.t("ManageClient.AddNewClinet.Username")}{" "}
                        <span style={{ color: "#f94b4b" }}>*</span>
                      </label>
                      <Box className="textfield-parent">
                        <TextField
                          name="user_name"
                          id="user_name"
                          value={props.values.user_name}
                          onBlur={props.handleBlur}
                          InputProps={{ readOnly: true }}
                          className="client-textfields"
                          variant="outlined"
                          fullWidth
                          style={{
                            border:
                              props.errors.user_name && props.touched.user_name
                                ? "2px solid red"
                                : "",
                            backgroundColor: "#eff6ff",
                          }}
                        />
                        {props.errors.user_name && props.touched.user_name ? (
                          <p className="validation_errors">
                            {props.errors.user_name}
                          </p>
                        ) : null}
                      </Box>
                    </Grid>

                    <Grid item xs={6}>
                      <label className="labels">
                        {this.t("ManageClient.AddNewClinet.Password")}
                        <span style={{ color: "#f94b4b" }}>*</span>
                      </label>
                      <Box className="textfield-parent">
                        <TextField
                          name="password"
                          id="password"
                          value={props.values.password}
                          onBlur={props.handleBlur}
                          className="client-textfields"
                          fullWidth
                          variant="outlined"
                          placeholder=""
                          InputProps={{ readOnly: true }}
                          style={{
                            border:
                              props.errors.password && props.touched.password
                                ? "2px solid red"
                                : "",
                            backgroundColor: "#eff6ff",
                          }}
                        />
                        {props.errors.password && props.touched.password ? (
                          <p className="validation_errors">
                            {props.errors.password}
                          </p>
                        ) : null}
                      </Box>
                    </Grid>

                    <Grid item xs={12}>
                      <Typography
                        variant="body2"
                        style={{ marginBottom: "5px" }}
                      >
                        {this.t("ManageClient.AddNewClinet.Uploadlogo")}<span style={{ color: "#f94b4b" }}>*</span>
                      </Typography>
                      <Box className="dragElement">
                        <input
                          data-test-id="questionImage"
                          className="questionImage"
                          type="file"
                          accept="image/png, image/jpeg, image/jpg"
                          style={{ display: "none" }}
                          id="questionImage"
                          ref={(ref) => {
                            //@ts-ignore
                            this.dragDrop = ref;
                          }}
                          onChange={(event: any) => {
                            this.acceptHandlechange(event, props)
                          }}
                        />
                        <label htmlFor="questionImage" style={{ width: "100%" }} className={this.state.dragActive ? "drag-active label-file-upload" : "label-file-upload"}>
                          <Box p={{ xs: "25px", sm: "25px 105px" }} textAlign="center" width="100%" border="1px dashed #bebebe" borderRadius="8px" style={{ cursor: "pointer" }}>
                            <img src={uploadIcon} />
                            <Box mt="15px">
                              {this.t(
                                "ManageClient.AddNewClinet.DropOrBrowseYour"
                              )}
                            </Box>
                            <Box mt="05px" sx={{fontSize:'14px'}}>
                              {this.t(
                                "ManageClient.AddNewClinet.ImageSizeRecommendation"
                              )}
                            </Box>
                          </Box>
                        </label>
                        {this.state.dragActive && <div className="drag-file-element" onDragEnter={(event) => this.handleDrag(event)} onDragLeave={(event) => this.handleDrag(event)} onDragOver={(event) => this.handleDrag(event)} onDrop={(event) => this.handleDrop(event, props)}></div>}
                        <Box mt="3px" fontSize="14px">
                          {this.state.questionImage && (
                            <Link
                              target="_blank"
                              href={URL.createObjectURL(
                                this.state.questionImage
                              )}
                              style={{ cursor: "pointer" }}
                            >
                              {this.t("ManageClient.AddNewClinet.ViewFile")}
                            </Link>
                          )}
                          {props.touched.questionImage &&
                            props.errors.questionImage && (
                              <div className="text-danger">
                                {props.errors.questionImage}
                              </div>
                            )}
                        </Box>
                      </Box>
                    </Grid>

                    <Grid item xs={3}>
                      <Button
                        fullWidth
                        onClick={this.handleCloseModal2}
                        variant="outlined"
                        style={{
                          border: "1px solid #f94b4b",
                          color: "#f94b4b",
                          borderRadius: "10px",
                          padding: "8px 30px",
                          marginTop: "20px",
                          fontWeight: "bold",
                          textTransform: "capitalize",
                          fontFamily: "ProximaNova-Semibold",
                          fontSize: "18px",
                          lineHeight: 1,
                          minHeight: '54px'
                        }}
                      >
                        {this.t("ManageClient.AddNewClinet.Cancel")}
                      </Button>
                    </Grid>
                    <Grid item xs={3}>
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        className="AddParticipantBtn"
                      >
                        {this.t("ManageClient.AddNewClinet.Add")}
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              )}
            </Formik>
          </DialogContent>
        </Dialog>
      </>
    );
  }
}

export default withTranslation()(AddNewClient);
// Customizable Area End
