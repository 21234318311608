// Customizable Area Start
import { Box } from "@material-ui/core";
import moment from "moment";
import React from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import Swal from "sweetalert2";
import AppHeader from "../../../components/src/AppHeader.web";
import AppFooter from "../../../components/src/AppFooter.web";
const { baseURL } = require("../../../framework/src/config.js");
import "./Calendar.web.css";
import interactionPlugin from "@fullcalendar/interaction";
import { withTranslation } from "react-i18next";
import ParticipantEventsController, { Props } from "./ParticipantEventsController.web";


class DefaultCalendar extends ParticipantEventsController {
    constructor(props: Props) {
        super(props);
    }

    fetchEvents = async () => {
        const token1 = localStorage.getItem("accesToken");
        const requestOptions = {
            headers: {
                "Content-Type": "application/json",
                token: `${token1}`,
            },
            method: "GET",
        };

        await fetch(
            `${baseURL}/bx_block_calendar/booked_slots/calender`,
            requestOptions
        )
            .then((responsdata) => {
                if (responsdata.status === 200) {
                    return responsdata.json();
                } else {
                    throw new Error("Something Went Wrong");
                }
            }).then((res) => {
        if (res) {
                let events = res?.events?.map((event: any) => {
        return {title: event.title,description: event.description,
            date:moment(event.start).utc().format("YYYY-MM-DD"),
                timeStart:moment(event.start).utc().format("hh:mm A"),
                timeEnd:moment(event.end).utc().format("hh:mm A"),
                    color:moment().isAfter(moment(event.start).format("YYYY-MM-DD hh:mm")) ? 
                    "green" : "#ADD8E6",
                    start:`${moment(event.start).utc().format()}`,
                        end:`${moment(event.end).utc().format()}`
                };
        });
this.setState({ events: events });
                }
            }).catch((err) => {
                console.log(err);
            });
    };

    async componentDidMount() {
        this.fetchEvents();
    }




    render() {
        return (
            <Box className="calendarModule">
                <AppHeader />
                <Box className="title" date-testid="Heading">{this.t("ParticipantEvents.title")}</Box>
                <Box
                    className="demo-app-main "
                    style={{
                        minWidth: "100%",
                        minHeight: " 100%",
                        fontFamily: " Arial, Helvetica Neue, Helvetica, sans-serif",
                        fontSize: "14px",
                    }}
                    date-testid="questionDisplay"
                >
                    <FullCalendar
                        plugins={[dayGridPlugin, interactionPlugin]}
                        //Dayclick open sweetalert
                        headerToolbar={{
                            left: "today",
                            center: "prev title next",
                            right: "",
                        }}
                        firstDay={1}
                        initialView="dayGridMonth"
                        businessHours={{
                            daysOfWeek: [1, 2, 3, 4, 5, 6],
                        }}
                        slotLabelFormat={{
                            minute: "2-digit",
                            meridiem: true,
                            hour: "numeric",
                          }}
                          timeZone=""
                        weekends={this.state.weekendsVisible}
                        validRange={{
                            start: moment().format("YYYY-MM-DD"),
                        }}
                        eventClick={(info) => {
                            Swal.fire({
                                title: info.event.title,
                                text: `${this.t("ParticipantEvents.SlotTime")} : ` +
                                    `${info.event._def.extendedProps.timeStart}-${info.event._def.extendedProps.timeEnd}` + " " +
                                    `${this.t("ParticipantEvents.Description")} : ` +
                                    info.event._def.extendedProps.description,
                            });
                        }}
                        events={this.state.events}
                        eventTimeFormat={{
                            hour: '2-digit',
                            minute: '2-digit',
                            meridiem: true
                        }}
                        locale={`${localStorage.getItem("language") ?? "en"}`}
                    />
                </Box>
                <AppFooter />
            </Box>
        );
    }
}
export default withTranslation()(DefaultCalendar)
// Customizable Area End
