import React from "react";

import {
  StyleSheet,
  Text,
  Image,
  TouchableOpacity,
  View,
  TextInput,
  ScrollView,
  TouchableWithoutFeedback,
  Button,
  Platform,
  // Customizable Area Start
  // Customizable Area End
} from "react-native";

import AssessmentTestController, {
  Props,
  configJSON
} from "./AssessmentTestController";

export default class AssessmentTest extends AssessmentTestController {
constructor(props: Props)

{super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End
render() {
    return(
      // Customizable Area Start
    <ScrollView keyboardShouldPersistTaps=
    "always" style={styles.container}>
<TouchableWithoutFeedback
      onPress={()=>{this.hideKeyboard();
          }}
        >
          {/* Customizable Area Start */}
          {/* Merge Engine UI Engine Code */}
          <View>
            {this.isPlatformWeb()?(
              <Text              style={styles.title} //UI Engine::From Sketch
                testID="labelTitle" //Merge Engine::From BDS

              >
                    {configJSON.labelTitleText}
              </Text> //UI Engine::From Sketch
) : null}
<Text
          style={styles.body} //UI Engine::From Sketch
      testID="labelBody" //Merge Engine::From BDS
>
    {" "}
              {/* UI Engine::From Sketch */}
          {configJSON.labelBodyText} {/* UI Engine::From Sketch */}
              </Text>

                   <Text testID="txtSaved">
              This is the reviced value :{this.state.txtSavedValue} {" "}
              {/* Merge Engine::From BDS - {...this.testIDValue} */}
            </Text>

            <View 
            style={styles.bgPasswordContainer}>
              <TextInput
                              placeholder={configJSON.txtInputPlaceholder} //UI Engine::From Sketch
                style=
{styles.bgMobileInput} //UI Engine::From Sketch
                testID=
   "txtInput" //Merge Engine::From BDS
       {...this.txtInputProps} //Merge Engine::From BDS - {...this.testIDProps}
              />
  <TouchableOpacity
     style={styles.showHide} //UI Engine::From Sketch
                             testID={"btnShowHide"} //Merge Engine::From BDS
                               {...this.btnShowHideProps}             >
                <Image
                    style={styles.imgShowhide} //UI Engine::From Sketch
                testID={"btnShowHideImage"} //Merge Engine::From BDS - testIDImage
                           {...this.btnShowHideImageProps} //Merge Engine::From BDS - {...this.testIDProps}
                />          </TouchableOpacity>          </View>

            <Button                         title={configJSON.btnExampleTitle} //UI Engine::From Sketch
                                   testID={"btnExample"} //Merge Engine::From BDS
                           {...this.btnExampleProps} //Merge Engine::From BDS - {...this.testIDProps}
            />
          </View>
          {/* Customizable Area End */}
        </TouchableWithoutFeedback>    </ScrollView>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  container: {
    padding: 16,
    marginLeft: "auto",
    flex: 1,
        marginRight: "auto",
    maxWidth: 650,
    backgroundColor: "#ffffffff",
    width: Platform.OS === "web" ? "75%" : "100%",
    
  },
  title: {
    fontSize: 16,
    textAlign: "left",
    marginBottom: 32,    
    marginVertical: 8
  },
  body: {
    fontSize: 16,
    textAlign: "left",
    marginBottom: 32,
        marginVertical: 8
  },
  bgPasswordContainer: {
    marginBottom: 16,
    borderBottomWidth: 1,
    flexDirection: "row",
    backgroundColor: "#00000000",
    borderColor: "#767676",
    borderRadius: 2,
    padding: 10,
    borderWidth: Platform.OS === "web" ? 0 : 1
  },
  bgMobileInput: {
    flex: 1,
    padding:10
  },
  showHide: {
    alignItems: "center"
  },
  imgShowhide: Platform.OS === "web" ? { width: 30, height: 30 } : {}
});
// Customizable Area End
