// Customizable Area Start
import React from "react";
import { Container } from "@material-ui/core";

import "../src/style.css";
// @ts-ignore
import shrm from '../assets/shrm.png'
import EmailAccountRegistrationController, {
  Props,
} from "./EmailAccountRegistrationController";
import { withTranslation } from "react-i18next";

class CheckEmail extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
  }
  async componentDidMount(): Promise<void> {
    let localData = localStorage.getItem("forgateLink") as any
    const NavigateToWhichpage = JSON.parse(localData) === "admin"
    setTimeout(() => {
      if (NavigateToWhichpage) {
        this.props.navigation.navigate("AdminLogin")
      }else{
        this.props.navigation.navigate("Home")
      }
    }, 5000);
  }

  render() {
    return (
      <>
        <div style={{ background: "#1B3C69" }}>
          <Container className="container">
            <div className="checkEmail">
              <img src={shrm} alt="logo" className="shrmlogo" />
              <div className="contentWrapper">
                <div className="whiteBox">
                  <div className="title">{this.t('ForgotYourPassword.CheckInYourMail')}</div>
                  <div className="content">
                    {this.t('ForgotYourPassword.WeJustEmailedYouWithTheInstructionsToResetYourPassword')}
                  </div>
                </div>
                <div className="copyright">
                  {this.state.copyright}
                </div>
              </div>
            </div>
          </Container>
        </div>
      </>
    );
  }
}
export default withTranslation()(CheckEmail)
// Customizable Area End
