// Customizable Area Start
import React from "react";

function AssignAvailableIcon() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="white" fillRule="nonzero">
        <path d="M16.5 2.4h-3.6v-.9c0-.828-.672-1.5-1.5-1.5H1.5C.672 0 0 .672 0 1.5v14.7A1.802 1.802 0 0 0 1.8 18h14.7c.828 0 1.5-.672 1.5-1.5V3.9c0-.828-.672-1.5-1.5-1.5zm.9 14.1c0 .497-.403.9-.9.9H3.14c.297-.33.46-.757.46-1.2v-3.9H3v3.9a1.2 1.2 0 1 1-2.4 0V1.5c0-.497.403-.9.9-.9h9.9c.497 0 .9.403.9.9v.9H4.5c-.828 0-1.5.672-1.5 1.5v4.2h.6V3.9c0-.497.403-.9.9-.9h12c.497 0 .9.403.9.9v12.6z" />
        <path d="M4.8 8.7H1.2v3h3.6v1.624L7.924 10.2 4.8 7.076V8.7zm.6-.176L7.076 10.2 5.4 11.876V11.1H1.8V9.3h3.6v-.776zM12.6 6a4.2 4.2 0 1 0 0 8.4 4.2 4.2 0 0 0 0-8.4zm0 7.8c-.877 0-1.723-.32-2.38-.9l.75-2a.3.3 0 0 1 .208-.185l1.258-.315-.373-.744A.603.603 0 0 1 12 9.388V8.7a.6.6 0 0 1 1.2 0v.688a.603.603 0 0 1-.063.268l-.373.744 1.258.315a.3.3 0 0 1 .208.185l.75 2a3.585 3.585 0 0 1-2.38.9zm2.838-1.387-.646-1.723a.9.9 0 0 0-.625-.557L13.636 10l.037-.076c.083-.167.127-.35.127-.536V8.7a1.2 1.2 0 1 0-2.4 0v.688c0 .186.044.37.127.536l.037.076-.531.133a.9.9 0 0 0-.625.557l-.646 1.723a3.6 3.6 0 1 1 5.676 0z" />
      </g>
    </svg>
  );
}

export default AssignAvailableIcon;

// Customizable Area End
