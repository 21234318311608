import React from "react";
export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const SvgFileImageComponent = ({ height = 22, width = 20, ...props }) => {
  return (
    <svg  {...props} width={width} height={height} viewBox="0 0 20 22" xmlns="http://www.w3.org/2000/svg">
      <g fill="" stroke="" strokeWidth=".25" fillRule="evenodd">
        <path d="M18.856 4.034 16.09 1.269A.914.914 0 0 0 15.44 1H3.798a.92.92 0 0 0-.92.92v9.1h-.646c-.68 0-1.233.553-1.233 1.232v5.637c0 .68.553 1.232 1.233 1.232h9.709a.92.92 0 0 0 .92-.919v-4.384a.92.92 0 0 0-.92-.92h-9.71a.647.647 0 0 1 0-1.292h.647v.334a.293.293 0 0 0 .586 0V1.92c0-.185.15-.334.334-.334h11.295v2.838c0 .334.272.606.606.606h2.839v15.05c0 .185-.15.334-.333.334H3.799a.334.334 0 0 1-.334-.333.293.293 0 0 0-.586 0c0 .507.413.919.92.919h14.407a.92.92 0 0 0 .919-.92V4.685a.913.913 0 0 0-.27-.65zm-16.623 9.45h9.709c.184 0 .333.15.333.334v4.384c0 .184-.15.333-.333.333h-9.71a.647.647 0 0 1-.646-.646V13.3c.188.117.41.184.647.184zm13.447-9.06V1.687l2.758 2.757H15.7a.02.02 0 0 1-.02-.02z" />
        <path d="M6.315 17.423c.02.003.037.006.313.006l.61-.003c.16 0 .288-.121.287-.27 0-.148-.132-.266-.29-.267l-.583.002V14.84c0-.148-.13-.269-.288-.269-.16 0-.289.12-.289.269v2.318c0 .13.101.243.24.265zM9.396 17.429c.51 0 .937-.305 1.017-.725.06-.315-.093-.726-.703-.938a13.644 13.644 0 0 1-.677-.257.176.176 0 0 1-.07-.172c.006-.045.04-.155.212-.203.36-.102.691.144.7.151.121.094.3.08.402-.034a.258.258 0 0 0-.035-.378c-.023-.018-.57-.441-1.232-.254-.333.094-.568.34-.614.642a.7.7 0 0 0 .38.729c.014.006.354.148.736.28.114.04.374.152.338.34-.026.138-.203.281-.454.281a.911.911 0 0 1-.648-.255.299.299 0 0 0-.404-.019.257.257 0 0 0-.02.38c.261.27.662.432 1.072.432zM3.283 17.38a.246.246 0 0 0 .356-.065l.624-.93.623.93a.246.246 0 0 0 .356.065.277.277 0 0 0 .061-.374l-.728-1.088.62-.923a.277.277 0 0 0-.063-.375.248.248 0 0 0-.356.065l-.513.766-.512-.766a.248.248 0 0 0-.356-.065.277.277 0 0 0-.062.374l.618.923-.73 1.088a.277.277 0 0 0 .062.375z" fillRule="nonzero" />
        <path d="M14.775 6.574A.586.586 0 0 0 14.178 6H7.397a.586.586 0 0 0-.597.574v3.852c0 .317.268.574.597.574h6.781a.586.586 0 0 0 .597-.574V6.574zm-4.932 2.39v-.928h1.89v.928h-1.89zm1.89.554v.927h-1.89v-.927h1.89zM7.376 8.036h1.889v.928h-1.89v-.928zm2.466-.554v-.927h1.89v.927h-1.89zm2.466.554h1.89v.928h-1.89v-.928zm1.89-1.462v.908h-1.89v-.927h1.87c.01 0 .02.008.02.019zm-6.802-.02h1.869v.928h-1.89v-.908c0-.01.01-.02.02-.02zm-.02 3.872v-.908h1.889v.927h-1.87a.02.02 0 0 1-.02-.019zm6.801.02h-1.87v-.928h1.89v.908a.02.02 0 0 1-.02.02z" />
      </g>
    </svg>
  );
};