import React from "react";

import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  TouchableWithoutFeedback,
  Platform
  // Customizable Area Start
  // Customizable Area End
} from "react-native";

import CameraAccessController, {
  Props,
  configJSON
} from "./CameraAccessController";

export default class CameraAccess extends CameraAccessController {
  
  constructor(props: Props) {
  super(props);
    // Customizable Area Start
    // Customizable Area End
  }
// Customizable Area Start
// Customizable Area End

  render() {
    return(
      // Customizable Area Start
<ScrollView 
keyboardShouldPersistTaps="always" 
style={styles.container}>
<TouchableWithoutFeedback onPress={() => {          this.hideKeyboard();
          }}
        >
          {/* Customizable Area Start */}
          {/* Merge Engine UI Engine Code */}
    <View>{this.isPlatformWeb() ? (
<Text                 style={styles.title} //UI Engine::From Sketch
                testID="labelTitle" //Merge Engine::From BDS
              >{configJSON.labelTitleText}
              </Text> //UI Engine::From Sketch
            )
             : 
             null
             }

<Text
              testID=
              "labelBody" //Merge Engine::From BDS
              style=
              {styles.body} //UI Engine::From Sketch
            >
{" "}
              {/* UI Engine::From Sketch */}{configJSON.labelBodyText} {/* UI Engine::From Sketch */}
              Web Code and UI WIP
            </Text>
          </View>
          {/* Merge Engine UI Engine Code */}
          {/* Customizable Area End */}
        </TouchableWithoutFeedback>
      </ScrollView>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles = 
StyleSheet.create({
  container: {
    marginRight: "auto",
    flex: 1,
    padding: 16,
    marginLeft: "auto",
    width: Platform.OS === "web" ? "75%" : "100%",
    maxWidth: 650,
    backgroundColor: "#ffffffff"
  },
  title: {
    marginVertical: 8
,
    marginBottom: 32,
    fontSize: 16,
    textAlign: "left",
  },
  body: {
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8,    marginBottom: 32,

  },
  bgPasswordContainer: {
    backgroundColor: "#00000000",
    marginBottom: 16,
    borderBottomWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    padding: 10,    flexDirection: "row",

    borderWidth: Platform.OS === "web" ? 0 : 1
  },
  
  showHide: {
    alignSelf: "center"
  },
  bgMobileInput: {
    flex: 1
  },
  imgShowhide: Platform.OS === "web" ? { height: 30, width: 30 } : {}
});
// Customizable Area End
