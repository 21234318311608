// Customizable Area Start
import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// import { toast } from "react-toastify";

export const configJSON = require("./config");

export interface Props {
  navigation?: any;
  id?: string;
  t: any;
}

interface S {
    playing: boolean;
    screenData:any;
    currentTime:number | any;
    isLoading:boolean;
}

interface SS {
  id: any;
}

export default class CoverImageController extends BlockComponent<
  Props,
  S,
  SS
> {
  getHeaderAPICall: string = '';
  getDynamicScreenApiCallID: string = '';
  t: any;

  constructor(props: Props) {

    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.PostDetailDataMessage)
    ]

    this.state = {
        playing: false,
        screenData: null,
        currentTime:0,
        isLoading: false
    };

    this.t = this.props.t;
    // @ts-ignore
    this.callRef = React.createRef(); 
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
   let id = localStorage.getItem("participantId");
   this.getDynamicScreen(id);
  }

  async componentDidUpdate(prevProps: any, prevState: any) {
   
  }

  async receive(from: string, message: Message) {
    if(getName(MessageEnum.PostDetailDataMessage) === message.id){
      if(message.properties.text === "SAVE_FESTIVAL_FORM_DATA"){
          
      }
      if(message.properties.text === "LIST_FESTIVAL_FORM_DATA") {
        
      }
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId === this.getDynamicScreenApiCallID) {
        if (responseJson != null && !responseJson.error) {
          this.setState({screenData : responseJson.data,isLoading:false});
        }
      }

      if (apiRequestCallId === this.getHeaderAPICall) {
        if (responseJson != null && !responseJson.error) {
          console.log("is it called",responseJson.data)
          // localStorage.setItem('usertoken', responseJson.data.attributes.user_role)
          // localStorage.setItem('profile',JSON.stringify(responseJson.data.attributes))
        // toast.success("Congratulations, Your Event is listed")
          //@ts-ignore
        } else {
        //   toast.error(responseJson.error)
        }
      }

      
    }
  }

  apiCall = async (data: any) => {
    const token = localStorage.getItem("accesToken") as string
    const { contentType, method, endPoint, body, type } = data
    const header = {
        'Content-Type': contentType, token: token
    }
    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    )
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    )
    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        endPoint
    )
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        method
    )
    body && type != 'formData' ?
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(body))

        : requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            body
        );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
}

getDynamicScreen = async (id:any) => {
  this.setState({isLoading : true});
  this.getDynamicScreenApiCallID = await this.apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `/bx_block_profile/projects/get_welcome_screen?id=${id}`,
  });
}
  handleDashboard = () => {
    this.props.navigation.navigate("Dashboard")
  }

  getHeaderData(): boolean {
    const header = {
      token: localStorage.getItem('accesToken')
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getHeaderAPICall = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointApiGetHeaderData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  onPlay = () => {
    this.setState({ playing: true });
  };
  onPause = () => {
    this.setState({ playing: false });
  };
  
  playAudio = () => {
    //@ts-ignore
    this.audioEl.play();
    //@ts-ignore
    const audio = this.audioEl;
    audio.addEventListener("play", this.onPlay);
    audio.addEventListener("pause", this.onPause);
  };

  pauseAudio = () => {
    //@ts-ignore
    this.audioEl.pause();
  };

  startAudio = () => {
    this.playAudio();
  };

  handleTimeUpdate = (event:any) => {
    const video = event.target;
    
    // Disable fast-forwarding by resetting the video's current time if it's greater than the current playback time
    if (video.currentTime < this.state.currentTime) {
      video.currentTime = this.state.currentTime;
    } else {
      this.setState({
        currentTime:video.currentTime
      })
    }
  }
 
}
 // Customizable Area End
