import { Message } from "../../../../../framework/src/Message";
import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import { runEngine } from "../../../../../framework/src/RunEngine";
import MessageEnum, {
    getName,
} from "../../../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { toast } from "react-toastify";
// Customizable Area End

export const configJSON = require("../../config");

export interface Props {
    id: string;
    navigation: any;
    t: any;
}

export interface S {
    // Customizable Area Start
    loading: boolean;
    page: number;
    rowsPerPage: number;
    dataLength: number;
    count: number | null;
    sectorId: any;
    sectorName: string;
    search: string;
    openDialogName: string | null;
    data: any[];
    tableData: any[];
    filterData: any[];
    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class SectorController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    getSectorListApiCallId: string = "";
    addSectorApiCallId: string = "";
    editSectorApiCallId: string = "";
    deleteSectorApiCallId: string = "";
    t: any;
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage),
        ];
        this.receive = this.receive.bind(this);
        runEngine.attachBuildingBlock(this, this.subScribedMessages);

        this.state = {
            // Customizable Area Start
            loading: false,
            page: 0,
            rowsPerPage: 20,
            dataLength: 0,
            count: null,
            sectorId: null,
            search: "",
            openDialogName: "",
            tableData: [],
            data: [],
            filterData: [],
            sectorName: "",
            // Customizable Area End
        };
        this.t = this.props.t;
    }

    async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson: any = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId && responseJson) {
                if (responseJson.status === 500) {
                    toast.error("Something went wrong!");
                    this.setState({ loading: false });
                } else if (!Array.isArray(responseJson.errors) && responseJson.errors) {
                    toast.error(responseJson.errors);
                    this.setState({ loading: false });
                } else {
                    this.handleApiResponse(apiRequestCallId, responseJson);
                }
            }
        }
    }

    async componentDidMount() {
        window.scrollTo(0, 0);
        this.getSectorList();
    }

    handleApiResponse = (apiRequestCallId: string, responseJson: any) => {
        if (Array.isArray(responseJson.errors)) {
            if (responseJson.errors[0].name) {
                toast.error(responseJson.errors[0].name);
            } else if (responseJson.errors[0].message) {
                toast.error(responseJson.errors[0].message);
            } else {
                toast.error(this.t("organizationFunction.sector.toast.somethingWrong"));
            }
            this.setState({ loading: false });
        } else if (apiRequestCallId === this.getSectorListApiCallId) {
            this.handelSectorListApiResponse(responseJson);
        } else if (apiRequestCallId === this.addSectorApiCallId) {
            this.handelAddSectorApiResponse(responseJson);
        } else if (apiRequestCallId === this.editSectorApiCallId) {
            this.handelEditSectorApiResponse(responseJson);
        } else if (apiRequestCallId === this.deleteSectorApiCallId) {
            this.handelDeleteSectorApiResponse();
        }
    }

    handelSectorListApiResponse = (responseJson: any) => {
        const data = responseJson.data.map((item: any) => {
            return {
                id: item.id,
                sectorName: item.attributes.name
            }
        });
        this.setState({ tableData: data, filterData: data, loading: false },
            () => { this.onChangeHandler(this.state.search, 0) }
        );
    }

    handelAddSectorApiResponse = (responseJson: any) => {
        toast.success(this.t("organizationFunction.sector.toast.add"));
        let tableData = [...this.state.tableData];
        const obj = {
            id: responseJson.data.id,
            sectorName: responseJson.data.attributes.name
        };
        tableData = [obj, ...tableData];
        this.setState({ tableData, filterData: tableData, sectorId: null, sectorName: "", openDialogName: null, loading: false },
            () => { this.onChangeHandler(this.state.search, 0) }
        );
    }

    handelEditSectorApiResponse = (responseJson: any) => {
        toast.success(this.t("organizationFunction.sector.toast.edit"));
        let tableData = [...this.state.tableData];
        tableData = tableData.map((item: any) => {
            if (item.id === this.state.sectorId) {
                return {
                    id: responseJson.data.id,
                    sectorName: responseJson.data.attributes.name
                };
            } else {
                return item;
            }
        });

        this.setState({ tableData, filterData: tableData, sectorId: null, sectorName: "", openDialogName: null, loading: false },
            () => { this.onChangeHandler(this.state.search, this.state.page) }
        );
    }

    handelDeleteSectorApiResponse = () => {
        toast.success(this.t("organizationFunction.sector.toast.delete"));
        let tableData = [...this.state.tableData];
        tableData = tableData.filter((item: any) => item.id !== this.state.sectorId);
        this.setState({ tableData, filterData: tableData, sectorId: null, sectorName: "", openDialogName: null, loading: false },
            () => { this.onChangeHandler(this.state.search, this.state.page) }
        );
    }

    onChangeHandler = (search: string, page: number) => {
        const { filterData, rowsPerPage } = this.state;
        let data = filterData.filter((item: any) => {
            if (search === "") {
                return true;
            } else if (item.sectorName.toString().toLowerCase().includes(search.toLowerCase())) {
                return item;
            }
        });

        const dataLength = data.length;
        let totalPage = Math.ceil(dataLength / this.state.rowsPerPage);
        page = totalPage === page ? page - 1 : page;
        data = data.slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage
        );
        this.setState({ data, dataLength, page, search });
    }

    getSectorList = () => {
        this.setState({ loading: true });

        const header = {
            "Content-Type": configJSON.sectorListContentType,
            token: localStorage.getItem("accesToken")
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getSectorListApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.sectorListApiEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.sectorListApiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }

    addSector = () => {
        this.setState({ loading: true });

        const header = {
            "Content-Type": configJSON.addSectorApiContentType,
            token: localStorage.getItem("accesToken")
        };

        const httpBody = {
            "name": this.state.sectorName,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.addSectorApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.addSectorApiEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.addSectorApiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }

    editSector = () => {
        this.setState({ loading: true });

        const header = {
            "Content-Type": configJSON.editSectorApiContentType,
            token: localStorage.getItem("accesToken")
        };

        const httpBody = {
            "name": this.state.sectorName,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.editSectorApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.editSectorApiEndPoint}/${this.state.sectorId}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.editSectorApiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }

    deleteSector = () => {
        this.setState({ loading: true });
        const header = {
            "Content-Type": configJSON.deleteSectorApiContentType,
            token: localStorage.getItem("accesToken")
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.deleteSectorApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.deleteSectorApiEndPoint}/${this.state.sectorId}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.deleteSectorApiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }
}
// Customizable Area End