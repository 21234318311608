// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { toast} from "react-toastify";
const { baseURL } = require("../../../framework/src/config")
import generator from 'generate-password'
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  location: any;
  history: any;
  match: any;
  t: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  data: any[];
  filterData: any[];
  openModal: boolean;
  openURLModal: boolean;
  URLmodal: boolean;
  EditModal: boolean;
  dynamicUrl: any;
  rederectUser: any;
  clientName: any
  clientEmail: any
  clientUserName: string,
  clientPassword: any,
  clientNameError: any,
  clientEmailError: any,
  clientUserNameError: any,
  clientPasswordError: any,
  loader: boolean,
  page: any,
  rowsPerPage: any,
  search: any,
  deleteModel:  any,
  deleteModelText:  any,
  clientDetails: any,
  questionImage: any,
  isfileEdit: boolean,
  dragActive: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class LandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  addClientCallId: string = "";
  getURLdataAPICall = "string"
  Tabledata: any = ""
  Userdelete: any = ""
  updateClientAPICall: string = "";
  t: any;
  dragDrop: any = "";


  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.handleOpenModal = this.handleOpenModal.bind(this)
    this.handleopenURLModal = this.handleopenURLModal.bind(this)
    this.handleCloseModal = this.handleCloseModal.bind(this)
    this.handleCloseModal2 = this.handleCloseModal2.bind(this)
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.PostDetailDataMessage)
    ]

    this.state = {
      filterData: [],
      data: [],
      openModal: false,
      openURLModal: false,
      URLmodal: false,
      rederectUser: "",
      dynamicUrl: "",
      clientName: "",
      clientEmail: "",
      clientPassword: "",
      clientNameError: "",
      clientUserNameError: "",       
      clientEmailError: "",
      clientPasswordError: "",
      loader: true,
      page: 0,
      clientUserName: "",
      rowsPerPage: 20,
      search: "",
      deleteModelText: [],
      deleteModel: false,
      clientDetails: null,
      EditModal: false,
      isfileEdit: false,
      questionImage: '',
      dragActive: false
    };
    this.t = this.props.t;
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  // Customizable Area Start
  async componentDidMount(): Promise<void> {
    return this.getTable()
  }

  createPassword() {
    const password = generator.generate({
      length: 8,
      numbers: true,
      symbols: true
    });
    return password
  }

  createUsername(organisation: string) {
    const tempNumber = new Uint8Array(1)
    const randomValue = crypto.getRandomValues(tempNumber)[0]
    const randomFloat = randomValue / Math.pow(2, 8)
    let userName = organisation + Math.floor(randomFloat * 1000)
    return userName

  }

  handleCompanyNameBlur() {
    this.setState({ clientUserName: this.createUsername(this.state.clientName), clientPassword: this.createPassword() }, () => {
    })
  }
  handleValid() {
    if (this.state.clientName === "" || !this.state.clientEmail.includes('@') || this.state.clientUserName === "" || this.state.clientPassword.length < 4) {
      if (this.state.clientName === "") {

        this.setState({ clientNameError: "Name cannot be empty" })

      }
      if (this.state.clientPassword.length < 8) {
        
        this.setState({ clientPasswordError: "Password must be atleast 8 characters long" })
        
      }
      if (this.state.clientUserName === "") {
        this.setState({ clientUserNameError: "Username cannot be empty" })
        
      }
      if (!this.state.clientEmail.includes('@')) {
        this.setState({ clientEmailError: "Email must be valid" })

      }
      return false

    }

    return true

  }

  handleToastMessage() {
    toast.success(this.t("ManageClient.ToastMessage.ClientAdd"), {
      position: toast.POSITION.TOP_RIGHT
    });
  }

  handleAddClient(e: any) {
    e.preventDefault()
    let { clientEmail, clientName, clientPassword, clientUserName } = this.state

    let httpBody = {}
    httpBody = {
      "data": {
        "attributes": {
          "email": clientEmail,
          "full_name": clientName,
          "user_name": clientUserName,
          "password": clientPassword
        },
        type: "client"
      }
    }
    let headers = {
      "Content-Type": "application/json",
      token: localStorage.getItem("accesToken")
    };
        const addClientApiMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
         this.addClientCallId = addClientApiMsg.messageId
addClientApiMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/account_block/clients"
    );
addClientApiMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers));
addClientApiMsg.addData(getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody));
addClientApiMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST");
    runEngine.sendMessage(addClientApiMsg.id, addClientApiMsg);
    this.setState({ openModal: false, clientName: "", clientEmail: "", 
    clientPassword: "", clientUserName: "", clientNameError: "", 
    clientEmailError: "", clientUserNameError: "", clientPasswordError: "" 
  }
  )
  }

  // Customizable Area End

  // Customizable Area Start
async receive(from: string, message: Message) {
    this.checkAddClientApiCallId(message)
    this.checkupdateClientApiCallId(message)

    if (getName(MessageEnum.PostDetailDataMessage) === message.id) {
      if (message.properties.text === "CLiENT_ADD") {
        this.setState({ search: "", });
        this.getTableData()
      }
      if (message.properties.text === "AddNewMessage") {
        this.setState({ loader:true });
      }
      if(message.properties.text === "CLiENT_ADD_FAILD"){
        this.setState({ loader:false });
        toast.error(this.t("ManageClient.errorMessages.somethingWrong"))
      }
      if(message.properties.text === "LOADERFALSE"){
        this.setState({ loader:false });
      }
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.ApicallForUrlTableUserData(apiRequestCallId,responseJson)
    }
  }
  
  ApicallForUrlTableUserData = (apiRequestCallId:any,responseJson:any) =>{
    if (apiRequestCallId === this.getURLdataAPICall) {
      if (!responseJson.errors) {
        this.setState({ dynamicUrl: responseJson.info, rederectUser: responseJson.info.split("/")[3] })
        toast.success(`${this.t("ManageClient.ToastMessage.Congratulations")}, ${responseJson.meta.message}`)
      } else {
        toast.error(responseJson.errors[0].message)
      }
    }
    else if (apiRequestCallId === this.Tabledata) {
      this.setState((e) => ({
        ...e, data: responseJson?.data, filterData: responseJson?.data, loader: false
      }))
    }
    else if (apiRequestCallId === this.Userdelete) {
      this.getTable()
      this.setState({
        ...this.state, deleteModel: false
      })
      if (responseJson.status === 500) {
        toast.error(this.t("ManageClient.errorMessages.somethingWrong"))
      } else {
        toast.success(this.t("ManageClient.ToastMessage.ClientDelete"), {
          position: toast.POSITION.TOP_RIGHT
        });
      }
    }
  }
  checkAddClientApiCallId=(message:any)=>{
    if (this.addClientCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
     this.checkApiResponseErrors(apiResponse)
    }

  }
  checkApiResponseErrors=(apiResponse:any)=>
  {
    if (apiResponse?.errors) {
      apiResponse?.errors?.map((option: any) => {
        for (const i in option) {
          if (option[i] == "has already been taken") {
            toast.error(this.t("ManageClient.ToastMessage.ClientWithSameEmail"))

          }
          else if (option[i] == "Admin with same name already exists.") {
            toast.error(this.t("ManageClient.ToastMessage.ClientWithSameName"))
          }

        }
      })

    }
  }
  checkupdateClientApiCallId = (message: any) => {
    if (this.updateClientAPICall === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if(responseJson?.errors?.[0]?.user_name !== undefined){
        toast.error(this.t("ManageClient.ToastMessage.Userhasalreadybeentaken"));
        this.setState({
          loader: false
        })
      }
      if(responseJson?.errors?.[0]?.email !== undefined){
        toast.error(this.t("ManageClient.ToastMessage.Emailhasalreadybeentaken"));
        this.setState({
          loader: false
        })
      }
      if (responseJson?.errors === undefined) {
        this.setState({
          loader: false,EditModal: false, isfileEdit: false
        })
        toast.success(this.t("ManageClient.ToastMessage.ClientUpdated"))
      }
    }
  }

  handleOpenModal() {

    this.setState({ openModal: true })

  }
  handleCloseModal2() {
    this.setState({ openModal: false, clientUserName: "", clientPassword: "" })
  }
  // Customizable Area End

  // Customizable Area Start
  onSearch(search: string) {
    const FilteredData = this.state.data.filter((item: any) => {
      if (search === "") {
        return true;
      } else if (
        item.attributes.user_name.toLowerCase().includes(search.toLowerCase()) ||
        item.attributes.email.toLowerCase().includes(search.toLowerCase())
      ) {
        return item;
      }
    })

    this.setState({ ...this.state, filterData: FilteredData, search: search, page: 0 })
  }

  handleopenURLModal() {
    this.setState({ openURLModal: true })
  }
  handleCloseModal() {
    this.setState({ openURLModal: false })
  }
  goToHome() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }
  getTable = () => {
    this.getTableData()

  }

  handleEditmodalOpen = (item: any) => {
    this.setState({ clientName: `${item.attributes.full_name}`, clientDetails: item.attributes, questionImage: item.attributes.logo_url })
    this.setState({ EditModal: true })
  }

  handleURLmodalClose = () => {
    this.setState({ URLmodal: false })
  };

  handleURLmodalOpen = (item: any) => {
    this.getURLData(item.attributes?.full_name, item.attributes?.id);
    this.setState({ URLmodal: true })
  }
  handleEditmodalClose = () => {
    this.setState({ EditModal: false })
  }

  getURLData(comp_name: any, comp_id: any): boolean {
    const payload = {
      company_name: comp_name,
      id: localStorage.getItem('userId'),
      client_id: comp_id
    };

    const header = {
      token: localStorage.getItem('accesToken'),
      "Content-Type": "application/json",
    };

         const requestMessage = new Message(
             getName(MessageEnum.RestAPIRequestMessage) );
            this.getURLdataAPICall = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.endPointApiGetURLData  );
           requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
             JSON.stringify(header));
requestMessage.addData(
getName(MessageEnum.RestAPIRequestMethodMessage),
configJSON.exampleAPiMethod );

    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(payload));

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  // handle drag events
  handleDrag = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      this.setState({ dragActive: true });
    } else if (e.type === "dragleave") {
      this.setState({ dragActive: false });
    }
  };

  // triggers when file is dropped
  handleDrop = (e: any, props: any) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ dragActive: false });
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      props.setFieldValue("questionImage", e.dataTransfer.files[0]);
      this.setState({
        questionImage: e.dataTransfer.files[0],
        isfileEdit: true
      });
    }
  };

  // triggers when file is selected with click
  handleChange = (e: any, props: any) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      props.setFieldValue("questionImage", e.target.files[0]);
      this.setState({
        questionImage: e.target.files[0],
        isfileEdit: true
      });
    }
  };

  // triggers the input when the button is clicked
  onButtonClick = () => {
    this.dragDrop.click();
  };

  getBase64 = (file: Blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  handleUpdateClient = async (values: any) => {
    this.setState({
      loader:true
    })
    const { full_name, email, user_name, password } = values
    const { clientDetails } = this.state
    let headers = {
      "Content-Type": "application/json",
      token: localStorage.getItem("accesToken")
    };
    let httpBody = {}

    httpBody = {
      "data": {
        "attributes": {
          "full_name": full_name,
          "email": email,
          "user_name": user_name,
          "password": password,
          "profile": this.state.isfileEdit ? { "data": this.state.questionImage ? await this.getBase64(this.state.questionImage) : null } : undefined,
        }
      }
    }
    const loginEditMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.updateClientAPICall = loginEditMsg.messageId;

    loginEditMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/clients/${clientDetails.id}`
    );

    loginEditMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    loginEditMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    loginEditMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
    runEngine.sendMessage(loginEditMsg.id, loginEditMsg);
  }

getTableData = () => {const header = {
"Content-Type": "application/json",
token: localStorage.getItem("accesToken"),};

const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

this.Tabledata = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/account_block/clients");

requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)    );

requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
);

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

          DeleteUser = () => {
             const header = {"Content-Type": "application/json",
              token: localStorage.getItem("accesToken")
            };

              const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
           );

             this.Userdelete = requestMessage.messageId;
                                 requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
              `/account_block/clients/${this.state.deleteModelText.id}`
            );
requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE");
runEngine.sendMessage(requestMessage.id, requestMessage);}
  // Customizable Area End
}
// Customizable Area End