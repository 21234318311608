// Customizable Area Start
import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { toast } from "react-toastify";

export const configJSON = require("./config");

export interface Props {
  navigation?: any;
  id?: string;
  t: any;
}

interface S {
  data: any;
  filterData: any,
  allClients: any;
  listTools: any
  AssessorName: any;
  AssessorDetails: any;
  openModal: boolean;
  openURLModal: boolean;
  URLmodal: boolean;
  deleteModal: boolean;
  deleteId: any;
  dynamicUrl: any;
  rederectUser: any;
  activeBtn: boolean,
  page: any,
  rowsPerPage: any,
  search: any;
  loader: boolean;
}

interface SS {
  id: any;
}

export default class ManageAssessorController extends BlockComponent<
  Props,
  S,
  SS
> {
  getAssessorAPICall: string = '';
  deleteAssessorAPICall: string = '';
  updateAssessorAPICall: string = '';
  t: any = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this)
    this.handleOpenModal = this.handleOpenModal.bind(this)
    this.handleopenURLModal = this.handleopenURLModal.bind(this)
    this.handleCloseModal = this.handleCloseModal.bind(this)
    this.handleCloseModal2 = this.handleCloseModal2.bind(this)

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.PostDetailDataMessage)
    ]

    this.state = {
      data: [],
      filterData: [],
      allClients: [],
      listTools: [],
      AssessorName: "",
      AssessorDetails: "",
      openModal: false,
      openURLModal: false,
      URLmodal: false,
      deleteModal: false,
      deleteId: "",
      activeBtn: false,
      dynamicUrl: "",
      rederectUser: "",
      page: 0,
      rowsPerPage: 20,
      search: "",
      loader: true,
    };
    this.t = this.props.t;
    // @ts-ignore
    this.callRef = React.createRef();
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.getAssessorData();
  }

  onSearch(search: string, pageNumber: number) {
    const FilteredData = this.state.data.filter((item: any) => {
      if (search === "") {
        return true;
      } else if (
        item.attributes.full_name.toLowerCase().includes(search.toLowerCase()) ||
        item.attributes.email.toLowerCase().includes(search.toLowerCase()) ||
        item.attributes.user_name.toLowerCase().includes(search.toLowerCase())
      ) {
        return item;
      }
    })
    this.setState({ ...this.state, filterData: FilteredData, search: search, page: 0 })
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.PostDetailDataMessage) === message.id) {
      if (message.properties.text === "SAVE_PARTICIPANT") {
        this.setState({ search: "", loader: true });
        this.getAssessorData();
      }
      if (message.properties.text === "SAVENEWPARTICIPANT") {
        this.setState({ search: "", loader: true });
      }
      if (message.properties.text === "EMAILALREDYEXISIT") {
        this.setState({ search: "", loader: false });
      }
    }
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      this.apiAfterResCalls(apiRequestCallId, responseJson)
    }
  }
  apiAfterResCalls = (apiRequestCallId: string, responseJson: any) => {
    if (apiRequestCallId === this.getAssessorAPICall) {
      if (responseJson != null) {
        this.setState({
          data: responseJson.data,
          filterData: responseJson.data,
          loader: false
        });
      }
    } else if (apiRequestCallId === this.deleteAssessorAPICall) {
      this.handleDeleteToastMessage();
      this.getAssessorData();
      this.setState({ search: "" });
    } else if (apiRequestCallId === this.updateAssessorAPICall) {
      if (responseJson?.errors?.email) {
        toast.error(this.t("manageAssessor.toast.exist"));
        this.setState({loader:false})
      }
      if (responseJson?.errors?.user_name) {
        toast.error(this.t("manageAssessor.toast.username"));
        this.setState({loader:false})
      } 
      if (responseJson?.errors === undefined) {
        this.handleUpdatedToast();
        this.getAssessorData();
        this.setState({ search: "", loader:false},()=>{
          this.getAssessorData()
        });
      }
    }
  }

  handleToastMessage() {
    toast.success(this.t("manageAssessor.toast.add"), {
      position: toast.POSITION.TOP_RIGHT
    });
  }

  handleUpdatedToast() {
    toast.success(this.t("manageAssessor.toast.edit"), {
      position: toast.POSITION.TOP_RIGHT
    });
  }

  handleDeleteToastMessage() {
    toast.success(this.t("manageAssessor.toast.delete"), {
      position: toast.POSITION.TOP_RIGHT
    });
  }

  handleOpenModal() {

    this.setState({ openModal: true })

  }
  handleCloseModal2() {
    this.setState({ openModal: false })
  }
  handleopenURLModal() {
    this.setState({ openURLModal: true })
  }
  handleCloseModal() {
    this.setState({ openURLModal: false })
  }
  handleURLmodalOpen = (item: any) => {
    this.setState({ AssessorName: `${item.attributes.full_name}` })
    this.setState({ AssessorDetails: item.attributes })
    this.setState({ URLmodal: true })
  }
  handleURLmodalClose = () => {
    this.setState({ URLmodal: false })
  };

  handleOpenDeleteModal = (id: string | number) => {
    this.setState({ deleteId: id });
    this.setState({ deleteModal: true });

  }

  handleCloseDeleteModal = () => {
    this.setState({ deleteModal: false })
  }


  handleDashboard = () => {
    this.props.navigation.navigate("Dashboard")
  }


  getAssessorData(): boolean {
    const header = {
      token: localStorage.getItem('accesToken')
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAssessorAPICall = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAssessorData
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;

  }

  deleteAssessorData = (id: string | number) => {
    let headers = {
      token: localStorage.getItem('accesToken'),
      "Content-Type": "application/json",
    };

    const addClientApiMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.deleteAssessorAPICall = addClientApiMsg.messageId
    addClientApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getAssessorData}/${id}`
    );
    addClientApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    addClientApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );
    runEngine.sendMessage(addClientApiMsg.id, addClientApiMsg);
    this.setState({ deleteModal: false })
  }

  updateAssessorData = (values: any) => {
    this.setState({
      loader : true
    })
    let headers = {
      token: localStorage.getItem('accesToken'),
      "Content-Type": "application/json",
    };
    let httpBody = {}
    httpBody = {
      "data": {
        "attributes": values,
      }
    }
    const addClientApiMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.updateAssessorAPICall = addClientApiMsg.messageId
    addClientApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getAssessorData}/${this.state.AssessorDetails.id}`
    );
    addClientApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    addClientApiMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    addClientApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
    runEngine.sendMessage(addClientApiMsg.id, addClientApiMsg);
    this.setState({ URLmodal: false })
  }



}
 // Customizable Area End
