Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "ProjectReporting";
exports.labelBodyText = "ProjectReporting Body";

exports.btnExampleTitle = "CLICK ME";

exports.getProjectReportingCall = "bx_block_projectreporting/projects";
exports.createProjectCall="bx_block_profile/projects";
exports.getCoManagersDataAPICall="account_block/co_managers";
exports.getManagersDataAPICall="account_block/managers";
exports.getClientDataAPICall="account_block/clients"
// Customizable Area End