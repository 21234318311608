import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { runEngine } from "../../../../framework/src/RunEngine";
import MessageEnum, {
    getName,
} from "../../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { toast } from "react-toastify";
// Customizable Area End

export const configJSON = require("../config");

export interface Props {
    id: string;
    navigation: any;
    t: any;
}

export interface S {
    // Customizable Area Start
    loading: boolean;
    page: number;
    rowsPerPage: number;
    dataLength: number;
    count: number | null;
    slotId: any;
    item: any;
    startTime: string;
    endTime: string;
    search: string;
    openDialogName: string | null;
    data: any[];
    tableData: any[];
    filterData: any[];
    assessorList: any[];
    assessorIds: any[];
    previousAssessorIds: any[];
    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class CreateGlobalSlotController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    getGlobalSlotListApiCallId: string = "";
    getAssessorListApiCallId: string = "";
    addGlobalSlotApiCallId: string = "";
    editGlobalSlotApiCallId: string = "";
    deleteGlobalSlotApiCallId: string = "";
    t: any;
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage),
        ];
        this.receive = this.receive.bind(this);
        runEngine.attachBuildingBlock(this, this.subScribedMessages);

        this.state = {
            // Customizable Area Start
            loading: false,
            page: 0,
            rowsPerPage: 20,
            dataLength: 0,
            count: null,
            slotId: null,
            item: null,
            startTime: "",
            endTime: "",
            search: "",
            openDialogName: null,
            data: [],
            tableData: [],
            filterData: [],
            assessorList: [],
            assessorIds: [],
            previousAssessorIds: []
            // Customizable Area End
        };
        this.t = this.props.t;
    }

    async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (responseJson && apiRequestCallId) {
                if (responseJson.status === 500) {
                    toast.error(this.t("createGlobalSlot.toast.somethingWrong"));
                    this.setState({ loading: false });
                } else if (!Array.isArray(responseJson.errors) && responseJson.errors) {
                    toast.error(responseJson.errors);
                    this.setState({ loading: false });
                } else {
                    this.handleApiResponse(apiRequestCallId, responseJson);
                }
            }
        }
    }

    async componentDidMount() {
        window.scrollTo(0, 0);
        this.getGlobalSlotList();
        this.getAssessorList();
    }

    handleApiResponse = (apiRequestCallId: string, responseJson: any) => {
        if (Array.isArray(responseJson.errors)) {
            if (responseJson.errors[0].message) {
                toast.error(responseJson.errors[0].message);
            } else if (responseJson.errors[0].token) {
                toast.error(responseJson.errors[0].token);
                this.setState({ loading: false });
            } else {
                toast.error(this.t("createGlobalSlot.toast.somethingWrong"));
            }
            this.setState({ loading: false });
        } else if (apiRequestCallId === this.getGlobalSlotListApiCallId) {
            this.handelGlobalSlotListApiResponse(responseJson);
        } else if (apiRequestCallId === this.addGlobalSlotApiCallId) {
            this.handelAddSlotApiResponse(responseJson);
        } else if (apiRequestCallId === this.editGlobalSlotApiCallId) {
            this.handelEditSlotApiResponse(responseJson);
        } else if (apiRequestCallId === this.deleteGlobalSlotApiCallId) {
            this.handelDeleteSlotApiResponse();
        } else if (apiRequestCallId === this.getAssessorListApiCallId) {
            this.handelAssessorListApiResponse(responseJson);
        }
    }

    handelGlobalSlotListApiResponse = (responseJson: any) => {
        const data = responseJson.data.map((item: any) => {
            return {
                id: item.id,
                startTime: item.attributes.start_time,
                endTime: item.attributes.end_time,
                assessorIds: item.attributes.assessor_ids
            }
        })
        this.setState({ tableData: data, filterData: data, loading: false },
            () => { this.onChangeHandler(this.state.search, 0) }
        );
    }

    handelAssessorListApiResponse = (responseJson: any) => {
        const assessorList = responseJson.data.map((item: any) => {
            return {
                label: item.attributes.full_name,
                value: item.attributes.id
            }
        });
        this.setState({ assessorList, loading: false });
    }

    handelAddSlotApiResponse = (responseJson: any) => {
        toast.success(this.t("createGlobalSlot.toast.add"));
        let tableData = [...this.state.tableData];
        const obj = {
            id: responseJson.data && responseJson.data[0].id,
            startTime: responseJson.data && responseJson.data[0].attributes.start_time,
            endTime: responseJson.data && responseJson.data[0].attributes.end_time,
            assessorIds: responseJson.data && responseJson.data[0].attributes.assessor_ids
        };
        tableData = [obj, ...tableData];
        this.setState({ tableData, filterData: tableData, slotId: null, startTime: "", endTime: "", assessorIds: [], openDialogName: null, loading: false },
            () => { this.onChangeHandler(this.state.search, 0) }
        );
    }

    handelEditSlotApiResponse = (responseJson: any) => {
        toast.success(this.t("createGlobalSlot.toast.edit"));
        let tableData = [...this.state.tableData];
        tableData = tableData.map((item: any) => {
            if (item.id === this.state.slotId) {
                return {
                    id: responseJson.data.id,
                    startTime: responseJson.data.attributes.start_time,
                    endTime: responseJson.data.attributes.end_time,
                    assessorIds: responseJson.data.attributes.assessor_ids
                };
            } else {
                return item;
            }
        });

        this.setState({ tableData, filterData: tableData, slotId: null, startTime: "", endTime: "", assessorIds: [], openDialogName: null, loading: false },
            () => { this.onChangeHandler(this.state.search, this.state.page) }
        );
    }

    handelDeleteSlotApiResponse = () => {
        toast.success(this.t("createGlobalSlot.toast.delete"));
        let tableData = [...this.state.tableData];
        tableData = tableData.filter((item: any) => item.id !== this.state.slotId);
        this.setState({ tableData, filterData: tableData, startTime: "", endTime: "", openDialogName: null, loading: false },
            () => { this.onChangeHandler(this.state.search, this.state.page) }
        );
    }

    onChangeHandler = (search: string, page: number) => {
        const { filterData, rowsPerPage } = this.state;
        let data = filterData.filter((item: any) => {
            if (search === "") {
                return true;
            } else if ((item.startTime + " to " + item.endTime).toString().toLowerCase().includes(search.toLowerCase())) {
                return item;
            }
        });

        const dataLength = data.length;
        let totalPage = Math.ceil(dataLength / this.state.rowsPerPage);
        page = totalPage === page ? page - 1 : page;
        data = data.slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage
        );
        this.setState({ data, dataLength, page, search });
    }

    getGlobalSlotList = () => {
        this.setState({ loading: true });

        const header = {
            "Content-Type": configJSON.globalSlotListContentType,
            token: localStorage.getItem("accesToken")
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getGlobalSlotListApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.globalSlotListApiEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.globalSlotListApiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }

    getAssessorList = () => {
        this.setState({ loading: true });

        const header = {
            "Content-Type": configJSON.assessorListContentType,
            token: localStorage.getItem("accesToken")
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getAssessorListApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.assessorListApiEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.assessorListApiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }

    addGlobalSlot = () => {
        this.setState({ loading: true });

        const header = {
            "Content-Type": configJSON.addGlobalSlotApiContentType,
            token: localStorage.getItem("accesToken")
        };

        const httpBody = {
            "slots": [{
                "start_time": this.state.startTime,
                "end_time": this.state.endTime,
            }],
            "assessor_ids": this.state.assessorIds
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.addGlobalSlotApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.addGlobalSlotApiEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.addGlobalSlotApiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }

    editGlobalSlot = () => {
        this.setState({ loading: true });

        const header = {
            "Content-Type": configJSON.editGlobalSlotApiContentType,
            token: localStorage.getItem("accesToken")
        };

        const httpBody = {
            "slot": {
                "start_time": this.state.startTime,
                "end_time": this.state.endTime,
            },
            "assessor_ids": this.state.previousAssessorIds,
            "new_ids": this.state.assessorIds.filter((id: any) => !this.state.previousAssessorIds.includes(id)),
            "remove_ids": this.state.previousAssessorIds.filter((id: any) => !this.state.assessorIds.includes(id)),
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.editGlobalSlotApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.editGlobalSlotApiEndPoint}/${this.state.slotId}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.editGlobalSlotApiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }

    deleteGlobalSlot = () => {
        this.setState({ loading: true });
        const header = {
            "Content-Type": configJSON.deleteGlobalSlotApiContentType,
            token: localStorage.getItem("accesToken")
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.deleteGlobalSlotApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.deleteGlobalSlotApiEndPoint}/${this.state.slotId}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.deleteGlobalSlotApiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }
}
// Customizable Area End