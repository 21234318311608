// Customizable Area Start
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { runEngine } from "../../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { toast } from "react-toastify";
// Customizable Area End

export const configJSON = require("../config");

export interface Props {
    navigation?: any;
    id?: string;
    location: any;
    history: any;
    match: any;
    t: any;
}

interface S {
    // Customizable Area Start
    loading: boolean;
    hasMore: boolean;
    dataLength: number;
    participantId: any;
    participantName: string;
    participantProject: string;
    questionsData: any[];
    forfilter: any[];
    toolNames: string;
    Toolsafterbackup: string;
    toolsdropDown: any[],
    locationtoolid: any,
    // Customizable Area End
}

interface SS {
    id: any;
}

export default class ParticipantReportController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    getParticipantReportCallId: string = "";
    t: any;
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage),
        ];
        this.receive = this.receive.bind(this);
        runEngine.attachBuildingBlock(this, this.subScribedMessages);

        this.state = {
            // Customizable Area Start
            loading: false,
            hasMore: true,
            dataLength: 10,
            participantId: "",
            participantName: "",
            participantProject: "",
            questionsData: [],
            forfilter: [],
            toolNames: "",
            Toolsafterbackup: "",
            toolsdropDown: [],
            locationtoolid: ""
            // Customizable Area End
        };
        this.t = this.props.t;
    }

    // Customizable Area Start
    async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (apiRequestCallId && responseJson) {
                this.handleApiResponse(apiRequestCallId, responseJson);
            }
        }
    }

    handleApiResponse = (apiRequestCallId: string, responseJson: any) => {
        if (responseJson.status === 500) {
            toast.error(this.t("participantReport.toast.somethingWrong"));
            this.setState({ loading: false });
        } else if (Array.isArray(responseJson.errors)) {
            if (responseJson.errors[0].message) {
                toast.error(responseJson.errors[0].message);
            } else if (responseJson.errors[0].token) {
                toast.error(responseJson.errors[0].token);
            } else {
                toast.error(this.t("participantReport.toast.somethingWrong"));
            }
            this.setState({ loading: false });
        } else if (responseJson.errors) {
            toast.error(responseJson.errors);
            this.setState({ loading: false });
        } else if (apiRequestCallId === this.getParticipantReportCallId) {
            this.handelReportApiResponse(responseJson);
        }
    }

    async componentDidMount() {
        window.scrollTo(0, 0);
        let state = this.props.location.state;
        if (state) {
            this.setState({
                participantId: state.participantId,
                participantName: state.participantName,
                locationtoolid: state.Locationtoolid
            }, () => { this.getParticipantReport() });
        }
    }

    handelReportApiResponse = (responseJson: any) => {
        let TestGivenId = this.props.location.state.Locationtoolid;
        const data = responseJson?.data?.map((item: any) => {
            const options = item.attributes.question_ids.data.attributes.answers.data.map((option: any) => {
                return {
                    id: option.id,
                    answer: option.attributes?.answer,
                    image_url: option.attributes?.image_url?.url,
                    type: option.attributes?.image_url?.type,
                    correct_answer: option.attributes?.correct_answer,
                    participant_answer: item.attributes.question_ids.data.attributes?.answer_ids?.some((ans: any) => ans.toString() === option.id.toString())
                }
            });
            return {
                question: item.attributes.question_ids.data.attributes?.name,
                questionImageURL: item.attributes.question_ids.data.attributes?.image_url?.url,
                type: item.attributes.question_ids.data.attributes?.image_url?.type,
                options: options,
                toolid: item.attributes.question_ids.data.attributes.online_tool_id
            }
        }).filter((datafilter: any) => {
            return TestGivenId?.includes(Number(datafilter.toolid))
        });
        this.DropDownDataSet(responseJson)
        this.setState({
            forfilter: data ?? []
        },()=>{
            this.setState({
                questionsData : this.state.forfilter.filter((data:any)=>{
                    if(data?.toolid === this.state?.toolsdropDown[0]?.value){return true}
                }),
                loading : false
            })
        });
    }

    DropDownDataSet = (responseJson: any) => {
        let TestGivenId = this.props.location.state.Locationtoolid;
        let dropdowndata = responseJson?.data?.map((item: any) => {
            return {
                label: item.attributes.question_ids.data.attributes.online_tool.name,
                value: item.attributes.question_ids.data.attributes.online_tool.id
            }
        }).filter((filterdata: any, index: any, arr: any) => {
            return arr.findIndex((item: any) => item.value === filterdata.value) === index
        }).filter((datafilter: any) => { return TestGivenId?.includes(Number(datafilter.value)) })
        this.setState({
            toolsdropDown: dropdowndata
        })
        if (responseJson.data !== null) {
            this.setState({
                participantProject: responseJson?.data[0]?.attributes?.project_name,
                toolNames: dropdowndata[0].label,
                Toolsafterbackup: dropdowndata[0].label,
            }, () => {
                this.setState({
                    questionsData: this.state.questionsData.filter((data: any) => {
                        if (data.toolid === dropdowndata[0].value) {
                            return true;
                        }
                    })
                })
            })
        }
    }

    addMoreData = () => {
        if (this.state.questionsData.length > this.state.dataLength) {
            this.setState({
                hasMore: true,
                dataLength: this.state.dataLength + 10
            });
        } else {
            this.setState({ hasMore: false });
        }
    }

    getParticipantReport = () => {
        this.setState({ loading: true });

        const header = {
            "Content-Type": configJSON.participantReportContentType,
            token: localStorage.getItem("accesToken")
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getParticipantReportCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.participantReportApiEndPoint}?participant_id=${this.state.participantId}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.participantReportApiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }
    // Customizable Area End
}
// Customizable Area End