// Customizable Area Start
import React from "react";
import { withTranslation } from "react-i18next";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TextField from "@material-ui/core/TextField";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import { Grid, Dialog, DialogContent } from "@material-ui/core";
import { Formik } from "formik";
import AppSidebar from "../../../components/src/AppSidebar.web";
import { styled } from "@material-ui/styles";
import Modal from "@material-ui/core/Modal";
import Box from "@material-ui/core/Box";
const LanguageData = localStorage.getItem("language") === "ar";
import Typography from "@material-ui/core/Typography";
import "./manageAssessor.web.css";
import { editPen, deleteIcon } from "./assets";
import ManageAssessorController, { Props } from "./ManageAssessorController.web";
import { updateAssessorSchema } from "./validationSchema";
import Search from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import Pagination from "@material-ui/lab/Pagination";
import Spinner from "../../../components/src/Spinner.web";
import AddNewAssessor from "./AddAssessor.web";

class ManageAssessor extends ManageAssessorController {
  constructor(props: Props) {
    super(props);
  }

  renderDeleteModal = () => {
    return (
      <Dialog
        onClose={this.handleCloseDeleteModal}
        maxWidth={"sm"}
        aria-describedby="scroll-dialog-description"
        scroll="body"
        open={this.state.deleteModal}
        aria-labelledby="scroll-dialog-title"
        fullWidth={false}
      >
        <DialogContent style={{ padding: "0px", maxWidth: "500px" }}>
          <Box
            style={{
              paddingTop: "0px !important",
              textAlign: "right",
              margin: "10px 10px 0px 0px",
            }}
          >
            <CloseIcon
              onClick={this.handleCloseDeleteModal}
              style={{
                color: "#787878",
                fontSize: "35px",
                cursor: "pointer",
              }}
            />
          </Box>
          <Box
            padding={{ xs: "15px 25px 25px", sm: "15px 70px 40px" }}
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <Box
              id="modal-title"
              fontSize={{ xs: "20px", sm: "30px" }}
              mb="20px"
              fontFamily="ProximaNova-Bold"
              fontWeight="bolder"
            >
              {this.t("manageAssessor.title.delete")}
            </Box>
            <Box fontSize="18px" textAlign="center">
              {this.t("manageAssessor.description.delete")}
            </Box>
            <Box display="flex" mt="30px">
              <CustomButton
                onClick={this.handleCloseDeleteModal}
                style={{ marginBottom: "0px", padding: "5px 10px" }}
                id="cancel"
              >
                {this.t("manageAssessor.button.cancel")}
              </CustomButton>
              <RedButton
                style={{ padding: "5px 10px" }}
                onClick={() => this.deleteAssessorData(this.state.deleteId)}
              >
                {this.t("manageAssessor.button.delete")}
              </RedButton>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    );
  };

  errorFullNameMessage = (props: any) => {
    return (
      props.errors.full_name &&
      props.touched.full_name && (
        <p className="validation_errors">{props.errors.full_name}</p>
      )
    );
  };

  renderUrlModal = () => {
    return (
      <Modal
        onClose={this.handleURLmodalClose}
        aria-describedby="modal-modal-description"
        aria-labelledby="modal-modal-title"
        open={this.state.URLmodal}
      >
        <Box className="modalStyle editModal">
          <div className="closeImg" onClick={this.handleURLmodalClose}>
            X
          </div>
          <Typography
            variant="h6"
            component="h2"
            className="helpModalTitle"
            style={{
              fontFamily: "ProximaNova-Bold",
              color: "#1b3c69",
              textAlign: "left",
              fontSize: "40px",
            }}
          >
            {this.t("manageAssessor.title.edit", {
              name: this.state.AssessorName,
            })}
          </Typography>
          <StyleManageAssessor>
            <Formik
              initialValues={{
                full_name: this.state.AssessorDetails.full_name || "",
                user_name: this.state.AssessorDetails.user_name || "",
                email: this.state.AssessorDetails.email || "",
                password: this.state.AssessorDetails.password || "",
              }}
              validationSchema={() => updateAssessorSchema(this.t)}
              onSubmit={(values) => {
                this.updateAssessorData(values);
              }}
              validateOnChange={true}
              validateOnBlur={true}
            >
              {(props) => (
                <form onSubmit={props.handleSubmit}>
                  <div style={{ marginTop: "15px" }}>
                    <Grid className="label-parent" container spacing={3}>
                      <Grid item xs={6}>
                        <label className="labels">
                          {this.t("manageAssessor.label.name")}
                          <span style={{ color: "#f94b4b" }}>*</span>
                        </label>
                        <Box className="textfield-parent">
                          <TextField
                            id="full_name"
                            name="full_name"
                            value={props.values.full_name}
                            onBlur={props.handleBlur}
                            onChange={props.handleChange}
                            fullWidth
                            variant="outlined"
                            className="client-textfields"
                            style={{
                              border:
                                props.errors.full_name &&
                                  props.touched.full_name
                                  ? "2px solid red"
                                  : "",
                            }}
                          />
                          {this.errorFullNameMessage(props)}
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <label className="labels">
                          {this.t("manageAssessor.label.username")}{" "}
                          <span style={{ color: "#f94b4b" }}>*</span>
                        </label>
                        <Box className="textfield-parent">
                          <TextField
                            id="user_name"
                            name="user_name"
                            onChange={props.handleChange}
                            value={props.values.user_name}
                            onBlur={props.handleBlur}
                            variant="outlined"
                            className="client-textfields"
                            fullWidth
                            style={{
                              border:
                                props.errors.user_name &&
                                  props.touched.user_name
                                  ? "2px solid red"
                                  : "",
                            }}
                          />
                          {props.errors.user_name && props.touched.user_name ? (
                            <p className="validation_errors">
                              {props.errors.user_name}
                            </p>
                          ) : null}
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <label className="labels">
                          {this.t("manageAssessor.label.emailId")}
                          <span style={{ color: "#f94b4b" }}>*</span>
                        </label>
                        <Box className="textfield-parent">
                          <TextField
                            value={props.values.email}
                            name="email"
                            id="email"
                            onBlur={props.handleBlur}
                            className="client-textfields"
                            variant="outlined"
                            fullWidth
                            onChange={props.handleChange}
                            placeholder=""
                            style={{
                              border:
                                props.errors.email && props.touched.email
                                  ? "2px solid red"
                                  : "",
                            }}
                          />
                          {props.errors.email && props.touched.email ? (
                            <p className="validation_errors">
                              {props.errors.email}
                            </p>
                          ) : null}
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <label className="labels">
                          {this.t("manageAssessor.label.password")}
                          <span style={{ color: "#f94b4b" }}>*</span>
                        </label>
                        <Box className="textfield-parent">
                          <TextField
                            name="password"
                            id="password"
                            fullWidth
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            className="client-textfields"
                            variant="outlined"
                            placeholder=""
                            value={props.values.password}
                            style={{
                              border:
                                props.errors.password && props.touched.password
                                  ? "2px solid red"
                                  : "",
                            }}
                          />
                          {props.errors.password && props.touched.password ? (
                            <p className="validation_errors">
                              {props.errors.password}
                            </p>
                          ) : null}
                        </Box>
                      </Grid>
                    </Grid>
                  </div>
                  <div
                    style={{
                      color: "#1b3c69",
                      fontSize: "18px",
                      fontFamily: "ProximaNova-regular",
                      textAlign: "left",
                      lineHeight: "1.27",
                      padding: "2px",
                    }}
                  >
                    <Button
                      className="cancelBtn"
                      size="large"
                      style={{
                        lineHeight: 1,
                        minHeight: '54px'
                      }}
                      onClick={this.handleURLmodalClose}
                    >
                      {this.t("manageAssessor.button.cancel")}
                    </Button>
                    <Button className="saveBtn" type="submit" size="large">
                      {this.t("manageAssessor.button.save")}
                    </Button>
                  </div>
                </form>
              )}
            </Formik>
          </StyleManageAssessor>
        </Box>
      </Modal>
    );
  };

  render() {
    return (
      <StyleManageAssessor className="manage-assessor manage-client" data-testid="MainDiv">
        {this.state.loader && <Spinner spinnerModal={this.state.loader} />}
        <AppSidebar type="admin">
          <div className="main_page">
            <div className="client_container">
              <div>
                <h4>{this.t("manageAssessor.title.main")}</h4>
                <p>{this.t("manageAssessor.description.main")}</p>
              </div>
              <div className="">
                <AddNewAssessor t={this.t} navigation={undefined} id={""} />
              </div>
            </div>

            <div style={{ margin: "100px 30px 0px 0px" }} className={LanguageData ? "SearchRight" : ""}>
              <div
                className="TextfieldSearch"
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginBottom: "10px",
                }}
              >
                <TextField
                  id="outlined-basics"
                  value={this.state.search}
                  onChange={(e: any) => {
                    this.onSearch(e.target.value, 0);
                  }}
                  variant="outlined"
                  className="searchInput"
                  InputLabelProps={{
                    style: {
                      fontFamily: "ProximaNova-Regular",
                      zIndex: 0,
                    },
                  }}
                  label={this.t("manageAssessor.label.search")}
                  InputProps={{
                    endAdornment: <Search />,
                  }}
                />
              </div>
              <div className="manage-client-table">
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        data-testid="UserName"
                        align="center"
                        style={{
                          fontFamily: "ProximaNova-Bold",
                          fontSize: "16px",
                          textTransform: "uppercase"
                        }}
                      >
                        {this.t("manageAssessor.label.name")}
                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: "16px",
                          fontFamily: "ProximaNova-Bold",
                        }}
                        align="center"
                      >
                        {this.t("manageAssessor.label.userName")}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          fontFamily: "ProximaNova-Bold",
                          fontSize: "16px",
                        }}
                      >
                        {this.t("manageAssessor.label.email")}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          fontFamily: "ProximaNova-Bold",
                          fontSize: "16px",
                          textTransform: "uppercase"
                        }}
                      >
                        {this.t("manageAssessor.label.password")}
                      </TableCell>
                      <TableCell
                        style={{
                          fontFamily: "ProximaNova-Bold",
                          fontSize: "16px",
                          textTransform: "uppercase"
                        }}
                        align="center"
                      >
                        {this.t("manageAssessor.label.action")}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.filterData.length > 0 ? (
                      <>
                        {this.state.loader ? (
                          <Spinner spinnerModal={true} />
                        ) : (
                          this.state.filterData
                            .slice(
                              this.state.page * this.state.rowsPerPage,
                              this.state.page * this.state.rowsPerPage +
                              this.state.rowsPerPage
                            )
                            .map((item: any, key: any) => (
                              <TableRow key={item.id}>
                                <TableCell className="fontSTyle" align="center">
                                  <span style={{display: 'block',maxWidth: "140px",whiteSpace: 'nowrap',overflow: 'hidden',textOverflow: 'ellipsis'}}>
                                  {item.attributes.full_name}
                                  </span>
                                </TableCell>
                                <TableCell
                                  className="fontSTyle"
                                  align="center"
                                >
                                  {item.attributes.user_name}
                                </TableCell>
                                <TableCell
                                  className="fontSTyle"
                                  align="center"
                                >
                                  {item.attributes.email}
                                </TableCell>
                                <TableCell
                                  className="fontSTyle"
                                  align="center"
                                >
                                  {item.attributes.password}
                                </TableCell>
                                <TableCell
                                  className="fontSTyle"
                                  align="center"
                                >
                                  <div style={{ display: "flex" }}>
                                    <div
                                      style={{
                                        color: "#f94b4b",
                                        fontFamily: "ProximaNova-semibold",
                                        cursor: "Pointer",
                                      }}
                                      onClick={() =>
                                        this.handleURLmodalOpen(item)
                                      }
                                    >
                                      <figure>
                                        {" "}
                                        <img
                                          src={editPen}
                                          alt="Pen"
                                          width="21"
                                          height="21"
                                        />
                                      </figure>
                                    </div>
                                    <div
                                      style={{
                                        color: "#f94b4b",
                                        fontFamily: "ProximaNova-semibold",
                                        cursor: "Pointer",
                                        margin: "0px 15px",
                                      }}
                                      onClick={() => {
                                        this.handleOpenDeleteModal(
                                          item.attributes.id
                                        );
                                      }}
                                    >
                                      <figure>
                                        {" "}
                                        <img
                                          src={deleteIcon}
                                          alt="Pen"
                                          width="21"
                                          height="21"
                                        />
                                      </figure>
                                    </div>
                                  </div>
                                </TableCell>
                              </TableRow>
                            ))
                        )}
                      </>
                    ) : (
                      <TableRow>
                        <TableCell
                          colSpan={6}
                          align="center"
                          className="fontSTyle"
                          style={{
                            whiteSpace: "nowrap",
                          }}
                        >
                          {this.t("manageAssessor.label.notFound")}
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </div>
              <div className="table-Pagination-Data" data-testid="Pagination">
                {this.state.search === "" ? (
                  <Pagination
                    count={Math.ceil(this.state.data.length / this.state.rowsPerPage)}
                    onChange={(event: unknown, newPage: number) =>
                      this.setState({
                        ...this.state,
                        page: newPage - 1,
                      })
                    }
                    dir="ltr"
                    variant="outlined"
                    shape="rounded"
                    showLastButton
                    showFirstButton
                    boundaryCount={1}
                    siblingCount={0}
                  />
                ) : (
                  <Pagination
                    count={Math.ceil(this.state.filterData.length / this.state.rowsPerPage)}
                    onChange={(event: unknown, newPage: number) =>
                      this.setState({
                        ...this.state,
                        page: newPage - 1,
                      })
                    }
                    dir="ltr"
                    shape="rounded"
                    variant="outlined"
                    showLastButton
                    showFirstButton
                    siblingCount={0}
                    boundaryCount={1}
                  />
                )}
              </div>
            </div>
            {this.renderUrlModal()}
            {this.renderDeleteModal()}
          </div>
        </AppSidebar>
      </StyleManageAssessor>
    );
  }
}

const CustomButton = styled(Button)({
  padding: "8px 25px",
  textTransform: "none",
  fontSize: "18px",
  borderRadius: "8px",
  marginRight: "15px",
  border: "1px solid #f26a42",
  backgroundColor: "white",
  color: "#f26a42",
  fontFamily: "ProximaNova-Semibold",
  "&:hover": {
    color: "#f26a42",
    backgroundColor: "white",
  },
  "@media(max-width:600px)": {
    width: "100%",
  },
  "@media(max-width:992px)": {
    padding: "7px 15px",
  },
});

const RedButton = styled(Button)({
  backgroundColor: "#f94b4b",
  textTransform: "none",
  color: "white",
  borderRadius: "10px",
  fontSize: "18px",
  fontFamily: "ProximaNova-Semibold",
  padding: "9px 25px",
  "&:hover": {
    backgroundColor: "#f94b4b",
    color: "white",
  },
  "@media(max-width:600px)": {
    marginTop: "27px",
    width: "100%",
  },
  "@media(max-width:992px)": {
    padding: "8px 15px",
  },
});

const StyleManageAssessor = styled("div")({
  '& .SearchRight': {
    '& .MuiInputBase-root': {
      display: "flex",
      flexDirection: "row-reverse"
    }
  },
  '& .MuiButton-label': {
    lineHeight: localStorage.getItem("language") === "hi" ? "1" : "unset",
    minHeight: '54px',
    fontFamily: "ProximaNova-Semibold !important",
  }
})
export default withTranslation()(ManageAssessor);
// Customizable Area End
