
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
const { baseURL } = require("../../../framework/src/config")
import moment from "moment";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  location: any;
  history: any;
  match: any;
  t: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  data: any[];
  page: 0,
  rowsPerPage: 20,
  events: any[],
  SelectedDate: any,
  SlotModel: any,
  AllSolts: any[],
  BookSlotIds: any[],
  RemoveSlots: any[],
  AvailableSlot: any[],
  AlertText: any,
  AlertModel: any,
  DateDisable: boolean,
  SlotUpdateApiCall: boolean,
  startDate: any;
  endDate: any;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class LandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start

  Tabledata: any = ""
  eventsData: any = ""
  SlotDataAPi: any = ""
  AssignSlots: any = ''
  t: any;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      data: [],
      page: 0,
      rowsPerPage: 20,
      events: [],
      SelectedDate: '',
      SlotModel: false,
      AllSolts: [],
      BookSlotIds: [],
      RemoveSlots: [],
      AvailableSlot: [],
      AlertText: "",
      AlertModel: false,
      DateDisable: false,
      SlotUpdateApiCall: false,
      startDate: "",
      endDate: "",
    };
    this.t = this.props.t;

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  // Customizable Area Start
  async componentDidMount(): Promise<void> {
    this.getTable()
    this.getEvents()
    this.currentDateInRange();
  }


  // Customizable Area End

  // Customizable Area Start
  checkCondition = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.eventsData) {
      this.setState((e) => ({
        ...e, events: responseJson.events
      }))
    }
    else if (apiRequestCallId === this.SlotDataAPi) {
      console.log(responseJson, "responseJson?.data?")
      let filterSlots = responseJson?.data?.filter((item: any) => item.attributes.available.includes('available') || item.attributes.available.includes('booked')).map((items: any) => items.attributes.id)
      this.setState({
        ...this.state, AllSolts: responseJson.data, AvailableSlot: filterSlots
      })
    }
    else if (apiRequestCallId === this.AssignSlots) {
      this.setState({
        ...this.state, BookSlotIds: [], SelectedDate: "", SlotModel: false, RemoveSlots: [], AlertModel: true, AlertText: responseJson.meta.message, SlotUpdateApiCall: false
      })
    }
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.Tabledata) {
        this.setState({
          data: responseJson.data
        })
      }

      this.checkCondition(apiRequestCallId, responseJson)
    }
  }


  // Customizable Area End

  // Customizable Area Start

  getTable = () => {
    this.getTableData()

  }

  currentDateInRange = () => {
    const currentDate = moment().format('DD-MM-YYYY');
    const StartDate = moment(10 - 11 - 2022).format('DD-MM-YYYY');
    const EndDate = moment(26 - 11 - 2022).format('DD-MM-YYYY');
    const temp = moment(currentDate, "DD-MM-YYYY")
    const startDate = moment(StartDate, "DD-MM-YYYY").add(-1, "days");
    const endDate = moment(EndDate, "DD-MM-YYYY").add(1, "days");
    return temp?.isBetween(startDate, endDate);
  }

  getEvents = () => {
    this.getEventsData()
  }

  SlotOpen = (date: any) => {
    this.setState({
      ...this.state, SlotModel: true, SelectedDate: date.dateStr
    })
    this.GetSlotData()
  }

  NavigateToAssessorParticipants = (data: any) => {
    this.props.history.push({
      pathname: "/AssessorParticipants",
      state: {
        data: data,
        filter: true
      }
    })
  }

  getTableData = () => {
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("accesToken")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.Tabledata = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/account_block/assessors/dashboard"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getEventsData = () => {
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("accesToken")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.eventsData = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/bx_block_calendar/booked_slots/calender"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);


  }

  GetSlotData = () => {
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("accesToken")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.SlotDataAPi = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/account_block/assessors/${localStorage.getItem("userId")}?slot_date=${moment(this.state.SelectedDate).format('YYYY-MM-DD')}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);


  }

  async AssignSlotsApiCall() {
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("accesToken")
    };

    const httpBody = {
      id: localStorage.getItem("userId"),
      remove_assessor_slot_id: this.state.RemoveSlots,
      assign_assessor_slot_id: this.state.BookSlotIds,
      slot_date: moment(this.state.SelectedDate).format('YYYY-MM-DD')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.AssignSlots = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      '/account_block/assessors/offline_assessor_date'
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // Customizable Area End
}
