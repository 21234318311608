// Customizable Area Start
import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TextField from "@material-ui/core/TextField";
import TableCell from "@material-ui/core/TableCell";
import Pagination from "@material-ui/lab/Pagination";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import AddNewClient from "./AddNewClient.web";
import AppSidebar from "../../../components/src/AppSidebar.web";
import Modal from "@material-ui/core/Modal";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { Link, withRouter } from "react-router-dom";
import { editPen, deleteIcon } from "./assets";
import { Grid, Dialog, DialogContent } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { styled } from "@material-ui/styles";
import Search from "@material-ui/icons/Search";
import "./ManageClient.web.css";
import LandingPageController, { Props } from "./LandingPageController";
import Spinner from "../../../components/src/Spinner.web";
import { Formik } from "formik";
import { addClientSchema } from "./validationSchema";
import { withTranslation } from "react-i18next";
const LanguageData = localStorage.getItem("language") === "ar";
const uploadIcon = require("../assets/upload.png");

class ManageClient extends LandingPageController {
  constructor(props: Props) {
    super(props);
  }

  handleClick = (item: any) => {
    this.setState({
      ...this.state,
      deleteModelText: item.attributes,
      deleteModel: true,
    })
  }
  paginationHandleChange = (event: unknown, newPage: number) => {
    this.setState({
      ...this.state,
      page: newPage - 1,
    })
  }
  filterPaginationHandleChange = (event: unknown, newPage: number) => {
    this.setState({
      ...this.state,
      page: newPage - 1,
    })
  }
  handleClose = () => {
    this.setState({ deleteModel: false })
  }
  renderPagination = () => {
    return (
      <div className="table-Pagination-Data" data-testid="Pagination">
        {this.state.search === "" ? (
          <Pagination
            data-test-id='page-id'
            onChange={(event: unknown, newPage: number) =>
              this.paginationHandleChange(event, newPage)
            }

            count={Math.ceil(this.state.data?.length / this.state.rowsPerPage)}
            shape="rounded"
            variant="outlined"
            boundaryCount={1}
            showFirstButton
            dir="ltr"
            showLastButton
            siblingCount={0}
          />
        ) : (
          <Pagination
            onChange={(event: unknown, newPage: number) =>
              this.filterPaginationHandleChange(event, newPage)
            }
            count={Math.ceil(this.state.filterData.length / this.state.rowsPerPage)}
            variant="outlined"
            shape="rounded"
            showLastButton
            dir="ltr"
            showFirstButton
            boundaryCount={1}
            siblingCount={0}
          />
        )}
      </div>
    );
  };

  renderFirstGrid = (props: any) => {
    return (
      <Grid item xs={6}>
        <label className="labels">
          {this.t("ManageClient.FirstName")}
          <span style={{ color: "#f94b4b" }}>*</span>
        </label>
        <Box className="textfield-parent">
          <TextField
            name="full_name"
            id="full_name"
            value={props.values.full_name}
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            className="client-textfields"
            variant="outlined"
            fullWidth
            style={{
              border:
                props.errors.full_name &&
                  props.touched.full_name
                  ? "2px solid red"
                  : "",
            }}
          />
          {props.errors.full_name &&
            props.touched.full_name ? (
            <p className="validation_errors">
              {props.errors.full_name}
            </p>
          ) : null}
        </Box>
      </Grid>
    )
  }
  renderSecondGrid = (props: any) => {
    return (
      <Grid item xs={6}>
        <label className="labels">
          {this.t("ManageClient.FormData.EmailId")}
          <span style={{ color: "#f94b4b" }}>*</span>
        </label>
        <Box className="textfield-parent">
          <TextField
            name="email"
            id="email"
            value={props.values.email}
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            className="client-textfields"
            fullWidth
            variant="outlined"
            placeholder=""
            style={{
              border:
                props.errors.email &&
                  props.touched.email
                  ? "2px solid red"
                  : "",
            }}
          />
          {props.errors.email && props.touched.email ? (
            <p className="validation_errors">
              {props.errors.email}
            </p>
          ) : null}
        </Box>
      </Grid>
    )
  }

  renderTableBody = () => {
    return (
      <TableBody data-testid="TableBodyData">
        {this.state.filterData?.length > 0 ? (
          this.state.filterData
            ?.slice(
              this.state.page * this.state.rowsPerPage,
              this.state.page * this.state.rowsPerPage + this.state.rowsPerPage
            )
            ?.map((item: any, index: any) => (
              <TableRow key={item.id}>
                <TableCell
                  style={{
                    fontFamily: "ProximaNova-Regular",
                    fontSize: "18px",
                    color: "#000"
                  }}
                  data-testid={`FullName${index}`}
                  align="center"
                >
                  <span style={{
                    display: 'block',
                    width: '100px',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  }}>{item.attributes.full_name}</span>
                </TableCell>
                <TableCell
                  style={{
                    fontFamily: "ProximaNova-Regular",
                    fontSize: "18px",
                    color: "#000",
                  }}
                  align="center"
                >
                  <span
                    style={{
                      display: "block",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      width: "100px",
                    }}
                  >
                    {item.attributes.dc_manager === null
                      ? "---"
                      : item.attributes.dc_manager}
                  </span>
                </TableCell>
                <TableCell
                  style={{
                    fontFamily: "ProximaNova-Regular",
                    fontSize: "18px",
                    color: "#000",
                  }}
                  align="center"
                >
                  <span
                    data-testid={`data${index}`}
                    style={{
                      display: "block",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      width: "100px",
                    }}
                  >
                    {item.attributes.user_name}
                  </span>
                </TableCell>
                <TableCell
                  style={{
                    fontFamily: "ProximaNova-Regular",
                    fontSize: "18px",
                    color: "#000",
                  }}
                  align="center"
                >
                  <span
                    style={{
                      display: "block",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      width: "100px",
                    }}
                  >
                    {item.attributes.email}
                  </span>
                </TableCell>
                <TableCell
                  style={{
                    fontFamily: "ProximaNova-Regular",
                    fontSize: "18px",
                    color: "#000",
                  }}
                  align="center"
                >
                  <span
                    style={{
                      display: "block",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      width: "100px",
                    }}
                  >
                    {item.attributes.password}
                  </span>
                </TableCell>
                <TableCell
                  style={{
                    fontFamily: "ProximaNova-Regular",
                    fontSize: "18px",
                    color: "#000",
                  }}
                  align="center"
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div
                      style={{
                        color: "#f94b4b",
                        fontFamily: "ProximaNova-semibold",
                        cursor: "Pointer",
                        marginRight: LanguageData ? "" : "10px",
                        marginLeft: LanguageData ?  "10px" : ""
                      }}
                      onClick={() => this.handleURLmodalOpen(item)}
                    >
                      {this.t("ManageClient.GenerateURL")}
                    </div>

                    <div
                      id="handleEditmodalOpen"
                      data-test-id="handleEditmodalOpen"
                      style={{
                        color: "#f94b4b",
                        fontFamily: "ProximaNova-semibold",
                        cursor: "Pointer",
                      }}
                      onClick={() => this.handleEditmodalOpen(item)}
                    >
                      <figure>
                        {" "}
                        <img src={editPen} alt="Pen" width="21" height="21" />
                      </figure>
                    </div>
                    <div
                      style={{ cursor: "Pointer", marginLeft: "12px" }}
                      onClick={() => this.handleClick(item)

                      }
                    >
                      {/* <Delete color="error" onClick={() => this.setState({
                                  ...this.state, deleteModelText: item.attributes, deleteModel: true
                                })} /> */}
                      <figure>
                        {" "}
                        <img
                          src={deleteIcon}
                          alt="Pen"
                          width="21"
                          height="21"
                        />
                      </figure>
                    </div>
                  </div>
                </TableCell>
              </TableRow>
            ))
        ) : (
          <TableRow>
            <TableCell
              colSpan={12}
              align="center"
              className="ActionClass"
              style={{
                fontFamily: "ProximaNova-Regular",
                fontSize: "18px",
                color: "#000",
                whiteSpace: "nowrap",
              }}
            >
              {this.t("ManageClient.noData")}
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    );
  };

  EditModelRender = () => {
    return (
      <Modal
        open={this.state.EditModal}
        onClose={this.handleEditmodalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modalStyle editModal">
          <div className="closeImg" onClick={this.handleEditmodalClose}>
            X
          </div>

          <Typography
            className="helpModalTitle"
            variant="h6"
            component="h2"
            style={{
              color: "#1b3c69",
              fontSize: "40px",
              fontFamily: "ProximaNova-Bold",
              textAlign: "left",
            }}
          >
            {this.t("ManageClient.Editdetailsof")}
            {this.state.clientName}
          </Typography>

          <div>
            <Formik
              initialValues={{
                full_name: this.state.clientDetails.full_name || "",
                user_name: this.state.clientDetails.user_name || "",
                email: this.state.clientDetails.email || "",
                password: this.state.clientDetails.password || "",
                questionImage: this.state.questionImage || "",
              }}
              validationSchema={() => addClientSchema(this.t)}
              onSubmit={(values, { setSubmitting }) => {
                this.handleUpdateClient(values);
              }}
              validateOnChange={true}
              validateOnBlur={true}
            >
              {(props) => (
                <form onSubmit={props.handleSubmit} onDragEnter={this.handleDrag} onDragOver={this.handleDrag}>
                  <div style={{ marginTop: "15px" }}>
                    <Grid
                      className="label-parent"
                      container
                      spacing={3}
                    >
                      {this.renderFirstGrid(props)}
                      {this.renderSecondGrid(props)}
                      <Grid item xs={6}>
                        <label className="labels">
                          {this.t("ManageClient.FormData.Username")}
                          <span style={{ color: "#f94b4b" }}>*</span>
                        </label>
                        <Box className="textfield-parent">
                          <TextField
                            onChange={props.handleChange}
                            value={props.values.user_name}
                            style={{
                              border:
                                props.errors.user_name &&
                                  props.touched.user_name
                                  ? "2px solid red"
                                  : "",
                            }}
                            onBlur={props.handleBlur}
                            className="client-textfields"
                            name="user_name"
                            fullWidth
                            variant="outlined"
                            id="user_name"
                          />
                          {props.errors.user_name &&
                            props.touched.user_name ? (
                            <p className="validation_errors">
                              {props.errors.user_name}
                            </p>
                          ) : null}
                        </Box>
                      </Grid>

                      <Grid item xs={6}>
                        <label className="labels">
                          {this.t("ManageClient.FormData.Password")}
                          <span style={{ color: "#f94b4b" }}>*</span>
                        </label>
                        <Box className="textfield-parent">
                          <TextField
                            name="password"
                            id="password"
                            value={props.values.password}
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            className="client-textfields"
                            fullWidth
                            variant="outlined"
                            placeholder=""
                            style={{
                              border:
                                props.errors.password &&
                                  props.touched.password
                                  ? "2px solid red"
                                  : "",
                            }}
                          />
                          {props.errors.password &&
                            props.touched.password ? (
                            <p className="validation_errors">
                              {props.errors.password}
                            </p>
                          ) : null}
                        </Box>
                      </Grid>

                      <Grid item xs={12}>
                        <Typography
                          variant="body2"
                          style={{ marginBottom: "5px" }}
                        >
                          {this.t("ManageClient.FormData.Uploadlogo")}
                        </Typography>
                        <Box className="dragElement">
                          <input
                            type="file"
                            accept="image/png, image/jpeg, image/jpg"
                            style={{ display: "none" }}
                            id="questionImage"
                            data-test-id='questionImage'
                            onChange={(event: any) => {
                              this.handleChange(event, props)
                            }}
                          />
                          <label htmlFor="questionImage" style={{ width: "100%" }} className={this.state.dragActive ? "drag-active label-file-upload" : "label-file-upload"}>
                            <Box p={{ xs: "25px", sm: "25px 105px" }} textAlign="center" width="100%" border="1px dashed #bebebe" borderRadius="8px" style={{ cursor: "pointer" }}>
                              <img src={uploadIcon} />
                              <Box mt="15px">
                                {this.t("ManageClient.FormData.DropOrBrowseYour")}
                              </Box>
                            </Box>
                          </label>
                          {this.state.dragActive && <div className="drag-file-element" onDragEnter={(event) => this.handleDrag(event)} onDragLeave={(event) => this.handleDrag(event)} onDragOver={(event) => this.handleDrag(event)} onDrop={(event) => this.handleDrop(event, props)}></div>}
                          <Box mt="3px" fontSize="14px">
                            {this.state.isfileEdit &&
                              this.state.questionImage ? (
                              <a
                                target="_blank"
                                href={URL.createObjectURL(
                                  this.state.questionImage
                                )}
                                style={{ cursor: "pointer" }}
                              >
                                {this.t(
                                  "ManageClient.FormData.Viewfile"
                                )}
                              </a>
                            ) : (
                              this.state.questionImage && (
                                <a
                                  target="_blank"
                                  href={this.state.questionImage}
                                  style={{ cursor: "pointer" }}
                                >
                                  {this.t(
                                    "ManageClient.FormData.Viewfile"
                                  )}
                                </a>
                              )
                            )}
                            {props.touched.questionImage &&
                              props.errors.questionImage && (
                                <div className="text-danger">
                                  {props.errors.questionImage}
                                </div>
                              )}
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </div>

                  <div
                    style={{
                      lineHeight: "1.27",
                      fontSize: "18px",
                      fontFamily: "ProximaNova-regular",
                      textAlign: "left",
                      padding: "2px",
                      color: "#1b3c69",
                    }}
                  >
                    <Button
                      size="large"
                      style={{
                        lineHeight: "1",
                      }}
                      onClick={this.handleEditmodalClose}
                      className="cancelBtn"
                    >
                      {this.t("ManageClient.FormData.Cancel")}
                    </Button>
                    <Button
                      className="saveBtn"
                      type="submit"
                      size="large"
                    >
                      {this.t("ManageClient.FormData.Save")}
                    </Button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </Box>
      </Modal>
    )
  }
  render() {
    return (
      <StyleMangeClient className="manage-client" data-testid="Heading">
        {this.state.loader && <Spinner spinnerModal={this.state.loader} />}
        <AppSidebar type="admin">
          <div className="main_page">
            <div className="client_container">
              <div>
                <h4>{this.t("ManageClient.title")}</h4>
                <p>{this.t("ManageClient.SubTitile")}</p>
              </div>
              <div className="" style={{ margin: "0px 30px 0px 0px" }}>
                <AddNewClient navigation={undefined} id={""} />
              </div>
            </div>
            <div style={{ margin: "100px 30px 0px 0px" }} className={LanguageData ? "SearchIcon" : ""}>
              <div
                className="TextfieldSearch"
                style={{
                  marginBottom: "10px",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <TextField
                  id="outlined-basicnew"
                  onChange={(e: any) => {
                    this.onSearch(e.target.value);
                  }}
                  InputLabelProps={{
                    style: {
                      fontFamily: "ProximaNova-Regular",
                      zIndex: 0,
                    },
                  }}
                  inputProps={{
                    "data-testid": "searchId",
                  }}
                  label="Search"
                  variant="outlined"
                  InputProps={{
                    endAdornment: <Search />,
                  }}
                />
              </div>
              <div className="manage-client-table">
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        align="center"
                        style={{
                          fontSize: "16px",
                          fontFamily: "ProximaNova-Bold",
                          textTransform: "uppercase",
                        }}
                      >
                        {this.t("ManageClient.ORGANISATIONNAME")}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          fontSize: "16px",
                          textTransform: "uppercase",
                          fontFamily: "ProximaNova-Bold",
                        }}
                      >
                        {this.t("ManageClient.DCManagerEmailId")}
                      </TableCell>
                      <TableCell
                        align="center"
                        data-testid="UserName"
                        style={{
                          fontFamily: "ProximaNova-Bold",
                          textTransform: "uppercase",
                          fontSize: "16px",
                        }}
                      >
                        {this.t("ManageClient.Username")}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          fontFamily: "ProximaNova-Bold",
                          textTransform: "uppercase",
                          fontSize: "16px",
                        }}
                      >
                        {this.t("ManageClient.EmailId")}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          textTransform: "uppercase",
                          fontFamily: "ProximaNova-Bold",
                          fontSize: "16px",
                        }}
                      >
                        {this.t("ManageClient.Password")}
                      </TableCell>
                      <TableCell
                        style={{
                          fontFamily: "ProximaNova-Bold",
                          fontSize: "16px",
                          textTransform: "uppercase",
                        }}
                        className="ActionClass"
                        align="center"
                      >
                        {this.t("ManageClient.action")}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  {this.renderTableBody()}
                </Table>
              </div>
              {this.renderPagination()}
            </div>

            {this.state.EditModal && (
              this.EditModelRender()
            )}

            {this.state.URLmodal && (
              <Modal
                open={this.state.URLmodal}
                onClose={this.handleURLmodalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box className="modalStyle">
                  <div className="closeImg" onClick={this.handleURLmodalClose}>
                    X
                  </div>

                  <Typography
                    className="helpModalTitle"
                    variant="h6"
                    component="h2"
                    style={{
                      color: "#1b3c69",
                      fontSize: "40px",
                      fontFamily: "ProximaNova-Bold",
                      textAlign: "left",
                    }}
                  >
                    {this.t("ManageClient.FormData.GenerateURL")}
                  </Typography>
                  <div
                    style={{
                      color: "#333",
                      fontSize: "22px",
                      fontFamily: "ProximaNova-regular",
                      textAlign: "left",
                      lineHeight: "1.27",
                      marginTop: "30px",
                    }}
                  >
                    {this.t("ManageClient.FormData.LinkMessage")}
                  </div>
                  <div
                    style={{
                      color: "#1b3c69",
                      fontSize: "18px",
                      fontFamily: "ProximaNova-regular",
                      textAlign: "left",
                      lineHeight: "1.27",
                      background: "#eff6ff",
                      padding: "15px",
                      marginTop: "20px",
                    }}
                  >
                    {this.t("ManageClient.FormData.URL")}
                    <Link
                      to={this.state.rederectUser}
                      target="_blank"
                      style={{ color: "#1b3c69", textDecoration: "underline" }}
                    >
                      {this.state.dynamicUrl}
                    </Link>
                  </div>
                </Box>
              </Modal>
            )}

            {this.state.deleteModel && (
              <Dialog
                data-test-id='deleteModel'
                className="deleteModel"
                fullWidth={false}
                maxWidth={"sm"}
                scroll="body"
                open={this.state.deleteModel}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                onClose={() => this.setState({ deleteModel: false })}
              >
                <DialogContent style={{ padding: "0px", maxWidth: "500px" }}>
                  <Box
                    style={{
                      paddingTop: "0px !important",
                      textAlign: "right",
                      margin: "10px 10px 0px 0px",
                    }}
                  >
                    <CloseIcon
                      onClick={() => this.handleClose()}
                      style={{
                        fontSize: "35px",
                        color: "#787878",
                        cursor: "pointer",
                      }}
                    />
                  </Box>
                  <Box
                    display="flex"
                    padding={{ xs: "15px 25px 25px", sm: "15px 70px 40px" }}
                    flexDirection="column"
                    alignItems="center"
                  >
                    <Box
                      fontSize={{ xs: "20px", sm: "30px" }}
                      fontFamily="ProximaNova-Bold"
                      id="modal-title"
                      mb="20px"
                      fontWeight="bolder"
                    >
                      {this.t("ManageClient.FormData.Delete")}
                    </Box>
                    <Box fontSize="18px" textAlign="center">
                      {this.t("ManageClient.FormData.AreYouSure")}
                    </Box>
                    <Box display="flex" mt="30px">
                      <CustomButton
                        onClick={() => this.setState({ deleteModel: false })}
                        id="cancel"
                        style={{ marginBottom: "0px", padding: "5px 10px" }}
                      >
                        {this.t("ManageClient.FormData.Cancel")}
                      </CustomButton>
                      <RedButton
                        style={{ padding: "5px 10px", marginRight: LanguageData ? "10px" : "unset" }}
                        onClick={this.DeleteUser}
                      >
                        {this.t("ManageClient.FormData.Delete")}
                      </RedButton>
                    </Box>
                  </Box>
                </DialogContent>
              </Dialog>
            )}
          </div>
        </AppSidebar>
      </StyleMangeClient>
    );
  }
}
const CustomButton = styled(Button)({
  color: "#f26a42",
  backgroundColor: "white",
  border: "1px solid #f26a42",
  padding: "8px 25px",
  textTransform: "none",
  marginRight: "15px",
  fontFamily: "ProximaNova-Semibold",
  fontSize: "18px",
  borderRadius: "8px",
  "&:hover": {
    backgroundColor: "white",
    color: "#f26a42",
  },
  "@media(max-width:600px)": {
    width: "100%",
  },
  "@media(max-width:992px)": {
    padding: "7px 15px",
  },
});

const StyleMangeClient = styled('div')({
  '& .SearchIcon': {
    '& .MuiInputBase-root': {
      display: "flex",
      flexDirection: "row-reverse"
    }
  }
})

const RedButton = styled(Button)({
  color: "white",
  fontFamily: "ProximaNova-Semibold",
  padding: "9px 25px",
  backgroundColor: "#f94b4b",
  fontSize: "18px",
  borderRadius: "10px",
  textTransform: "none",
  "&:hover": {
    backgroundColor: "#f94b4b",
    color: "white",
  },
  "@media(max-width:600px)": {
    marginTop: "27px",
    width: "100%",
  },
  "@media(max-width:992px)": {
    padding: "8px 15px",
  },
});
export default withRouter(withTranslation()(ManageClient));

// Customizable Area End




