Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.dashboardGetUrl = "/dashboard/dashboards";
exports.dashboarContentType = "application/json";
exports.dashboarApiMethodType = "GET";
exports.dashboardHost = "<calculated when request is sent>";
exports.dashboarUserAgent = "PostmanRuntime/7.26.5";
exports.dashboarAccept = "*/*";
exports.dashboarAcceptEncoding = "gzip, deflate, br";
exports.dashboarConnection = "keep-alive";
exports.dashboartoken = "";
exports.labelTitleText = "dashboard";
exports.labelBodyText = "dashboard Body";

exports.dashboardApiMethod = "GET";
exports.dashboardApiContentType = "application/json";
exports.dashboardApiEndPoint = "/bx_block_profile/projects/get_client_project_tools";
exports.validationApiMethodType = "GET";
exports.getProjectDocument = "/account_block/assessors/project_documents"
exports.getListAssesorParticipants = "/account_block/assessors/list_participants"
exports.getAnalyticsData = "account_block/clients/client_dashboard_data?sort_by="

exports.participantReportApiMethod = "GET";
exports.participantReportApiContentType = "application/json";
exports.participantReportApiEndPoint = "/account_block/participant_report";
// Customizable Area End