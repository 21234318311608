// Customizable Area Start
import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { toast } from "react-toastify";

export const configJSON = require("./config");

export interface Props {
    navigation?: any;
    id?: string;
    t: any;
}

interface S {
    data: any;
    allClients: any;
    listTools: any
    ParticipantName: any;
    ParticipantDetails: any;
    openModal: boolean;
    openURLModal: boolean;
    URLmodal: boolean;
    dynamicUrl: any;
    rederectUser: any;
    activeBtn: boolean,
    page: any,
    rowsPerPage: any,
    search: any;
    loader: boolean;
}

interface SS {
    id: any;
}

export default class ManageAssessorController extends BlockComponent<
    Props,
    S,
    SS
> {
    addAssessorCallId: string = '';
    t: any = "";

    constructor(props: Props) {

        super(props);
        this.receive = this.receive.bind(this)
        this.handleopenURLModal = this.handleopenURLModal.bind(this)
        this.handleOpenModal = this.handleOpenModal.bind(this)
        this.handleCloseModal2 = this.handleCloseModal2.bind(this)
        this.handleCloseModal = this.handleCloseModal.bind(this)

        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.PostDetailDataMessage)
        ]

        this.state = {
            data: [],
            listTools: [],
            allClients: [],
            ParticipantName: "",
            ParticipantDetails: "",
            openURLModal: false,
            openModal: false,
            URLmodal: false,
            dynamicUrl: "",
            activeBtn: false,
            rederectUser: "",
            rowsPerPage: 20,
            page: 0,
            search: "",
            loader: false,
        };
        this.t = this.props.t;
        // @ts-ignore
        this.callRef = React.createRef();
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }




    async receive(from: string, message: Message) {


        if (this.addAssessorCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
            const apiResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (apiResponse?.errors) {
                apiResponse?.errors?.map((option: any) => {
                    for (const i in option) {
                        if (option[i] == "has already been taken") {
                            this.handleDangerToastMessage();
                            this.setState({ openModal: true })

                        }
                    }
                })
            } else {
                this.sentMessage("SAVE_PARTICIPANT");
                this.handleToastMessage();
                this.setState({ openModal: false, loader: false });
            }
        }
    }

    generateUniquePassword = (passwordLength: any) => {
        let numberChars = "0123456789";
        let upperChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
        let specialChars = "!@#$%^&*)(:',.?/><";
        let lowerChars = "abcdefghiklmnopqrstuvwxyz";
        let allChars = numberChars + upperChars + lowerChars + specialChars;
        let randPasswordArray = Array(passwordLength);
        randPasswordArray[1] = upperChars;
        randPasswordArray[0] = numberChars;
        randPasswordArray[3] = specialChars;
        randPasswordArray[2] = lowerChars;
        randPasswordArray = randPasswordArray.fill(allChars, 4);
        return this.shuffleArray(
            randPasswordArray.map(function (x) {
                const tempNum = new Uint8Array(1)
                const randomValue = crypto.getRandomValues(tempNum)[0]
                const randomFloat = randomValue / Math.pow(2, 8)
                return x[Math.floor(randomFloat * x.length)];
            })
        ).join("");
    };

    shuffleArray = (array: any) => {
        for (let i = array.length - 1; i > 0; i--) {
            const arr = new Uint8Array(1)
            const randomValue = crypto.getRandomValues(arr)[0]
            const randomFloat = randomValue / Math.pow(2, 8)
            let j = Math.floor(randomFloat * (i + 1));
            let temp = array[i];
            array[i] = array[j];
            array[j] = temp;
        }
        return array;
    };

    handleToastMessage() {
        toast.success(this.t("manageAssessor.toast.credential"), {
            position: toast.POSITION.TOP_RIGHT
        });
    }

    handleDangerToastMessage() {
        this.sentMessage("EMAILALREDYEXISIT")
        toast.error(this.t("manageAssessor.toast.exist"), {
            position: toast.POSITION.TOP_RIGHT
        });
    }

    sentMessage(data: any) {
        const msg: Message = new Message(getName(MessageEnum.PostDetailDataMessage))
        msg.properties['text'] = data
        this.send(msg)
    }
    handleOpenModal() {

        this.setState({ openModal: true })

    }
    handleCloseModal2() {
        this.setState({ openModal: false })
    }
    handleopenURLModal() {
        this.setState({ openURLModal: true })
    }
    handleCloseModal() {
        this.setState({ openURLModal: false })
    }
    handleURLmodalOpen = (item: any) => {
        this.setState({ ParticipantName: `${item.attributes.first_name} ${item.attributes.last_name}` })
        this.setState({ ParticipantDetails: item.attributes })
        this.setState({ URLmodal: true })
    }
    handleURLmodalClose = () => {
        this.setState({ URLmodal: false })
    };


    handleDashboard = () => {
        this.props.navigation.navigate("Dashboard")
    }

    setNewPassword = (setFieldValue: any) => {
        const newPassword = this.generateUniquePassword(8);
        setFieldValue("password", newPassword);
    };

    setUsername = (setFieldValue: any, fname?: any, lname?: any) => {
        const username = fname.substring(0, 3) + lname.substring(0, 3) + new Date().getDate().toString() + (new Date().getMonth() + 1).toString() + new Date().getHours().toString() + new Date().getMinutes().toString() + new Date().getMilliseconds().toString();
        setFieldValue("user_name", username);
    };

    addAssessorData = (values: any) => {
        this.setState({
            loader: true
        })
        let headers = {
            token: localStorage.getItem('accesToken'),
            "Content-Type": "application/json",
        };
        let httpBody = {}
        httpBody = {
            "data": {
                "attributes": {
                    "full_name": values.first_name + " " + values.last_name,
                    "user_name": values.user_name,
                    "email": values.email,
                    "password": values.password,
                },
                "type": "assessor"
            }
        }
        const addClientApiMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.addAssessorCallId = addClientApiMsg.messageId
        addClientApiMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getAssessorData
        );
        addClientApiMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        addClientApiMsg.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );
        addClientApiMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.exampleAPiMethod
        );
        runEngine.sendMessage(addClientApiMsg.id, addClientApiMsg);

    }




}
 // Customizable Area End
