// App.js - WEB
import React, { Component } from 'react';
import { View } from 'react-native';
import firebase from 'firebase';
import Portal from '@material-ui/core/Portal';
import { connect } from 'react-firebase';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import WebRoutesGenerator from '../../components/src/NativeWebRouteWrapper';
import { ModalContainer } from 'react-router-modal';
import HomeScreen from '../../components/src/HomeScreen';
import TopNav from '../../components/src/TopNav';
import InfoPage from '../../blocks/info-page/src/InfoPageBlock';
import AlertBlock from '../../blocks/alert/src/AlertBlock.web';
import Library2 from '../../blocks/Library2/src/Library2';
import ProjectReporting from '../../blocks/ProjectReporting/src/ProjectReporting';
import ProjectReportingWeb from '../../blocks/ProjectReporting/src/ProjectReporting.web';
import TimeTracker from '../../blocks/TimeTracker/src/TimeTracker';
import CountryCodeSelector from '../../blocks/country-code-selector/src/CountryCodeSelector';
import Scheduling from '../../blocks/scheduling/src/Scheduling';
import GroupVideoCall2 from '../../blocks/GroupVideoCall2/src/GroupVideoCall2';
import Scoring from '../../blocks/Scoring/src/Scoring';
import Analytics from '../../blocks/analytics/src/Analytics';
import AssessmentTest from '../../blocks/AssessmentTest/src/AssessmentTest';
import CfLanguageUploadSupport from '../../blocks/CfLanguageUploadSupport/src/CfLanguageUploadSupport';
import EmailNotifications from '../../blocks/EmailNotifications/src/EmailNotifications';
import TaskList from '../../blocks/TaskList/src/TaskList';
import LanguageSupport from '../../blocks/LanguageSupport/src/LanguageSupport';
import LandingPage from '../../blocks/landingpage/src/LandingPage';
import Customform from '../../blocks/customform/src/Customform';
import OTPInputAuth from '../../blocks/otp-input-confirmation/src/OTPInputAuth';
import Surveys from '../../blocks/Surveys/src/Surveys';
import DataImportexportcsv from '../../blocks/DataImportexportcsv/src/DataImportexportcsv';
import UserProfileBasicBlock from '../../blocks/user-profile-basic/src/UserProfileBasicBlock';
import UploadMedia2 from '../../blocks/UploadMedia2/src/UploadMedia2';
import ApiIntegration from '../../blocks/apiintegration/src/ApiIntegration';
import VideoEmbeddingYoutube from '../../blocks/VideoEmbeddingYoutube/src/VideoEmbeddingYoutube';
import CfOnlineProctoringBlocking from '../../blocks/CfOnlineProctoringBlocking/src/CfOnlineProctoringBlocking';
import PhoneNumberInput from '../../blocks/mobile-account-registration/src/PhoneNumberInput';
import AdditionalDetailForm from '../../blocks/mobile-account-registration/src/AdditionalDetailForm';
import Categoriessubcategories from '../../blocks/categoriessubcategories/src/Categoriessubcategories';
import BulkUploading from '../../blocks/BulkUploading/src/BulkUploading';
import AdminConsole3 from '../../blocks/AdminConsole3/src/AdminConsole3';
import CfOnlineProctoringCaptureImages from '../../blocks/CfOnlineProctoringCaptureImages/src/CfOnlineProctoringCaptureImages';
import Videos from '../../blocks/videos/src/Videos';
import RolesPermissions2 from '../../blocks/RolesPermissions2/src/RolesPermissions2';
import CameraAccess from '../../blocks/cameraaccess/src/CameraAccess';
import ContentManagement from '../../blocks/ContentManagement/src/ContentManagement';
import ContentManagementWeb from '../../blocks/ContentManagement/src/ContentManagement.web';
import PhotoLibrary3 from '../../blocks/PhotoLibrary3/src/PhotoLibrary3';
import AssessorList from '../../blocks/dashboard/src/AssessorList.web';
import SocialMediaAccountRegistrationScreen from '../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen';
import McqSjtTest from '../../blocks/dashboard/src/McqSjtTest.web';
import ClientsDashboard from '../../blocks/dashboard/src/ClientsDashboard.web';
import Calendar from '../../blocks/dashboard/src/Calendar.web';
import ParticipantEvents from '../../blocks/dashboard/src/ParticipantEvents.web';
import CfGenerateCredentials from '../../blocks/CfGenerateCredentials/src/CfGenerateCredentials';
import EmailAccountRegistration from '../../blocks/email-account-registration/src/EmailAccountRegistration';
import { TraslateAdime } from '../../blocks/email-account-registration/src/LoginParticipant.web';
import BasicInformation from '../../blocks/email-account-registration/src/BasicInformation.web';
import BasicInformationNew from '../../blocks/email-account-registration/src/BasicInformationNew.web';
import WelcomeParticipant from '../../blocks/email-account-registration/src/WelcomeParticipant.web';
import CheckEmail from '../../blocks/email-account-registration/src/CheckEmail.web';
import EmailAccountForgetPassword from '../../blocks/email-account-registration/src/EmailAccountForgetPassword.web';
import ManageClient from '../../blocks/landingpage/src/ManageClient.web';
import UnavailabilityofAssessor from '../../blocks/landingpage/src/UnavailabilityOfAssessor.web';
import UnavailabilityCalander from '../../blocks/landingpage/src/UnavailabilityCalander.web';
import AssignAvailableAssessor from '../../blocks/landingpage/src/AssignAvailableAssessor.web';
import ResetPassword from '../../blocks/email-account-registration/src/ResetPassword.web';
import { LoginAdmin } from '../../blocks/email-account-registration/src/loginAdmin.web';
import addNewClient from '../../blocks/landingpage/src/AddNewClient.web';
import DashboardApp from '../../blocks/dashboard/src/DashboardPage.web';
import TestInstruction from '../../blocks/dashboard/src/TestInstruction.web';
import Reports from '../../blocks/AdminConsole3/src/Report/Reports.web';
import ProjectDetail from '../../blocks/AdminConsole3/src/Report/ProjectDetail.web';
import ManageSHRMUser from '../../blocks/AdminConsole3/src/ManageSHRMUser/ManageSHRMUser.web';
import ManageCompetency from '../../blocks/AdminConsole3/src/ManageCompetency/ManageCompetency.web';
import CoDCManager from '../../blocks/AdminConsole3/src/ManageSHRMUser/CoDCManager.web';
import OnlineTools from '../../blocks/AdminConsole3/src/OnlineTools/OnlineTools.web';
import AccessRepository from '../../blocks/AdminConsole3/src/AccessRepository/AccessRepository.web';
import Sector from '../../blocks/AdminConsole3/src/OrganizationFunction/Sector/Sector.web';
import Industry from '../../blocks/AdminConsole3/src/OrganizationFunction/Industry/Industry.web';
import Function from '../../blocks/AdminConsole3/src/OrganizationFunction/Function/Function.web';
import Designation from '../../blocks/AdminConsole3/src/OrganizationFunction/Designation/Designation.web';
import EmploymentType from '../../blocks/AdminConsole3/src/OrganizationFunction/EmploymentType/EmploymentType.web';
import CreateGlobalSlot from '../../blocks/AdminConsole3/src/CreateGlobalSlot/CreateGlobalSlot.web';
import ManageParticipant from '../../blocks/landingpage/src/ManageParticipant.web';
import Thankyou from '../../blocks/AssessmentTest/src/ThankyouPage.web';
import AssessorDashboard from '../../blocks/dashboard/src/AssessorDashboard.web';
import AssessorCalendar from '../../blocks/dashboard/src/AssessorCalendar.web';
import AssessorProjectDocs from '../../blocks/dashboard/src/AssessorProjectDocs.web';
import AssessorParticipant from '../../blocks/dashboard/src/AssessorParticipant.web';
import CreateProject from '../../blocks/dashboard/src/Projects.web';
import EditProject from '../../blocks/dashboard/src/EditProject.web';
import CloneProject from '../../blocks/dashboard/src/CloneProject.web';
import CreateNewProject from '../../blocks/dashboard/src/CreateNewProject.web';
import ManageAssessor from '../../blocks/landingpage/src/ManageAssessor.web';
import AnalyticsPage from '../../blocks/landingpage/src/Analytics.web';
import AssessorLeadTools from '../../blocks/AdminConsole3/src/AssessorLeadTools/AssessorLeadTools.web';
import ParticipantReport from '../../blocks/dashboard/src/ParticipantReport/ParticipantReport.web';
import PsychometricUrl from '../../blocks/landingpage/src/PsychometricUrl.web';

const routeMap = {
  Sector: {
    component: Sector,
    path: '/Sector'
  },
  Industry: {
    component: Industry,
    path: '/Industry'
  },
  Function: {
    component: Function,
    path: '/Function'
  },
  Designation: {
    component: Designation,
    path: '/Designation'
  },
  EmploymentType: {
    component: EmploymentType,
    path: '/EmploymentType'
  },
  CreateGlobalSlot: {
    component: CreateGlobalSlot,
    path: '/CreateGlobalSlot'
  },
  CoDCManager: {
    component: CoDCManager,
    path: '/ManageCoDcManagers'
  },
  ManageSHRMUser: {
    component: ManageSHRMUser,
    path: '/ManageDcManagers'
  },
  Reports: {
    component: Reports,
    path: '/Reports'
  },
  AssessorLeadTools: {
    component: AssessorLeadTools,
    path: '/AssessorLeadTools'
  },
  ProjectDetail: {
    component: ProjectDetail,
    path: '/ProjectDetail'
  },
  OnlineTools: {
    component: OnlineTools,
    path: '/OnlineTool'
  },
  AccessRepository: {
    component: AccessRepository,
    path: '/AccessRepository'
  },
  TestInstruction: {
    component: TestInstruction,
    path: '/TestInstructions'
  },
  Library2: {
    component: Library2,
    path: '/Library2'
  },
  ProjectReporting: {
    component: ProjectReporting,
    path: '/ProjectReporting'
  },
  ProjectReportingWeb: {
    component: ProjectReporting,
    path: '/ProjectReportingWeb'
  },
  TimeTracker: {
    component: TimeTracker,
    path: '/TimeTracker'
  },
  CountryCodeSelector: {
    component: CountryCodeSelector,
    path: '/CountryCodeSelector'
  },
  Scheduling: {
    component: Scheduling,
    path: '/Scheduling'
  },
  GroupVideoCall2: {
    component: GroupVideoCall2,
    path: '/GroupVideoCall2'
  },
  Scoring: {
    component: Scoring,
    path: '/Scoring'
  },
  ManageCompetency: {
    component: ManageCompetency,
    path: '/ManageCompetency'
  },
  Analytics: {
    component: Analytics,
    path: '/Analytics'
  },
  AssessorList: {
    component: AssessorList,
    path: '/AssessorList'
  },
  AssessmentTest: {
    component: AssessmentTest,
    path: '/AssessmentTest'
  },
  CfLanguageUploadSupport: {
    component: CfLanguageUploadSupport,
    path: '/CfLanguageUploadSupport'
  },
  EmailNotifications: {
    component: EmailNotifications,
    path: '/EmailNotifications'
  },
  TaskList: {
    component: TaskList,
    path: '/TaskList'
  },
  LanguageSupport: {
    component: LanguageSupport,
    path: '/LanguageSupport'
  },
  LandingPage: {
    component: LandingPage,
    path: '/LandingPage'
  },
  Customform: {
    component: Customform,
    path: '/Customform'
  },
  OTPInputAuth: {
    component: OTPInputAuth,
    path: '/OTPInputAuth'
  },
  Surveys: {
    component: Surveys,
    path: '/Surveys'
  },
  DataImportexportcsv: {
    component: DataImportexportcsv,
    path: '/DataImportexportcsv'
  },
  UserProfileBasicBlock: {
    component: UserProfileBasicBlock,
    path: '/UserProfileBasicBlock'
  },
  UploadMedia2: {
    component: UploadMedia2,
    path: '/UploadMedia2'
  },
  ApiIntegration: {
    component: ApiIntegration,
    path: '/ApiIntegration'
  },
  VideoEmbeddingYoutube: {
    component: VideoEmbeddingYoutube,
    path: '/VideoEmbeddingYoutube'
  },
  CfOnlineProctoringBlocking: {
    component: CfOnlineProctoringBlocking,
    path: '/CfOnlineProctoringBlocking'
  },
  PhoneNumberInput: {
    component: PhoneNumberInput,
    path: '/PhoneNumberInput'
  },
  AdditionalDetailForm: {
    component: AdditionalDetailForm,
    path: '/AdditionalDetailForm'
  },
  Categoriessubcategories: {
    component: Categoriessubcategories,
    path: '/Categoriessubcategories'
  },
  BulkUploading: {
    component: BulkUploading,
    path: '/BulkUploading'
  },
  AdminConsole3: {
    component: AdminConsole3,
    path: '/AdminConsole3'
  },
  CfOnlineProctoringCaptureImages: {
    component: CfOnlineProctoringCaptureImages,
    path: '/CfOnlineProctoringCaptureImages'
  },
  Videos: {
    component: Videos,
    path: '/Videos'
  },
  RolesPermissions2: {
    component: RolesPermissions2,
    path: '/RolesPermissions2'
  },
  CameraAccess: {
    component: CameraAccess,
    path: '/CameraAccess'
  },
  ContentManagement: {
    component: ContentManagement,
    path: '/ContentManagement'
  },
  ContentManagementWeb: {
    component: ContentManagementWeb,
    path: '/ContentManagementWeb'
  },
  PhotoLibrary3: {
    component: PhotoLibrary3,
    path: '/PhotoLibrary3'
  },
  SocialMediaAccountRegistrationScreen: {
    component: SocialMediaAccountRegistrationScreen,
    path: '/SocialMediaAccountRegistrationScreen'
  },
  Dashboard: {
    component: DashboardApp,
    path: '/Dashboard'
  },
  Test: {
    component: McqSjtTest,
    path: '/Test'
  },
  Calendar: {
    component: Calendar,
    path: '/Calendar'
  },
  ParticipantEvents: {
    component: ParticipantEvents,
    path: '/ParticipantEvents'
  },
  ClientsDashboard: {
    component: ClientsDashboard,
    path: '/ClientsDashboard'
  },
  CfGenerateCredentials: {
    component: CfGenerateCredentials,
    path: '/CfGenerateCredentials'
  },

  EmailAccountRegistration: {
    component: EmailAccountRegistration,
    path: '/EmailAccountRegistration'
  },

  // LoginParticipant: {
  //   component: LoginParticipant,
  //   path: '/login'
  // },

  EmailAccountForgetPassword: {
    component: EmailAccountForgetPassword,
    path: '/EmailAccountForgotPassword'
  },

  ResetPassword: {
    component: ResetPassword,
    path: '/resetPassword'
  },

  AdminLogin: {
    component: LoginAdmin,
    path: '/AdminLogin'
  },

  BasicInformation: {
    component: BasicInformationNew,
    path: '/BasicInformation',
    isAuthentication: true
  },

  Home: {
    component: TraslateAdime,
    path: '/login',
    exact: true
  },

  WelcomeParticipant: {
    component: WelcomeParticipant,
    path: '/Welcome'
  },

  ManageParticipant: {
    component: ManageParticipant,
    path: '/ManageParticipant'
  },

  ManageClient: {
    component: ManageClient,
    path: '/ManageClient'
  },

  CheckEmail: {
    component: CheckEmail,
    path: '/EmailSent'
  },

  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: '*/AlertWeb',
    modal: true
  },

  addNewClient: {
    component: addNewClient,
    path: '/addNewClient'
  },

  Thankyou: {
    component: Thankyou,
    path: '/thankyou'
  },
  AssessorDashboard: {
    component: AssessorDashboard,
    path: '/AssessorDashboard'
  },
  AssessorCalendar: {
    component: AssessorCalendar,
    path: '/AssessorCalendar'
  },

  AssessorProjectDocs: {
    component: AssessorProjectDocs,
    path: '/AssessorProjectDocs'
  },

  AssessorParticipant: {
    component: AssessorParticipant,
    path: '/AssessorParticipants'
  },
  ParticipantReport: {
    component: ParticipantReport,
    path: '/ParticipantReport'
  },
  CreateProject: {
    component: CreateProject,
    path: '/myproject'
  },
  EditProject: {
    component: EditProject,
    path: '/EditProject/:id'
  },
  CloneProject: {
    component: CloneProject,
    path: '/CloneProject/:id'
  },
  CreateNewProject: {
    component: CreateNewProject,
    path: '/newproject'
  },
  ManageAssessor: {
    component: ManageAssessor,
    path: '/ManageAssessor'
  },

  AnalyticsPage: {
    component: AnalyticsPage,
    path: '/AppAnalytics'
  },

  UnavailabilityofAssessor: {
    component: UnavailabilityofAssessor,
    path: '/UnavailabilityofAssessor'
  },

  UnavailabilityCalander: {
    component: UnavailabilityCalander,
    path: '/UnavailabilityCalander'
  },

  AssignAvailableAssessor: {
    component: AssignAvailableAssessor,
    path: '/AssignAvailableAssessor'
  },
  PsychometricUrl: {
    component: PsychometricUrl,
    path: '/PsychometricUrl'
  }
};

const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
  authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
  databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
  projectId: 'rnmasterapp-c11e9',
  storageBucket: 'rnmasterapp-c11e9.appspot.com',
  messagingSenderId: '649592030497',
  appId: '1:649592030497:web:7728bee3f2baef208daa60',
  measurementId: 'G-FYBCF3Z2W3'
});

class App extends Component {
  render() {
    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');

    return (
      <View style={{ height: '100%', width: '100%' }}>
        <Portal>
          <ToastContainer
            autoClose={3000}
            position="top-right"
            className="toast-container"
            toastClassName="dark-toast"
          />
        </Portal>
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;
