// Customizable Area Start
import React from "react";
import {
  Box,
  Button,
  InputBase,
  Grid,
  TextField,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Dialog,
  DialogContent,
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import { styled } from "@material-ui/styles";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import * as Yup from "yup";
import { Formik } from "formik";
import { withTranslation } from "react-i18next";
import DesignationController, { Props } from "./DesignationController.web";
import AppSidebar from "../../../../../components/src/AppSidebar.web";
import Spinner from "../../../../../components/src/Spinner.web";
const LanguageData = localStorage.getItem("language") === "ar";

const editIcon = require("../../../assets/edit.png");
const deleteIcon = require("../../../assets/delete.png");

class Designation extends DesignationController {
  constructor(props: Props) {
    super(props);
  }

  designationSchema = () => {
    return Yup.object().shape({
      designationName: Yup.string().required(this.t("organizationFunction.designation.errorMessages.name")),
    });
  };

  TableBodyRender = () => {
    return (
      <TableBody>
        {this.state.data.length > 0 ? (
          <>
            {this.state.data.map((item: any, index: number) => (
              <TableRow key={`${index + 1}`}>
                <TableCell
                  align={LanguageData ? "right" : "left"}
                  style={{
                    fontFamily: "ProximaNova-Regular",
                    fontSize: "18px",
                    color: "#000",
                  }}
                >
                  {item.designationName}
                </TableCell>
                <TableCell
                  align={LanguageData ? "left" : "right"}
                  style={{
                    fontFamily: "ProximaNova-Regular",
                    fontSize: "18px",
                    color: "#000",
                  }}
                >
                  <img
                    src={editIcon}
                    id="edit"
                    style={{
                      cursor: "pointer",
                      marginRight: "10px",
                    }}
                    onClick={() =>
                      this.setState({
                        designationId: item.id,
                        designationName: item.designationName,
                        openDialogName: "Edit",
                      })
                    }
                  />
                  <img
                    src={deleteIcon}
                    id="delete"
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      this.setState({
                        designationId: item.id,
                        designationName: item.designationName,
                        openDialogName: "Delete",
                      })
                    }
                  />
                </TableCell>
              </TableRow>
            ))}
          </>
        ) : (
          <TableRow>
            <TableCell
              colSpan={6}
              align="center"
              style={{
                fontFamily: "ProximaNova-Regular",
                fontSize: "18px",
                color: "#000",
                whiteSpace: "nowrap",
              }}
            >
              {this.t(
                "organizationFunction.designation.label.notFound"
              )}
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    )
  }

  DeleteDilogRender = () => {
    return (
      <Dialog
        open={this.state.openDialogName === "Delete"}
        fullWidth={false}
        maxWidth={"sm"}
        scroll="body"
        transitionDuration={0}
        onClose={() => { }}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogContent style={{ padding: "0px", maxWidth: "500px" }}>
          <Box
            style={{
              paddingTop: "0px !important",
              textAlign: "right",
              margin: "10px 10px 0px 0px",
            }}
          >
            <CloseIcon
              onClick={() =>
                this.setState({
                  designationId: null,
                  designationName: "",
                  openDialogName: null,
                })
              }
              style={{
                cursor: "pointer",
                fontSize: "35px",
                color: "#787878",
              }}
            />
          </Box>
          <Box
            display="flex"
            alignItems="center"
            flexDirection="column"
            padding={{ xs: "15px 25px 25px", sm: "15px 70px 40px" }}
          >
            <Box
              id="modal-title"
              fontWeight="bolder"
              fontSize={{ xs: "20px", sm: "30px" }}
              fontFamily="ProximaNova-Bold"
              mb="20px"
            >
              {this.t("organizationFunction.designation.title.delete")}
            </Box>
            <Box textAlign="center" fontSize="18px">
              {this.t("organizationFunction.designation.description.delete", {
                value: this.state.designationName,
              })}
            </Box>
            <Box mt="30px" display="flex">
              <CustomButton
                className="cancelBtn"
                onClick={() =>
                  this.setState({
                    designationId: null,
                    designationName: "",
                    openDialogName: null,
                  })
                }
                style={{ marginBottom: "0px", padding: "5px 10px" }}
              >
                {this.t("organizationFunction.designation.button.cancel")}
              </CustomButton>
              <RedButton
                onClick={this.deleteDesignation}
                style={{ padding: "5px 10px", marginRight: LanguageData ? '15px' : 'unset' }}
              >
                {this.t("organizationFunction.designation.button.delete")}
              </RedButton>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    )
  }

  EditDilogRender = () => {
    return (
      <Dialog
        open={["Add", "Edit"].includes(this.state.openDialogName ?? "")}
        fullWidth={false}
        maxWidth={"sm"}
        scroll="body"
        transitionDuration={0}
        onClose={() => { }}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        PaperProps={{
          style: {
            width: "500px",
          },
        }}
      >
        <DialogContent style={{ padding: "0px", width: "100%" }}>
          <Box style={{ textAlign: "right", margin: "10px 10px 0px 0px" }}>
            <CloseIcon
              onClick={() =>
                this.setState({
                  designationId: null,
                  designationName: "",
                  openDialogName: null,
                })
              }
              style={{
                cursor: "pointer",
                fontSize: "35px",
                color: "#787878",
              }}
            />
          </Box>
          <Box padding={{ xs: "15px 25px 25px", sm: "15px 40px 40px" }}>
            <Box
              id="modal-title"
              fontWeight="bolder"
              fontSize={{ xs: "20px", sm: "30px" }}
              fontFamily="ProximaNova-Bold"
              mb="20px"
              color="#1b3c69"
              style={{ textAlign: LanguageData ? "right" : "unset" }}
            >
              {this.t(
                `organizationFunction.designation.title.${this.state.openDialogName}`
              )}
            </Box>
            <Formik
              initialValues={{
                designationName: this.state.designationName,
              }}
              validationSchema={this.designationSchema}
              onSubmit={(values) => {
                this.state.openDialogName === "Add"
                  ? this.addDesignation()
                  : this.editDesignation();
              }}
            >
              {({ errors, touched, setFieldValue, handleSubmit }) => (
                <form onSubmit={handleSubmit} noValidate autoComplete="off">
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <div style={{ marginBottom: "5px", fontSize: "18px", textAlign: LanguageData ? "right" : "unset" }}>
                        {this.t(
                          "organizationFunction.designation.label.name"
                        )}
                        <span style={{ color: "red" }}>*</span>
                      </div>
                      <CustomInputBase
                        fullWidth
                        placeholder={this.t(
                          "organizationFunction.designation.placeholder.name"
                        )}
                        value={this.state.designationName}
                        onChange={(event: any) => {
                          setFieldValue(
                            "designationName",
                            event.target.value
                          );
                          this.setState({
                            designationName: event.target.value,
                          });
                        }}
                      />
                      {touched.designationName && errors.designationName && (
                        <div className="text-danger" style={{ textAlign: LanguageData ? "right" : "unset" }}>
                          {errors.designationName}
                        </div>
                      )}
                    </Grid>
                  </Grid>
                  <Box
                    mt="30px"
                    justifyContent="end"
                    display={{ xs: "block", sm: "flex" }}
                  >
                    <CustomButton
                      className="cancelBtn"
                      onClick={() =>
                        this.setState({
                          designationId: null,
                          designationName: "",
                          openDialogName: null,
                        })
                      }
                    >
                      {this.t(
                        "organizationFunction.designation.button.cancel"
                      )}
                    </CustomButton>
                    <RedButton type="submit" style={{ marginRight: LanguageData ? '15px' : 'unset' }}>
                      {this.t(
                        `organizationFunction.designation.button.${this.state.openDialogName}`
                      )}
                    </RedButton>
                  </Box>
                </form>
              )}
            </Formik>
          </Box>
        </DialogContent>
      </Dialog>
    )
  }

  render() {
    return (
      <DesignationStyle style={{ fontFamily: "ProximaNova-Regular" }}>
        {this.state.loading && <Spinner spinnerModal={this.state.loading} />}
        <AppSidebar type="admin" openOrganization>
          <Box margin="35px 0px" mr="30px" data-testid="designationSchema">
            <Box
              display={{ xs: "block", sm: "flex" }}
              justifyContent="space-between"
              mb="40px"
            >
              <Box>
                <Box color="#1b3c69"
                  fontFamily="ProximaNova-Bold" fontSize={{ xs: "24px", sm: "30px", md: "44px" }} mb="5px">
                  {this.t("organizationFunction.designation.title.main")}</Box>
                <Box color="#1b3c69"
                  fontSize={{ xs: "14px", sm: "20px" }}
                >
                  {this.t("organizationFunction.designation.description.main")}
                </Box>
              </Box>
              <Box mt={{ xs: "20px", sm: "0px" }}> <RedButton style={{ backgroundColor: "#f26a42" }} onClick={() => this.setState({ openDialogName: "Add" })}>
                {this.t("organizationFunction.designation.button.Add")}
              </RedButton>
              </Box>
            </Box>
            <Box mt="60px">
              <Box><Grid container>
                <Grid item
                  xs={12} className={LanguageData ? "SearchIconRightSide" : ""}>
                  <Box
                    mb="25px"
                    justifyContent="end"
                    alignItems="center"
                    display="flex"
                  >
                    <TextField
                      id="search"
                      InputLabelProps={{
                        style: {
                          fontFamily: "ProximaNova-Regular",
                          zIndex: 0,
                        },
                      }}
                      onChange={(event: any) => {
                        this.onChangeHandler(event.target.value, 0);
                      }}
                      label={this.t(
                        "organizationFunction.designation.label.search"
                      )}
                      variant="outlined"
                      InputProps={{
                        endAdornment: <SearchIcon />,
                      }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} style={{ overflow: "auto" }}>
                  <Table
                    aria-label="simple table"
                    style={{
                      border: "1px solid #E0E0E0",
                      borderRadius: "10px",
                      borderCollapse: "separate",
                    }}
                    className="custom-table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell
                          align={LanguageData ? "right" : "left"}
                          style={{
                            fontFamily: "ProximaNova-Bold",
                            fontSize: "16px",
                          }}
                        >
                          {this.t(
                            "organizationFunction.designation.label.tableName"
                          )}
                        </TableCell>
                        <TableCell
                          style={{
                            fontFamily: "ProximaNova-Bold",
                            fontSize: "16px",
                          }}
                          align={LanguageData ? "left" : "right"}
                        >
                          {this.t(
                            "organizationFunction.designation.label.action"
                          )}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    {this.TableBodyRender()}
                  </Table>
                  {this.state.data.length > 0 && (
                    <div className="custom-pagination">
                      <Pagination
                        page={this.state.page + 1}
                        count={Math.ceil(
                          this.state.dataLength / this.state.rowsPerPage
                        )}
                        onChange={(event: unknown, newPage: number) => {
                          this.onChangeHandler(
                            this.state.search,
                            newPage - 1
                          );
                        }}
                        dir="ltr"
                        variant="outlined"
                        shape="rounded"
                        showFirstButton
                        showLastButton
                        siblingCount={0}
                        boundaryCount={1}
                      />
                    </div>
                  )}
                </Grid>
              </Grid>
              </Box>
            </Box>
          </Box>
        </AppSidebar>

        {/* for add/edit user */}
        {this.EditDilogRender()}

        {/* for delete */}
        {this.DeleteDilogRender()}
      </DesignationStyle>
    );
  }
}

export default withTranslation()(Designation);

const CustomInputBase = styled(InputBase)({
  border: "1px solid #787878",
  borderRadius: "10px",
  minHeight: "45px",
  paddingLeft: "15px",
  fontSize: "16px",
  "& .MuiInputBase-inputMultiline": {
    "&::placeholder": {
      fontSize: "17px",
    },
  },
});

const DesignationStyle = styled('div')({
  '& .SearchIconRightSide': {
    '& .MuiInputBase-root': {
      display: "flex",
      flexDirection: "row-reverse"
    }
  }
})

const CustomButton = styled(Button)({
  backgroundColor: "white",
  color: "#f26a42",
  border: "1px solid #f26a42",
  fontFamily: "ProximaNova-Semibold",
  marginRight: "15px",
  borderRadius: "8px",
  fontSize: "18px",
  textTransform: "none",
  padding: "7px 17px",
  "&:hover": {
    color: "#f26a42",
    backgroundColor: "white",
  },
  "@media(max-width:600px)": {
    width: "100%",
  },
  "@media(max-width:992px)": {
    padding: "7px 15px",
  },
});

const RedButton = styled(Button)({
  backgroundColor: "#f94b4b",
  color: "white",
  borderRadius: "10px",
  fontFamily: "ProximaNova-Semibold",
  fontSize: "18px",
  textTransform: "none",
  padding: "9px 30px",
  "&:hover": {
    color: "white",
    backgroundColor: "#f94b4b",
  },
  "@media(max-width:600px)": {
    width: "100%",
    marginTop: "27px",
  },
});
// Customizable Area End