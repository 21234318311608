// Customizable Area Start
import React from "react";
import {
  Box,
  Button,
  InputBase,
  Grid,
  TextField,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Dialog,
  DialogContent,
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import { styled } from "@material-ui/styles";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import * as Yup from "yup";
import { Formik } from "formik";
import { withTranslation } from "react-i18next";
import IndustryController, { Props } from "./IndustryController.web";
const LanguageData = localStorage.getItem("language") === "ar";
import AppSidebar from "../../../../../components/src/AppSidebar.web";
import Spinner from "../../../../../components/src/Spinner.web";

const editIcon = require("../../../assets/edit.png");
const deleteIcon = require("../../../assets/delete.png");

class Industry extends IndustryController {
  constructor(props: Props) {
    super(props);
  }

  industrySchema = () => {
    return Yup.object().shape({
      industryName: Yup.string().required(
        this.t("organizationFunction.industry.errorMessages.name")
      ),
    });
  };

  EditAddDilogRender = () => {
    return (
      <Dialog
        open={["Add", "Edit"].includes(this.state.openDialogName ?? "")}
        fullWidth={false}
        maxWidth={"sm"}
        scroll="body"
        transitionDuration={0}
        PaperProps={{
          style: {
            width: "500px",
          },
        }}
        onClose={() => { }}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogContent style={{ padding: "0px", width: "100%" }}>
          <Box style={{ textAlign: "right", margin: "10px 10px 0px 0px" }}>
            <CloseIcon
              style={{ cursor: "pointer", fontSize: "35px", color: "#787878", }}
              onClick={() =>
                this.setState({
                  industryId: null, industryName: "", openDialogName: null,
                })
              }
            />
          </Box>
          <Box padding={{ xs: "15px 25px 25px", sm: "15px 40px 40px" }}>
            <Box
              id="modal-title"
              color="#1b3c69"
              fontFamily="ProximaNova-Bold"
              fontSize={{ xs: "20px", sm: "30px" }}
              mb="20px"
              fontWeight="bolder"
              style={{ textAlign: LanguageData ? "right" : "unset" }}
            >
              {this.t(
                `organizationFunction.industry.title.${this.state.openDialogName}`
              )}
            </Box>
            <Formik
              initialValues={{
                industryName: this.state.industryName,
              }}
              validationSchema={this.industrySchema}
              onSubmit={(values) => {
                this.state.openDialogName === "Add"
                  ? this.addIndustry()
                  : this.editIndustry();
              }}
            >
              {({ errors, touched, setFieldValue, handleSubmit }) => (
                <form onSubmit={handleSubmit} noValidate autoComplete="off">
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <div style={{
                        marginBottom: "5px",
                        fontSize: "18px", textAlign: LanguageData ? "right" : "unset"
                      }}>
                        {this.t("organizationFunction.industry.label.name")}
                        <span style={{ color: "red" }}>*</span>
                      </div>
                      <CustomInputBase
                        placeholder={this.t(
                          "organizationFunction.industry.placeholder.name"
                        )}
                        fullWidth
                        value={this.state.industryName}
                        onChange={(event: any) => {
                          setFieldValue("industryName", event.target.value);
                          this.setState({ industryName: event.target.value });
                        }}
                      />
                      {touched.industryName && errors.industryName && (
                        <div className="text-danger" style={{ textAlign: LanguageData ? "right" : "unset" }}>
                          {errors.industryName}
                        </div>
                      )}
                    </Grid>
                  </Grid>
                  <Box
                    mt="30px"
                    justifyContent="end"
                    display={{ xs: "block", sm: "flex" }}
                  >
                    <CustomButton
                      className="cancelBtn"
                      onClick={() =>
                        this.setState({
                          industryId: null,
                          industryName: "",
                          openDialogName: null,
                        })
                      }
                    >
                      {this.t("organizationFunction.industry.button.cancel")}
                    </CustomButton>
                    <RedButton type="submit" style={{ marginRight: LanguageData ? "10px" : "unset" }}>
                      {this.t(`organizationFunction.industry.button.${this.state.openDialogName}`)}
                    </RedButton>
                  </Box>
                </form>
              )}
            </Formik>
          </Box>
        </DialogContent>
      </Dialog>
    )
  }

  DeleteDilogRender = () => {
    return (
      <Dialog
        open={this.state.openDialogName === "Delete"}
        fullWidth={false}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        scroll="body"
        transitionDuration={0}
        onClose={() => { }}
        maxWidth={"sm"}
      >
        <DialogContent style={{ padding: "0px", maxWidth: "500px" }}>
          <Box
            style={{
              margin: "10px 10px 0px 0px",
              textAlign: "right",
              paddingTop: "0px !important",
            }}
          >
            <CloseIcon
              onClick={() =>
                this.setState({
                  industryId: null,
                  industryName: "",
                  openDialogName: null,
                })
              }
              style={{
                cursor: "pointer",
                color: "#787878",
                fontSize: "35px",
              }}
            />
          </Box>
          <Box
            display="flex"
            alignItems="center" flexDirection="column" padding={{ xs: "15px 25px 25px", sm: "15px 70px 40px" }}
          >
            <Box
              id="modal-title"
              fontWeight="bolder"
              fontSize={{ xs: "20px", sm: "30px" }}
              fontFamily="ProximaNova-Bold"
              mb="20px"
            >
              {this.t("organizationFunction.industry.title.delete")}
            </Box>
            <Box textAlign="center" fontSize="18px">
              {this.t("organizationFunction.industry.description.delete", {
                value: this.state.industryName,
              })}
            </Box>
            <Box mt="30px" display="flex">
              <CustomButton
                className="cancelBtn"
                onClick={() =>
                  this.setState({
                    industryId: null,
                    industryName: "",
                    openDialogName: null,
                  })
                }
                style={{ marginBottom: "0px", padding: "5px 10px" }}
              >
                {this.t("organizationFunction.industry.button.cancel")}
              </CustomButton>
              <RedButton
                onClick={this.deleteIndustry}
                style={{ padding: "5px 10px" }}
              >
                {this.t("organizationFunction.industry.button.delete")}
              </RedButton>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    )
  }

  TableRender = () => {
    return (
      <Table
        aria-label="simple table"
        className="custom-table"
        style={{
          border: "1px solid #E0E0E0",
          borderRadius: "10px",
          borderCollapse: "separate",
        }}
      >
        <TableHead>
          <TableRow>
            <TableCell
              style={{
                fontFamily: "ProximaNova-Bold",
                fontSize: "16px",
              }}
              align={LanguageData ? "right" : "left"}
            >
              {this.t(
                "organizationFunction.industry.label.tableName"
              )}
            </TableCell>
            <TableCell
              style={{
                fontSize: "16px",
                fontFamily: "ProximaNova-Bold",
              }}
              align={LanguageData ? "left" : "right"}
            >
              {this.t(
                "organizationFunction.industry.label.action"
              )}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {this.state.data.length > 0 ? (
            <>
              {this.state.data.map((item: any, index: number) => (
                <TableRow key={`${index + 1}`}>
                  <TableCell
                    style={{
                      color: "#000",
                      fontFamily: "ProximaNova-Regular",
                      fontSize: "18px",
                    }}
                    align={LanguageData ? "right" : "left"}
                  >
                    {item.industryName}
                  </TableCell>
                  <TableCell
                    style={{
                      fontSize: "18px",
                      fontFamily: "ProximaNova-Regular",
                      color: "#000",
                    }}
                    align={LanguageData ? "left" : "right"}
                  >
                    <img
                      src={editIcon}
                      style={{
                        cursor: "pointer",
                        marginRight: "10px",
                      }}
                      id="edit"
                      onClick={() =>
                        this.setState({
                          industryId: item.id,
                          industryName: item.industryName,
                          openDialogName: "Edit",
                        })
                      }
                    />
                    <img
                      src={deleteIcon}
                      id="delete"
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        this.setState({
                          industryId: item.id,
                          industryName: item.industryName,
                          openDialogName: "Delete",
                        })
                      }
                    />
                  </TableCell>
                </TableRow>
              ))}
            </>
          ) : (
            <TableRow>
              <TableCell
                colSpan={6}
                align="center"
                style={{
                  whiteSpace: "nowrap",
                  fontSize: "18px",
                  color: "#000",
                  fontFamily: "ProximaNova-Regular",
                }}
              >
                {this.t(
                  "organizationFunction.industry.label.notFound"
                )}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    )
  }

  render() {
    return (
      <StyleIndustry style={{ fontFamily: "ProximaNova-Regular" }}>
        {this.state.loading && <Spinner spinnerModal={this.state.loading} />}
        <AppSidebar type="admin" openOrganization>
          <Box margin="35px 0px" mr="30px">
            <Box
              display={{ xs: "block", sm: "flex" }} justifyContent="space-between" mb="40px"
            >
              <Box>
                <Box
                  fontFamily="ProximaNova-Bold"
                  fontSize={{ xs: "24px", sm: "30px", md: "44px" }}
                  mb="5px" color="#1b3c69"
                >
                  {this.t("organizationFunction.industry.title.main")}
                </Box>
                <Box color="#1b3c69" fontSize={{ xs: "14px", sm: "20px" }}>
                  {this.t("organizationFunction.industry.description.main")}
                </Box>
              </Box>
              <Box mt={{ xs: "20px", sm: "0px" }}>
                <RedButton onClick={() => this.setState({ openDialogName: "Add" })} style={{ backgroundColor: "#f26a42" }}
                >
                  {this.t("organizationFunction.industry.button.Add")}
                </RedButton>
              </Box>
            </Box>
            <Box mt="60px">
              <Box>
                <Grid container>
                  <Grid item xs={12} className={LanguageData ? "SearchIconRightSide" : ""}>
                    <Box
                      display="flex"
                      mb="25px" justifyContent="end" alignItems="center"
                    >
                      <TextField id="search"
                        InputLabelProps={{
                          style: { fontFamily: "ProximaNova-Regular", zIndex: 0, },
                        }}
                        onChange={(event: any) => { this.onChangeHandler(event.target.value, 0); }}
                        label={this.t("organizationFunction.industry.label.search")}
                        InputProps={{
                          endAdornment: <SearchIcon />,
                        }}
                        variant="outlined"
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} style={{ overflow: "auto" }}>
                    {this.TableRender()}
                    {this.state.data.length > 0 && (
                      <div className="custom-pagination">
                        <Pagination
                          dir="ltr" shape="rounded" variant="outlined" showFirstButton siblingCount={0} showLastButton
                          boundaryCount={1}
                          page={this.state.page + 1}
                          count={Math.ceil(
                            this.state.dataLength / this.state.rowsPerPage
                          )}
                          onChange={(event: unknown, newPage: number) => {
                            this.onChangeHandler(
                              this.state.search,
                              newPage - 1
                            );
                          }}
                        />
                      </div>
                    )}
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>
        </AppSidebar>

        {/* for add/edit user */}
        {this.EditAddDilogRender()}

        {/* for delete */}
        {this.DeleteDilogRender()}
      </StyleIndustry>
    );
  }
}

export default withTranslation()(Industry);

const CustomInputBase = styled(InputBase)({
  borderRadius: "10px",
  minHeight: "45px",
  fontSize: "16px",
  paddingLeft: "15px",
  border: "1px solid #787878",
  "& .MuiInputBase-inputMultiline": {
    "&::placeholder": {
      fontSize: "17px",
    },
  },
});

const StyleIndustry = styled('div')({
  '& .SearchIconRightSide': {
    '& .MuiInputBase-root': {
      display: "flex",
      flexDirection: "row-reverse"
    }
  }
})

const CustomButton = styled(Button)({
  border: "1px solid #f26a42",
  backgroundColor: "white",
  color: "#f26a42",
  fontFamily: "ProximaNova-Semibold",
  marginRight: "15px",
  borderRadius: "8px",
  textTransform: "none",
  padding: "7px 17px",
  fontSize: "18px",
  "&:hover": {
    color: "#f26a42",
    backgroundColor: "white",
  },
  "@media(max-width:600px)": {
    width: "100%",
  },
  "@media(max-width:992px)": {
    padding: "7px 15px",
  },
});

const RedButton = styled(Button)({
  borderRadius: "10px",
  color: "white",
  fontFamily: "ProximaNova-Semibold",
  textTransform: "none",
  padding: "9px 30px",
  fontSize: "18px",
  backgroundColor: "#f94b4b",
  "&:hover": {
    color: "white",
    backgroundColor: "#f94b4b",
  },
  "@media(max-width:600px)": {
    width: "100%",
    marginTop: "27px",
  },
});
// Customizable Area End
