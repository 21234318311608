// Customizable Area Start
import React from "react";
import { Link } from "react-router-dom";
import { Snackbar, Box, FormControl } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import EmailAccountRegistrationController, {
  Props,
} from "./EmailAccountRegistrationController";
const { baseURL } = require("../../../framework/src/config");
import { India, UAE, UK } from "../src/assets";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withTranslation } from "react-i18next";
import Select from "react-select";
// @ts-ignore
import shrmLogo from '../assets/shrmlogo.png'
const infosysLogo = require("../assets/infosys.png");
const indianFlag = require("../assets/india.png");
const dropIcon = require("../assets/image_arrow@2x.png");


const Alert = (props: any) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default class LoginParticipant extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
  }


  onLanguageChange = (language: string) => {
    this.setState({ selected: language });
    localStorage.setItem("language", language);
    this.props.i18n.changeLanguage(language);
    window.location.reload()
  }
  emailregsx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  handleValidate = () => {
    const errors = { username: "", password: "" };
    if (this.state.account.username.trim() === "") {
      errors.username = "Enter Username";
    } else if (this.emailregsx.test(this.state.account.username)) {
      errors.username = "Please Enter Valid Username";
    }
    if (this.state.account.password.trim() === "") {
      errors.password = "Enter password";
    }
    return Object.keys(errors).length === 0 ? null : errors;
  };


  handleValidateProperty = (e: any) => {
    const USER_REGEX = /^[A-z][A-z0-9-_]{3,23}$/;
    if (e.target.name === "username") {
      if (e.target.value.trim() === "") {
        return "Enter Username";
      } else if (this.emailregsx.test(e.target.value)) {
        return "Please Enter Valid Username";
      }
    }
    if (e.target.name === "password") {
      if (e.target.value.trim() === "") {
        return "Login.errorMessage.password";
      }
      if (e.target.value.trim().length < 8) {
        return "Login.errorMessage.passwordLength";
      }
    }
  };


  handleSubmit = (e: any) => {
    const USER_REGEX = /^[A-z][A-z0-9-_]{3,23}$/;
    e.preventDefault();
    const errors = this.handleValidate();

    this.setState({ errors: errors || {} });

    if (
      this.state.account.username !== "" &&
      this.state.account.password !== "" &&
      this.state.errors.username !== "Please Enter Valid Username"
    ) {
      this.setState({ isLoading: true });
      const jsonpayload = {
        data: {
          attributes: {
            email: this.state.account.username,
            password: this.state.account.password,
          },
          type: "participant_client"
        },
      };

      fetch(baseURL + `/bx_block_login/logins`, {
        method: "POST",
        headers: { "Content-Type": "application/json", "locale": this.state.selected },
        body: JSON.stringify(jsonpayload)
      })
        .then(response => {
          if (response.status === 401) {
            this.setState({ open: true });
            this.setState({ isLoading: false });
            this.setState({ account: { username: "", password: "" } });
            throw new Error("Your password is incorrect");
          } else if (response.status === 422) {
            this.setState({ open: true });
            this.setState({ isLoading: false });
            this.setState({ account: { username: "", password: "" } });
            throw new Error("Your email is incorrect");
          }
          return response.json();
        })
        .then((data: any) => {
          this.HandelSubmitTwo(data)
        })
        .catch(err => {
          this.setState({ isLoading: false });
          console.log(err);
        });
    }
  };

  HandelSubmitTwo = (data:any) =>{
    const accessToken = data.meta.token;
    const participantId = data?.data?.attributes?.id
    localStorage.setItem("user", data.data.attributes.full_name)
    localStorage.setItem("accesToken", accessToken);
    localStorage.setItem("participantId", participantId);
    localStorage.setItem("ContentType", data.data.attributes.type)
    localStorage.setItem("isUser", "true");
    localStorage.setItem("logo", data.data.attributes.logo_url)
    localStorage.setItem("userRole", data?.data?.attributes?.type)
    if (accessToken) {
      if (data?.data?.attributes?.type === "Client") { this.props.navigation.navigate("ClientsDashboard") }
      if (data?.data?.attributes?.type === "Participant") { this.AlredyLogin() }
    }
  }


  handleChange = (e: any) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.handleValidateProperty(e);

    if (errorMessage) {
      errors[e.target.name] = errorMessage;
    } else {
      delete errors[e.target.name];
    }
    const account = { ...this.state.account };
    account[e.currentTarget.name] = e.currentTarget.value;
    this.setState({ account, errors });
  };

  handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ open: false });
  };


  render() {
    return (
      <>
        {this.state.isLoading ? <Backdrop open={this.state.isLoading}>
          <CircularProgress color="primary" />
        </Backdrop> :
          <div className="participant_login">
            <section className="d-flex">
              <div className="shrm_logo">
                <img src={shrmLogo} alt="shrmlogo" className="brand_img" />
              </div>
              <Box className="select-icon" >
                <FormControl style={{ width: "150px" }}>
                  <Select
                    classNamePrefix="react-select"
                    options={languageList}
                    isSearchable={false}
                    formatOptionLabel={(country: any) => {
                      return (
                        <div className="country-option" style={{ display: "flex", alignItems: "center" }}>
                          <img src={country.flag} width="20px" height="20px" alt="india" />
                          <p style={{ fontSize: "16px", fontFamily: "ProximaNova-Semibold", marginLeft: "5px", marginRight: "5px" }}>{country.label}</p>
                        </div>
                      )
                    }}
                    value={
                      languageList.filter(
                        (option: any) =>
                          option.value === this.state.selected
                      )[0]
                    }
                    onChange={(event: any) => {
                      this.onLanguageChange(event.value);
                    }}
                  />
                </FormControl>
              </Box>
            </section>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div className="form_container form_container_sm">
                <section>
                  <figure className="img_logo">
                    {this.state.clientLogoURL ?
                      <img src={this.state.clientLogoURL} alt="infosyslogo" width="148" height="70" />
                      : <img src={shrmLogo} alt="infosyslogo" width="148" height="70" />
                    }
                  </figure>
                  <form onSubmit={this.handleSubmit}>
                    <div className="form-control" dir={this.state.selected === "ar" ? 'rtl' : ''}>
                      <label htmlFor="username" className="field-label">{this.t("Login.username")} <span style={{ color: "red" }}>*</span></label>
                      <input
                        type="text"
                        value={this.state.account.username}
                        name="username"
                        onChange={this.handleChange}
                        id="username"
                        className="custom-form-control"
                        autoComplete="off"
                        style={{ border: this.state.errors.username ? '2px solid red' : '', fontFamily: "ProximaNova-Semibold" }}
                        autoFocus
                      />
                      {(this.state.account.username === "" || this.state.errors.username === "Please Enter Valid Username") && <p className="validation_errors">{this.t("Login.errorMessage.username")}</p>}
                    </div>
                    <div className="form-controlPwd" dir={this.state.selected === "ar" ? 'rtl' : 'ltr'}>
                      <label htmlFor="password" className="field-label">{this.t("Login.password")}<span style={{ color: "red" }}>*</span></label>
                      <input
                        id="password"
                        value={this.state.account.password}
                        name="password"
                        onChange={this.handleChange}
                        type="password"
                        className="custom-form-control"
                        style={{ border: this.state.errors.password ? '2px solid red' : '' }}
                      />
                      {this.state.errors && <p className="validation_errors">{this.t(this.state.errors.password)}</p>}
                    </div>
                    <div>

                      <button className="login_btns" >
                        <span className="btn_text" style={{ color: "#fff", fontSize: '22px', fontWeight: 600 }} >{this.t("Login.button.login")}</span>
                      </button>

                    </div>
                    <div className="forgot_password">
                      <Link to="/EmailAccountForgotPassword" onClick={() => {
                        localStorage.setItem('forgateLink', JSON.stringify('login'))
                      }} > <p> {this.t("Login.link.forgotPassword")}</p></Link>
                    </div>
                  </form>
                  <Snackbar open={this.state.open} anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}>
                    <Alert onClose={this.handleClose} severity="error" style={{ height: "46px" }}>
                      {this.t("Login.InvalidEmailandPassword")}
                    </Alert>
                  </Snackbar>

                </section>

              </div>
            </div>
            <section className="text_footer" dir="ltr">
              <p>{this.state.copyright}</p>
            </section>
          </div>}
      </>
    );
  }

}
const languageList = [
  { label: "English", value: "en", flag: UK },
  { label: "Hindi", value: "hi", flag: India },
  { label: "Arabic", value: "ar", flag: UAE },
];

const TraslateAdime = withTranslation()(LoginParticipant)
export { TraslateAdime }
// Customizable Area End