Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "AdminConsole3";
exports.labelBodyText = "AdminConsole3 Body";

exports.btnExampleTitle = "CLICK ME";

exports.tableDetailApiMethod = "GET";
exports.tableDetailContentType = "application/json";
exports.tableDetailApiEndPoint = "/bx_block_assessmenttest/questions";

exports.industryListApiMethod = "GET";
exports.industryListApiContentType = "application/json";
exports.industryListApiEndPoint = "/bx_block_profile/industries";

exports.competencyListApiMethod = "GET";
exports.competencyListApiContentType = "application/json";
exports.competencyListApiEndPoint = "/bx_block_assessmenttest/competancies";

exports.onlineToolsListApiMethod = "GET";
exports.onlineToolsListApiContentType = "application/json";
exports.onlineToolsListApiEndPoint = "/bx_block_admin/online_tools";

exports.addQuestionApiMethod = "POST";
exports.addQuestionApiContentType = "application/json";
exports.addQuestionApiEndPoint = "/bx_block_assessmenttest/questions";

exports.editQuestionApiMethod = "PUT";
exports.editQuestionApiContentType = "application/json";
exports.editQuestionApiEndPoint = "/bx_block_assessmenttest/questions";

exports.deleteQuestionApiMethod = "DELETE";
exports.deleteQuestionApiContentType = "application/json";
exports.deleteQuestionApiEndPoint = "/bx_block_assessmenttest/questions";

exports.cloneQuestionApiMethod = "POST";
exports.cloneQuestionApiContentType = "application/json";
exports.cloneQuestionApiEndPoint = "/bx_block_assessmenttest/questions/clone";

exports.addBulkQuestionApiMethod = "POST";
exports.addBulkQuestionApiEndPoint = "/bx_block_assessmenttest/questions/bulk";

exports.addToolApiMethod = "POST";
exports.addToolApiContentType = "application/json";
exports.addToolApiEndPoint = "/bx_block_admin/online_tools";

exports.editToolApiMethod = "PUT";
exports.editToolApiContentType = "application/json";
exports.editToolApiEndPoint = "/bx_block_admin/online_tools";

exports.deleteToolApiMethod = "DELETE";
exports.deleteToolApiContentType = "application/json";
exports.deleteToolApiEndPoint = "/bx_block_admin/online_tools";

exports.deleteErrorCSVApiMethod = "DELETE";
exports.deleteErrorCSVApiContentType = "application/json";
exports.deleteErrorCSVApiEndPoint = "/bx_block_assessmenttest/questions/error_csv";

exports.reportApiMethod = "GET";
exports.reportApiContentType = "application/json";
exports.reportApiEndPoint = "/bx_block_profile/admin_reports";

exports.participantDetailApiMethod = "GET";
exports.participantDetailApiContentType = "application/json";
exports.participantDetailApiEndPoint = "/inner_report";

exports.participantReportApiMethod = "GET";
exports.participantReportApiContentType = "application/json";
exports.participantReportApiEndPoint = "/download_report";

exports.editFeedbackApiMethod = "POST";
exports.editFeedbackApiContentType = "application/json";
exports.editFeedbackApiEndPoint = "/account_block/assessors/set_score";

exports.SHRMUserListApiMethod = "GET";
exports.SHRMUserListApiContentType = "application/json";
exports.SHRMUserListApiEndPoint = "/account_block/managers";

exports.clientListApiMethod = "GET";
exports.clientListApiContentType = "application/json";
exports.clientListApiEndPoint = "/account_block/clients";

exports.projectListApiMethod = "GET";
exports.projectListApiContentType = "application/json";
exports.projectListApiEndPoint = "/account_block/clients";

exports.addUserApiMethod = "POST";
exports.addUserApiContentType = "application/json";
exports.addUserApiEndPoint = "/account_block/managers";

exports.editUserApiMethod = "PUT";
exports.editUserApiContentType = "application/json";
exports.editUserApiEndPoint = "/account_block/managers";

exports.deleteUserApiMethod = "DELETE";
exports.deleteUserApiContentType = "application/json";
exports.deleteUserApiEndPoint = "/account_block/managers";

exports.coDcManagerListApiMethod = "GET";
exports.coDcManagerListApiContentType = "application/json";
exports.coDcManagerListApiEndPoint = "/account_block/co_managers";

exports.addCoDcManagerApiMethod = "POST";
exports.addCoDcManagerApiContentType = "application/json";
exports.addCoDcManagerApiEndPoint = "/account_block/co_managers";

exports.editCoDcManagerApiMethod = "PUT";
exports.editCoDcManagerApiContentType = "application/json";
exports.editCoDcManagerApiEndPoint = "/account_block/co_managers";

exports.deleteCoDcManagerApiMethod = "DELETE";
exports.deleteCoDcManagerApiContentType = "application/json";
exports.deleteCoDcManagerApiEndPoint = "/account_block/co_managers";

exports.globalSlotListApiMethod = "GET";
exports.globalSlotListApiContentType = "application/json";
exports.globalSlotListApiEndPoint = "/bx_block_profile/slots";

exports.addGlobalSlotApiMethod = "POST";
exports.addGlobalSlotApiContentType = "application/json";
exports.addGlobalSlotApiEndPoint = "/bx_block_profile/slots";

exports.editGlobalSlotApiMethod = "PUT";
exports.editGlobalSlotApiContentType = "application/json";
exports.editGlobalSlotApiEndPoint = "/bx_block_profile/slots";

exports.deleteGlobalSlotApiMethod = "DELETE";
exports.deleteGlobalSlotApiContentType = "application/json";
exports.deleteGlobalSlotApiEndPoint = "/bx_block_profile/slots";

exports.industryListApiMethod = "GET";
exports.industryListApiContentType = "application/json";
exports.industryListApiEndPoint = "/bx_block_profile/industries";

exports.addIndustryApiMethod = "POST";
exports.addIndustryApiContentType = "application/json";
exports.addIndustryApiEndPoint = "/bx_block_profile/industries";

exports.editIndustryApiMethod = "PUT";
exports.editIndustryApiContentType = "application/json";
exports.editIndustryApiEndPoint = "/bx_block_profile/industries";

exports.deleteIndustryApiMethod = "DELETE";
exports.deleteIndustryApiContentType = "application/json";
exports.deleteIndustryApiEndPoint = "/bx_block_profile/industries";

exports.sectorListApiMethod = "GET";
exports.sectorListApiContentType = "application/json";
exports.sectorListApiEndPoint = "/bx_block_admin/sectors";

exports.addSectorApiMethod = "POST";
exports.addSectorApiContentType = "application/json";
exports.addSectorApiEndPoint = "/bx_block_admin/sectors";

exports.editSectorApiMethod = "PUT";
exports.editSectorApiContentType = "application/json";
exports.editSectorApiEndPoint = "/bx_block_admin/sectors";

exports.deleteSectorApiMethod = "DELETE";
exports.deleteSectorApiContentType = "application/json";
exports.deleteSectorApiEndPoint = "/bx_block_admin/sectors";

exports.functionListApiMethod = "GET";
exports.functionListApiContentType = "application/json";
exports.functionListApiEndPoint = "/bx_block_admin/org_functions";

exports.addFunctionApiMethod = "POST";
exports.addFunctionApiContentType = "application/json";
exports.addFunctionApiEndPoint = "/bx_block_admin/org_functions";

exports.editFunctionApiMethod = "PUT";
exports.editFunctionApiContentType = "application/json";
exports.editFunctionApiEndPoint = "/bx_block_admin/org_functions";

exports.deleteFunctionApiMethod = "DELETE";
exports.deleteFunctionApiContentType = "application/json";
exports.deleteFunctionApiEndPoint = "/bx_block_admin/org_functions";

exports.designationListApiMethod = "GET";
exports.designationListApiContentType = "application/json";
exports.designationListApiEndPoint = "/bx_block_admin/org_designations";

exports.addDesignationApiMethod = "POST";
exports.addDesignationApiContentType = "application/json";
exports.addDesignationApiEndPoint = "/bx_block_admin/org_designations";

exports.editDesignationApiMethod = "PUT";
exports.editDesignationApiContentType = "application/json";
exports.editDesignationApiEndPoint = "/bx_block_admin/org_designations";

exports.deleteDesignationApiMethod = "DELETE";
exports.deleteDesignationApiContentType = "application/json";
exports.deleteDesignationApiEndPoint = "/bx_block_admin/org_designations";

exports.employmentTypeListApiMethod = "GET";
exports.employmentTypeListApiContentType = "application/json";
exports.employmentTypeListApiEndPoint = "/bx_block_profile/employment_types";

exports.addEmploymentTypeApiMethod = "POST";
exports.addEmploymentTypeApiContentType = "application/json";
exports.addEmploymentTypeApiEndPoint = "/bx_block_profile/employment_types";

exports.editEmploymentTypeApiMethod = "PUT";
exports.editEmploymentTypeApiContentType = "application/json";
exports.editEmploymentTypeApiEndPoint = "/bx_block_profile/employment_types";

exports.deleteEmploymentTypeApiMethod = "DELETE";
exports.deleteEmploymentTypeApiContentType = "application/json";
exports.deleteEmploymentTypeApiEndPoint = "/bx_block_profile/employment_types";

exports.assessorListApiMethod = "GET";
exports.assessorListContentType =  "application/json";
exports.assessorListApiEndPoint = "/account_block/assessors";

exports.competencyListApiMethod = "GET";
exports.competencyListApiContentType = "application/json";
exports.competencyListApiEndPoint = "/bx_block_assessmenttest/competancies";

exports.addCompetencyApiMethod = "POST";
exports.addCompetencyApiContentType = "application/json";
exports.addCompetencyApiEndPoint = "/bx_block_assessmenttest/competancies";

exports.editCompetencyApiMethod = "PUT";
exports.editCompetencyApiContentType = "application/json";
exports.editCompetencyApiEndPoint = "/bx_block_assessmenttest/competancies";

exports.deleteCompetencyApiMethod = "DELETE";
exports.deleteCompetencyApiContentType = "application/json";
exports.deleteCompetencyApiEndPoint = "/bx_block_assessmenttest/competancies";

exports.addBulkCompetencyApiMethod = "POST";
exports.addBulkCompetencyApiContentType = "application/json";
exports.addBulkCompetencyApiEndPoint = "/bx_block_assessmenttest/competancies/bulk";
// Customizable Area End