import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { runEngine } from "../../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { toast } from "react-toastify";
// Customizable Area End

export const configJSON = require("../config");

export interface Props {
  id?: string;
  location: any;
  history: any;
  navigation?: any;
  match: any;
  t: any;
}

export interface S {
  // Customizable Area Start
  page: number;
  loading: boolean;
  dataLength: number;
  rowsPerPage: number;
  client: string;
  project: string;
  openDialogName: string | null;
  search: string;
  userName: string;
  password: string;
  id: any;
  phoneNumber: string;
  managerName: string;
  email: string;
  data: any[];
  filterData: any[];
  tableData: any[];
  projectList: any[];
  clientList: any[];
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class CoDCManagerController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getCoDcManagerListApiCallId: string = "";
  getClientListApiCallId: string = "";
  getProjectListApiCallId: string = "";
  addCoDcManagerApiCallId: string = "";
  editCoDcManagerApiCallId: string = "";
  deleteCoDcManagerApiCallId: string = "";
  t: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      loading: false,
      page: 0,
      rowsPerPage: 20,
      dataLength: 0,
      client: "",
      project: "",
      search: "",
      openDialogName: "",
      userName: "",
      password: "",
      id: null,
      managerName: "",
      phoneNumber: "",
      email: "",
      data: [],
      tableData: [],
      filterData: [],
      clientList: [],
      projectList: [],
      // Customizable Area End
    };
    this.t = this.props.t;
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (apiRequestCallId && responseJson) {
        if (responseJson.status === 500) {
          toast.error(this.t("manageCoDcManager.toast.somethingWrong"));
          this.setState({
            loading: false
          });
        } else if (responseJson.message === "co-manager not found") {
          this.setState({
            filterData: [],
            data: [],
            tableData: [],
            loading: false,
          });
        } else {
          this.handelApiResponse(apiRequestCallId, responseJson);
        }
      }
    }
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    this.setState({ loading: true });
    this.getClientList();
    this.getTableDetail();
  }

  handelApiResponse = (apiRequestCallId: string, responseJson: any) => {
    if (Array.isArray(responseJson.errors)) {
      if (responseJson.errors[0].message) { toast.error(responseJson.errors[0].message) }
      else if (responseJson.errors[0].token) { toast.error(responseJson.errors[0].token) }
      else if (responseJson.errors[0].email) { toast.error("Email " + responseJson.errors[0].email) }
      else { toast.error(this.t("manageCoDcManager.toast.somethingWrong")) }
      this.setState({ loading: false });
    } else if (apiRequestCallId === this.getCoDcManagerListApiCallId) {
      this.handelTableDataApiResponse(responseJson);
    } else if (apiRequestCallId === this.getClientListApiCallId) {
      let clientList = responseJson.data.map((item: any) => {
        return {
          value: item.attributes.id,
          label: item.attributes.full_name
        };
      });
      this.setState({ clientList, loading: false });
    } else if (apiRequestCallId === this.getProjectListApiCallId) {
      const projectList = responseJson.data.map((item: any) => {
        return { label: item.attributes.name, value: item.attributes.id, }
      });
      this.setState({ projectList, loading: false });
    } else if (apiRequestCallId === this.addCoDcManagerApiCallId) {
      this.handelAddApiResponse(responseJson);
    } else if (apiRequestCallId === this.editCoDcManagerApiCallId) {
      this.handelEditApiResponse(responseJson);
    } else if (apiRequestCallId === this.deleteCoDcManagerApiCallId) {
      this.handelDeleteApiResponse();
    }
  };

  handelTableDataApiResponse = (responseJson: any) => {
    const data = responseJson.data.map((item: any) => {
      return {
        managerName: item.attributes.full_name,
        email: item.attributes.email,
        id: item.id,
        phoneNumber: item.attributes.phone_number,
        userName: item.attributes.user_name,
        password: item.attributes.password,
      };
    });

    this.setState({ tableData: data, filterData: data, loading: false }, () => {
      this.onChangeHandler(this.state.search, 0);
    });
  };

  handelAddApiResponse = (responseJson: any) => {
    toast.success(this.t("manageCoDcManager.toast.add"));
    let tableData = [...this.state.tableData];
    const obj = {
      id: responseJson.data.id,
      managerName: responseJson.data.attributes.full_name,
      userName: responseJson.data.attributes.user_name,
      phoneNumber: responseJson.data.attributes.phone_number,
      email: responseJson.data.attributes.email,
      password: responseJson.data.attributes.password,
    };
    tableData = [obj, ...tableData];
    this.setState(
      {
        tableData,
        filterData: tableData,
        phoneNumber: "",
        managerName: "",
        email: "",
        userName: "",
        password: "",
        id: null,
        openDialogName: null,
        loading: false,
      },
      () => {
        this.onChangeHandler(this.state.search, 0);
      }
    );
  };

  handelEditApiResponse = (responseJson: any) => {
    toast.success(this.t("manageCoDcManager.toast.edit"));
    let tableData = [...this.state.tableData];
    tableData = tableData.map((item: any) => {
      if (item.id === this.state.id) {
        return {
          id: item.id,
          phoneNumber: responseJson.data.attributes.phone_number,
          password: responseJson.data.attributes.password,
          email: responseJson.data.attributes.email,
          userName: responseJson.data.attributes.user_name,
          managerName: responseJson.data.attributes.full_name,
        };
      } else {
        return item;
      }
    });

    this.setState(
      {
        tableData,
        filterData: tableData,
        phoneNumber: "",
        userName: "",
        email: "",
        password: "",
        id: "",
        openDialogName: null,
        managerName: "",
        loading: false,
      },
      () => {
        this.onChangeHandler(this.state.search, this.state.page);
      }
    );
  };

  handelDeleteApiResponse = () => {
    toast.success(this.t("manageCoDcManager.toast.delete"));
    let tableData = [...this.state.tableData];
    tableData = tableData.filter((item: any) => item.id !== this.state.id);
    this.setState(
      {
        tableData,
        filterData: tableData,
        loading: false,
        openDialogName: null,
      },
      () => {
        this.onChangeHandler(this.state.search, this.state.page);
      }
    );
  };

  onApplyButtonClick = () => {
    if(this.state.client !== ""){
      this.setState({ loading: true });
      this.getTableDetail();
    }
  };

  onsortByValueChange = (client: string) => {
    if (client) {
      this.getProjectList(client);
    }
    if (client === undefined) {
      this.setState({
        client: client ?? "",
        project: "",
        projectList: []
      }, () => {
        this.getTableDetail()
      });
    }
    this.setState({ client: client ?? "", project: "", projectList: [] });
  };

  onChangeHandler = (search: string, page: number) => {
    const { filterData, rowsPerPage } = this.state;

    let data = filterData.filter((item: any) => {
      if (search === "") {
        return true;
      } else if (
        item.managerName.toString().toLowerCase().includes(search.toLowerCase()) ||
        item.userName.toString().toLowerCase().includes(search.toLowerCase()) ||
        item.phoneNumber?.toString().toLowerCase().includes(search.toLowerCase()) ||
        item.email.toString().toLowerCase().includes(search.toLowerCase())
      ) {
        return item;
      }
    });

    const dataLength = data.length;
    let totalPage = Math.ceil(dataLength / this.state.rowsPerPage);
    page = totalPage === page ? page - 1 : page;
    data = data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    this.setState({ data, dataLength, page, search });
  };

  shuffleArray = (array: any) => {
    for (let i = array.length - 1; i > 0; i--) {
      const dummyArray = new Uint8Array(1);
      const randomValue = crypto.getRandomValues(dummyArray)[0];
      const randomFloat = randomValue / Math.pow(2, 8);
      const j = Math.floor(randomFloat * (i + 1));
      let temp = array[i];
      array[i] = array[j];
      array[j] = temp;
    }
    return array;
  };

  generateUniquePassword = (passwordLength: number) => {
    const upperChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const numberChars = "0123456789";
    const specialChars = "!@#$%^&*)(:',.?/><";
    const lowerChars = "abcdefghijklmnopqrstuvwxyz";
    const allChars = numberChars + upperChars + lowerChars + specialChars;
    let randPasswordArray = Array(passwordLength);
    randPasswordArray[0] = numberChars;
    randPasswordArray[3] = specialChars;
    randPasswordArray[2] = lowerChars;
    randPasswordArray[1] = upperChars;
    randPasswordArray = randPasswordArray.fill(allChars, 4);
    return this.shuffleArray(
      randPasswordArray.map((x) => {
        const tempArr = new Uint8Array(1);
        const randomValue = crypto.getRandomValues(tempArr)[0];
        const randomFloat = randomValue / Math.pow(2, 8);
        return x[Math.floor(randomFloat * x.length)];
      })
    ).join("");
  };

  generateUsername = () => {
    return (
      this.state.managerName.substring(0, 3) + new Date().getDate().toString() + (new Date().getMonth() + 1).toString() + new Date().getHours().toString() +
      new Date().getMinutes().toString() +
      new Date().getMilliseconds().toString()
    );
  };

  onBlur = () => {
    if (!this.state.id && this.state.managerName) {
      const userName = this.generateUsername(); const password = this.generateUniquePassword(8);
      this.setState({ userName, password });
    }
  };

  getTableDetail = () => {

    const header = {
      "Content-Type": configJSON.coDcManagerListContentType,
      token: localStorage.getItem("accesToken"),
    };

    let url = `${configJSON.coDcManagerListApiEndPoint}`;
    if (this.state.client && this.state.project) {
      url += `?client_id=${this.state.client}&project_id=${this.state.project}`
    } else if (this.state.client !== "") {
      url += `?client_id=${this.state.client}`
    }

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.getCoDcManagerListApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), url);

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.coDcManagerListApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getClientList = () => {
    this.setState({ loading: true });

    const header = {
      "Content-Type": configJSON.clientListContentType,
      token: localStorage.getItem("accesToken"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getClientListApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.clientListApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.clientListApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header));
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getProjectList = (client: string) => {
    const header = {
      "Content-Type": configJSON.projectListContentType, token: localStorage.getItem("accesToken"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage));

    this.getProjectListApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.projectListApiEndPoint}/${client}/client_projects`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.projectListApiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  addCoDcManager = () => {
    this.setState({ loading: true });

    const header = {
      "Content-Type": configJSON.addCoDcManagerApiContentType,
      token: localStorage.getItem("accesToken"),
    };

    const data = {
      full_name: this.state.managerName,
      password: this.state.password,
      user_name: this.state.userName,
      phone_number: this.state.phoneNumber.length > 4 ? this.state.phoneNumber : "",
      email: this.state.email,
    };

    const httpBody = {
      data: {
        attributes: data,
        type: "co_manager",
      },
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addCoDcManagerApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addCoDcManagerApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.addCoDcManagerApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  editCoDcManager = () => {
    this.setState({ loading: true });

    const header = {
      "Content-Type": configJSON.editCoDcManagerApiContentType,
      token: localStorage.getItem("accesToken"),
    };

    const data = {
      phone_number: this.state.phoneNumber?.length > 4 ? this.state.phoneNumber : "",
      full_name: this.state.managerName,
      email: this.state.email,
      user_name: this.state.userName,
      password: this.state.password,
    };

    const httpBody = {
      data: {
        attributes: data,
        type: "co_manager",
      },
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.editCoDcManagerApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.editCoDcManagerApiEndPoint}/${this.state.id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.editCoDcManagerApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  deleteCoDcManager = () => {
    this.setState({ loading: true });

    const header = {
      "Content-Type": configJSON.deleteCoDcManagerApiContentType,
      token: localStorage.getItem("accesToken"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteCoDcManagerApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deleteCoDcManagerApiEndPoint}/${this.state.id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteCoDcManagerApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
}

// Customizable Area End
