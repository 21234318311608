// Customizable Area Start
import React from "react";
import {
  Box,
  Button,
  Grid,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  InputBase,
  DialogContent,
  Tooltip,
  Dialog,
} from "@material-ui/core";
import { styled } from "@material-ui/styles";
import moment from "moment";
import CloseIcon from "@material-ui/icons/Close";
import Select, { components } from "react-select";
import { Formik } from "formik";
import * as Yup from "yup";
import Spinner from "../../../components/src/Spinner.web";
import AppSidebar from "../../../components/src/AppSidebar.web";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import BorderColorOutlinedIcon from "@material-ui/icons/BorderColorOutlined";
import HighlightOffOutlinedIcon from "@material-ui/icons/HighlightOffOutlined";
import CloudUploadOutlined from "@material-ui/icons/CloudUploadOutlined";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Pagination from "@material-ui/lab/Pagination";
import ProjectsController from "./ProjectsController.web";
const LanguageData = localStorage.getItem("language") === "ar";
import Typography from "@material-ui/core/Typography";
import InsertDriveFileOutlined from '@material-ui/icons/InsertDriveFileOutlined';
import EditIconForSection from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import ReactQuill from "react-quill";
import "./Projects.web.css";
import { withRouter } from "react-router-dom";
export const uploadIcon = require("../assets/upload.png");
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
export const calender = require("../assets/calender.png");
import DoneIcon from "@material-ui/icons/Done";
import { withTranslation } from "react-i18next";
import { editIcon } from "./assets";
import { toast } from "react-toastify";

class CloneProject extends ProjectsController {
  projectTypeListClone = [
    {
      label: "Hybrid",
      value: "hybrid",
    },
    {
      label: "Virtual",
      value: "virtual",
    },
    {
      label: "Assessor",
      value: "assessor",
    },
  ];

  negativeMarkingListClone = [
    {
      label: "0.5",
      value: "0.5",
    },
    {
      label: "1",
      value: "1",
    },
    {
      label: "1.5",
      value: "1.5",
    },
    {
      label: "2",
      value: "2",
    },
  ];

  sortByList = [
    { label: "Status", value: "status" },
    { label: "Project", value: "name" },
  ];

  async componentDidMount(): Promise<void> {
    this.setState({ clonnedFrom_id: window.location.pathname.split("/")[2] });
    this.handleCloneProject(window.location.pathname.split("/")[2]);
    this.getFilteredClientsData();
    this.getCoManagersData();
    this.getAssessorData();
    this.getCompetencyData(`/`);
    this.getManagersData();
    this.getQuestionsData();
    const locationDataClone = this.props?.history?.location?.state?.attributes?.online_tools.map(
      (item: any) => {
        return {
          id: item.id.toString(),
          attributes: item,
          type: "online_tool",
        };
      }
    );
    const locationDataCloneassessor = this.props?.history?.location?.state?.attributes?.assessor_tools.map(
      (item: any) => {
        return {
          id: item.id.toString(),
          attributes: item,
          type: "assessor_lead_tools",
        };
      }
    );
    const QuestionForLocation = this.props?.history?.location?.state?.attributes?.questions.map(
      (item: any) => {
        return {
          ...item,
          attributes: { online_tool_id: item.online_tool_id },
        };
      }
    );
    let setTimeToOnlineTools = this.props?.history?.location?.state?.attributes
      ?.set_time_to_online_tools?.data;
    let timefortools = setTimeToOnlineTools.map((item: any) => {
      return {
        online_tool_id: Number(item.attributes.online_tool_id),
        hour: Number(item.attributes.hour),
        min: Number(item.attributes.min),
        id: Number(item.id),
      };
    });
    let changefortimer = setTimeToOnlineTools.map((item: any) => {
      return {
        label: item.attributes?.tool_name,
        hours: item.attributes?.hour,
        min: item.attributes?.min,
        value: item.attributes?.online_tool_id,
        online_tool_id: item.attributes?.online_tool_id,
        tool_name: item.attributes?.tool_name,
        errorMin: false,
        errorHours: false,
        id: item.attributes.id,
      };
    });
    const ProjectType = this.props?.history?.location?.state?.attributes
      ?.project_type;
    this.toolsApicall(ProjectType);
    this.setState({
      TimerForTools: timefortools,
      ChangeTimeOfTools: changefortimer,
      finalQuetionForApi: this.state.finalQuetionForApi.concat(
        QuestionForLocation
      ),
      projectType: ProjectType,
      selectedTools: [...locationDataClone, ...locationDataCloneassessor],
    });
  }

  async componentWillUnmount(): Promise<void> {
    if (!this.state.isProjectClonnedAndUpdated && this.state.clonning_id) {
      localStorage.setItem("deleteId", this.state.clonning_id.toString());
    }
  }

  containsDeviceId = (value: any) => {
    const { editProject } = this.state;
    if (editProject.name !== value) {
      return false;
    } else {
      return true;
    }
  };
  uploadFileChange = async (e: any, props: any) => {
    if (e.target.files[0].size > 50 * 1024 * 1024) { toast.warn(this.t("ProjectsController.Uploadvideowithsizelessthan50MB")) } else {
      if (e.target.files[0].type.split('/')[0] !== "video") { toast.warn(this.t("EditProject.AddCompanysection.toast.UploadValid", { value: 'video' })) } else {
        props.setFieldValue('uploadFile', [await this.getBase64(e.target.files[0])])
      }
    }
  }
  imageHandleChange = async (e: any, props: any) => {
    if (e.target.files[0].size > 50 * 1024 * 1024) { toast.warn(this.t("ProjectsController.Uploadvideowithsizelessthan50MB")) } else {
      if (e.target.files[0].type.split('/')[0] !== "image") { toast.warn(this.t("CloneProject.AddCompanysection.toast.UploadValid", { value: "image" })) } else { props.setFieldValue('uploadFile', [await this.getBase64(e.target.files[0])]) }
    }
  }
  editProjectSchema = () => {
    return Yup.object().shape({
      project_name: Yup.string()
        .test(
          this.t(
            "CloneProject.createNewProjectSchema.PleaseEnterDifferentProjectName"
          ),
          this.t(
            "CloneProject.createNewProjectSchema.PleaseEnterDifferentProjectName"
          ),
          (value) => !this.containsDeviceId(value)
        )
        .required(this.t("CloneProject.createNewProjectSchema.Projectname")),
      project_type: Yup.string().required(
        this.t("CloneProject.createNewProjectSchema.Projecttype")
      ),
      assessor_id: Yup.string().when(
        "project_type",
        (project_type: any, schema: any) => {
          if (project_type !== "virtual")
            return schema.required(
              this.t("CloneProject.createNewProjectSchema.Assessor")
            );
          return schema;
        }
      ),
      client_id: Yup.string()
        .nullable()
        .required(this.t("CloneProject.createNewProjectSchema.Client")),
      manager_id: Yup.string().required(
        this.t("CloneProject.createNewProjectSchema.Manager")
      ),
      coManager_id: Yup.string().required(
        this.t("CloneProject.createNewProjectSchema.Comanager")
      ),
      start_date: Yup.date()
        .min(
          new Date(),
          this.t(
            "CloneProject.createNewProjectSchema.StartDatetimeCannotBeInThePast"
          )
        )
        .required(this.t("CloneProject.createNewProjectSchema.Startdate")),
      end_date: Yup.date().when(
        ["start_date"],
        (start_date: any, schema: any) => {
          if (start_date) {
            const dayAfter = new Date(
              new Date(start_date).getTime() + 86400000
            );
            return schema
              .min(
                dayAfter,
                "*" +
                this.t(
                  "CloneProject.createNewProjectSchema.EndDateLessThenStartDate"
                )
              )
              .required(
                "*" +
                this.t("CloneProject.createNewProjectSchema.PleaseSelectDate")
              );
          }
          return schema.required(
            "*" + this.t("CloneProject.createNewProjectSchema.PleaseSelectDate")
          );
        }
      ),
      negative_marks: Yup.string().required(
        this.t("CloneProject.createNewProjectSchema.NegativeMarkRequired")
      ),
      competency_id: Yup.string().when(
        "project_type",
        (project_type: any, schema: any) => {
          if (project_type !== "virtual")
            return schema.required(
              this.t("CloneProject.createNewProjectSchema.Competency")
            );
          return schema;
        }
      ),
      pdfFile: Yup.array().required(
        this.t("CloneProject.createNewProjectSchema.DocumentsRequired")
      ),
    });
  };

  uploadDocumentRender = (props: any) => {
    return (
      <Grid item xs={6}>
        <Box>
          <Typography
            className="Projectlabel"
            variant="body2"
            style={{ marginBottom: "5px" }}
          >
            {this.t("CloneProject.TextField.UploadDocument")}
          </Typography>
          <Box className="dragElement" style={{ width: '100%' }}>
            <input
              id="pdf" multiple type="file" accept="application/pdf" data-testid="creaetedfileupload"
              name="pdf" style={{ display: "none" }}
              onChange={(e: any) => { this.AddPdfFile(e, props) }}
              onBlur={props.handleBlur}
            />
            <label htmlFor="pdf" style={{ width: "100%" }} className={this.state.dragActive ? "drag-active label-file-upload" : "label-file-upload"}>
              <Box textAlign="center" className='customUploadArea'>
                <div data-testid="centerTextcretae"><CloudUploadOutlined /><Box mt="15px">
                  {this.t(
                    "CloneProject.TextField.DropOrBrowseYourFileHere"
                  )}</Box>
                </div>
              </Box>
            </label>
            {this.state.dragActive && <div className="drag-file-element" onDragEnter={(event) => this.handleDrag(event)} onDragLeave={(event) => this.handleDrag(event)} onDragOver={(event) => this.handleDrag(event)} onDrop={(event) => this.handleDropFile(event, props, "pdf")}></div>}
          </Box>
          <Grid alignItems="flex-start" container spacing={1}>
            {this.state.pdfFile.map((fileObj: any, index: any) => {
              return (
                <div key={`${index + 1}`} className="viewUploadedFiles">
                  <a
                    href={fileObj.file}
                    target="_blank"
                    style={{ cursor: "pointer" }}
                  >
                    {fileObj.name}
                  </a>
                  <HighlightOffOutlinedIcon
                    data-testid='delete' id='delete'
                    onClick={() => { this.DeletePdfFile(props, index, fileObj); }} titleAccess="Delete" className="HighlightOffOutlinedIcon"
                  />
                </div>
              );
            })}
          </Grid>
          {this.getErrorMessage(props.touched, props.errors, "pdfFile")}
        </Box>
      </Grid>
    );
  };

  renderButtonContainer = () => {
    return (
      <Box className="ButtonContainer">
        {this.state.tools.sort((a: any, b: any) => { return a.attributes.name.toLowerCase().localeCompare(b.attributes.name.toLowerCase()) }).map((tool: any, index: any) => {
          return (
            <Box key={tool.id}>
              <Button
                onClick={() => this.handleTool(tool)}
                className={
                  this.state.selectedTools
                    .map((item: any) => item.id)
                    .includes(tool.id)
                    ? "SelectBtn"
                    : "UnSelectBtn"
                }
                data-testid={"toolNameBtn-" + tool.id}
                style={{ textTransform: "capitalize" }}
              >
                {tool.attributes.name}
              </Button>
            </Box>
          );
        })}
      </Box>
    );
  };

  imagechangeTrueClone = (ans: any) => {
    return ans.attributes.MediaType.includes("video") ? (
      <div className="videoRender">
        <video controls>
          <source src={ans.attributes.image_url.url} type="video/mp4" />
        </video>
      </div>
    ) : (
      <img
        width="100%"
        height="200px"
        alt="true"
        src={ans.attributes.image_url.url}
      />
    );
  };

  imagechangefalseClone = (ans: any) => {
    return ans.attributes.image_url?.url !== null &&
      ans.attributes.image_url?.url !== "undefined" &&
      typeof ans.attributes.image_url === "object" &&
      ans.attributes.image_url.type?.includes("video") ? (
      <video width="100%" height="200px" controls>
        <source src={ans.attributes.image_url?.url} type="video/mp4" />
      </video>
    ) : (
      ans.attributes.image_url.url !== null && (
        <img
          height="200px"
          width="100%"
          alt="flase"
          src={ans.attributes.image_url?.url}
        />
      )
    );
  };

  renderGrid4 = (props: any) => {
    return (
      <Grid container spacing={2}>
        {this.state.EditedAnswers?.map((ans: any, ansIndex: number) => {
          let color = ans?.attributes?.correct_answer ? "#dff8d1" : "";
          return (
            <Grid
              item
              key={ans?.id}
              data-testid={ans?.id}
              style={{ margin: "20px 0" }}
              xs={6}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <label
                  style={{
                    marginBottom: "10px",
                  }}
                  className="AddOption"
                >
                  {this.t("CloneProject.TextField.AddOption")}{" "}
                  {String.fromCharCode(65 + ansIndex)}
                </label>
                {ans.attributes.image_url?.url !== null && (
                  <div>
                    {color &&
                      <Checkbox
                        disabled
                        checked={true}
                        style={{ color: "#057605" }}
                      />
                    }
                    <Button
                      component="label"
                      size="small"
                      className="redButton"
                      variant="outlined"
                    >
                      {this.t("CloneProject.button.Edit")}{" "}
                      {String.fromCharCode(65 + ansIndex)}
                      <input
                        data-testid='videoUpload'
                        className="videoUpload"
                        onChange={(e: any) =>
                          this.HandelImageChange(e, ansIndex, ans)
                        }
                        type="file"
                        accept={
                          ans.attributes.image_url.type?.includes("video")
                            ? "video/*"
                            : "image/*"
                        }
                        style={{ display: "none" }}
                        id={`${ans?.id}`}
                      />
                    </Button>
                  </div>
                )}
              </div>
              {ans.attributes.imageChange
                ? this.imagechangeTrueClone(ans)
                : this.imagechangefalseClone(ans)}
              {ans.attributes.image_url.url === null && (
                <TextField
                  fullWidth
                  style={{ backgroundColor: color }}
                  onChange={(e) => this.handleAnswer(e, ansIndex)}
                  value={ans?.attributes?.answer}
                  variant="outlined"
                  className="QuetionTextField"
                />
              )}
            </Grid>
          );
        })}
      </Grid>
    );
  };

  renderQuetionpart = (toolsList: any, props: any) => {
    return (
      <Box style={{ marginTop: "3rem" }}>
        <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                cursor: "pointer",
                alignItems: "center",
                marginTop: "20px",
              }}
            >
              <Typography style={{ fontFamily: "ProximaNova-Bold", display: 'flex' }}>
                {this.t("CloneProject.TextField.SelectTool")}{' '}<span style={{ color: "red" }}>*</span>
              </Typography>
              {(this.state.projectType === "assessor") === false && (
                <div
                  className="SetTimerContainer"
                  onClick={this.TimerModelOpenClose}
                >
                  <BorderColorOutlinedIcon className="EditTimePen" />{" "}
                  <p>{this.t("CloneProject.TextField.SetTimer")}</p>
                </div>
              )}
            </div>
            {this.renderButtonContainer()}
          </Grid>
          {this.state.tools.length && this.state.selectedTools.length > 0 && (
            <Box
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "3rem",
              }}
            >
              <Box>
                <Typography data-testid="Questionaires">
                  {this.t(
                    "CloneProject.TextField.QuestionairesFromTheRepository"
                  )}
                </Typography>
              </Box>
            </Box>
          )}
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            data-testid="selectedQuestions"
          >
            {this.state.selectedTools.length > 0 &&
              this.state.selectedQuestions
                ?.slice(
                  this.state.page * this.state.rowsPerPage,
                  this.state.page * this.state.rowsPerPage +
                  this.state.rowsPerPage
                )
                .map((items: any, index: any) => {
                  return (
                    <div className="ContainerQuetion" key={items?.id}>
                      <Accordion
                        expanded={this.state.isExpendQue === items?.id}
                        style={{
                          marginBottom: "10px",
                          backgroundColor: "#eff6ff",
                        }}
                      >
                        <AccordionSummary
                          expandIcon={false}
                          id="panel1bh-header"
                          aria-controls="panel1bh-content"
                          className="panel1bh-header"
                        >
                          <div
                            className="AcorenHeading"
                            data-testid={"AcorenHeading" + items?.id}
                          >
                            <div className="labelAcordin" style={{ backgroundColor: `${items?.attributes?.online_tool?.tool_color === null ? "#ffd9d8" : items?.attributes?.online_tool?.tool_color}` }}>
                              <h5>{items?.attributes?.online_tool?.name}</h5>
                            </div>
                            <div className="checkBoxAndQueHeading">
                              <div className="InputContainer">
                                <Checkbox
                                  checked={this.state.finalQuetionForApi
                                    .map((item: any) => Number(item.id))
                                    .includes(Number(items.id))}
                                  inputProps={{
                                    "aria-label": "Checkbox demo",
                                  }}
                                  onChange={(event: any) =>
                                    this.handleCheck(event, items)
                                  }
                                />
                              </div>
                              {this.state.isExpendQue === items?.id ? (
                                <Grid container data-testid={items?.id}>
                                  <Grid
                                    item
                                    xs={12}
                                    style={{ marginTop: "20px" }}
                                  >
                                    <div className="MarksContainer">
                                      <h5>
                                        {this.t(
                                          "CloneProject.TextField.Question"
                                        )}
                                      </h5>
                                      {items?.attributes?.mark !== null && (
                                        <h5>
                                          {this.t(
                                            "CloneProject.TextField.Mark"
                                          )}{" "}
                                          {items?.attributes?.mark}
                                        </h5>
                                      )}
                                    </div>
                                    <TextField
                                      fullWidth
                                      onChange={(e: any) =>
                                        this.ChnageQueText(e)
                                      }
                                      variant="outlined"
                                      style={{ width: "100%" }}
                                      className="QuetionTextField"
                                      value={this.state.QuestionText}
                                    />
                                  </Grid>
                                </Grid>
                              ) : (
                                <div
                                  className="TextandButton"
                                  data-testid={"TextandButton" + items?.id}
                                >
                                  <div className="QuetionRender">
                                    <div>
                                      <h3
                                        data-testid={
                                          "QuetionRender" + items?.id
                                        }
                                        style={{
                                          alignSelf: "center",
                                          verticalAlign: "middle",
                                        }}
                                      >
                                        {this.t(
                                          "CloneProject.TextField.Question"
                                        )}{" "}
                                        {20 * (this.state.page + 1 - 1) +index +1}
                                      </h3>
                                    </div>
                                    <div className="quetions">
                                      <h4>{items?.attributes?.name}</h4>
                                    </div>
                                  </div>
                                  <div style={{display:"grid",placeItems:"center"}}>
                                    <Button
                                      onClick={() => this.AcordinOpen(items)}
                                      style={{
                                        marginRight: "15px",
                                        border: "1px solid #f94b4c",
                                        minWidth: "130px",
                                        borderRadius: "10px",
                                        color: "#f94b4c",
                                        textTransform: "capitalize"
                                      }}
                                    >
                                      {this.t("CloneProject.button.View")}
                                    </Button>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid container>
                            <Grid item style={{ margin: "20px 0" }} xs={6}>
                              <label>
                                {this.t(
                                  "CloneProject.TextField.SelectOnlineTool"
                                )}
                              </label>
                              <Select
                                onChange={(e) => {
                                  this.toolIdchnage(e);
                                }}
                                value={
                                  toolsList.filter(
                                    (item: any) =>
                                      item.value ===
                                      this.state.CurrentEditQuetion?.attributes?.online_tool_id.toString()
                                  )[0]
                                }
                                placeholder="Industry"
                                classNamePrefix="react-select"
                                isSearchable={false}
                                options={toolsList}
                              />
                            </Grid>
                            {this.renderGrid4(props)}
                            <Grid container>
                              <Grid
                                item
                                style={{
                                  margin: "20px 0",
                                  display: "flex",
                                  justifyContent: "flex-end",
                                }}
                                xs={12}
                              >
                                <Button
                                  onClick={this.editQuestion}
                                  style={{
                                    marginRight: "20px",
                                    color: "white",
                                    backgroundColor: "#f94b4c",
                                    textTransform: "capitalize"
                                  }}
                                >
                                  {this.t("CloneProject.button.Edit")}
                                </Button>
                                <Button
                                  onClick={() => this.AcordinOpen(items)}
                                  style={{
                                    marginRight: "20px",
                                    color: "white",
                                    backgroundColor: "#bebebe",
                                    textTransform: "capitalize"
                                  }}
                                >
                                  {this.t("CloneProject.button.Cancel")}
                                </Button>
                              </Grid>
                            </Grid>
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  );
                })}
          </Grid>
          {this.state.tools.length > 0 && this.state.selectedTools.length > 0 && (
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <div
                data-testid="PaginationClone"
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <div
                  className="table-Pagination-Data"
                  data-testid="PaginationCloneDiv"
                >
                  <Pagination
                    count={Math.ceil(this.state.selectedQuestions?.length / this.state.rowsPerPage)}
                    onChange={(event: unknown, newPage: number) =>
                      this.setState({
                        ...this.state,
                        page: newPage - 1,
                      })
                    }
                    dir="ltr"
                    variant="outlined"
                    showFirstButton
                    shape="rounded"
                    page={this.state.page === 0 ? 1 : this.state.page + 1}
                    showLastButton
                    siblingCount={0}
                    boundaryCount={1}
                  />
                </div>
              </div>
            </Grid>
          )}
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "10px",
            }}
          >
            <Button
              style={{
                backgroundColor: "#f94b4c",
                color: "white",
                fontFamily: "ProximaNova-Regular !important",
                marginRight: !LanguageData ? "12px" : "",
                marginLeft: LanguageData ? "12px" : "",
                textTransform: "capitalize"
              }}
              data-testid="createProjectBtn"
              type="submit"
            >
              {this.t("CloneProject.button.SAVE")}
            </Button>
            <Button
              style={{
                backgroundColor: "#bebebe",
                fontFamily: "ProximaNova-Regular !important",
                color: "white",
                textTransform: "capitalize"
              }}
              onClick={this.handleCancel}
            >
              {this.t("CloneProject.button.Cancel")}
            </Button>
          </Grid>
        </Grid>
      </Box>
    );
  };

  GridForMarks = (props: any) => {
    return (
      <Grid item xs={6}>
        <Box>
          <Typography
            style={{
              display: "flex",
              marginBottom: "5px",
              justifyContent: "space-between",
            }}
            variant="body2"
          >
            <span className="Projectlabel">
              {this.t("CloneProject.TextField.NegativeMarking")}?
              <span style={{ color: "red" }}>*</span>
            </span>
            <RadioGroup
              aria-label="isNegative"
              value={this.state.isNegative}
              name="isNegative"
              onChange={(event: any) => {
                props.setFieldValue("isNegative", event.target.value);
                this.setState({
                  isNegative: (event.target as HTMLInputElement).value,
                });
                if (this.state.isNegative === "No") {
                  props.setFieldValue("negative_marks", 0);
                  this.setState({ negative_marks: 0 });
                }
              }}
              style={{ flexDirection: "row" }}
            >
              <FormControlLabel
                value="Yes"
                control={<Radio />}
                className="radio"
                label="Yes"
              />
              <FormControlLabel
                value="No"
                control={<Radio />}
                label="No"
                className="radio"
              />
            </RadioGroup>
          </Typography>
          <Select
            name="negative_marks"
            placeholder="0"
            classNamePrefix="react-select"
            isDisabled={this.state.isNegative === "No" ? true : false}
            isSearchable={false}
            options={this.negativeMarkingListClone}
            onChange={(event: any) => {
              props.setFieldValue("negative_marks", event.value);
              this.setState({ negative_marks: event.value });
            }}
            value={
              this.state.isNegative === "No"
                ? { label: "0", value: "0" }
                : {
                  label: props.values.negative_marks,
                  value: props.values.negative_marks,
                }
            }
          />
          {props.touched.negative_marks && props.errors.negative_marks && (
            <div className="text-danger">{props.errors.negative_marks}</div>
          )}
        </Box>
        <Box mt="20px">
          <Typography
            className="Projectlabel"
            variant="body2"
            style={{ marginBottom: "5px" }}
          >
            {this.t("CloneProject.TextField.SelectCompetency")}
            <span style={{ color: "red" }}>*</span>
          </Typography>
          <Select
            className="competency"
            placeholder="Competency"
            menuIsOpen={this.state.menuOpen}
            classNamePrefix="react-select"
            options={this.state.competencyIds}
            isMulti={true}
            allowSelectAll={true}
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            components={{ Option }}
            value={this.state.competencyIds?.filter(
              (option: any) =>
                !!this.state.competencyId?.find(
                  (ele: string) => ele.toString() === option.value
                )
            )}
            isDisabled={this.state.projectType === "virtual" ? true : false}
            openMenuOnClick={true}
            onBlur={(event: any) => {
              this.setState({ menuOpen: false });
            }}
            onFocus={(event: any) => {
              this.setState({ menuOpen: true });
            }}
            onChange={(event: any) => {
              let ids = [];
              if (event) {
                ids = event.map((obj: any) => {
                  return obj.value;
                });
              }
              props.setFieldValue("competency_id", ids);
              this.setState({ competencyId: ids });
            }}
            onEditOptionClicked={(obj: any) => {
              this.handleCloneCompetency(obj.value);
            }}
          />
          {props.touched.competency_id && props.errors.competency_id && (
            <div className="text-danger">{props.errors.competency_id}</div>
          )}
          <Box>
            {(this.state.projectType === "assessor" ||
              this.state.projectType === "hybrid") && (
                <div
                  onClick={() =>
                    this.handleScoreModal("clone", this.state.setIncrement)
                  }
                  className="SetRatingContainer"
                  data-testid="SetRatingContainerClone"
                >
                  <BorderColorOutlinedIcon className="EditTimePen" />{" "}
                  <p>{this.t("CloneProject.TextField.SetScoreRating")}</p>
                </div>
              )}
          </Box>
        </Box>
      </Grid>
    );
  };

  EndDateItem = (props: any) => {
    return (
      <Grid item xs={6}>
        <Box>
          <Typography
            className="Projectlabel"
            variant="body2"
            style={{ marginBottom: "5px" }}
          >
            {this.t("CloneProject.TextField.EndDate")}
            <span style={{ color: "red" }}>*</span>
          </Typography>
          <DatePicker
            name="end_date"
            id="end_date"
            value={moment(props.values.end_date).format("DD/MM/YYYY HH:MM a")}
            onChange={(date: any) => {
              props.setFieldValue("end_date", date);
            }}
            onBlur={props.handleBlur}
            customInput={<CustomInput />}
            showYearDropdown
            showMonthDropdown
            minDate={new Date()}
            dropdownMode="select"
            placeholderText="DD/MM/YYYY HH:MM a"
            data-testid="endDate"
            timeInputLabel="Time:"
            dateFormat="DD/MM/YYYY HH:MM a"
            peekNextMonth
            showTimeInput
          />
          {props.touched.end_date && props.errors.end_date && (
            <div className="text-danger">{props.errors.end_date}</div>
          )}
        </Box>
      </Grid>
    );
  };

  renderProjectName = (props: any) => {
    return (
      <Grid item xs={6}>
        <Typography
          className="Projectlabel"
          style={{ marginBottom: "5px" }}
          variant="body2"
        >
          {this.t("CloneProject.TextField.ProjectName")}
          <span style={{ color: "red" }}>*</span>
        </Typography>
        <Box className="textfield-parent">
          <TextField
            id="project_name"
            name="project_name"
            value={props.values.project_name}
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            placeholder={this.t("CloneProject.TextField.EnterProjectName")}
            variant="outlined"
            className="client-textfields"
            fullWidth
          />
          {props.errors.project_name && props.touched.project_name ? (
            <p className="validation_errors">{props.errors.project_name}</p>
          ) : null}
        </Box>
      </Grid>
    );
  };

  renderProjectType = (props: any) => {
    const { projectType } = this.state;
    return (
      <Grid item xs={6}>
        <Box>
          <Typography
            className="Projectlabel"
            style={{ marginBottom: "5px" }}
            variant="body2"
          >
            {this.t("CloneProject.TextField.ProjectType")}
            <span style={{ color: "red" }}>*</span>
          </Typography>
          {projectType && (
            <Select
              name="project_type"
              classNamePrefix="react-select"
              placeholder={this.t("CloneProject.TextField.SelectProjectType")}
              isSearchable={false}
              options={this.projectTypeListClone}
              onChange={(event: any) => {
                props.setFieldValue("project_type", event.value);
                this.setState({ projectType: event.value });
                this.toolsApicall(event.value);
                this.ProjectTypeChnageInClone(event)
              }}
              value={
                this.projectTypeListClone.filter((option: any) => {
                  return option.value === projectType;
                })[0]
              }
            />
          )}
          {props.touched.project_type && props.errors.project_type && (
            <div className="text-danger">{props.errors.project_type}</div>
          )}
        </Box>
      </Grid>
    );
  };

  renderDCmanager = (props: any) => {
    return (
      <Grid item xs={6}>
        <Typography
          className="Projectlabel"
          style={{ marginBottom: "5px" }}
          variant="body2"
        >
          {this.t("CloneProject.TextField.DCManager")}
          <span style={{ color: "red" }}>*</span>
        </Typography>
        <Select
          classNamePrefix="react-select"
          placeholder={this.t("CloneProject.TextField.DCManager")}
          isSearchable
          value={
            this.state.managerList.filter(
              (option: any) => option.value == this.state.manager_id
            )[0]
          }
          onChange={(event: any) => {
            props.setFieldValue("manager_id", event.value);
            this.setState({ manager_id: event.value });
          }}
          options={this.state.managerList}
        />
        {props.touched.manager_id && props.errors.manager_id && (
          <div className="text-danger">{props.errors.manager_id}</div>
        )}
      </Grid>
    );
  };

  renderCoManager = (props: any) => {
    const { editProject } = this.state;
    return (
      <Grid item xs={12} sm={6}>
        <Typography
          className="Projectlabel"
          style={{ marginBottom: "5px" }}
          variant="body2"
        >
          {this.t("CloneProject.TextField.CoManager")}
          <span style={{ color: "red" }}>*</span>
        </Typography>
        <Select
          classNamePrefix="react-select"
          isSearchable
          placeholder={this.t("CloneProject.TextField.CoManager")}
          options={this.state.coManagerList}
          value={
            this.state.coManagerList.filter(
              (option: any) => option.value == this.state.coManager_id
            )[0]
          }
          onChange={(event: any) => {
            this.setState({ coManager_id: event.value });
            props.setFieldValue("coManager_id", event.value);
          }}
        />
        {props.touched.coManager_id && props.errors.coManager_id && (
          <div className="text-danger">{props.errors.coManager_id}</div>
        )}
        {this.state.coManagerList.filter((option: any) => {
          return option.value === editProject.co_manager_id;
        })}
      </Grid>
    );
  };

  renderAssessor = (props: any) => {
    const { assessor_id } = this.state;

    return (
      <Grid item xs={12} sm={6}>
        <Typography
          className="Projectlabel"
          style={{ marginBottom: "5px" }}
          variant="body2"
        >
          {this.t("CloneProject.TextField.SelectAssessor")}
          <span style={{ color: "red" }}>*</span>
        </Typography>
        <Select
          classNamePrefix="react-select"
          isMulti={true}
          placeholder={this.t("CloneProject.TextField.SelectAssessor")}
          isSearchable={true}
          options={this.state.assessorList}
          isDisabled={this.state.projectType === "virtual" ? true : false}
          value={this.state.assessorList.filter(
            (option: any) =>
              !!assessor_id.find((ele: string) => ele === option.value)
          )}
          onChange={(event: any) => {
            let assessorIds = [];
            if (event) {
              assessorIds = event.map((obj: any) => {
                return obj.value;
              });
            }
            this.setState({ assessor_id: assessorIds });
            props.setFieldValue("assessor_id", assessorIds);
          }}
        />
        {props.touched.assessor_id && props.errors.assessor_id && (
          <div className="text-danger">{props.errors.assessor_id}</div>
        )}
      </Grid>
    );
  };

  renderClient = (props: any) => {
    return (
      <Grid item xs={12} sm={6}>
        <Typography
          className="Projectlabel"
          variant="body2"
          style={{ marginBottom: "5px" }}
        >
          {this.t("CloneProject.TextField.SelectClient")}
          <span style={{ color: "red" }}>*</span>
        </Typography>
        <Select
          placeholder={this.t("CloneProject.TextField.SelectClient")}
          isSearchable
          options={this.state.clientsList}
          classNamePrefix="react-select"
          value={
            this.state.clientsList.filter(
              (option: any) => option.value == this.state.client_id
            )[0]
          }
          onChange={(event: any) => {
            props.setFieldValue("client_id", event.value);
            this.setState({ client_id: event.value });
          }}
        />
        {props.touched.client_id && props.errors.client_id && (
          <div className="text-danger">{props.errors.client_id}</div>
        )}
      </Grid>
    );
  };

  renderStartDate = (props: any) => {
    return (
      <Grid item xs={12} sm={6}>
        <Typography
          className="Projectlabel"
          variant="body2"
          style={{ marginBottom: "5px" }}
        >
          {this.t("CloneProject.TextField.StartDate")}
          <span data-testid="StartDate" style={{ color: "red" }}>
            *
          </span>
        </Typography>
        <DatePicker
          name="start_date"
          id="start_date"
          value={moment(props.values.start_date).format("DD/MM/YYYY HH:MM a")}
          onChange={(date: any) => {
            props.setFieldValue("start_date", date);
          }}
          onBlur={props.handleBlur}
          customInput={<CustomInput />}
          peekNextMonth
          showMonthDropdown
          showYearDropdown
          minDate={new Date()}
          dropdownMode="select"
          placeholderText="DD/MM/YYYY HH:MM a"
          data-testid="startDate"
          dateFormat="DD/MM/YYYY HH:MM a"
          timeInputLabel="Time:"
          showTimeInput
        />

        {props.touched.start_date && props.errors.start_date && (
          <div className="text-danger">{props.errors.start_date}</div>
        )}
      </Grid>
    );
  };

  RenderSetTimerModel = () => {
    return (
      <DeleteDialog
        fullWidth={false}
        scroll="body"
        onClose={this.TimerModelOpenClose}
        open={this.state.SetTimeModel}
        aria-describedby="scroll-dialog-description"
        aria-labelledby="scroll-dialog-title"
      >
        <DialogContent style={{ padding: "0px", overflow: "hidden" }}>
          <div className="firstDiv" data-testid="clonefirstDiv">
            <Box
              style={{
                paddingTop: "0px !important",
                textAlign: "right",
              }}
            >
              <CloseIcon
                onClick={this.TimerModelOpenClose}
                style={{
                  cursor: "pointer",
                  fontSize: "35px",
                  color: "#787878",
                }}
              />
            </Box>
            <Box padding={{ xs: "15px 25px" }} data-testid="clonefirstDiv3">
              <Box
                id="modal-title"
                fontWeight="bolder"
                fontSize={{ xs: "20px", sm: "30px" }}
                fontFamily="ProximaNova-Bold"
              >
                {this.t("CloneProject.TextField.SetTimer")}
              </Box>
            </Box>
            <Box padding={{ xs: "15px 25px" }}>
              <Grid container>
                <Grid item xs={12}>
                  <label className="labels" data-testid="clonefirstDiv4">
                    {this.t("CloneProject.TextField.SelectToolName")}
                    <span style={{ color: "#f94b4b" }}>*</span>
                  </label>
                  <Box className="textfield-parent">
                    <Select
                      classNamePrefix="react-select"
                      placeholder={this.t(
                        "CloneProject.TextField.SelectToolName"
                      )}
                      options={this.state.ChangeTimeOfTools}
                      className="SelectToolName"
                      value={
                        this.state.CurrentToolName === "" 
                        ? this.state.ChangeTimeOfTools[0]: this.state.tools.filter( (option: any) =>
                            option.value === this.state.CurrentToolName
                        )[0]
                      }
                      isSearchable={false}
                      onChange={(event: any, index: any) => {
                        this.setState({
                          CurrentToolName: event.value,
                          CurrentToolIndex: this.state.ChangeTimeOfTools.map(
                            (item: any) => item.online_tool_id
                          ).indexOf(event.value),
                        });
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box padding={{ xs: "15px 25px" }}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <label className="labels" data-testid="clonefirstDiv45">
                    {this.t("CloneProject.TextField.SetTimer")}{" "}<span style={{ fontSize: "10px", fontFamily: "ProximaNova-Regular", color: "red" }}>{`(${this.t("ProjectsController.Max60minutesallowed")})`}</span>
                  </label>
                </Grid>
                <Grid item xs={12}>
                  <div
                    className="ModelMainDiv"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <div data-testid="clonefirstDiv232323">
                      <TextField
                        variant="outlined"
                        type="number"
                        InputProps={{ inputProps: { min: 0, max: 60 } }}
                        onChange={(e: any) => this.ChangeToolsTime(e, "min")}
                        InputLabelProps={{
                          style: {
                            fontFamily: "ProximaNova-Regular",
                            zIndex: 0,
                          },
                        }}
                        value={
                          this.state?.ChangeTimeOfTools[
                            this.state.CurrentToolIndex
                          ]?.min
                        }
                        error={
                          this.state?.ChangeTimeOfTools[
                            this.state.CurrentToolIndex
                          ]?.errorMin ||
                          this.state?.ChangeTimeOfTools[
                            this.state.CurrentToolIndex
                          ]?.min === ""
                        }
                        label={this.t("CloneProject.TextField.Min")}
                      />
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                    data-testid="clonefirstDiv23232132"
                  >
                    <Button onClick={this.TimerModelOpenClose}
                      style={{ textTransform: "capitalize", fontFamily: "ProximaNova-Regular !important", }}
                      className="cancelBtn"
                      size="large">
                      {this.t("CloneProject.button.Cancel")}
                    </Button>
                    <Button
                      type="submit"
                      data-testid="createProjectBtn"
                      style={{
                        backgroundColor: "#f94b4c", color: "white",
                        textTransform: "capitalize",
                        fontFamily: "ProximaNova-Regular !important",
                      }}
                      onClick={this.TimerSave}>{this.t("CloneProject.button.SAVE")}</Button>
                  </div>
                </Grid> </Grid> </Box> </div> </DialogContent>
      </DeleteDialog>
    );
  };

  EditCompetencyDialog = () => {
    return (
      <Dialog
        open={this.state.openDialogName === "Edit"}
        fullWidth={false}
        maxWidth={"sm"}
        scroll="body"
        transitionDuration={0}
        aria-describedby="scroll-dialog-description"
        aria-labelledby="scroll-dialog-title"
        PaperProps={{
          style: {
            width: "500px",
          },
        }}
      >
        <DialogContent style={{ width: "100%", padding: "0px" }}>
          <Box
            style={{
              margin: "10px 10px 0px 0px",
              textAlign: "right",
            }}
          >
            <CloseIcon
              onClick={() => this.deleteCompetency()}
              style={{
                fontSize: "35px",
                cursor: "pointer",
                color: "#787878",
              }}
            />
          </Box>
          <Box padding={{ xs: "15px 25px 25px", sm: "15px 40px 40px" }}>
            <Box
              id="modal-title"
              fontSize={{ xs: "20px", sm: "30px" }}
              fontWeight="bolder"
              fontFamily="ProximaNova-Bold"
              color="#111"
              mb="20px"
              style={{
                textAlign: LanguageData? "right": "unset"
              }}
            >
              {this.t("CloneProject.TextField.EditCompetency")}
            </Box>
            <Formik
              validationSchema={this.competencySchema}
              initialValues={{
                competencyName: this.state.competencyName,
              }}
              onSubmit={(values) => {
                let currentList = this.state.competencyIds.filter(
                  (item: any) => item.label === values.competencyName
                );
                if (currentList.length === 0) {
                  this.editCompetency(values);
                } else {
                  toast.error(
                    this.t("CloneProject.ToastMessage.DifferentName")
                  );
                }
              }}
              validateOnChange={true}
              validateOnBlur={true}
            >
              {({ errors, touched, setFieldValue, handleSubmit }) => (
                <form
                  id="my-form"
                  autoComplete="off"
                  data-testid="cloneformcompetency"
                  onSubmit={handleSubmit}
                  noValidate
                >
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <div
                        style={{
                          marginBottom: "5px",
                          fontSize: "18px",
                          textAlign: LanguageData? "right": "unset"
                        }}
                      >
                        {this.t("CloneProject.TextField.CompetencyName")}
                        <span style={{ color: "red" }}>*</span>
                      </div>
                      <CustomInputBase
                        fullWidth
                        name="competencyName"
                        id="competencyName"
                        value={this.state.competencyName}
                        onChange={(event: any) => {
                          this.setState({
                            competencyName: event.target.value,
                          });
                          setFieldValue("competencyName", event.target.value);
                        }}
                        placeholder={this.t(
                          "CloneProject.TextField.CompetencyName"
                        )}
                      />
                      {touched.competencyName && errors.competencyName && (
                        <div className="text-danger">
                          {errors.competencyName}
                        </div>
                      )}
                    </Grid>
                  </Grid>
                  <Box mt="30px" display="flex" justifyContent="end">
                    <CancelButton onClick={() => this.deleteCompetency()} className="cancelBtn" data-testid="cancelBtnCreateProject">
                      {this.t("CloneProject.button.Cancel")}
                    </CancelButton>
                    <SaveButton style={{ marginRight : LanguageData ? "15px" : "unset"}} type="submit" form="my-form">
                      {this.t("CloneProject.button.SAVE")}
                    </SaveButton>
                  </Box>
                </form>
              )}
            </Formik>
          </Box>
        </DialogContent>
      </Dialog>
    );
  };

  RatingdialogReder = () => {
    return (
      <Dialog
        maxWidth={"sm"}
        scroll="body"
        fullWidth={false}
        className="setScoreRating"
        transitionDuration={0}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        open={this.state.openDialogName === "scoreRating"}
        PaperProps={{
          style: {
            width: "600px",
          },
        }}
      >
        <DialogContent style={{ padding: "0px", width: "100%" }}>
          <Box
            style={{
              textAlign: "right",
              margin: "10px 10px 0px 0px",
            }}
          >
            <CloseIcon
              onClick={() =>
                this.setState({
                  competencyId: [],
                  competencyName: "",
                  openDialogName: null,
                  rangeHalfData: [],
                  rangeOneData: [],
                })
              }
              style={{
                cursor: "pointer",
                fontSize: "35px",
                color: "#787878",
              }}
            />
          </Box>
          <Box padding={{ xs: "15px 25px 25px", sm: "15px 40px 40px" }}>
            <Box
              id="modal-title"
              fontWeight="bolder"
              fontSize={{ xs: "20px", sm: "30px" }}
              fontFamily="ProximaNova-Bold"
              mb="20px"
              color="#111"
            >
              {this.t("CloneProject.TextField.SetScoreRating")}
            </Box>
            <form
              id="my-form"
              onSubmit={this.onSubmitRating}
              noValidate
              autoComplete="off"
            >
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <Box>
                    <Typography variant="body2" style={{ marginBottom: "5px" }}>
                      {this.t("CloneProject.TextField.SetIncrement")}
                      <span data-testid="SetIncrement" style={{ color: "red" }}>
                        *
                      </span>
                    </Typography>
                    <Select
                      name="setIncrement"
                      value={
                        this.incrementList.filter(
                          (option: any) =>
                            option.value == this.state.setIncrement
                        )[0]
                      }
                      options={this.incrementList}
                      onChange={(event: any) => {
                        this.setState({
                          setIncrement: event.value,
                          rating:
                            event.value === "0.5"
                              ? this.state.rangeHalfData[0]?.value
                              : this.state.rangeOneData[0]?.value,
                        });
                      }}
                      isSearchable={false}
                    />
                  </Box>
                </Grid>
              </Grid>
              {this.state.setIncrement === "1" && (
                <>
                  {this.state.rangeOneData.map((item: any) => {
                    return (
                      <Grid
                        key={item.label}
                        style={{
                          color: "#8a8888",
                          margin: "20px 0",
                          fontSize: "12px",
                        }}
                      >
                        {item.label}
                        {" - "}
                        {item.description}
                      </Grid>
                    );
                  })}
                </>
              )}
              {this.state.setIncrement === "0.5" && (
                <>
                  {this.state.rangeHalfData.map((item: any) => {
                    return (
                      <Grid
                        style={{
                          color: "#8a8888", margin: "20px 0", fontSize: "12px",
                        }}
                        key={item.label}
                      >
                        {item.label}
                        {" - "}
                        {item.description}
                      </Grid>
                    );
                  })}
                </>
              )}
              <Grid container spacing={3}>
                <Grid
                  item xs={12}
                  sm={6}>
                  <Box>
                    <Typography
                      variant="body2"
                      style={{ marginBottom: "5px" }}>
                      {this.t("CloneProject.TextField.Rating")}
                      <span style={{ color: "red" }}>*</span>
                    </Typography>
                    <Select
                      className="rating-dropdown"
                      classNamePrefix="react-select"
                      isSearchable={false}
                      name="rating"
                      value={
                        this.state.setIncrement === "0.5"
                          ? this.state.rangeHalfData.filter(
                            (option: any) => option.value == this.state.rating
                          )[0]
                          : this.state.rangeOneData.filter(
                            (option: any) => option.value == this.state.rating
                          )[0]
                      }
                      onChange={(event: any) => {
                        this.setState({ rating: event.value });
                      }}
                      options={
                        this.state.setIncrement === "0.5"
                          ? this.state.rangeHalfData
                          : this.state.rangeOneData
                      }
                    />
                  </Box>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography variant="body2" style={{ marginBottom: "5px" }}>
                    {this.t("CloneProject.TextField.Description")}
                    <span data-testid="Description" style={{ color: "red" }}>
                      *
                    </span>
                  </Typography>
                  <CustomTwoInputBase
                    value={this.handleRatingsDescription()}
                    multiline
                    minRows={4}
                    fullWidth
                    placeholder={this.t(
                      "CloneProject.TextField.WriteDescription"
                    )}
                    onChange={this.handleProjectRatings}
                  />
                </Grid>
              </Grid>
              <Box mt="30px" display="flex" justifyContent="end">
                <CancelButton
                  className="cancelBtn"
                  onClick={() =>
                    this.setState({
                      isCancelClicked: true,
                      openDialogName: "",
                      rangeHalfData: [],
                      rangeOneData: [],
                    })
                  }
                >
                  {this.t("CloneProject.button.Cancel")}
                </CancelButton>
                <SaveButton style={{ marginRight: LanguageData ? "15px" : "unset" }} form="my-form" type="submit">
                  {this.t("CloneProject.button.SAVE")}
                </SaveButton>
              </Box>
            </form>
          </Box>
        </DialogContent>
      </Dialog>
    );
  };

  sectionuploadSchema = () => {
    return Yup.object().shape({
      heading: Yup.string().required(this.t("CloneProject.sectionuploadSchema.Headingisrequired")),
      title: Yup.string().required(this.t("CloneProject.sectionuploadSchema.Titleisrequired")),
      content: Yup.string().required(this.t("CloneProject.sectionuploadSchema.Contentisrequired")),
      uploadFile: Yup.array().required(this.t("CloneProject.sectionuploadSchema.UploadFileisrequired")),
    });
  };

  OnSubmitFuncationForFormik = (values: any) => {
    if (this.state.ParentContentData.length === 0 || this.state.CompanySection.length === 0) {
      if (this.state.ParentContentData.length === 0) {
        toast.warn(this.t("CreateNewProject.AddCompanysection.toast.addsection"))
      }
      if (this.state.CompanySection.length === 0) {
        toast.warn(this.t("CreateNewProject.AddCompanysection.toast.AddSubSection"))
      }
    } else {
      const id = this.state.clonning_id;
      this.setState({ isProjectClonnedAndUpdated: true });
      if (this.state.projectType === "assessor") {
        this.handleUpdateProjectMethod(id, values);
      } else {
        if (this.state.finalQuetionForApi.length === 0) {
          toast.warn(this.t("ProjectsController.PleaseAddQuestions"));
        } else {
          this.CloneAPiCall(id, values);
        }
      }
    }
  };

  AddParentsectionuploadSchema = () => {
    return Yup.object().shape({
      heading: Yup.string()
        .required(
          this.t("CloneProject.sectionuploadSchema.Headingisrequired")
        ),
      content: Yup.string()
        .required(this.t("CloneProject.sectionuploadSchema.Contentisrequired")),
    });
  };

  AddCompanySection = () => {
    return (
      <Grid container>
        {this.state.CompanySection.length === 0 && this.state.ParentContentData.length === 0 ?
          <Grid
            item
            xs={12}>
            <div className="CompanysectionMainDiv"
              onClick={() => this.setState({ CompanyModel: !this.state.CompanyModel, AddParentContentModel: "EditParent" })}>
              <AddIcon />
              <Typography style={{ fontWeight: "bold" }}>
                {this.t("CloneProject.AddCompanysection.AddCompanysectionText")}
              </Typography>
            </div>
          </Grid> :
          <Grid item xs={12}>
            <div className="SectionContainer">
              <div className="SectionHeading">
                <div style={{ fontWeight: "bolder" }}>
                  <Typography>{this.t("CloneProject.AddCompanysection.CompanyDetails")}</Typography></div>
                <div style={{ display: 'flex' }}>
                  <Button className="addnewsection"
                    style={{ marginRight: '10px' }} onClick={() => this.setState({
                      AddParentContentModel: "EditParent", CompanyModel: !this.state.CompanyModel
                    })}>
                    <EditIconForSection />
                    <Typography>{this.t("CloneProject.AddCompanysection.EditParentDetails")}</Typography>
                  </Button>
                  <Button
                    className="addnewsection"
                    onClick={() => this.setState({
                      SectionNumber: this.state.CompanySection.length + 1, CompanyModel: true
                    })}>
                    <Typography>
                      {this.t("CloneProject.AddCompanysection.AddCompanySection")}
                    </Typography></Button>
                </div>
              </div>
              {this.state.ParentContentData.map((item: any, index: any) => {
                return (
                  <div className="AllSection"
                    key={`${item.heading + index + 1}`}>
                    <div className="SectionHeading">
                      <div>
                        <Typography variant="h4">{item.heading}</Typography>
                      </div>
                    </div><div style={{ marginLeft: "30px" }}>
                      <Typography variant="subtitle1"
                        dangerouslySetInnerHTML={this.HtmlConverter(item.content)} /></div></div>
                )
              })}
              {this.state.CompanySection.map((item: any, index: any) => {
                return (this.CompanySectionMapClone(item, index))
              })}
            </div>
          </Grid>
        }
        {this.ViewFileModel()}
      </Grid>
    )
  }

  CompanySectionMapClone = (item: any, index: any) => {
    return (
      <div className="AllSection" key={`${item.heading + index + 1}`}>
        <div className="SectionHeading">
          <div>
            <Typography variant="h4">{item.heading}</Typography>
          </div>
          <Button className="addnewsection" style={{ textDecoration: "none" }} onClick={() => this.setState({
            CompanyModel: !this.state.CompanyModel, SectionNumber: index + 1,
            EditSectionIndex: index
          })}>
            <EditIconForSection />
            <Typography>{this.t("CloneProject.AddCompanysection.EditDetails")}</Typography>
          </Button>
        </div>
        <div>
          <Typography variant="h5">{item.title}</Typography>
        </div>
        <div style={{ marginLeft: "30px" }}>
          <Typography variant="subtitle1" dangerouslySetInnerHTML={this.HtmlConverter(
            item.content
          )} />
        </div>
        <div className="MediaContainer">
          <div className="AllMediaSection">
            {item.uploadFile?.map((fileRender: any, index: any) => {
              let Type;
              if (fileRender?.url !== undefined) { Type = fileRender?.type.toLowerCase() } else { Type = fileRender?.includes("video") ? "video" : "Image" }
              return (
                <Tooltip title={this.t('ProjectsController.ViewFile')} key={fileRender} placement="bottom" onClick={() => this.OpenSectionFiles(fileRender)}>
                  {Type.includes("video") ? <PlayCircleOutlineIcon style={{ width: "60px", height: "60px" }} /> :
                    <img style={{ width: "60px", height: "60px" }} src={fileRender.url !== undefined ? fileRender.url : fileRender} alt={`${fileRender + index + 1}`} />
                  }
                </Tooltip>
              )
            }
            )}
          </div>
          <div style={{ display: 'flex', alignItems: 'flex-end' }}>
            <Button className="addnewsection" style={{ color: "#fa6c7d", paddingBottom: '0px' }} onClick={() => this.RemoveSection(item)} >
              <Typography>
                {this.t("CloneProject.AddCompanysection.RemoveSection")}
              </Typography>
            </Button>
          </div>
        </div>
      </div>
    )
  }

  AddCompanyModel = () => {
    return (
      <Dialog
        fullWidth={false}
        className="setScoreRating"
        transitionDuration={0}
        scroll="body"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        maxWidth={"lg"}
        open={this.state.CompanyModel}
        PaperProps={{
          style: {
            width: "100%",
          },
        }}
      >
        <DialogContent style={{ padding: "0px", width: "100%" }}>
          {this.state.AddParentContentModel === "EditParent" &&
            this.parentSection()
          }
          {this.state.ParentContentData.length !== 0 && this.state.AddParentContentModel !== "EditParent" &&
            this.SubSectionPart()
          }
        </DialogContent>
      </Dialog>
    )
  }

  parentSection = () => {
    return (
      <StyleCloneProject>
        <Box data-textid="CloneProjectAddCompanysection1"
          style={{ textAlign: "right", margin: "10px 10px 0px 0px" }}>
          <CloseIcon
            style={{
              cursor: "pointer",
              fontSize: "35px",
              color: "#787878",
            }}
            onClick={() =>
              this.setState({
                CompanyModel: !this.state.CompanyModel,
                AddParentContentModel: ""
              })
            }
          />
        </Box>
        <Box padding={{ xs: "15px 25px 25px", sm: "15px 40px 40px" }}>
          <Box
            id="modal-title"
            color="#111"
            fontWeight="bolder"
            fontSize={{ xs: "20px", sm: "30px" }}
            fontFamily="ProximaNova-Bold"
            data-textid="CloneProjectAddCompanysection2"
            style={{ fontFamily: "ProximaNova-Regular" }}
            mb="20px"
          >{this.t("CloneProject.AddCompanysection.ParentSection")}</Box>
          <Formik
            validationSchema={this.AddParentsectionuploadSchema}
            validateOnChange={true}
            onSubmit={(values, { setSubmitting }) => {
              this.setState({
                ParentContentData: [values],
                AddParentContentModel: ""
              })
              if (this.state.CompanySection.length !== 0) {
                this.setState({
                  CompanyModel: false
                })
              }
            }}
            initialValues={{
              heading: this.state.ParentContentData[0]?.heading || "",
              content: this.state.ParentContentData[0]?.content || ""
            }}
            validateOnBlur={true}
          >
            {(props) => (
              <form
                id="my-form"
                autoComplete="off"
                onSubmit={props.handleSubmit}
                noValidate
              >
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12} data-textid="CloneProjectAddCompanysection3">
                    <Box>
                      <Typography variant="body2" style={{ marginBottom: "5px", fontFamily: "ProximaNova-Regular" }}>
                        {this.t("CloneProject.AddCompanysection.Heading")}
                      </Typography>
                      <TextField
                        id="heading"
                        placeholder={this.t("CloneProject.AddCompanysection.PlaceholderHeading")}
                        variant="outlined"
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        className="client-textfields"
                        value={props.values.heading}
                        name="heading"
                        fullWidth
                      />
                    </Box>
                    {this.getErrorMessage(
                      props.touched,
                      props.errors,
                      "heading"
                    )}
                  </Grid>
                </Grid>
                <Grid container spacing={3} data-textid="CloneProjectAddCompanysection4">
                  <Grid item xs={12}>
                    <Typography
                      variant="body2"
                      style={{ marginBottom: "5px", fontFamily: "ProximaNova-Regular" }}
                    >
                      {this.t("CloneProject.AddCompanysection.HTML")}
                      <span style={{ color: "red" }}>*</span>
                    </Typography>
                    <div data-textid="CloneProjectAddCompanysection5">
                      <ReactQuill
                        modules={{
                          toolbar: [
                            [{ header: [1, 2, 3, 4, 5, 6, 7] }],
                            [
                              "bold",
                              "italic",
                              "underline",
                              "strike",
                              "blockquote",
                            ],
                            [
                              { list: "ordered" },
                              { list: "bullet" },
                              { indent: "-1" },
                              { indent: "+1" },
                            ],
                            ["link"],
                            ["clean"],
                          ],
                        }}
                        placeholder={this.t("CloneProject.AddCompanysection.PlaceholderHTML")}
                        theme="snow"
                        onChange={(e: any) => props.setFieldValue('content', e)}
                        id="content"
                        value={props.values.content}
                        formats={[
                          "header",
                          "bold",
                          "italic",
                          "underline",
                          "strike",
                          "blockquote",
                          "list",
                          "bullet",
                          "indent",
                          "link",
                          "image",
                        ]}
                      />
                    </div>
                    {this.getErrorMessage(
                      props.touched,
                      props.errors,
                      "content"
                    )}
                  </Grid>
                </Grid>
                <Box mt="30px" display="flex" data-textid="CloneProjectAddCompanysection6" justifyContent="end">
                  <CancelButton
                    className="cancelBtn"
                    onClick={() =>
                      this.setState({
                        CompanyModel: false,
                        AddParentContentModel: ""
                      })
                    }
                    style={{
                      marginLeft: LanguageData ? "10px" : ""
                    }}
                  >
                    {this.t("CloneProject.button.Cancel")}
                  </CancelButton>
                  <SaveButton form="my-form" type="submit">
                    {this.t("CloneProject.button.SAVE")}
                  </SaveButton>
                </Box>
              </form>
            )}
          </Formik>
        </Box>
      </StyleCloneProject>
    )
  }

  SubSectionPart = () => {
    return (
      <StyleCloneProject data-textid="CloneProjectAddCompanysection7">
        <Box
          data-textid="CloneProjectAddCompanysection8"
          style={{ textAlign: "right", margin: "10px 10px 0px 0px", }}>
          <CloseIcon
            style={{
              cursor: "pointer", fontSize: "35px", color: "#787878",
            }}
            onClick={() =>
              this.setState({
                CompanyModel: !this.state.CompanyModel,
              })
            }
          />
        </Box>
        <Box
          data-textid="CloneProjectAddCompanysection9"
          padding={{ xs: "15px 25px 25px", sm: "15px 40px 40px" }}
        >
          <Box
            id="modal-title"
            color="#111"
            fontFamily="ProximaNova-Bold"
            mb="20px"
            fontWeight="bolder"
            fontSize={{ xs: "20px", sm: "30px" }}
          >
            {this.t("CloneProject.AddCompanysection.Section")} {this.state.CompanySection.length === 0 ? 1 : this.state.SectionNumber}
          </Box>
          <Formik
            validationSchema={this.sectionuploadSchema}
            onSubmit={(values, { setSubmitting }) => {
              this.AddDatainSections(values)
            }}
            validateOnBlur={true}
            validateOnChange={true}
            initialValues={{
              heading: this.state.CompanySection[this.state.EditSectionIndex]?.heading || "",
              title: this.state.CompanySection[this.state.EditSectionIndex]?.title || "",
              content: this.state.CompanySection[this.state.EditSectionIndex]?.content || "",
              uploadFile: this.state.CompanySection[this.state.EditSectionIndex]?.uploadFile || [],
            }}
          >
            {(props) => (
              <form
                id="my-form"
                onSubmit={props.handleSubmit}
                autoComplete="off"
                noValidate
                onDragEnter={this.handleDrag} onDragOver={this.handleDrag}
              >
                <Grid container spacing={3} data-textid="CloneProjectAddCompanysection10">
                  <Grid item xs={12} sm={6} data-textid="CloneProjectAddCompanysection11">
                    <Box>
                      <Typography
                        variant="body2"
                        style={{ marginBottom: "5px", fontFamily: "ProximaNova-Regular" }}
                      >
                        {this.t("CloneProject.AddCompanysection.Heading")}
                      </Typography>
                      <TextField
                        fullWidth
                        className="client-textfields"
                        placeholder={this.t("CloneProject.AddCompanysection.PlaceholderHeading")}
                        name="heading"
                        id="heading"
                        value={props.values.heading}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        variant="outlined"
                      />
                    </Box>
                    {this.getErrorMessage(
                      props.touched,
                      props.errors,
                      "heading"
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6} data-textid="CloneProjectAddCompanysection12">
                    <Box>
                      <Typography
                        variant="body2"
                        data-textid="CloneProjectAddCompanysection13"
                        style={{ marginBottom: "5px", fontFamily: "ProximaNova-Regular" }}
                      >
                        {this.t("CloneProject.AddCompanysection.Title")}
                      </Typography>
                      <TextField
                        name="title"
                        variant="outlined"
                        id="title"
                        value={props.values.title}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        placeholder={this.t("CloneProject.AddCompanysection.PlaceholderTitle")}
                        className="client-textfields"
                        fullWidth
                      />
                    </Box>
                    {this.getErrorMessage(
                      props.touched,
                      props.errors,
                      "title"
                    )}
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} data-textid="CloneProjectAddCompanysection114">
                    <Typography
                      variant="body2"
                      style={{ marginBottom: "5px", fontFamily: "ProximaNova-Regular" }}
                      data-textid="CloneProjectAddCompanysection1134"
                    >
                      {this.t("CloneProject.AddCompanysection.HTML")}
                      <span style={{ color: "red" }}>*</span>
                    </Typography>
                    <div data-textid="CloneProjectAddCompanysection14">
                      <ReactQuill
                        onChange={(e: any) => props.setFieldValue('content', e)}
                        placeholder={this.t("CloneProject.AddCompanysection.PlaceholderHTML")}
                        theme="snow"
                        modules={{
                          toolbar: [
                            [{ header: [1, 2, 3, 4, 5, 6, 7] }], ["bold", "italic", "underline", "strike", "blockquote",], [
                              { list: "ordered" },
                              { list: "bullet" }, { indent: "-1" }, { indent: "+1" },
                            ],
                            ["link"],
                            ["clean"],
                          ],
                        }}
                        id="content"
                        value={props.values.content}
                        formats={[
                          "header", "bold", "italic", "underline", "strike",
                          "blockquote", "list", "bullet", "indent", "link", "image",
                        ]}
                      />
                    </div>
                    {this.getErrorMessage(
                      props.touched,
                      props.errors,
                      "content"
                    )}
                  </Grid>
                </Grid>
                {this.renderImageandVideoUploadBtn(props)}
                <Box mt="30px" display="flex" justifyContent="end" data-textid="CloneProjectAddCompanysectionsdksd1"><CancelButton className="cancelBtn" style={{
                      marginLeft: LanguageData ? "10px" : ""
                    }} onClick={() => this.setState({ CompanyModel: false, })}>{this.t("CloneProject.button.Cancel")}</CancelButton><SaveButton form="my-form" type="submit">{this.t("CloneProject.button.SAVE")}</SaveButton></Box>
              </form>
            )}
          </Formik>
        </Box>
      </StyleCloneProject>
    )
  }

  renderImageandVideoUploadBtn = (props: any) => {
    return (
      <Grid container spacing={3} data-textid="CloneProjectAddCompanysection15">
        {this.SectionImageUpload(props)}
        <Grid item xs={2} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          <Typography variant="body2" style={{ display: "flex", alignItems: "center", justifyContent: "center" }} data-testid="AddCompanysdsdsdsdectidsdsdsonCreates">{this.t("ProjectsController.OR")}</Typography></Grid>
        {this.SectionVideoUpload(props)}
        <Grid item xs={12} data-textid="CloneProjectAddCompanysectiondfdfsds1">
          {props.values.uploadFile.length !== 0 && <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <SaveButton onClick={() => this.OpenSectionFiles(props.values.uploadFile[0])}>{this.t("CloneProject.AddCompanysection.ViewUploadedFile")}</SaveButton><SaveButton onClick={() => props.setFieldValue('uploadFile', [])} >
              {this.t("CloneProject.AddCompanysection.DeleteUploadedFile")}</SaveButton></div>}
        </Grid>
      </Grid>
    )
  }

  SectionImageUpload = (props: any) => {
    return (
      <Grid item xs={5} data-textid="CloneProjectAddCompanysection16">
        <Typography
          variant="body2"
          data-textid="CloneProjectAddCompanysection17"
          style={{ marginBottom: "5px", fontFamily: "ProximaNova-Bold", fontWeight: "bold" }}
        > {this.t("CloneProject.AddCompanysection.UploadImage")} <span style={{ color: "red" }}>*</span></Typography>
        <Button className="dragElement" disabled={props.values.uploadFile.length !== 0} style={{ width: '100%' }}>
          <input
            data-testid="creaetedfileuploadcompanyfdfdfd" type="file" accept="image/*" name="ImageUpload" id="ImageUpload" style={{ display: "none" }} onChange={async (e: any) => { this.imageHandleChange(e, props) }}
            onBlur={props.handleBlur}
          />
          <label htmlFor="ImageUpload" style={{ width: "100%" }} className={this.state.dragActive ? "drag-active label-file-upload" : "label-file-upload"}><Box textAlign="center" className='customUploadAreaCompany'>
            <div style={{ display: 'flex', alignItems: 'center', textTransform: "capitalize" }}><InsertDriveFileOutlined /><Box style={{ fontWeight: "bold" }}>{this.t("CloneProject.TextField.DropOrBrowseYourFileHere")}</Box></div>
          </Box></label>
          {this.state.dragActive && <div
            className="drag-file-element"
            onDragEnter={(event) => this.handleDrag(event)}
            onDragLeave={(event) => this.handleDrag(event)}
            data-testid="dragcloneprojecsdfd"
            onDragOver={(event) => this.handleDrag(event)}
            onDrop={(event) => this.handleDrop(event, props, "image")}>
          </div>}
        </Button>
        {this.getErrorMessage(
          props.touched,
          props.errors,
          "uploadFile"
        )}
      </Grid>
    )
  }

  SectionVideoUpload = (props: any) => {
    return (
      <Grid item xs={5} data-textid="CloneProjectAddCompanysectiodn1sds">
        <Typography variant="body2" style={{ marginBottom: "5px", fontFamily: "ProximaNova-Bold", fontWeight: "bold" }}>{this.t("CloneProject.AddCompanysection.UploadVideo")}<span style={{ color: "red" }}>*</span></Typography>
        <Button className="dragElement" disabled={props.values.uploadFile.length !== 0} style={{ width: '100%' }}>
          <input onBlur={props.handleBlur} type="file" name="VideoUpload" id="VideoUpload" data-testid="creaetedfileuploadcreaetedfileuploadcompany" accept="video/*" style={{ display: "none" }}
            onChange={async (e: any) => {
              this.uploadFileChange(e, props)
            }}
          /><label htmlFor="VideoUpload" style={{ width: "100%" }} className={this.state.dragActive ? "drag-active label-file-upload" : "label-file-upload"}>
            <Box textAlign="center" className='customUploadAreaCompany'><div style={{ display: 'flex', alignItems: 'center', textTransform: "capitalize" }}><InsertDriveFileOutlined /><Box style={{ fontWeight: "bold" }}>{this.t("EditProject.TextField.DropOrBrowseYourFileHere")}</Box></div>
            </Box></label>
          {this.state.dragActive && <div
            onDrop={(event) =>
              this.handleDrop(event, props, "video")}
            onDragEnter={(event) =>
              this.handleDrag(event)}
            className="drag-file-element"
            data-testid="dragcloneproject31"
            onDragLeave={(event) =>
              this.handleDrag(event)}
            onDragOver={(event) =>
              this.handleDrag(event)}
          >
          </div>}
        </Button>
        {this.getErrorMessage(
          props.touched,
          props.errors,
          "uploadFile"
        )}
      </Grid>
    )
  }

  ViewFileModel = () => {
    return (
      <Dialog
        open={this.state.ViewFileModel}
        fullWidth={false}
        className="setScoreRating"
        transitionDuration={0}
        onClose={() => this.setState({
          ViewFileModel: !this.state.ViewFileModel,
        })}
        scroll="body"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        maxWidth={"lg"}
        PaperProps={{
          style: {
            width: "100%",
          },
        }}
      >
        <DialogContent style={{ padding: "0px", width: "100%" }}>
          <StyleCloneProject data-textid="CloneProjectAddCompanysection1ghdhh1763">
            <Box
              style={{
                margin: "10px 10px 0px 0px", textAlign: "right",
              }}
            >
              <CloseIcon
                style={{
                  fontSize: "35px",
                  color: "#787878",
                  cursor: "pointer",
                }}
                onClick={() =>
                  this.setState({
                    ViewFileModel: !this.state.ViewFileModel,
                    AddParentContentModel: ""
                  })
                }
              />
            </Box>
            <Box padding={{ xs: "15px 25px 25px", sm: "15px 40px 40px" }}>
              {this.state.FileType === "video" ?
                <div className="FilevideoRender" data-textid="CloneProjectAddCompanysection1fileer">
                  <video controls><source src={this.state.ViewFileSource} type="video/mp4" /></video>
                </div> :
                <img
                  alt="true"
                  width="100%"
                  src={this.state.ViewFileSource}
                  height="500px"
                />
              }
            </Box>
          </StyleCloneProject>
        </DialogContent>
      </Dialog>
    )
  }

  render() {
    const toolsList = this.state.tools
      .filter((item: any) => item.type !== "assessor_lead_tools")
      ?.map((tool: any) => {
        return {
          label: tool?.attributes?.name,
          value: tool.id,
        };
      })
      .filter((item: any) => item?.label !== "Psychometric");
    const { editProject, assessor_id, projectType, competencyId } = this.state;

    return (
      <StyleCloneProject
        data-testid="Heading"
        style={{ fontFamily: "ProximaNova-Regular" }}
      >
        {this.state.loading && <Spinner spinnerModal={this.state.loading} />}
        <AppSidebar type="admin">
          <Box margin="35px 0px" mr="30px" className="createNewProject">
            {editProject && Object.keys(editProject).length > 0 && (
              <>
                <Box
                  display={{ xs: "block", md: "flex" }}
                  justifyContent="space-between"
                  mb="40px"
                >
                  <Box>
                    <Box
                      mb="5px"
                      fontFamily="ProximaNova-Bold"
                      fontSize={{ xs: "24px", sm: "30px", md: "44px" }}
                      color="#1b3c69"
                    >
                      {this.t("CloneProject.title.title")} {editProject.name}
                    </Box>
                  </Box>
                </Box>
                <Formik
                  initialValues={{
                    project_name: editProject.name,
                    project_type: projectType,
                    assessor_id: assessor_id,
                    client_id: editProject.client_id,
                    manager_id: editProject.manager_id,
                    coManager_id: editProject.co_manager_id,
                    start_date: editProject.start_date,
                    end_date: editProject.end_date,
                    pdfFile: [],
                    negative_marks: editProject.is_negative_marking
                      ? editProject.negative_mark
                      : "0",
                    isNegative: editProject.is_negative_marking ? "Yes" : "No",
                    competency_id: competencyId,
                  }}
                  validationSchema={this.editProjectSchema}
                  onSubmit={(values) => {
                    this.OnSubmitFuncationForFormik(values);
                  }}
                  validateOnBlur={true}
                  validateOnChange={true}
                >
                  {(props) => (
                    <form onSubmit={props.handleSubmit}
                      onDragEnter={
                        this.handleDrag
                      }
                      onDragOver={this.handleDrag}>
                      <Grid className="label-parent" container spacing={3}>
                        {this.renderProjectName(props)}
                        {this.renderProjectType(props)}
                        {this.renderDCmanager(props)}
                        {this.renderCoManager(props)}
                        {this.renderAssessor(props)}
                        {this.renderClient(props)}
                        {this.renderStartDate(props)}
                        {this.EndDateItem(props)}
                        {this.uploadDocumentRender(props)}
                        {this.GridForMarks(props)}
                      </Grid>
                      {this.AddCompanySection()}
                      {this.state.tools.length > 0 &&
                        this.renderQuetionpart(toolsList, props)}
                    </form>
                  )}
                </Formik>
              </>
            )}
          </Box>
          {this.RenderSetTimerModel()}
          {/* Edit competency dialog */}
          {this.EditCompetencyDialog()}
          {/* Rating dialog */}
          {this.RatingdialogReder()}
          {this.AddCompanyModel()}
        </AppSidebar>
      </StyleCloneProject>
    );
  }
}

export default withRouter(withTranslation()(CloneProject));
const CustomInput = React.forwardRef<any, { value?: any; onClick?: any }>(
  ({ value, onClick }, ref) => (
    <Box position="relative" border="1px solid #d6d6d6" borderRadius="10px">
      <img
        src={calender}
        onClick={onClick}
        style={{
          zIndex: 0,
          position: "absolute",
          right: LanguageData === false ? "12px" : "",
          left: LanguageData ? "12px" : "",
          cursor: "pointer",
          top: "14px",
          width: "20px",
        }}
      />
      <DateInput placeholder="DD/MM/YYYY" onClick={onClick} value={value} />
    </Box>
  )
);
const DateInput = styled(InputBase)({
  minHeight: "50px",
  fontFamily: "ProximaNova-Regular",
  paddingLeft: !LanguageData ? "20px" : "",
  paddingRight: LanguageData ? "20px" : "",
  width: "100%",
  fontSize: "14px",
  "& .MuiInputBase-input": {
    "&::placeholder": {
      fontSize: "17px",
    },
  },
});

const StyleCloneProject = styled("div")({
  fontFamily: "ProximaNova-Regular !important",
  "& .UnSelectBtn": {
    flex: "10%",
    border: "1px solid black",
    backgroundColor: "white",
    color: "black",
    margin: "5px",
    minWidth: "80px",
    textTransform: "capitalize",
  },
  "& .SelectBtn": {
    backgroundColor: "#f94b4b",
    textTransform: "capitalize",
    color: "white",
    margin: "5px",
    minWidth: "80px",
  },
  "& .ButtonContainer": {
    display: "flex",
    justifyContent: "flex-start",
    padding: "5px",
    marginTop: "6px",
    flexWrap: "wrap",
    backgroundColor: "#eff6ff",
  },
  "& .ContainerQuetion": {
    margin: "5px 0",
  },
  "& .panel1bh-header .MuiAccordionSummary-content": {
    flexGrow: "unset",
    width: "100%"
  },
  "& .AcorenHeading": {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& .checkquetion": {
      display: "flex",
      alignItems: "center",
    },
  },
  "& .SetTimerContainer": {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "space-between",
    "& .EditTimePen": {
      marginRight: "10px",
      color: "#f94b4b",
      fontSize: "20px",
    },
    "& p": {
      color: "#f94b4b",
      fontWeight: "bold",
    },
  },
  "& .QuetionTextField": {
    "& input": {
      fontFamily: "ProximaNova-Regular",
      fontWeight: "700",
    },
  },
  "& .TextandButton": {
    display: "flex",
    justifyContent: "space-between",
    width: "calc(100% - 68px)",
  },
  "& .InputContainer": {
    "& .MuiSvgIcon-root": {
      width: "50px",
      height: "50px",
      borderRadius: "6px",
      backgroundColor: "white",
    },
    "& .MuiCheckbox-root": {
      borderWidth: "1px",
    },
  },
  "& .quetions": {
    maxWidth: "85%",
    width: "100%",
    margin: "0",
    paddingLeft: "10px",
    boxSizing: "border-box",
    textOverflow: "ellipsis",
    "& h4": {
      display: "inline-block",
      width: "inherit",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      maxWidth: "700px",
      fontFamily: "ProximaNova-Regular",
    },
  },
  "& .CloseAcordian": {
    position: "relative",
  },
  "& .labelAcordin": {
    position: "absolute",
    left: !LanguageData ?  "0" : "unset",
    right: LanguageData ?  "0" : "unset",
    color: "black",
    top:"0",
    padding: "2px 10px",
    borderTopLeftRadius: "4px",
    "& h5": {
      fontSize: "16px",
    },
  },
  "& .checkBoxAndQueHeading": {
    display: "flex",
    width: "100%",
    marginTop: "10px",
    flexWrap: "wrap",
    maxWidth: "100%",
  },
  "& .QuetionRender": {
    display: "flex",
    alignItems: "center",
    maxWidth: "100%",
    overflow: "hidden",
    width: "calc(100% - 155px)",
    "& div": {
      "& h3": {
        fontFamily: "ProximaNova-Regular",
        fontWeight: "400",
      },
    },
  },
  "& .MarksContainer": {
    display: "flex",
    justifyContent: "space-between",
  },
  "& .AddOption": {
    fontFamily: "ProximaNova-Regular",
  },
  '& .videoRender': {
    '& video': {
      width: '100%',
      height: '200px'
    }
  },
  "&  .redButton": {
    backgroundColor: "#f94b4b",
    color: "white",
    borderRadius: "10px",
    fontFamily: "ProximaNova-Semibold",
    fontSize: "18px",
    padding: "5px 25px",
    textTransform: "none",
    "&:hover": {
      color: "white",
      backgroundColor: "#f94b4b",
    },
    "@media(max-width:600px)": {
      width: "100%",
      marginTop: "27px",
    },
    "@media(max-width:992px)": {
      padding: "8px 15px",
    },
  },
  "& .SetRatingContainer": {
    cursor: "pointer",
    display: "flex",
    justifyContent:"flex-end",
    marginTop: "15px",
    padding: "5px",
    "& .EditTimePen": {
      marginRight: "10px",
      color: "#f94b4b",
      fontSize: "20px",
    },
    "& p": {
      color: "#f94b4b",
      fontWeight: "bold",
    },
  },
  "& .Projectlabel": {
    fontFamily: "ProximaNova-Regular",
    fontWeight: "900",
    textTransform: "capitalize",
  },
  '& .CompanysectionMainDiv': {
    marginTop: "10px", display: 'flex', justifyContent: 'center', padding: '10px', backgroundColor: '#eff6ff', borderRadius: '6px', cursor: 'pointer'
  },
  '& .SectionContainer': {
    backgroundColor: '#eff6ff', marginTop: "10px",
    padding: "20px 20px", borderRadius: '6px',
  },
  '& .SectionHeading': {
    display: "flex", justifyContent: "space-between", marginBottom: '10px'
  },
  '& .FilevideoRender': {
    '& video': { width: '100%', height: '500px' }
  },
  '& .addnewsection': {
    display: 'flex', textDecoration: "underline",
    color: '#1b395c', cursor: "pointer", textTransform: "capitalize"
  },
  '& .MediaContainer': {
    display: "flex", justifyContent: "space-between"
  },
  '& .AllMediaSection': {
    display: "flex",
    '& >div': {
      marginLeft: "5px", marginRight: "5px", marginTop: "10px",
      borderRadius: "8px", cursor: "pointer"
    },
    '& :first-child': { marginLeft: '0px' },
    '& :last-child': { marginRight: "0px" }
  },
  '& .AllSection': {
    backgroundColor: "white", margin: "10px 0",
    padding: "15px", borderRadius: '6px'
  },
  '& .customUploadAreaCompany': {
    width: '100%', border: 'dashed', cursor: 'pointer', overflow: 'hidden',
    position: 'relative', boxSizing: 'border-box', minHeight: '60px', borderColor: 'rgba(0, 0, 0, 0.12)',
    borderRadius: '4px', backgroundColor: '#fff', display: 'flex', justifyContent: 'center', alignItems: 'center',
    padding: '30px',
    '& .MuiSvgIcon-root': {
      marginBottom: '2px'
    }
  },
  '& .quill': { display: 'grid' },
  '& .ql-container': { minHeight: '100px' },
  '& .react-datepicker-wrapper': {
    width: "100%"
  },
  "& .HighlightOffOutlinedIcon": {
    cursor: "pointer", marginLeft: "7px",
    height: "15px", width: "15px", color: "white", verticalAlign: "middle"
  }
});

const CustomTwoInputBase = styled(InputBase)({
  border: "1px solid #d6d6d6",
  minHeight: "50px",
  padding: "10px",
  paddingLeft: "15px",
  fontSize: "14px",
  borderRadius: "5px",
  "& .MuiInputBase-input": {
    "&::placeholder": {
      fontSize: "14px",
    },
  },
});

const Option = (props: any) => {
  return (
    <div>
      <components.Option {...props}>
        <Checkbox
          disableRipple
          icon={<div className="unchecked" />}
          checkedIcon={
            <div className="checked" data-testid="checked">
              <DoneIcon />
            </div>
          }
          checked={props.isSelected}
          onChange={() => null}
        />
        <div className="comp_wrapper">
          <label>{props.label}</label>
          <img
            src={editIcon}
            alt="Edit icon"
            onClick={(event: any) => {
              props.selectProps?.onEditOptionClicked(props.data);
              event.stopPropagation();
            }}
          />
        </div>
      </components.Option>
    </div>
  );
};

const CustomInputBase = styled(InputBase)({
  border: "1px solid #d6d6d6",
  backgroundColor: "white",
  padding: "10px 15px",
  borderRadius: "10px",
  minHeight: "50px",
  fontSize: "14px",
  "& .MuiInputBase-input": {
    "&::placeholder": {
      fontSize: "14px",
    },
  },
});
const CancelButton = styled(Button)({
  fontFamily: "ProximaNova-Semibold",
  marginRight: "30px",
  fontSize: "18px",
  borderRadius: "10px",
  padding: "7px 15px",
  color: "#f26a42",
  backgroundColor: "#fff",
  border: "1px solid #f26a42",
  textTransform: "none",
});

const SaveButton = styled(Button)({
  fontFamily: "ProximaNova-Semibold",
  backgroundColor: "#f26a42",
  padding: "7px 22px",
  fontSize: "18px",
  color: "#fff",
  borderRadius: "10px",
  border: "1px solid #f26a42",
  textTransform: "none",
  "&:hover": {
    color: "#fff",
    backgroundColor: "#f26a42",
  },
  "&.MuiButton-root.Mui-disabled": {
    border: "1px solid hsl(0,0%,60%)",
    backgroundColor: "hsl(0,0%,95%)",
    cursor: "no-drop",
    color: "hsl(0,0%,60%)",
    pointerEvents: "auto",
  },
});

const DeleteDialog = styled(Dialog)({
  "& .SelectToolName": {
    "& .react-select__menu-list": {
      zindex: 99,
      maxHeight: "170px",
    },
  },
  "& .ModelMainDiv": {
    "& div": {
      width: "100%",
    },
  },
  "& .MuiDialog-paper": {
    minWidth: "500px",
  },
  "@media(max-width:576px)": {
    margin: "0px",
  },
  margin: "0px 61px",
});
// Customizable Area End
