import { Message } from "../../../../../framework/src/Message";
import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import { runEngine } from "../../../../../framework/src/RunEngine";
import MessageEnum, {
    getName,
} from "../../../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { toast } from "react-toastify";
// Customizable Area End

export const configJSON = require("../../config");

export interface Props {
    id: string;
    navigation: any;
    t: any;
}

export interface S {
    // Customizable Area Start
    loading: boolean;
    page: number;
    rowsPerPage: number;
    dataLength: number;
    count: number | null;
    industryId: any;
    industryName: string;
    search: string;
    openDialogName: string | null;
    data: any[];
    tableData: any[];
    filterData: any[];
    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class IndustryController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    getIndustryListApiCallId: string = "";
    addIndustryApiCallId: string = "";
    editIndustryApiCallId: string = "";
    deleteIndustryApiCallId: string = "";
    t: any;
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage),
        ];
        this.receive = this.receive.bind(this);
        runEngine.attachBuildingBlock(this, this.subScribedMessages);

        this.state = {
            // Customizable Area Start
            loading: false,
            page: 0,
            rowsPerPage: 20,
            dataLength: 0,
            count: null,
            industryId: null,
            industryName: "",
            search: "",
            data: [],
            openDialogName: "",
            filterData: [],
            tableData: [],
            // Customizable Area End
        };
        this.t = this.props.t;
    }

    async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson: any = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId && responseJson) {
                if (responseJson.status === 500) {
                    toast.error(this.t("organizationFunction.industry.toast.somethingWrong"));
                    this.setState({ loading :false });
                } else if (!Array.isArray(responseJson.errors) && responseJson.errors) {
                    this.setState({ loading: false });
                    toast.error(responseJson.errors);
                } else {
                    this.handleApiResponse(apiRequestCallId, responseJson);
                }
            }
        }
    }

    async componentDidMount() {
        window.scrollTo(0, 0);
        this.getIndustryList();
    }

    handleApiResponse = (apiRequestCallId: string, responseJson: any) => {
        if (Array.isArray(responseJson.errors)) {
            if (responseJson.errors[0].message) {
                toast.error(responseJson.errors[0].message);
            } else if (responseJson.errors[0].industry_name) {
                toast.error(responseJson.errors[0].industry_name);
            } else {
                toast.error(this.t("organizationFunction.industry.toast.somethingWrong"));
            }
            this.setState({ loading: false });
        } else if (apiRequestCallId === this.getIndustryListApiCallId) {
            this.handelIndustryListApiResponse(responseJson);
        } else if (apiRequestCallId === this.addIndustryApiCallId) {
            this.handelAddIndustryApiResponse(responseJson);
        } else if (apiRequestCallId === this.editIndustryApiCallId) {
            this.handelEditIndustryApiResponse(responseJson);
        } else if (apiRequestCallId === this.deleteIndustryApiCallId) {
            this.handelDeleteIndustryApiResponse();
        }
    }

    handelIndustryListApiResponse = (responseJson: any) => {
        const data = responseJson.data.map((item: any) => {
            return {
                id: item.id,
                industryName: item.attributes.industry_name
            }
        });
        this.setState({ tableData: data, filterData: data, loading: false },
            () => { this.onChangeHandler(this.state.search, 0) }
        );
    }

    handelAddIndustryApiResponse = (responseJson: any) => {
        toast.success(this.t("organizationFunction.industry.toast.add"));
        let tableData = [...this.state.tableData];
        const obj = {
            id: responseJson.data.id,
            industryName: responseJson.data.attributes.industry_name
        };
        tableData = [obj, ...tableData];
        this.setState({ tableData, filterData: tableData, industryId: null, industryName: "", openDialogName: null, loading: false },
            () => { this.onChangeHandler(this.state.search, 0) }
        );
    }

    handelEditIndustryApiResponse = (responseJson: any) => {
        toast.success(this.t("organizationFunction.industry.toast.edit"));
        let tableData = [...this.state.tableData];
        tableData = tableData.map((item: any) => {
            if (item.id === this.state.industryId) {
                return {
                    id: responseJson.data.id,
                    industryName: responseJson.data.attributes.industry_name
                };
            } else {
                return item;
            }
        });

        this.setState({ tableData, filterData: tableData, industryId: null, industryName: "", openDialogName: null, loading: false },
            () => { this.onChangeHandler(this.state.search, this.state.page) }
        );
    }

    handelDeleteIndustryApiResponse = () => {
        toast.success(this.t("organizationFunction.industry.toast.delete"));
        let tableData = [...this.state.tableData];
        tableData = tableData.filter((item: any) => item.id !== this.state.industryId);
        this.setState({ tableData, filterData: tableData, industryId: null, industryName: "", openDialogName: null, loading: false },
            () => { this.onChangeHandler(this.state.search, this.state.page) }
        );
    }

    onChangeHandler = (search: string, page: number) => {
        const { filterData, rowsPerPage } = this.state;
        let data = filterData.filter((item: any) => {
            if (search === "") {
                return true;
            } else if (item.industryName.toString().toLowerCase().includes(search.toLowerCase())) {
                return item;
            }
        });

        const dataLength = data.length;
        let totalPage = Math.ceil(dataLength / this.state.rowsPerPage);
        page = totalPage === page ? page - 1 : page;
        data = data.slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage
        );
        this.setState({ data, dataLength, page, search });
    }

    getIndustryList = () => {
        this.setState({ loading: true });

        const header = {
            "Content-Type": configJSON.industryListContentType,
            token: localStorage.getItem("accesToken")
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getIndustryListApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.industryListApiEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.industryListApiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }

    addIndustry = () => {
        this.setState({ loading: true });

        const header = {
            "Content-Type": configJSON.addIndustryApiContentType,
            token: localStorage.getItem("accesToken")
        };

        const httpBody = {
            "industry_name": this.state.industryName,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.addIndustryApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.addIndustryApiEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.addIndustryApiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }

    editIndustry = () => {
        this.setState({ loading: true });

        const header = {
            "Content-Type": configJSON.editIndustryApiContentType,
            token: localStorage.getItem("accesToken")
        };

        const httpBody = {
            "industry_name": this.state.industryName,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.editIndustryApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.editIndustryApiEndPoint}/${this.state.industryId}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.editIndustryApiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }

    deleteIndustry = () => {
        this.setState({ loading: true });
        const header = {
            "Content-Type": configJSON.deleteIndustryApiContentType,
            token: localStorage.getItem("accesToken")
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.deleteIndustryApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.deleteIndustryApiEndPoint}/${this.state.industryId}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.deleteIndustryApiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }
}
// Customizable Area End