// Customizable Area Start
import React from "react";
import {
  Box,
  Button,
  Grid,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Slider,
  Link,
  Modal,
  Fade,
  TextField,
  Dialog,
  DialogContent,
  Typography,
  InputBase
} from "@material-ui/core";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import { styled } from "@material-ui/styles";
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import VisibilityIcon from "@material-ui/icons/Visibility";
import CloseIcon from '@material-ui/icons/Close';
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { excelIcon } from "./assets";
import AppSidebar from "../../../components/src/AppSidebar.web";
import "./assessorParticipant.web.css";
import Pagination from "@material-ui/lab/Pagination";
import Select from "react-select";
import AssessorParticipantController, { Props } from "./AssessorParticipantController";
import moment from 'moment';
import SearchIcon from "@material-ui/icons/Search";
import Spinner from "../../../components/src/Spinner.web";
//@ts-ignore
import { CSVLink } from 'react-csv';
const locallanguage = localStorage.getItem("language") === "ar";

class AssessorParticipant extends AssessorParticipantController {
  constructor(props: Props) {
    super(props);
  }

  renderJoinNow = (item: any) => {
    let date = moment().format("yyyy-MM-DD");
    let slotDate = item?.attributes?.schedule_date
    let isDisable = false
    if (date === slotDate) {
      let format = 'hh:mm:ss'
      let time = moment(moment().format("hh:mm:ss"), format),
        afterTime = moment(moment(item?.attributes?.end_time).utc().format(format), format),
        beforeTime = moment(moment(item?.attributes?.start_time).utc().subtract(30, 'minutes').format(format), format);
      if (!time.isBetween(beforeTime, afterTime)) {
        isDisable = true
      }
    }
    else {
      let check = date === slotDate
      isDisable = !(check)
    }
    return (<ActionBtn
      disabled={isDisable}
      onClick={() => this.handleJoinNow(item, item.index)}
    >
      {this.t("assessorParticipant.button.joinNow")}{" "}
      <ArrowForwardIcon
        style={{
          paddingLeft: "4px",
          marginTop: "-2px",
        }}
      />
    </ActionBtn>)
  }

  TableRender = () => {
    return (<Table
      aria-label="simple table"
      style={{
        border: "1px solid #E0E0E0",
        borderRadius: "10px",
        borderCollapse: "separate",
      }}
    >
      <TableHead>
        <TableRow>
          <TableCell align={locallanguage ? "right" : "left"} style={{
            fontFamily: "ProximaNova-Bold", fontSize: "16px",
          }}
          > {this.t("assessorParticipant.label.participantName")} </TableCell>
          <TableCell style={{
            fontFamily: "ProximaNova-Bold", fontSize: "16px",
          }}
            align={locallanguage ? "right" : "left"}
          > {this.t("assessorParticipant.label.client")} </TableCell>
          <TableCell
            style={{ fontFamily: "ProximaNova-Bold", fontSize: "16px", }}
            align={locallanguage ? "right" : "left"}
          > {this.t("assessorParticipant.label.date")} </TableCell>
          <TableCell
            style={{
              fontSize: "16px",
              fontFamily: "ProximaNova-Bold",
            }}
            align={locallanguage ? "right" : "left"} >
            {this.t("assessorParticipant.label.tool")} </TableCell>
          <TableCell align={locallanguage ? "right" : "left"} style={{
            fontFamily: "ProximaNova-Bold",
            fontSize: "16px",
          }}>
            {this.t("assessorParticipant.label.action")}
          </TableCell>
        </TableRow>
      </TableHead>
      {this.TableBodyRender()}
    </Table>)
  }
  TableBodyRender = () => {
    return (
      <TableBody>
        {this.state.filterData.length > 0 ? (
          <>
            {this.state.filterData.map((item: any, index: number) => (
              <TableRow key={item.id}>
                <TableCell
                  style={{
                    fontFamily: "ProximaNova-Regular",
                    fontSize: "18px",
                    color: "#333",
                  }}
                  align={locallanguage ? "right" : "left"}
                >
                  {item.attributes.name === null
                    ? "--"
                    : item.attributes.name}
                </TableCell>
                {this.ClinetNameRender(item)}
                {this.ScheduleDateTime(item)}
                {this.ToolNameRender(item)}
                {this.TableBigTableCell(item)}
              </TableRow>
            ))}
          </>
        ) : (
          <TableRow>
            <TableCell
              colSpan={6}
              align="center"
              style={{
                fontFamily: "ProximaNova-Regular",
                fontSize: "18px",
                color: "#000",
                whiteSpace: "nowrap",
              }}
            >
              {this.t("assessorParticipant.label.notFound")}
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    )
  }
  ClinetNameRender = (item:any) => {
    return (
      <TableCell
        align={locallanguage ? "right" : "left"}
        style={{
          fontFamily: "ProximaNova-Regular",
          fontSize: "18px",
          color: "#333",
        }}
      >
        {item.attributes.client}
      </TableCell>
    )
  }

  ScheduleDateTime = (item: any) => {
    return (
      <TableCell
        style={{
          fontFamily: "ProximaNova-Regular",
          fontSize: "18px",
          color: "#333",
        }}
        align={locallanguage ? "right" : "left"}
      >
        {item.attributes.schedule_date_time}
      </TableCell>
    )
  }

  ToolNameRender = (item: any) => {
    return (
      <TableCell
        align={locallanguage ? "right" : "left"}
        style={{
          fontFamily: "ProximaNova-Regular",
          fontSize: "18px",
          color: "#333",
        }}
      >
        {item.attributes.tool_name || "--"}
      </TableCell>
    )
  }

  TableBigTableCell = (item: any) => {
    return (
      <TableCell
        style={{
          fontFamily: "ProximaNova-Regular",
          fontSize: "18px",
          width: "358px"
        }}
        align={locallanguage ? "right" : "left"}
      >
        <ActionBtn onClick={() => this.onReportButtonClick(item)}>
          {this.t("assessorParticipant.button.report")}
          <VisibilityIcon
            style={{
              paddingLeft: "4px",
              marginTop: "-2px",
            }}
          />
        </ActionBtn>
        {this.renderJoinNow(item)}
        <ActionBtn
          onClick={() => this.onSetScoreButtonClick(item, item.index)}
          disabled={typeof item?.attributes?.status_meeting === "string" &&
           item?.attributes?.set_score.length === 0 ? false : true}
        >
          {this.t("assessorParticipant.button.setScore")}
          <ArrowForwardIcon
            style={{
              paddingLeft: "4px",
              marginTop: "-2px",
            }}
          />
        </ActionBtn>
      </TableCell>
    )
  }

  SetScoreDilog = () => {
    return (
      <Dialog
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        open={this.state.openDialogName === "SetScore"}
        maxWidth={"sm"}
        fullWidth={false}
        onClose={() => { }}
        scroll="body"
      >
        <DialogContent style={{ padding: "0px", width: "100%", maxWidth: "500px", }}>
          <Box style={{ margin: "10px 10px 0px 0px", textAlign: "right", }}>
            <CloseIcon style={{ cursor: "pointer", fontSize: "35px", color: "#787878" }} onClick={() => this.setState({ openDialogName: "" })} />
          </Box>
          <Box
            padding={{ xs: "15px 25px 25px", sm: "15px 40px 40px" }}
          >
            <Box
              fontSize={{ xs: "20px", sm: "30px" }}
              id="modal-title"
              fontWeight="bolder"
              mb="20px"
              fontFamily="ProximaNova-Bold"
            >
              {this.state.participantName}
            </Box>
            <Box mb={2}>
              <Typography variant="body2" style={{ fontSize: "20px", fontFamily: "ProximaNova-Semibold", }}>
                {this.t("assessorParticipant.label.selectCompetency")}
              </Typography>
              <Select
                isSearchable={false}
                className="competency-dropdown"
                classNamePrefix="react-select"
                options={this.state.scoreDetail}
                placeholder={this.t("assessorParticipant.placeholder.selectCompetency")}
                onChange={(event: any) => {
                  this.setState({ competency: event.value, competencyIndex: event.index });
                }}
                value={
                  this.state.scoreDetail.filter(
                    (option: any) =>
                      option.value === this.state.competency
                  )[0] ?? []
                }
              />
            </Box>
            <Typography variant="body2" style={{ fontSize: "20px", fontFamily: "ProximaNova-Semibold", }}>
              {this.t("assessorParticipant.title.setScore")}
            </Typography>
            <Box p="20px" border="1px solid #d6d6d6" borderRadius="5px">
              <Box>{this.t("assessorParticipant.description.setScore")}</Box>
              <Box mb="15px" mt="30px">
                <CustomSlider
                  aria-labelledby="discrete-slider-always"
                  defaultValue={0}
                  step={this.state.scoreGap}
                  min={0}
                  max={5}
                  value={this.state.scoreDetail[this.state.competencyIndex]?.score}
                  onChange={(event: React.ChangeEvent<{}>, value: number | number[]) => {
                    this.onChange("score", value);
                  }}
                  valueLabelDisplay="on"
                  marks={[
                    { label: "0", value: 0 },
                    { label: "5", value: 5 }
                  ]}
                />
              </Box>
              <CustomInputBase
                readOnly
                multiline
                fullWidth
                value={this.getDefinition(this.state.scoreDetail[this.state.competencyIndex]?.score)}
                placeholder={this.t("assessorParticipant.placeholder.definition")}
              />
            </Box>
            <Box mt="20px">
              <CustomInputBase
                minRows={4}
                multiline
                fullWidth
                value={this.state.scoreDetail[this.state.competencyIndex]?.comment}
                onChange={(event: any) => {
                  this.onChange("comment", event.target.value);
                }}
                placeholder={this.t("assessorParticipant.placeholder.comment")}
              />
            </Box>
            <Box display="flex" mt="25px" style={{ gap: "15px" }}>
              <CancelButton onClick={() => { this.setState({ openDialogName: "" }) }}>{this.t("assessorParticipant.button.cancel")}</CancelButton>
              <SubmitButton onClick={() => this.addFeedback()}>{this.t("assessorParticipant.button.submit")}</SubmitButton>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    )
  }

  ZoomMeetingDilog = () => {
    return (
      <Modal
        open={this.state.open}
        onClose={this.handleClose}
        className="modal"
      >
        <Fade in={this.state.open}>
          <Box className="paper">
            <CloseOutlinedIcon
              className="closeIcon"
              onClick={() => this.handleClose()}
            />
            <h2
              style={{
                margin: "6px 100px 31px 0",
                fontSize: "30px",
                fontWeight: "bold",
                color: " #000",
              }}
            >
              {this.t("assessorParticipant.title.zoom")}
            </h2>
            <h3
              style={{
                marginBottom: "1rem",
              }}
            >
              {this.t("assessorParticipant.description.zoom")}
            </h3>
            <div className="time">{this.state.scheduleInfo}</div>
            <BlueBox>
              <Box>
                <p
                  style={{
                    margin: "0 107px 15px 0",
                    fontSize: "16px",
                    color: "#1b3c69",
                  }}
                >
                  {this.t("assessorParticipant.label.zoom")}
                </p>
                <p
                  style={{
                    marginBottom: "0.3rem", maxWidth: " 60rem", overflow: "hidden"
                  }}
                >
                  {this.t("assessorParticipant.label.url")}:{" "}
                  <StyledLink
                    href={this.state.zoomURL}
                    target="_blank"
                    onClick={() => this.handleZoomLink()}
                  >
                    {this.state.zoomURL}
                  </StyledLink>
                </p>
                <p> {this.t("assessorParticipant.label.password")}: {this.state.zoomPassword}</p>
              </Box>
            </BlueBox>
          </Box>
        </Fade>
      </Modal>
    )
  }

  render() {
    const ProjectData = this.state.sortByClients !== "" ? this.state.projects.map((item: any) => ({
      label: item.project_name,
      value: item.project_id,
    })) : []
    return (
      <div>
        {this.state.loading && <Spinner spinnerModal={this.state.loading} />}
        <AppSidebar>
          <Box margin="35px 0px" mr="30px" data-testid="Heading">
            <Box
              display={{ xs: "block", md: "flex" }}
              justifyContent="space-between"
              mb="40px"
            >
              <Box data-testid="participantList">
                <Box
                  mb="5px"
                  fontFamily="ProximaNova-Bold"
                  fontSize={{ xs: "24px", sm: "30px", md: "44px" }}
                  color="#1b3c69"
                >
                  {this.t("assessorParticipant.title.main")}
                </Box>
                <Box color="#1b3c69" fontSize={{ xs: "14px", sm: "20px" }}>
                  {this.t("assessorParticipant.description.main")}
                </Box>
              </Box>
            </Box>
            <Box style={{ borderRadius: "10px", backgroundColor: "#eff6ff" }}>
              <Grid
                container
                spacing={4}
                style={{
                  alignItems: "end",
                  padding: "20px 15px 10px 15px",
                }}
              >
                <Grid item xs={12} sm={6} md={5}>
                  <Box style={{ color: "#1b3c69" }}>
                    <div style={{ marginBottom: "5px", fontSize: "18px" }}>
                      {this.t("assessorParticipant.label.sortBy")}
                    </div>
                    <Select
                      autofocus={true}
                      openOnFocus={true}
                      classNamePrefix="react-select"
                      placeholder={this.t("assessorParticipant.placeholder.sortBy")}
                      isSearchable={false}
                      isClearable={true}
                      options={this.state.clients.map((item: any) => ({
                        label: item.client_name,
                        value: item.client_id,
                      }))}
                      value={
                        this.state.clients.filter(
                          (opt: any) => opt.label === this.state.sortByClients
                        )[0]
                      }
                      onChange={(event: any) => {
                        this.filterClients(event);
                      }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={5}>
                  <Box style={{ color: "#1b3c69" }}>
                    <div style={{ marginBottom: "5px", fontSize: "18px" }}>
                      {this.t("assessorParticipant.label.sortByProject")}
                    </div>
                    <Select
                      classNamePrefix="react-select"
                      placeholder={this.t("assessorParticipant.placeholder.sortByProject")}
                      isSearchable={false}
                      options={ProjectData}
                      value={this.state.projects.filter((opt: any) => opt.project_name === this.state.sortByProjects)?.map((item: any) => ({ label: item.project_name, value: item.project_id })) ?? ""}
                      onChange={(event: any) => {
                        this.filterProjects(event);
                      }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                  <Box>
                    <RedButton onClick={() => this.onApplyButtonClick()}>
                      {this.t("assessorParticipant.button.apply")}
                    </RedButton>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box mt="60px">
              <Box>
                <Grid container>
                  <Grid item xs={12}>
                    <Box
                      mb="25px"
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Box
                        fontSize="22px"
                        fontWeight="bold"
                        fontFamily="ProximaNova-Semibold"
                      />
                      <Box>
                        <TextField
                          id="search"
                          value={this.state.search}
                          onChange={(event: any) => {
                            this.onChangeHandler(event.target.value, 0);
                          }}
                          placeholder={this.t("assessorParticipant.label.search")}
                          variant="outlined"
                          InputProps={{
                            startAdornment: <SearchIcon style={{ marginRight: "15px" }} />,
                            style: { borderRadius: "10px" }
                          }}
                        />
                        <DownlodBtn onClick={this.onExportAll} style={{ marginLeft: locallanguage ? "0px" : "20px", marginRight: locallanguage ? "20px" : "0px" }}>
                          <img src={excelIcon} alt="Excel" /> &nbsp;
                          {this.t("assessorParticipant.button.download")}
                        </DownlodBtn>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{ overflow: "auto" }}
                    className="Assessor-Participant"
                  >
                    <CSVLink
                      headers={this.state.csvHeader}
                      data={this.state.csvData}
                      filename={'participants.csv'}
                      target="_blank"
                      ref={(ref: any) => { this.csvLink = ref; }}
                    />
                    {this.TableRender()}
                    {this.state.filterData.length > 0 &&
                      <div className="table-Pagination-Data">
                        <Pagination
                          count={Math.ceil(this.state.dataLength / this.state.rowsPerPage)}
                          onChange={(event: unknown, newPage: number) => {
                            this.onChangeHandler(this.state.search, newPage - 1);
                          }}
                          variant="outlined"
                          shape="rounded"
                          showFirstButton
                          dir="ltr"
                          showLastButton
                          siblingCount={0}
                          boundaryCount={1}
                        />
                      </div>
                    }
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>
          {/* for Zoom Meeting Dilog */}
          {this.ZoomMeetingDilog()}
        </AppSidebar>

        {/* for Set Score */}
        {this.SetScoreDilog()}
      </div>
    );
  }
}

export default withRouter(withTranslation()(AssessorParticipant));

const CustomInputBase = styled(InputBase)({
  border: "1px solid #d6d6d6",
  minHeight: "50px",
  padding: "10px",
  paddingLeft: "15px",
  fontSize: "14px",
  borderRadius: "5px",
  '& .MuiInputBase-input': {
    "&::placeholder": {
      fontSize: "14px"
    }
  }
});

const SubmitButton = styled(Button)({
  textTransform: "none",
  borderRadius: "10px",
  backgroundColor: "#f94b4b",
  fontSize: "18px",
  color: "white",
  padding: "7px 22px",
  fontFamily: "ProximaNova-Semibold",
  "&:hover": {
    backgroundColor: "#f94b4b",
    color: "white",
  },
  "@media(max-width:600px)": {
    padding: "7px 16px",
  }
});

const CancelButton = styled(Button)({
  fontFamily: "ProximaNova-Semibold",
  border: "1px solid #f26a42",
  color: "#f26a42",
  backgroundColor: "white",
  borderRadius: "8px",
  textTransform: "none",
  fontSize: "18px",
  padding: "7px 21px",
  "&:hover": {
    backgroundColor: "white",
    color: "#f26a42",
  },
  "@media(max-width:600px)": {
    padding: "7px 15px",
  }
});

const CustomSlider = withStyles({
  root: {
    color: '#288a0a',
    height: 10,
    padding: '15px 0',
    cursor: 'default'
  },
  thumb: {
    height: 30,
    width: 30,
    top: 15,
    backgroundColor: "#288a0a",
    marginTop: -8,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)',
    },
    cursor: "pointer"
  },
  valueLabel: {
    left: 'calc(-50% + 15px)',
    fontSize: 15,
    zIndex: 0,
    top: -22,
    '& *': {
      background: 'transparent',
      color: '#000',
    },
  },
  track: {
    height: 15,
    borderRadius: 10,
    cursor: "pointer"
  },
  rail: {
    height: 15,
    borderRadius: 10,
    backgroundColor: "#9ccf81",
    cursor: "pointer"
  },
  mark: {
    display: "none",
  },
  markLabel: {
    top: "40px",
    fontSize: 15,
  }
})(Slider);

const StyledLink = styled(Link)({
  color: "#1b3c69",
  fontFamily: "ProximaNova-Semibold",
});

const BlueBox = styled(Box)({
  backgroundColor: "#eff6ff",
  color: "#1b3c69",
  borderRadius: "5px",
  fontFamily: "ProximaNova-Semibold",
  margin: "22px 0 0",
  padding: "15px 140px 15px 15px",
});

const RedButton = styled(Button)({
  backgroundColor: "#f94b4b",
  color: "white",
  borderRadius: "10px",
  fontFamily: "ProximaNova-Semibold",
  fontSize: "18px",
  textTransform: "none",
  padding: "9px 25px",
  "&:hover": {
    color: "white",
    backgroundColor: "#f94b4b",
  },
  "@media(max-width:600px)": {
    width: "100%",
    marginTop: "27px",
  },
  "@media(max-width:992px)": {
    padding: "8px 15px",
  },
});

const DownlodBtn = styled(Button)({
  color: "#eca00c",
  borderRadius: "10px",
  border: "solid 1.3px #eca00c",
  fontFamily: "ProximaNova-Semibold",
  fontSize: "14px",
  textTransform: "none",
  padding: "12px 25px",
  marginLeft: "20px",
  "&:hover": {
    color: "#eca00c",
    backgroundColor: "white",
  },
  "@media(max-width:600px)": {
    width: "100%",
    marginTop: "27px",
  },
  "@media(max-width:992px)": {
    padding: "8px 15px",
  },
});

const ActionBtn = styled(Button)({
  margin: "0 6px 0 0px",
  fontWeight: "normal",
  fontStretch: "normal",
  fontStyle: "normal",
  lineHeight: 1,
  letterSpacing: "normal",
  textAlign: "right",
  color: "#eca00c",
});
// Customizable Area End
