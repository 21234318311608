// Customizable Area Start
import React from "react";
import { Button, Card, CardContent, Typography, Grid, Box } from '@material-ui/core';
import Menu, { MenuProps } from '@material-ui/core/Menu';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import AnalyticsController, { Props } from "./AnalyticsController.web";
import {
  PieChart, Pie, Cell, ResponsiveContainer, Legend, Tooltip, BarChart,
  Bar, CartesianGrid, XAxis, YAxis
} from 'recharts';
import { withTranslation } from "react-i18next";
import AppSidebar from "../../../components/src/AppSidebar.web";
import Spinner from "../../../components/src/Spinner.web";
import { styled, withStyles } from "@material-ui/styles";
import Select from "react-select";

const orgFunctionData = [
	{
		"name": "Marketing",
		"value": 1
	},
	{
		"name": "Finance",
		"value": 2
	},
	{
		"name": "Sales",
		"value": 2
	}
]

const empTypeData = [
	{
		"name": "Full Time",
		"value": 2
	},
	{
		"name": "Part Time",
		"value": 1
	},
	{
		"name": "Contract",
		"value": 0
	}
]
const COLORS = ['#1b3c69', '#f26a42', '#24b793'];


const sortByList = [
  {
    label: 'All',
    value: 'all'
  },
  {
    label: 'Gender',
    value: 'gender'
  }, {
    label: 'Industry',
    value: 'industry'
  }, {
    label: 'Sector',
    value: 'sector'
  }, {
    label: 'Function',
    value: 'function'
  }
];

const ITEM_HEIGHT = 48;

class Analytics extends AnalyticsController {
  constructor(props: Props) {
    super(props);
  }

  ErrorPartRender = (data:any) => {
    return(
      <Grid container spacing={2} >
        {data.age_group &&
          <Grid item xs={6}>
            <Card className="card">
              <CardContent >
                <Typography className="CardTitle" >
                  {this.t("analytics.label.ageGroup")}
                </Typography>

                <ResponsiveContainer width="100%" height="100%" className="customChart">
                  <PieChart width={400} height={400}>
                    <Pie
                      data={data.age_group}
                      cx="50%"
                      cy="50%"
                      labelLine={false}
                      outerRadius={80}
                      fill="#8884d8"
                      dataKey="count"
                    >
                      {data.age_group.map((entry: any, index: any) => (
                        <Cell key={`cell-${index + 1}`} fill={COLORS[index % COLORS.length]} />
                      ))}
                    </Pie>
                    <Tooltip />
                    <Legend layout="vertical" content={this.renderLegend} />
                  </PieChart>
                </ResponsiveContainer>

              </CardContent>
            </Card>
          </Grid>
        }

        {data.employment_types &&
          <Grid item xs={6}>
            <Card className="card">
              <CardContent >
                <Typography className="CardTitle">
                  {this.t("analytics.label.employmentType")}
                </Typography>
                <ResponsiveContainer width="99%" height={250}>
                  <PieChart width={400} height={400}>
                    <Pie
                      data={data.employment_types}
                      cx="50%"
                      cy="50%"
                      labelLine={false}
                      outerRadius={80}
                      fill="#8884d8"
                      dataKey="count"
                    >
                      {data.employment_types.map((entry: any, index: any) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                      ))}
                    </Pie>
                    <Tooltip />
                    <Legend layout="vertical"
                      content={this.renderLegend}
                      formatter={this.renderColorfulLegendText} />
                  </PieChart>
                </ResponsiveContainer>
              </CardContent>
            </Card>
          </Grid>
        }

        {data.gender &&
          <Grid item xs={6}>
            <Card className="card">
              <CardContent >
                <Typography className="CardTitle">
                  {this.t("analytics.label.gender")}
                </Typography>
                <ResponsiveContainer width="100%" height="100%" className="customChart">
                  <PieChart width={400} height={400}>
                    <Pie
                      data={data.gender}
                      cx="50%"
                      cy="50%"
                      labelLine={false}
                      outerRadius={80}
                      fill="#8884d8"
                      dataKey="count"
                    >
                      {data.gender.map((entry: any, index: any) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                      ))}
                    </Pie>
                    <Tooltip />
                    <Legend layout="vertical"
                      content={this.renderLegend}
                      formatter={this.renderColorfulLegendText} />
                  </PieChart>
                </ResponsiveContainer>
              </CardContent>
            </Card>
          </Grid>
        }


        {data.total_assessments &&
          <Grid item xs={6}>
            <Card className="card">
              <CardContent >
                <Typography className="CardTitle">
                  {this.t("analytics.label.totalAssessment")}
                </Typography>
                <ResponsiveContainer width="100%" height="100%" className="customChart">
                  <PieChart width={400} height={400}>
                    <Pie
                      data={data.total_assessments}
                      cx="50%"
                      cy="50%"
                      labelLine={false}
                      outerRadius={80}
                      fill="#8884d8"
                      dataKey="count"
                    >
                      {data.total_assessments.map((entry: any, index: any) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                      ))}
                    </Pie>
                    <Tooltip />
                    <Legend layout="vertical"
                      content={this.renderAssesmentLegend}
                      formatter={this.renderColorfulLegendText} />
                  </PieChart>
                </ResponsiveContainer>
              </CardContent>
            </Card>
          </Grid>
        }

        {data.work_experience &&
          <Grid item xs={6}>
            <Card className="card">
              <CardContent >
                <Typography className="CardTitle">
                  {this.t("analytics.label.experience")}
                </Typography>
                <ResponsiveContainer width="100%" height="100%" className="customChart">
                  <PieChart width={400} height={400}>
                    <Pie
                      data={data.work_experience}
                      cx="50%"
                      cy="50%"
                      labelLine={false}
                      outerRadius={80}
                      fill="#8884d8"
                      dataKey="count"
                    >
                      {data.work_experience.map((entry: any, index: any) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                      ))}
                    </Pie>
                    <Tooltip />
                    <Legend layout="vertical"
                      content={this.renderLegend}
                    />
                  </PieChart>
                </ResponsiveContainer>
              </CardContent>
            </Card>
          </Grid>
        }

        {data.organization_function &&
          <Grid item xs={6}>
            <Card className="card">
              <CardContent >
                <Typography className="CardTitle">
                  {this.t("analytics.label.function")}
                </Typography>
                <ResponsiveContainer width="100%" height="100%" className="customChart">
                  <PieChart width={400} height={400}>
                    <Pie
                      data={data.organization_function}
                      cx="50%"
                      cy="50%"
                      labelLine={false}
                      outerRadius={80}
                      fill="#8884d8"
                      dataKey="count"
                    >
                      {data.organization_function.map((entry: any, index: any) => (
                        <Cell key={`cell-${index + 1}`} fill={COLORS[index % COLORS.length]} />
                      ))}
                    </Pie>
                    <Tooltip />

                    <Legend layout="vertical"
                      content={this.renderLegend} />
                  </PieChart>
                </ResponsiveContainer>
              </CardContent>
            </Card>
          </Grid>
        }

        {data.organization_sector &&
          <Grid item xs={6}>
            <Card className="card">
              <CardContent >
                <Typography
                  className="CardTitle">
                  {this.t("analytics.label.orgSector")}
                </Typography>
                <ResponsiveContainer width="99%" height={250}>
                  <BarChart
                    width={500}
                    height={250}
                    data={data.organization_sector}
                    margin={{
                      top: 10,
                      right: 0,
                      left: -40,
                      bottom: 0
                    }}
                  >
                    <CartesianGrid strokeDasharray="4 4" />
                    <XAxis dataKey="name" dy={5} />
                    <YAxis />
                    <Tooltip cursor={{ fill: '#f3f3f3' }} />
                    <Bar barSize={20} dataKey="count" fill="#1b3c69" />
                  </BarChart>
                </ResponsiveContainer>
              </CardContent>
            </Card>
          </Grid>
        }


        {data.organization_industry &&
          <Grid item xs={6}>
            <Card className="card">
              <CardContent >
                <Typography className="CardTitle">
                  {this.t("analytics.label.orgIndustry")}
                </Typography>
                <ResponsiveContainer width="99%" height={250}>
                  <BarChart
                    height={250}
                    data={data.organization_industry}
                    width={500}
                    margin={{
                      top: 10,
                      right: 0,
                      left: -40,
                      bottom: 0
                    }}
                  >
                    <CartesianGrid strokeDasharray="4 4" />
                    <XAxis dataKey="name" dy={5} />
                    <YAxis />
                    <Tooltip
                      cursor={{ fill: '#f3f3f3' }} />
                    <Bar
                      dataKey="count"
                      barSize={20} fill="#1b3c69" />
                  </BarChart>
                </ResponsiveContainer>
              </CardContent>
            </Card>
          </Grid>
        }

        {data.highest_education &&
          <Grid item xs={6}>
            <Card className="card">
              <CardContent >
                <Typography className="CardTitle">
                  {this.t("analytics.label.education")}
                </Typography>
                <ResponsiveContainer width="99%" height={250}>
                  <BarChart
                    width={500}
                    height={250}
                    data={data.highest_education}
                    margin={{
                      right: 0,
                      left: -40,
                      top: 10,
                      bottom: 0
                    }}
                  >
                    <CartesianGrid strokeDasharray="4 4" />
                    <XAxis dataKey="name" dy={5} />
                    <YAxis />
                    <Tooltip cursor={{ fill: '#f3f3f3' }} />
                    <Bar dataKey="count" barSize={20} fill="#1b3c69" />
                  </BarChart>
                </ResponsiveContainer>
              </CardContent>
            </Card>
          </Grid>
        }

        {data.per_tool_progress &&
          <Grid item xs={6}>
            <Card className="card">
              <CardContent>
                <Typography
                  className="CardTitle">
                  {
                    this.t("analytics.label.completion")
                  }
                </Typography>
                <ResponsiveContainer width="99%" height={250}>
                  <BarChart
                    data={data.per_tool_progress}
                    width={500}
                    height={250}
                    margin={{
                      top: 10,
                      left: -40,
                      right: 0,
                      bottom: 0
                    }}
                  >
                    <CartesianGrid strokeDasharray="4 4" />
                    <XAxis dataKey="name" dy={5} />
                    <YAxis />
                    <Tooltip cursor={{ fill: '#f3f3f3' }}
                    />
                    <Bar dataKey="count" barSize={20} fill="#1b3c69" />
                  </BarChart>
                </ResponsiveContainer>
              </CardContent>
            </Card>
          </Grid>
        }


        {data.designation &&
          <Grid item xs={6}>
            <Card className="card">
              <CardContent >
                <Typography className="CardTitle">
                  {this.t("analytics.label.designation")}
                </Typography>
                <ResponsiveContainer width="99%" height={250}>
                  <BarChart
                    height={250}
                    data={data.designation}
                    width={500}
                    margin={{
                      left: -40,
                      top: 10,
                      right: 0,
                      bottom: 0
                    }}
                  >
                    <CartesianGrid strokeDasharray="4 4" />
                    <XAxis
                      dataKey="name" dy={5} />
                    <YAxis />
                    <Tooltip cursor={{ fill: '#f3f3f3' }} />
                    <Bar dataKey="count"
                      barSize={20} fill="#1b3c69"
                    />
                  </BarChart>
                </ResponsiveContainer>
              </CardContent>
            </Card>

          </Grid>
        }


      </Grid>
    )
  }

  render() {
    const { loader, data, error } = this.state;

    return (
      <Box className="analyticsPage manage-participant" data-testid='age'>
        {loader && <Spinner spinnerModal={loader} />}
        <AppSidebar type="admin">
          <div className="main_page" data-testid="MainDiv">
            <div className="client_container">
              <div data-testid="Heading">
                <h4 data-testid="Heading2">{this.t("analytics.title.main")}</h4>
                <p data-testid="Heading3">{this.t("analytics.description.main")}</p>
              </div>
              <div className="sort_analytics" data-testid="AllData">
                {data && <RedButton href={data.url} style={{ backgroundColor: "#f26a42" }} >{this.t("analytics.button.download")}</RedButton>}
              </div>
            </div>
            <Box data-testid="sortBy" style={{ borderRadius: "10px", backgroundColor: "#eff6ff", margin: "15px 15px 15px 0" }}>
              <Grid container spacing={2} style={{ alignItems: "end", padding: "20px 15px 10px 15px" }}>
                <Grid item xs={12} sm={6} md={5}>
                  <Box style={{ color: "#1b3c69" }}>
                    <div data-testid="Heading4" style={{ marginBottom: "5px", fontSize: "18px" }}>{this.t("analytics.label.sortBy")}</div>
                    <Select
                      value={
                        sortByList.filter(
                          (option: any) =>
                            option.value === this.state.sortBy
                        )[0]
                      }
                    // id="analytics-select"
                    data-test-id="analytics-select"
                      autofocus={true}
                      isSearchable={false}
                      openOnFocus={true}
                      options={sortByList}
                      isClearable={true}
                      classNamePrefix="react-select"
                      placeholder={this.t("analytics.placeholder.sortBy")}
                      onChange={(event: any) => {
                        this.onsortByValueChange(event);
                      }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={5}>
                  <Box style={{ color: "#1b3c69" }}>
                    <div data-testid="Heading5" style={{ marginBottom: "5px", fontSize: "18px" }} >{this.t("analytics.label.selectOption")}</div>
                    <Select
                      classNamePrefix="react-select"
                      data-test-id="Heading5-select"
                      isClearable={true}
                      placeholder={this.t("analytics.placeholder.selectOption")}
                      isSearchable={false}
                      options={this.state.optionList}
                      value={
                        this.state.optionList?.filter((option: any) => option?.label === this.state.option)[0] ?? []
                      }
                      onChange={(event: any) => {
                        this.setState({ option: event !== null ? event.label : "" });
                      }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                  <Box>
                    <RedButton onClick={this.onApplyButtonClick} style={{ width: "100%" }}>{this.t("analytics.button.apply")}</RedButton>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            {!error ?
              this.ErrorPartRender(data)
              : <div className="noDataFound" >{this.t("analytics.label.notFound")}</div>}
          </div>
        </AppSidebar>
      </Box>
    );
  }
}

const RedButton = styled(Button)({
  backgroundColor: "#f94b4b",
  color: "white",
  borderRadius: "10px",
  fontFamily: "ProximaNova-Semibold",
  fontSize: "18px",
  marginTop: "20px",
  textTransform: "none",
  padding: "9px 25px",
  "&:hover": {
    color: "white",
    backgroundColor: "#f94b4b",
  },
  "@media(max-width:600px)": {
    width: "100%",
    marginTop: "27px",
  },
  "@media(max-width:992px)": {
    padding: "8px 15px",
  }
});

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const Accordion = withStyles({
  root: {
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: '#fff',
    color: '#1b3c69',
    marginBottom: -1,
    minHeight: 40,
    '&$expanded': {
      minHeight: 40,
    },
  },
  content: {
    '&$expanded': {
      margin: '10px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    pointerEvents: 'auto',
  },
}))(MuiAccordionDetails);


export default withTranslation()(Analytics);
// Customizable Area End
