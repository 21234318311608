Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "LandingPage";
exports.labelBodyText = "LandingPage Body";

exports.btnExampleTitle = "CLICK ME";
exports.endPointApiGetURLData = "/bx_block_admin/admin/generate_url"
exports.getParticipantData = "/account_block/participants"
exports.getAllClientsData = "/account_block/clients"
exports.getToolsData = "/bx_block_admin/online_tools";
exports.getAssessorData= "/account_block/assessors";
exports.getAnalyticsData= "account_block/participants/get_admin_analytics"
// Customizable Area End