import React from "react";

import {
  StyleSheet,
  Text,
  Image,
  TouchableOpacity,
  View,
  TextInput,
  ScrollView,
  TouchableWithoutFeedback,
  Button,
  Platform,
  // Customizable Area Start
  // Customizable Area End
} from "react-native";

import BulkUploadingController, {
  Props,
  configJSON
} from "./BulkUploadingController";

export default class BulkUploading extends BulkUploadingController {
constructor(props: Props) {super(props);
    // Customizable Area Start
    // Customizable Area End
}// Customizable Area Start
  // Customizable Area End
render() {
return (
      // Customizable Area Start
<ScrollView keyboardShouldPersistTaps="always" style={styles.container}>
<TouchableWithoutFeedback onPress=
{() => {this.hideKeyboard();}}
        >
          {/* Customizable Area Start */}
          {/* Merge Engine UI Engine Code */}
  <View>{this.isPlatformWeb() ? (<Text
                style=
                {styles.title} //UI Engine::From Sketch
                testID=
                "labelTitle" //Merge Engine::From BDS
              >{configJSON.labelTitleText}
              </Text> //UI Engine::From Sketch
            ) 
            :
             null}

            <Text           style={styles.body} //UI Engine::From Sketch
                                 testID="labelBody" //Merge Engine::From BDS
            >
              {" "}
              {/* UI Engine::From Sketch */}
              {configJSON.labelBodyText} {/* UI Engine::From Sketch */}
            </Text>

            <Text testID="txtSaved">
This is the reviced value:{this.state.txtSavedValue}
              {" "}
              {/* Merge Engine::From BDS - {...this.testIDValue} */}
            </Text>

            <View style={styles.bgPasswordContainer}>
              <TextInput               style={styles.bgMobileInput} //UI Engine::From Sketch
                testID="txtInput" //Merge Engine::From BDS
                     placeholder={configJSON.txtInputPlaceholder}  {...this.txtInputProps} //Merge Engine::From BDS - {...this.testIDProps}
              />

                 <TouchableOpacity style={styles.showHide}       testID={"btnShowHide"} //Merge Engine::From BDS
                        {...this.btnShowHideProps} //Merge Engine::From BDS - {...this.testIDProps}
                  >
                <Image
                      style={styles.imgShowhide} //UI Engine::From Sketch
                    testID={"btnShowHideImage"} //Merge Engine::From BDS - testIDImage
                          {...this.btnShowHideImageProps}            />
          </TouchableOpacity>
                </View>

                    <Button
                    title={configJSON.btnExampleTitle} //UI Engine::From Sketch
                    testID={"btnExample"} {...this.btnExampleProps} 
            />
          </View>
          {/* Merge Engine UI Engine Code */}
          {/* Customizable Area End */}
        </TouchableWithoutFeedback>
      </ScrollView>
      // Customizable Area End
    );}
}

// Customizable Area Start
const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginLeft: "auto",
    marginRight: "auto",
    padding: 16,
       width: Platform.OS === "web" ? "75%" : "100%",
    maxWidth: 650,
    backgroundColor: "#ffffffff"
  },
  title: {
        textAlign: "left",
    marginVertical: 8,
    marginBottom: 32,
    fontSize: 16,
  },
  body: {
    textAlign: "left",
    marginVertical: 8,
    marginBottom: 32,
    fontSize: 16,
  },
  bgPasswordContainer: {
    marginBottom: 16,
    borderBottomWidth: 1,
    flexDirection: "row",
    backgroundColor: "#00000000",
    borderColor: "#767676",
    borderRadius: 2,
    padding: 10,
    borderWidth: Platform.OS === "web" ? 0 : 1
  },
  bgMobileInput: {
    flex: 1,
    padding:10
  },
  showHide: {
    alignItems: "center"
  },
  imgShowhide: Platform.OS === "web" ? { width: 30 ,height: 30, } : {}
});
// Customizable Area End
