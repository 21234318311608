// Customizable Area Start
import React from "react";

function ManageUsersSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="21"
      height="20"
      viewBox="0 0 21 20"
    >
      <defs>
        <path id="path-1" d="M0 0H20.007V20H0z"></path>
      </defs>
      <g fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(-35 -274)">
          <g transform="translate(35 274)">
            <mask id="mask-2" fill="#fff">
              <use xlinkHref="#path-1"></use>
            </mask>
            <path
              fillRule="nonzero"
              d="M14.533 9.468a2.4 2.4 0 00-3.92-1.508l-.655.551-.655-.553a2.401 2.401 0 00-3.92 1.508v.01a21.183 21.183 0 00.074 5.378v.008a1.31 1.31 0 001.818.96l.045 1.787a.403.403 0 00.402.392h.01a.402.402 0 00.392-.411l-.074-2.927c0-.035 0-.071-.002-.109l-.002-.016-.107-4.2a.402.402 0 10-.804.02l.11 4.3a.503.503 0 01-.996.064 20.317 20.317 0 01-.066-5.15 1.597 1.597 0 012.605-1l.913.771c.002.003.007.005.01.007l.013.012.022.014.011.007c.012.008.024.012.036.02l.005.002c.011.004.021.01.033.014l.01.002.028.007c.004 0 .007.003.011.003.01.002.02.002.031.005a.233.233 0 00.05.005c.014 0 .026 0 .04-.003.002 0 .007 0 .01-.002.009-.003.018-.003.03-.005.005 0 .007-.003.012-.003l.028-.007.01-.002c.012-.005.021-.007.033-.014.002 0 .002 0 .005-.003a.25.25 0 00.047-.026l.021-.014c.005-.002.01-.007.014-.012.003-.002.008-.004.01-.007l.912-.77a1.595 1.595 0 012.606 1.002c.239 1.719.215 3.45-.066 5.142a.505.505 0 01-1-.118.334.334 0 00-.005-.085l.104-4.102a.402.402 0 10-.804-.019l-.182 7.2a.402.402 0 00.393.41h.009a.4.4 0 00.402-.392l.045-1.792a1.313 1.313 0 00.539.118 1.31 1.31 0 001.286-1.073v-.005c.298-1.775.322-3.589.073-5.39z"
              mask="url(#mask-2)"
            ></path>
            <path
              d="M9.963 7.156a2.137 2.137 0 002.135-2.135 2.137 2.137 0 00-2.135-2.135 2.137 2.137 0 00-2.135 2.135 2.137 2.137 0 002.135 2.135zm0-3.468c.736 0 1.331.598 1.331 1.33 0 .734-.598 1.332-1.331 1.332s-1.33-.598-1.33-1.331.595-1.331 1.33-1.331z"
              mask="url(#mask-2)"
            ></path>
            <path
              fillRule="nonzero"
              d="M5.324 8.303a.41.41 0 00.308-.142.404.404 0 00-.047-.567l-.916-.771A2.401 2.401 0 00.75 8.332a17.908 17.908 0 00.071 5.39v.005a1.31 1.31 0 001.818.96l.046 1.777a.403.403 0 00.401.393h.01a.402.402 0 00.392-.411l-.073-2.92c0-.036 0-.069-.002-.104 0-.005-.003-.01-.003-.017l-.104-4.204a.402.402 0 10-.804.02l.11 4.3a.507.507 0 01-.443.473.508.508 0 01-.555-.41 17.032 17.032 0 01-.067-5.141A1.597 1.597 0 014.152 7.44l.912.77c.079.06.17.093.26.093z"
              mask="url(#mask-2)"
            ></path>
            <path
              d="M5.33 6.017a2.137 2.137 0 002.134-2.135 2.137 2.137 0 00-2.135-2.135 2.137 2.137 0 00-2.135 2.135c0 1.177.958 2.135 2.135 2.135zm0-3.466a1.332 1.332 0 010 2.662 1.334 1.334 0 01-1.332-1.331c0-.733.598-1.331 1.331-1.331zM14.595 6.017a2.137 2.137 0 002.135-2.135 2.137 2.137 0 00-2.135-2.135 2.137 2.137 0 00-2.135 2.135c0 1.177.957 2.135 2.135 2.135zm0-3.466c.735 0 1.331.598 1.331 1.33 0 .734-.598 1.332-1.331 1.332a1.334 1.334 0 01-1.331-1.331c0-.733.596-1.331 1.33-1.331z"
              mask="url(#mask-2)"
            ></path>
            <path
              fillRule="nonzero"
              d="M19.165 8.331a2.4 2.4 0 00-3.92-1.508l-.915.77a.402.402 0 10.518.615l.912-.77a1.595 1.595 0 012.606 1.002 17.04 17.04 0 01-.066 5.143.505.505 0 01-1-.119.379.379 0 00-.005-.09l.104-4.154a.401.401 0 10-.804-.019l-.182 7.245a.401.401 0 00.392.411h.01a.401.401 0 00.402-.392l.045-1.783a1.325 1.325 0 00.541.118 1.31 1.31 0 001.286-1.073v-.005c.303-1.776.327-3.59.076-5.39z"
              mask="url(#mask-2)"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default ManageUsersSvg;
// Customizable Area End