// Customizable Area Start
import React from "react";
import { Popover, Container } from "@material-ui/core";
import { Formik } from "formik";
import "../src/style.css";
import {help } from "./assets";
// @ts-ignore
import shrmLogo from '../assets/shrm.png'
import ResetPasswordController, { Props } from "./ResetPasswordController";
import { resetPasswordSchema } from "./validationSchema";
import { withTranslation } from "react-i18next";
const LanguageData = localStorage.getItem("language") === "ar";

class ResetPassword extends ResetPasswordController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { isReinitailize, anchorEl } = this.state;
    return (
      <>
        <Formik
          enableReinitialize={isReinitailize}
          initialValues={{
            newPassword: "",
            confirmNewPassword: "",
          }}
          validationSchema={()=> resetPasswordSchema(this.t)}
          onSubmit={(values, { setSubmitting }) => {
            this.handleSubmit(values)
          }}
          validateOnChange={true}
          validateOnBlur={true}
        >
          {props => (
            <div style={{ background: "#1B3C69" }}>
              <Container className="container">
                <div className="checkEmail resetPassword">
                  <img src={shrmLogo} alt="logo" className="shrmlogo" />
                  <div className="contentWrapper">
                    <div className="whiteBox">
                      <div className="title">{this.t("ResetPassword.TitltResetYourPassword")}</div>
                      <div className="content">{this.t("ResetPassword.Pleaseenteryournewpassword")}</div>
                      <section>
                        <form onSubmit={props.handleSubmit} className="resetPsform">
                          <div className="form-control">
                            <label htmlFor="newPassword" className="field-label">
                              {this.t("ResetPassword.NewPassword")}
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <div>
                              <input
                                name="newPassword"
                                id="newPassword"
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                                value={props.values.newPassword}
                                placeholder={this.t("ResetPassword.Enternewpassword")}
                                autoComplete="off"
                                type="password"
                                style={{
                                  border: props.errors.newPassword && props.touched.newPassword
                                    ? "2px solid red"
                                    : "",
                                }}
                                className="custom-form-control formInputone"
                              />
                              <img
                                src={help}
                                className="helpImage"
                                style={{
                                  marginLeft: !LanguageData ? "7px" : "",
                                  marginRight: LanguageData ? "7px" : ""
                                }}
                                aria-owns={
                                  Boolean(anchorEl)
                                    ? "mouse-over-popover"
                                    : undefined
                                }
                                aria-haspopup="true"
                                onMouseEnter={this.handlePopoverOpen}
                                onMouseLeave={this.handlePopoverClose}
                              />
                              <Popover
                                id="mouse-over-popover"
                                open={Boolean(anchorEl)}
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                  vertical: "top",
                                  horizontal: "right",
                                }}
                                transformOrigin={{
                                  vertical: 20,
                                  horizontal: -10,
                                }}
                                onClose={this.handlePopoverClose}
                                disableRestoreFocus
                                marginThreshold={16}
                                className="popoverWrapper"
                                style={{ pointerEvents: "none" }}
                              >
                                <div className="popover">
                                {this.t("ResetPassword.Yourpasswordmustbe")}
                                  <br />{this.t("ResetPassword.8CharachtersLong")}
                                  <br />
                                  {this.t("ResetPassword.UppercaseLetter")}
                                  <br />
                                  {this.t("ResetPassword.LowercaseLetter")}
                                  <br />
                                  {this.t("ResetPassword.Alphanumeric")}
                                  <br /> {this.t("ResetPassword.ASpecialCharacter")}
                                </div>
                              </Popover>
                            </div>
                            {props.errors.newPassword && props.touched.newPassword ? (<p className="validation_errors">{props.errors.newPassword}</p>) : null}
                          </div>
                          <div className="form-control">
                            <label
                              htmlFor="confirmNewPassword"
                              className="field-label"
                            >
                              {this.t("ResetPassword.ConfirmNewPassword")}
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                              name="confirmNewPassword"
                              id="confirmNewPassword"
                              onChange={props.handleChange}
                              onBlur={props.handleBlur}
                              value={props.values.confirmNewPassword}
                              placeholder={this.t("ResetPassword.Reenternewpassword")}
                              type="password"
                              className="custom-form-control"
                              style={{
                                border: props.errors.confirmNewPassword && props.touched.confirmNewPassword
                                  ? "2px solid red"
                                  : "",
                              }}
                            />
                            {props.errors.confirmNewPassword && props.touched.confirmNewPassword ? (<p className="validation_errors">{props.errors.confirmNewPassword}</p>) : null}
                          </div>
                          <div style={{ display : "flex", justifyContent: "space-between"}}>
                            <button className="btn_text cancelButton" 
                            onClick={this.handleCancel} 
                            // onClick={()=>this.handleCancel()} 
                              type="button">
                              {this.t("ResetPassword.Cancel")}
                            </button>
                            <button className="btn_text saveButton" type="submit">{this.t("ResetPassword.Save")}</button>
                          </div>
                        </form>
                      </section>
                    </div>
                    <div className="copyright" dir="ltr">
                      {this.state.copyright}
                    </div>
                  </div>
                </div>
              </Container>
            </div>
          )}
             {/* <Link to="/EmailAccountRegistration">Login</Link> */}
        </Formik>
      </>
    );
  }
}
export default withTranslation()(ResetPassword)
// Customizable Area End
