// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import * as xlsx from 'xlsx'
import * as fileSaver from 'file-saver'
import { toast } from "react-toastify";
// Customizable Area End

// Customizable Area Start
// Customizable Area End


export const configJSON = require("./config");

export interface Props {
    id?: string;
    navigation?: any;
    location: any;
    history: any;
    match: any;
    // Customizable Area Start
    t: any;
    // Customizable Area End
}

export interface S {
    // Customizable Area Start
    loading: boolean;
    page: number;
    rowsPerPage: number;
    dataLength: number;
    count: number | null;
    item: any;
    sortBy: string;
    option: string;
    search: string;
    openDialogName: string | null;
    sortByClients: string,
    sortByProjects: string,
    data: any[];
    clients: any;
    projects: any;
    AllProjectist: any[];
    tableData: any[];
    filterData: any[];
    sortByList: any[];
    optionList: any[];
    industryList: any[];
    competencyList: any[];
    onlineToolList: any[];
    csvHeader: any[];
    csvData: any[];
    open: boolean;
    scoreModal: boolean;
    attendCall: boolean;
    zoomURL: string;
    zoomPassword: string;
    scheduleInfo: any;
    sliderRange: any[];
    score: string;
    currentIndex: number;
    participantId: any;
    scoreValue: any;
    comment: any;
    slotId: any;
    competency: any;
    participantName: string;
    competencyIndex: number;
    scoreDetail: any[];
    definition: any[];
    scoreGap: number;
    zoomDetails: any[],
    currentParticipant: any;
    setScoreDisable : boolean;
    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}
// Customizable Area Start
export default class AssessorParticipantController extends BlockComponent<Props, S, SS> {

    csvLink: any = "";
    t: any = "";


    getAssessorParticipantsAPICall: string = "";
    applyFilterAPICall: string = "";

    zoomCallApiCallID: string = "";
    addFeedbackApiCallId: string = "";
    callJoinedApiCallId: string = "";
    competencyListApiCallId: string = "";
    changeToolStatusApiCallId: string = "";

    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage),];
        this.receive = this.receive.bind(this);
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
        this.state = {
            loading: true,
            page: 0,
            rowsPerPage: 20,
            openDialogName: "",
            sortByClients: "",
            sortByProjects: "",
            data: [], projects: [],
            AllProjectist: [],
            tableData: [],
            clients: [],

            filterData: [],
            sortByList: [],
            optionList: [],
            industryList: [],
            competencyList: [],
            onlineToolList: [],
            dataLength: 0,
            count: null,
            item: null,
            sortBy: "",
            option: "",
            search: "",
            csvHeader: [{ label: 'Participant Name', key: 'participantName' },
            { label: 'Client', key: 'client' },
            { label: 'Scheduled date & time', key: 'dnt' },
            ],
            csvData: [], currentIndex: 0,
            participantId: 0,
            scoreValue: 0,
            comment: "",
            slotId: null,
            participantName: "",
            open: false,
            scoreModal: false,
            attendCall: true,
            zoomURL: "",
            zoomPassword: "",
            scheduleInfo: "",
            sliderRange: [],
            score: "",

            competency: "",
            competencyIndex: 0,
            scoreDetail: [],
            scoreGap: 1,
            definition: [],
            zoomDetails: [],
            currentParticipant: {},
            setScoreDisable : true
        };
        this.t = this.props.t;
    }

    async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (apiRequestCallId && responseJson) {
                if (responseJson.status === 500) {
                    toast.error(this.t("assessorParticipant.toast.somethingWrong"));
                    this.setState({ loading: true });
                }
                else if (!Array.isArray(responseJson.errors) && responseJson.errors) { toast.error(responseJson.errors); }
                else {
                    this.handleApiResponse(apiRequestCallId, responseJson);
                }
            }
        }
    }
    handleApiResponse = (apiRequestCallId: string, responseJson: any) => {
        if (Array.isArray(responseJson.errors)) {
            if (responseJson.errors[0].message) {
                toast.error(responseJson.errors[0].message);
            } else if (responseJson.errors[0].token) {
                toast.error(responseJson.errors[0].token);
            } else {
                toast.error(this.t("assessorParticipant.toast.somethingWrong"));
            }
            this.setState({ loading: false });
        } else if (apiRequestCallId === this.getAssessorParticipantsAPICall) {
            const data = responseJson.data.map((item: any, index: number) => {
                return { ...item, index: index, isClicked: false };
            });
            if (this.props.location?.state?.filter === undefined) {
                this.setState({
                    projects: responseJson.projects,
                    data
                })
            }
            this.setState({
                clients: responseJson.clients,
                AllProjectist: responseJson.projects,
                loading: false,
                search: ""
            }, () => { this.onChangeHandler(this.state.search, 0) });
        }
        this.handleApiResponse2(apiRequestCallId, responseJson)
    }

    handleApiResponse2 = (apiRequestCallId: string, responseJson: any) => {
        if (apiRequestCallId === this.applyFilterAPICall) {
            const data = responseJson.data.map((item: any, index: number) => {
                return { ...item, index: index, isClicked: false };
            });
            this.setState({
                data,
                loading: false,
                search: "",
            }, () => { this.onChangeHandler(this.state.search, 0) });
        } else if (apiRequestCallId === this.zoomCallApiCallID) {
            this.setState({ zoomDetails: responseJson?.data })
        } else if (apiRequestCallId === this.competencyListApiCallId) {
            this.handleCompetencyListApiResponse(responseJson);
        } 
        else if (apiRequestCallId === this.callJoinedApiCallId) {
            this.getAssessorData()
        }
        else if (apiRequestCallId === this.addFeedbackApiCallId) {
            this.getAssessorData()
            this.setState({setScoreDisable : true})
            toast.success(this.t("assessorParticipant.toast.addFeedback"));
            let data = [...this.state.data];
            data = data.map((item: any) => {
                if (item.index === this.state.currentIndex) {
                    return { ...item, isClicked: false };
                } else {
                    return item;
                }
            });
            this.setState({ data, openDialogName: "", currentIndex: 0, scoreDetail: [], competency: "", loading: false, search: "" }, () => {
                this.onChangeHandler(this.state.search, this.state.page);
            });
            this.getAssessorData()
        }
    }

    handleCompetencyListApiResponse = (responseJson: any) => {
        let scoreDetail = responseJson.competency_list.map((item: any, index: number) => {
            return {
                index: index,
                label: item.name,
                value: item.id,
                score: 0,
                comment: ""
            }
        });
        const scoreGap = responseJson.set_increament ?? "0.5";
        const ratings = responseJson.ratings.data.filter((rating: any) => {
            if (rating.attributes.range === scoreGap) {
                return true;
            } else {
                return false;
            }
        });

        const definition = ratings.map((obj: any) => {
            return {
                min: Number(obj.attributes.min),
                max: Number(obj.attributes.max),
                description: obj.attributes.project_ratings
                    ? obj.attributes.project_ratings.description
                    : obj.attributes.description
            }
        });
        this.setState({ scoreDetail, definition, scoreGap: Number(scoreGap), competency: scoreDetail[0]?.value, openDialogName: "SetScore", loading: false });
    }

    onChange = (name: string, value: any) => {
        const scoreDetail = [...this.state.scoreDetail];
        scoreDetail[this.state.competencyIndex][name] = value;
        this.setState({ scoreDetail });
    }

    async componentDidMount() {
        this.getZoomCall();
        this.getAssessorData();
        if (this.props.location?.state?.filter) {
            this.onApplyFilterOnNavigate(this.props.location.state.data.attributes.id)
        }
    }

    getDefinition = (score: number) => {
        const definition = this.state.definition;
        for (const obj of definition) {
            if (obj.min <= score && obj.max >= score) {
                return obj.description;
            }
        }
        return "-";
    }

    onReportButtonClick = (obj: any) => {
        this.props.history.push({
            pathname: "/ParticipantReport",
            state: {
                participantId: obj.attributes.participant_id,
                participantName: obj.attributes.name,
                Locationtoolid: obj.attributes.tool_id
            }
        });
    }

    apiCall = (data: any) => {
        const token = localStorage.getItem("accesToken") as string
        const { contentType, method, endPoint, body, type } = data
        const header = {
            'Content-Type': contentType,
            token: token
        }
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage)
        )
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header))
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        )
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        )
        body && type != 'formData' ? requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(body)
        ) : requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            body
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return requestMessage.messageId;
    }

    filterClients = (event: any) => {
        if (event === null) {
            if (this.props.location?.state?.filter) {
                this.onApplyFilterOnNavigate(this.props.location.state.data.attributes.id)
            } else {
                this.getZoomCall(); this.getAssessorData();
            }
        }
        let data = this.state.AllProjectist.filter((item: any) => item.client_name.toLowerCase() === event?.label.toLowerCase())
        this.setState({ sortByClients: event !== null ? event.label : "", projects: data, sortByProjects: "" })
    }

    filterProjects = (event: any) => {
        this.setState({ sortByProjects: event !== null ? event.label : "" })
    }

    getAssessorData(): boolean {
        const header = {
            token: localStorage.getItem('accesToken')
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getAssessorParticipantsAPICall = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getListAssesorParticipants
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);


        return true;
    }

    onApplyButtonClick = () => {
        this.setState({ loading: true })
        const client_id = this.state.sortByClients !== "" ?
            this.state.clients.find((opt: any) => opt.client_name === this.state.sortByClients).client_id
            : ""
        const project_id = this.state.sortByProjects !== "" ?
            this.state.projects.find((opt: any) => opt.project_name === this.state.sortByProjects).project_id
            : ""

        const header = {
            token: localStorage.getItem('accesToken')
        };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage)
        );
        this.applyFilterAPICall = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header));
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getListAssesorParticipants}?project_id=${project_id}&client_id=${client_id}`
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    onApplyFilterOnNavigate = (project_id: any) => {
        const header = {
            token: localStorage.getItem('accesToken')
        };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage)
        );
        this.applyFilterAPICall = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header));
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getListAssesorParticipants}?project_id=${project_id}`
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    TABLE_COLUMNS = [
        { field: 'Participant List', header: 'Participants List' },
        { field: 'Clients', header: 'Clients' },
        { field: 'Scheduled Date & Time', header: 'Scheduled Date & Time' },
    ];

    onExportAll = () => {
        let csvData = [];
        csvData = this.state.data.map((item: any) => {
            const { attributes } = item
            return {
                participantName: attributes.name,
                client: attributes.client,
                dnt: attributes.schedule_date_time
            }
        });

        this.setState({ csvData, openDialogName: "Success" }, () => this.csvLink && this.csvLink.link && this.csvLink.link.click());
    }

    saveAsExcelFile = (fileName: string, exportData: any) => {
        const worksheet = xlsx.utils.json_to_sheet(exportData);
        const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
        const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
        let EXCEL_TYPE = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([excelBuffer], { type: EXCEL_TYPE });
        const file = `${fileName}_${EXCEL_EXTENSION}`;
        fileSaver.default.saveAs(data, file);

    }

    onChangeHandler = (search: string, page: number) => {
        const { data, rowsPerPage } = this.state;
        let filterData = data.filter((item: any) => {
            if (search === "") {
                return true;
            } else if (
                item.attributes.client.toString().toLowerCase().includes(search.toLowerCase()) ||
                item.attributes.name.toString().toLowerCase().includes(search.toLowerCase()) ||
                item.attributes.tool_name?.toString().toLowerCase().includes(search.toLowerCase())
            ) {
                return item;
            }
        });

        const dataLength = filterData.length;
        filterData = filterData.slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage
        );
        this.setState({ filterData, dataLength, page, search });
    }

    exportExcel = () => {
        const exportFields = this.TABLE_COLUMNS.map((column: any) => column.field);
        const exportData = this.state.data.map((dataObj: any) => {
            const filteredObj: any = {};
            exportFields.forEach((fieldName: any) => (filteredObj[fieldName] = dataObj[fieldName]));
            return filteredObj;
        });
        this.saveAsExcelFile("List-Participants", exportData)
    }

    handleJoinNow = (item: any, index: number) => {
        this.callJoined(item.attributes.participant_id,item.attributes.assessor_lead_tool_id)
        let selectedObj = this.state.zoomDetails?.filter((val: any) => Number(val.attributes.participant_id) === item.attributes.participant_id)
        let temp = selectedObj?.filter((val: any) => Number(val.attributes.assessor_lead_tool_id) === item.attributes.assessor_lead_tool_id)
        this.setState({
            currentIndex: index, open: true,
            scheduleInfo: item?.attributes?.schedule_date_time,
            zoomURL: temp[0]?.attributes?.start_url,
            zoomPassword: temp[0]?.attributes?.password,
        })
        this.setState({setScoreDisable : false})
        this.getAssessorData()
        
    };

    handleScoreModal = () => {
        this.setState({ scoreModal: !this.state.scoreModal })
    };

    onSetScoreButtonClick = (item: any, index: number) => {
        this.setState({
            slotId: item.attributes.id, currentIndex: index, participantName: item.attributes.name,
            currentParticipant: item
        });
        this.getCompetencyList(item.attributes.project_id);

    };

    handleClose = () => {
        this.setState({ open: false })
    };
    changeStatusOfTool = (val: any) => {
        this.changeToolStatusApiCallId = this.apiCall({
            contentType: "application/json",
            method: "POST",
            endPoint: `bx_block_profile/participant_meeting_status?assessor_lead_tool_id=${val.attributes.assessor_lead_tool_id}&status=Completed&id=${val?.attributes?.participant_id}`,
        });
    }

    handleZoomLink = () => {
        let data = [...this.state.data];
        data = data.map((item: any) => {
            if (item.index === this.state.currentIndex) {
                return { ...item, isClicked: true };
            } else {
                return item;
            }
        });
        this.setState({ data, open: false }, () => this.onChangeHandler(this.state.search, this.state.page));
    };

    getZoomCall = () => {
        this.zoomCallApiCallID = this.apiCall({
            contentType: "application/json",
            method: "GET",
            endPoint: "/account_block/assessors/assessor_meeting_calenders",
        });
    }

    getCompetencyList = (id: any) => {
        this.setState({ loading: true });
        this.competencyListApiCallId = this.apiCall({
            contentType: "application/json",
            method: "POST",
            endPoint: `/bx_block_profile/projects/project_competancy_list?id=${id}`,
        });
    }

    callJoined = (id:any, toolId:any) => {
        this.callJoinedApiCallId = this.apiCall({
            contentType: "application/json",
            method: "POST",
            endPoint: `/account_block/zoom_meeting_value`,
            body : {
                "status_meeting": "joined",
                "assessor_lead_tool_id": toolId,
                "participant_id": id
            }
        });
    }

    addFeedback = () => {
        this.setState({ loading: true });
        const arr = this.state.scoreDetail.map((item: any) => {
            return {
                competancy_id: item.value,
                score: item.score.toString(),
                comment: item.comment
            }
        });

        this.changeStatusOfTool(this.state.currentParticipant)
        

        this.addFeedbackApiCallId = this.apiCall({
            contentType: "application/json",
            method: "POST",
            endPoint: `/account_block/assessors/set_score?id=${this.state.slotId}`,
            body: {
                "assessor_participant_slots": arr
            }
        });
    }
}
// Customizable Area End

