// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { toast } from "react-toastify";
import moment from "moment";
import { Country, State } from 'country-state-city';
export const configJSON = require("./config");

export interface Props {
  id?: string;
  navigation?: any;
  location: any;
  history: any;
  match: any;
  t: any;
}

export interface S {
  loading: boolean;
  firstName: any;
  lastName: any;
  companyName: any;
  gender: any;
  dob: any;
  phoneNumber: any;
  designation: any;
  workExperience: any;
  country: any;
  countryCode: any;
  state: any;
  stateCode: any;
  city: any;
  highestEducation: any;
  employmentType: any;
  organizationFunction: any;
  organizationSector: any;
  organizationIndustry: any;
  tenure: any;
  certificate: any;
  designationList: any[];
  employmentTypeList: any[];
  highestEductionList: any[];
  organizationSectorList: any[];
  organizationIndustryList: any[];
  organizationFunctionList: any[];
  isInfoFetched: boolean;
  copyright: string;
}

export interface SS {
  id: any;
}

export default class EmailAccountRegistrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  getSectorListApiCallId: string = "";
  getFunctionListApiCallId: string = "";
  getDesignationListApiCallId: string = "";
  getEmploymentTypeListApiCallId: string = "";
  geIndustryListApiCallId: string = "";
  getBasicInfoApiCallId: string = "";
  getEductionListApiCallId: string = "";
  submitApiCallId: string = "";
  t: any;
  getCopyrightApiCallId: string = "";

  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);
    this.state = {
      loading: false,
      firstName: "",
      lastName: "",
      companyName: "",
      gender: "",
      dob: "",
      phoneNumber: "",
      designation: "",
      workExperience: "",
      country: "",
      countryCode: null,
      state: "",
      stateCode: null,
      city: "",
      highestEducation: "",
      employmentType: "",
      organizationFunction: "",
      organizationSector: "",
      organizationIndustry: "",
      tenure: "",
      certificate: "",
      designationList: [],
      employmentTypeList: [],
      highestEductionList: [],
      organizationSectorList: [],
      organizationIndustryList: [],
      organizationFunctionList: [],
      isInfoFetched: false,
      copyright: `© ${new Date().getFullYear()} SHRM India. All Rights Reserved.`
    }
    this.t = this.props.t;

  }

  async componentDidMount() {
    const token = localStorage.getItem("accesToken");
    if (!token) {
      this.props.history.goBack();
      return;
    }
    this.getCopyright();
    this.getDesignationList();
    this.getHighestEductionList();
    this.getEmploymentTypeList();
    this.getOrganizationFunctionList();
    this.getOrganizationSectorList();
    this.getOrganizationIndustryList();
    this.fetchParticipantInformation();
  }

  componentDidUpdate() {
    if (this.state.isInfoFetched) {
      this.setState({ isInfoFetched: false });
    }
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {
        this.handleApiResponse(apiRequestCallId, responseJson);
      }
    }
  }
checkError = (responseJson:any)=>{
  if (responseJson.errors[0].token) {
    toast.error(responseJson.errors[0].token);
  } else if (responseJson.errors[0].account) {
    toast.error(responseJson.errors[0].account);
  } else if (responseJson.errors[0].message) {
    toast.error(responseJson.errors[0].message);
  } else {
    toast.error(this.t("basicInfo.toast.somethingWrong"));
  }
  this.setState({ loading: false });
}
  handleApiResponse = (apiRequestCallId: any, responseJson: any) => {
    if (Array.isArray(responseJson.errors)) {
      this.checkError(responseJson)
    } else if (apiRequestCallId === this.submitApiCallId) {
      let res = responseJson?.data?.attributes;
      localStorage.setItem("user", res?.first_name + " " + res?.last_name);
      toast.success(this.t("basicInfo.toast.add"));
      this.props.history.push("Welcome");
    } else if (apiRequestCallId === this.getSectorListApiCallId) {
      this.handleOrganizationSectorListApiResponse(responseJson);
    } else if (apiRequestCallId === this.getFunctionListApiCallId) {
      this.handleOrganizationFunctionListApiResponse(responseJson);
    } else if (apiRequestCallId === this.getDesignationListApiCallId) {
      this.handleDesignationListApiResponse(responseJson);
    } else if (apiRequestCallId === this.getEmploymentTypeListApiCallId) {
      this.handleEmploymentTypeListApiResponse(responseJson);
    } else if (apiRequestCallId === this.geIndustryListApiCallId) {
      this.handleOrganizationIndustryListApiResponse(responseJson);
    } else if (apiRequestCallId === this.getEductionListApiCallId) {
      this.handleHighestEducationListApiResponse(responseJson);
    } else if (apiRequestCallId === this.getBasicInfoApiCallId) {
      this.handleAlreadyLoginApiResponse(responseJson);
    } else if (apiRequestCallId === this.getCopyrightApiCallId) {
      if(Array.isArray(responseJson)){
        this.setState({ copyright: responseJson[0].copyright });
      }
    }
  }

  handleDesignationListApiResponse = (responseJson: any) => {
    const designationList = responseJson.data.map((item: any) => {
      return {
        label: item.attributes.name,
        value: item.attributes.id
      }
    });
    this.setState({ designationList });
  }

  handleHighestEducationListApiResponse = (responseJson: any) => {
    const highestEductionList = responseJson.data.map((item: any) => {
      return {
        label: item.attributes.name,
        value: item.attributes.id
      }
    });
    this.setState({ highestEductionList });
  }

  handleEmploymentTypeListApiResponse = (responseJson: any) => {
    const employmentTypeList = responseJson.data.map((item: any) => {
      return {
        label: item.attributes.employment_type_name,
        value: item.attributes.id
      }
    });
    this.setState({ employmentTypeList });
  }

  handleOrganizationFunctionListApiResponse = (responseJson: any) => {
    const organizationFunctionList = responseJson.data.map((item: any) => {
      return {
        label: item.attributes.name,
        value: item.attributes.id
      }
    });
    this.setState({ organizationFunctionList });
  }

  handleOrganizationSectorListApiResponse = (responseJson: any) => {
    const organizationSectorList = responseJson.data.map((item: any) => {
      return {
        label: item.attributes.name,
        value: item.attributes.id
      }
    });
    this.setState({ organizationSectorList });
  }

  handleOrganizationIndustryListApiResponse = (responseJson: any) => {
    const organizationIndustryList = responseJson.data.map((item: any) => {
      return {
        label: item.attributes.industry_name,
        value: item.id
      }
    });
    this.setState({ organizationIndustryList });
  }

  handleAlreadyLoginApiResponse = (responseJson: any) => {
    const countryName = responseJson.data.attributes.country;
    const stateName = responseJson.data.attributes.state;
    const countryCode = Country.getAllCountries().filter((country: any) => country.name === countryName)[0]?.isoCode;
    const stateCode = State.getStatesOfCountry(countryCode).filter((state: any) => state.name === stateName)[0]?.isoCode;
    const date = responseJson.data.attributes.date_of_birth?.split("/").reverse().join("-");
    if (responseJson.data.attributes.gender !== null) {
      this.setState({
        loading: true
      }, () => {
        this.props.history.push("Welcome")
      })
    }
    this.setState({
      loading: false,
      firstName: responseJson.data.attributes.first_name ?? "",
      lastName: responseJson.data.attributes.last_name ?? "",
      companyName: responseJson.data.attributes.company_name ?? "",
      gender: responseJson.data.attributes.gender,
      dob: date ? new Date(responseJson.data.attributes.date_of_birth.split("/").reverse().join("-")) : "",
      phoneNumber: responseJson.data.attributes.phone_number?.toString(),
      designation: responseJson.data.attributes.org_designation?.id,
      workExperience: responseJson.data.attributes.overall_work_experience,
      country: countryName,
      countryCode: countryCode,
      state: stateName,
      stateCode: stateCode,
      city: responseJson.data.attributes.city,
      highestEducation: responseJson.data.attributes.highest_eduction?.id,
      employmentType: responseJson.data.attributes.employment_type?.id,
      organizationFunction: responseJson.data.attributes.org_function?.id,
      organizationSector: responseJson.data.attributes.sector?.id,
      organizationIndustry: responseJson.data.attributes.industry?.id.toString(),
      tenure: responseJson.data.attributes.organistion_work_experience,
      certificate: responseJson.data.attributes.professional_certification ?? "",
      isInfoFetched: true
    });
  }

  getDesignationList = () => {
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("accesToken")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getDesignationListApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      '/bx_block_admin/org_designations'
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getHighestEductionList = () => {
    this.setState({ loading: true });
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("accesToken")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getEductionListApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      '/bx_block_admin/highest_eductions'
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getEmploymentTypeList = () => {
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("accesToken")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getEmploymentTypeListApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      '/bx_block_profile/employment_types'
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getOrganizationFunctionList = () => {
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("accesToken")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getFunctionListApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      '/bx_block_admin/org_functions'
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getOrganizationSectorList = () => {
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("accesToken")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSectorListApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      '/bx_block_admin/sectors'
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getOrganizationIndustryList = () => {
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("accesToken")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.geIndustryListApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      '/bx_block_profile/industries'
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getCopyright = () => {
    const header = {
      "Content-Type": "application/json",};
    

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage)

    );

    this.getCopyrightApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      '/bx_block_admin/footer_copyrights'
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage)
      ,JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage)
      ,"GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  fetchParticipantInformation = () => {
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("accesToken")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getBasicInfoApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      '/account_block/accounts/profile'
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  handleSubmit = () => {
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("accesToken")
    };

    const attrs = {
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      company_name: this.state.companyName,
      gender: this.state.gender,
      date_of_birth: moment(this.state.dob).format("YYYY-MM-DD"),
      country: this.state.country,
      state: this.state.state,
      city: this.state.city,
      overall_work_experience: this.state.workExperience,
      highest_eduction_id: this.state.highestEducation,
      professional_certification: this.state.certificate,
      employment_type_id: this.state.employmentType,
      org_designation_id: this.state.designation,
      org_function_id: this.state.organizationFunction,
      sector_id: this.state.organizationSector,
      industry_id: this.state.organizationIndustry,
      organistion_work_experience: this.state.tenure,
      phone_number: this.state.phoneNumber
    };

    const httpBody = {
      data: {
        attributes: attrs
      }
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.submitApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'account_block/accounts/basic_information'
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
}
// Customizable Area End