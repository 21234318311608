// Customizable Area Start
import React from "react";
import { Grid, Container } from "@material-ui/core";
import { withTranslation } from "react-i18next";
import { play } from "./assets";
// @ts-ignore
import welcome from '../assets/welcome.png';
// @ts-ignore
import welcome2 from '../assets/welcome2.png';
import AppHeader from "../../../components/src/AppHeader.web";
import { styled } from "@material-ui/styles";
import AppFooter from "../../../components/src/AppFooter.web";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

import WelcomeParticipantController, {
  Props,
} from "./WelcomeParticipantController";
import Loader from "../../../components/src/Loader.web";

class WelcomeParticipant extends WelcomeParticipantController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { playing, screenData } = this.state;

    return (
      <>
      {
        this.state.isLoading && !screenData &&(
        <Backdrop open={this.state.isLoading}>
        <CircularProgress color="primary" />
      </Backdrop>)
      }
        <AppHeader />
        {screenData && !this.state.isLoading && screenData.attributes?.project_content_sections?.data.length > 0 && (
          <Container className="container">
            <div className="welcomePage">
              <div>
                <div className="welcomeTitle" data-testid="header">{screenData?.attributes?.parent_heading || null}</div>
                <div className="sectionBody" dangerouslySetInnerHTML={{ __html: screenData?.attributes?.parent_content }} />
              </div>
              {screenData && screenData.attributes?.project_content_sections?.data?.map((sec: any, index: any) => {
                return (
                  sec.attributes.image_url.type.includes("image") ? <Grid container spacing={2} className="sectionWrapper" data-testid="content" direction={
                    (index + 1) % 2 === 0 ? "row-reverse" : "row"}>
                    <Grid item xs={12} md={4} className="imageWrapper"
                    >
                      <div style={{ position: "relative" }}>
                        <img src={sec.attributes.image_url.url} alt="img"
                          style={{ height: "100%", width: "100%", objectFit: "cover" }} />
                      </div>
                    </Grid>
                    <Grid item xs={12} md={8} >
                      <div style={{ padding: "0 2rem 0 2rem" }}>
                        <div className="welcomeTitle">{sec.attributes.heading || null}</div>
                        <div className="sectionTitle">{sec.attributes.title || null}</div>
                        <div className="sectionBody" dangerouslySetInnerHTML={{ __html: sec.attributes.html }} />
                      </div>
                    </Grid>
                  </Grid> :
                    <div className="videoSectionWrapper sectionWrapper" data-testid="video">
                      <div className="sectionBody">
                        <div className="welcomeTitle">{sec.attributes.heading || null}</div>
                        <div className="sectionTitle">{sec.attributes.title || null}</div>
                      </div>
                      <WelcomeScreenstyle>
                        <div className="videoWrapper">
                          {/* @ts-ignore */}
                          <video
                            className="video"
                            controls
                            width="470"
                            height="255"
                            src={sec.attributes.image_url.url}
                          />
                        </div>
                      </WelcomeScreenstyle>
                      <div className="sectionBody" dangerouslySetInnerHTML={{ __html: sec.attributes.html }} />
                    </div>
                )
              })}
              <div style={{ width: "100%", display: "flex" }}>
                <button className="dashboardBtn" onClick={this.handleDashboard} data-testid="btn">{this.t("welcome.button.dashboard")}</button>
              </div>
            </div>
          </Container>)}
          {screenData == null && !this.state.isLoading && (<div style={{ flex: "1", justifyContent:"center", alignItems:"center", display:"flex" }}>
            <h1 style={{ fontFamily: "ProximaNova-Regular" }}>{this.t("welcome.Projectnotyetassignedtothisparticipant")}</h1>
          </div>)}
        <AppFooter />
      </>
    );
  }
}
export default (withTranslation()(WelcomeParticipant))
const WelcomeScreenstyle = styled("div")({
  '& .videoWrapper': {
    '& ::-webkit-media-controls-timeline': {
      display: 'none'
    },
  }
})
// Customizable Area End
