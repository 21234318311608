// Customizable Area Start
import React from "react";
import {
Button,
TextField,
Grid,
Paper,
Typography,
InputLabel
} from "@material-ui/core";

import "./EmailAccountRegistration.css";
import { Link } from "react-router-dom"
import EmailAccountRegistrationController, {
    Props
  } from "./EmailAccountRegistrationController";
  
  export default class EmailAccountRegistration extends EmailAccountRegistrationController {
    constructor(props:Props) {
        super(props);
        
        this.handleSubmit = this.handleSubmit.bind(this)
      
    }
    formValidation = () => {
        const {email , password} = this.state
        let isValid = true;
        const errors = {email:"",password:"",emailvalid:""};
        if(email.trim().length < 6){
            errors.email = "email is inValid";
            isValid = false;
        }
        if(!email.includes('@')){
            errors.emailvalid = "email is inValid";
            isValid = false;
        }
        if(password.trim().length < 8){
            errors.password = "Password length must be 8 word";
            isValid = false;
        }
        console.log("errors",errors)
        return isValid;
    }
    handleSubmit(){
         
        const isValid = this.formValidation()
        if(isValid){
            let data = {
                email:this.state.email,
                password:this.state.password
            }
            console.log("data",data)
        }
       
    }

    render() {
        const {email,password} = this.state
        return (
            <div style={{background:"darkslategrey",height:"100%"}}>
            <Grid container spacing={0} justify="center" direction="row">
            <Grid item>
            <Grid
            container
            direction="column"
            justify="center"
            spacing={2}
            className="login-form"
            >
            <Paper
            variant="elevation"
            elevation={2}
            className="login-background"
            >
            <Grid item>
            <Typography component="h1" variant="h5">
            LogIn Page
            </Typography>
            </Grid>
            <Grid item>
            {/* <form onSubmit={(e)=>this.handleSubmit}> */}
            <Grid container direction="column" spacing={2}>
            <Grid item>
                <InputLabel>
                Email:
            <TextField
            type="email"
            placeholder="Email"
            fullWidth
            name="username"
            variant="outlined"
            value={email}
            onChange={(e)=>this.setState({email:e.target.value})}
            required
            autoFocus
            />
                </InputLabel>
               
            </Grid>
            <Grid item>
                <InputLabel>
                Password :
            <TextField
            type="password"
            placeholder="Password"
            fullWidth
            name="password"
            value={password}
            onChange={(e)=>this.setState({password:e.target.value})}
            variant="outlined"
            required
            />
            </InputLabel>
            </Grid>
            <Grid item>
            <Button
            variant="contained"
            color="primary"
            type="submit"
            className="button-block"
            onClick={()=>this.handleSubmit()}
            >
            Submit
            </Button>
            </Grid>
            
            </Grid>
            {/* </form> */}
            </Grid>
            <Grid item>
            <Link to="/EmailAccountForgetPassword">
            Forgot Password?
            </Link>
            </Grid>
            </Paper>
            </Grid>
            </Grid>
            </Grid>
            </div>
          );
    }
}
// Customizable Area End