// Customizable Area Start
import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { toast } from "react-toastify";

export const configJSON = require("./config");

export interface Props {
    navigation?: any;
    id?: string;
    t: any;
}

interface S {
    data: any;
    loader: boolean;
    classes: any;
    open: boolean;
    successModal: boolean;
    weekendsVisible: boolean;
    currentDate: any;
    currentEvents: any;
    participantId: any;
    assessorId: any;
    slotId: any;
    toolId: any
    slots: any;
    error: boolean;
    startDate: any;
    endDate: any;
    events: any;
    
}

interface SS {
    id: any;
}


export default class AnalyticsController extends BlockComponent<
    Props,
    S,
    SS
> {
    t: any = "";


    constructor(props: Props) {

        super(props);
        this.receive = this.receive.bind(this)


        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.PostDetailDataMessage)
        ]


        this.state = {
            data: [],
            loader: true,
            classes: "",
            open: false,
            successModal: false,
            weekendsVisible: true,
            currentDate: "",
            currentEvents: [],
            slotId: "",
            toolId: "",
            participantId: "",
            assessorId: "",
            slots: [],
            startDate: "",
            endDate: "",
            events: [],
            error: false,
        };
        this.t = this.props.t;
        // @ts-ignore
        this.callRef = React.createRef();
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    handleToastMessage() {
        toast.success('participant added succesfully', {
            position: toast.POSITION.TOP_RIGHT
        });
    }

    sentMessage(data: any) {
        const msg: Message = new Message(getName(MessageEnum.PostDetailDataMessage))
        msg.properties['text'] = data
        this.send(msg)
    }

    handleDashboard = () => {
        this.props.navigation.navigate("Dashboard")
    }


}
// Customizable Area End
