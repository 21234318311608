import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { toast } from "react-toastify";
import * as Yup from "yup";
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  id?: string;
  navigation?: any;
  location: any;
  history: any;
  match: any;
}

export interface S {
  // Customizable Area Start
  data: any[];
  PdfModel: any;
  isExpanded: any;
  isExpandedInner: any;
  MediaFile: any;
  loader: boolean;
  loading: boolean;
  ContentType: any;
  CreateContentModel: boolean;
  currentIndex: number;
  isAddToolButtonClick: boolean;
  currentToolLogo: any;
  currentToolName: any;
  currentToolDescription: any;
  deleteTool: any[];
  AddAndEditDilogState: boolean;
  DeleteModel: boolean;
  onlineToolList: any[];
  editToolId: any;
  Lableindex: any;
  ContentMainData: any;
  ImagesRender: any;
  EditForm: boolean;
  DeleteTableData: boolean;
  DeleteIdOfData: null;
  Previewdata: any[];
  Usertype: any;
  ApproveModel: any;
  TextForModel: any;
  SectionId: any;
  EditSectionId: any;
  OldContent: any;
  ContentId: any;
  ShowID: any;
  StutasOfData: any;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}
export default class ContentManagementControllerWeb extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  GetProjectDetailApiCallId: string = "";
  DeleteAPi: string = "";
  addToolApiCallId: string = "";
  editToolApiCallId: string = "";
  ApproveAPiCallApi: string = "";
  RejectAPiCallApi: string = "";
  ShowDataOfContent: string = "";
  inputRef: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      data: [],
      PdfModel: false,
      isExpanded: false,
      MediaFile: [],
      loading: false,
      loader: false,
      ContentType: "",
      isExpandedInner: false,
      CreateContentModel: false,
      currentIndex: 0,
      isAddToolButtonClick: false,
      currentToolLogo: "",
      currentToolName: "",
      currentToolDescription: "",
      deleteTool: [],
      AddAndEditDilogState: false,
      DeleteModel: false,
      onlineToolList: [],
      editToolId: null,
      Lableindex: null,
      ContentMainData: [],
      ImagesRender: [],
      EditForm: false,
      DeleteTableData: false,
      DeleteIdOfData: null,
      Previewdata: [],
      Usertype: false,
      ApproveModel: false,
      TextForModel: "",
      SectionId: "",
      EditSectionId: "",
      OldContent: [],
      ContentId: null,
      ShowID: "",
      StutasOfData: "",
      // Customizable Area End
    };
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.AllAPicall(apiRequestCallId, responseJson);
    }
  }

  AllAPicall = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId && responseJson) {
      if (responseJson.status === 500) {
        toast.error("Something went wrong!");
      } else if (Array.isArray(responseJson.errors)) {
        if (responseJson.errors[0].message) {
          toast.error(responseJson.errors[0].message);
        } else if (responseJson.errors[0].token) {
          toast.error(responseJson.errors[0].token);
        } else {
          toast.error("Something went wrong!");
        }
        this.setState({ loading: false });
      } else if (apiRequestCallId === this.GetProjectDetailApiCallId) {
        this.setState({
          data: responseJson.data,
          loader: false,
        });
      } else if (apiRequestCallId === this.addToolApiCallId) {
        this.GetProjectList();
        this.CretaModelOpenAndClose();
      } else if (apiRequestCallId === this.DeleteAPi) {
        this.GetProjectList();
        this.DeleteTableDataMode([]);
      } else if (apiRequestCallId === this.editToolApiCallId) {
        this.GetProjectList();
        this.setState({
          CreateContentModel: !this.state.CreateContentModel,
          onlineToolList: [],
          ContentMainData: [],
          editToolId: null,
          currentIndex: -1,
          currentToolLogo: null,
          currentToolName: null,
          currentToolDescription: null,
          EditForm: false,
          isAddToolButtonClick: false,
        });
      }
      this.ApproveAndRejectAPiCall(apiRequestCallId, responseJson);
    }
  };

  ApproveAndRejectAPiCall = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.ApproveAPiCallApi) {
      this.GetProjectList();
      this.ApproveModelFuncation({ id: null }, "");
    } else if (apiRequestCallId === this.RejectAPiCallApi) {
      this.ApproveModelFuncation({ id: null }, "");
      this.GetProjectList();
    } else if (apiRequestCallId === this.ShowDataOfContent) {
      this.setState({
        Previewdata: [responseJson?.data],
        OldContent:
          responseJson.old_content.data !== undefined
            ? [responseJson?.old_content?.data]
            : [],
        loader: false,
      });
    }
  };

  async componentDidMount() {
    this.GetProjectList();
    const TypeOfUser = localStorage.getItem("ContentType");
    if (TypeOfUser?.toLocaleLowerCase() === "admin") {
      this.setState({
        Usertype: true,
      });
    }
  }

  HtmlConverter = (data: any) => {
    return { __html: data };
  };

  pdfModelDataSet = (items: any, type: any) => {
    this.setState({
      PdfModel: true,
      ContentType: type,
      MediaFile: items,
    });
  };

  LableDataSet = (items: any, type: any, index: any) => {
    this.setState({
      PdfModel: true,
      ContentType: type,
      MediaFile: items,
      Lableindex: index,
    });
  };

  ApproveModelFuncation = (item: any, text: any) => {
    this.setState({
      ApproveModel: !this.state.ApproveModel,
      TextForModel: text,
      SectionId: Number(item.id),
    });
  };

  PdfModelClose = () => {
    this.setState({
      PdfModel: false,
    });
  };

  onSaveTool = (index: any) => {
    let dataUpdate = [...this.state.onlineToolList];
    dataUpdate[index].isUpdate = true;
    if (dataUpdate[index].label === "") {
      toast.warn("Add heading for Save Data");
    } else {
      this.setState({
        onlineToolList: dataUpdate,
        editToolId: "",
        isAddToolButtonClick: false,
      });
    }
  };

  AcordingOpen = (panel: any) => (even: any, Expanded: any) => {
    this.setState({
      isExpanded: Expanded ? panel : false,
      isExpandedInner: false,
    });
  };

  AcordingOpenInner = (panel: any) => (even: any, Expanded: any) => {
    this.setState({
      isExpandedInner: Expanded ? panel : false,
    });
  };

  CretaModelOpenAndClose = () => {
    this.setState({
      CreateContentModel: !this.state.CreateContentModel,
      onlineToolList: [],
      ContentMainData: [],
      editToolId: null,
      currentIndex: -1,
      currentToolLogo: null,
      currentToolName: null,
      currentToolDescription: null,
      EditForm: false,
      isAddToolButtonClick: false,
      OldContent: [],
    });
  };

  PreviewDataClose = () => {
    this.setState({
      Previewdata: [],
    });
  };

  DeleteTableDataMode = (item: any) => {
    this.setState({
      DeleteTableData: !this.state.DeleteTableData,
      DeleteIdOfData: item.id,
    });
  };

  GetProjectList = () => {
    this.setState({ loader: true });

    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("accesToken"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.GetProjectDetailApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.contentsAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  AssignAvailableAssessorEditSchema = () => {
    return Yup.object().shape({
      heading: Yup.string().required("Please Upload Heading"),
      content: Yup.string().required("Please Upload Content"),
    });
  };

  onDeleteToolButtonClick = (tool: any) => {
    this.setState({
      deleteTool: tool,
      DeleteModel: !this.state.DeleteModel,
      isAddToolButtonClick: false,
      onlineToolList: this.state.onlineToolList.filter((item) => item !== tool),
    });
    setTimeout(() => {
      if (this.state.onlineToolList.length === 0) {
        this.setState({
          isAddToolButtonClick: false,
        });
      }
    }, 100);
  };

  onToolNameChange = (event: any, index: number) => {
    let onlineToolList = [...this.state.onlineToolList];
    onlineToolList[index].label = event.target.value;
    if (this.state.EditForm) {
      onlineToolList[index].isLabelUpdate = true;
    }
    this.setState({ onlineToolList });
  };

  onToolDescriptionChange = (event: any, index: number) => {
    let onlineToolList = [...this.state.onlineToolList];
    onlineToolList[index].description = event;
    if (this.state.EditForm) {
      onlineToolList[index].isDescription = true;
    }
    this.setState({ onlineToolList });
  };

  addToolRecord = () => {
    let onlineToolList = [...this.state.onlineToolList];
    const obj = {
      image: null,
      label: "",
      description: "",
      value: onlineToolList.length,
      index: onlineToolList.length,
      isUpdate: false,
    };
    onlineToolList = [...onlineToolList, obj];
    this.setState({
      onlineToolList,
      editToolId: onlineToolList.length - 1,
      isAddToolButtonClick: true,
    });
  };

  onEditButtonClick = (tool: any, index: number) => {
    let onlineToolList = [...this.state.onlineToolList];
    if (this.state.editToolId) {
      onlineToolList[this.state.currentIndex].isUpdate = false;
    }

    this.setState({
      onlineToolList,
      editToolId: tool.value,
      currentIndex: index,
      currentToolLogo: tool.image,
      currentToolName: tool.label,
      currentToolDescription: tool.description,
      isAddToolButtonClick: true,
    });
  };

  onImageChange = (file: any, index: number) => {
    let onlineToolList = [...this.state.onlineToolList];
    onlineToolList[index].isImageChange = true;
    onlineToolList[index].image = file;
    this.setState({ onlineToolList });
  };
  onAudioChange = (file: any, index: number) => {
    let onlineToolList = [...this.state.onlineToolList];
    onlineToolList[index].isAudioChange = true;
    onlineToolList[index].audio = file;
    this.setState({ onlineToolList });
  };

  onVideoChange = (file: any, index: number) => {
    let onlineToolList = [...this.state.onlineToolList];
    onlineToolList[index].isVideoChange = true;
    onlineToolList[index].video = file;
    this.setState({ onlineToolList });
  };

  onPdfChange = (file: any, index: number) => {
    let onlineToolList = [...this.state.onlineToolList];
    onlineToolList[index].isPdfChange = true;
    onlineToolList[index].pdf = file;
    this.setState({ onlineToolList });
  };

  handelOnlineToolsApiResponse = (responseJson: any) => {
    const onlineToolList = responseJson.attributes.sections.data.map(
      (item: any) => {
        this.setState({
          EditSectionId: item.id,
        });
        return {
          value: item.attributes.id,
          label: item.attributes.description.data.attributes.heading,
          image: item.attributes.description.data.attributes.image,
          description: item.attributes.description.data.attributes.content,
          index: item.attributes.id,
          isUpdate: false,
          descriptionId: item.attributes.description.data.attributes.id,
        };
      }
    );
    this.setState({
      onlineToolList,
      loading: false,
      CreateContentModel: true,
      ContentMainData: responseJson?.attributes?.description.data.attributes,
      EditForm: true,
      ContentId: responseJson.attributes.id,
    });
  };

  DataAppend = (items: any, formData: any, i: number) => {
    if (items.image !== null) {
      formData.append(
        `sections_attributes[${i}][description_attributes][image]`,
        items.image
      );
    }
  };

  addTool = (values: any) => {
    toast.warn(`Please wait`);
    const data = this.state.onlineToolList.filter(
      (items) => items.isUpdate === true
    );
    const onlineToolList = [...data];

    this.setState({ loading: true });

    const header = {
      token: localStorage.getItem("accesToken"),
      contentType: "multipart/form-data",
    };

    const formData = new FormData();
    Object.keys(values).forEach((keys: any, index: any) => {
      if (values[keys] !== undefined && values[keys] !== "") {
        if (values[keys].length === 0 && values[keys] !== undefined) {
          formData.append(`description_attributes[${keys}]`, values[keys]);
        } else {
          if (values[keys] !== undefined) {
            formData.append(`description_attributes[${keys}]`, values[keys]);
          }
        }
      }
    });
    onlineToolList.forEach((items: any, i: any) => {
      this.DataAppend(items, formData, i);
    });
    onlineToolList.forEach((items: any, index) => {
      formData.append(
        `sections_attributes[${items.index}][description_attributes][heading]`,
        items.label
      );
    });
    onlineToolList.forEach((items: any, index) => {
      formData.append(
        `sections_attributes[${items.index}][description_attributes][content]`,
        items.description
      );
    });

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addToolApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/bx_block_content_management/generic_contents"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  SubmitData = (values: any) => {
    let array: any = [];
    this.state.onlineToolList.forEach((item: any) => {
      array.push(
        item.isImageChange,
        item.isVideoChange,
        item.isPdfChange,
        item.isAudioChange,
        item.isDescription,
        item.isLabelUpdate
      );
    });
    let Validate =
      array.map((item: any) => item === false).includes("true") === false;
    const DataOfForm =
      this.state.ContentMainData.heading === values.heading &&
      this.state.ContentMainData.content === values.content &&
      this.state.ContentMainData.content === values.content &&
      this.state.ContentMainData.audio === values.audio &&
      this.state.ContentMainData.image === values.image &&
      this.state.ContentMainData.video === values.video &&
      this.state.ContentMainData.pdf === values.pdf;
    if (this.state.isAddToolButtonClick) {
      toast.warn("Please save Your data");
    } else if (this.state.EditForm && DataOfForm && Validate) {
      toast.warn("Please Update Anything");
    } else {
      this.state.EditForm ? this.editTool(values) : this.addTool(values);
    }
  };

  EditAppendData = (item: any, formData: any, index: any) => {
    if (
      item.isImageChange ||
      item.isVideoChange ||
      item.isPdfChange ||
      item.isAudioChange ||
      item.isDescription ||
      item.isLabelUpdate
    ) {
      formData.append(`sections_attributes[0][id]`, this.state.EditSectionId);
      item.descriptionId !== undefined &&
        formData.append(
          `sections_attributes[${index}][description_attributes][id]`,
          item.descriptionId
        );
    }
    if (item.isImageChange) {
      formData.append(
        `sections_attributes[${index}][description_attributes][image]`,
        item.image
      );
    }
    if (item.isVideoChange) {
      formData.append(
        `sections_attributes[${index}][description_attributes][video]`,
        item.video
      );
    }
    if (item.isPdfChange) {
      formData.append(
        `sections_attributes[${index}][description_attributes][pdf]`,
        item.pdf
      );
    }
    if (item.isAudioChange) {
      formData.append(
        `sections_attributes[${index}][description_attributes][audio]`,
        item.audio
      );
    }
    if (item.isDescription) {
      formData.append(
        `sections_attributes[${index}][description_attributes][content]`,
        item.description
      );
    }
    if (item.isLabelUpdate) {
      formData.append(
        `sections_attributes[${index}][description_attributes][heading]`,
        item.label
      );
    }
  };

  editTool = (values: any) => {
    toast.warn(`Please wait`);
    this.setState({
      loader: true,
    });
    const AllSecetionData = this.state.onlineToolList.filter(
      (items) => items.isUpdate === true
    );
    const formData = new FormData();
    if (this.state.ContentMainData.heading !== values.heading) {
      formData.append(`description_attributes[heading]`, values.heading);
    }
    if (this.state.ContentMainData.content !== values.content) {
      formData.append(`description_attributes[content]`, values.content);
    }
    if (
      this.state.ContentMainData.image !== values.image &&
      values.image !== null &&
      values.image !== ""
    ) {
      formData.append(`description_attributes[image]`, values.image);
    }

    const DataOfForm =
      this.state.ContentMainData.heading === values.heading &&
      this.state.ContentMainData.content === values.content &&
      this.state.ContentMainData.content === values.content &&
      this.state.ContentMainData.audio === values.audio &&
      this.state.ContentMainData.image === values.image &&
      this.state.ContentMainData.video === values.video &&
      this.state.ContentMainData.pdf === values.pdf;
    DataOfForm === false &&
      formData.append(
        `description_attributes[id]`,
        this.state.ContentMainData.id
      );

    AllSecetionData.forEach((item: any, index: any) => {
      this.EditAppendData(item, formData, index);
    });

    this.setState({ loading: true });

    const header = {
      token: localStorage.getItem("accesToken"),
      contentType: "multipart/form-data",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.editToolApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_content_management/generic_contents/${this.state.ContentId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  DeleteData = () => {
    this.setState({ loader: true });

    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("accesToken"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.DeleteAPi = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_content_management/generic_contents/${this.state.DeleteIdOfData}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  ApproveAPiCall = () => {
    this.setState({ loader: true });

    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("accesToken"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.ApproveAPiCallApi = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_content_management/generic_contents/${this.state.SectionId}/approve_request`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  RejectAPiCall = () => {
    this.setState({ loader: true });

    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("accesToken"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.RejectAPiCallApi = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_content_management/generic_contents/${this.state.SectionId}/reject_request`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  ViewDataOfContent = (id: any) => {
    this.setState({ loader: true });

    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("accesToken"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.ShowDataOfContent = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_content_management/generic_contents/${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
}

// Customizable Area End
