// Customizable Area Start
import { Box, Button } from "@material-ui/core";
import moment from "moment";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import React from "react";
import { withTranslation } from "react-i18next";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import Swal from "sweetalert2";
import AppHeader from "../../../components/src/AppHeader.web";
import AppFooter from "../../../components/src/AppFooter.web";
import { groupCheck } from "./assets";
const { baseURL } = require("../../../framework/src/config.js");
import "./Calendar.web.css";
import interactionPlugin from "@fullcalendar/interaction";
import CalendarController, { Props } from "./CalendarController.web";
const locallanguage = localStorage.getItem("language") === "ar";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  })
);


class DefaultCalendar extends CalendarController {
  constructor(props: Props) {
    super(props);
  }

  fetchEvents = async () => {
    const token = localStorage.getItem("accesToken");
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        token: `${token}`,
      },
    };

    await fetch(
      `${baseURL}/bx_block_calendar/booked_slots/calender`,
      requestOptions
    )
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw new Error("Something Went Wrong");
        }
      })
      .then((res) => {
  if (res) {       let events = res?.events?.map((event: any) => {
            return {      title: event.title,
        description: event.description,
        AssessorName:event.assessor_name,
      date: moment(event.start).utc().format("YYYY-MM-DD"),
timeStart: moment(event.start).utc().format("hh:mm A"),
timeEnd: moment(event.end).utc().format("hh:mm A"),
color: moment().isAfter(moment(event.start).format("YYYY-MM-DD hh:mm")) ? "green" : "#ADD8E6",
          start: `${moment(event.start).utc().format()}`,
          end: `${moment(event.end).utc().format()}`
            };
      });this.setState({ events: events });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  async componentDidMount() {
    this.fetchEvents();
    // @ts-ignore
    const Assessor = JSON.parse(localStorage?.getItem("AssessorList"));
    // @ts-ignore
    const AssessorId = JSON.parse(localStorage?.getItem("AssessorId"));
    // @ts-ignore
    const toolid = JSON.parse(localStorage?.getItem("toolId"));
    // @ts-ignore
    const ParticipantID = JSON.parse(localStorage?.getItem("participantId"));
    this.setState({
      assessorId: AssessorId,
      participantId: ParticipantID,
      endDate: Assessor?.end_date,
      startDate: Assessor?.start_date,
      toolId: toolid,
    });
  }
  handleSuccessModal = () => {
    this.setState({ successModal: false });
    this.handleDashboard();
  };
  handleSubmit = () => {
    const slotId = this.state.slots?.filter(
      (slot: any) =>
        `${moment(slot?.attributes?.start_time)
          .utc()
          .format("hh:mm a")} - ${moment(slot?.attributes?.end_time)
            .utc()
            .format("hh:mm a")}` === this.state.slotId
    );
    if (slotId?.[0]?.id) {
      this.setState({ open: false, successModal: true, error: false });
      const token = localStorage.getItem("accesToken");
      const jsonpayload = {
        participant_id: this.state.participantId,
        assessor_id: this.state.assessorId,
        assessor_slot_id: slotId?.[0]?.id,
        slot_date: moment(this.state?.currentDate).format("DD-MM-YYYY"),
        assessor_lead_tool_id: this.state.toolId,
      };
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          token: `${token}`,
        },
        body: JSON.stringify(jsonpayload),
      };
      this.fetchData(requestOptions);
    } else {
      this.setState({ error: true });
    }
  };
  fetchData = (requestOptions: any) => {
    return fetch(
      `${baseURL}/bx_block_profile/slots/assign_participant_slot`,
      requestOptions
    )
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw new Error("Something Went Wrong");
        }
      })
      .then((res) => {
        if (res) {
          console.log(res);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleClose = () => {
    this.setState({ open: false });
  };
  currentDateInRange = () => {
    const currentDate = moment(this.state?.currentDate).format("DD-MM-YYYY");
    const StartDate = moment(this.state?.startDate).format("DD-MM-YYYY");
    const EndDate = moment(this.state?.endDate).format("DD-MM-YYYY");
    const temp = moment(currentDate.toString(), "DD-MM-YYYY");
    const startDate = moment(StartDate.toString(), "DD-MM-YYYY").add(
      -1,
      "days"
    );
    const endDate = moment(EndDate.toString(), "DD-MM-YYYY").add(1, "days");
    return temp?.isBetween(startDate, endDate);
  };
  fetchSlot = async () => {
    const token = localStorage.getItem("accesToken");
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        token: `${token}`,
      },
    };
    await fetch(
      `${baseURL}/bx_block_profile/slots/available_assessor_slot?slot_date=${this.state.currentDate}
    &assessor_id=${this.state.assessorId}&assessor_lead_tool_id=${this.state.toolId}`,
      requestOptions
    )
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw new Error("Something Went Wrong");
        }
      })
      .then((res) => {
        if (res) {
          this.setState({ slots: res?.data });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleDateClick = (date: any) => {

    this.setState({ currentDate: date?.date });
    console.log("this.state.currentDate", this.state.currentDate)
    this.fetchSlot();
    let isValid = this.currentDateInRange();
    if (isValid) {
      setTimeout(() => {
        this.setState({ open: true });
      }, 500);
    }

  };
  render() {
    return (
      <Box className="calendarModule">
        <AppHeader />
        <Box className="title" date-testid="Heading">
          {this.t("calender.title.main")}
        </Box>
        <Box
          className="demo-app-main "
          date-testid="questionDisplay"
          style={{
            minWidth: "100%",
            minHeight: " 100%",
            fontFamily: " Arial, Helvetica Neue, Helvetica, sans-serif",
            fontSize: "14px",
          }}
        >
          <FullCalendar
            plugins={[dayGridPlugin, interactionPlugin]}
            //Dayclick open sweetalert
            headerToolbar={{
              left: "today",
              center: "prev title next",
              right: "",
            }}
            slotLabelFormat={{
              hour: "numeric",
              minute: "2-digit",
              meridiem: true,
            }}
            timeZone=""
            locale={`${localStorage.getItem("language") ?? "en"}`}
            initialView="dayGridMonth"
            weekends={this.state.weekendsVisible}
            dateClick={(date: any) => this.handleDateClick(date)}
            businessHours={{
              daysOfWeek: [1, 2, 3, 4, 5, 6],
            }}
            validRange={{
              start: moment().format("YYYY-MM-DD"),
            }}
            firstDay={1}
            events={this.state.events}
            eventClick={function (info) {
              Swal.fire({
                title: info.event.title,
                text: "Slot Time : " +
                  moment(info.event.startStr).format("DD-MM-YYYY") + " " +
                  "Assessor : " + info.event._def.extendedProps.AssessorName + " " +
                  "Description : " +
                  info.event._def.extendedProps.description,
              });
            }}
            eventTimeFormat={{
              hour: '2-digit',
              minute: '2-digit',
              meridiem: true
            }}
          />
        </Box>
        <Modal
          open={this.state.open}
          onClose={this.handleClose}
          className="modal"
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={this.state.open}>
            {this.state?.slots?.length !== 0 ? (
              <div className="paper">
                <CloseOutlinedIcon
                  className="closeIcon"
                  onClick={() => this.handleClose()}
                />
                <h2 className="modal-title">{this.t("calender.title.date")} </h2>
                <p className="dropdownTitle">
                  {this.t("calender.label.slot")}{" "}
                  <span className="boldText">
                    {moment(this.state.currentDate).format("MMMM D YYYY")}{" "}
                  </span>
                </p>

                <select
                  className="dropdown"
                  placeholder={this.t("calender.placeholder.selectSlot")}
                  onChange={(e) => this.setState({ slotId: e.target.value })}
                >
                  <option>{this.t("calender.label.selectSlot")}</option>
                  {this.state.slots.sort((a: any, b: any) => {
                    return moment.utc(a.attributes.start_time).diff(moment.utc(b.attributes.start_time))
                  })?.map((slot: any) => (
                    <option
                      id={slot?.attributes?.id}
                      key={slot?.attributes?.id}
                      disabled={Number(
                        moment(slot.attributes.start_time)
                          .utc()
                          .format("H")
                      ) < Number(moment().format("H")) &&
                        moment(this.state.currentDate).format(
                          "DD-MM-YYYY"
                        ) === moment().format("DD-MM-YYYY")}
                    >
                      {moment(slot?.attributes?.start_time)
                        .utc()
                        .format("hh:mm a")}{" "}
                      -{" "}
                      {moment(slot?.attributes?.end_time)
                        .utc()
                        .format("hh:mm a")}
                    </option>
                  ))}
                </select>
                {this.state.error && (
                  <p
                    style={{
                      color: "red",
                      marginTop: "0.5rem",
                      marginBottom: "1rem",
                    }}
                  >
                    {this.t("calender.errorMessages.slot")}
                  </p>
                )}
                <Box className="btnContainer">
                  <Button
                    variant="outlined"
                    className="btnCancel"
                    onClick={() => this.handleClose()}
                    style={{marginLeft : locallanguage ? "1rem" : "0"}}
                  >
                    {this.t("calender.button.cancel")}
                  </Button>
                  <Button
                    variant="contained"
                    className="btnSubmit"
                    onClick={() => this.handleSubmit()}
                  >
                    {this.t("calender.button.submit")}
                  </Button>
                </Box>
              </div>
            ) : (
              <div className="paper">
                <CloseOutlinedIcon
                  className="closeIcon"
                  onClick={() => this.handleClose()}
                />
                <h2 className="modal-title">
                  {this.t("calender.label.alreadyBooked")}
                </h2>
              </div>
            )}
          </Fade>
        </Modal>
        <Modal
          open={this.state.successModal}
          // onClose={this.handleClose}
          className="modal modal2"
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={this.state.successModal}>
            <div className="paper">
              <CloseOutlinedIcon
                className="closeIcon"
                onClick={() => this.handleSuccessModal()}
              />
              <img src={groupCheck} alt="groupCheck" className="modalImg" />
              <h2 className="modalTitle2">{this.t("calender.label.congratulation")}!</h2>
              <p className="modalText2">
                {this.t("calender.label.lab1")} <br />
                {this.t("calender.label.lab2")}
              </p>
            </div>
          </Fade>
        </Modal>
        <AppFooter />
      </Box>
    );
  }
}

export default withTranslation()(DefaultCalendar);
// Customizable Area End
