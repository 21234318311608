// Customizable Area Start
import React from "react";

function UnavailabilityIcon() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.059 2.684a.451.451 0 0 0-.03.676L14.669 14a.451.451 0 0 0 .676-.03A8.04 8.04 0 0 0 4.058 2.684zm9.912 12.661a.451.451 0 0 0 .03-.675L3.36 4.029a.451.451 0 0 0-.676.03 8.04 8.04 0 0 0 11.287 11.287zM2.66 2.661a8.985 8.985 0 1 1 12.707 12.707A8.985 8.985 0 0 1 2.661 2.661z"
        fill="white"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default UnavailabilityIcon;

// Customizable Area End
