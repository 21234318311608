// Customizable Area Start
import React from "react";
import {
  Box,
  Button,
  Card,
  Grid,
  Typography,
  Modal,
  Fade,
  Backdrop,
  Link,
} from "@material-ui/core";
import moment from "moment";
import "./Dashboard.web.css";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import AppHeader from "../../../components/src/AppHeader.web";
import AppFooter from "../../../components/src/AppFooter.web";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import DashboardController from "./DashboardController.web";
import Loader from "../../../components/src/Loader.web";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import { styled } from "@material-ui/styles";
const locallanguage = localStorage.getItem("language") === "ar";
import LinearProgress from "@material-ui/core/LinearProgress";

const BorderLinearProgress = withStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 10,
      borderRadius: 5,
    },
    colorPrimary: {
      backgroundColor:
        theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
    },
    bar: {
      borderRadius: 5,
      backgroundColor: "#1a90ff",
    },
  })
)(LinearProgress);

class AssesmentDashboard extends DashboardController {
  getButtonColor = (status: string, isDisabled: boolean) => {
    switch (status) {
      case "Start Now":
        return isDisabled ? "#bababa" : "#1b3c69";
      case "Resume":
        return "#0076be";
      case "Completed":
        return "#288a0a";
      case "Schedule Now":
        return "#cca91f";
      default:
        return "#1b3c69";
    }
  };

  renderGrid = (val: any) => {
    return (
      <Box>
        <Grid container>
          <Grid item sm={6} className="content">
            <p className="contentTitle">{this.t("trackerDashboard.label.evaluationType")}</p>
            <p className="value">{val?.evalution_type}</p>
          </Grid>
          <Grid item sm={6} className="content">
            <p className="contentTitle">{this.t("trackerDashboard.label.completeBy")}</p>
            <p className="value">
              {`${moment(val?.complete_by).format("Do")}`}{" "}
              {moment(val?.complete_by).format("MMMM YYYY")} |{" "}
              {moment(val?.complete_by).format("hh:mm A")}
            </p>
          </Grid>
          {val?.tool_type === "OnlineTool" ? <Grid item sm={6} className="content">
            <p className="contentTitle">{this.t("trackerDashboard.label.DURATION")}</p>
            {val?.name === "Psychometric" ? <p><b>--</b></p> : <p className="value"> {val?.updated_duration === null || val?.updated_duration === 0 ?
             `${(val?.hour) * 60 + val?.mint} Minutes` : val?.updated_duration + " Minutes"} </p>}
          </Grid> :
            <Grid item sm={6} className="content">
              <p className="contentTitle">{this.t("trackerDashboard.label.DURATION")}</p>
              <p className="value">{val?.duration + " Minutes"}</p>
            </Grid>
          }
          {val?.tool_type === "OnlineTool" ? <Grid item sm={6} className="content">
            <Box className="progressbar">
              <p className="contentTitle">{this.t("trackerDashboard.label.progress")}</p>
              <p className="value">
                {val?.progress ? this.checkProgress(val) + "%" : "0%"}
              </p>{" "}
            </Box>
            <BorderLinearProgress
              style={{ marginBottom: "3px" }}
              variant="determinate"
              value={val?.progress ? this.checkProgress(val) : 0}
            />
          </Grid> : <Grid item sm={6} className="content">
            <Box >
              <p className="contentTitle">{this.t("trackerDashboard.label.STATUS")}</p>
              <p className="value" style={{ textTransform: "none" }}>
                {val?.progress}
              </p>{" "}
            </Box>
          </Grid>}
        </Grid>
      </Box>
    );
  };

  renderButton = (val: any) => {
    let date = moment().format("yyyy-MM-DD");
    return (
      <>
        {val?.tool_type === "OnlineTool" ?
          <>
            {
              val.status && val.status === "Completed" ?
              <Button className="custombtn greenAssordashbord">{this.t("trackerDashboard.label.Completed")}</Button> :
              this.renderButtonOne(val,date)
            }
          </>
          :
          this.assessorToolButton(date, val)
        }
      </>
    )
  }

  renderButtonOne = (val:any,date:any) => {
    return(
      <>
              {val.status === "Start Now" ?  <Button
                className={
                  moment(val?.complete_by).isBefore(date, "year")
                    ? "custombtn"
                    : "custombtn blue"
                }
                disabled={
                  moment(val?.complete_by).isBefore(date, "year") ? true : false
                }
                variant="contained"
                onClick={() => this.handleStartNow(val)}
              >{this.t("trackerDashboard.label.StartNow")}</Button> :  <Button
              className={
                moment(val?.complete_by).isBefore(date, "year")
                  ? "custombtn"
                  : "custombtn orange"
              }
              disabled={
                moment(val?.complete_by).isBefore(date, "year") ? true : false
              }
              variant="contained"
              onClick={() => this.handleStartNow(val)}
            >{this.t("trackerDashboard.button.resume")}</Button>}
              </>
    )
  }

  renderBtnInnerPart = (val:any,date:any) => {
    return (
      <>
        {val?.progress && this.checkProgress(val) === 100 ?
          <Button className="custombtn greenAssordashbord">{this.t("trackerDashboard.label.Completed")}</Button> :
          <Button
            className={
              moment(val?.complete_by).isBefore(date, "year")
                ? "custombtn"
                : "custombtn blue"
            }
            disabled={
              moment(val?.complete_by).isBefore(date, "year") ? true : false
            }
            variant="contained"
            onClick={() => this.handleStartNow(val)}
          >{this.t("trackerDashboard.label.StartNow")}</Button>}
      </>
    )
  }

  renderScheduleNow = (val: any, date: any) => {
    let datecomplei = moment(val?.complete_by).format("YYYY-MM-DD")
    let currentData = moment().format("YYYY-MM-DD")
    return (
      <Button
        className={
          moment(val?.complete_by).isBefore(date, "year")
            ? "custombtn"
            : "custombtn golden"
        }
        disabled={
          moment(currentData).isBefore(datecomplei, "date") ? false : true
        }
        variant="contained"
        onClick={() => {
          localStorage.setItem("toolId", val?.id);
          this.props.navigation.navigate("AssessorList");
        }}
      >
        {this.t("trackerDashboard.button.scheduleNow")}
      </Button>
    )
  }
  assessorToolButton = (date: any, val: any) => {
    let slotDate = val?.slots?.data?.attributes.schedule_date
    let isDisable = false
    if (date === slotDate) {
      let format = 'hh:mm:ss'
      let time = moment(moment().format("hh:mm:ss"), format),
        afterTime = moment(moment(val?.slots?.data?.attributes?.end_time).utc().format(format), format),
        beforeTime = moment(moment(val?.slots?.data?.attributes?.start_time).utc().subtract(30, 'minutes').format(format), format);
      if (!time.isBetween(beforeTime, afterTime)) {
        isDisable = true
      }
    }
    else {
      let checkDate = date === slotDate
      isDisable = !(checkDate)
    }
    return <>
      {val?.progress && val?.progress === "Completed" ? <Button className="custombtn greenAssordashbord">{this.t("trackerDashboard.label.Completed")}</Button> :
        <>
          {
            val.slots.data ? (
              <Button
                className={
                  moment(val?.complete_by).isBefore(date, "year")
                    ? "custombtn"
                    : "custombtn orange"
                }
                variant="contained"
                disabled={isDisable}
                onClick={() => {
                  this.redirectData(val);
                }}
              >
                {this.t("trackerDashboard.button.joinNow")}
              </Button>
            ) : (
              this.renderScheduleNow(val, date)
            )
          }
        </>}
    </>

  }

  render() {
    return (
      <DashboardPage>
        {this.state.loading && <Loader loading={this.state.loading} />}
        <AppHeader />
        <Box margin="0px 30px 60px" className="dashboard">
          <Box className="dashboard-title">
            <Typography className="title" variant="h5">
              {this.t("trackerDashboard.title")}
            </Typography>
            <Button
              onClick={() => this.props.navigation.navigate("ParticipantEvents")}
              className="goToDashboard"
              id="goToDashboard"
            >
              {this.t("trackerDashboard.button.bookedSlot")}
            </Button>
          </Box>
          <Grid container spacing={4}>
            {this.state.tools ?
              this.state.tools?.map((val: any) => {
                return (
                  <Grid item sm={4} key={val?.id} className="CardContainerPage">
                    <Card className="toolCard">
                      {val.slots?.data ? (
                        <Box
                          style={{
                            background: "#d3e2f6",
                            color: "#1b3c69",
                            padding: "10px",
                            borderRadius: "10px",
                            width: "fit-content",
                            fontSize: "12px",
                            position: "absolute",
                            borderBottomRightRadius: "0px",
                            borderBottomLeftRadius: "0px"
                          }}
                        >
                          {this.t("trackerDashboard.label.slotBooked")}{" "}
                          {moment(
                            val.slots?.data?.attributes?.schedule_date
                          ).format("DD-MM-YYYY")} {this.t("trackerDashboard.label.time")} : {" "}
                          {moment(
                            val.slots?.data?.attributes?.start_time
                          ).utc().format("h:mm A")}{" "}
                          -{" "}
                          {moment(
                            val.slots?.data?.attributes?.end_time
                          ).utc().format("h:mm A")}
                        </Box>
                      ) : (
                        ""
                      )}
                      <Box className="tool">
                        <img
                          src={val?.logo}
                          alt={val.logo_url}
                          className="image"
                        />
                        <p className="heading">{val?.name}</p>
                        <p className="description">{val?.description}</p>
                      </Box>
                      {this.renderGrid(val)}
                      {this.renderButton(val)}

                    </Card>
                  </Grid>
                );
              }) : null}
          </Grid>
        </Box>
        <Modal
          open={this.state.open}
          onClose={() => this.setState({ open: false })}
          className="linkModal"
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={this.state.open}>
            <div className="paper">
              <CloseOutlinedIcon
                className={locallanguage ? "closeIconLeft" : "closeIcon"}
                style={{cursor: "pointer" }}
                onClick={() => this.setState({ open: false })}
                id="CloseOutlinedIcon"
              />
              <Box>{this.t("trackerDashboard.label.clickedOn")} </Box>
              <Box style={{ marginTop: "1rem" }}>
                {this.t("trackerDashboard.label.link")} :
                <Link
                  href={this.state.url}
                  style={{ color: "black" , display: 'block',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  width:"150px"
                 }}
                  target="_blank"
                  onClick={() => this.setState({ open: false })}
                  id="LinkButton"
                >
                  {this.state.url}
                </Link>
               {this.state.PsychometricTool.status_count == 1 && <>
                <Box style={{ fontFamily: "ProximaNova-Regular, Serif, Sans-serif",marginTop:"1rem" }}>
                {this.t("trackerDashboard.label.HaveyoutakenPsychometricAssessment")}
              </Box>
              <Box className="btn">
                <Button className="btnYes" variant="contained" style={{marginLeft:"10px", marginRight:"10px"}} onClick={() => {this.setState({ open: false })
                  this.updateProgress()}}>{this.t("trackerDashboard.label.Yes")}</Button>
                <Button style={{marginLeft:"10px", marginRight:"10px"}} className="btnNo" variant="contained" onClick={() => this.setState({ open: false })}>{this.t("trackerDashboard.label.NotYet")}</Button>
              </Box>
                </>}
              </Box>
            </div>
          </Fade>
        </Modal>
        <AppFooter />
      </DashboardPage>
    );
  }
}

const DashboardPage = styled("div")({
  "& .CardContainerPage": {
    "& .MuiPaper-elevation1": {
      boxShadow: "none",
    },
  },
  "& .description": {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "90%",
  },
});

export default withRouter(withTranslation()(AssesmentDashboard));

// Customizable Area End
